import actions from "actions";

const initialState = {
  messageThreads: [],
  newMessageCount: 0,
  selectedMessageThreads: [],
};

function messages(state = initialState, action) {
  switch (action.type) {
    case actions.messages.MESSAGE_THREADS_RECEIVED:
      return { ...state, messageThreads: action.threads, newMessageCount: action.newMessageCount };
    case actions.messages.MESSAGE_THREAD_SELECTED: {
      if (action.isPhone) {
        return { ...state, selectedMessageThreads: [action.thread] };
      }
      const { selectedMessageThreads } = state;
      if (!action.thread) return state;
      const threadIndex = selectedMessageThreads.findIndex((t) => t._id === action.thread?._id);
      if (threadIndex > -1) {
        const updatedThreads = selectedMessageThreads.map((t, index) => {
          if (index !== threadIndex) return t;
          return { ...t, ...action.thread };
        });
        return { ...state, selectedMessageThreads: updatedThreads };
      }
      if (selectedMessageThreads.length > 2) {
        const slicedMessageThreads = selectedMessageThreads.slice(0, 2);
        return { ...state, selectedMessageThreads: [action.thread, ...slicedMessageThreads] };
      }
      return { ...state, selectedMessageThreads: [action.thread, ...selectedMessageThreads] };
    }
    case actions.messages.CLOSE_MESSAGE_THREAD: {
      const updatedThreads = state.selectedMessageThreads.filter((t) => t._id !== action.threadId);
      return { ...state, selectedMessageThreads: updatedThreads };
    }
    case actions.messages.MESSAGE_THREADS_EXTEND: {
      const { messageThreads } = state;
      const threadsToAdd = [];
      action.threads.forEach((thread) => {
        const existingThread = messageThreads.find((t) => t._id === thread._id);
        if (!existingThread) threadsToAdd.push(thread);
      });
      const newMessageThreads = [...messageThreads, ...threadsToAdd];
      newMessageThreads.sort((a, b) => a.lastMessageAt > b.lastMessageAt);
      return { ...state, messageThreads: newMessageThreads };
    }
    case actions.messages.MARK_MESSAGE_THREADS_AS_SEEN: {
      const { messageThreads } = state;
      const { messageThreads: messageThreadsSeen, markAllAsSeen, userId } = action;
      const updatedMessageThreads = messageThreads.map((n) => {
        if (markAllAsSeen || messageThreadsSeen.indexOf(n._id) > -1) return { ...n, seenBy: [...n.seenBy, userId] };
        return n;
      });
      return { ...state, notifications: updatedMessageThreads, newMessageCount: 0 };
    }
    case actions.messages.LOGOUT: {
      return { ...state, messageThreads: [], selectedMessageThreads: [] };
    }
    case actions.messages.ON_THREAD_CREATED: {
      const { messageThreads, selectedMessageThreads } = state;
      const existingNewThread = messageThreads.findIndex((t) => t._id === action.newThreadId);
      const existingSelectedThread = selectedMessageThreads.findIndex((t) => t._id === action.newThreadId);
      const updatedThreads = Object.assign([], messageThreads);
      const updatedSelectedThreads = Object.assign([], selectedMessageThreads);
      if (existingNewThread !== -1) {
        updatedThreads[existingNewThread] = action.thread;
      } else updatedThreads.push(action.thread);
      if (existingSelectedThread !== -1) {
        updatedSelectedThreads[existingSelectedThread] = action.thread;
      }
      return { ...state, messageThreads: updatedThreads, selectedMessageThreads: updatedSelectedThreads };
    }
    default:
      return state;
  }
}

export default messages;

import React from "react";
import { confirmable } from "react-confirm";
import { Modal, Header, Button, Icon, Input } from "semantic-ui-react";

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "", confirmed: false };
  }

  complete = () => {
    this.props.onComplete(this.state.value);
    this.props.proceed();
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13 && !this.state.confirmed) {
      this.setState({ confirmed: true });
      this.complete();
    }
  };

  render() {
    const { show, dismiss, title, prompt } = this.props;
    const { value } = this.state;
    return (
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={show}
        onClose={dismiss}
        basic
        size="small"
        className="no-scroll"
      >
        <Header content={title} />
        <Modal.Content>{prompt}</Modal.Content>
        <Modal.Content>
          <Input
            fluid
            value={value}
            onChange={(e) => this.setState({ value: e.target.value })}
            onKeyDown={this.handleKeyDown}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" inverted onClick={dismiss}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" inverted onClick={this.complete}>
            <Icon name="checkmark" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default confirmable(Dialog);

import React from "react";
import { Icon, SemanticICONS, SemanticCOLORS } from "semantic-ui-react";
import styled from "styled-components";

const MetaContainer = styled.div<{ $bgColor: string }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 30px;

  background-color: ${({ $bgColor }) => $bgColor};

  > span {
    display: block;
    color: #fff;
    font-size: 12px;
  }
  i {
    color: #fff;
  }
`;

export const MetaInfo = ({
  text,
  icon: propIcon,
  color,
}: {
  text: string;
  icon: SemanticICONS;
  color: SemanticCOLORS;
}) => (
  <MetaContainer $bgColor={color}>
    <Icon name={propIcon} size="small" />
    <span>{text}</span>
  </MetaContainer>
);

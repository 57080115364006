const messagesActions = {
  MESSAGE_THREADS_RECEIVED: "MESSAGE_THREADS_RECEIVED",
  MESSAGE_THREAD_SELECTED: "MESSAGE_THREAD_SELECTED",
  MESSAGE_THREADS_EXTEND: "MESSAGE_THREADS_EXTEND",
  MARK_MESSAGE_THREADS_AS_SEEN: "MARK_MESSAGE_THREADS_AS_SEEN",
  CLOSE_MESSAGE_THREAD: "CLOSE_MESSAGE_THREAD",
  LOGOUT: "LOGOUT",
  ON_THREAD_CREATED: "ON_THREAD_CREATED",

  receiveThreads(threads, newMessageCount) {
    return { type: this.MESSAGE_THREADS_RECEIVED, threads, newMessageCount };
  },
  selectThread(thread, isPhone = false) {
    return { type: this.MESSAGE_THREAD_SELECTED, thread, isPhone };
  },
  extendThreads(threads) {
    return { type: this.MESSAGE_THREADS_EXTEND, threads };
  },
  markMessageThreadsAsSeen(messageThreads, markAllAsSeen, userId, newMessageCount) {
    return {
      type: this.MARK_MESSAGE_THREADS_AS_SEEN,
      messageThreads,
      markAllAsSeen,
      userId,
      newMessageCount,
    };
  },
  closeMessageThread(threadId) {
    return { type: this.CLOSE_MESSAGE_THREAD, threadId };
  },
  onThreadCreated(newThreadId, thread) {
    return { type: this.ON_THREAD_CREATED, newThreadId, thread };
  },
  logout() {
    return { type: this.LOGOUT };
  },
};

export default messagesActions;

import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";

export const profileLinks = {
  facebook: {
    icon: "facebook",
    title: "Facebook",
    link: "https://facebook.com/",
  },
  twitter: {
    iconLeftAlt: "X",
    title: "𝕏.com",
    link: "https://twitter.com/",
  },
  instagram: {
    icon: "instagram",
    title: "Instagram",
    link: "https://instagram.com/",
  },
  github: {
    icon: "github",
    title: "Github",
    link: "https://github.com/",
  },
  linkedin: {
    icon: "linkedin",
    title: "LinkedIn",
    link: "https://linkedin.com/in/",
  },
};

export const socialMediaLinks = {
  facebook: {
    icon: "facebook",
    content: "Facebook",
    link: "https://facebook.com/",
  },
  twitter: {
    iconLeftAlt: "𝕏",
    content: "𝕏.com",
    link: "https://twitter.com/",
  },
  instagram: {
    icon: "instagram",
    content: "Instagram",
    link: "https://instagram.com/",
  },
  github: {
    icon: "github",
    content: "Github",
    link: "https://github.com/",
  },
  linkedin: {
    icon: "linkedin",
    content: "LinkedIn",
    link: "https://linkedin.com/in/",
  },
};

export class ProfileLink extends PureComponent {
  render() {
    const { type, link } = this.props;
    const linkType = profileLinks[type];
    return (
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Icon name={linkType.icon} />{" "}
        <a className="with-border" href={`${linkType.link}${link}`} target="_blank" rel="noopener noreferrer">
          {linkType.title}
        </a>
      </div>
    );
  }
}

import { useCallback, CSSProperties } from "react";
import { Button, Popup, SemanticICONS, Icon, SemanticCOLORS } from "semantic-ui-react";
import styled from "styled-components";
import { Other } from "simplydo/interfaces";

export const ROLE_EDITOR_ICON_OPTIONS: SemanticICONS[] = ["star", "rocket", "trophy", "certificate", "diamond"];

export const ROLE_EDITOR_COLOR_OPTIONS: SemanticCOLORS[] = [
  "red",
  "orange",
  "yellow",
  "olive",
  "green",
  "teal",
  "blue",
  "violet",
  "purple",
  "pink",
];

export const SEMANTIC_UI_COLOR_NAME_MAP = {
  red: "#e74c3c",
  orange: "#e67e22",
  yellow: "#f39c12",
  olive: "#8e44ad",
  green: "#27ae60",
  teal: "#16a085",
  blue: "#3498db",
  violet: "#9b59b6",
  purple: "#8e44ad",
  pink: "#e91e63",
  brown: "#795548",
  grey: "#95a5a6",
  black: "#000000",
  white: "#ffffff",
};

const IconChooserButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
`;

const IconChooserButton = styled.div<{ $isSelected?: boolean }>`
  cursor: pointer;
  border-radius: 5px;
  padding: 7.5px;
  &:hover {
    ${({ $isSelected }) =>
      !$isSelected &&
      `
      background-color: #f6f6f6;
    `}
  }
  ${({ $isSelected }) =>
    $isSelected &&
    `
    background-color: #cfcfcf;
  `}
  > i.icon {
    margin: 0;
  }
`;

const ColorChooserButton = styled.div<{ $color: SemanticCOLORS }>`
  cursor: pointer;
  border-radius: 5px;
  padding: 15px;
  background-color: ${({ $color }) => SEMANTIC_UI_COLOR_NAME_MAP[$color]};
  &:hover {
    // Grow slightly
    transform: scale(1.05);
  }
`;

type RoleEditorIconChooserProps = {
  containerStyle?: CSSProperties;
  role: Other.IRole;
  updateRole: (data: Partial<Other.IRole>) => void;
};

const RoleEditorIconChooser = ({ containerStyle = {}, role, updateRole }: RoleEditorIconChooserProps) => {
  const {
    icon = {
      name: "",
      colour: "",
    },
  } = role ?? {};
  const { name: selectedIcon, colour: selectedColor } = icon;

  const setSelectedIcon = useCallback(
    (icon: SemanticICONS) => {
      updateRole({
        icon: {
          ...(role.icon ?? {}),
          name: icon,
        },
      });
    },
    [role, updateRole],
  );

  const setSelectedColor = useCallback(
    (color: SemanticCOLORS) => {
      updateRole({
        icon: {
          ...role.icon,
          colour: color,
        },
      });
    },
    [role, updateRole],
  );

  const removeIcon = useCallback(() => {
    updateRole({
      icon: null,
    });
  }, [updateRole]);

  return (
    <div style={containerStyle}>
      <IconChooserButtonGroup>
        {ROLE_EDITOR_ICON_OPTIONS.map((icon) => (
          <Popup
            key={icon}
            on="click"
            position="bottom center"
            content={
              <IconChooserButtonGroup>
                {ROLE_EDITOR_COLOR_OPTIONS.map((color) => (
                  <ColorChooserButton
                    key={color}
                    $color={selectedIcon === icon ? color : null}
                    onClick={() => setSelectedColor(color)}
                  />
                ))}
              </IconChooserButtonGroup>
            }
            trigger={
              <IconChooserButton $isSelected={selectedIcon === icon} onClick={() => setSelectedIcon(icon)}>
                <Icon name={icon} size="big" color={icon === selectedIcon ? selectedColor || null : null} />
              </IconChooserButton>
            }
          />
        ))}
      </IconChooserButtonGroup>
      {selectedIcon ? (
        <Button
          floated="right"
          icon="trash"
          size="mini"
          basic
          onClick={() => removeIcon()}
          content="Remove icon"
          style={{ marginTop: 10 }}
        />
      ) : null}
    </div>
  );
};

export default RoleEditorIconChooser;

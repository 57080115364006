const giphyUrl = "https://api.giphy.com/v1/gifs";

const giphyApi = {
  token: "B0ZScYw9WECMOS2k7VHpbYgviDzWKSIJ",
  tokenString: "api_key=B0ZScYw9WECMOS2k7VHpbYgviDzWKSIJ",

  request(method, path, data, success, fail) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, `${giphyUrl}${path}`);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let response;
          try {
            response = JSON.parse(xhr.responseText);
          } catch (err) {
            console.error(err);
          }
          if (success) success(response);
        } else {
          let message;
          try {
            message = JSON.parse(xhr.responseText).message;
          } catch (err) {
            if (fail) fail({ status: xhr.status, message: "There was a problem with this request" });
          }
          if (fail) fail({ status: xhr.status, message });
        }
      }
    };
    xhr.send(data && JSON.stringify(data));
  },
};

const giphy = {
  trending(data, success, fail) {
    const { page = 1 } = data;
    giphyApi.request(
      "GET",
      `/trending?${giphyApi.tokenString}&limit=12&offset=${(page - 1) * 12}`,
      null,
      success,
      fail,
    );
  },
  search(data, success, fail) {
    const { query, page = 1 } = data;
    giphyApi.request(
      "GET",
      `/search?${giphyApi.tokenString}&q=${query}&limit=12&offset=${(page - 1) * 12}`,
      null,
      success,
      fail,
    );
  },
};

export default giphy;

import React from "react";
import { Trans } from "react-i18next";
import util from "utils/utils";

export default function groupJoinRequestedContent({ events, ownerGroup = { name: "Undefined" } }) {
  return (
    <Trans i18nKey="notifications.groupJoinRequested">
      <b>{{ name: util.getEventNames(events) }}</b>
      would like to join your group
      <b>{{ group: ownerGroup.name }}</b>
    </Trans>
  );
}

import React from "react";
import { Menu, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import util from "utils/utils";
import { EmptyBox } from "components/lib/UI";
import { UserChip } from "../Chips";

const MessageTrayMessages = ({ messageThreads = [], selectMessageThread, user, t }) => {
  if (!messageThreads.length) {
    return (
      <Menu.Item>
        <EmptyBox>
          <h5>{t("messages.empty")}</h5>
        </EmptyBox>
      </Menu.Item>
    );
  }
  const numberOfMessagesToShow = window?.innerHeight ? (0.6 * window?.innerHeight - 100) / 85 : 6;
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
      {messageThreads.slice(0, numberOfMessagesToShow).map((thread) => {
        if (thread.lastMessageAt || thread.participants.length > 2) {
          const hasMoreThanTwoParticipants = thread?.participantUsers?.length && thread?.participantUsers?.length > 2;
          const otherUsers = (thread.participantUsers ?? []).filter((p) => p._id !== user._id);

          return (
            thread.participantUsers.length > 0 && (
              <Menu.Item
                fitted
                link
                key={thread._id}
                onClick={() => selectMessageThread(thread)}
                style={{
                  padding: ".833em 1em",
                  borderRadius: 6,
                  marginTop: 0,
                  marginBottom: 0,
                  alignSelf: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {hasMoreThanTwoParticipants ? (
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          display: "block",
                          margin: 0,
                        }}
                      >
                        {util.getChatName(user, thread)}
                      </span>
                    ) : (
                      <UserChip user={otherUsers[0]} compact displayOnly />
                    )}
                  </div>
                  {thread.lastMessageSnippet && thread.lastMessageAt && (
                    <span>{moment(new Date(thread.lastMessageAt)).fromNow()}</span>
                  )}
                </div>
                {thread.seenBy && thread.seenBy.indexOf(user && user._id) === -1 && (
                  <Label size="tiny" color="teal">
                    New
                  </Label>
                )}
                {thread.lastMessageSnippet || thread.lastMessageAt ? (
                  <span>
                    {thread.lastMessageUser === (user && user._id) && "You: "}{" "}
                    {thread.lastMessageSnippet || moment(new Date(thread.lastMessageAt)).fromNow()}
                  </span>
                ) : null}
              </Menu.Item>
            )
          );
        }
        return null;
      })}
    </div>
  );
};

export default withTranslation()(MessageTrayMessages);

import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import moment from "moment";
import util from "utils/utils";

import RowComponent from "components/lib/RowComponent";

const HomeCuratedChallenges = ({ t, challenges }) => {
  if (!challenges.length) return null;
  return (
    <RowComponent>
      <RowComponent.Fill>
        <RowComponent.Title>Pinned challenges</RowComponent.Title>
        <Button as={Link} to="/challenges/discover" primary size="mini">
          {t("challenges.yours.sidebar.viewAll")}
        </Button>
      </RowComponent.Fill>
      {challenges && challenges.length > 0 && (
        <>
          {challenges.slice(0, 4).map((challenge, index) => (
            <RowComponent.Cell index={index} key={challenge._id} as={Link} to={`/challenges/${challenge._id}`}>
              <div
                style={{
                  width: 50,
                  height: 40,
                  borderRadius: 3,
                  flex: "0 0 50px",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundImage: `url(${util.mixinCssUrlFallback(util.getImageUrl(challenge, ["coverImage", "bannerImage"], ["200x200"]), util.challengeImage())})`,
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 5,
                  marginRight: 5,
                  position: "relative",
                  top: -1,
                }}
              >
                <RowComponent.Title wrapping style={{ color: "#5d5d5d" }} block>
                  {challenge.name}
                </RowComponent.Title>
                <RowComponent.StatusArea>
                  {challenge.stage === "closed" ? (
                    <>
                      <RowComponent.StatusLabel color="red" /> Closed
                    </>
                  ) : (
                    challenge.stage === "published" &&
                    (challenge.deadline ? (
                      <>
                        <RowComponent.StatusLabel color="blue" /> Closes {moment(challenge.deadline).fromNow()}
                      </>
                    ) : (
                      <>
                        <RowComponent.StatusLabel color="green" /> Open
                      </>
                    ))
                  )}
                </RowComponent.StatusArea>
              </div>
            </RowComponent.Cell>
          ))}
        </>
      )}
    </RowComponent>
  );
};

const HomeCuratedChallengesContainer = withTranslation()(HomeCuratedChallenges);

export default HomeCuratedChallengesContainer;

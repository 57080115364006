import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Message, Icon } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import api from "api";
import styled from "styled-components";
import {
  OverviewContainer,
  HeaderContainer,
  HeaderInfo,
  InfoContainer,
  InfoBox,
  InfoBoxWidget,
} from "./CompanyOverview/Styles";
import {
  CompanyPatents,
  CompanyArticles,
  CompanyTwitter,
  CompanyCertifications,
  CompanyFundedResearch,
  CompanyTRL,
  CompanyShareholders,
  CompanyBasicInfo,
  CompanyAcquisitions,
  CompanyChildOwnerships,
  CompanySanctions,
  CompanyCompaniesHouse,
  CompanyBlog,
  CompanyMessages,
  CompanyServices,
} from "./CompanyOverview/InfoComponents";
import { CreditsafeReportSummary } from "./CompanyOverview/InfoComponents/CompanyCreditsafe";

const CompanyPDFContainer = styled.div`
  padding: 20px 50px;
  background: #fff;
  ${InfoBoxWidget} {
    background: #fff;
  }
`;

const CompanyView = ({ company }) => {
  const {
    imageUrl,
    name,
    categories = [],
    patents,
    articles = [],
    certifications,
    research = [],
    trl,
    sources = {},
    messages = [],
    ai,
    runningTasks = [],
  } = company;
  const services = ai?.services || [];
  const { creditsafe = {}, crunchbase = {}, twitter = {}, blogTask = {}, googleNews = {} } = sources;
  const { news = [] } = googleNews;

  const { acquisitions = [], ownerships = [] } = crunchbase;
  const report = creditsafe?.report?.data;
  const shareholders = report?.shareCapitalStructure?.shareHolders || [];

  const hasShareholders = !!shareholders.length;
  const hasAcquisitions = !!acquisitions.length;
  const hasChildOwnerships = !!ownerships.length;
  const hasNews = !!(research.length || articles.length || news.length);
  const hasSocials = !!twitter?.socialActivity;
  const hasProtect = !!creditsafe?.protect?.alertsCount;
  const hasBlogs = !!sources?.blogTask?.blogs?.length;
  const hasMessages = !!messages.length;

  const companyCardImage = useMemo(() => {
    if (imageUrl) return <img src={imageUrl} alt={name} />;
    return <Icon size="huge" name="building" />;
  }, [imageUrl, name]);

  const handleNewSearch = () => {};

  return (
    <CompanyPDFContainer>
      {/*
        Include link to font-awesome stylesheet here, otherwise any Semantic Icons do not render in the PDF download
      */}
      <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />

      <InfoBoxWidget>
        <OverviewContainer>
          <HeaderContainer>
            {companyCardImage}
            <HeaderInfo>
              <h3>Company</h3>
              <h1>{company.name}</h1>
              {categories.length ? <span className="meta">{categories.join(", ")}</span> : null}
            </HeaderInfo>
          </HeaderContainer>
        </OverviewContainer>
      </InfoBoxWidget>

      <InfoBoxWidget style={{ marginLeft: 0 }}>
        <CompanyBasicInfo company={company} />
      </InfoBoxWidget>
      <InfoContainer $sectioned>
        <InfoBox>
          <InfoContainer>
            <CompanyServices services={services} runningTasks={runningTasks} />
          </InfoContainer>
          <InfoContainer>
            <CompanyCompaniesHouse company={company} isPDF />
          </InfoContainer>
          {creditsafe?.report ? (
            <InfoContainer>
              <InfoBoxWidget>
                <CreditsafeReportSummary creditsafe={creditsafe} />
              </InfoBoxWidget>
            </InfoContainer>
          ) : null}
          <InfoContainer>
            <CompanyCertifications certifications={certifications} />
          </InfoContainer>
          <InfoContainer>
            <CompanyPatents companyName={company.name} patents={patents} total={sources?.patents?.total} />
          </InfoContainer>
          <InfoContainer>
            <CompanyTRL trl={trl} isPDF />
          </InfoContainer>
        </InfoBox>
      </InfoContainer>

      {hasShareholders || hasAcquisitions || hasChildOwnerships ? (
        <InfoContainer>
          <InfoBox>
            <InfoContainer>
              <InfoBoxWidget>
                <CompanyShareholders shareholders={shareholders} handleNewSearch={handleNewSearch} isPDF />
              </InfoBoxWidget>
            </InfoContainer>
            <InfoContainer>
              <CompanyChildOwnerships childOwnerships={ownerships} handleNewSearch={handleNewSearch} />
            </InfoContainer>
            <InfoContainer>
              <CompanyAcquisitions acquisitions={acquisitions} handleNewSearch={handleNewSearch} />
            </InfoContainer>
          </InfoBox>
        </InfoContainer>
      ) : null}

      {hasProtect ? (
        <InfoContainer>
          <InfoBox>
            <InfoContainer>
              <CompanySanctions protect={creditsafe?.protect} />
            </InfoContainer>
          </InfoBox>
        </InfoContainer>
      ) : null}

      {hasNews ? (
        <InfoContainer>
          <InfoBox>
            <InfoContainer>
              <CompanyFundedResearch fundingData={research} />
            </InfoContainer>
            <InfoContainer>
              <CompanyArticles numArticles={crunchbase.numArticles} articles={articles} news={news} />
            </InfoContainer>
          </InfoBox>
        </InfoContainer>
      ) : null}

      {hasSocials ? (
        <InfoContainer>
          <InfoBox>
            <InfoContainer>
              <CompanyTwitter twitter={twitter} />
            </InfoContainer>
          </InfoBox>
        </InfoContainer>
      ) : null}

      {hasBlogs ? (
        <InfoContainer>
          <InfoBox>
            <InfoContainer>
              <CompanyBlog blogTask={blogTask} />
            </InfoContainer>
          </InfoBox>
        </InfoContainer>
      ) : null}

      {hasMessages ? (
        <InfoContainer>
          <InfoBox>
            <InfoContainer>
              <CompanyMessages messages={messages} />
            </InfoContainer>
          </InfoBox>
        </InfoContainer>
      ) : null}
    </CompanyPDFContainer>
  );
};

const CompanyPDF = () => {
  const [company, setCompany] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const params = useParams();
  const matchId = params.id;

  const getCompany = useCallback(() => {
    setErrorMessage("");
    setCompany(null);
    api.innovationIntelligence.get(
      matchId,
      (response) => {
        setCompany(response.company);
      },
      (err) => {
        setErrorMessage(err.message);
      },
    );
  }, [matchId]);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  if (errorMessage) {
    return (
      <Message error>
        <p>{errorMessage} </p>
      </Message>
    );
  }
  if (!company) {
    return <div>{matchId}</div>;
  }
  return <CompanyView company={company} />;
};

export default CompanyPDF;

import React, { useCallback } from "react";
import { Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import styled from "styled-components";
import { Trans } from "react-i18next";
import util from "utils/utils";
import { useAppDispatch } from "store";
import actions from "actions";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const IdeaBusinessProfileInvitedActions = ({ events, invitations, onAccept, onReject }) => {
  const invitationId = events[0].invitation;
  const invitationExists = invitations.find((i) => i._id === invitationId && !i.acceptedAt);

  const dispatch = useAppDispatch();

  const acceptInvite = useCallback(() => {
    api.invitations.accept(
      invitationId,
      ({ permissions }) => {
        if (permissions) {
          Object.entries(permissions).forEach(([key, value]) => {
            dispatch(actions.user.updatePermissions(key, value));
          });
        }
        toast.success("Business profile joined");
        onAccept(invitationId);
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [invitationId, onAccept, dispatch]);

  const rejectInvite = useCallback(() => {
    api.invitations.reject(
      invitationId,
      () => {
        onReject(invitationId);
        toast.success("Group invitation rejected");
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [invitationId, onReject]);

  if (!invitationExists) return null;
  return (
    <ButtonContainer>
      <Button icon="check" color="green" size="small" onClick={acceptInvite} style={{ marginBottom: 5 }} />
      <Button basic icon="trash" size="small" onClick={rejectInvite} />
    </ButtonContainer>
  );
};

export const IdeaBusinessProfileInvited = ({
  events,
  ownerIdeaBusinessProfile = { profile: { name: "Undefined" } },
}) => {
  return (
    <Trans i18nKey="notifications.ideaBusinessProfileInvited">
      <b>{{ name: util.getEventNames(events) }}</b>
      has invited you to join the group
      <b>{{ profileName: ownerIdeaBusinessProfile?.profile?.name }}</b>
    </Trans>
  );
};

import React, { useState } from "react";
import { toast } from "react-hot-toast";
import api from "api";
import { OpenAPI } from "simplydo/interfaces";
import { useTranslation } from "react-i18next";

type IdeaDocxExportProps = {
  ideas: OpenAPI.Schemas["Idea"][];
  challenge?: OpenAPI.Schemas["Challenge"];
  pdfName?: string;
  trigger: React.ReactElement;
};

const IdeaDocxExport = ({ ideas = [], pdfName, trigger }: IdeaDocxExportProps) => {
  const [isExporting, setIsExporting] = useState(false);
  const { t } = useTranslation();

  const ideaIds = ideas.map((idea) => idea._id);

  const downloaIdeaDocx = () => {
    const loader = toast.loading(t("ideas.docx.download"), {
      duration: 15000,
    });
    setIsExporting(true);
    api.reports.exportIdeasDocx(
      ideaIds,
      {},
      (result) => {
        setIsExporting(false);
        const element = document.createElement("a");
        element.setAttribute("href", result.url);
        element.setAttribute("download", pdfName ?? "download.pdf");
        element.setAttribute("target", "_blank");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        toast.dismiss(loader);
      },
      () => {
        setIsExporting(false);
        toast.dismiss(loader);
        toast.error(t("ideas.docx.error"));
      },
    );
  };

  return React.cloneElement(trigger, {
    loading: isExporting || undefined,
    onClick: downloaIdeaDocx,
  });
};

export default IdeaDocxExport;

import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Button, Accordion, Icon, Input } from "semantic-ui-react";
import { Other, OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";
import { EmptyBox } from "components/lib/UI";
import api from "api";
import util from "utils/utils";
import toast from "react-hot-toast";
import useTheme from "theme/useTheme";
import { UserChip } from "components/lib/Chips";
import useThrottle from "utils/useThrottle";

type IIdeaTemplateChooser = {
  onTemplateSelected: (template: any) => void;
};

type IChallengeSearchState = {
  page: number;
  limit: number;
  query: string;
};

const IdeaTemplateChooser = ({ onTemplateSelected }: IIdeaTemplateChooser) => {
  const [_loading, setLoading] = useState<boolean>(false);
  const [challenges, setChallenges] = useState<Other.IChallenge[]>([]);
  const [nextPageAvailable, setNextPageAvailable] = useState<boolean>(false);
  const [prevPageAvailable, setPrevPageAvailable] = useState<boolean>(false);
  const [accordionIndex, setAccordionIndex] = useState<number>(-1);
  const [selectedChallenge, setSelectedChallenge] = useState<string>("");
  const [challengeSearchState, setChallengeSearchState] = useState<IChallengeSearchState>({
    page: 1,
    limit: 9,
    query: "",
  });
  const params = useParams<{ id: string }>();
  const challenge = useAppSelector((state) => state.challenges.challenges.filter((c) => c._id === params.id)[0]);
  const user: OpenAPI.GET<"/users/me">["response"] = useAppSelector((state) => state.user);
  const canManageChallenge = useMemo(() => util.canManageChallenge(user, challenge), [user, challenge]);
  const { t } = useTranslation();
  const theme = useTheme();
  const challengeId = params.id;

  const templates = [
    {
      _id: "3is",
      name: 'The "3 Is"',
      description: `A short template designed to quickly collect ${t("generic.ideas")} for internal innovation. Includes a self-assessment rating against Importance, Impact, and ease of Implementation.`,
      image: util.getCdnFile("images/format_3is.png"),
      disableAttachments: true,
      body: [
        {
          name: `The ${t("generic.idea")}`,
          fields: [
            {
              wordLimit: "50",
              id: "overview",
              title: `What is your ${t("generic.idea")} about?`,
              description: `Briefly describe your ${t("generic.idea")} as you see it.`,
              type: "longtext",
            },
            {
              id: "importance",
              title: `How important is this ${t("generic.idea")} to you?`,
              description: "10 = Very Important",
              type: "slider",
            },
            {
              id: "implementation",
              title: "How easy would it be to implement?",
              description: "10 = Very easy",
              type: "slider",
            },
            {
              id: "impact",
              title: "How much impact could it have?",
              description: "10 = Game changing",
              type: "slider",
            },
            {
              id: "image",
              title: `Please upload an image of your ${t("generic.idea")} `,
              description: "Optional ",
              type: "image",
            },
          ],
        },
      ],
    },
    {
      _id: "scratch",
      name: "Start from scratch",
      description:
        "None of these suit? No problem - you can craft your own format from scratch using our format builder.",
      image: util.getCdnFile("images/format_scratch.png"),
      disableAttachments: true,
      body: [
        {
          name: `The ${t("generic.idea")}`,
          fields: [],
        },
      ],
    },
  ];

  const userId = user?._id;
  const getChallenges = useThrottle(
    () => {
      setLoading(true);
      setSelectedChallenge("");
      api.users.getChallenges(
        userId,
        challengeSearchState,
        (data) => {
          setChallenges(data.challenges);
          setNextPageAvailable(data.nextPageAvailable);
          setPrevPageAvailable(data.prevPageAvailable);
          setLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setLoading(false);
        },
      );
    },
    500,
    [userId, challengeSearchState],
  );

  useEffect(() => {
    getChallenges();
  }, [getChallenges]);

  const onClickDefaultTemplate = useCallback(
    (template) => {
      onTemplateSelected(template);
      setSelectedChallenge(template._id);
    },
    [onTemplateSelected],
  );

  const onClickChallenge = useCallback(
    (clickedChallenge) => {
      setSelectedChallenge(clickedChallenge._id);
      api.challenges.getTemplate(
        clickedChallenge._id,
        (newTemplate) => {
          if (newTemplate.body) {
            onTemplateSelected(newTemplate);
          } else {
            toast.error("Challenge has no valid template.");
          }
        },
        () => {},
        null,
      );
    },
    [onTemplateSelected],
  );

  const handleAccordionClick = useCallback(
    (e, titleProps) => {
      if (!titleProps) return;
      const { index } = titleProps;
      const newIndex = accordionIndex === index ? -1 : index;
      setAccordionIndex(newIndex);
    },
    [accordionIndex],
  );

  return (
    <div>
      <h3>
        {!selectedChallenge ? <Icon name={"exclamation circle"} color={"red"} /> : null}
        {t("challenge.templateChooser.title")}
      </h3>
      <Card.Group itemsPerRow={3} stackable>
        {templates.map((template, i) => {
          // Temporarily disable challenge capture for regular users/admins
          if (template._id === "challengeCapture" && !util.hasPermission(user, "super.viewDashboard")) return null;
          return (
            <Card key={i}>
              {template.image && (
                <div
                  style={{
                    height: 150,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundImage: `url(${template.image})`,
                  }}
                />
              )}
              <Card.Content>
                <Card.Header>{template.name}</Card.Header>
                <Card.Meta>{template.description}</Card.Meta>
              </Card.Content>
              <Card.Content extra>
                <Button
                  fluid
                  disabled={
                    !canManageChallenge && !util.hasPermission(user, "challenge.editIdeaFormat", challenge?._id)
                  }
                  primary
                  title={template.name}
                  content="Use this format"
                  onClick={() => onClickDefaultTemplate(template)}
                />
              </Card.Content>
            </Card>
          );
        })}
      </Card.Group>

      {challenges.length > 0 ||
      (!!challengeSearchState.query &&
        (canManageChallenge || util.hasPermission(user, "challenge.editIdeaFormat", challenge?._id))) ? (
        <Accordion style={{ marginTop: 20 }}>
          <Accordion.Title active={accordionIndex === 0} index={0} onClick={handleAccordionClick}>
            <h4>
              <Icon name="dropdown" />
              Re-use a format from one of your existing {t("generic.challenges")}
            </h4>
          </Accordion.Title>
          <Accordion.Content active={accordionIndex === 0} index={0} onClick={handleAccordionClick}>
            <Input
              placeholder={`Search for ${t("generic.challengeWithArticle")}..`}
              onChange={(e, { value }) =>
                setChallengeSearchState((prevState) => ({ ...prevState, page: 1, query: value }))
              }
              style={{ marginBottom: 10 }}
              value={challengeSearchState.query}
            />
            {challenges?.length ? (
              <Card.Group itemsPerRow={theme.sizes.isComputer ? 3 : 2} centered>
                {challenges
                  .filter((c) => c._id !== challengeId)
                  .map((c) => (
                    <Card key={c._id}>
                      <div
                        style={{
                          height: 150,
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          backgroundImage: `url(${util.mixinCssUrlFallback(util.challengeImage(c), util.challengeImage())})`,
                        }}
                      />
                      <Card.Content>
                        <Card.Header>{c.name}</Card.Header>
                        <Card.Meta>{t("challenge.templateChooser.copyFromChallenge")}</Card.Meta>
                        <Card.Meta style={{ marginTop: 10 }}>
                          <Link className="with-border" to={`/challenges/${c._id}/format`} target="_blank">
                            View format
                          </Link>
                        </Card.Meta>
                      </Card.Content>
                      <Card.Content extra>
                        {/* @ts-ignore */}
                        <UserChip user={{ ...user, emails: null }} />
                      </Card.Content>
                      <Card.Content extra>
                        <Button fluid primary content="Use this format" onClick={() => onClickChallenge(c)} />
                      </Card.Content>
                    </Card>
                  ))}
              </Card.Group>
            ) : (
              <EmptyBox title={undefined} message={undefined} style={undefined}>
                No {t("generic.challenges")} found
              </EmptyBox>
            )}
            {nextPageAvailable || prevPageAvailable ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                {prevPageAvailable ? (
                  <Button
                    size="small"
                    content="Prev page"
                    onClick={() =>
                      setChallengeSearchState((prevState) => ({
                        ...prevState,
                        page: prevState.page - 1,
                      }))
                    }
                  />
                ) : (
                  <div />
                )}
                {nextPageAvailable ? (
                  <Button
                    size="small"
                    content="Next page"
                    onClick={() =>
                      setChallengeSearchState((prevState) => ({
                        ...prevState,
                        page: prevState.page + 1,
                      }))
                    }
                  />
                ) : null}
              </div>
            ) : null}
          </Accordion.Content>
        </Accordion>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(IdeaTemplateChooser);

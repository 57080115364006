import util from "./utils";

const locations = [
  {
    title: "Home",
    keywords: ["feed", "dashboard", "activity"],
    location: "",
    icon: "home",
    pages: [
      {
        title: "Admin",
        permissionNeeded: "org.viewDashboard",
        keywords: ["organisation"],
        location: "admin",
        icon: "warehouse",
        pages: [
          {
            title: "Activity",
            keywords: ["activity", "stats"],
            location: "activity",
            icon: "area graph",
            pages: [
              {
                title: "Dashboards",
                keywords: ["stats"],
                location: "dashboards",
                icon: "chart line",
              },
            ],
          },
          {
            title: "Users",
            keywords: ["people"],
            location: "users",
            icon: "group",
          },
          {
            title: "Challenges",
            location: "challenges",
            icon: "target",
          },
          {
            title: "Communication",
            location: "communication",
            icon: "bullhorn",
            pages: [
              {
                title: "Announcements",
                keywords: ["announcements"],
                location: "announcements",
                icon: "bullhorn",
              },
            ],
          },
          {
            title: "Uploads",
            keywords: ["file"],
            location: "uploads",
            icon: "upload",
          },
          {
            title: "Polls",
            location: "polls",
            icon: "lightning",
          },
          {
            title: "Links",
            keywords: ["domain"],
            location: "links",
            icon: "linkify",
          },
          {
            title: "Settings",
            keywords: ["preferences", "name", "language", "permissions", "onboarding"],
            location: "settings",
            icon: "cogs",
            pages: [
              {
                title: "Branding",
                keywords: ["colour", "logo", "text", "icon"],
                location: "branding",
                icon: "paint brush",
              },
              {
                title: "Domains",
                keywords: ["mail", "site"],
                location: "domains",
                icon: "globe",
              },
              {
                title: "Rules",
                keywords: ["automation"],
                location: "rules",
                icon: "chess",
              },
              {
                title: "Integrations",
                keywords: ["power", "power bi", "creditsafe", "microsoft", "webhook"],
                location: "integrations",
                icon: "chess",
              },
              {
                title: "Sign-on",
                keywords: ["login", "register", "sign-up", "signon", "auth", "sso", "oauth", "saml"],
                location: "signon",
                icon: "user plus",
              },
            ],
          },
        ],
      },
      {
        title: "Account",
        keywords: ["preferences", "settings", "log", "language"],
        location: "preferences",
        icon: "cogs",
        pages: [
          {
            title: "Profile",
            keywords: [
              "name",
              "avatar",
              "cover",
              "video",
              "overview",
              "tagline",
              "social",
              "website",
              "tags",
              "uploads",
            ],
            location: "profile",
            icon: "address card",
          },
          {
            title: "Email addresss",
            location: "email",
            icon: "at",
          },
          {
            title: "Password",
            keywords: ["multi", "mfa"],
            location: "password",
            icon: "lock",
          },
          {
            title: "Notifications",
            keywords: ["notify", "email", "push"],
            location: "notifications",
            icon: "bell",
          },
          {
            title: "Connected Services",
            keywords: ["api"],
            location: "connections",
            icon: "plug",
          },
        ],
      },
      {
        title: "Challenges",
        keywords: ["ideas"],
        location: "challenges",
        icon: "star",
      },
      {
        title: "Ideas",
        keywords: ["challenges"],
        location: "ideas",
        icon: "lightbulb",
      },
    ],
  },
];

// Recursive function to build link for spotlight search
const getPath = (user, query, link = "", path = locations, results = []) => {
  for (let i = 0; i < path.length; i += 1) {
    if (results.length > 2) break;
    const subPath = path[i];
    const { title, location: subLocation, pages = [], icon = "", keywords = [], permissionNeeded } = subPath;
    if (
      !permissionNeeded ||
      (permissionNeeded && util.hasPermission(user, permissionNeeded, user.ownerOrganisation._id))
    ) {
      const updatedLink = link ? `${link}/${subLocation}` : subLocation;
      const titleLower = title.toLowerCase().trim();
      const queryLower = query.toLowerCase().trim();
      const queryInKeywords = keywords.find((k) => k.indexOf(queryLower) > -1);
      if (queryInKeywords || titleLower.indexOf(queryLower) > -1) {
        results.push({ title, link: updatedLink, icon });
      }
      getPath(user, query, updatedLink, pages, results);
    }
  }
  return results;
};

export { getPath, locations };

export default locations;

import { LinkChooser } from "components/lib/Choosers";

export const getMinimalMenu = (chooser, setChooser) => [
  {
    name: "link",
    icon: "linkify",
    onClick: ({ commands, isActive, selectedText }) => {
      if (isActive) {
        commands.removeLink();
      } else {
        setChooser("link", !chooser.link?.open, commands.createCustomLink, selectedText);
      }
    },
    MenuItem: ({ RenderItem, itemProps }) => (
      <LinkChooser
        trigger={
          <div>
            <RenderItem {...itemProps} />
          </div>
        }
        isOpen={chooser.link?.open}
        shouldFix={false}
        selectedText={chooser.link?.text}
        onClose={() => setChooser("link", false)}
        onComplete={(text, url) => {
          chooser.link.cmd({ text, href: url });
          setChooser("link", false);
        }}
      />
    ),
  },
];

import React from "react";
import ExternalLinkModal from "components/lib/ExternalLinkModal";
import Remirror from "./Remirror";

export class BasicRichText extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      followExternalLink: null,
    };

    this.timeout = null;
  }

  render() {
    const { followExternalLink } = this.state;
    const { settings, ...otherProps } = this.props;

    return (
      <>
        <ExternalLinkModal
          open={followExternalLink !== null}
          href={followExternalLink}
          onClose={() => {
            this.setState({ followExternalLink: null });
          }}
          onFollow={() => {
            this.setState({ followExternalLink: null });
          }}
        />
        <Remirror
          settings={{
            ...settings,
            onExternalLink: (href) => {
              this.setState({ followExternalLink: href });
            },
          }}
          {...otherProps}
        />
      </>
    );
  }
}

import React, { useCallback } from "react";
import { Modal, Table, Button } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import util from "utils/utils";
import { IExistingAssessor, IPotentialAssessor, IModalAction, TableHeaderItem } from ".";
import { useTranslation } from "react-i18next";
import { ImageWithFallback } from "components/lib/ImageWithFallback";

type IAssessorModal = {
  isOpen: boolean;
  onClose: () => void;
  assessor?: IExistingAssessor | IPotentialAssessor;
  existingIdeas?: Other.IIdea[];
  potentialIdeas?: Other.IIdea[];
  getAssessorActions: (assessor: IExistingAssessor | IPotentialAssessor, idea: Other.IIdea) => IModalAction[];
};

/*
  This modal can be used for existing/potential assessors but not pending invitations, due to differences in data structures
*/
const AssessorModal = ({
  isOpen,
  onClose,
  assessor,
  existingIdeas = [],
  potentialIdeas = [],
  getAssessorActions,
}: IAssessorModal) => {
  const { t } = useTranslation();

  const IdeaRow = useCallback(
    (idea) => (
      <Table.Row key={idea._id}>
        <Table.Cell>
          <TableHeaderItem as="h4" style={{ fontWeight: "400" }}>
            <ImageWithFallback avatar src={util.ideaCoverImage(idea)} fallbackSrc={util.ideaCoverImage()} />
            {idea.name}
          </TableHeaderItem>
        </Table.Cell>
        <Table.Cell collapsing>
          {getAssessorActions(assessor, idea).map((action) => (
            <Button
              key={assessor._id}
              size="tiny"
              content={action.content}
              icon={action.icon}
              loading={action.loading}
              onClick={action.onClick}
            />
          ))}
        </Table.Cell>
      </Table.Row>
    ),
    [assessor, getAssessorActions],
  );

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={isOpen}
      onClose={onClose}
      size="fullscreen"
    >
      <Modal.Header>{assessor?.profile.fullName}</Modal.Header>
      <Modal.Content>
        <Table basic="very">
          {potentialIdeas.length && assessor ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Selected local {t("generic.ideas")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{potentialIdeas.map((idea) => IdeaRow(idea))}</Table.Body>
            </>
          ) : null}

          {existingIdeas.length && assessor ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">Previously assigned {t("generic.ideas")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{existingIdeas.map((idea) => IdeaRow(idea))}</Table.Body>
            </>
          ) : null}
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Done" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export default AssessorModal;

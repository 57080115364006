import React from "react";
import { Trans } from "react-i18next";

const ChallengeClosed = ({ ownerChallenge = { name: "Undefined" } }) => {
  return (
    <Trans i18nKey="notifications.challengeClosed">
      A challenge you are following,
      <b>{{ challenge: ownerChallenge.name }}</b>, is now closed
    </Trans>
  );
};

export default ChallengeClosed;

import React from "react";
import CardView from "./CardView";
import ListView from "./ListView";

const IdeaCard = ({ listView: showList, ...props }) => {
  if (showList) return <ListView {...props} />;
  return <CardView {...props} />;
};

export default IdeaCard;

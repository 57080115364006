import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Loader, Grid, Label, Divider, Checkbox, Button } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import api from "api";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import styled from "styled-components";
import toast from "react-hot-toast";
import actions from "actions";
import useTheme from "theme/useTheme";

export const OnboardingContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ $isPreview }) =>
    $isPreview &&
    `
    margin: 1rem;
  `}
  p {
    font-size: 1.15rem;
  }
  .progress-labels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    > .ui.label {
      margin: 0 0.5rem;
    }
  }
  .agree-to-terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
  }
`;

const ProgressLabel = ({ isActive, colour }) => (
  <Label
    empty
    circular
    size="large"
    color={!isActive ? "grey" : null}
    style={{ backgroundColor: isActive && colour }}
  />
);

export const OnboardingFrame = ({
  user,
  organisationId,
  onboardingData,
  activePage,
  setActivePage,
  completing,
  completeOnboarding,
  isPreview,
}) => {
  const [userHasAgreedToScreen, setUserHasAgreedToScreen] = useState(false);
  const currentScreenData = useMemo(() => onboardingData.screens[activePage], [onboardingData.screens, activePage]);
  const userCanProgress = useMemo(
    () => !currentScreenData.requireUserAgreement || (currentScreenData.requireUserAgreement && userHasAgreedToScreen),
    [currentScreenData, userHasAgreedToScreen],
  );
  const theme = useTheme();

  useEffect(() => {
    setUserHasAgreedToScreen(false);
  }, [currentScreenData]);

  const nextPageAvailable = activePage < onboardingData.screens.length - 1;
  const prevPageAvailable = activePage !== 0;
  return (
    <Grid.Column computer={isPreview ? 16 : 10} tablet={isPreview ? 16 : 14}>
      <h1>
        <span role="img" aria-label="Wave">
          👋
        </span>{" "}
        Hi{user?.profile ? `, ${user.profile.fullName}` : ""}
      </h1>
      <Divider />
      <RichTextViewer forType="onboardings" forId={organisationId} content={currentScreenData.content} />
      {currentScreenData.requireUserAgreement ? (
        <div className="agree-to-terms">
          <Checkbox
            checked={userHasAgreedToScreen}
            onClick={() => setUserHasAgreedToScreen(!userHasAgreedToScreen)}
            label="I have read and agree to the above terms."
          />
        </div>
      ) : null}
      <div className="progress-labels">
        {prevPageAvailable ? (
          <Button
            labelPosition="left"
            icon="chevron left"
            content="Prev"
            onClick={() => setActivePage((prevPage) => prevPage - 1)}
          />
        ) : null}
        {nextPageAvailable ? (
          <Button
            labelPosition="right"
            icon="chevron right"
            content="Next"
            primary
            onClick={() => setActivePage((prevPage) => prevPage + 1)}
            disabled={!userCanProgress}
          />
        ) : null}
        {!nextPageAvailable && !isPreview ? (
          <Button
            labelPosition="right"
            icon="flag checkered"
            content="Finish"
            primary
            onClick={completeOnboarding}
            loading={completing}
            disabled={!userCanProgress}
          />
        ) : null}
      </div>
      <div className="progress-labels">
        {onboardingData.screens.map((screen, index) => (
          <ProgressLabel isActive={activePage === index} colour={theme?.primaryColour} key={index} />
        ))}
      </div>
    </Grid.Column>
  );
};

const CustomOnboarding = ({ user, isPreview, trackOnboardingCompletion }) => {
  const [organisationOnboarding, setOrganisationOnboarding] = useState({
    screens: [],
    isEnabled: false,
  });
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(0);
  const [completing, setCompleting] = useState(false);
  const organisationId = user?.ownerOrganisation?._id;
  const theme = useTheme();

  const getOnboarding = useCallback(() => {
    setLoading(true);
    api.organisations.getOnboarding(
      organisationId,
      ({ onboardingData, found }) => {
        if (!found) setLoading(false);
        else {
          setOrganisationOnboarding(onboardingData);
          setLoading(false);
        }
      },
      (err) => {
        toast.error(err.message);
        setLoading(false);
      },
    );
  }, [organisationId]);

  const completeOnboarding = useCallback(() => {
    setCompleting(true);
    api.organisations.trackOnboardingCompletion(
      organisationId,
      () => {
        trackOnboardingCompletion();
        setCompleting(false);
      },
      (err) => {
        toast.error(err.message);
        setCompleting(false);
      },
    );
  }, [organisationId, trackOnboardingCompletion]);

  useEffect(() => getOnboarding(), [getOnboarding]);

  if (loading) {
    return <Loader active />;
  }
  if (!organisationOnboarding.screens.length) return null;
  return (
    <OnboardingContainer $isPreview={isPreview}>
      {!isPreview ? <Helmet title={`Welcome to ${user.ownerOrganisation.name}`} /> : null}
      <Grid stackable centered style={isPreview ? {} : { marginTop: 40, marginBottom: 20 }}>
        <OnboardingFrame
          user={user}
          organisationId={organisationId}
          onboardingData={organisationOnboarding}
          activePage={activePage}
          setActivePage={setActivePage}
          completing={completing}
          completeOnboarding={completeOnboarding}
          isPreview={isPreview}
          theme={theme}
        />
      </Grid>
    </OnboardingContainer>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  trackOnboardingCompletion: () => dispatch(actions.user.trackOnboardingCompletion()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomOnboarding);

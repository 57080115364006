import React from "react";
import { RichTextField } from "components/lib/Editors/RichText";
import RichTextViewer from "components/lib/Editors/RichTextViewer";

const RichText = ({ ideaId, field, fieldValue = "", updateIdea, isEditing, inputRef, handleKeyDown }) => {
  if (!isEditing) return <RichTextViewer content={fieldValue} forType="idea" forId={ideaId} />;
  return (
    <RichTextField
      forType="idea"
      forId={ideaId}
      value={fieldValue}
      updateIdea={updateIdea}
      field={{ id: field.id }}
      wordLimit={field?.wordLimit}
      editorRef={inputRef}
      handleKeyDown={handleKeyDown}
    />
  );
};

export default RichText;

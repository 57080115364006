import React, { useCallback, useState, useMemo, cloneElement, ReactElement } from "react";
import { Modal, Button } from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "store";
import { Other } from "simplydo/interfaces";
import actions from "actions";
import util from "utils/utils";
import { useTranslation } from "react-i18next";

type IdeaLikeHandlerProps = {
  idea: Other.IIdea;
  trigger: ReactElement;
  onLikeIdea: (ideaId: string, addingLike: boolean) => void;
  challenge: Other.IChallenge;
};

const IdeaLikeHandler = ({ idea, onLikeIdea, challenge, trigger }: IdeaLikeHandlerProps) => {
  const user: Other.IUserMe = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const ideaId = idea._id;
  const hasVotedForIdea = idea.votedFor;

  const { t } = useTranslation();

  const hasSeenIdeaLikeInformationModalThisSession = useAppSelector(
    (state) => state.organisations.hasSeenIdeaLikeInformationModalThisSession,
  );
  const [modalOpen, setModalOpen] = useState(false);

  const shouldShowLikeNotificationModal = useMemo(
    () =>
      !util.ideaMeetsChallengeVisibilityLimits(idea, challenge) &&
      (util.canViewEntireChallenge(user, challenge) || util.hasPermission(user, "challenge.viewIdeas", challenge?._id)),
    [challenge, user, idea],
  );

  const onClickLikeButton = useCallback(() => {
    if (shouldShowLikeNotificationModal && !hasSeenIdeaLikeInformationModalThisSession && !hasVotedForIdea) {
      setModalOpen(true);
    } else {
      onLikeIdea(ideaId, !hasVotedForIdea);
    }
  }, [
    shouldShowLikeNotificationModal,
    hasSeenIdeaLikeInformationModalThisSession,
    onLikeIdea,
    ideaId,
    hasVotedForIdea,
  ]);

  const onClickModalLikeButton = useCallback(() => {
    dispatch(actions.organisations.setHasSeenIdeaLikeInformationModalThisSession(true));
    onLikeIdea(ideaId, !hasVotedForIdea);
    setModalOpen(false);
  }, [dispatch, onLikeIdea, ideaId, hasVotedForIdea]);

  const onCloseModal = useCallback(() => {
    dispatch(actions.organisations.setHasSeenIdeaLikeInformationModalThisSession(true));
    setModalOpen(false);
  }, [dispatch]);

  return (
    <>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>{t("common:capitalise", { key: "generic.idea" })} Like Information</Modal.Header>
        <Modal.Content>
          When you "like" {t("generic.ideaWithArticle")} in this {t("generic.challenge")}, the author(s) of the{" "}
          {t("generic.idea")}
          will be notified via email and/or push notification. Your name will be visible to the author(s) of the{" "}
          {t("generic.idea")}, and they will be able to see that you liked their {t("generic.idea")}. You are seeing
          this message because you have special permission to view {t("generic.ideas")} in this {t("generic.challenge")}
          , and this {t("generic.challenge")} only allows{" "}
          {challenge.ideaVisibilityLimits?.length
            ? `users to see ${t("generic.ideas")} that are ${challenge.ideaVisibilityLimits.join("/")}`
            : `people with special permissions to see ${t("generic.ideas")}`}
          .
        </Modal.Content>
        <Modal.Actions>
          <Button content={`Cancel and don't like ${t("generic.idea")}`} onClick={onCloseModal} />
          <Button
            content={`I understand and still want to like this ${t("generic.idea")}`}
            onClick={onClickModalLikeButton}
            primary
            icon="thumbs up"
          />
        </Modal.Actions>
      </Modal>

      {cloneElement(trigger, { onClick: onClickLikeButton })}
    </>
  );
};

export default IdeaLikeHandler;

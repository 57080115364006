import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import util from "utils/utils";

const NotFound = ({ user, t }) => (
  <Container style={{ marginTop: 40 }}>
    <Grid stackable centered>
      <Grid.Column computer={8}>
        <h1>Page not found</h1>
        <p>
          Sorry, the page you have requested could not be found. If you have arrived here by following a link from
          elsewhere on
          {util.appName()} then please let us know by emailing{" "}
          <a href="mailto:hello@simplydo.co.uk">hello@simplydo.co.uk</a>.
        </p>
      </Grid.Column>
      <Grid.Column computer={1} />
      <Grid.Column computer={6}>
        <h2>{t("home.quickLinks")}</h2>
        <p>
          <Link className="with-border" to="/challenges">
            {t("generic.challenges")}
          </Link>
        </p>
        <p>
          <Link className="with-border" to="/ideas">
            {t("generic.ideas")}
          </Link>
        </p>
        {util.hasPermission(user, "org.viewDashboard", user && user.ownerOrganisation._id) && (
          <p>
            <Link to="/admin" className="with-border">
              {t("generic.admin")}
            </Link>
          </p>
        )}
      </Grid.Column>
    </Grid>
  </Container>
);

const mapStateToProps = (state) => ({ user: state.user });

const NotFoundContainer = connect(mapStateToProps)(NotFound);

export default withTranslation()(NotFoundContainer);

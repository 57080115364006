import api from "api";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";

export type UploadState = {
  isUploading: boolean;
  uploadProgress: number;
};

export type OnCompleteParams = {
  savedName: string;
  fileName: string;
  signedDownloadRequest: string;
};

export const allowedTypes = {
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  pdf: "application/pdf",
  csv: "text/csv",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  txt: "text/plain",
  xml: "application/xml",
  xml_text: "text/xml",
  odt: "application/vnd.oasis.opendocument.text",
  ods: "application/vnd.oasis.opendocument.spreadsheet",
  odp: "application/vnd.oasis.opendocument.presentation",
  odg: "application/vnd.oasis.opendocument.graphics",
  jpg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  tiff: "image/tiff",
  svg: "image/svg+xml",
  mp4: "video/mp4",
  mov: "video/quicktime",
  avi: "video/x-msvideo",
  mkv: "video/x-matroska",
  m2ts: "video/avchd-stream",
  flv: "video/x-flv",
};

export const verifyType = (accept: string, type: string) => {
  if (accept === "image/*") {
    if (type.indexOf("image") !== 0) {
      toast.error("Only images are accepted");
      return false;
    }
  } else {
    const videoType = type.replace("video/", ".");
    const acceptedTypes = accept.split(",");
    if (acceptedTypes.indexOf(videoType) === -1) {
      toast.error("Only videos are accepted");
      return false;
    }
  }
  return true;
};

export const uploadFile = (
  file: File | null,
  setUploadState: Dispatch<SetStateAction<UploadState>> = () => {},
  forType: string,
  forId: string,
  onComplete = (..._params) => {},
  onError,
  setOpen: Dispatch<SetStateAction<boolean>> = () => {},
) => {
  if (file) {
    setUploadState((prevState) => ({ ...prevState, isUploading: true, uploadProgress: 1 }));
    const regex = new RegExp("[^a-zA-Z0-9_.]", "g");
    const name = file.name.replace(regex, "-");
    api.uploads.generateFileUploadRequest(
      {
        forType,
        forId,
        name,
        size: file.size,
        type: file.type,
      },
      (response) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", response.signedRequest);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.upload.addEventListener("progress", (e) => {
          if (e.lengthComputable) {
            setUploadState((prevState) => ({ ...prevState, uploadProgress: ((e.loaded * 100) / e.total, 10) }));
          }
        });
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            setUploadState((prevState) => ({ ...prevState, isUploading: false, uploadProgress: 100 }));
            if (xhr.status === 200) {
              setOpen(false);
              onComplete(response.fileName, name, response.signedDownloadRequest);
            } else if (onError) {
              onError("Unable to upload file");
            }
          }
        };
        xhr.send(file);
      },
      (err) => {
        setUploadState((prevState) => ({ ...prevState, isUploading: false, uploadProgress: 0 }));
        if (onError) onError(err.message || "Unable to upload file");
        else toast.error(err.message);
      },
    );
  }
};

export const uploadMultiFiles = (
  uploadFiles: File[],
  setUploadState: Dispatch<SetStateAction<UploadState>> = () => {},
  forType: string,
  forId: string,
  onCompleteMultiple = (..._params) => {},
  onError,
  setOpen: Dispatch<SetStateAction<boolean>> = () => {},
) => {
  setUploadState((prevState) => ({ ...prevState, isUploading: true, uploadProgress: 1 }));
  const regex = new RegExp("[^a-zA-Z0-9_.]", "g");
  const renamedFiles = Array.from(uploadFiles).map((file) => {
    const name = file.name.replace(regex, "-");
    return {
      name,
      size: file.size,
      type: file.type,
      forType,
      forId,
    };
  });

  api.uploads.generateMultiFileUploadRequest(
    renamedFiles,
    (response) => {
      const { files = [] } = response;
      const uploaders = files.map((file, index) => {
        return new Promise((resolve, reject) => {
          const { request, type } = file;
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", request.signedRequest);
          xhr.setRequestHeader("Content-Type", type);
          xhr.upload.addEventListener("progress", (e) => {
            if (e.lengthComputable) {
              setUploadState((prevState) => ({ ...prevState, uploadProgress: ((e.loaded * 100) / e.total, 10) }));
            }
          });
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve({
                  fileName: request.fileName,
                  signedDownloadRequest: request.signedDownloadRequest,
                });
              } else {
                reject("Unable to upload file");
              }
            }
          };
          xhr.send(uploadFiles[index]);
        });
      });
      Promise.all(uploaders)
        .then((results: OnCompleteParams[]) => {
          setUploadState((prevState) => ({ ...prevState, isUploading: false, uploadProgress: 100 }));
          setOpen(false);
          onCompleteMultiple(
            results.map((result, index) => ({
              fileSize: renamedFiles[index].size,
              savedName: result.fileName,
              fileName: renamedFiles[index].name,
              signedDownloadRequest: result.signedDownloadRequest,
            })),
          );
        })
        .catch((err) => {
          setUploadState((prevState) => ({ ...prevState, isUploading: false, uploadProgress: 0 }));
          if (onError) onError(err.message || "Unable to upload file");
          else toast.error(err.message);
        });
    },
    (err) => {
      setUploadState((prevState) => ({ ...prevState, isUploading: false, uploadProgress: 0 }));
      if (onError) onError(err.message || "Unable to upload file");
      else toast.error(err.message);
    },
  );
};

import React, { useState, useCallback, useEffect } from "react";
import { Icon, Label, Button, Container, Input, Table, Loader, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import moment from "moment";
import util from "utils/utils";
import api from "api";
import actions from "actions";
import styled, { withTheme } from "styled-components";

import { Banner, EmptyBox } from "components/lib/UI";
import { UserChip } from "components/lib/Chips";
import useThrottle from "utils/useThrottle";

const BannerContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.sizes.isComputer ? "row" : "column")};
  justify-content: ${({ theme }) => (theme.sizes.isComputer ? "space-between" : "centre")};
  align-items: ${({ theme }) => (theme.sizes.isComputer ? "flex-end" : "centre")};
  h1,
  a {
    color: #fff !important;
    display: block;
  }
  h1 {
    margin: 0 0 10px 0;
  }
  a {
    font-size: 14px;
    margin: ${({ theme }) => (theme.sizes.isMobile ? "1rem 0" : "0")};
  }
  .ui.input {
    width: 300px;
  }
`;

const NavigationArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  span {
    display: block;
    font-weight: 700;
  }
`;

const MessageRow = styled(Table.Row)`
  cursor: pointer;
  &:hover {
    background-color: #e9ebee;
  }
`;

const MessageCentre = ({ user, t, theme, onMessageThreadSelected }) => {
  const [queryState, setQueryState] = useState({
    page: 1,
    query: "",
  });
  const [messageState, setMessageState] = useState({
    messageThreads: [],
    nextPageAvailable: false,
    previousPageAvailable: false,
    total: 0,
  });
  const [messagesLoading, setMessagesLoading] = useState(false);
  const { page, query } = queryState;

  const fetchThreads = useThrottle(
    (nextPage, nextQuery) => {
      api.messages.getThreads(
        { page: nextPage, query: nextQuery },
        (data) => {
          setMessageState(data);
          setMessagesLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setMessagesLoading(false);
        },
      );
    },
    400,
    [],
  );

  const getThreads = useCallback(
    (nextPage, nextQuery) => {
      setMessagesLoading(true);
      setQueryState({ page: nextPage, query: nextQuery });
      fetchThreads(nextPage, nextQuery);
    },
    [fetchThreads],
  );

  useEffect(() => {
    getThreads();
  }, [getThreads]);

  const { messageThreads, total } = messageState;

  return (
    <>
      <Banner marginless>
        <Container>
          <BannerContainer>
            <div>
              <h1>{t("messages.title")}</h1>
              <Input
                loading={messagesLoading}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                placeholder={t("messages.searchPlaceholder")}
                size="small"
                onChange={(e) => getThreads(1, e.target.value)}
                value={query}
              />
            </div>
            <Button compact secondary as={Link} to="/preferences/notifications">
              <Icon name="settings" />
              {t("messages.updateSettings")}
            </Button>
          </BannerContainer>
        </Container>
      </Banner>
      <Container style={{ marginTop: 20 }}>
        {messageThreads && messageThreads.length > 0 ? (
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Chat</Table.HeaderCell>
                <Table.HeaderCell>With</Table.HeaderCell>
                {!theme.sizes.isMobile && <Table.HeaderCell>{t("messages.table.lastMessage")}</Table.HeaderCell>}
                <Table.HeaderCell>{t("messages.table.received")}</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {messageThreads.map((thread) => {
                const { lastMessageAt, lastMessageSnippet = "" } = thread;

                const otherUsers = thread.participantUsers.filter((u) => u._id !== user._id);

                return (
                  <MessageRow key={thread._id} onClick={() => onMessageThreadSelected(thread, !theme.sizes.isComputer)}>
                    <Table.Cell collapsing>
                      {thread.seenBy && thread.seenBy.indexOf(user?._id) === -1 && (
                        <Label
                          style={{
                            marginRight: 7,
                            display: theme.sizes.isMobile && "block",
                            maxWidth: theme.sizes.isMobile && "fit-content",
                          }}
                          size="tiny"
                          color="teal"
                        >
                          New
                        </Label>
                      )}
                      {util.getChatName(user, thread)}
                    </Table.Cell>
                    <Table.Cell collapsing>
                      {otherUsers.slice(0, 3).map((p) => (
                        <UserChip key={p._id} user={p} compact />
                      ))}
                      {otherUsers.length > 3 && <Label size="tiny">+{otherUsers.length - 3} others</Label>}
                    </Table.Cell>
                    {!theme.sizes.isMobile && (
                      <Table.Cell>
                        <div
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 2,
                          }}
                        >
                          "{lastMessageSnippet}"
                        </div>
                      </Table.Cell>
                    )}

                    <Table.Cell collapsing>{lastMessageAt && moment(lastMessageAt).fromNow()}</Table.Cell>
                    <Table.Cell collapsing>
                      <Button
                        basic
                        secondary
                        size="small"
                        content={t("messages.table.view")}
                        onClick={() => onMessageThreadSelected(thread, theme.sizes.isMobile)}
                      />
                    </Table.Cell>
                  </MessageRow>
                );
              })}
            </Table.Body>
          </Table>
        ) : (
          <>{messagesLoading ? <Loader active /> : <EmptyBox title={t("messages.empty")} />}</>
        )}
        <NavigationArea>
          <span>{util.pluralise(total, "thread", "total threads")}</span>
          <Pagination
            activePage={page}
            totalPages={Math.ceil(total / 10)}
            onPageChange={(_, { activePage }) => getThreads(activePage, query)}
          />
        </NavigationArea>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user, selectedMessageThread: state.messages.selectedMessageThread });

const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (thread, isPhone = false) => dispatch(actions.messages.selectThread(thread, isPhone)),
  onMessageThreadsReceived: (threads) => dispatch(actions.messages.extendThreads(threads)),
});

export default withTheme(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MessageCentre)));

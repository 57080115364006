import React from "react";

import { Modal, Button, Divider } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

const ExternalLinkModal = ({ t, open, href, onClose, onFollow }) => (
  <Modal mountNode={document.getElementById("semantic-modal-mount-node")} small open={open} onClose={onClose}>
    <Modal.Header>Warning</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        You are about to leave the safety of the Simply Do platform to follow an external link. Please make sure that
        you can trust the linked resource before proceeding.
        <br />
        <br />
        Please make sure to never enter your login information anywhere but on the Simply Do platform.
      </Modal.Description>
      <Divider />
      <Modal.Description>
        <b>{href}</b>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button
        content="Open link in a new tab"
        secondary
        onClick={() => {
          window.open(href, "_blank");
          onFollow();
        }}
      />
      <Button
        content={t("generic.cancel")}
        onClick={() => {
          onClose();
        }}
      />
    </Modal.Actions>
  </Modal>
);

export default withTranslation()(ExternalLinkModal);

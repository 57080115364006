import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";

import Idea from "components/ideas";
import Entry from "components/entry/Entry";
import UserUnsubscribe from "components/users/Unsubscribe";
import PublicChallenges from "components/challenges/PublicChallenges";
import Challenge from "components/challenges/Challenge";
import PersistentToken from "components/app/PersistentToken";
import CustomPage from "./CustomPage";
import { LoggedOutInvitation } from "components/users/Invitations";

const NonUserSwitch = ({ user, showLogin, gettingUserError }) => {
  if (!user && (showLogin || !gettingUserError)) {
    return (
      <Routes>
        <Route path="unsubscribe" element={<UserUnsubscribe />} />
        <Route path="invitations" element={<LoggedOutInvitation />} />
        <Route path="ideas/:id" element={<Idea />} />
        <Route path="challenges/public" element={<PublicChallenges />} />
        <Route path="challenges/:id/*" element={<Challenge />} />
        <Route path="join/:token" element={<PersistentToken />} />
        <Route path="custom-pages/:pageId" element={<CustomPage />} />
        <Route path="*" element={<Entry />} />
      </Routes>
    );
  }
  return null;
};

const mapStateToProps = ({ user, auth }) => ({ user, showLogin: auth.showLogin, gettingUserError: auth.errorMessage });

export default connect(mapStateToProps)(NonUserSwitch);

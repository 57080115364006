import React from "react";
import { Card, Icon, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import { withTranslation } from "react-i18next";

import util from "utils/utils";
import { IdeaCoverImage, IdeaInfo, IdeaMeta as GroupMeta } from "components/lib/Cards/IdeaCard/ListView/styles";

import { ShadowedCard } from "components/lib/UI";
import GroupJoinButton from "../GroupJoinButton";

const GroupInfo = styled(IdeaInfo)`
  flex: 1;
  max-height: 40px;
  ${({ $noBorder }) =>
    $noBorder &&
    `
    border-right: none;
  `}
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const CoverImage = styled(IdeaCoverImage)`
  background-color: ${({ theme }) => theme.secondaryColour};
`;

const GroupCardContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 7.5px;
  border-radius: 5px;
  height: 55px;
  flex: 1;
  h4 {
    margin: 0;
  }
  background-color: #fff;
  max-width: 100%;
  border: 1px solid #e9ebee;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  &:hover {
    background-color: #eeeff1;
  }
  a,
  p,
  h4 {
    color: #000;
  }
`;

const GroupCard = ({ group, theme }) => (
  <ShadowedCard>
    <Link to={`/groups/${group._id}`}>
      <div
        style={{
          width: "100%",
          height: "100px",
          backgroundImage: `url(${util.groupCoverImage(group)})`,
          backgroundColor: theme.secondaryColour,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          borderTopLeftRadius: 7,
          borderTopRightRadius: 7,
        }}
      />
    </Link>
    <Card.Content>
      <div className="ui header">
        <Link to={`/groups/${group._id}`}>{group.name}</Link>
      </div>
      {group.description && <p>{group.description}</p>}
    </Card.Content>
    <div
      className="content"
      style={{
        backgroundColor: theme.primaryColour,
        textAlign: "center",
        fontWeight: 600,
        maxHeight: 50,
      }}
    >
      <GroupJoinButton group={group} isCardView />
    </div>
  </ShadowedCard>
);

export const GroupCardListView = ({ group }) => (
  <GroupCardContainer as={Link} to={`/groups/${group._id}`}>
    <CoverImage $coverImage={util.groupCoverImage(group, "300x300")} $isGroup />
    <GroupInfo $noBorder={!group.isLeader} $isGroup>
      <div>
        <h4>{group.name}</h4>
      </div>
      {group.description ? (
        <div>
          <p>{group.description}</p>
        </div>
      ) : null}
    </GroupInfo>
    <GroupMeta $isGroup>
      {group.isLeader ? (
        <Label size="tiny" color="purple">
          <Icon name="rocket" />
          Leader
        </Label>
      ) : null}
    </GroupMeta>
  </GroupCardContainer>
);

export default withTranslation()(withTheme(GroupCard));

import React, { useCallback, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Icon, Input, Label, Button, Dropdown, Segment, Form, TextArea } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import styled from "styled-components";
import api from "api";
import util from "utils/utils";
import toast from "react-hot-toast";
import useThrottle from "utils/useThrottle";
import { useTranslation } from "react-i18next";

const StyledInput = styled(Input)`
  height: 37.5px;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledDropdown = styled(Dropdown)`
  .menu.right {
    left: auto;
    right: 0;
  }
`;

const IdeaImpactHeader = styled.div`
  > span {
    display: block;
    margin: 0;
  }
  .impact-name {
  }
  .impact-description {
    opacity: 0.7;
  }
`;

const StyledSegment = styled(Segment)`
  > .row-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const ImpactFrequencyOptions = [
  {
    key: "weekly",
    text: "Weekly",
    value: "weekly",
  },
  {
    key: "monthly",
    text: "Monthly",
    value: "monthly",
  },
  {
    key: "annually",
    text: "Annually",
    value: "annually",
  },
  {
    key: "one-off",
    text: "One-off",
    value: "one-off",
  },
];

type ImpactProps = {
  idea: Other.IIdea;
  onUpdateIdeaImpact: (impactMeasureId: string, data: Partial<Other.IIdeaImpact>) => void;
  deleteImpact: (impactId: string, impactMeasureId: string) => void;
  impact: Other.IIdeaImpact;
  onUpdateIdeaTotalImpacts?: (totalImpacts: Other.IIdeaImpact[]) => void;
  canEdit: boolean;
};

function Impact({
  idea,
  onUpdateIdeaImpact,
  impact,
  deleteImpact,
  onUpdateIdeaTotalImpacts = () => {},
  canEdit,
}: ImpactProps) {
  const ideaId = idea._id;
  const impactId = impact._id;
  const [impactData, setImpactData] = useState<Other.IIdeaImpact>(impact);
  const editTimeout = useRef(null);
  const { t } = useTranslation();
  const authToken = useMemo(() => {
    if (util.localStorageIsSupported()) {
      return localStorage.getItem(`ideaAuthToken:${ideaId}`);
    }
    return null;
  }, [ideaId]);

  const saveImpactChanges = useThrottle(
    (data: Partial<Other.IIdeaImpact>) => {
      api.ideas.updateImpactMeasure(
        impact.idea,
        impact.impactId,
        impactId,
        { ...data, authToken },
        ({ totalImpacts }) => {
          onUpdateIdeaTotalImpacts(totalImpacts);
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    400,
    [ideaId, impact.impactId, impactId],
  );

  const handleIdeaUpdate = useCallback(
    (data: Partial<Other.IIdeaImpact>) => {
      onUpdateIdeaImpact(impactId, data);
      if (editTimeout.current) {
        clearTimeout(editTimeout.current);
        editTimeout.current = null;
      }
      editTimeout.current = setTimeout(() => saveImpactChanges(data), 400);
    },
    [impactId, onUpdateIdeaImpact, saveImpactChanges],
  );

  const updateImpact = useCallback(
    (data) => {
      setImpactData((prev) => ({ ...prev, ...data }));
      saveImpactChanges(data);
      handleIdeaUpdate(data);
    },
    [saveImpactChanges, handleIdeaUpdate],
  );

  const formatGuidance = (icon) => {
    if (!icon) return;
    if (icon === "pound") return "Pounds";
    if (icon === "users") return "People";
    if (icon === "clock outline") return "Hours";
  };

  return (
    <StyledSegment key={impactId}>
      {impact.ownerIdea && idea._id !== impact.ownerIdea?._id ? (
        <Label style={{ marginBottom: 5 }}>This impact measure is shared from "{impact.ownerIdea.name}"</Label>
      ) : null}

      <div className="row-flex">
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Label style={{ display: "flex", alignItems: "center" }}>
            <Icon name={impact.ownerImpact?.icon ?? "tag"} size="big" />
            <IdeaImpactHeader>
              <span className="impact-name">{impact.ownerImpact.name}</span>
              <span className="impact-description">{impact.ownerImpact.description}</span>
            </IdeaImpactHeader>
          </Label>
          {canEdit ? (
            <Button
              icon="trash"
              size="small"
              basic
              onClick={() => deleteImpact(impact.impactId, impactId)}
              style={{ marginLeft: 5 }}
            />
          ) : null}
        </div>
        <div />
      </div>

      {impact.ownerImpact.type ? (
        <div className="row-flex">
          <div />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {impact.ownerImpact.type === "dropdown" ? (
              <>
                <StyledDropdown
                  search
                  selection
                  value={impactData.value}
                  onChange={(e, { value }) => {
                    updateImpact({ value });
                  }}
                  disabled={!canEdit}
                  options={(impact.ownerImpact?.options || []).map((option, idx) => ({
                    key: idx,
                    value: option,
                    text: option,
                  }))}
                  direction="right"
                />
              </>
            ) : (
              <StyledInput
                data-tooltip={formatGuidance(impact.ownerImpact.icon)}
                labelPosition="left"
                size="small"
                disabled={!canEdit}
                onChange={(e, { value }) => {
                  // @ts-ignore
                  if (!isNaN(value)) {
                    // @ts-ignore
                    value = Number(value);
                  }
                  updateImpact({ value });
                }}
                value={impactData.value}
              >
                <Label basic>
                  <Icon
                    name={impact.ownerImpact?.icon}
                    style={{ margin: 0, padding: 0, marginRight: impact.ownerImpact.type === "time" ? 5 : 0 }}
                  />
                  {impact.ownerImpact.type === "time" ? "Hours" : null}
                  {impact.ownerImpact.effect === "cost" ? "-" : null}
                </Label>
                <input type="number" style={{ width: 80 }} />
              </StyledInput>
            )}

            {impact.ownerImpact.type !== "dropdown" ? (
              <Dropdown
                compact
                disabled={!canEdit}
                selection
                value={impactData.frequency}
                onChange={(e, { value }) => updateImpact({ frequency: value })}
                options={ImpactFrequencyOptions}
                style={{ marginLeft: 5 }}
                placeholder="Impact frequency"
              />
            ) : null}
          </div>
        </div>
      ) : null}

      {impact.value && impact.frequency ? (
        <div className="row-flex">
          <div />
          <span style={{ display: "block" }}>
            This {t("generic.idea")} will {impact.ownerImpact?.effect === "cost" ? "cost" : "save"} {impact.value}{" "}
            {formatGuidance(impact.ownerImpact.icon)} {impact.frequency}
          </span>
        </div>
      ) : null}

      <Form>
        <TextArea
          disabled={!canEdit}
          value={impactData.justification}
          onChange={(e, { value }) => updateImpact({ justification: value })}
          placeholder="Justify this impact measure..."
          rows={2}
        />
      </Form>
    </StyledSegment>
  );
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(Impact);

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { Banner } from "components/lib/UI";
import actions from "actions";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  ${({ $noVerticalPadding }) => $noVerticalPadding && "padding-top: 0;"}
`;

const HeaderSpan = styled.div`
  color: #fff;
  font-weight: 700;
  opacity: 0.9;
`;

const HeaderMessage = styled.div`
  color: #fff;
  opacity: 0.8;
`;

function HomeHeader({ user, t, compact, onDashboardTextShow, onDashboardTextHidden, dashboardText }) {
  const theme = useContext(ThemeContext);
  return (
    <Banner compact={compact}>
      <HeaderContainer>
        <HeaderArea>
          <h1>{t("home.welcome", { user })}</h1>
        </HeaderArea>
        <ActionContainer>
          <div style={{ textAlign: theme.sizes.isMobile ? "center" : "right" }}>
            <Button
              name="discover"
              size={theme.sizes.isMobile ? "tiny" : "medium"}
              inverted
              content={t("home.getStarted.discoverChallenges")}
              as={Link}
              to="/challenges/discover"
              style={compact ? {} : { marginRight: 20, marginBottom: 10 }}
            />
          </div>
        </ActionContainer>
      </HeaderContainer>
      {dashboardText && (
        <HeaderContainer>
          <HeaderArea $noVerticalPadding>
            <HeaderSpan>
              Message from {user.ownerOrganisation.name}{" "}
              <span
                style={{
                  textDecorationLine: "underline",
                  cursor: "pointer",
                  fontSize: 12,
                  opacity: 0.6,
                }}
                onClick={user.hideDashboardText ? onDashboardTextShow : onDashboardTextHidden}
              >
                {user.hideDashboardText ? "(Show)" : "(Hide)"}
              </span>
            </HeaderSpan>
            {!user.hideDashboardText ? <HeaderMessage>{dashboardText}</HeaderMessage> : null}
          </HeaderArea>
        </HeaderContainer>
      )}
    </Banner>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  dashboardText: state.user.ownerOrganisation.dashboardText,
});

const mapDispatchToProps = (dispatch) => ({
  onDashboardTextHidden: () => dispatch(actions.user.hideDashboardText()),
  onDashboardTextShow: () => dispatch(actions.user.showDashboardText()),
});

const HomeHeaderContainer = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HomeHeader));

export default HomeHeaderContainer;

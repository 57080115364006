import PDFExportManager, { PDFExportBaseProps } from "./PDFExportManager";
import { useAppSelector } from "store";
import api from "api";
import util from "utils/utils";
import { OpenAPI } from "simplydo/interfaces";

type IdeaPDFExportProps = PDFExportBaseProps & {
  ideas: OpenAPI.Schemas["Idea"][];
  challenge?: OpenAPI.Schemas["Challenge"];
};

const IdeaPDFExport = (props: IdeaPDFExportProps) => {
  const user = useAppSelector((state) => state.user);

  const { ideas = [], pdfName, challenge, dropdownItems = [], ...rest } = props;

  const ideaIds = ideas.map((idea) => idea._id);

  const items = [...dropdownItems];
  if (challenge) {
    if (util.canManageChallenge(user, challenge) || util.hasPermission(user, "challenge.viewIdeas", challenge?._id)) {
      const ideaTemplate: OpenAPI.Schemas["IdeaTemplate"] =
        (challenge as any).ideaTemplateData ||
        (challenge as any).ownerIdeaTemplate ||
        (ideas.find((idea) => (idea as any).ideaTemplate) as any)?.ideaTemplate ||
        (ideas.find((idea) => (idea as any).ownerChallenge?.ownerIdeaTemplate) as any)?.ownerChallenge
          ?.ownerIdeaTemplate ||
        challenge.ideaTemplate;
      const hasCreditsafeReport = ideaTemplate?.body?.find((section) =>
        section?.fields?.find((field) => field.type === "creditsafereport"),
      );

      if (hasCreditsafeReport) {
        items.push({
          value: "creditsafe",
          text: "With Creditsafe report",
        });
      }
    }
  }

  return (
    <PDFExportManager
      onDownload={({ type }) => {
        const options: {
          appendCreditsafeReport?: "true";
        } = {};

        if (type === "creditsafe") {
          options["appendCreditsafeReport"] = "true";
        }
        return new Promise((resolve, reject) => {
          api.reports.exportIdeaPdfs(
            ideaIds,
            options,
            (result) => {
              resolve(result);
            },
            (e) => {
              reject(e);
            },
          );
        });
      }}
      forType="idea"
      pdfName={pdfName ?? "ideas.pdf"}
      dropdownItems={items}
      {...rest}
    />
  );
};

export default IdeaPDFExport;

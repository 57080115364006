import React, { useState, useEffect } from "react";
import { Loader, Button } from "semantic-ui-react";
import api from "api";

import ChallengeOverview from "components/challenges/Challenge/Overview";

const IdeaChallengeHelp = ({ closeHelp, challengeId }) => {
  const [loading, setLoading] = useState(false);
  const [challenge, setChallenge] = useState({});

  useEffect(() => {
    setLoading(true);
    api.challenges.getOverview(
      challengeId,
      {},
      (newChallenge) => {
        setChallenge(newChallenge);
        setLoading(false);
      },
      () => setLoading(false),
    );
  }, [challengeId]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Button icon="close" color="red" onClick={closeHelp} />
      </div>
      {loading ? <Loader active={loading} /> : <ChallengeOverview challenge={challenge} />}
    </div>
  );
};

export default IdeaChallengeHelp;

import React, { Component } from "react";
import { Message, Icon } from "semantic-ui-react";
import styled from "styled-components";

const VideoDisplayer = styled.div`
  width: 100%;
  .video-embedded,
  video {
    position: relative;
    height: 350px;
    width: 100%;
    transition: height 0.5s;
    -webkit-transition: height 0.5s;
    ${({ theme, $canExpand }) =>
      theme.sizes.isComputer && $canExpand
        ? `
      &:hover {
        height: 600px;
      }
    `
        : ""}

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .video-uploaded {
    display: block;
    width: 100%;
  }
`;

class Video extends Component {
  maybePlay = (video) => {
    if (!video) return;
    const v = video.toLowerCase();
    let type = "video/";
    if (v.indexOf(".mp4") > 0) type += "mp4";
    else if (v.indexOf(".avi") > 0) type += "avi";
    else if (v.indexOf(".mov") > 0) type += "quicktime";
    else type += "mp4";
    const videoElement = document.createElement("video");
    const canPlay = !!videoElement.canPlayType(type);
    return canPlay;
  };

  render() {
    const { src, force, canExpand } = this.props;
    if (!src) return null;
    const canPlay = this.maybePlay(src);
    return (
      <VideoDisplayer style={this.props.style} $canExpand={canExpand}>
        {src.indexOf("embed") === 0 ? (
          <div className="video-embedded">
            <iframe
              title="Video"
              style={{
                maxWidth: "120%",
                maxHeight: "400",
              }}
              src={src.replace("embed-", "")}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <div className="video-uploaded">
            {!force && src.toLowerCase().indexOf(".mp4") === -1 && (
              <Message size="small">
                <Icon name="asterisk" loading /> We're processing this video.{" "}
                {canPlay ? (
                  <span>The preview below may not yet work on your device.</span>
                ) : (
                  <span>Please save your changes and check back soon.</span>
                )}
              </Message>
            )}
            {(force || canPlay) && (
              <video controls preload="metadata" className="video-uploaded">
                <source src={src} type="video/mp4" />
                Your browser does not support this video.
              </video>
            )}
          </div>
        )}
      </VideoDisplayer>
    );
  }
}

export default Video;

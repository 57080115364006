import React from "react";
import { Segment, Icon, Loader, Dropdown, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import Checkbox from "components/lib/Forms/Checkbox";
import DiscoverImage from "src/images/discover.png";
import DiscoverChallengeCard from "./FeedCards/DiscoverChallenge";
import AchievementCard from "./FeedCards/Achievement";
import Comment from "./FeedCards/Comment";
import IdeaCard from "./FeedCards/Idea";
import SubmittedIdeaCard from "./FeedCards/SubmittedIdea";
import GroupDiscussionFeed from "./FeedCards/GroupDiscussionFeed";
import ChallSubmitCard from "./FeedCards/ChallSubmitCard";
import OrganisationPostCard from "./FeedCards/OrganisationPost";
import PostWidget from "./ActivityFeedPostWidget";

const ActivityFeed = (props) => {
  const {
    t,
    feed = [],
    show,
    loading,
    handleFeedType,
    feedOptions,
    extendFeed,
    canExtend,
    profileFeed,
    groupFeed,
    emptyFeedMessage,
    profileUser,
    hideAction,
    extending,
    canPost,
    onPost,
    onUpdatePost,
    onDeletePost,
  } = props;
  let emptyMessage;
  if (emptyFeedMessage) emptyMessage = emptyFeedMessage;
  else
    emptyMessage = profileFeed
      ? "This user has no activity to display"
      : "When you start to receive notifications, they'll appear here.";
  const feedType = (profileFeed && "Profile") || (groupFeed ? "Group" : "Activity");
  return (
    <React.Fragment>
      {feedOptions?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: 15,
          }}
        >
          <h4 className="primary-text" style={{ marginBottom: 0 }}>
            <Dropdown
              as="a"
              style={{
                flexDirection: "row",
                display: "flex",
                fontWeight: "bold",
                textTransform: "uppercase",
                alignItems: "center",
              }}
              size="tiny"
              basic
              direction="right"
              trigger={
                <div>
                  <Icon name="rss" />
                  {t("generic.activityFeed").toUpperCase()}
                </div>
              }
            >
              {feedOptions && feedOptions.length ? (
                <Dropdown.Menu>
                  {feedOptions.map((o) => (
                    <Dropdown.Item key={o.value}>
                      <Checkbox
                        compact
                        key={o.value}
                        checked={show.includes(o.value)}
                        label={o.text}
                        onChange={() => handleFeedType(o)}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              ) : null}
            </Dropdown>
          </h4>
        </div>
      )}

      {canPost && onPost && <PostWidget onPost={onPost} groupFeed={groupFeed} />}
      <Loader active={loading} />
      {!loading && (
        <>
          {feed.map((item, i) => {
            switch (item.type) {
              case "discoverChallenge":
                return <DiscoverChallengeCard key={i} challenge={item} hideAction={hideAction} feedType={feedType} />;
              case "achievement":
                return (
                  <AchievementCard
                    key={i}
                    achievement={item}
                    profileUser={profileUser}
                    hideAction={hideAction}
                    feedType={feedType}
                  />
                );
              case "ideaComment":
                return (
                  <Comment
                    key={i}
                    comment={item}
                    profileFeed={profileFeed}
                    profileUser={profileUser}
                    hideAction={hideAction}
                    feedType={feedType}
                  />
                );
              case "idea":
                return (
                  <IdeaCard
                    key={i}
                    idea={item}
                    profileFeed={profileFeed}
                    hideAction={hideAction}
                    groupFeed={groupFeed}
                    feedType={feedType}
                  />
                );
              case "submittedIdea":
                return (
                  <SubmittedIdeaCard
                    key={i}
                    idea={item}
                    profileFeed={profileFeed}
                    hideAction={hideAction}
                    feedType={feedType}
                  />
                );
              case "groupFeedComment":
                return <GroupDiscussionFeed key={i} comment={item} hideAction={hideAction} feedType={feedType} />;
              case "followedChallengeSubmittedIdea":
                return (
                  <ChallSubmitCard
                    key={i}
                    idea={item}
                    profileFeed={profileFeed}
                    hideAction={hideAction}
                    feedType={feedType}
                  />
                );
              case "organisationActivityFeedPost":
              case "groupActivityFeedPost":
                return (
                  <OrganisationPostCard
                    key={i}
                    post={item}
                    canPost={canPost}
                    onUpdatePost={onUpdatePost}
                    onDeletePost={onDeletePost}
                    isGroup={item.type === "groupActivityFeedPost"}
                  />
                );
              default:
                return null;
            }
          })}
          <div style={{ width: "100%" }}>
            {feed.length === 0 && (
              <Segment placeholder textAlign="center">
                <img
                  src={DiscoverImage}
                  style={{
                    maxHeight: 150,
                    maxWidth: "70%",
                    display: "block",
                    margin: "10px auto",
                  }}
                  alt="Discover"
                />
                <h2>{emptyMessage}</h2>
              </Segment>
            )}
            {extendFeed && canExtend && (
              <div
                style={{
                  justifyContent: "center",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  loading={extending}
                  content={t("activityFeed.extend")}
                  onClick={() => extendFeed()}
                  style={{ width: "40%" }}
                />
              </div>
            )}
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(ActivityFeed);

import FullPreset from "./full/full";
import { getFullMenu } from "./full/fullMenu";
import SimplePreset from "./simple/simple";
import { getSimpleMenu } from "./simple/simpleMenu";
import MinimalPreset from "./minimal/minimal";
import { getMinimalMenu } from "./minimal/minimalMenu";

export const presets = {
  minimal: {
    options: MinimalPreset,
    getMenu: getMinimalMenu,
  },
  simple: {
    options: SimplePreset,
    getMenu: getSimpleMenu,
  },
  full: {
    options: FullPreset,
    getMenu: getFullMenu,
  },
};

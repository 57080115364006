import React, { useState } from "react";
import { Modal, Button, Message, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { setupNotifications } from "utils/pushMessaging";

const PushSetupModal = ({ open, setOpen }) => {
  const [pushState, setPushState] = useState<typeof Notification.permission | "loading">(
    ("Notification" in window && Notification.permission) || "denied",
  );
  const { t } = useTranslation();

  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={open}>
      <Modal.Header>
        <Icon name="bell" />
        Enable offline notifications
      </Modal.Header>
      <Modal.Content>
        <h3>Why?</h3>
        <p>
          Right now we can only send you updates while you are working within the SimplyDo platform. If you want to
          receive notifications instantly and even when you are offline, you can enable push notifications now. We will
          only ever send you updates for content that is relevant to you. Additionally, you can choose to limit the kind
          of notifications you would like to receive in your settings, for this navigate to{" "}
          <strong>Account {"->"} Notifications</strong>.
        </p>

        <h3>How to?</h3>
        <p>
          Click the button below to get started. This will show you a popup at the top of your browser window. Press
          "Allow" (or equivalent) and we will be able to send you push notifications for any changes of your{" "}
          {t("generic.ideas")} and challenges in your organisation.
        </p>
        <Button
          disabled={pushState === "granted" || pushState === "denied"}
          loading={pushState === "loading"}
          content="Set up notifications now"
          primary
          onClick={() => {
            setPushState("loading");
            setupNotifications().finally(() => {
              setPushState(Notification.permission);
            });
          }}
        />
        {pushState === "granted" ? <Message success>Notifications are now enabled!</Message> : null}
        {pushState === "denied" ? (
          <Message warning>
            You have declined to receive push notifications. If you change your mind you can always change this in your
            browser settings.
          </Message>
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Done"
          onClick={() => {
            if (pushState === "default") {
              confirm("You did not enable push notifications. Are you sure you want to leave them disabled?") &&
                setOpen(false); // eslint-disable-line no-alert
            } else {
              setOpen(false);
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default PushSetupModal;

import React, { useEffect, useState, useCallback } from "react";
import websocketApi from "api/websocket";
import actions from "actions";
import { setupNotifications, supportsPush } from "utils/pushMessaging";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import styled from "styled-components";
import { ToastContainer, ToastIcon } from "components/lib/Toast";
import { Modal, Button } from "semantic-ui-react";
import { useReward } from "react-rewards";
import { useAppSelector } from "store";
import { Other, OpenAPI } from "simplydo/interfaces";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import ModalCarousel from "./lib/ModalCarousel";
import util from "utils/utils";

const StyledAchievementModal = styled.div`
  width: 450px;
  max-width: 90%;
  margin: 0 auto;
  > div {
    color: black;
    font-size: 1.3em;
    border-radius: 7.5px;
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .confetti-spawner {
      width: 1px;
      height: 1px;
      display: block;
    }
    .congratulations-title {
      font-weight: bold;
      color: gray;
      margin-bottom: 15px;
    }
    .achievement-icon {
      line-height: 80px;
      font-size: 80px;
      margin: 20px 0;
      position: relative;
    }
    .achievement-name {
      font-size: 1.3em;
      font-weight: bold;
      display: block;
      margin-bottom: 5px;
    }
    .achievement-description {
      font-size: 0.9em;
      color: #a5a5a5;
      display: block;
      margin-bottom: 5px;
    }
    .achievement-actions {
      display: flex;
      margin-top: 30px;
      width: 100%;
      justify-content: flex-end;
      .ui.button {
        margin-left: 5px;
      }
    }
  }
`;

const EventSubscriptions = ({ incrementNewMessageCount }) => {
  const dispatch = useDispatch();

  const user = useAppSelector((state) => state.user);
  const messageThreads = useAppSelector((state) => state.messages.messageThreads);
  const onMessageThreadSelected = useCallback((thread) => dispatch(actions.messages.selectThread(thread)), [dispatch]);
  const userId = user?._id;
  const navigate = useNavigate();

  const [activeAchievement, setActiveAchievement] = useState<Other.IAchievement>({
    name: "",
    description: "",
    icon: "",
    key: "",
  });
  const [achievementModalOpen, setAchievementModalOpen] = useState(false);

  const { reward } = useReward("achievementAwarded", "confetti", { lifetime: 200 });

  useEffect(() => {
    // @ts-ignore
    const achievementSubscription = websocketApi.subscribe(
      `unlockedAchievement-${userId}`,
      (achievement: Other.IAchievement) => {
        if (achievement.key) {
          setActiveAchievement(achievement);
          setAchievementModalOpen(true);
          reward();
        }
      },
    );
    return () => achievementSubscription.unsubscribe();
    // Including reward() in the dependencies currently causes this effect to run on every page change
    // Awaiting an update to react-rewards to change this; Currently it doesn't matter to include it for us as we only ever use a single configuration for the reward
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    // @ts-ignore
    const messageSubscription = websocketApi.subscribe(`newMessage-${userId}`, (message: Other.IMessage) => {
      if (message.message && message.thread) {
        const thread = messageThreads.find((t) => t._id === message.thread);
        toast((t) => (
          <ToastContainer
            onClick={() => {
              if (thread) onMessageThreadSelected(thread);
              toast.dismiss(t.id);
            }}
          >
            <div className="icon-container">
              <ToastIcon name="envelope" />
            </div>
            <div className="info-container">
              <span>
                <b>{message.user.profile.firstName}</b> sent you a message
              </span>
              <span className="meta">"{message.message}"</span>
            </div>
          </ToastContainer>
        ));
      }
    });
    return () => messageSubscription.unsubscribe();
  }, [messageThreads, onMessageThreadSelected, incrementNewMessageCount, userId]);

  const pushHandler = useCallback(
    (notification) => {
      if (supportsPush() && Notification.permission === "granted") {
        setupNotifications();
      }
      // We may manually choose to surpress some notifications; For example, direct messages are already shown as toasts, therefore we hide the double toast
      if (notification.title && !notification?.extra?.suppressInAppNotification) {
        toast((t) => (
          <ToastContainer
            onClick={() => {
              if (notification?.extra?.path) {
                navigate(notification?.extra?.path);
              }
              toast.dismiss(t.id);
            }}
          >
            <div className="icon-container">
              <ToastIcon name="bell" />
            </div>
            <div className="info-container">
              <span>{notification.title}</span>
              <span className="meta">{notification.message}</span>
            </div>
          </ToastContainer>
        ));
      }
    },
    [navigate],
  );

  useEffect(() => {
    const notificationSubscription = websocketApi.subscribe(`pushNotification-${userId}`, pushHandler);
    return notificationSubscription.unsubscribe;
  }, [pushHandler, userId]);

  const [fullScreenAnnouncements, setFullScreenAnnouncements] = useState<OpenAPI.Schemas["Announcement"][]>([]);
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
  useEffect(() => {
    // @ts-ignore
    const fullScreenAnnouncementSubscription = websocketApi.subscribe(
      `fullScreenAnnouncement-${userId}`,
      (announcement: OpenAPI.Schemas["Announcement"]) => {
        setFullScreenAnnouncements((prev) => [...prev, announcement]);
        setAnnouncementModalOpen(true);
      },
    );
    return () => fullScreenAnnouncementSubscription.unsubscribe();
  }, [userId]);

  return (
    <div>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        basic
        open={achievementModalOpen}
        onClose={() => setAchievementModalOpen(false)}
      >
        <StyledAchievementModal>
          <div>
            <span id="achievementAwarded" />
            <span className="congratulations-title">Congratulations, you unlocked an achievement!</span>
            <span
              role="img"
              aria-label={activeAchievement.name}
              className="achievement-icon"
              dangerouslySetInnerHTML={{ __html: activeAchievement.icon }}
            />
            <span className="achievement-name">{activeAchievement.name}</span>
            <span className="achievement-description">{activeAchievement.description}</span>
            <div className="achievement-actions">
              <Button
                size="tiny"
                content="View all achievements"
                as={Link}
                to="/achievements"
                onClick={() => setAchievementModalOpen(false)}
              />
              <Button size="tiny" primary content="Done" icon="check" onClick={() => setAchievementModalOpen(false)} />
            </div>
          </div>
        </StyledAchievementModal>
      </Modal>

      <ModalCarousel
        title={"Received " + util.pluralise(fullScreenAnnouncements.length, "announcement", "announcements")}
        screens={fullScreenAnnouncements.map((announcement) => ({
          title: announcement.subject,
          content: (
            <div>
              {announcement.body ? <RichTextViewer content={announcement.body} /> : <p>{announcement.text}</p>}
              {announcement?.attachments?.length > 0 && (
                <>
                  <h5>Attachments</h5>
                  {announcement.attachments.map((attachment, index) => (
                    <a
                      href={attachment.url}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={attachment.name}
                      className="ui small blue button"
                      style={{ borderRadius: 5 }}
                    >
                      <i className="paperclip icon"></i>
                      {attachment.name}
                    </a>
                  ))}
                </>
              )}
            </div>
          ),
        }))}
        actions={[
          {
            label: "Got it",
            icon: "check",
            primary: true,
            onClick: () => {
              setFullScreenAnnouncements([]);
              setAnnouncementModalOpen(false);
            },
          },
        ]}
        open={announcementModalOpen}
        onClose={() => setAnnouncementModalOpen(false)}
      />
    </div>
  );
};

export default EventSubscriptions;

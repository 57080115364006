import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const AssignIdea = ({ isOpen, onClose, assignIdea, loading }) => {
  const { t } = useTranslation();

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={isOpen}
      onClose={onClose}
      style={{ padding: 20, backgroundColor: "#fff" }}
    >
      <h3>Assign {t("generic.idea")} to your account?</h3>
      <p>
        Assigning this {t("generic.idea")} to your account will allow you to return to it later to continue editing, as
        well as reply to comments.
      </p>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button content="Cancel" onClick={onClose} />
        <Button primary content="Assign" loading={loading} icon="add user" onClick={assignIdea} />
      </div>
    </Modal>
  );
};

export default AssignIdea;

import React from "react";
import { Icon } from "semantic-ui-react";
import { InfoContainer, InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFactLink, InfoItem } from "../Styles";

export const getUkriLink = (projectUrl) => {
  const splitter = "/gtr.portal/";
  if (!projectUrl) return projectUrl;
  if (projectUrl.indexOf(splitter) === -1) return projectUrl;
  const splitProjectUrl = projectUrl.split(splitter);
  if (splitProjectUrl.length === 2) {
    return `https://gtr.ukri.org/${splitProjectUrl[1]}`;
  }
  return projectUrl;
};

const CompanyFundedResearch = ({ fundingData: projects = [] }) => {
  if (projects.length) {
    return (
      <InfoBoxWidget $sectioned>
        <InfoBoxWidgetTitleArea>
          <Icon name="folder" />
          <h3>Funded research</h3>
          <span>{projects.length} project(s) found</span>
        </InfoBoxWidgetTitleArea>
        <InfoContainer $wrapped>
          {projects.slice(0, 10).map((project, index) => (
            <InfoItem key={`${project.title}+${index}`} href={getUkriLink(project.url)}>
              <KeyFactLink
                key={project.title}
                title={`${project.title} - ${project.grantCategory}`}
                fact={`£${project.fund.valuePounds}, ${project.fund.start} - ${project.fund.end}`}
              />
            </InfoItem>
          ))}
        </InfoContainer>
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyFundedResearch;

import React, { useCallback, useState, useEffect } from "react";
import api from "api";
import { Icon, Header, Container, Message, Loader, Button, Segment, Divider } from "semantic-ui-react";
import { useParams, useNavigate } from "react-router-dom";
import { OpenAPI, Other } from "simplydo/interfaces";
import { useAppSelector, useAppDispatch } from "store";
import { useTranslation } from "react-i18next";
import Login from "components/entry/Login";
import actions from "actions";
// @ts-ignore
import i18n from "src/i18n";
import { useUserReload } from "utils/useUserReload";
import { UserChip } from "components/lib/Chips";
import toast from "react-hot-toast";

const getEntityAction = (persistentToken: OpenAPI.Schemas["PersistentToken"]) => {
  const { forEntity } = persistentToken;
  if (forEntity === "invitation") {
    return "invited";
  }
  return "";
};

const getForTypeText = (persistentToken: OpenAPI.Schemas["PersistentToken"]) => {
  const { forType } = persistentToken;
  if (forType === "ideaBusinessProfile") {
    return `join the business profile`;
  } else if (forType === "idea") {
    return `join the ${i18n.t("generic.idea")}`;
  } else if (forType === "group") {
    return `join the ${i18n.t("generic.group")}`;
  }
  return "";
};

const getEntityName = (persistentToken: OpenAPI.Schemas["PersistentToken"]) => {
  const { forType } = persistentToken;
  if (forType === "ideaBusinessProfile") {
    const { ownerIdeaBusinessProfile } = persistentToken;
    return ownerIdeaBusinessProfile?.profile?.name ?? "Unknown";
  } else if (forType === "idea") {
    const { ownerIdea } = persistentToken;
    return ownerIdea?.name ?? "Unknown";
  } else if (forType === "group") {
    const { ownerGroup } = persistentToken;
    return ownerGroup?.name ?? "Unknown";
  }
  return "";
};

const getActionText = (persistentToken: OpenAPI.Schemas["PersistentToken"]) => {
  const { forEntity } = persistentToken;
  if (forEntity === "invitation") {
    return "accept this invitation";
  }
  return "";
};

const PersistentToken = () => {
  const reloadUser = useUserReload();
  const [persistentToken, setPersistentToken] = useState<
    OpenAPI.GET<"/persistent-tokens/redeem/{token}">["response"]["persistentToken"] | null
  >(null);
  const [persistentTokenLoading, setPersistentTokenLoading] = useState(false);
  const [persistentTokenErrorMessage, setPersistentTokenErrorMessage] = useState<string>("");

  const [redeemingPersistentToken, setRedeemingPersistentToken] = useState(false);

  const user: OpenAPI.GET<"/users/me">["response"] = useAppSelector((state) => state.user);

  const urlOrganisation: Other.IOrganisation = useAppSelector((state) => state.organisations.urlOrganisation);
  const dispatch = useAppDispatch();
  const onSelectOrganisation = useCallback(
    (organisation: Other.IOrganisation | null) => {
      dispatch(actions.organisations.receiveUrlOrganisation(organisation));
    },
    [dispatch],
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  // If we have data indicating the user has previously visited this link, and created an entity/accepted an invitation, redirect them to the entity
  const handleRedirect = useCallback(
    (persistentToken: OpenAPI.GET<"/persistent-tokens/redeem/{token}">["response"]["persistentToken"]) => {
      if (persistentToken.forEntity === "invitation") {
        if (persistentToken.forType === "ideaBusinessProfile") {
          navigate(`/?businessProfileId=${persistentToken.forId}`); // See AutoBusinessProfileModal.tsx
        } else if (persistentToken.forType === "idea") {
          navigate(`/ideas/${persistentToken.forId}`);
        } else if (persistentToken.forType === "group") {
          navigate(`/groups/${persistentToken.forId}`);
        }
      }
    },
    [navigate],
  );

  const getPersistentToken = useCallback(() => {
    setPersistentTokenLoading(true);
    setPersistentTokenErrorMessage("");
    api.persistentTokens.getByToken(
      token,
      (res: OpenAPI.GET<"/persistent-tokens/redeem/{token}">["response"]) => {
        if (res.userHasRedeemedToken) {
          handleRedirect(res.persistentToken);
        }
        setPersistentToken(res.persistentToken);
        setPersistentTokenLoading(false);
      },
      (err) => {
        setPersistentTokenErrorMessage(err.message);
        setPersistentTokenLoading(false);
      },
    );
  }, [handleRedirect, token]);

  useEffect(() => {
    getPersistentToken();
  }, [getPersistentToken]);

  const redeemPersistentToken = useCallback(() => {
    setRedeemingPersistentToken(true);
    setPersistentTokenErrorMessage("");
    api.persistentTokens.redeem(
      token,
      () => {
        toast.success("Invitation accepted");
        reloadUser(() => {
          handleRedirect(persistentToken);
        });
        setRedeemingPersistentToken(false);
      },
      (err) => {
        setRedeemingPersistentToken(false);
        setPersistentTokenErrorMessage(err.message);
      },
    );
  }, [persistentToken, reloadUser, token, handleRedirect]);

  return (
    <Container
      style={{
        paddingTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Segment style={{ maxWidth: 700 }}>
        {persistentToken ? (
          <>
            <Header as="h2" icon textAlign="center">
              <Icon name="users" circular />
              <Header.Content>Join {`"${getEntityName(persistentToken)}"`}</Header.Content>
            </Header>
            <p>
              <UserChip user={persistentToken.ownerUser} compact="very" />
              {" has "}
              {getEntityAction(persistentToken)}
              {" you to "}
              {getForTypeText(persistentToken)} {getEntityName(persistentToken)}
            </p>
          </>
        ) : null}

        {persistentTokenErrorMessage ? (
          <Message error>
            <Message.Header>Error</Message.Header>
            <Message.Content>{persistentTokenErrorMessage}. Your link may be invalid, or has expired.</Message.Content>
          </Message>
        ) : null}

        {persistentTokenLoading ? <Loader active inline="centered" /> : null}

        {persistentToken ? (
          user ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 35,
                marginTop: 20,
                width: "100%",
              }}
            >
              <Button
                primary
                fluid
                icon="check"
                loading={redeemingPersistentToken}
                onClick={redeemPersistentToken}
                style={{ maxWidth: 500 }}
                disabled={!!persistentTokenErrorMessage}
              >
                Accept invitation
              </Button>
            </div>
          ) : (
            <>
              <Message info style={{ marginBottom: 20 }}>
                To {getActionText(persistentToken)}, please login or create an account.
              </Message>

              {urlOrganisation ? (
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 style={{ display: "inline-block", marginTop: 20, marginBottom: 0 }}>
                      {t("entry.register.org.signingUpWith", { organisation: urlOrganisation })}
                    </h5>
                    <Button
                      size="tiny"
                      type="button"
                      content={t("entry.register.org.changeThis")}
                      onClick={() => onSelectOrganisation(null)}
                      basic
                      style={{ margin: "10px 10px 10px 0px" }}
                    />
                  </div>

                  <Divider />
                </div>
              ) : null}

              <Login />
            </>
          )
        ) : null}
      </Segment>
    </Container>
  );
};

export default PersistentToken;

import React, { useMemo } from "react";
import { Table, Divider, Label, Icon, Image } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";

import constants from "utils/constants";

export const businessTypeOptions = [
  { key: "public", value: "public", text: "Public Sector" },
  { key: "private", value: "private", text: "Private Sector" },
  { key: "notForProfit", value: "notForProfit", text: "Not For-Profit" },
  { key: "academic", value: "academic", text: "Academic" },
];

export const businessSizeOptions = [
  { key: "micro", value: "micro", text: "Micro <10 Employees" },
  { key: "small", value: "small", text: "Small <50 Employees" },
  { key: "medium", value: "medium", text: "Medium <250 Employees" },
  { key: "large", value: "large", text: "Large >250 Employees" },
];

export const businessStatusOptions = [
  { key: "preStartup", value: "preStartup", text: "Pre-start-up" },
  { key: "startup", value: "startup", text: "Start up < 1 year" },
  { key: "established_small", value: "established_small", text: "Established 1 - 5 years" },
  { key: "established_medium", value: "established_medium", text: "Established 6 - 10 years" },
  { key: "established_large", value: "established_large", text: "Established > 10 years" },
];

type OverviewProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
};

export const Overview = ({ businessProfile }: OverviewProps) => {
  const { profile } = businessProfile;
  const { address } = profile || {};
  const { country, city, streetAddress, postcode } = address || {};
  const size = useMemo(
    () => businessSizeOptions.find((option) => option.value === profile?.size)?.text,
    [profile?.size],
  );
  const type = useMemo(
    () => businessTypeOptions.find((option) => option.value === profile?.type)?.text,
    [profile?.type],
  );
  const status = useMemo(
    () => businessStatusOptions.find((option) => option.value === profile?.status)?.text,
    [profile?.status],
  );
  const fullAddress = useMemo(
    () => [streetAddress, city, country, postcode].filter((addressSegment) => !!addressSegment),
    [country, city, streetAddress, postcode],
  );

  return (
    <>
      <div style={{ display: "flex", gap: 24 }}>
        {profile?.imageUrl && (
          <div
            style={{
              display: "flex",
              alignSelf: "flex-start",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 4,
              overflow: "hidden",
              maxHeight: 150,
              width: 150,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={profile?.imageUrl} alt={profile?.name} />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <h4>About</h4>
          {profile?.websiteUrl && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="world" />
              {profile?.websiteUrl}
            </p>
          )}

          {fullAddress && fullAddress.length > 0 ? (
            <p style={{ marginBottom: 7 }}>
              <Icon name="map pin" />
              {fullAddress.join(", ")}
            </p>
          ) : null}

          {profile?.phoneNumber && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="phone" />
              {profile?.phoneNumber}
            </p>
          )}

          {size && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="users" />
              {size}
            </p>
          )}

          {status && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="building" />
              {status}
            </p>
          )}

          {type && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="industry" />
              {type}
            </p>
          )}
        </div>
      </div>
      <Divider hidden />
      {!profile?.creditsafeRegNoSkipped && profile?.creditsafeRegNo && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold" }}>
          <p style={{ marginBottom: 0, marginRight: 5 }}>Company registration number</p>
          <Label size="small" content={profile?.creditsafeRegNo} />
        </div>
      )}
      {!profile?.vatNoSkipped && profile?.vatNo && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold" }}>
          <p style={{ marginBottom: 0, marginRight: 5 }}>VAT number</p>
          <Label size="small" content={profile?.vatNo} />
        </div>
      )}
      {profile?.accreditations && profile?.accreditations?.length > 0 && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold" }}>
          <p style={{ marginBottom: 0, marginRight: 5 }}>Accreditations</p>
          {profile?.accreditations.map((accreditation) => (
            <Label
              size="small"
              key={accreditation}
              content={constants.accreditations.find((acc) => acc.key === accreditation)?.text || accreditation}
            />
          ))}
        </div>
      )}
      <Divider hidden />
      <p>{profile?.description}</p>
      <Divider hidden />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">Primary contact details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>Full name</Table.Cell>
            <Table.Cell>{profile?.primaryContact?.fullName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Email</Table.Cell>
            <Table.Cell>{profile?.primaryContact?.email}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Phone number</Table.Cell>
            <Table.Cell>{profile?.primaryContact?.phoneNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Job title</Table.Cell>
            <Table.Cell>{profile?.primaryContact?.jobTitle}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

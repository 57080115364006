import React, { Component } from "react";
import { Card, Container, Loader, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { EmptyBox, Banner } from "components/lib/UI";
import api from "api";
import DiscoverChallengeCard from "components/lib/Cards/DiscoverChallengeCard";

class PublicChallenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      challenges: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchChallenges();
  }

  componentDidUpdate(prevProps) {
    const { organisation } = this.props;
    if ((organisation && organisation._id) !== (prevProps.organisation && prevProps.organisation._id)) {
      this.fetchChallenges();
    }
  }

  fetchChallenges = () => {
    const { organisation } = this.props;
    if (organisation) {
      this.setState({ loading: true });
      api.organisations.getShopWindow(organisation._id, ({ challenges }) => {
        this.setState({ loading: false, challenges });
      });
    }
  };

  render() {
    const { organisation, user, t } = this.props;
    const { challenges, loading } = this.state;
    return (
      <div style={{ textAlign: "center" }}>
        <Banner>
          <Container style={{ textAlign: "center" }}>
            <h1 className="title" style={{ color: "#3b3b3b" }}>
              Public {t("generic.challenges")}
            </h1>
            {!user && (
              <Link to="/">
                <p style={{ color: "white", fontSize: 14 }}>
                  <u>Sign up or log in to discover more</u>
                </p>
              </Link>
            )}
            {user && (
              <Link to="/challenges/discover">
                <p style={{ color: "white", fontSize: 14 }}>
                  <u>Discover more {t("generic.challenges")}</u>
                </p>
              </Link>
            )}
          </Container>
        </Banner>

        {loading ? (
          <Loader style={{ marginTop: 20 }} active inline="centered" />
        ) : (
          <div>
            {organisation ? (
              <div style={{ width: "80%", display: "inline-block" }}>
                {challenges !== null && challenges.length >= 1 && (
                  <div style={{ marginTop: 30 }}>
                    <Card.Group style={{ marginBottom: 10 }} itemsPerRow={4} stackable centered>
                      {challenges.map((challenge) => (
                        <DiscoverChallengeCard challenge={challenge} key={challenge._id} />
                      ))}
                    </Card.Group>
                  </div>
                )}
                {challenges.length === 0 && (
                  <EmptyBox style={{ margin: "20px 40px" }}>
                    <h2>There aren't any public {t("generic.challenges")} right now</h2>
                    {user ? (
                      <Button
                        size="large"
                        as={Link}
                        to="/challenges/discover"
                        primary
                        icon="eye"
                        content={`Discover ${t("generic.challenges")}`}
                      />
                    ) : (
                      <Button size="large" as={Link} to="/" primary content="Sign up or log in" />
                    )}
                  </EmptyBox>
                )}
              </div>
            ) : (
              <EmptyBox style={{ margin: "20px 40px" }} title={`No public ${t("generic.challenges")} available`} />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ organisation: state.organisations.urlOrganisation, user: state.user });

const PublicChallengesContainer = withTranslation()(connect(mapStateToProps)(PublicChallenges));

export default PublicChallengesContainer;

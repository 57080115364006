import React, { ComponentProps, useMemo } from "react";
import { Button, ButtonProps, Popup } from "semantic-ui-react";
import { CSSProperties } from "styled-components";

type TooltipButtonProps = ButtonProps & {
  tooltip?: string;
  tooltipProps?: ComponentProps<typeof Popup>;
  wrapperStyle?: CSSProperties;
};

const TooltipButton = ({ tooltip, tooltipProps = {}, wrapperStyle = {}, ...props }: TooltipButtonProps) => {
  const button = useMemo(() => <Button {...props} />, [props]);
  if (!tooltip) {
    return button;
  }
  return (
    <Popup
      {...tooltipProps}
      content={tooltip}
      trigger={<div style={{ display: "inline-block", ...wrapperStyle }}>{button}</div>}
    />
  );
};

export default TooltipButton;

import actions from "../actions/index.js";

const initialState = {
  isGettingUser: false,
  isAuthenticated: false,
  errorMessage: "",
  showLogin: false,
  recentlyLoggedOut: false,
  utmChallenge: "",
  utmLink: "",
  clearUtm: false,
};

function auth(state = initialState, action) {
  switch (action.type) {
    case actions.auth.START_GETTING_USER:
      return { ...state, isGettingUser: true };
    case actions.auth.FINISH_GETTING_USER:
      return { ...state, isGettingUser: false, errorMessage: "" };
    case actions.auth.GET_USER_FAIL:
      return {
        ...state,
        errorMessage: action.error,
        isGettingUser: false,
        showLogin: action.showLogin,
      };
    case actions.auth.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        errorMessage: "",
        showLogin: false,
      };
    case actions.auth.LOGIN_FAIL:
      return { ...state, isAuthenticated: false };
    case actions.auth.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        errorMessage: "",
        recentlyLoggedOut: true,
      };
    case actions.auth.SET_UTM_LINK:
      return { ...state, utmLink: action.utm };
    case actions.auth.SET_UTM_CHALLENGE:
      return { ...state, utmChallenge: action.utm };
    case actions.auth.SET_CLEAR_UTM:
      return { ...state, clearUtm: action.shouldClear };
    case actions.auth.IP_NOT_ALLOWED:
      return {
        ...state,
        isGettingUser: false,
        ipBlocked: true,
        errorMessage: action.error,
      };
    default:
      return state;
  }
}

export default auth;

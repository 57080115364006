import { ImageChooser, LinkChooser, FileChooser, VideoChooser, IFrameChooser } from "components/lib/Choosers";

import { iframeRegex, extractHTMLTag } from "../../Remirror/extensions/custom/iframe";

export const getFullMenu = (chooser, setChooser, forType, forId) => [
  {
    name: "link",
    icon: "linkify",
    onClick: ({ commands, isActive, selectedText }) => {
      if (isActive) {
        commands.removeLink();
      } else {
        setChooser("link", !chooser.link?.open, commands.createCustomLink, selectedText);
      }
    },
    MenuItem: ({ RenderItem, itemProps }) => (
      <LinkChooser
        trigger={
          <div>
            <RenderItem {...itemProps} />
          </div>
        }
        isOpen={chooser.link?.open}
        shouldFix={false}
        selectedText={chooser.link?.text}
        onClose={() => setChooser("link", false)}
        onComplete={(text, url) => {
          chooser.link.cmd({ text, href: url });
          setChooser("link", false);
        }}
      />
    ),
  },
  {
    name: "image",
    onClick: ({ commands, selectedText }) => {
      setChooser("image", !chooser.image?.open, commands.insertResizableImage || commands.insertImage, selectedText);
    },
    MenuItem: ({ RenderItem, itemProps }) => (
      <ImageChooser
        trigger={
          <div>
            <RenderItem {...itemProps} />
          </div>
        }
        forType={forType}
        forId={forId}
        isOpen={chooser.image?.open}
        onClose={() => setChooser("image", false)}
        onComplete={(image, name, imageSrc) => {
          chooser.image.cmd({ src: imageSrc });
          setChooser("image", false);
        }}
      />
    ),
  },
  {
    name: "video",
    onClick: ({ commands, selectedText }) => {
      setChooser(
        "video",
        !chooser.video?.open,
        (embed) => (embed ? commands.addIframe : commands.insertVideo),
        selectedText,
      );
    },
    MenuItem: ({ RenderItem, itemProps }) => (
      <VideoChooser
        trigger={
          <div>
            <RenderItem {...itemProps} />
          </div>
        }
        forType={forType}
        forId={forId}
        isOpen={chooser.video?.open}
        onClose={() => setChooser("video", false)}
        onComplete={(video, name, videoSrc) => {
          const isEmbed = video.startsWith("embed-");
          const src = isEmbed ? video.replace("embed-", "") : videoSrc;

          chooser.video.cmd(isEmbed)({ src });
          setChooser("video", false);
        }}
      />
    ),
  },
  {
    name: "file",
    onClick: ({ commands, isActive, selectedText }) => {
      if (isActive) return;
      setChooser("file", !chooser.file?.open, commands.insertFile, selectedText);
    },
    MenuItem: ({ RenderItem, itemProps }) => (
      <FileChooser
        trigger={
          <div>
            <RenderItem {...itemProps} />
          </div>
        }
        forType={forType}
        forId={forId}
        isOpen={chooser.file?.open}
        onClose={() => setChooser("file", false)}
        onComplete={(file, name, fileSrc) => {
          chooser.file.cmd({ name, src: fileSrc });
          setChooser("file", false);
        }}
      />
    ),
  },
  {
    name: "iframe",
    icon: "file code",
    onClick: ({ commands, isActive, selectedText }) => {
      if (isActive) {
        commands.removeLink();
      } else {
        setChooser("iframe", !chooser.iframe?.open, commands.addIframe, selectedText);
      }
    },
    MenuItem: ({ RenderItem, itemProps }) => (
      <IFrameChooser
        trigger={
          <div>
            <RenderItem {...itemProps} />
          </div>
        }
        isOpen={chooser.iframe?.open}
        selectedText={chooser.iframe?.text}
        onClose={() => setChooser("iframe", false)}
        onComplete={(iFrameURL) => {
          const options = {};
          const match = iFrameURL.match(iframeRegex);
          if (match) {
            options.src = extractHTMLTag(match[0], "src");
            options.title = extractHTMLTag(match[0], "title") ?? "iFrame";
            options.allow = extractHTMLTag(match[0], "allow") ?? "";
          } else {
            options.src = iFrameURL;
          }

          if (options.src) {
            chooser.iframe.cmd(options);
          }
          setChooser("iframe", false);
        }}
      />
    ),
  },
];

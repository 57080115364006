import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, Message, Table, Icon, Loader } from "semantic-ui-react";
import toast from "react-hot-toast";
import moment from "moment";
import api from "api";
import util from "utils/utils";

import { countries } from "utils/countries";

function CompanyVerificationReportGenerator({ user, trigger, forId, forType, onComplete, onError }) {
  const [companies, setCompanies] = useState();
  const [country, setCountry] = useState("GB");
  const [companyNumber, setCompanyNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [reports, setReports] = useState([]);
  const [loadingReports, setLoadingReports] = useState(false);
  const [searching, setSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const userId = user?._id;
  let authToken = null;
  if (util.localStorageIsSupported()) {
    authToken = localStorage.getItem(`ideaAuthToken:${forId}`);
  }

  useEffect(() => {
    if (open && userId) {
      setLoadingReports(true);
      api.verifications.getReports(
        (data) => {
          setLoadingReports(false);
          setReports(data.reports);
        },
        () => setLoadingReports(false),
      );
    }
  }, [userId, open]);

  const openModal = () => {
    setOpen(!open);
  };

  const searchForCompanies = useCallback(() => {
    setSearching(true);
    api.verifications.searchForCompanies(
      country,
      companyNumber,
      companyName,
      forType,
      forId,
      (data) => {
        setSearching(false);
        setCompanies(data.companies);
      },
      (err) => {
        setSearching(false);
        if (err.status === 503) {
          setOpen(false);
          onError &&
            onError({
              creditsafeEnabled: false,
              message:
                "We have detected an issue with the company lookup service. Please enter your information manually or contact support for assistance",
            });
        }
      },
      authToken,
    );
  }, [forType, forId, country, companyNumber, companyName, authToken, onError]);

  const chooseReport = useCallback(
    (report) => {
      onComplete(report);
      setOpen(false);
    },
    [onComplete],
  );

  const generateReportForCompany = useCallback(
    (company) => {
      setLoadingReports(company.regNo);
      api.verifications.createReport(
        company._id,
        forType,
        forId,
        (data) => {
          onComplete(data.report);
          setLoadingReports(false);
          setOpen(false);
        },
        (err) => {
          toast.error(err.message);
          setLoadingReports(false);
        },
        authToken,
      );
    },
    [forType, forId, authToken, onComplete],
  );

  return (
    <>
      {trigger ? (
        React.cloneElement(trigger, { onClick: openModal })
      ) : (
        <Button
          size="small"
          secondary
          icon="file outline"
          fluid
          content={this.props.content || "Generate a new report"}
          onClick={openModal}
        />
      )}
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={open}
        dimmer="inverted"
        size="tiny"
        style={{ zIndex: 100 }}
        closeOnDocumentClick={false}
      >
        <Modal.Header>
          <Icon name="check circle" /> Company lookup
        </Modal.Header>
        <Modal.Content>
          <h3>Your companies</h3>
          {loadingReports && <Loader active inline="centered" />}
          {reports?.length > 0 ? (
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Company name</Table.HeaderCell>
                  <Table.HeaderCell>Looked-up at</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {reports.map((report) => (
                  <Table.Row key={report._id}>
                    <Table.Cell>
                      {report.companyName}
                      <br />
                      <small>{report.companyNumber}</small>
                    </Table.Cell>
                    <Table.Cell>{moment(report.createdAt).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                    <Table.Cell collapsing>
                      <Button size="tiny" onClick={() => chooseReport(report)} primary content="Use this company" />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            !loadingReports && <Message info>You haven't looked-up any companies yet.</Message>
          )}

          <h3>New company lookup</h3>
          <p>
            Search for your company by providing its country of registration <strong>and</strong> either its name or
            registration number.
          </p>

          <Form onSubmit={searchForCompanies}>
            <Form.Field>
              <label>Registration country (required)</label>
              <Form.Select
                search
                value={country}
                onChange={(e, s) => setCountry(s.value)}
                placeholder="Select one"
                options={countries.map((c) => ({ key: c.iso2, value: c.iso2, text: c.name }))}
              />
            </Form.Field>

            <Form.Group stackable>
              <Form.Field width={8}>
                <label>Registration number</label>
                <Form.Input
                  value={companyNumber}
                  onChange={(e) => setCompanyNumber(e.target.value)}
                  placeholder="12345678"
                />
              </Form.Field>
              <Form.Field width={8}>
                <label>Company name</label>
                <Form.Input
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="My Company Ltd"
                />
              </Form.Field>
            </Form.Group>
            <Form.Button loading={searching} type="submit" secondary content="Search" icon="search" />
          </Form>
          {companies?.length === 0 && (
            <Message size="small">We were unable to find any companies matching your search criteria.</Message>
          )}
          {companies?.length > 0 && (
            <>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Company</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {companies.map((company) => (
                    <Table.Row key={company.id}>
                      <Table.Cell>
                        {company.name}
                        <br />
                        <small>
                          <strong>{company.regNo}</strong>
                          <br />
                          {company.address?.simpleValue}
                        </small>
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Button
                          size="tiny"
                          loading={loadingReports === company.regNo}
                          disabled={loadingReports && loadingReports !== company.regNo}
                          primary
                          icon="check circle"
                          content="Use this company"
                          onClick={() => generateReportForCompany(company)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={openModal} content="Close" />
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default CompanyVerificationReportGenerator;

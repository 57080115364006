const entryActions = {
  CLEAR: "CLEAR",
  UPDATE_EMAIL: "UPDATE_EMAIL",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_CHALLENGE_RESPONSE: "UPDATE_CHALLENGE_RESPONSE",
  UPDATE_FIRST_NAME: "UPDATE_FIRST_NAME",
  UPDATE_LAST_NAME: "UPDATE_LAST_NAME",
  UPDATE_ORGANISATION_SEARCH_CODE: "UPDATE_ORGANISATION_SEARCH_CODE",
  UPDATE_AGREED_TERMS: "UPDATE_AGREED_TERMS",
  UPDATE_REGISTER_SECTION: "UPDATE_REGISTER_SECTION",
  UPDATE_REGISTERING: "UPDATE_REGISTERING",
  UPDATE_LOADING: "UPDATE_LOADING",
  UPDATE_ORGANISATION_SEARCHING: "UPDATE_ORGANISATION_SEARCHING",
  UPDATE_SHOW_PASSWORD: "UPDATE_SHOW_PASSWORD",
  UPDATE_ERROR: "UPDATE_ERROR",

  clear() {
    return { type: this.CLEAR };
  },
  updateEmail(email) {
    return { type: this.UPDATE_EMAIL, email };
  },
  updatePassword(password) {
    return { type: this.UPDATE_PASSWORD, password };
  },
  updateChallengeResponse(challengeResponse) {
    return { type: this.UPDATE_CHALLENGE_RESPONSE, challengeResponse };
  },
  updateFirstName(firstName) {
    return { type: this.UPDATE_FIRST_NAME, firstName };
  },
  updateLastName(lastName) {
    return { type: this.UPDATE_LAST_NAME, lastName };
  },
  updateOrganisationSearchCode(code) {
    return { type: this.UPDATE_ORGANISATION_SEARCH_CODE, code };
  },
  updateAgreedTerms(agreedTerms) {
    return { type: this.UPDATE_AGREED_TERMS, agreedTerms };
  },
  updateRegisterSection(registerSection) {
    return { type: this.UPDATE_REGISTER_SECTION, registerSection };
  },
  updateRegistering(registering) {
    return { type: this.UPDATE_REGISTERING, registering };
  },
  updateLoading(loading) {
    return { type: this.UPDATE_LOADING, loading };
  },
  updateOrganisationSearching(searching) {
    return { type: this.UPDATE_ORGANISATION_SEARCHING, searching };
  },
  updateShowPassword(showPassword) {
    return { type: this.UPDATE_SHOW_PASSWORD, showPassword };
  },
  updateError(error) {
    return { type: this.UPDATE_ERROR, error };
  },
};

export default entryActions;

import { useEffect, useState } from "react";

export const useDidMount = (func) => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    if (!didMount) {
      func();
      setDidMount(true);
    }
  }, [didMount, func]);
};

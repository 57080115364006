import {
  ExtensionTag,
  NodeExtension,
  omitExtraAttributes,
  isNumber,
  getTextSelection,
  ExtensionImageTheme,
} from "remirror";
import { insertPoint } from "@remirror/pm/transform";

function createPlaceholder() {
  const element = document.createElement("div");
  element.classList.add(ExtensionImageTheme.IMAGE_LOADER);

  return element;
}

export class FileExtension extends NodeExtension {
  name = "file";

  tags = [ExtensionTag.InlineNode];

  ReactComponent = (props) => {
    const { src, name } = props.node.attrs;
    const onClick = () => {
      if (!props.view.editable) {
        if (this.options.onClick) {
          this.options.onClick(src);
        }
      }
    };

    return (
      <span onClick={onClick}>
        <i className="icon file" />
        {name}
      </span>
    );
  };

  createNodeSpec(extra, override) {
    return {
      inline: true,
      content: "",
      attrs: {
        ...extra.defaults(),
        src: { default: null },
        name: { default: "" },
      },
      ...override,
      parseDOM: [
        {
          tag: 'span[data-ext="file"]',
          getAttrs: (node) => {
            return {
              ...extra.parse(node),
              name: node.getAttribute("data-name") || node.getAttribute("name") || node.textContent,
              src: node.getAttribute("data-url") || node.getAttribute("src"),
            };
          },
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        const rest = omitExtraAttributes(node.attrs, extra);
        const domAttrs = extra.dom(node);
        return ["span", { ...domAttrs, ...rest, "data-ext": "file" }, domAttrs.name];
      },
    };
  }

  insertFile(attributes, selection) {
    return (param) => {
      const { tr, dispatch } = param;
      const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc);
      const node = this.type.create(attributes);
      dispatch(tr.replaceRangeWith(from, to, node));
      return true;
    };
  }

  createCommands() {
    return {
      insertFile: this.insertFile.bind(this),
      uploadFile: (value, onElement) => (props) => {
        const { tr } = props;

        // This is update in the validate hook
        let pos = tr.selection.from;
        return this.store
          .createPlaceholderCommand({
            promise: value,
            placeholder: {
              type: "widget",
              get pos() {
                return pos;
              },
              createElement: (view, newPos) => {
                const element = createPlaceholder(view, newPos);
                onElement?.(element);
                return element;
              },
            },
            onSuccess: (file, range, commandProps) => this.insertFile(file, range)(commandProps),
          })
          .validate(({ tr: newTr, dispatch }) => {
            const insertPos = insertPoint(newTr.doc, pos, this.type);

            if (insertPos == null) {
              return false;
            }

            pos = insertPos;

            if (!newTr.selection.empty) {
              dispatch?.(newTr.deleteSelection());
            }

            return true;
          }, "unshift")

          .generateCommand()(props);
      },
    };
  }

  fileUploadFileHandler(files, event, pos) {
    const { uploadHandler } = this.options;

    const { commands, chain } = this.store;
    const filesWithProgress = files.map((file, index) => ({
      file,
      progress: (progress) => {
        commands.updatePlaceholder(files[index], progress);
      },
    }));

    const uploads = uploadHandler(filesWithProgress);

    if (isNumber(pos)) {
      chain.selectText(pos);
    }

    for (const upload of uploads) {
      // eslint-disable-line no-restricted-syntax
      chain.uploadFile(upload);
    }

    chain.run();

    return true;
  }

  createPasteRules() {
    return [
      {
        type: "file",
        fileHandler: (props) => {
          const pos = props.type === "drop" ? props.pos : undefined;
          return this.fileUploadFileHandler(props.files, props.event, pos);
        },
      },
    ];
  }
}

export const createFileExtension = (options) => {
  const fileOptions = {
    extraAttributes: { src: { default: null }, name: { default: "" } },
    ...options[0],
  };
  return new FileExtension(fileOptions);
};

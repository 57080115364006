import React, { useCallback } from "react";
import { Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import api from "api";
import actions from "actions";
import { useTranslation } from "react-i18next";

const IdeaTeamStartChat = ({ idea, onMessageThreadSelected, style, compact, user, size, fluid, basic }) => {
  const forId = idea?._id;
  const forType = "idea";
  let name = idea?.name;
  const { t } = useTranslation();

  const startChat = useCallback(() => {
    const currentUser = user._id;

    // Look for existing thread for this idea team
    api.messages.findThreads(
      { forType, forId },
      (data) => {
        if (data.threads.length) {
          // If a thread exists, open this thread
          onMessageThreadSelected(data.threads[0]);
        } else {
          // Otherwise create a new thread and open that
          const { user, collaborators = [] } = idea;
          const chatUsers =
            currentUser === user ? collaborators : [user, ...collaborators.filter((c) => c !== currentUser)];
          if (name.length > 50) {
            name = name.substring(0, 50) + "...";
          }
          api.messages.createThread(
            {
              users: chatUsers,
              name: `Idea Team Chat - ${name}`,
              forType,
              forId,
            },
            (data) => {
              onMessageThreadSelected(data);
            },
            (err) => toast.error(err.message),
          );
        }
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [name, forType, forId, idea, user._id, onMessageThreadSelected]);

  return (
    <Button
      primary={!basic}
      basic={basic}
      size={size}
      content={`Message ${t("generic.idea")} team`}
      icon="mail"
      onClick={() => startChat()}
      style={style}
      compact={compact}
      fluid={fluid}
    />
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (threadId) => dispatch(actions.messages.selectThread(threadId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdeaTeamStartChat);

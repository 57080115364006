import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const GiphyOverlay = styled.div`
  display: inline-block;

  max-width: 200px;
  height: 150px;
  max-height: 150px;

  overflow: hidden;
  position: relative;
  margin: 0 3px;
  img {
    display: block;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
  .delete-icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }
`;

class GiphyGif extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imageHover: false,
    };
  }

  get_sized_url = () => {
    const { gif, size } = this.props;
    switch (size) {
      case "fixed_small":
        return gif.images.fixed_width_small.url;
      case "fixed":
        return gif.images.fixed_width.url;
      default:
        return gif.images.fixed_width.url;
    }
  };

  setHovered = (hovered) => this.setState({ imageHover: hovered });

  render() {
    const { gif, size, onClickGif, canDelete } = this.props;
    /*
      Ensure you provide the size prop - This ensures the component can pick a valid URL from the gif data
    */
    const gifUrl = !size ? gif.url : this.get_sized_url();
    return (
      <GiphyOverlay
        onMouseEnter={() => this.setHovered(true)}
        onMouseLeave={() => this.setHovered(false)}
        onClick={onClickGif}
        className={canDelete ? "giphy-overlay" : null}
      >
        <img
          style={{
            cursor: onClickGif ? "pointer" : null,
            borderRadius: 5,
          }}
          src={gifUrl}
          alt={gif.title || "Gif"}
          {...this.props}
        />
        {this.state.imageHover && canDelete && <Icon inverted circular name="close" className="delete-icon" />}
      </GiphyOverlay>
    );
  }
}

export default GiphyGif;

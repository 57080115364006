import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Icon, Button, List } from "semantic-ui-react";
import actions from "actions";
import styled from "styled-components";
import useTheme from "theme/useTheme";

const SecurityRecommendationsContainer = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 5px !important;
  margin-bottom: 20px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  h3 {
    color: black;
  }
`;

function SecurityRecommendations({ user, onHidden }) {
  const theme = useTheme();
  return (
    <>
      {user.recommendTwoFactor && !user.hideSecurityRecommendations && (
        <SecurityRecommendationsContainer theme={theme}>
          <h3 style={{ marginBottom: 5 }}>
            Security recommendations
            <Icon name="close" style={{ cursor: "pointer", float: "right" }} onClick={onHidden} />
          </h3>
          <p>As an administrator it is important that your account is as secure as possible.</p>
          <List divided relaxed>
            <List.Item>
              <List.Icon name="exclamation circle" color="orange" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header>Set-up two-factor authentication</List.Header>
                <List.Description>
                  Two factor authentication (2FA or MFA) adds an extra layer of security to your account in case anyone
                  else guesses your password.
                  <br />
                  <Button
                    as={Link}
                    to="preferences/password"
                    content="Set-up 2FA"
                    size="small"
                    secondary
                    style={{ marginTop: 5 }}
                  />
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </SecurityRecommendationsContainer>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  onHidden: () => dispatch(actions.user.hideSecurityRecommendations()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SecurityRecommendations));

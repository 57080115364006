import React, { useEffect, useRef, useState } from "react";
import { Icon, Label, Popup, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";

import { useAppSelector } from "store";

import FileChooser from "components/lib/Choosers/FileChooser/FileChooser";
import { MultiLineParagraph } from "components/lib/UI";
import { GiphyGif, GiphyPicker } from "components/lib/Giphy";
import Emoji from "components/lib/Emoji/Emoji";
import EmojiChooser from "components/lib/Emoji/EmojiChooser";
import MentionText from "components/lib/Editors/RichText/MentionText";
import { ActionArea, ActionButton, InputContainer } from "./Actions";
import UserChip from "components/lib/Chips/UserChip";
import { OpenAPI } from "simplydo/interfaces";
import { useSearchParams } from "react-router-dom";

import type { Comment } from "./index";

export const MediaContainer = styled.div<{ $margined: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ $margined }) => ($margined ? "margin-top: 7.5px;" : "")}
  // padding: 0 5px;

  .ui.label:not(.circular),
  .ui.button:not(.circular) {
    max-width: 47%;
  }
`;

const CommentContainer = styled.div<{ $isChild: boolean; $isPinned: boolean; $isHighlighted: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding-bottom: 7.5px;
  min-width: 100%;
  margin: 0 5px 5px;
  ${({ $isChild }) => $isChild && "margin-left: 0; margin-bottom: 0; padding-bottom: 0;"}
  ${({ $isPinned }) =>
    $isPinned &&
    `
    padding: 10px;
    border-radius: 5px;
    background-color: #f6f6db;
    border: 2px solid #dadba0;
    margin-left: 0;
    margin-bottom: 15px;
  `}
  ${({ $isHighlighted }) =>
    $isHighlighted &&
    `
    padding: 10px;
    border-radius: 5px;
    background-color: #88e4d51c;
    border: 2px solid #5da2b3;
    margin-left: 0;
    margin-bottom: 15px;
  `}
`;

const ContentContainer = styled.div<{
  $isChild: boolean;
  $isPinned: boolean;
  $isHighlighted: boolean;
  $lastComment: boolean;
  $collapsed: boolean;
}>`
  display: ${({ $collapsed }) => ($collapsed ? "none" : "flex")};
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-left: 55px;
  ${({ $isChild, $lastComment, $isPinned, $isHighlighted }) =>
    !$isChild && !$lastComment && !$isPinned && !$isHighlighted && "border-bottom: 1px solid #c9c9c9;"}
`;

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 3.5px;
  a {
    font-weight: 700;
    font-size: 0.9em;
    color: black;
    display: block;
    line-height: 1.2;
  }
`;

const TextContainer = styled.div`
  p {
    color: #2d2d2d;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3.5px 0 7.5px;
  span {
    display: block;
    color: gray;
    font-weight: 550;
    cursor: pointer;
    margin-right: 7.5px;
    font-size: 0.85em;
    &:hover {
      text-decoration-line: underline;
    }
  }
`;

const CollapseIcon = styled(Icon)<{ $collapsed: boolean }>`
  cursor: pointer;
  && {
    opacity: 0.5;
    margin-top: ${({ $collapsed }) => ($collapsed ? "12px" : "15px")};

    transition: transform 0.2s;
    ${({ $collapsed }) => !$collapsed && "transform: rotate(90deg);"}

    &:hover {
      opacity: 1;
    }
  }
`;

const EmojiLabel = styled(Label)`
  && {
    padding: 2px 4px 1px 4px;
    max-width: none !important;
    cursor: pointer;

    &:hover {
      filter: brightness(1.05);
    }
  }
`;

const NewEmojiLabel = styled(Label)`
  cursor: pointer;

  opacity: 0.5;
  transition: opacity 0.2s;
  filter: grayscale(100%);
  &:hover {
    opacity: 1;
    filter: grayscale(0%);
  }
`;

type FeedCommentProps = {
  comment: Comment;
  onPostReply?: (commentId: string) => void;
  onAddGiphy?: (gif: string, commentId: string) => void;
  onAddAttachment?: (signedUrl: string, file: File, data: string, commentId: string) => void;
  addAutoMention?: (commentId: string, user: OpenAPI.Schemas["User"]) => void;
  canManage?: boolean;
  getReplyData?: (commentId: string) => { text: string; media: Comment["media"] };
  feed?: Comment[];
  updateReply?: (commentId: string, text: string) => void;
  deleteComment?: (commentId: string) => void;
  deleteAttachment?: (index: number, commentId: string) => void;
  getFile?: (media: Comment["media"][number]) => string;
  pin?: (commentId: string) => void;
  unpin?: (commentId: string) => void;
  reactComment?: (commentId: string, emoji: string, react: boolean) => void;
  authors?: string[];
  isLastChild?: boolean;
  commentLoading?: boolean;
  disableReacts?: boolean;
  disableAttachments?: boolean;
  disableReplies?: boolean;
  replyToParent?: (user: OpenAPI.Schemas["User"]) => void;
  canPost?: boolean;
  fileChooserForType?: string;
  fileChooserForId?: string;
};

const FeedComment = (props: FeedCommentProps) => {
  const {
    comment,
    onPostReply,
    onAddGiphy,
    onAddAttachment,
    addAutoMention,
    canManage,
    getReplyData = () => ({ text: "", media: [] }),
    feed = [],
    updateReply,
    deleteComment,
    deleteAttachment,
    getFile,
    pin,
    unpin,
    reactComment,
    authors,
    isLastChild,
    commentLoading,
    disableReacts,
    disableAttachments,
    disableReplies,
    replyToParent,
    canPost,
    fileChooserForType,
    fileChooserForId,
  } = props;

  const [collapsed, setCollapsed] = useState(false);
  const [replyBoxOpen, setReplyBoxOpen] = useState(false);
  const [giphyPickerOpen, setGiphyPickerOpen] = useState(false);
  const [highlightComment, setHighlightComment] = useState(false);
  const replyRef = useRef();

  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setHighlightComment(searchParams.get("comment") === comment?._id);
  }, [searchParams, comment?._id]);

  const handleCollapsing = () => {
    setCollapsed((prev) => !prev);
  };

  const postReply = (commentId) => {
    if (onPostReply) {
      onPostReply(commentId);
    }
    setReplyBoxOpen(false);
    setCollapsed(false);
  };

  const openGiphyPicker = () => setGiphyPickerOpen(true);
  const closeGiphyPicker = () => setGiphyPickerOpen(false);

  const addGiphy = (gif) => {
    setGiphyPickerOpen(false);
    onAddGiphy(gif, comment._id);
  };

  const addAttachment = (s, f, d) => {
    onAddAttachment(s, f, d, comment._id);
  };

  const replyToExistingReply = (user) => {
    // If we are replying to an existing reply
    // We just open the comment box for the parent instead
    // With a pre-populated mention to the user we were originally attempting to reply to
    if (!addAutoMention) return;
    setReplyBoxOpen(true);

    // This is a helper function to focus the rich text box
    // It finds the last one open on the page (which we are assuming is the reply box)
    // And focusing the cursor after the @ mention
    setTimeout(() => {
      addAutoMention(comment._id, user);

      const remirrors = document.getElementsByClassName("remirror-editor") as HTMLCollectionOf<HTMLInputElement>;
      const last = remirrors[remirrors.length - 1];
      last.focus();

      const range = document.createRange();
      const sel = window.getSelection();

      const lastChild = last.childNodes[last.childNodes.length - 1];
      range.setStart(lastChild, lastChild.childNodes.length);
      range.collapse(true);

      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }, 150);
  };

  const children = feed.filter((f) => f.parent === comment._id);
  if (!comment.owner) return null;
  const reacts: Array<{
    emoji: string;
    count: number;
    users: OpenAPI.Schemas["User"][];
    hasReacted: boolean;
  }> = [];
  comment.reacts &&
    comment.reacts.forEach((react) => {
      let index = reacts.map((r) => r.emoji).indexOf(react.emoji);
      if (index === -1) {
        reacts.push({
          ...react,
          count: 0,
          users: [],
          hasReacted: false,
        });
        index = reacts.length - 1;
      }
      reacts[index].users.push(react.ownerUser);
      reacts[index].count += 1;
      if (react.user === user._id) reacts[index].hasReacted = true;
    });
  const { text: replyText = "", media: replyMedia = [] } = getReplyData(comment._id);

  const isChildComment = comment.ownerIdea && comment.ownerIdea.parent;

  return (
    <CommentContainer
      key={comment._id}
      $isPinned={comment.isPinned}
      $isChild={!!comment.parent}
      $isHighlighted={highlightComment}
    >
      <div>
        {comment.isPinned ? (
          <Popup
            content="This comment is pinned"
            trigger={
              <Label size="small" color="yellow">
                <Icon name="pin" style={{ margin: 0 }} /> Pinned
              </Label>
            }
          />
        ) : null}
        {comment.byAssignee ? (
          <Popup
            content={`${comment.owner.profile.fullName} is assigned to this ${t("generic.idea")}`}
            trigger={
              <Label size="small" color="teal">
                Assigned to this {t("generic.idea")}
              </Label>
            }
          />
        ) : null}
        {comment.isMergeJustification ? (
          <Popup
            content={`This comment was left as an explanation for why these ${t("generic.ideas")} were merged`}
            trigger={
              <Label size="small" color="blue">
                This comment is {t("generic.ideaWithArticle")} merge explanation
              </Label>
            }
          />
        ) : null}
        {isChildComment ? (
          <Popup
            content={`This comment is originally from the ${t("generic.idea")} "${comment.ownerIdea.name}"`}
            trigger={<Label size="small">This comment is from a merged {t("generic.idea")}</Label>}
          />
        ) : null}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex" }}>
          <CollapseIcon $collapsed={collapsed} name="chevron right" onClick={handleCollapsing} />
          <AuthorContainer>
            <UserChip
              precedingText={
                comment.userStatus === "ideaOwner" ? `${t("common:capitalise", { key: "generic.idea" })} author` : null
              }
              user={comment.owner}
              compact
            />
          </AuthorContainer>
        </div>
        <span
          style={{
            fontSize: "0.9em",
            color: "gray",
            lineHeight: 1.2,
          }}
        >
          {moment(comment.createdAt).fromNow()}
        </span>
      </div>
      <ContentContainer
        $isChild={!!comment.parent}
        $isPinned={comment.isPinned}
        $isHighlighted={highlightComment}
        $lastComment={isLastChild}
        $collapsed={collapsed}
        className="content-container"
      >
        <TextContainer>
          <MultiLineParagraph
            mentionedUsers={comment.mentionedUsers}
            mentionedUserAccess={comment.mentionedUserAccess}
            content={comment.comment || ""}
          />
        </TextContainer>
        {comment.media && comment.media.length ? (
          <MediaContainer $margined>
            {comment.media.map((media, i) => {
              if (media.type === "image") {
                if (comment.media.length === 1) {
                  return (
                    <div key={i} style={{ width: "100%" }}>
                      <a href={getFile(media)} rel="noopener noreferrer" target="_blank">
                        <Image
                          rounded
                          bordered
                          style={{ objectFit: "contain" }}
                          alt="Comment attachment"
                          className="comment-img"
                          src={getFile(media)}
                        />
                      </a>
                    </div>
                  );
                }
                return (
                  <div
                    key={i}
                    style={{
                      minWidth: 100,
                      maxWidth: 200,
                      height: 150,
                      maxHeight: 150,
                      overflow: "hidden",
                    }}
                  >
                    <a href={getFile(media)} rel="noopener noreferrer" target="_blank">
                      <Image
                        rounded
                        bordered
                        style={{ objectFit: "contain", height: "100%", width: "100%" }}
                        alt="Comment attachment"
                        className="comment-img"
                        src={getFile(media)}
                      />
                    </a>
                  </div>
                );
              }
              if (media.type === "gif") {
                return <GiphyGif style={{ borderRadius: 5 }} key={media.value} gif={media.data} size="fixed" />;
              }
              return (
                <a
                  href={getFile(media)}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={media.name}
                  className="ui tiny basic button secondary"
                  style={{
                    borderRadius: 5,
                    maxWidth: 200,
                    textWrap: "nowrap",
                    overflow: "hidden",
                    display: "block",
                    textOverflow: "ellipsis",
                  }}
                >
                  <i className="paperclip icon"></i>
                  {media.name}
                </a>
              );
            })}
          </MediaContainer>
        ) : null}

        {(!disableReacts && (canPost || canManage)) || reacts?.length ? (
          <MediaContainer $margined>
            {reacts?.map((react, idx) => (
              <Popup
                key={idx}
                clickable="true"
                trigger={
                  <EmojiLabel
                    size="tiny"
                    onClick={() => reactComment(comment._id, react.emoji, !react.hasReacted)}
                    color={react.hasReacted ? "yellow" : "grey"}
                  >
                    <Emoji style={{ display: "inline-block" }} emoji={{ id: react.emoji }} size={14} />
                    <span
                      style={{
                        verticalAlign: "top",
                        display: "inline-block",
                        marginLeft: 5,
                        marginTop: 2,
                      }}
                    >
                      {react.count}
                    </span>
                  </EmojiLabel>
                }
                content={
                  <p style={{ fontSize: 11 }}>
                    {`${react?.users
                      ?.map((u) => (u?._id === user._id ? "You" : u?.profile?.fullName || "Someone"))
                      .join(" and ")} reacted with :${react?.emoji}:`}
                  </p>
                }
              />
            ))}
            {!disableReacts && (canPost || canManage) && (
              <Popup
                flowing
                on="click"
                basic
                size="tiny"
                position="top right"
                trigger={
                  <NewEmojiLabel
                    size="tiny"
                    style={{ padding: "2px 4px 1px 4px", maxWidth: "none", cursor: "pointer" }}
                    color={"grey"}
                  >
                    <Emoji style={{ display: "inline-block" }} emoji={{ id: "grinning" }} size={14} />
                    <Icon name="plus" style={{ verticalAlign: "top", marginRight: 0, marginLeft: 4, marginTop: 2 }} />
                  </NewEmojiLabel>
                }
                content={<EmojiChooser onComplete={(em) => reactComment(comment._id, em, true)} />}
              />
            )}
          </MediaContainer>
        ) : null}
        <ActionsContainer>
          {!disableReplies && (canPost || canManage) ? (
            <span
              onClick={() => {
                if (!comment.parent) {
                  updateReply(comment._id, "");
                  setReplyBoxOpen((prev) => !prev);
                } else if (replyToParent) {
                  replyToParent(comment.owner);
                }
              }}
            >
              {t("feeds.comments.reply")}
            </span>
          ) : null}
          {comment.owner._id === user._id || canManage ? (
            <span onClick={() => deleteComment(comment._id)}>{t("generic.delete")}</span>
          ) : null}
          {(canManage || (authors && authors.indexOf(user._id) !== -1)) && !comment.parent ? (
            <>
              {comment.isPinned ? (
                <span onClick={() => unpin(comment._id)}>{t("feeds.pin.unPin")}</span>
              ) : (
                <span onClick={() => pin(comment._id)}>{t("feeds.pin.pin")}</span>
              )}
            </>
          ) : null}
        </ActionsContainer>
        {(children || []).map((child) => (
          <FeedComment
            {...props}
            key={child._id}
            comment={child}
            replyToParent={replyToExistingReply}
            canPost={canPost}
          />
        ))}
        {replyBoxOpen && canPost && (
          <div style={{ alignSelf: "stretch", marginBottom: 7.5 }} className="feed-replies">
            <InputContainer>
              <MentionText
                value={replyText}
                onChange={(value) => updateReply(comment._id, value)}
                editorRef={replyRef}
              />

              <MediaContainer $margined>
                {replyMedia.map((file, index) => {
                  switch (file.type) {
                    case "gif":
                      return (
                        <GiphyGif
                          onClickGif={() => deleteAttachment(index, comment._id)}
                          canDelete={true}
                          key={file.value}
                          gif={file.data}
                          size="fixed_small"
                        />
                      );
                    case "image":
                      return (
                        <div style={{ position: "relative" }}>
                          <Image
                            bordered
                            rounded
                            spaced
                            src={file.signedDownloadRequest}
                            width={75}
                            height={75}
                            style={{ objectFit: "cover" }}
                          />
                          <Icon
                            name="close"
                            onClick={() => deleteAttachment(index, comment._id)}
                            style={{ cursor: "pointer", position: "absolute", top: 3, right: 8 }}
                          />
                        </div>
                      );
                    default:
                      return (
                        <Label key={file.value}>
                          {file.name} <Icon name="close" onClick={() => deleteAttachment(index, comment._id)} />
                        </Label>
                      );
                  }
                })}
              </MediaContainer>

              <ActionArea>
                <div>
                  <ActionButton
                    title={"Mention user"}
                    position="top left"
                    iconName="user"
                    onClick={(e) => {
                      e.preventDefault();
                      const newComment = `${replyText}@`;
                      updateReply(comment._id, newComment);

                      setTimeout(() => {
                        if (replyRef.current) {
                          // @ts-ignore
                          replyRef.current.focus(100000);
                        }
                      }, 150);
                    }}
                  />
                  {!disableAttachments && onAddAttachment ? (
                    <FileChooser
                      forType={fileChooserForType}
                      forId={fileChooserForId}
                      trigger={<ActionButton title={t("feeds.attachments.attach")} iconName="paperclip" />}
                      onComplete={(s, f, d) => addAttachment(s, f, d)}
                    />
                  ) : null}
                  {!disableAttachments && onAddGiphy ? (
                    <GiphyPicker
                      onChoose={(gif) => addGiphy(gif)}
                      trigger={
                        <ActionButton iconName="file image outline" title="Find a GIF" onClick={openGiphyPicker} />
                      }
                      open={giphyPickerOpen}
                      onClose={closeGiphyPicker}
                    />
                  ) : (
                    <div />
                  )}
                </div>
                <ActionButton
                  disabled={!(replyText || replyMedia.length)}
                  onClick={() => postReply(comment._id)}
                  content="Post reply"
                  iconName={commentLoading ? "spinner" : "send"}
                />
              </ActionArea>
            </InputContainer>
          </div>
        )}
      </ContentContainer>
    </CommentContainer>
  );
};

export default FeedComment;

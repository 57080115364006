import React from "react";
import { Trans } from "react-i18next";

export default function DeadlineApproaching({ ownerIdea = { name: "Undefined" } }) {
  return (
    <Trans i18nKey="notifications.ideaDeadlineApproaching">
      The deadline for your idea
      <b>{{ idea: ownerIdea.name }}</b> is approaching. Make sure you submit your idea before the deadline!
    </Trans>
  );
}

export const notifications = (api) => ({
  get(page, success, fail) {
    api.authenticatedRequest("GET", `/notifications?page=${page}`, null, success, fail);
  },
  markAsSeen(notificationIds, markAllAsSeen, success, fail) {
    api.authenticatedRequest(
      "PUT",
      `/notifications/seen`,
      { notifications: notificationIds, markAllAsSeen },
      success,
      fail,
    );
  },
  markAsInteracted(notification, success, fail) {
    api.authenticatedRequest("PUT", `/notifications/${notification}/interacted`, null, success, fail);
  },
});

import { PlainExtension } from "remirror";

// Expose an easily chainable command to refocus the view after a button press
export class RefocusExtension extends PlainExtension {
  name = "refocus";

  createCommands() {
    return {
      refocus:
        () =>
        ({ view }) => {
          view.focus();
        },
    };
  }
}

import React from "react";
import { Trans } from "react-i18next";

export default function StageChangedContent({ ownerChallenge = { name: "Undefined" } }) {
  return (
    <Trans i18nKey="notifications.stageChangedFound">
      A new challenge <b>{{ challenge: ownerChallenge?.name || "Undefined" }}</b> has been found matching your
      interests. Check it out now!
    </Trans>
  );
}

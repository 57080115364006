/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Container, Message, Divider, Checkbox, Icon, Table, Radio, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { supportsPush } from "utils/pushMessaging";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import api from "api";
import util from "utils/utils";
import actions from "actions";
import constants from "utils/constants";
import PushSetupModal from "components/lib/PushSetupModal";

import NotificationFrequency from "./NotificationFrequency";

function Subscription({ sub, hasSub, toggleSub }) {
  const { t } = useTranslation();

  return (
    <Table.Row>
      <Table.Cell>
        <p style={{ marginBottom: sub.subtext ? 0 : 2.5 }}>
          <em>{t(sub.title)}</em>
          <br />
          {t(sub.text)}
        </p>
        {sub.subtext && <span style={{ color: "gray", marginBottom: 5, fontSize: "0.9em" }}>{sub.subtext}</span>}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Checkbox
          disabled={sub.unsupportedMethods?.indexOf("email") > -1}
          data-testid={`test-email-${sub.key}`}
          data-tooltip={sub.unsupportedMethods?.indexOf("email") > -1 ? "This method is not available" : null}
          style={{ margin: 5 }}
          checked={hasSub(sub.key, "email")}
          onChange={(e, c) => toggleSub(sub.key, "email", c.checked)}
        />
      </Table.Cell>
      <Table.Cell>
        <Checkbox
          disabled={sub.unsupportedMethods?.indexOf("push") > -1}
          data-testid={`test-push-${sub.key}`}
          data-tooltip={sub.unsupportedMethods?.indexOf("push") > -1 ? "This method is not available" : null}
          style={{ margin: 5 }}
          checked={hasSub(sub.key, "push")}
          onChange={(e, c) => toggleSub(sub.key, "push", c.checked)}
        />
      </Table.Cell>
    </Table.Row>
  );
}

function NotificationSection({ title, children, id }) {
  return (
    <Table id={id}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{title}</Table.HeaderCell>
          <Table.HeaderCell collapsing>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Icon name="mail" />
              <p>Email</p>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Icon name="bell" />
              <p>Push</p>
            </div>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{children}</Table.Body>
    </Table>
  );
}

const general = constants.subscriptions.filter((s) => s.type === "general");
const ideas = constants.subscriptions.filter((s) => s.type === "ideas");
const challenges = constants.subscriptions.filter((s) => s.type === "challenges");
const groups = constants.subscriptions.filter((s) => s.type === "groups");

export function UserSettingsNotifications({ user, onSubToggled }) {
  const subs = user.eventSubscriptions || {};

  const { t } = useTranslation();

  const [pushSetupOpen, setPushSetupOpen] = useState(() => {
    if (supportsPush() && Notification.permission === "default") {
      if (util.localStorageIsSupported()) {
        const hasSeenPushSetupModal = localStorage.getItem("hasSeenPushSetupModal");
        if (!hasSeenPushSetupModal) {
          localStorage.setItem("hasSeenPushSetupModal", true);
          return true;
        }
      }
    }
    return false;
  });
  const [bulkAction, setBulkAction] = useState("all");

  useEffect(() => {
    api.users.getNotificationPreferences(user._id, (data) => {
      onSubToggled(data.eventSubscriptions);
    });
  }, [user._id, onSubToggled]);

  function hasSub(key, method) {
    const sub = subs[key];
    if (!sub) {
      return true;
    }
    return sub.indexOf(method) > -1;
  }

  function toggleSub(key, method, checked) {
    let methods = [...(subs[key] || [])];
    if (!methods.length && checked) {
      methods = [method];
    }
    if (methods.length) {
      const index = methods.indexOf(method);
      if (checked && index === -1) {
        methods.push(method);
      } else if (!checked && index > -1) {
        methods.splice(index, 1);
      }
    }
    api.users.updateNotificationPreferences(
      user._id,
      [key],
      methods,
      (data) => {
        onSubToggled(data.eventSubscriptions);
      },
      (err) => toast.error(err.message),
    );
  }

  return (
    <Container>
      <PushSetupModal open={pushSetupOpen} setOpen={setPushSetupOpen} />
      <h3>{t("users.preferences.email.title")}</h3>
      <p>{t("users.preferences.email.info")}</p>

      <NotificationFrequency />

      <h3 style={{ display: "block" }}>Update preferences</h3>
      <p>
        Use these controls to update all your preferences at once or use the checkboxes in the table below to control
        individual notification types.
      </p>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <Radio
          label="Subscribe to all notifications"
          checked={bulkAction === "all"}
          value="all"
          onChange={(e, { value }) => setBulkAction(value)}
        />
        <Radio
          label="Subscribe to email notifications"
          checked={bulkAction === "emails"}
          value="emails"
          onChange={(e, { value }) => setBulkAction(value)}
        />
        <Radio
          label="Subscribe to push messaging"
          checked={bulkAction === "push"}
          value="push"
          onChange={(e, { value }) => setBulkAction(value)}
        />
        <Radio
          label="Unsubscribe from all"
          checked={bulkAction === "unsubscribe"}
          value="unsubscribe"
          onChange={(e, { value }) => setBulkAction(value)}
        />
        <div>
          <Button
            onClick={() => {
              const subscriptions = constants.subscriptions.map((s) => s.key);
              let methods = [];
              switch (bulkAction) {
                case "all":
                  methods = ["email", "push"];
                  break;
                case "emails":
                  methods = ["email"];
                  break;
                case "push":
                  methods = ["push"];
                  break;
                case "unsubscribe":
                  break;
                default:
                  break;
              }

              api.users.updateNotificationPreferences(
                user._id,
                subscriptions,
                methods,
                (data) => {
                  onSubToggled(data.eventSubscriptions);
                },
                (err) => toast.error(err.message),
              );
            }}
          >
            Apply
          </Button>
        </div>
      </div>

      <Divider />

      <h3 style={{ display: "block" }}>{t("users.preferences.email.action")}</h3>

      {supportsPush() && Notification.permission === "default" ? (
        <Message info icon>
          <Icon name="bell" />
          <Message.Content>
            You have not yet enabled push notifications.&nbsp;
            <a href="#" onClick={() => setPushSetupOpen(true)}>
              Click here
            </a>
            &nbsp;to enable them.
          </Message.Content>
        </Message>
      ) : null}

      {supportsPush() && Notification.permission === "denied" ? (
        <Message warning>
          You have turned off push notifications for SimplyDo in this browser. You have to re-enable them in your
          browser settings to start receiving them again.
        </Message>
      ) : null}

      <NotificationSection title={t("users.preferences.yourAccount.title")} id="subscription-account">
        {general.map((sub) => (
          <Subscription key={sub.key} sub={sub} hasSub={hasSub} toggleSub={toggleSub} />
        ))}
      </NotificationSection>

      <Divider hidden />

      <NotificationSection title="Groups" id="subscription-groups">
        {groups.map((sub) => (
          <Subscription key={sub.key} sub={sub} hasSub={hasSub} toggleSub={toggleSub} />
        ))}
      </NotificationSection>

      <Divider hidden />

      <NotificationSection title={t("common:capitalise", { key: "generic.ideas" })} id="subscription-ideas">
        {ideas.map((sub) => (
          <Subscription key={sub.key} sub={sub} hasSub={hasSub} toggleSub={toggleSub} />
        ))}
      </NotificationSection>

      <Divider hidden />

      <NotificationSection title={t("users.preferences.yourChallenges.title")} id="subscription-challenges">
        {challenges.map((sub) => (
          <Subscription key={sub.key} sub={sub} hasSub={hasSub} toggleSub={toggleSub} />
        ))}
      </NotificationSection>

      <Divider hidden />
      <Message>{t("users.preferences.email.message")}</Message>
    </Container>
  );
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onSubToggled: (sub, on) => dispatch(actions.user.toggleEventSubscription(sub, on)),
});
const UserSettingsNotificationsContainer = connect(mapStateToProps, mapDispatchToProps)(UserSettingsNotifications);

export default UserSettingsNotificationsContainer;

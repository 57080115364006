import React, { useState, useEffect, useRef } from "react";
import { Modal, Icon, Button, Input } from "semantic-ui-react";
import { connect } from "react-redux";
import { isIE } from "react-device-detect";
import toast from "react-hot-toast";
import styled from "styled-components";
import api from "api";
import util from "utils/utils";

const StyledContent = styled(Modal.Content)`
  > p {
    margin-bottom: 8px;
  }
`;

function EdgeSuggestionModal({ user }) {
  const [edgeSuggestPopupOpen, setEdgeSuggestPopupOpen] = useState(false);
  const code = util.code() || "simplydo";
  const urlLink = useRef(null);

  useEffect(() => {
    if (isIE) {
      setEdgeSuggestPopupOpen(true);
    }
  }, []);

  const copyToClipboard = () => {
    urlLink.current.select();
    document.execCommand("Copy");
    toast.success("URL copied");
  };

  const directToEdge = (url) => {
    window.location.href = `microsoft-edge:${url}`;
  };

  const generateUrl = () => {
    let url = window.location.href;
    if (user) {
      api.auth.generateExchangeToken(
        (data) => {
          url = `http:${code}.simplydo.co.uk/login/with?token=${data.exchangeToken}&next=${window.location.pathname}`;
          directToEdge(url);
        },
        () => {
          directToEdge(url);
        },
      );
    } else {
      directToEdge(url);
    }
  };

  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={edgeSuggestPopupOpen}>
      <StyledContent>
        <h2 style={{ marginBottom: 7 }}>Welcome to SimplyDo!</h2>
        <p>
          We have detected that you are currently using an unsupported browser. This means that parts of the platform
          might look strange or behave in unexpected ways, and as such we recommend switching to a more up-to-date
          browser.
        </p>

        <h3 style={{ marginBottom: 6 }}>
          <Icon name="edge" />
          Microsoft Edge
        </h3>
        <p>If you have Microsoft Edge installed, then please tap the button below to open SimplyDo in Edge.</p>
        <Button primary size="small" content="Open SimplyDo in Microsoft Edge" onClick={() => generateUrl()} />

        <h3 style={{ marginBottom: 6 }}>
          <Icon name="chrome" />
          <Icon name="firefox" />
          Other browsers
        </h3>
        <p>
          SimplyDo also works well on browsers such as Chrome and Firefox. If you have one of these installed you can
          copy your current URL into one of those browsers to continue.
        </p>
        <Input
          ref={urlLink}
          size="mini"
          value={window.location.href}
          readOnly
          action={<Button size="mini" icon="copy" content="Copy current URL" onClick={() => copyToClipboard()} />}
        />

        <h4 style={{ marginBottom: 5 }}>Continue with your current browser (not recommended)</h4>
        <p>To continue using SimplyDo with your current browser, please tap the button below.</p>
        <Button size="mini" content="Continue with current browser" onClick={() => setEdgeSuggestPopupOpen(false)} />
      </StyledContent>
    </Modal>
  );
}
const mapStateToProps = (state) => ({ user: state.user });
const EdgeSuggestionModalContainer = connect(mapStateToProps)(EdgeSuggestionModal);

export default EdgeSuggestionModalContainer;

import { reports } from "api/reports";
import api from "api";

const reportsApi = {
  req(method, path, data, success, fail) {
    api.auth.requestReportsToken(
      path,
      ({ token }) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, `${import.meta.env.VITE_REPORTS_URL}${path}`);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              let response;
              try {
                response = JSON.parse(xhr.responseText);
              } catch (err) {
                console.error(err);
              }
              if (success) success(response);
            } else {
              let message;
              try {
                message = JSON.parse(xhr.responseText).message;
              } catch (err) {
                if (fail) {
                  fail({ status: xhr.status, message: "There was a problem with this request" });
                }
              }
              if (fail) {
                fail({ status: xhr.status, message });
              }
            }
          }
        };
        xhr.send(data && JSON.stringify(data));
      },
      (err) => {
        if (fail) {
          fail(err);
        }
      },
    );
  },

  authenticatedRequest(method, path, data, success, fail) {
    reportsApi.req(method, path, data, success, fail);
  },
};
reportsApi.reports = reports(reportsApi);

export default reportsApi;

import React from "react";

import { Icon, Button, Modal, Checkbox, Portal } from "semantic-ui-react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

import { Column } from "./types";

type TableRowProps<ItemType extends Record<string, any>> = {
  provided: any;
  snapshot: any;
  column: Column<ItemType>;
  updateColumn: (key: string, column: Column<ItemType>) => void;
};

const ReorderableRow = styled.div<{ $isDragging?: boolean }>`
  user-select: none;
  padding: 10px 0;
  margin: 0 0 5px 0;
  border-radius: 5px;
  background: ${({ $isDragging }) => ($isDragging ? "lightblue" : "transparent")};
  cursor: pointer;
`;

const TableRow = <ItemType extends Record<string, any>>({
  provided,
  snapshot,
  column,
  updateColumn,
}: TableRowProps<ItemType>) => {
  const { isDragging } = snapshot;
  const child = (
    <ReorderableRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      $isDragging={snapshot.isDragging}
      style={provided.draggableProps.style}
    >
      <Icon name="bars" style={{ marginLeft: 10, marginRight: 10 }} />
      <Checkbox
        toggle
        checked={column.enabled}
        onChange={(e, check) => {
          if ((check.checked && !column.enabled) || (!check.checked && column.enabled)) {
            updateColumn(column.key as string, { ...column, enabled: !!check.checked });
          }
        }}
        label={column.settingName ?? column.name}
      />
    </ReorderableRow>
  );

  if (!isDragging) {
    return child;
  }

  return <Portal open={true}>{child}</Portal>;
};

type TableSettingsProps<ItemType extends Record<string, any>> = {
  open: boolean;
  onClose: () => void;
  columns: Column<ItemType>[];
  updateColumns: (newColumns: Column<ItemType>[] | null) => void;
};

const TableSettings = <ItemType extends Record<string, any>>({
  open,
  onClose,
  columns,
  updateColumns,
}: TableSettingsProps<ItemType>) => {
  const reorderColumns = ({ destination, source }) => {
    if (!destination) {
      return;
    }

    const orderIndices = columns.map((_, i) => i);
    const [removed] = orderIndices.splice(source.index, 1);
    orderIndices.splice(destination.index, 0, removed);

    const newSettings = columns.map((col, i) => ({
      ...col,
      order: orderIndices.indexOf(i),
    }));

    updateColumns(newSettings);
  };

  const updateColumn = (key, newColumn) => {
    const index = columns.findIndex((col) => col.key === key);
    if (index === -1) return;

    const newColumns = [...columns];
    newColumns[index] = newColumn;
    updateColumns(newColumns);
  };

  return (
    <DragDropContext onDragEnd={reorderColumns}>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={open}
        closeOnDimmerClick
        onClose={onClose}
      >
        <Modal.Header>Update table</Modal.Header>
        <Modal.Content>
          <h3>Selected columns</h3>
          <p>
            Table columns can be modified here. Use the toggle to only display information that is relevant to you.
            Table columns can also be reordered via drag and drop.
          </p>
          <p>These settings will be synchronised between devices.</p>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={{ padding: 10 }}>
                {columns.map((col, index) => (
                  <Draggable index={index} key={col.key} draggableId={col.key}>
                    {(inner, snapshot) => (
                      <TableRow provided={inner} snapshot={snapshot} column={col} updateColumn={updateColumn} />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => updateColumns(null)}>Reset to default</Button>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Done" labelPosition="right" icon="checkmark" onClick={onClose} />
        </Modal.Actions>
      </Modal>
    </DragDropContext>
  );
};

export default TableSettings;

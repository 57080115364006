import React from "react";
import util from "utils/utils";
import { Trans, useTranslation } from "react-i18next";

const AnnouncementReport = ({ context, ownerChallenge, ownerGroup }) => {
  const { t } = useTranslation();

  let contextStr = "your";
  let contextName = "organisation";
  if (ownerChallenge) {
    contextStr = `the ${t("generic.challenge")}`;
    contextName = ownerChallenge.name;
  } else if (ownerGroup) {
    contextStr = "the group";
    contextName = ownerGroup.name;
  }

  return (
    <Trans i18nKey="notifications.announcementReport">
      Your announcement <b>{{ announcement: context.subject }}</b> in <span>{{ contextStr }}</span>{" "}
      <b>{{ contextName }}</b> was sent to
      {{ recipients: util.pluralise(context.recipients, "recipient", "recipients") }}
    </Trans>
  );
};

export default AnnouncementReport;

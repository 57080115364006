import { OpenAPI } from "simplydo/interfaces";
import { SearchParams } from "simplydo/core";

type GenerateFileUploadRequest = OpenAPI.GET<"/uploads/file/request">;
type GenerateMultiFileUploadRequest = OpenAPI.POST<"/uploads/file/request/multiple">;

export const uploads = (api) => ({
  generateFileUploadRequest(
    { forType, forId, name, size, type }: GenerateFileUploadRequest["parameters"]["query"],
    success: (res: GenerateFileUploadRequest["response"]) => void,
    fail,
  ) {
    const params = new SearchParams({
      name,
      size: size.toString(),
      type,
      forType,
      forId,
    });
    api.maybeAuthenticatedRequest("GET", `/uploads/file/request${params.toSafeString()}`, null, success, fail);
  },
  generateMultiFileUploadRequest(
    files: GenerateMultiFileUploadRequest["requestBody"]["files"],
    success: (res: GenerateMultiFileUploadRequest["response"]) => void,
    fail,
  ) {
    api.maybeAuthenticatedRequest("POST", "/uploads/file/request/multiple", { files }, success, fail);
  },
  generateVideoUploadRequest({ forType, forId, name, size, type }, success, fail) {
    const params = new SearchParams({
      name,
      size: size.toString(),
      type,
      forType,
      forId,
    });
    api.maybeAuthenticatedRequest("GET", `/uploads/video/request${params.toSafeString()}`, null, success, fail);
  },
  transcodeVideo(data, success, fail) {
    api.authenticatedRequest("POST", "/uploads/video/transcode", data, success, fail);
  },
  checkVideoTranscoded(type, id, fileName, success, fail) {
    api.unauthenticatedRequest("GET", `/uploads/video/check/${type}/${id}/${fileName}`, null, success, fail);
  },
  oneDriveListDirectory(path, success, fail) {
    api.authenticatedRequest("GET", `/uploads/onedrive/directory?path=${path}`, null, success, fail);
  },
  storeOneDriveFileInS3(itemId, itemName, forId, forType, success, fail) {
    const params = new SearchParams({
      itemId,
      itemName,
      forId,
      forType,
    });

    api.authenticatedRequest("PUT", `/uploads/onedrive/store${params.toSafeString()}`, null, success, fail);
  },
});

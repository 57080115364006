import React, { useState, useCallback } from "react";
import { Table, Button, Icon, Header } from "semantic-ui-react";
import handleViewport from "react-in-viewport";
import { FileChooserWithDropzone } from "components/lib/Choosers";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import util from "utils/utils";
import api from "api";
import { EmptyBox } from "components/lib/UI";

const IdeaAttachments = ({ idea, user, t, isEditing, updateIdea }) => {
  const [uploadingAttachment, setUploadingAttachment] = useState(false);
  const { attachments = [] } = idea;

  const addAttachments = useCallback(
    (files) => {
      setUploadingAttachment(true);
      let authToken = null;
      if (util.localStorageIsSupported()) {
        authToken = localStorage.getItem(`ideaAuthToken:${idea._id}`);
      }

      api.ideas.createMultipleAttachments(
        idea._id,
        { attachments: files, authToken },
        ({ attachments: newAttachments }) => {
          setUploadingAttachment(false);
          updateIdea(newAttachments, ["attachments"], true);
          toast.success("File attached successfully");
        },
        (err) => {
          toast.error(err.message);
          setUploadingAttachment(false);
        },
      );
    },
    [idea._id, updateIdea],
  );

  const deleteAttachment = useCallback(
    (uuid) => {
      setUploadingAttachment(true);
      let authToken = null;
      if (util.localStorageIsSupported()) {
        authToken = localStorage.getItem(`ideaAuthToken:${idea._id}`);
      }

      api.ideas.deleteAttachment(
        idea._id,
        uuid,
        ({ attachments: newAttachments }) => {
          setUploadingAttachment(false);
          updateIdea(newAttachments, ["attachments"], true);
          toast("File deleted successfully");
        },
        (err) => {
          toast.error(err.message);
          setUploadingAttachment(false);
        },
        authToken,
      );
    },
    [idea._id, updateIdea],
  );

  const canEdit = util.canEditIdea(user, idea);

  const fileChooserProps = {
    forType: "idea",
    forId: idea._id,
    onCompleteMultiple: addAttachments,
    allowMultiple: true as const,
  };

  return (
    <div>
      <Header as="h4">{t("generic.attachments")}</Header>
      {attachments.length === 0 && (
        <EmptyBox>
          <h2>{t("ideas.attachments.none")}</h2>
          <p>{t("ideas.attachments.info")}</p>
          <FileChooserWithDropzone
            {...fileChooserProps}
            trigger={<Button icon="plus" size="small" content={t("ideas.actions.addAttachment")} />}
          />
        </EmptyBox>
      )}
      <Table basic="very">
        {attachments.map((a) => (
          <Table.Row key={a.uuid}>
            <Table.Cell>
              <a href={a.url} target="_blank" rel="noopener noreferrer">
                <Icon name="paperclip" /> {a.name}
              </a>
            </Table.Cell>
            {canEdit && isEditing && (
              <Table.Cell textAlign="right">
                <Button icon="trash" basic loading={uploadingAttachment} onClick={() => deleteAttachment(a.uuid)} />
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table>
      {canEdit && attachments.length > 0 && isEditing && (
        <FileChooserWithDropzone
          {...fileChooserProps}
          trigger={<Button icon="plus" size="small" content={t("ideas.actions.addAttachment")} />}
        />
      )}
    </div>
  );
};

export default withTranslation()(handleViewport(IdeaAttachments, { threshold: 1 }));

import React, { Component } from "react";
import { Icon, Header } from "semantic-ui-react";

class IconHeader extends Component {
  render() {
    const { as, icon, header, content, wrapped } = this.props;
    if (wrapped) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            <Icon name={icon} style={{ fontSize: 40, height: "0.8em", color: "rgb(75, 92, 107)" }} />
            <Header as={as} style={{ margin: "0 0 5px 0" }}>
              {header}
            </Header>
          </div>
          <div>{content}</div>
        </div>
      );
    }
    return (
      <div style={{ display: "flex", flex: "auto" }}>
        <div style={{ textAlign: "center", paddingTop: 10 }}>
          <Icon name={icon} style={{ fontSize: 40, color: "rgb(75, 92, 107)" }} />
        </div>
        <div style={{ paddingLeft: 10 }}>
          <Header as={as} style={{ marginBottom: 5 }}>
            {header}
          </Header>
          <div>{content}</div>
        </div>

        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

export default IconHeader;

import React from "react";
import { Form } from "semantic-ui-react";
import util from "utils/utils";

const Assessment = ({ field, assessment, updateAssessment }) => {
  const currentScore = assessment?.assessment?.[field.id]?.score;
  return (
    <Form>
      <Form.Input
        width="8"
        disabled={assessment?.isSubmitted}
        label={`Score (between 0 - ${field.options.availableScore})`}
        value={currentScore ?? ""}
        placeholder="Enter a score..."
        required
        icon={currentScore === undefined || currentScore === "" || currentScore === null ? "wait" : "check"}
        onChange={(e) =>
          util.limitNumberInput(e, (val) =>
            updateAssessment(val === "" ? val : Math.max(0, Math.min(field.options.availableScore, val)), [
              "assessment",
              field.id,
              "score",
            ]),
          )
        }
        type="number"
        min="0"
        max={String(field.options.availableScore)}
      />
      <Form.TextArea
        disabled={assessment?.isSubmitted}
        label="Justification or notes"
        width="16"
        placeholder="Add context and reasoning to your evaluation..."
        value={assessment?.assessment?.[field.id]?.notes ?? ""}
        onChange={(e, { value }) => updateAssessment(value, ["assessment", field.id, "notes"])}
      />
    </Form>
  );
};

export default Assessment;

import { useRef, useState, useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import moment from "moment";
import api from "api";
import util from "utils/utils";
import { reducePath } from "./useIdeaEditor";
import { useTranslation } from "react-i18next";

export const useAssessmentEditorHook = (idea, onAssessmentEdited = () => {}) => {
  const [assessmentState, setAssessmentState] = useState({
    assessment: {},
    isLoading: false,
    isSaving: false,
    savedAt: {},
    assessmentIsSubmissable: true,
  });
  const ideaId = idea?._id;
  const isAssessor = idea?.isAssessor;
  const editTimeout = useRef(null);

  const { t } = useTranslation();

  const getAssessment = useCallback(() => {
    if (isAssessor) {
      setAssessmentState((prevState) => ({ ...prevState, isLoading: true }));
      api.ideas.getAssessment(
        ideaId,
        (newAssessment) => {
          const assessmentIsSubmissable = util.assessmentIsSubmissable(
            newAssessment,
            idea?.ideaTemplate,
            idea?.ideaTemplate?.assessmentClosed,
          );
          setAssessmentState((prevState) => ({
            ...prevState,
            isLoading: false,
            assessment: newAssessment,
            assessmentIsSubmissable,
          }));
        },
        () => setAssessmentState((prevState) => ({ ...prevState, isLoading: false })),
      );
    }
  }, [ideaId, isAssessor, idea, setAssessmentState]);

  useEffect(() => getAssessment(), [getAssessment]);

  const saveAssessment = useCallback(
    (fieldId, updatedAssessment) =>
      new Promise(() => {
        setAssessmentState((prevState) => ({ ...prevState, isSaving: true }));
        return api.ideas.updateAssessment(
          ideaId,
          updatedAssessment,
          () => {
            const assessmentIsSubmissable = util.assessmentIsSubmissable(
              updatedAssessment,
              idea?.ideaTemplate,
              idea?.ideaTemplate?.assessmentClosed,
            );
            setAssessmentState((prevState) => ({
              ...prevState,
              isSaving: false,
              assessmentSavedAt: { ...prevState.assessmentSavedAt, [fieldId]: moment() },
              assessmentIsSubmissable,
            }));
          },
          (err) => {
            toast.error(err.message);
            setAssessmentState((prevState) => ({ ...prevState, isSaving: false }));
          },
        );
      }),
    [ideaId, idea, setAssessmentState],
  );

  /*
    Path is as follows: ['assessment_object', 'field_id', 'property']
  */
  const updateAssessment = useCallback(
    (update, path, suppressSave = false) => {
      setAssessmentState((prevState) => {
        const prevAssessment = prevState.assessment || {};
        const updatedAssessment = reducePath(prevAssessment, update, path);

        if (editTimeout.current) {
          clearTimeout(editTimeout.current);
          editTimeout.current = null;
        }
        editTimeout.current = setTimeout(() => {
          // Second item in the path will be the field id, if saving
          if (!suppressSave) saveAssessment(path[1], updatedAssessment);
        }, 400);

        onAssessmentEdited();

        return {
          ...prevState,
          assessment: updatedAssessment,
        };
      });
    },
    [saveAssessment, onAssessmentEdited],
  );

  const submitAssessment = useCallback(() => {
    let scoredAll = true;

    const prompt = assessmentState?.assessment?.conflictOfInterest?.hasConflictOfInterest ? (
      <div>
        Once submitted you will not be able to change your assessment of this {t("generic.idea")}.
        <br />
        <br />
        You have declared a conflict of interest. When you submit this assessment, no assessment scores will be
        submitted and the {t("generic.challenge")} managers will be notified. Are you sure you want to proceed?
      </div>
    ) : (
      <div>
        Once submitted you will not be able to change your assessment of this {t("generic.idea")}. You have given the
        following scores:
        <ul>
          {idea?.ideaTemplate?.body.flatMap((section) => {
            if (section.type !== "assessment") {
              return [];
            }

            return section.fields
              .filter((field) => field.type === "assessment")
              .map((field) => {
                const fieldScore = assessmentState?.assessment?.assessment[field.id]?.score;
                // If the assessment field has not been scored at all it is undefined - only in this case we reject submission
                if (fieldScore === undefined) {
                  scoredAll = false;
                }
                return (
                  <li key={field.title}>
                    {field.title}: {fieldScore ?? 0} / {field.options?.availableScore || 0}
                  </li>
                );
              });
          })}
        </ul>
        If you are happy with these scores, click submit now. If you would like to change anything, please cancel and
        amend.
      </div>
    );

    if (!scoredAll) {
      toast.error("You must score all fields before submitting");
      return;
    }

    util
      .confirm("Ready to submit your assessment?", prompt)
      .then(() => {
        setAssessmentState((prevState) => ({ ...prevState, isSaving: true }));
        api.ideas.submitAssessment(
          ideaId,
          ({ assessment: newAssessment }) => {
            updateAssessment(newAssessment.isSubmitted, ["isSubmitted"], true);
            updateAssessment(newAssessment.submittedAt, ["submittedAt"], true);
            if (onAssessmentEdited) {
              onAssessmentEdited();
            }
            toast.success("Assessment submitted");
            setAssessmentState((prevState) => ({ ...prevState, isSaving: false }));
          },
          (err) => {
            toast.error(`Unable to submit your assessment: ${err.message}`);
            setAssessmentState((prevState) => ({ ...prevState, isSaving: false }));
          },
        );
      })
      .catch(() => {});
  }, [idea, ideaId, updateAssessment, onAssessmentEdited, assessmentState.assessment, t]);

  return {
    assessmentState,
    updateAssessment,
    submitAssessment,
  };
};

export default useAssessmentEditorHook;

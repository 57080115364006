import React, { Component } from "react";
import { Label, Table, Input, Icon } from "semantic-ui-react";
import { UserChip } from "components/lib/Chips";
import withTheme from "src/theme/withTheme";
import { withTranslation } from "react-i18next";

class AdjustableUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };
  }

  renderListItem = (user) => {
    const { t } = this.props;
    const { actions, infoCells, isGroup, isTeam, onClickUser = () => {} } = this.props;
    const isAdmin = this.props.isAdmin && this.props.isAdmin(user);
    const actionContent = actions && actions(user);
    let label = "Admin";
    if (isGroup) {
      label = "Leader";
    } else if (isTeam) {
      label = `${t("common:capitalise", { key: "generic.idea" })} Owner`;
    }
    return (
      <Table.Row key={user._id} onClick={() => onClickUser(user)}>
        <Table.Cell>
          <UserChip user={user} style={{ marginRight: 5 }} />
          {isAdmin && (
            <Label size="tiny" color="purple">
              <Icon name={isTeam ? "lightbulb" : "rocket"} />
              {label}
            </Label>
          )}
        </Table.Cell>
        <Table.Cell> {user.profile.tagline} </Table.Cell>
        {infoCells && infoCells(user)}
        {actionContent && (
          <Table.Cell textAlign="right" collapsing>
            {actionContent}
          </Table.Cell>
        )}
      </Table.Row>
    );
  };

  render() {
    const { results, searchPlaceholder, searchFunction, hideSearch, primaryAction, loading } = this.props;
    const { searchValue } = this.state;
    return (
      <div>
        {hideSearch ? null : (
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
            {searchFunction && (
              <Input
                style={{ flex: 1, marginRight: 5 }}
                fluid
                placeholder={searchPlaceholder || "Search..."}
                iconPosition="left"
                value={searchValue}
                icon={loading ? "spinner" : "search"}
                onChange={(e, { value }) => this.setState({ searchValue: value }, () => searchFunction(value))}
              />
            )}
            {primaryAction}
          </div>
        )}
        <Table basic="very">
          <Table.Body>{results.map((result) => this.renderListItem(result))}</Table.Body>
        </Table>
      </div>
    );
  }
}

export default withTranslation()(withTheme(AdjustableUserList));

import { useCallback, useEffect, useRef, RefObject, useState } from "react";

export type SectionRefs = Record<string, RefObject<HTMLElement>>;

/*
  Callback for scrolling; So we can set the active item once scroll has completed
*/
const scrollToCallback = (offset, callback = () => {}, scrollContainer = undefined, scrollOffset = 0) => {
  if (!scrollContainer) {
    // If no scroll container is provided, use the window
    const fixedOffset = offset.toFixed();
    const onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener("scroll", onScroll);
        callback();
      }
    };
    window.addEventListener("scroll", onScroll);
    onScroll();
    window.scrollTo({
      top: offset + scrollOffset,
      left: 0,
      behavior: "smooth",
    });
  } else {
    // If a scroll container is provided, scroll within that container
    const container = scrollContainer.current;
    if (container) {
      const fixedOffset = offset.toFixed();
      const onScroll = function () {
        if (container.scrollTop.toFixed() === fixedOffset) {
          container.removeEventListener("scroll", onScroll);
          callback();
        }
      };
      container.addEventListener("scroll", onScroll);
      onScroll();
      container.scrollTo({
        top: offset + scrollOffset,
        left: 0,
        behavior: "smooth",
      });
    }
  }
};

const useIdeaScrollTracking = (
  ideaId: string,
  sectionRefs: SectionRefs,
  divRef?: RefObject<HTMLElement>, // Optional divRef parameter
  scrollOffset?: number, // Optional scroll offset parameter
): {
  activeItemId: string | null;
  setActiveItemId: (itemId: string | null) => void;
  scrollToSection: (itemId: string) => void;
} => {
  const lastScrollPos = useRef(0);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);

  // On load set the active item to the first section
  useEffect(() => {
    if (sectionRefs?.current && Object.keys(sectionRefs.current).length > 0 && !activeItemId) {
      setActiveItemId(Object.keys(sectionRefs.current)[0]);
    }
  }, [activeItemId, ideaId, sectionRefs]);

  const getScrollContainer = useCallback(() => {
    // Use divRef if provided, or fallback to window
    return divRef ? divRef.current : window;
  }, [divRef]);

  const scrollTo = useCallback(
    (distance, itemId) => {
      scrollToCallback(distance, () => setActiveItemId(itemId), divRef ? divRef : undefined, scrollOffset ?? 0);
    },
    [setActiveItemId, scrollOffset, divRef],
  );

  const scrollToSection = useCallback(
    (itemId) => {
      const scrollContainer = getScrollContainer();

      if (scrollContainer && sectionRefs?.current[itemId]) {
        const sectionRef = sectionRefs.current[itemId];
        const offsetTopInSection = sectionRef.offsetTop;

        // Calculate the offset relative to the top of the scroll container
        const isWindow = scrollContainer === window;
        // @ts-ignore
        const scrollContainerTop = isWindow ? 0 : scrollContainer.getBoundingClientRect().top;
        const offsetTop = offsetTopInSection - scrollContainerTop;

        // Adjust the scroll position to consider a fixed offset (e.g., -100)
        const scrollToOffset = offsetTop - 100;

        scrollTo(scrollToOffset, itemId);
      }
    },
    [getScrollContainer, scrollTo, sectionRefs],
  );

  useEffect(() => {
    const onScroll = () => {
      const scrollContainer = getScrollContainer();
      if (!scrollContainer) {
        return;
      }
      if (!sectionRefs?.current) {
        return;
      }

      const isWindow = scrollContainer === window;
      // @ts-ignore
      const y = isWindow ? parseInt(window.pageYOffset.toFixed(), 10) : scrollContainer.scrollTop;
      // @ts-ignore
      const windowHeight = isWindow ? window.innerHeight : scrollContainer.clientHeight;

      const direction = y > lastScrollPos.current ? "down" : "up";
      lastScrollPos.current = y;

      const foundSectionId = Object.keys(sectionRefs?.current).find((sectionId) => {
        const sectionRef = sectionRefs.current[sectionId];
        if (!sectionRef) {
          return false; // Skip if the ref is undefined
        }
        const offsetTop = sectionRef.offsetTop;
        const clientHeight = sectionRef.clientHeight;

        if (direction === "down") {
          return offsetTop >= y + 100 || offsetTop + clientHeight >= y + 100 + windowHeight;
        }

        return offsetTop + clientHeight >= y + 100;
      });

      if (foundSectionId) {
        setActiveItemId(foundSectionId);
      }
    };

    const scrollContainer = getScrollContainer();
    scrollContainer.addEventListener("scroll", onScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", onScroll);
    };
  }, [setActiveItemId, sectionRefs, divRef, getScrollContainer]);

  return {
    activeItemId,
    setActiveItemId,
    scrollToSection,
  };
};

export default useIdeaScrollTracking;

import { Segment, Grid } from "semantic-ui-react";
import styled from "styled-components";

export const BodySegment = styled(Segment)`
  border: 0px solid;
`;

export const BodyContainer = styled(Grid.Column)`
  border: none;
`;

export const InfoTextHeader = styled.div`
  font-size: 9px;
  color: gray;
  text-align: right;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  > h3 {
    margin: 0;
    display: block;
    font-size: 1.5em;
  }
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e9ebee;
`;

import React, { useCallback, useState } from "react";
import { Modal, Button, Input, Message, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { withTranslation } from "react-i18next";
import { UserChip } from "components/lib/Chips";
import actions from "actions";
import styled from "styled-components";
import api from "api";
import util from "utils/utils";

const ModalContainer = styled(Segment)`
  display: flex;
  ${({ $marginedTop }) => $marginedTop && "margin-top: 12.5px;"}
`;

const ParticipantContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ActionArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Participant = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ChatSettings = ({ thread, onMessageThreadSelected, user, modalOpen, closeModal, t, closeThread }) => {
  const [searchState, setSearchState] = useState({
    results: [],
    searching: false,
    searchValue: "",
  });
  const { results } = searchState;
  const { participantUsers = [], participants = [], participantsMuted = [] } = thread || {};
  const threadId = thread?._id;
  const isMuted = participantsMuted.indexOf(user._id) > -1;

  const addParticipant = useCallback(
    (newId) => {
      util
        .confirm(
          "Add participant",
          "Adding a participant will allow them to view and reply to any message in this chat. This includes messages that were sent before this user was added. Are you sure you want to add this user to the chat?",
        )
        .then(() => {
          const newParticipants = [...participants, newId];
          api.messages.updateThread(
            threadId,
            { participants: newParticipants },
            (newThread) => {
              onMessageThreadSelected(newThread);
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {});
    },
    [participants, threadId, onMessageThreadSelected],
  );

  const searchMessageRecipient = useCallback(
    (e) => {
      setSearchState((prevState) => ({ ...prevState, searching: true, searchValue: e?.target?.value }));
      api.search.messageRecipient(
        e?.target?.value || "",
        ({ users }) => {
          const filteredUsers = users.filter((u) => !participants.includes(u._id));
          setSearchState((prevState) => ({ ...prevState, searching: false, results: filteredUsers }));
        },
        (err) => {
          toast.error(err.message);
          setSearchState((prevState) => ({ ...prevState, searching: false }));
        },
      );
    },
    [setSearchState, participants],
  );

  const leaveThread = useCallback(
    () =>
      util
        .confirm(
          "Leave chat",
          "Leaving this chat means you will not receive any more messages and will lose access to its messages. Do you really want to leave this chat?",
        )
        .then(() => {
          api.messages.leaveThread(
            threadId,
            () => {
              onMessageThreadSelected(null);
              closeModal();
              closeThread(threadId);
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {}),
    [threadId, onMessageThreadSelected, closeThread, closeModal],
  );

  const handleSearchChange = useCallback(
    (e) => {
      e.persist();
      searchMessageRecipient(e);
    },
    [searchMessageRecipient],
  );

  const muteThread = useCallback(() => {
    api.messages.muteThread(
      threadId,
      !isMuted,
      ({ participantsMuted: newParticipantsMuted }) => {
        onMessageThreadSelected({ ...thread, participantsMuted: newParticipantsMuted });
      },
      (err) => toast.error(err.message),
    );
  }, [threadId, isMuted, onMessageThreadSelected, thread]);

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={modalOpen}
      onClose={closeModal}
      style={{ minHeight: "50vh", maxHeight: "90vh" }}
    >
      <Modal.Content>
        <h3 style={{ marginBottom: 5 }}>Thread settings</h3>
        <ModalContainer>
          <ActionArea>
            <h4>Participants</h4>
            <ParticipantContainer>
              {participantUsers.map((u, index) => (
                <Participant key={index}>
                  <UserChip user={u} compact />
                </Participant>
              ))}
            </ParticipantContainer>
          </ActionArea>
          <ActionArea>
            <h4>Add participant</h4>
            <ParticipantContainer>
              <Input
                size="small"
                fluid
                onChange={(e) => handleSearchChange(e)}
                placeholder={t("messages.findRecipient")}
              />
              {results.slice(0, 5).map((u) => (
                <Participant key={u._id}>
                  <UserChip user={u} noLink />
                  {[thread.user, ...participants].indexOf(u._id) > -1 ? (
                    <div />
                  ) : (
                    <Button compact icon="add" onClick={() => addParticipant(u._id)} size="tiny" />
                  )}
                </Participant>
              ))}
            </ParticipantContainer>
          </ActionArea>
        </ModalContainer>
        <ModalContainer>
          <ActionArea>
            <h4>Mute chat</h4>
            <span>You will no longer receive push notifications or emails when messages are sent to this chat.</span>
            <Button
              color={isMuted ? "blue" : ""}
              content={isMuted ? "Un-mute chat" : "Mute chat"}
              onClick={() => muteThread()}
              style={{ width: "fit-content", marginTop: 10 }}
            />
          </ActionArea>
        </ModalContainer>
        <ModalContainer $marginedTop>
          <ActionArea>
            <h4>Leave chat</h4>
            If you leave this chat, you will no longer receive messages from it.
            <Button
              style={{ maxWidth: "fit-content", marginTop: 10 }}
              icon="log out"
              content="Leave chat"
              size="small"
              basic
              onClick={() => leaveThread()}
            />
          </ActionArea>
        </ModalContainer>
        {thread?.forType === "idea" ? (
          <Message info>
            The participants of this chat are tied to the {t("generic.idea")} team. If the {t("generic.idea")} is
            deleted, you will continue to be able to use the chat, along with edit the participants.
          </Message>
        ) : null}
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (thread) => dispatch(actions.messages.selectThread(thread)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatSettings));

/* eslint-disable global-require */
import React from "react";
import { Map, Marker, TileLayer, Rectangle } from "react-leaflet";
import L from "leaflet";

import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIcon2 from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { Other } from "simplydo/interfaces";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const coordinateToLatLng = (coordinate: { latitude: number; longitude: number }) => {
  if (!coordinate) {
    return null;
  }
  return [coordinate.latitude, coordinate.longitude] as const;
};

const CompanyMap = ({ company }: { company: Other.ICompanyDetails }) => {
  const coordinates = company.location?.coordinates;
  const position = coordinateToLatLng(coordinates);
  const coordinateBounds = coordinates?.bounds;
  const coordinateViewport = coordinates?.viewport;

  if (!coordinates) {
    return null;
  }

  let bounds;
  if (coordinateViewport) {
    bounds = [coordinateToLatLng(coordinateViewport[0]), coordinateToLatLng(coordinateViewport[1])];
  }
  const zoom = bounds ? undefined : 10;

  return (
    <Map
      center={position}
      style={{
        width: "300px",
        height: "200px",
        zIndex: 1,
      }}
      zoom={zoom}
      bounds={bounds}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} />
      {coordinateBounds ? (
        <Rectangle
          bounds={[coordinateToLatLng(coordinateBounds[0]), coordinateToLatLng(coordinateBounds[1])]}
          color="red"
          weight={1}
          fillOpacity={0.1}
        />
      ) : null}
    </Map>
  );
};

export default CompanyMap;

import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Container, Segment, Header } from "semantic-ui-react";
import actions from "actions";

import { MultiLineParagraph } from "components/lib/UI";
import PointOfContactList from "components/lib/PointOfContactList";
import Login from "components/entry/Login";

function PageLoadError({ title, message, user, organisation, onSelectOrganisation, t }) {
  return (
    <Container style={{ marginTop: 80 }}>
      <div style={{ textAlign: "center" }}>
        {typeof title === "string" ? <Header icon>{title}</Header> : title}

        {user && (
          <>
            <p>{message}</p>
            <PointOfContactList />
          </>
        )}
      </div>

      {!user ? (
        <Segment color="violet" style={{ marginTop: 40 }}>
          <Grid stackable centered>
            <Grid.Column computer={6}>
              {<MultiLineParagraph content={organisation?.welcomeText || "Welcome to Simply Do"} />}
              {organisation && (
                <>
                  <h5 style={{ display: "inline-block", marginTop: 20, marginBottom: 0 }}>
                    {t("entry.register.org.signingUpWith", { organisation })}
                  </h5>
                  <Button
                    size="tiny"
                    type="button"
                    content={t("entry.register.org.changeThis")}
                    onClick={() => onSelectOrganisation(null)}
                    basic
                    style={{ margin: "10px 10px 10px 0px" }}
                  />
                </>
              )}
            </Grid.Column>
            <Grid.Column computer={2} />
            <Grid.Column computer={6}>
              <Login />
            </Grid.Column>
          </Grid>
        </Segment>
      ) : (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <Button as={Link} to="/" size="large" primary>
            Return to homepage
          </Button>
        </div>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({ user: state.user, organisation: state.organisations.urlOrganisation });

const mapDispatchToProps = (dispatch) => ({
  onSelectOrganisation: (org) => dispatch(actions.organisations.receiveUrlOrganisation(org)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PageLoadError));

import React from "react";
import { Icon, Table, Loader } from "semantic-ui-react";
import { InfoContainer, InfoBoxWidget, InfoBoxWidgetTitleArea } from "../Styles";
import { OpenAPI } from "simplydo/interfaces";

const CompanyServices = ({
  services = [],
  runningTasks = [],
}: {
  services: OpenAPI.Schemas["Company"]["ai"]["services"];
  runningTasks: string[];
}) => {
  if (runningTasks.indexOf("openaiTask") > -1) {
    return (
      <InfoBoxWidget>
        <Loader active inline="centered" content="Searching for company products and services..." />
      </InfoBoxWidget>
    );
  }
  if (services.length) {
    return (
      <InfoBoxWidget>
        <InfoBoxWidgetTitleArea>
          <Icon name="balance scale" />
          <h3>Services</h3>
          <span>SimplyDo found these products and/or services from analysing this organisation's website</span>
        </InfoBoxWidgetTitleArea>
        <InfoContainer $wrapped>
          <Table definition>
            {services.map((service) => (
              <Table.Row key={service.name}>
                <Table.Cell collapsing>{service.name}</Table.Cell>
                <Table.Cell>{service.description}</Table.Cell>
              </Table.Row>
            ))}
          </Table>
        </InfoContainer>
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyServices;

import React, { Reducer, useReducer } from "react";
import { Menu, Popup, StrictDropdownItemProps } from "semantic-ui-react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export type PDFExportTriggerProps = {
  loading: boolean;
};

export type PDFExportBaseProps = {
  trigger: React.ReactElement<
    PDFExportTriggerProps & {
      onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>, ...args: any[]) => void;
    }
  >;
  dropdownItems?: StrictDropdownItemProps[];
  popupOffset?: [number, number];
  pdfName?: string;
};

type OnDownloadProps = {
  type: string;
  [key: string]: any;
};

type PDFExportManagerProps = PDFExportBaseProps & {
  forType: "idea";
  onDownload: (props: OnDownloadProps) => Promise<{
    url: string;
  }>;
};

type PDFState = {
  isExporting: boolean;
};

type PDFStateAction = {
  type: "start" | "end";
};

const updateState = (state, action) => {
  switch (action.type) {
    case "start":
      return { ...state, isExporting: true };
    case "end":
      return { ...state, isExporting: false };
    default:
      return state;
  }
};

const PDFExportManager = (props: PDFExportManagerProps) => {
  const [state, dispatch] = useReducer<Reducer<PDFState, PDFStateAction>>(updateState, {
    isExporting: false,
  });
  const { t } = useTranslation();

  const { trigger, onDownload, pdfName, dropdownItems = [], popupOffset = [0, 0] } = props;

  const downloaderFactory = (downloadProps: OnDownloadProps) => () => {
    const loader = toast.loading(t("ideas.pdf.download"), {
      duration: 15000,
    });
    onDownload(downloadProps)
      .then((result) => {
        const element = document.createElement("a");
        element.setAttribute("href", result.url);
        element.setAttribute("download", pdfName ?? "download.pdf");
        element.setAttribute("target", "_blank");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .catch((_error) => {
        toast.error(t("ideas.pdf.error"));
      })
      .finally(() => {
        dispatch({ type: "end" });
        toast.dismiss(loader);
      });
  };

  if (dropdownItems.length > 0) {
    return (
      <Popup
        on="click"
        hoverable
        position="bottom center"
        trigger={React.cloneElement(trigger, {
          loading: state.isExporting || undefined,
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        })}
        offset={popupOffset}
        content={
          <Menu secondary vertical>
            <Menu.Item header content="Export as..." />
            <Menu.Item onClick={() => {}} onMouseUp={downloaderFactory({ type: "default" })}>
              Regular PDF
            </Menu.Item>
            {dropdownItems.map((item) => (
              <Menu.Item
                key={item.value as string}
                onClick={() => {}}
                onMouseUp={downloaderFactory({ type: item.value as string })}
                {...item}
              >
                {item.text}
              </Menu.Item>
            ))}
          </Menu>
        }
      />
    );
  }

  return React.cloneElement(trigger, {
    loading: state.isExporting || undefined,
    onClick: downloaderFactory({ type: "default" }),
  });
};

export default PDFExportManager;

import { IframeExtension } from "remirror/extensions";

export const iframeRegex = /<iframe[^<]+<\/iframe>/gi;

export const extractHTMLTag = (match: string, attr: string) => {
  const reg = new RegExp(`${attr}="([^"]+)"`);
  const result = reg.exec(match);
  return result ? result[1] : null;
};

export class CustomIFrameExtension extends IframeExtension {
  createPasteRules() {
    return [
      {
        type: "node" as const,
        nodeType: this.type,
        regexp: iframeRegex,
        getAttributes: (match) => {
          const iframe = match[0];
          const src = extractHTMLTag(iframe, "src");
          const title = extractHTMLTag(iframe, "title");
          const allow = extractHTMLTag(iframe, "allow");
          return {
            src,
            title,
            allow,
          };
        },
        getContent: () => {
          return undefined;
        },
      },
    ];
  }
}

import React from "react";
import { Popup, Input, Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledListItem = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e9ebee;
  }
`;

const OmniSearcher = ({ searchOpen, setSearchOpen, searchResults, searchTerm, updateSearchTerm, onResultClick }) => {
  const { t } = useTranslation();
  return (
    <Popup
      basic
      hoverable
      hideOnScroll
      size="small"
      position="bottom left"
      open={searchOpen}
      onClose={() => setSearchOpen(false)}
      style={{ marginTop: 5, maxHeight: 800, display: "block" }}
      trigger={
        <Input
          value={searchTerm}
          icon="search"
          iconPosition="left"
          style={{ border: "0px", display: "block", margin: "10px 0" }}
          placeholder="Search by name..."
          onChange={(e) => updateSearchTerm(e.target.value)}
          onClick={() => {
            if (searchResults?.length) {
              setSearchOpen(true);
            }
          }}
        />
      }
      content={
        searchResults ? (
          <Grid stackable style={{ padding: 5, paddingBottom: 0, minWidth: 550 }}>
            {searchResults?.challenges.length > 0 && (
              <Grid.Column width={8}>
                <h4>{t("common:capitalise", { key: "generic.challenges" })}</h4>
                {searchResults.challenges.slice(0, 5).map((chall) => (
                  <StyledListItem onClick={() => onResultClick("challenge", chall)}>
                    <Icon name={"star"} color="grey" style={{ marginRight: 5 }} />
                    {chall?.name}
                  </StyledListItem>
                ))}
              </Grid.Column>
            )}
            {searchResults?.groups.length > 0 && (
              <Grid.Column width={8}>
                <h4>Groups</h4>
                {searchResults.groups.slice(0, 5).map((group) => (
                  <StyledListItem onClick={() => onResultClick("group", group)}>
                    <Icon name={"users"} color="grey" style={{ marginRight: 5 }} />
                    {group?.name}
                  </StyledListItem>
                ))}
              </Grid.Column>
            )}
            {searchResults?.polls.length > 0 && (
              <Grid.Column width={8}>
                <h4>Polls</h4>
                {searchResults.polls.slice(0, 5).map((poll) => (
                  <StyledListItem onClick={() => onResultClick("poll", poll)}>
                    <Icon name={"lightning"} color="grey" style={{ marginRight: 5 }} />
                    {poll?.name}
                  </StyledListItem>
                ))}
              </Grid.Column>
            )}
            {searchResults?.ideas.length > 0 && (
              <Grid.Column width={8}>
                <h4>{t("common:capitalise", { key: "generic.ideas" })}</h4>
                {searchResults.ideas.slice(0, 5).map((idea) => (
                  <StyledListItem onClick={() => onResultClick("idea", idea)}>
                    <Icon name={"lightbulb"} color="grey" style={{ marginRight: 5 }} />
                    {idea?.name}
                  </StyledListItem>
                ))}
              </Grid.Column>
            )}
          </Grid>
        ) : null
      }
    />
  );
};

export default OmniSearcher;

import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Message, Card, Label, Divider, Icon, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import api from "api";
import util from "utils/utils";
import styled from "styled-components";

import { Other } from "simplydo/interfaces";
import { UserChip } from "components/lib/Chips";
import IdeaBody from "./Body";

const IdeaPDFViewContainer = styled.div`
  background: #fff;
  padding-top: 40px;
  * {
    font-family: "Open Sans", sans-serif !important;
  }
`;

const IdeaPDFView = ({ idea }: { idea: Other.IIdea }) => {
  const { t } = useTranslation();
  return (
    <IdeaPDFViewContainer>
      <Container className="idea" style={{ backgroundColor: "#fff" }}>
        <Card.Group centered itemsPerRow={1} style={{ margin: "0 20px 10px" }}>
          <Card fluid>
            <Image src={util.ideaCoverImage(idea)} style={{ maxHeight: 300, objectFit: "cover" }} />
            <Card.Content>
              <Card.Header>{idea.name} </Card.Header>
              {idea.ownerChallenge && (
                <Card.Meta>
                  {idea.isPinned && (
                    <Label size="tiny" color="olive">
                      {" "}
                      <Icon name="thumbtack" /> {t("ideas.tags.pinned")}
                    </Label>
                  )}{" "}
                  In <Link to={`/challenges/${idea.ownerChallenge._id}`}> {idea.ownerChallenge?.name} </Link>
                </Card.Meta>
              )}
            </Card.Content>
          </Card>

          <Card fluid>
            <Card.Content>
              <div style={{ marginTop: "5px" }} />
              {idea.authors &&
                idea.authors.map((author) => (
                  <UserChip
                    key={author._id}
                    user={author}
                    style={{ marginRight: 10, marginBottom: 10, display: "inline-block" }}
                  />
                ))}
            </Card.Content>
            <Card.Content extra textAlign="right">
              {t("ideas.pdf.updated", { date: moment(idea.updatedAt).format("MMMM Do, YYYY") })}
            </Card.Content>
          </Card>
        </Card.Group>
      </Container>
      <div style={{ margin: "-20px 0 0" }}>
        {/* @ts-ignore */}
        <IdeaBody idea={idea} isPDF />
      </div>
    </IdeaPDFViewContainer>
  );
};

type MultiIdeaPdfProps = {
  ideaIds?: string[];
};

export const MultiIdeaPDF = ({ ideaIds: propIdeaIds }: MultiIdeaPdfProps) => {
  const params = useParams();

  const ideaIds = useMemo(() => propIdeaIds ?? params.ids.split(","), [params?.ids, propIdeaIds]);
  const { t } = useTranslation();
  const [errorMessages, setErrorMessages] = useState<{ [ideaId: string]: string }>({});
  const [ideas, setIdeas] = useState<Other.IIdea[]>([]);

  const getIdea = useCallback((ideaId) => {
    api.ideas.get(
      ideaId,
      (ideaData: Other.IIdea) => {
        setIdeas((prevIdeas) => [...prevIdeas, ideaData]);
      },
      (err) => {
        setErrorMessages((prevErrors) => ({ ...prevErrors, [ideaId]: err.message }));
      },
      null,
    );
  }, []);

  const getIdeas = useCallback(() => {
    setErrorMessages({});
    setIdeas([]);
    ideaIds.forEach((ideaId) => getIdea(ideaId));
  }, [ideaIds, getIdea]);

  useEffect(() => {
    getIdeas();
  }, [getIdeas]);

  const renderIdeaPdf = useCallback(
    (ideaId: string, idea: Other.IIdea | null) => {
      if (errorMessages[ideaId]) {
        return (
          <Message key={ideaId}>
            <p>
              <strong>{t("ideas.pdf.problem")} </strong>
            </p>
            <p>{errorMessages[ideaId]} </p>
          </Message>
        );
      }
      if (!idea) {
        return null;
      }
      return (
        <>
          <IdeaPDFView key={ideaId} idea={idea} />
          <Container>
            <Divider />
          </Container>
        </>
      );
    },
    [errorMessages, t],
  );

  return (
    <div style={{ backgroundColor: "#fff" }}>
      {ideaIds.map((ideaId) => {
        const idea = ideas.find((idea) => idea._id === ideaId);
        if (idea?.ownerChildren?.length) {
          return idea.ownerChildren.map((childIdea) => renderIdeaPdf(childIdea._id, childIdea));
        }
        return renderIdeaPdf(ideaId, idea);
      })}
    </div>
  );
};

const IdeaPDF = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [idea, setIdea] = useState<Other.IIdea | null>(null);

  const params = useParams();
  const matchId = params.id;

  const getIdea = useCallback(() => {
    setErrorMessage("");
    api.ideas.get(
      matchId,
      (ideaData: Other.IIdea) => setIdea(ideaData),
      (err) => setErrorMessage(err.message),
      null,
    );
  }, [matchId]);

  useEffect(() => {
    getIdea();
  }, [matchId, getIdea]);

  if (errorMessage)
    return (
      <Message>
        <p>
          <strong>{t("ideas.pdf.problem")} </strong>
        </p>
        <p>{errorMessage} </p>
      </Message>
    );
  if (!idea) return null;
  if (idea.children?.length > 0) {
    return (
      // @ts-ignore
      <MultiIdeaPDF ideaIds={idea.children} />
    );
  }
  return (
    // @ts-ignore
    <div style={{ "-webkit-print-color-adjust": "exact" }}>
      <IdeaPDFView idea={idea} />
    </div>
  );
};

export default IdeaPDF;

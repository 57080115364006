import { useSearchParams } from "react-router-dom";
import IdeaBusinessProfileModal from "./IdeaBusinessProfileModal";
import { useCallback, useEffect, useState } from "react";
import api from "api";
import toast from "react-hot-toast";

export const AutoBusinessProfileModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [businessProfile, setBusinessProfile] = useState();
  const businessProfileId = searchParams.get("businessProfileId");

  const setBusinessProfileId = useCallback(
    (id: string | null) => {
      setSearchParams((prev) => {
        if (!id) {
          prev.delete("businessProfileId");
        } else {
          prev.set("businessProfileId", id);
        }
        return prev;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (!businessProfileId) {
      return;
    }

    let mounted = true;
    api.users.getProfile(
      businessProfileId,
      (result) => {
        if (mounted) {
          setBusinessProfile(result.ideaBusinessProfile);
        }
      },
      () => {
        toast.error("Cannot open business profile");
        setBusinessProfileId(null);
      },
    );

    return () => {
      mounted = false;
    };
  }, [businessProfileId, setBusinessProfileId]);

  if (!businessProfile) {
    return null;
  }

  return (
    <IdeaBusinessProfileModal
      businessProfile={businessProfile}
      onClose={() => {
        setBusinessProfile(null);
        setBusinessProfileId(null);
      }}
    />
  );
};

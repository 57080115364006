import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const SelectChip = styled.div<{
  $isSelected?: boolean;
  $compact?: boolean;
}>`
  display: flex;
  width: ${({ $compact }) => ($compact ? "25px" : "35px")};
  height: ${({ $compact }) => ($compact ? "40px" : "55px")};
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  // border: 1px solid lightgray;
  background-color: ${({ $isSelected, theme }) => ($isSelected ? theme.accent : "#e9ebee")};
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "gray")};

  cursor: pointer;
  > i {
    font-size: ${({ $compact }) => ($compact ? "1.2em" : "2em")};
    width: 1em;
    margin-right: 0;
    margin-top: ${({ $compact }) => ($compact ? "0 !important" : "7px")};
    ${({ $isSelected }) => !$isSelected && "font-size: 0"}
  }
  &:hover {
    > i {
      font-size: ${({ $compact }) => ($compact ? "1.2em" : "2em")} !important;
    }
  }
`;

const SelectItem = ({
  isSelected,
  onClick,
  compact,
}: {
  isSelected?: boolean;
  onClick?: () => void;
  compact?: boolean;
}) => (
  <SelectChip $isSelected={isSelected} onClick={onClick} $compact={compact}>
    <Icon name="check" />
  </SelectChip>
);

export default SelectItem;

import { useCallback } from "react";
import { Divider } from "semantic-ui-react";
import { Slider } from "components/lib/UI";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { withTranslation } from "react-i18next";
import api from "api";
import actions from "actions";

const markStyle = { marginTop: 10, marginRight: 10 };

const sliderMarks = {
  4: {
    label: "Instant",
    key: "instant",
    value: 4,
    style: markStyle,
  },
  3: {
    label: "Hourly",
    key: "hourly",
    value: 3,
    style: markStyle,
  },
  2: {
    label: "Daily",
    key: "daily",
    value: 2,
    style: markStyle,
  },
  1: {
    label: "Weekly",
    key: "weekly",
    value: 1,
    style: markStyle,
  },
};

export const NotificationFrequency = ({ user, onSubToggled }) => {
  const emailFrequency = user?.eventSubscriptions?.notificationFrequency?.email;

  const sliderValue = Object.values(sliderMarks).find((m) => m.key === emailFrequency) || "instant";

  const onSliderChange = useCallback(
    (value) => {
      const newSliderValue = sliderMarks[value];
      onSubToggled({ ...user.eventSubscriptions, notificationFrequency: { email: newSliderValue.key } });
      api.users.updateNotificationPreferences(
        user._id,
        ["notificationFrequency"],
        { email: newSliderValue.key },
        () => {
          toast.success("Notification frequency updated");
        },
        (err) => toast.error(err.message),
      );
    },
    [user?._id, user?.eventSubscriptions, onSubToggled],
  );

  return (
    <div>
      <Divider />
      <h3>Notification frequency</h3>
      <p>
        Adjust the frequency for how often you receive email updates. Emails will summarise the events during the time
        period.
      </p>
      <Slider
        min={1}
        max={4}
        marks={sliderMarks}
        defaultValue={4}
        value={sliderValue.value}
        onChange={(value) => onSliderChange(value)}
        style={{ marginBottom: 50 }}
      />
      <Divider />
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onSubToggled: (subs) => dispatch(actions.user.toggleEventSubscription(subs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationFrequency));

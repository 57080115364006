import React, { useCallback, useEffect, useState, useRef, Dispatch, SetStateAction } from "react";
import { Button, Icon, Popup, Loader, ButtonProps } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import toast from "react-hot-toast";
import { useAppSelector } from "store";
import styled from "styled-components";
import moment from "moment";
import api from "api";
import useTheme from "theme/useTheme";

const eventTypes = {
  changedLane: { icon: "arrow right", color: "blue" },
  assignedUser: { icon: "user plus", color: "green" },
  unassignedUser: { icon: "user times", color: "red" },
  changedDeadline: { icon: "calendar", color: "blue" },
  changedReviewDate: { icon: "calendar", color: "blue" },
};

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.5px;
  width: 400px;
  max-height: 600px;
  min-height: 100px;
  overflow: auto;
`;

const EventItem = styled.div<{ $interacted?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  padding: 2.5px;
  cursor: pointer;
  &:hover {
    background-color: #e9ebee;
  }
  .icon-container {
    .i {
      font-size: 0.9em;
      line-height: 1.1em;
    }
    margin-right: 10px;
  }
  .info-container {
    > span {
      display: block;
      ${({ $interacted }) =>
        $interacted &&
        `
        color: #B5B6B7;
      `}
    }
    .meta {
      color: ${({ $interacted }) => ($interacted ? "#D8D9DC" : "gray")};
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 2.5px;
  }
  &:not(:first-of-type) {
    margin-top: 2.5px;
  }
`;

const BoardEvents = ({
  forId,
  setCurrentIdeaId,
  buttonStyle,
  buttonProps = {},
}: {
  forId: string;
  setCurrentIdeaId: Dispatch<SetStateAction<string>>;
  buttonStyle?: React.CSSProperties;
  buttonProps?: ButtonProps;
}) => {
  const user = useAppSelector<Other.IUserMe>((state) => state.user);
  const [events, setEvents] = useState<Other.IProjectIdeaEvent[]>([]);
  const [seenEvents, setSeenEvents] = useState<boolean>(true);
  const [canExtend, setCanExtend] = useState<boolean>(false);
  const [eventsState, setEventsState] = useState<{ page: number; limit: number }>({
    page: 1,
    limit: 10,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [challenge, setChallenge] = useState<Other.IChallenge | null>(null);
  const bottomOfEventsRef = useRef<HTMLDivElement | null>(null);
  const { page, limit } = eventsState;
  const userId = user?._id;
  const theme = useTheme();

  const getEvents = useCallback(() => {
    setLoading(page === 1);
    api.boards.getBoardEvents(
      forId,
      { page, limit },
      (data) => {
        if (page === 1) {
          setEvents(data.events);
        } else {
          setEvents((prevEvents) => [...prevEvents, ...data.events]);
          bottomOfEventsRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
        setSeenEvents(data.seen);
        setCanExtend(data.canExtend);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      },
    );
  }, [forId, page, limit]);

  useEffect(() => getEvents(), [getEvents]);

  const getChallenge = useCallback(() => {
    if (!forId) return;
    api.challenges.get(
      forId,
      (data) => {
        if (data) setChallenge(data);
      },
      () => {},
    );
  }, [forId]);

  useEffect(() => getChallenge(), [getChallenge]);

  const onEventClick = useCallback(
    (event) => {
      setPopupOpen(false);
      setCurrentIdeaId(event.idea);
      api.boards.markBoardEventAsInteracted(
        forId,
        event._id,
        () => {
          setEvents((prevEvents) =>
            prevEvents.map((e) => {
              if (e._id === event._id) {
                return {
                  ...e,
                  interacted: [...(e.interacted || []), userId],
                };
              }
              return e;
            }),
          );
        },
        () => {},
      );
    },
    [forId, setCurrentIdeaId, userId],
  );

  const onOpen = useCallback(() => {
    setPopupOpen(true);
    api.boards.markBoardEventsAsSeen(
      forId,
      () => setSeenEvents(true),
      () => {},
    );
  }, [forId]);

  const getLaneName = useCallback(
    (event, laneKey = "previousLane") => {
      // If previousLane is empty string/null, we know it was moved from default lane
      if (!event[laneKey]) {
        return challenge?.projectBoard?.defaultLane?.name ?? "Default";
      }
      if (event[`${laneKey}Object`]) {
        return event[`${laneKey}Object`].name;
      }
      return "an unknown lane";
      // Else if we know the previous lane object, we can return that
    },
    [challenge],
  );

  if (!events.length) return null;
  return (
    <Popup
      onClose={() => setPopupOpen(false)}
      open={popupOpen}
      on="click"
      trigger={
        <Button
          color={!seenEvents ? "red" : undefined}
          icon={seenEvents ? "list" : "bell"}
          style={{ marginLeft: 5, ...(buttonStyle ?? {}) }}
          size="small"
          content={theme.sizes.isLargeComputer ? "Recent activity" : null}
          {...buttonProps}
        />
      }
      onOpen={onOpen}
      position="bottom right"
      content={
        <EventContainer>
          {loading ? (
            <Loader active style={{ margin: 10 }} />
          ) : (
            <>
              {events.map((event) => {
                const eventInteracted = (event.interacted || []).indexOf(userId) > -1;
                if (!eventTypes[event.action]) return null;
                return (
                  <EventItem key={event._id} $interacted={eventInteracted} onClick={() => onEventClick(event)}>
                    <div className="icon-container">
                      <Icon
                        color={!eventInteracted ? eventTypes[event.action].color : "grey"}
                        name={eventTypes[event.action].icon}
                        size="large"
                      />
                    </div>
                    <div className="info-container">
                      <span>
                        {event.userObject ? `${event.userObject.profile.fullName}` : "Unknown user"}
                        {event.action === "assignedUser" && (
                          <>
                            {" assigned "}
                            <b>
                              {event.targetUserObject ? `${event.targetUserObject.profile.fullName}` : "unknown user"}
                            </b>
                            {" to "}
                            <b>{event.targetIdea.name}</b>
                          </>
                        )}
                        {event.action === "unassignedUser" && (
                          <>
                            {" un-assigned "}
                            <b>
                              {event.targetUserObject ? `${event.targetUserObject.profile.fullName}` : "unknown user"}
                            </b>
                            {" from "}
                            <b>{event.targetIdea.name}</b>
                          </>
                        )}
                        {event.action === "changedLane" && (
                          <>
                            {" moved "}
                            <b>{event.targetIdea.name}</b>
                            {" from "}
                            <b>{getLaneName(event)}</b>
                            {" to "}
                            <b>{getLaneName(event, "targetLane")}</b>
                          </>
                        )}
                        {event.action === "changedDeadline" && (
                          <>
                            {" changed the deadline of "}
                            <b>{event.targetIdea.name}</b>
                            {" to "}
                            <b>{event.deadline ? moment(event.deadline).format("DD/MM/YYYY") : "No deadline"}</b>
                          </>
                        )}
                        {event.action === "changedReviewDate" && (
                          <>
                            {" changed the review date of "}
                            <b>{event.targetIdea.name}</b>
                            {" to "}
                            <b>{event.reviewDate ? moment(event.reviewDate).format("DD/MM/YYYY") : "No review date"}</b>
                          </>
                        )}
                      </span>
                      <span className="meta">{moment(event.createdAt).fromNow()}</span>
                    </div>
                  </EventItem>
                );
              })}
              {canExtend ? (
                <Button
                  size="small"
                  onClick={() => setEventsState((prevState) => ({ ...prevState, page: prevState.page + 1 }))}
                  content="Load more"
                  style={{
                    marginTop: 5,
                    width: "99%",
                    minHeight: 30,
                    padding: 0,
                  }}
                />
              ) : null}
              <div ref={bottomOfEventsRef} />
            </>
          )}
        </EventContainer>
      }
    />
  );
};

export default BoardEvents;

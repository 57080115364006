import React, { useCallback, useMemo } from "react";
import { Header, Icon, Button, List } from "semantic-ui-react";
import { FileChooserWithDropzone } from "components/lib/Choosers";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import uuid from "uuid";
import util from "utils/utils";

const MultiFile = ({ field, ideaId, fieldValue = [], updateIdea, isEditing }) => {
  const { t } = useTranslation();

  const addFiles = useCallback(
    (
      files: {
        savedName: string;
        fileName: string;
        signedDownloadRequest: string;
      }[],
    ) => {
      if (field.maxFiles && fieldValue.length + files.length > field.maxFiles) {
        toast.error("Your uploads exceed the maximum number of files allowed");
        return;
      }

      const updatedImages = [
        ...files.map((file) => ({
          value: file.signedDownloadRequest,
          name: file.fileName,
          _id: uuid.v4(),
        })),
        ...fieldValue,
      ];
      updateIdea(updatedImages, ["templated", field.id]);
    },
    [field.id, field.maxFiles, fieldValue, updateIdea],
  );

  const removeFile = useCallback(
    (fileId) => {
      util
        .confirm(
          "Are you sure you want to delete this file?",
          "This action cannot be undone, but you can always re-upload the file.",
        )
        .then(() => {
          const updatedImages = fieldValue.filter((file) => file._id !== fileId);
          updateIdea(updatedImages, ["templated", field.id]);
        })
        .catch(() => {});
    },
    [fieldValue, updateIdea, field.id],
  );

  const hasMaxFiles = field.maxFiles && fieldValue.length >= field.maxFiles;

  const downloadFileProps = useMemo(
    () => ({
      as: "a",
      target: "_blank",
      rel: "noopener noreferrer",
    }),
    [],
  );

  return (
    <div>
      {isEditing ? (
        <div>
          <Header icon textAlign="center">
            <Icon name="folder" /> Upload multiple files
            {field.maxFiles ? (
              <Header.Subheader>{`Maximum of ${util.pluralise(field.maxFiles, "file", "files")}`}</Header.Subheader>
            ) : null}
          </Header>
        </div>
      ) : null}

      <List relaxed divided>
        {fieldValue.map((file) => (
          <List.Item key={file.value}>
            <List.Content floated="right">
              <Button
                {...downloadFileProps}
                href={file.value}
                download={file.name}
                icon="download"
                content={t("generic.download")}
                size="tiny"
              />
              {isEditing ? <Button basic icon="trash" onClick={() => removeFile(file._id)} size="tiny" /> : null}
            </List.Content>
            <List.Content
              style={{
                height: 30,
                display: "flex",
                alignItems: "center",
              }}
            >
              <List.Icon name="file" size="large" />
              <a {...downloadFileProps} href={file.value} download={file.name} title={file.name}>
                {file.name}
              </a>
            </List.Content>
          </List.Item>
        ))}
      </List>

      {isEditing ? (
        <FileChooserWithDropzone
          forType="idea"
          forId={ideaId}
          allowMultiple={true}
          onCompleteMultiple={addFiles}
          onError={toast.error}
          popupProps={{
            position: "bottom center",
            disabled: hasMaxFiles,
          }}
        />
      ) : null}
    </div>
  );
};

export default MultiFile;

import { OpenAPI } from "simplydo/interfaces";
import actions from "../actions";

export type InitialState = {
  searchTerm: string;
  searchResults: OpenAPI.GET<"/search/google">["response"]["results"];
  page: 1;
  limit: 20;
  loading: boolean;
  isOpen: boolean;
};

const initialState: InitialState = {
  searchTerm: "",
  searchResults: [],
  page: 1,
  limit: 20,
  loading: false,
  isOpen: false,
};

const googleHelper = (state: InitialState = initialState, action: { type: string; payload: Partial<InitialState> }) => {
  switch (action.type) {
    case actions.googleHelper.REQUEST_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    case actions.googleHelper.RECEIVE_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    case actions.googleHelper.SET_SEARCH_QUERY:
      return {
        ...state,
        ...action.payload,
      };
    case actions.googleHelper.OPEN_GOOGLE_HELPER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default googleHelper;

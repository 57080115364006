import React, { useCallback, useState } from "react";
import { Message, Input, Button, Modal, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "store";
import api from "api";
import actions from "actions";

export const OrganisationChooserView = ({ errorMessage, searchByCode, organisationSearching }) => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const searchCode = useAppSelector((state) => state.entry.organisationSearchCode);
  const onUpdateCode = useCallback(
    (code) => {
      dispatch(actions.entry.updateOrganisationSearchCode(code));
    },
    [dispatch],
  );

  const openChatwoot = useCallback(() => {
    if (window.$chatwoot) {
      window.$chatwoot.toggle();
    }
  }, []);

  return (
    <>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={helpModalOpen}
        onClose={() => setHelpModalOpen(false)}
      >
        <Modal.Header>In order to sign up to SimplyDo you will need an organisational code.</Modal.Header>
        <Modal.Content>
          This is a unique code that identifies your organisation. If you don't know this code then{" "}
          <span style={{ textDecorationLine: "underline", cursor: "pointer" }} onClick={() => openChatwoot()}>
            click here
          </span>{" "}
          or press the button below to start a chat with us. Tell us which organisation you are looking to join and we
          can support you to get started.
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setHelpModalOpen(false)}>Close</Button>
          <Button onClick={() => openChatwoot()} primary>
            <Icon name="chat" />
            Speak to us
          </Button>
        </Modal.Actions>
      </Modal>

      <p>{t("entry.register.org.title")}</p>
      {errorMessage && <Message warning>{errorMessage}</Message>}
      <Input
        fluid
        label=".simplydo.co.uk"
        labelPosition="right"
        name="orgCode"
        placeholder={t("entry.register.org.placeholder")}
        value={searchCode}
        onKeyDown={(e) => e.keyCode === 13 && searchByCode()}
        onChange={(e) => onUpdateCode(e.target.value)}
      />

      <div style={{ marginTop: 15, marginBottom: 10, textAlign: "right" }}>
        <p onClick={() => setHelpModalOpen(true)} style={{ cursor: "pointer", color: "#14435b" }}>
          {t("entry.register.unknownCode")}
        </p>
      </div>
      {searchCode && (
        <Button loading={organisationSearching} fluid primary content={t("generic.continue")} onClick={searchByCode} />
      )}
    </>
  );
};

const OrganisationChooser = ({
  searchCode,
  organisationSearching,
  errorMessage,
  onError,
  onUpdateSearching,
  onSelectOrganisation,
  t,
}) => {
  const searchByCode = () => {
    onUpdateSearching(true);
    onError("");
    api.organisations.getByCode(
      searchCode,
      (organisation) => {
        onSelectOrganisation(organisation);
        onUpdateSearching(false);
      },
      () => {
        onError(t("entry.register.org.error"));
        onUpdateSearching(false);
      },
    );
  };

  return (
    <OrganisationChooserView
      errorMessage={errorMessage}
      searchByCode={searchByCode}
      organisationSearching={organisationSearching}
    />
  );
};

const mapStateToProps = (state) => ({
  organisation: state.organisations.urlOrganisation,
  searchCode: state.entry.organisationSearchCode,
  errorMessage: state.entry.error,
});
const mapDispatchToProps = (dispatch) => ({
  onSelectOrganisation: (org) => dispatch(actions.organisations.receiveUrlOrganisation(org)),
  onUpdateSearching: (l) => dispatch(actions.entry.updateOrganisationSearching(l)),
  onError: (e) => dispatch(actions.entry.updateError(e)),
});
const OrganisationChooserContainer = connect(mapStateToProps, mapDispatchToProps)(OrganisationChooser);

export default withTranslation()(OrganisationChooserContainer);

import { useState, useMemo } from "react";

import moment from "moment";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import { useAppSelector } from "store";
import util from "utils/utils";
import { UserChip } from "components/lib/Chips";
import { InfoBoxWidget, InfoBoxWidgetTitleArea } from "../Styles";

const CompanyMessages = ({ messages }) => {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const allMessagesWithLogs = useMemo(() => messages.filter((m) => !!m.emailLogs), [messages]);

  const user = useAppSelector((state) => state.user);
  return (
    <>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={!!selectedMessage}
        onClose={() => setSelectedMessage(null)}
        closeOnDimmerClick
      >
        <Modal.Header>{selectedMessage?.subject}</Modal.Header>
        <Modal.Content>
          <p>{selectedMessage?.message}</p>
        </Modal.Content>
      </Modal>
      <InfoBoxWidget>
        <InfoBoxWidgetTitleArea>
          <Icon name="mail" />
          <h3>Messages</h3>
          <span>{messages.length} total</span>
        </InfoBoxWidgetTitleArea>
        <p>These are communications sent by you or someone working in your organisation to this company.</p>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Subject</Table.HeaderCell>
              {util.hasPermission(user, "org.listUsers", user.ownerOrganisation) ? (
                <Table.HeaderCell>From</Table.HeaderCell>
              ) : null}
              <Table.HeaderCell>Reply-To address</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              {allMessagesWithLogs.length > 0 ? (
                <>
                  <Table.HeaderCell collapsing>Sent</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Delivered</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Opened</Table.HeaderCell>
                  <Table.HeaderCell collapsing>Bounced</Table.HeaderCell>
                </>
              ) : null}
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {messages.map((message) => {
              const messageLog = message.emailLogs?.[0];
              return (
                <Table.Row key={message._id}>
                  <Table.Cell>{message.subject}</Table.Cell>
                  {util.hasPermission(user, "org.listUsers", user.ownerOrganisation) ? (
                    <Table.Cell collapsing>
                      <UserChip user={message.ownerUser} />
                    </Table.Cell>
                  ) : null}
                  <Table.Cell collapsing>{message?.replyTo}</Table.Cell>
                  <Table.Cell collapsing>{moment(message.createdAt).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                  {allMessagesWithLogs.length > 0 ? (
                    <>
                      <Table.Cell>{messageLog?.sent ? <Icon name="check" color="green" /> : null}</Table.Cell>
                      <Table.Cell>
                        {messageLog?.events?.delivered ? <Icon name="check" color="green" /> : null}
                      </Table.Cell>
                      <Table.Cell>{messageLog?.events?.open ? <Icon name="check" color="green" /> : null}</Table.Cell>
                      <Table.Cell>{messageLog?.events?.bounced ? <Icon name="close" color="red" /> : null}</Table.Cell>
                    </>
                  ) : null}

                  <Table.Cell collapsing>
                    <Button onClick={() => setSelectedMessage(message)}>View message</Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </InfoBoxWidget>
    </>
  );
};

export default CompanyMessages;

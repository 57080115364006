import React from "react";
import { Dropdown, Image, Icon } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import styled from "styled-components";

const BusinessProfileDropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .company-info {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    span {
      display: block;
      margin: 0;
    }
    .description {
      opacity: 0.7;
    }
  }
`;

const BusinessProfileDropdownItem = ({
  businessProfile,
  onClick,
  selected,
}: {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  onClick?: () => void;
  selected?: boolean;
}) => {
  const { profile, _id } = businessProfile;
  const { name, imageUrl, address = {} } = profile;
  const { country, city } = address;
  return (
    <Dropdown.Item
      key={_id}
      value={_id}
      selected={selected}
      onClick={selected ? () => {} : () => onClick()}
      content={
        <BusinessProfileDropDownContainer>
          {selected ? (
            <Icon name="check circle" size="large" />
          ) : imageUrl ? (
            <Image
              src={imageUrl}
              style={{ borderRadius: 2, overflow: "hidden", height: 28, width: 28, objectFit: "contain" }}
            />
          ) : (
            <Icon name="building" size="large" />
          )}
          <div className="company-info">
            <span>{name}</span>
            {country || city ? (
              <span className="description">{`${city || ""}${city && country ? ", " : ""}${country}`}</span>
            ) : null}
          </div>
        </BusinessProfileDropDownContainer>
      }
    />
  );
};

type IProfileSelector = {
  attachedBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  loadingExistingProfiles: boolean;
  existingProfiles: OpenAPI.Schemas["IdeaBusinessProfile"][];
  attachBusinessProfile: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
  unattachBusinessProfile: () => void;
};

const BusinessProfileSelector = ({
  attachedBusinessProfile,
  loadingExistingProfiles,
  existingProfiles,
  attachBusinessProfile,
  unattachBusinessProfile,
}: IProfileSelector) => {
  if (!existingProfiles.length) {
    return null;
  }
  return (
    <Dropdown
      selection
      fluid
      placeholder="Select a organisation profile.."
      loading={loadingExistingProfiles}
      // @ts-ignore
      text={
        attachedBusinessProfile ? (
          <BusinessProfileDropdownItem businessProfile={attachedBusinessProfile} onClick={() => {}} />
        ) : null
      }
    >
      <Dropdown.Menu>
        {attachedBusinessProfile ? (
          <Dropdown.Item
            onClick={() => {
              unattachBusinessProfile();
            }}
          >
            No organisation profile
          </Dropdown.Item>
        ) : null}
        {existingProfiles.map((businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => (
          <BusinessProfileDropdownItem
            key={businessProfile._id}
            businessProfile={businessProfile}
            onClick={() => attachBusinessProfile(businessProfile)}
            selected={attachedBusinessProfile?._id === businessProfile._id}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BusinessProfileSelector;

import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";
import FormattedComment from "components/lib/FormattedComment";

const ProjectIdeaReplied = ({ events, ownerIdea = { name: "Undefined" }, ownerChallenge = { name: "Undefined" } }) => {
  return (
    <Trans i18nKey="notifications.projectIdeaReplied">
      <b>{{ name: util.getEventNames(events) }}</b>
      replied to your note on the project card for the idea
      <b>{{ idea: ownerIdea.name }}</b>
      in the challenge
      <b>{{ challenge: ownerChallenge.name }}</b>:
      <FormattedComment noLink>"{{ comment: events[0]?.comment }}"</FormattedComment>
    </Trans>
  );
};

export default ProjectIdeaReplied;

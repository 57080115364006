import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";
import FormattedComment from "components/lib/FormattedComment";

export default function IdeaCommentReply({ events, ownerIdea = { name: "Undefined" } }) {
  return (
    <Trans i18nKey="notifications.ideaCommentReplied">
      <b>{{ name: util.getEventNames(events) }}</b> replied to your comment on the idea
      <b>{{ idea: ownerIdea.name }}</b>: <FormattedComment noLink>"{{ comment: events[0]?.comment }}"</FormattedComment>
    </Trans>
  );
}

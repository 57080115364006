import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

class AnonymousChip extends Component {
  render() {
    const { t, style, colour } = this.props;
    return (
      <div style={style || { display: "inline-block" }} onClick={this.clicked}>
        <span
          style={{
            backgroundColor: "rgb(240,240,240)",
            fontSize: 20,
            textAlign: "center",
            borderRadius: "50%",
            width: 30,
            height: 30,
            marginRight: "5px",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          <Icon
            name="user secret"
            style={{
              margin: 0,
              padding: 0,
              position: "relative",
              top: 4,
              color: "rgb(150,150,150)",
            }}
          />
        </span>
        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
          <span style={{ marginBottom: 0, color: colour || "inherit" }}>{t("generic.anonymous")}</span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AnonymousChip);

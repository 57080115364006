import React, { useEffect, useState } from "react";
import { Message, Loader, Card, Divider } from "semantic-ui-react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import api from "api";

import { useSearchParams, useNavigate } from "react-router-dom";

const LoginWithToken = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    const next = searchParams.get("next");
    setLoading(true);

    api.auth.loginWithToken(
      token,
      () => {
        setLoading(false);
        navigate(next || "/");
      },
      (err) => {
        toast.error(err.message);
        setLoading(false);
        setErrorMessage(err.message);
      },
    );
  }, [searchParams, navigate]);

  return (
    <Card.Group centered>
      <Card color="yellow" style={{ marginTop: 50 }}>
        <Card.Content>
          <Card.Header>{t("entry.login.title")}</Card.Header>
          {loading && <Card.Meta>{t("entry.login.token.wait")}</Card.Meta>}
          <Divider hidden />
        </Card.Content>
        <Card.Content textAlign="center">
          {loading && <Loader centered active inline />}
          {errorMessage && (
            <Message color="orange">
              {t("entry.login.token.failed")} {errorMessage}
            </Message>
          )}
        </Card.Content>
      </Card>
    </Card.Group>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const LoginWithTokenContainer = connect(mapStateToProps)(LoginWithToken);

export default LoginWithTokenContainer;

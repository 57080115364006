import React, { Component } from "react";
import { Grid, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withTheme } from "styled-components";

import FeedCard from "./FeedCard";

const CardLeft = withTheme(({ achievement, theme }) => (
  <Grid.Column stretched width={12} verticalAlign="middle">
    <div className="cover-and-text">
      <Icon style={{ color: theme.primaryColour, fontSize: "3em" }} name="trophy" size="big" />
      <div style={{ marginLeft: 5, fontSize: "1.2em" }}>
        <span style={{ fontWeight: 600 }}>{achievement.name}</span>
        <br />
        <span>{achievement.description}</span>
      </div>
    </div>
  </Grid.Column>
));

class AchievementCard extends Component {
  render() {
    const { achievement, user, profileUser, feedType } = this.props;
    const you = profileUser || user;
    return (
      <FeedCard
        user={you}
        feedType={feedType}
        action="activityFeed.achievements.action"
        date={achievement.achievedAt}
        toLink="/achievements"
        type="ACHIEVEMENTS"
        cardLeft={<CardLeft achievement={achievement} />}
      />
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

const AchievementCardContainer = connect(mapStateToProps)(AchievementCard);

export default withTranslation()(AchievementCardContainer);

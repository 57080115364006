import React, { useMemo, useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Card, Button } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import api from "api";
import constants from "utils/constants";
import styled from "styled-components";
import actions from "actions";
import useTheme from "theme/useTheme";
import Joyride, { CallBackProps, STATUS, EVENTS, ACTIONS, TooltipRenderProps, Step } from "react-joyride";
import { useTranslation } from "react-i18next";

const TooltipButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px 0 0 0;
`;

const TooltipContainer = styled.div`
  .ui.button {
    height: 30px;
    font-size: 0.8em;
  }
  .description {
    font-size: 1.1em;
    color: black !important;
  }
`;

// eslint-disable-next-line no-unused-vars
type IStepCustomFunction = (stepData: any) => void;

interface IProductTour {
  user: Other.IUserMe;
  urlOrganisation: Other.IOrganisation | null;
  tour:
    | "challenge"
    | "assessment"
    | "assessmentHomepage"
    | "innovationIntelligenceOverview"
    | "innovationIntelligenceSearch"
    | "innovationIntelligenceList"
    | "orgSwitcher"
    | "projectBoardOnIdeasPage";
  demoingTour: string;
  setDemoingTour: (tourId: string) => void;
  isReady: boolean;
  customTourFunctions?: {
    [customTourFunctionId: string]: IStepCustomFunction;
  };
  onComplete?: () => void;
  skipStep?: string;
}

interface IProductTourTooltip extends TooltipRenderProps {
  stepCount: number;
  continuous: boolean;
  step: ICustomStep;
  index: number;
  closeProps: any;
  tooltipProps: any;
  backProps: any;
  primaryProps: any;
}

interface ICustomStep extends Step {
  customTourFunctionId?: string;
  showProgress?: boolean;
  title: string;
  content: string;
}

interface ICustomCallbackProps extends CallBackProps {
  step: ICustomStep;
}

const ProductTourTooltip = ({
  continuous,
  step,
  index,
  primaryProps,
  closeProps,
  backProps: _backProps,
  tooltipProps,
  stepCount,
}: IProductTourTooltip) => (
  <TooltipContainer {...tooltipProps}>
    <Card>
      <Card.Content>
        {step.title ? <Card.Header>{step.title}</Card.Header> : null}
        <Card.Description>{step.content}</Card.Description>
        <Card.Content extra>
          <TooltipButtonContainer>
            {/*
             * Back button is disabled for now as there seems to be a bug with the joyride library
             * https://github.com/gilbarbara/react-joyride/issues/838
             */}
            {/* {(index > 0)
              ? (
                <Button
                  {...backProps}
                  content='Back'
                  id='back'
                  size='small'
                />
              ) : <div />
            } */}
            <div />
            {continuous ? (
              <Button
                {...primaryProps}
                content={`${index === stepCount - 1 ? "Finish" : "Next"}${step.showProgress ? ` ${index + 1} / ${stepCount}` : ""}`}
                id="next"
                size="small"
              />
            ) : (
              <Button
                {...closeProps}
                content={`Finish${step.showProgress ? ` ${index + 1} / ${stepCount}` : ""}`}
                id="close"
                size="small"
              />
            )}
          </TooltipButtonContainer>
        </Card.Content>
      </Card.Content>
    </Card>
  </TooltipContainer>
);

/*

~ Creating a Tour ~

- When creating a new tour, the tour must be added to the productTours array in util/constants
- The tour value/id must be added to the interface IProductTour, and to the API under organisations/product_tours into the valid_tours array
- Each tour needs steps with valid classnames that correspond to elements. This is how the tour system knows where to display the tooltip

*/

const ProductTour = ({
  user,
  tour: tourId,
  isReady,
  urlOrganisation,
  customTourFunctions,
  onComplete,
  demoingTour,
  setDemoingTour,
  skipStep,
}: IProductTour) => {
  const [organisationTourData, setOrganisationTourData] = useState<Other.IOrganisationTour>({
    id: "",
    isEnabled: false,
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const [runTour, setRunTour] = useState<boolean>(false);
  const [tourStepIndex, setTourStepIndex] = useState<number>(0);
  const [completedTour, setCompletedTour] = useState<boolean>(false);
  const orgId: string = user?.ownerOrganisation?._id || urlOrganisation?._id || "";
  const { isEnabled } = organisationTourData;

  const getTour = useCallback(() => {
    if (orgId) {
      api.organisations.getProductTour(
        orgId,
        tourId,
        ({ success, tour: newTour, userHasCompletedTour }) => {
          if (success && (!userHasCompletedTour || demoingTour === tourId)) {
            setOrganisationTourData(newTour);
          }
        },
        () => {},
      );
    }
  }, [orgId, tourId, demoingTour]);

  useEffect(() => {
    if (!theme.sizes.isMobile) {
      getTour();
    }
  }, [getTour, theme.sizes.isMobile]);

  const startTour = useCallback(() => {
    if ((demoingTour === tourId && isReady) || (isReady && !completedTour && isEnabled)) {
      setRunTour(true);
    }
  }, [isReady, completedTour, isEnabled, demoingTour, tourId]);

  useEffect(() => {
    startTour();
  }, [startTour]);

  const handleFinishTour = useCallback(() => {
    setCompletedTour(true);
    setRunTour(false);
    setTourStepIndex(0);
    if (onComplete) {
      onComplete();
    }
    if (demoingTour) {
      setDemoingTour("");
    } else {
      api.organisations.completeProductTour(
        orgId,
        tourId,
        () => {},
        () => {},
      );
    }
  }, [onComplete, orgId, tourId, demoingTour, setDemoingTour]);

  const handleJoyrideCallback = useCallback(
    (data: ICustomCallbackProps) => {
      const { action, index, status, type, step } = data;
      const { customTourFunctionId } = step;
      const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
      const stepStatuses: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];

      // If opening a dialog, and there's a custom function assigned to the step, run the function
      if (action === "update" && status === "running") {
        if (
          customTourFunctionId &&
          customTourFunctions &&
          Object.prototype.hasOwnProperty.call(customTourFunctions, customTourFunctionId)
        ) {
          const customTourFunction = customTourFunctions[customTourFunctionId];
          customTourFunction(step);
        }
      }

      if (finishedStatuses.includes(status)) {
        handleFinishTour();
      } else if (stepStatuses.includes(type)) {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        setTourStepIndex(nextStepIndex);
      }
    },
    [handleFinishTour, customTourFunctions],
  );

  const tour: Other.ITour = useMemo(() => {
    const foundTour = constants.productTours.find((pt) => pt.key === tourId) as Other.ITour;
    if (!foundTour) {
      return;
    }
    return {
      ...foundTour,
      steps: foundTour.steps.map((step) => ({
        ...step,
        content: t(step.content),
      })),
    };
  }, [tourId, t]);
  const { steps } = tour;
  const filteredSteps = useMemo(() => {
    return steps.filter((step) => !skipStep || step.target !== skipStep);
  }, [steps, skipStep]);
  return (
    <div>
      <Joyride
        steps={filteredSteps}
        run={runTour}
        callback={handleJoyrideCallback}
        stepIndex={tourStepIndex}
        continuous
        showProgress
        showSkipButton
        tooltipComponent={(tooltipProps) => <ProductTourTooltip {...tooltipProps} stepCount={filteredSteps?.length} />}
        styles={{
          options: {
            zIndex: 1001,
          },
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  urlOrganisation: state.organisations.urlOrganisation,
  demoingTour: state.organisations.demoingTour,
});
const mapDispatchToProps = (dispatch) => ({
  setDemoingTour: (tourId) => dispatch(actions.organisations.setDemoingTour(tourId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductTour);

import React, { Component } from "react";
import styled from "styled-components";

const ContainerCheck = styled.label`
  display: block;
  position: relative;
  padding-left: 33px;
  height: 20px;
  margin: ${({ $compact }) => ($compact ? "0" : "5px 0")};
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 5px;
    height: 20px;
    width: 20px;
    background-color: ${({ $checked, theme }) => ($checked ? theme.primaryColour : "#aaadab")};
    border-radius: 7px;
  }
  .checkmark:after {
    left: 8.3px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
    position: absolute;
    display: none;
  }
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

class Checkbox extends Component {
  render() {
    const { label, checked, Click, onChange, compact } = this.props;
    return (
      <div>
        <ContainerCheck $checked={checked} $compact={compact} className="simply-do checkbox-label">
          <input type="checkbox" checked={checked} onChange={Click || onChange} />
          <LabelContainer>
            <span>{label}</span>
          </LabelContainer>
          <span className="checkmark" />
        </ContainerCheck>
      </div>
    );
  }
}

export default Checkbox;

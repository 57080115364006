import React from "react";
import { Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { OpenAPI } from "simplydo/interfaces";

const getDropdownItems = (user, type) => {
  if (!user) {
    return [];
  }
  if (type === "groups") {
    if (!user.groups) return [];
    return user.groups.map((g) => ({ _id: g._id, link: `/groups/${g._id}`, title: g.name }));
  }
  if (type === "challenges") {
    if (!user.challenges) return [];
    return user.challenges.map((c) => ({ _id: c._id, link: `/challenges/${c._id}`, title: c.name }));
  }
  if (type === "ideas") {
    if (!user.ideas) return [];
    return user.ideas.map((i) => ({ _id: i._id, link: `/ideas/${i._id}`, title: i.name }));
  }
  return [];
};

export const HoverLink = styled.div<{ $isActive?: number }>`
  padding: 7.5px 15px;
  cursor: ${({ $isActive }) => ($isActive ? "default" : "pointer")};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;

  &:hover {
    color: black;
  }
  &:first-of-type {
    border-top-left-radius: 2.5px;
    border-top-right-radius: 2.5px;
  }
  &:last-of-type {
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
  }
  a,
  span {
    color: black;
    display: block;
    overflow: hidden;
  }
  ${({ $isActive, theme }) =>
    $isActive
      ? `
    background-color: ${theme.accent};
    span {
      color: ${theme.shouldBeWhiteOnAccent ? "#fff" : "#333"};
    }
  `
      : `
    &:hover {
      background-color: #e9ebee;
      a, span {
        color: black;
      }
    }
  `}
`;

const HoverLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  border: none;
  > span {
    padding: 10px 15px 7.5px;
    font-size: 1.1em;
    font-weight: bold;
  }
`;

const LinkContainer = styled.div``;

type HoverLinkDropdownProps = {
  user: OpenAPI.GET<"/users/me">["response"];
  type?: string;
  title: string;
  trigger: any;
  content?: any;
};

const isActiveRoute = (link) => window.location.pathname === link;

const HoverLinkDropdown = ({ user, type, title, trigger, content }: HoverLinkDropdownProps) => {
  const dropdownItems = getDropdownItems(user, type);
  if (!(content || dropdownItems?.length)) return trigger;
  return (
    <Popup
      on="hover"
      hoverable
      trigger={<LinkContainer>{trigger}</LinkContainer>}
      position="bottom center"
      style={{ padding: 0 }}
      offset={[0, -1]}
    >
      <HoverLinkContainer>
        <span>{title || "Quick links"}</span>
        {content ??
          dropdownItems.slice(0, 6).map((item) => (
            <HoverLink key={item._id} as={Link} to={item.link} $isActive={isActiveRoute(item.link) ? 1 : 0}>
              {isActiveRoute(item.link) ? <span>{item.title}</span> : <Link to={item.link}>{item.title}</Link>}
            </HoverLink>
          ))}
      </HoverLinkContainer>
    </Popup>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(HoverLinkDropdown);

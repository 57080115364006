import util from "utils/utils";
import { DefaultPreset } from "../../Remirror/extensions/default";

const MinimalPreset = [
  ...DefaultPreset,
  {
    name: "mention",
    config: {
      name: "users",
      char: "@",
      filter: () => true, // skip frontend filter
      formatMention: (user) => ({
        href: `/users/${user._id}`,
        id: user._id,
        label: user?.profile?.fullName,
        description: user?.profile?.department || user?.profile?.jobTitle || "User",
        image: util.avatarUrl(user),
      }),
    },
  },
  {
    name: "mention",
    config: {
      name: "ideas",
      char: "$",
      filter: () => true, // skip frontend filter
      formatMention: (idea) => ({
        href: `/ideas/${idea._id}`,
        id: idea._id,
        label: idea?.name,
        description: "Idea",
        image: util.ideaCoverImage(idea),
      }),
    },
  },
  {
    name: "mention",
    config: {
      name: "challenges",
      char: "$",
      filter: () => true, // skip frontend filter
      formatMention: (challenge) => ({
        href: `/challenges/${challenge._id}`,
        id: challenge._id,
        label: challenge?.name,
        description: "Challenge",
        image: util.getImageUrl(challenge, ["coverImage", "bannerImage"], ["200x200"]),
      }),
    },
  },
  {
    name: "mention",
    config: {
      name: "groups",
      char: "$",
      filter: () => true, // skip frontend filter
      formatMention: (group) => ({
        href: `/groups/${group._id}`,
        id: group._id,
        label: group?.name,
        description: "Group",
        image: util.groupCoverImage(group),
      }),
    },
  },
  {
    name: "mention",
    config: {
      name: "emoji",
    },
  },
  {
    name: "link",
    config: {
      autoLink: true,
      autoLinkRegex: /(http:\/\/|https:\/\/)?(www\.)?[\da-z]+([.-][\da-z]+)*\.[a-z]{2,5}(:\d{1,5})?(\/\S*)?/g,
    },
  },
];

export default MinimalPreset;

import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import toast, { Toaster, ToastBar } from "react-hot-toast";

const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  .icon-container {
    margin-right: 10px;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 19px;
    max-width: 270px;
    span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  .meta {
    color: gray;
  }
  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
  `}
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 2.5px;
  cursor: pointer;
  color: gray;
`;

const ToastIcon = ({ name }) => <Icon name={name} size={64} />;

const CustomToaster = (props) => (
  <Toaster {...props}>
    {(t) => (
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <>
            {icon}
            {message}
            {t.type !== "loading" ? (
              <>
                <div style={{ width: 5 }} />
                <StyledIcon onClick={() => toast.dismiss(t.id)} name="close" size="small" />
              </>
            ) : null}
          </>
        )}
      </ToastBar>
    )}
  </Toaster>
);

export { ToastContainer, ToastIcon, CustomToaster as Toaster };

import React, { useState, useEffect, useCallback } from "react";
import { Modal, Breadcrumb, Button, Table, Icon, Loader } from "semantic-ui-react";
import api from "api";
import util from "utils/utils";

const imageTypes = ["jpg", "jpeg", "png"];

function OneDriveFileChooser({ open, imagesOnly, forId, forType, onComplete, onError, onClose }) {
  const [path, _setPath] = useState("/");
  const [currentDirectory, setCurrentDirectory] = useState([]);
  const [didInit, setDidInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const setPath = useCallback((newPath) => {
    setUpdating(true);
    let tempPath = "";
    _setPath((prevPath) => {
      tempPath = prevPath;
      return newPath;
    });
    api.uploads.oneDriveListDirectory(
      newPath,
      (data) => {
        setUpdating(false);
        setCurrentDirectory(data.directory);
      },
      () => {
        _setPath(tempPath);
        setUpdating(false);
      },
    );
  }, []);

  useEffect(() => {
    if (open && !didInit) {
      setDidInit(true);
      setPath("/");
    }
  }, [didInit, open, setPath]);

  function isChooseable(file) {
    if (file.type === "file") {
      if (!imagesOnly) return true;
      for (let i = 0; i < imageTypes.length; i += 1) {
        if (file.name.toLowerCase().indexOf(`.${imageTypes[i]}`) > 1) return true;
      }
    }
    return false;
  }

  function pathComponents() {
    return path.split("/").filter((p) => p.length);
  }

  function setPathToPoint(index) {
    let newPath = "/";
    const components = pathComponents();
    for (let i = 0; i <= index; i += 1) {
      newPath += components[i];
    }
    setPath(newPath);
  }

  function fileChosen(file) {
    if (!file || !isChooseable(file)) return;
    setLoading(true);
    api.uploads.storeOneDriveFileInS3(
      file.itemId,
      file.name,
      forId,
      forType,
      (data) => {
        setLoading(false);
        onComplete(data.fileName, file.name, data.signedDownloadRequest);
      },
      (err) => {
        setLoading(false);
        if (onError) onError(err.message || "Unable to upload file");
      },
    );
  }

  return (
    <React.Fragment>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={open}
        dimmer="inverted"
        size="tiny"
        style={{ zIndex: 100 }}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <Icon name="microsoft" /> Your OneDrive
        </Modal.Header>
        <Modal.Content>
          {updating && <Loader active />}
          {path.length > 1 && (
            <Breadcrumb>
              <Breadcrumb.Section link onClick={() => setPath("/")}>
                Home
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              {pathComponents()?.map((p, i) => (
                <>
                  <Breadcrumb.Section link onClick={() => setPathToPoint(i)}>
                    {p}
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider />
                </>
              ))}
            </Breadcrumb>
          )}
          {!updating && !currentDirectory.length && <h4 style={{ textAlign: "center" }}>This directory is empty</h4>}
          <Table basic="very">
            <Table.Body>
              {currentDirectory.map((d) => (
                <Table.Row
                  disabled={d.type === "file" && !isChooseable(d)}
                  key={d.itemId}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (d.type === "folder") {
                      setPath(`${path}/${d.name}`);
                    } else {
                      fileChosen(d);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Table.Cell collapsing>
                    <Icon name={d.type === "folder" ? "folder" : util.getFileIcon(d.name)} />
                  </Table.Cell>
                  <Table.Cell>{d.name}</Table.Cell>
                  <Table.Cell collapsing>
                    {isChooseable(d) ? (
                      <Button
                        loading={loading}
                        primary
                        size="mini"
                        onClick={(e) => {
                          e.stopPropagation();
                          fileChosen(d);
                        }}
                        content="Choose"
                      />
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={onClose} content="Cancel" />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
}

export default OneDriveFileChooser;

import React, { useEffect, useState, useRef } from "react";
import { Menu, Progress, Popup, Button, Input } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import api from "api";

const ChooserPopup = styled(Popup)`
  z-index:5;
  max-width:400px !important;
  .menu {
    max-width:400px !important;
    min-width:300px !important;
  }
}`;

function VideoChooser({
  forType,
  forId,
  trigger,
  content,
  isOpen,
  onOpen,
  onClose,
  onError,
  onComplete,
  t,
  buttonStyle,
  isBasic,
}) {
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInput = useRef(null);

  useEffect(() => {
    if (open !== isOpen) {
      setOpen(isOpen);
    }
  }, [open, isOpen]);

  const togglePopup = () => {
    if (!open && onOpen) onOpen();
    if (open && onClose) onClose();
    setOpen(!open);
  };

  const onChosen = (responseFilename, filename, responseDownload) => {
    setOpen(false);
    onComplete(responseFilename, filename, responseDownload);
  };

  const updateVideoFromLink = () => {
    let storedLink;
    if (link.toLowerCase().indexOf("youtube") > -1 || link.toLowerCase().indexOf("youtu.be") > -1) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = link.match(regExp);
      if (match && match[2].length === 11) {
        storedLink = `embed-https://youtube.com/embed/${match[2]}`;
      } else if (onError) {
        return onError("This YouTube link is invalid");
      }
    } else if (link.toLowerCase().indexOf("vimeo.com") > -1) {
      const regExp = /^.*(player\.)*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/)|(video\/))?([0-9]+)/;
      const match = link.match(regExp);
      if (match) {
        storedLink = `embed-https://player.vimeo.com/video/${match[7]}`;
      } else if (onError) {
        return onError("This Vimeo link is invalid");
      }
    } else if (link.length === 11) {
      storedLink = `embed-https://youtube.com/embed/${link}`;
    }
    if (!storedLink) {
      return onError && onError("Unable to get embed link");
    }
    onChosen(storedLink, storedLink, storedLink);
    setLink("");
  };

  const chooseVideo = (e) => {
    e.stopPropagation();
    fileInput.current.click();
  };
  const handleFileChosen = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setIsUploading(true);
      setUploadProgress(1);
      const regex = new RegExp("[^a-zA-Z0-9_.]", "g");
      const name = file.name.replace(regex, "-");
      api.uploads.generateVideoUploadRequest(
        {
          forType,
          forId,
          name,
          size: file.size,
          type: file.type,
        },
        (response) => {
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", response.signedRequest);
          xhr.setRequestHeader("Content-Type", file.type);
          xhr.upload.addEventListener("progress", (progress) => {
            if (progress.lengthComputable) {
              setUploadProgress(parseInt((progress.loaded * 100) / progress.total, 10));
            }
          });
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              setIsUploading(false);
              setUploadProgress(100);
              if (xhr.status === 200) {
                onChosen(response.fileName, name, response.signedDownloadRequest);
              } else if (onError) {
                onError("Unable to upload file");
              }
            }
          };
          xhr.send(file);
        },
        (err) => {
          setIsUploading(false);
          setUploadProgress(0);
          if (onError) onError(err.message || "Unable to upload file");
        },
      );
    }
  };

  return (
    <ChooserPopup
      on="click"
      open={open}
      position="bottom left"
      trigger={
        trigger ? (
          React.cloneElement(trigger, { loading: isUploading || undefined, onClick: togglePopup })
        ) : (
          <Button
            size="small"
            basic={isBasic}
            style={buttonStyle}
            secondary
            fluid
            icon="film"
            content={content || "Choose a video"}
            loading={isUploading}
            onClick={togglePopup}
          />
        )
      }
      content={
        <div>
          <h5 style={{ marginTop: 0 }}>Upload a video</h5>
          <Menu vertical borderless>
            {isUploading && <Progress percent={uploadProgress} indicating progress style={{ margin: 5 }} />}
            <Menu.Item onClick={chooseVideo}>
              <i className="upload icon" />
              {t("ideas.actions.chooseVideo")}
            </Menu.Item>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInput}
              onChange={handleFileChosen}
              accept=".mp4,.mov"
            />
          </Menu>
          <h5 style={{ marginTop: 0 }}>Embed a video from YouTube or Vimeo</h5>
          <Input
            size="small"
            fluid
            icon="youtube"
            iconPosition="left"
            placeholder="Enter link to video..."
            onChange={(e) => setLink(e.target.value)}
            action={<Button size="tiny" primary content="Embed" onClick={updateVideoFromLink} />}
          />
          <div style={{ textAlign: "left", marginTop: 20 }}>
            <Button basic size="tiny" content={t("generic.cancel")} onClick={togglePopup} />
          </div>
        </div>
      }
    />
  );
}

export default withTranslation()(VideoChooser);

import React from "react";
import { Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

const StyledMessageItem = styled(Message.Item)`
  ${({ $password }) =>
    $password && $password.length < 8
      ? `
    color: #cc202d;
  `
      : ""}
`;

const PasswordGuidance = ({ password = "", t }) => (
  <Message>
    <Message.Header>{t("entry.register.passwordGuidance.title")}</Message.Header>
    <Message.List>
      <StyledMessageItem $password={password}>{t("entry.register.passwordGuidance.passwordLimit")}</StyledMessageItem>
      <StyledMessageItem>{t("entry.register.passwordGuidance.noRepeat")}</StyledMessageItem>
      <StyledMessageItem>{t("entry.register.passwordGuidance.manager")}</StyledMessageItem>
    </Message.List>
  </Message>
);

export default withTranslation()(PasswordGuidance);

import React, { HTMLProps } from "react";
import { SemanticICONS, LabelProps, Icon, Label } from "semantic-ui-react";
import styled from "styled-components";

const CircularButtonWithNumberWrapper = styled.div<{ $square?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  > .action-button {
    border-radius: ${({ $square }) => ($square ? "10%" : "50%")};
    font-size: 20px;
    background-color: rgb(240, 240, 240);
    transition: background-color 0.25s;
    height: 30px;
    width: 30px;
    cursor: pointer;
    .icon {
      display: block;
      margin: 5px auto;
    }
    a,
    .icon {
      transition: color 0.25s;
      color: rgb(120, 136, 150);
    }
    &:hover {
      background-color: ${({ theme }) => theme.accent};
      a,
      .icon {
        color: ${({ theme }) => (theme.shouldBeWhiteOnAccent ? "white" : "#4a4544")};
      }
    }
  }
  img {
    object-fit: cover;
    width: 30px !important;
    height: 30px !important;
    border-radius: ${({ $square }) => ($square ? "10%" : "50%")};
  }
  i.icon {
    margin: 5px;
  }
`;

const StyledLabel = styled(Label)`
  margin: -5px -12.5px !important;
  z-index: 300;
`;

type CircularButtonWithNumberProps = HTMLProps<HTMLDivElement> & {
  icon?: SemanticICONS;
  image?: string;
  labelProps?: LabelProps;
  count: number | string;
  square?: boolean;
};

const CircularButtonWithNumber = ({
  icon,
  image,
  count,
  labelProps = {},
  square,
  ...divProps
}: CircularButtonWithNumberProps) => (
  <CircularButtonWithNumberWrapper $square={square} {...divProps}>
    <div className="action-button">
      {icon ? <Icon name={icon} /> : null}
      {image ? <img src={image} alt="org favicon" /> : null}
    </div>
    {count ? (
      <StyledLabel circular size="small" color="red" {...labelProps}>
        {count}
      </StyledLabel>
    ) : null}
  </CircularButtonWithNumberWrapper>
);

export default CircularButtonWithNumber;

import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Message,
  Modal,
  Button,
  Divider,
  Grid,
  Icon,
  Segment,
  Select,
  Checkbox,
  AccordionTitle,
  AccordionContent,
  Label,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { TooltipButton } from "components/lib/UI";
import { DateInput } from "components/lib/DateInputs";
import { TimeInput } from "semantic-ui-calendar-react";
import uuid from "uuid";
import toast from "react-hot-toast";
import api from "api";
import moment from "moment";
import util from "utils/utils";
import AnnouncementBase from "./Base";
import EmojiChooser from "components/lib/Emoji/EmojiChooser";
import { StyledModal } from "../StyledModal";

import TimezoneExplainer from "../../TimezoneExplainer";

const defaultSendAt = moment().add(1, "d").startOf("day").add(9, "hours").toDate();

const StandardAnnouncementModal = ({
  forType,
  forId,
  standardConditions,
  standardConditionsRequired,
  scheduledAnnouncements,
  setScheduledAnnouncements,
  editingAnnouncement,
  setEditingAnnouncement,
  deleteAnnouncement,
}) => {
  const [recipientsMatched, setRecipientsMatched] = useState(0);
  const [subconditionOptions, setSubconditionOptions] = useState({});
  const [sending, setSending] = useState(false);
  const [activeAccordionIndexes, setActiveAccordionIndexes] = useState([]);
  const { t } = useTranslation();

  const toggleActiveAccordion = useCallback((index) => {
    setActiveAccordionIndexes((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index);
      } else {
        return [...prevState, index];
      }
    });
  }, []);

  const addCondition = useCallback(() => {
    setEditingAnnouncement((prevAnn) => ({
      ...prevAnn,
      recipientConditions: [...(prevAnn.recipientConditions ?? []), { type: null, data: null, key: uuid.v4() }],
    }));
  }, [setEditingAnnouncement]);

  const getSubconditions = useCallback(
    (standardCondition) => standardConditions.find((sc) => sc.value === standardCondition?.type)?.subConditions,
    [standardConditions],
  );

  const updateCondition = useCallback(
    (conditionIndex, label, value) => {
      const updatedRecipientConditions = [...editingAnnouncement.recipientConditions];
      updatedRecipientConditions[conditionIndex][label] = value;
      setEditingAnnouncement((prev) => ({ ...prev, recipientConditions: updatedRecipientConditions }));
    },
    [editingAnnouncement.recipientConditions, setEditingAnnouncement],
  );

  const deleteCondition = useCallback(
    (conditionIndex) => {
      const updatedRecipientConditions = [...editingAnnouncement.recipientConditions];
      updatedRecipientConditions.splice(conditionIndex, 1);
      setEditingAnnouncement((prev) => ({ ...prev, recipientConditions: updatedRecipientConditions }));
    },
    [editingAnnouncement.recipientConditions, setEditingAnnouncement],
  );

  const updateSubconditionOptions = useCallback(
    (conditionKey, option, selectSingle) => {
      const updatedRecipientConditions = editingAnnouncement.recipientConditions.map((condition) => {
        if (condition.key !== conditionKey) {
          return condition;
        }
        if (selectSingle) {
          return { ...condition, data: option };
        }

        const isAdding = !condition.data || !condition.data.includes(option);
        if (isAdding) {
          return { ...condition, data: [...(condition.data || []), option] };
        }
        return { ...condition, data: condition.data.filter((e) => e !== option) };
      });
      setEditingAnnouncement((prev) => ({ ...prev, recipientConditions: updatedRecipientConditions }));
    },
    [editingAnnouncement.recipientConditions, setEditingAnnouncement],
  );

  const updateSendAt = useCallback(
    (type, value) => {
      const date = type === "date" ? value : moment(editingAnnouncement.sendAt).format("YYYY-MM-DD");
      const time = type === "time" ? value : moment(editingAnnouncement.sendAt).format("HH:mm");
      const newDate = moment(`${date} ${time}`);
      setEditingAnnouncement((prev) => ({ ...prev, sendAt: newDate.isValid() ? newDate.toISOString() : value }));
    },
    [editingAnnouncement.sendAt, setEditingAnnouncement],
  );

  useEffect(() => {
    let fetching = true;
    api.announcements.getAudience(
      forType,
      forId,
      {
        type: "standard",
        recipientConditions: editingAnnouncement.recipientConditions,
      },
      ({ recipientsMatched: response }) => {
        if (!fetching) return;
        setRecipientsMatched(response);
      },
      (err) => {
        if (!fetching) return;
        toast.error(err.message);
        setRecipientsMatched(0);
      },
    );

    return () => {
      fetching = false;
    };
  }, [forType, forId, editingAnnouncement.recipientConditions]);

  const createStandard = useCallback(() => {
    setSending(true);
    const {
      subject,
      body,
      text,
      replyTo,
      callToActionText,
      callToActionLink,
      recipientConditions,
      calendarEvent,
      attachments,
      fromUser,
    } = editingAnnouncement;
    api.announcements.create(
      forType,
      forId,
      {
        type: "standard",
        recipientConditions,
        subject,
        body,
        text,
        replyTo,
        callToActionText,
        callToActionLink,
        calendarEvent,
        attachments,
        fromUser: fromUser?._id,
      },
      (announcement) => {
        toast.success(t("announcements.sent", { audience: `${announcement.recipientsMatched} recipients` }));
        setSending(false);
        setEditingAnnouncement({});
        setRecipientsMatched(0);
      },
      (err) => {
        setSending(false);
        toast.error(err.message);
      },
    );
  }, [forType, forId, t, editingAnnouncement, setRecipientsMatched, setEditingAnnouncement]);

  const createScheduled = useCallback(() => {
    setSending(true);
    const {
      subject,
      body,
      text,
      replyTo,
      sendAt,
      recipientConditions,
      callToActionText,
      callToActionLink,
      calendarEvent,
      attachments,
      fromUser,
    } = editingAnnouncement;
    api.announcements.create(
      forType,
      forId,
      {
        type: "scheduled",
        recipientConditions,
        subject,
        body,
        text,
        sendAt,
        replyTo,
        callToActionText,
        callToActionLink,
        calendarEvent,
        attachments,
        fromUser: fromUser?._id,
      },
      (announcement) => {
        toast.success(t("announcements.created"));
        setScheduledAnnouncements((prev) => [...prev, announcement]);
        setSending(false);
        setRecipientsMatched(0);
        setEditingAnnouncement({});
      },
      (err) => {
        setSending(false);
        toast.error(err.message);
      },
    );
  }, [forType, forId, t, editingAnnouncement, setScheduledAnnouncements, setRecipientsMatched, setEditingAnnouncement]);

  const updateAnnouncement = useCallback(() => {
    setSending(true);
    api.announcements.update(
      forType,
      forId,
      editingAnnouncement._id,
      {
        recipientConditions: editingAnnouncement.recipientConditions,
        subject: editingAnnouncement.subject,
        body: editingAnnouncement.body,
        text: editingAnnouncement.text,
        sendWhen: editingAnnouncement.sendWhen,
        replyTo: editingAnnouncement.replyTo,
        callToActionText: editingAnnouncement.callToActionText,
        callToActionLink: editingAnnouncement.callToActionLink,
        sendAt: moment(editingAnnouncement.sendAt).toISOString(),
        calendarEvent: editingAnnouncement?.calendarEvent,
        attachments: editingAnnouncement.attachments,
        fromUser: editingAnnouncement.fromUser?._id,
      },
      (announcement) => {
        const updatedAnnouncements = Object.assign([], scheduledAnnouncements).map((a) => {
          if (a._id === announcement._id) return { ...announcement };
          return a;
        });
        setScheduledAnnouncements(updatedAnnouncements);
        setSending(false);
        setEditingAnnouncement({});
        toast.success(t("generic.saved", { type: "Announcement" }));
      },
      (err) => {
        toast.error(err.message);
        setSending(false);
      },
    );
  }, [
    t,
    forType,
    forId,
    editingAnnouncement,
    scheduledAnnouncements,
    setScheduledAnnouncements,
    setEditingAnnouncement,
  ]);

  const { recipientConditions = [] } = editingAnnouncement;

  useEffect(() => {
    recipientConditions.forEach((c) => {
      const cond = standardConditions.find((sc) => sc.value === c.type);
      if (cond && cond.subConditions) {
        cond.subConditions.forEach((subcondition) => {
          if (subcondition.getOptions && subcondition.key && !subconditionOptions[subcondition.key]) {
            subcondition.getOptions(forId, (options) =>
              setSubconditionOptions((prev) => ({
                ...prev,
                [subcondition.key]: options,
              })),
            );
          }
        });
      }
    });
  }, [standardConditions, subconditionOptions, forId, recipientConditions]);

  const sendAtValid = useMemo(() => moment(editingAnnouncement.sendAt).isValid(), [editingAnnouncement.sendAt]);
  const scheduledDate = useMemo(
    () => moment(editingAnnouncement.sendAt).format("YYYY-MM-DD"),
    [editingAnnouncement.sendAt],
  );
  const scheduledTime = useMemo(() => moment(editingAnnouncement.sendAt).format("HH:mm"), [editingAnnouncement.sendAt]);

  const sendDisabled = useMemo(() => {
    if (util.isEmpty(editingAnnouncement.subject)) return true;
    if (util.isEmpty(editingAnnouncement.body)) return true;
    if (standardConditionsRequired && standardConditions?.length && recipientConditions.length === 0) return true;
    return false;
  }, [editingAnnouncement, standardConditions, recipientConditions, standardConditionsRequired]);

  const sendDisabledMessage = useMemo(() => {
    return standardConditions?.length > 0
      ? "Please fill in the subject, body and recipient conditions."
      : "Please fill in the subject and body.";
  }, [standardConditions]);

  return (
    <>
      <StyledModal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={!util.isEmpty(editingAnnouncement)}
        onClose={() => setEditingAnnouncement({})}
      >
        <Modal.Header>
          {t("announcements.manual.new.title")}
          <br />
          <small>{t("announcements.manual.sendInfo")}</small>
        </Modal.Header>
        <Modal.Content>
          {standardConditions?.length ? (
            <Segment>
              <h3>{t("announcements.manual.recipients.title")}</h3>
              <p>{t("announcements.manual.recipients.info")}</p>
              {standardConditionsRequired ? null : (
                <p>{t("announcements.manual.recipients.condition.optional", { type: forType })}</p>
              )}
              <h5 style={{ fontSize: "small", marginBottom: 5 }}>
                Recipient conditions{" "}
                {standardConditionsRequired ? (
                  <span style={{ color: "red", marginBottom: 4, fontSize: "small" }}>*</span>
                ) : null}
              </h5>
              {recipientConditions.map((c, i) => {
                const subconditions = getSubconditions(c);

                return (
                  <>
                    <Grid stackable key={c.value}>
                      <Grid.Column computer={8}>
                        <Select
                          placeholder={t("announcements.manual.recipients.condition.select")}
                          fluid
                          options={standardConditions.map((s) => ({ ...s, key: s.value, text: t(s.text) }))}
                          onChange={(e, s) => {
                            updateCondition(i, "type", s.value);
                          }}
                          value={c.type}
                        />
                      </Grid.Column>
                      <Grid.Column computer={6}>
                        {subconditions &&
                          subconditions.map((subcondition) => {
                            return (
                              <>
                                {subcondition.type === "stamps" ? (
                                  <EmojiChooser
                                    onComplete={(em) => updateSubconditionOptions(c.key, em)}
                                    onRemoveExisting={(em) => updateSubconditionOptions(c.key, em, false)}
                                    existing={c.data}
                                  />
                                ) : null}
                                {subcondition.type === "dropdown" ? (
                                  <Select
                                    placeholder={subcondition.placeholder ?? "Select an option"}
                                    fluid
                                    options={(subconditionOptions[subcondition.key] ?? []).map((s) => ({
                                      key: s._id,
                                      value: s._id,
                                      text: s.name,
                                    }))}
                                    onChange={(e, s) => updateSubconditionOptions(c.key, s.value, true)}
                                    value={c.data}
                                  />
                                ) : null}
                              </>
                            );
                          })}
                      </Grid.Column>
                      <Grid.Column computer={2} textAlign="center" style={{ marginTop: 5 }}>
                        <Button size="mini" basic icon="trash" onClick={() => deleteCondition(i)} />
                      </Grid.Column>
                    </Grid>
                    {i !== recipientConditions.length - 1 ? <Divider horizontal>And</Divider> : null}
                  </>
                );
              })}
              <Button
                size="small"
                icon="plus"
                content={t("announcements.manual.recipients.condition.add")}
                basic
                onClick={addCondition}
                style={{ marginTop: 10 }}
              />
              {recipientsMatched === 0 ? (
                <h4 style={{ marginTop: 20 }}>
                  <Icon name="exclamation triangle" /> {t("announcements.manual.recipients.none")}
                </h4>
              ) : (
                <h4 style={{ marginTop: 20 }}>This announcement will be sent to {recipientsMatched} users.</h4>
              )}
            </Segment>
          ) : (
            <h3>{t("announcements.manual.recipients.all")}</h3>
          )}
          <AnnouncementBase
            forType={forType}
            forId={forId}
            announcement={editingAnnouncement}
            setAnnouncement={setEditingAnnouncement}
            activeAccordionIndexes={activeAccordionIndexes}
            toggleActiveAccordion={toggleActiveAccordion}
            accordionPanels={
              <>
                <AccordionTitle
                  index={4}
                  onClick={() => toggleActiveAccordion(4)}
                  active={activeAccordionIndexes.includes(4)}
                >
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <h3 style={{ marginBottom: 0 }}>Send when</h3>
                    {editingAnnouncement.sendAt && (
                      <Label
                        style={{ marginLeft: 10 }}
                        color="blue"
                        size="small"
                        content={`${moment(editingAnnouncement.sendAt).format("MMMM Do YYYY [at] HH:mm")}`}
                      />
                    )}
                  </div>
                </AccordionTitle>
                <AccordionContent active={activeAccordionIndexes.includes(4)}>
                  <p>This announcement can be scheduled to be sent at a later date instead of right away.</p>
                  <Checkbox
                    slider
                    label="Schedule this announcement to be sent at a later date"
                    checked={!!editingAnnouncement.sendAt}
                    onChange={(e, { checked }) =>
                      setEditingAnnouncement((prev) => ({
                        ...prev,
                        sendAt: checked ? prev.sendAt || defaultSendAt : null,
                      }))
                    }
                  />
                  {editingAnnouncement.sendAt ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        <span>Send announcement on</span>
                        <DateInput
                          name="date"
                          placeholder="Date"
                          value={sendAtValid ? scheduledDate : editingAnnouncement.sendAt}
                          minDate={moment().toDate()}
                          dateFormat="YYYY-MM-DD"
                          iconPosition="left"
                          onChange={(e, { value }) => updateSendAt("date", value)}
                          style={{ marginLeft: 10, marginRight: 10, marginBottom: -10 }}
                        />
                        <span style={{ marginLeft: 20 }}> at </span>
                        <TimeInput
                          name="time"
                          placeholder="Time"
                          value={sendAtValid ? scheduledTime : editingAnnouncement.sendAt}
                          disableMinute
                          iconPosition="left"
                          onChange={(e, { value }) => updateSendAt("time", value)}
                          style={{ marginLeft: 10, marginRight: 10, marginBottom: 5 }}
                        />
                      </div>
                      <TimezoneExplainer
                        time={sendAtValid ? scheduledTime : editingAnnouncement.sendAt}
                        infoPrefix="The announcement will be sent at"
                        style={{ marginTop: 10 }}
                      />
                    </>
                  ) : null}
                </AccordionContent>
              </>
            }
          />
          <Message
            icon="info"
            info
            size="small"
            header={`Don't use ${util.appName()} to send out marketing messages`}
            content={
              "For GDPR purposes, announcements should be used only for Simply Do activity and not for advertising events, services or other marketing correspondence."
            }
          />
        </Modal.Content>

        <Modal.Actions>
          <span>{t("announcements.form.info", { audience: recipientsMatched })}</span>
          <Button content={t("generic.cancel")} onClick={() => setEditingAnnouncement({})} />
          {editingAnnouncement._id ? (
            <Button
              secondary
              icon="send"
              content={t("generic.saveChanges")}
              onClick={updateAnnouncement}
              loading={sending}
            />
          ) : (
            <>
              {editingAnnouncement.sendAt ? (
                <TooltipButton
                  secondary
                  icon="calendar"
                  content={
                    sendAtValid
                      ? `Send on ${moment(editingAnnouncement.sendAt).format("MMMM Do YYYY [at] HH:mm")}`
                      : "Invalid date entered"
                  }
                  onClick={createScheduled}
                  loading={sending}
                  disabled={!sendAtValid || sendDisabled}
                  tooltip={sendDisabled ? sendDisabledMessage : null}
                />
              ) : (
                <TooltipButton
                  secondary
                  icon="send"
                  content={t("announcements.form.send")}
                  onClick={createStandard}
                  loading={sending}
                  disabled={sendDisabled}
                  tooltip={sendDisabled ? sendDisabledMessage : null}
                />
              )}
            </>
          )}
        </Modal.Actions>
        {editingAnnouncement._id ? (
          <Modal.Actions>
            <h4>{t("announcements.delete.title")}</h4>
            <Button
              onClick={() => {
                deleteAnnouncement(editingAnnouncement._id, () => {
                  setEditingAnnouncement({});
                });
              }}
              content={t("generic.delete")}
              icon="trash"
              basic
              size="small"
            />
          </Modal.Actions>
        ) : null}
      </StyledModal>
    </>
  );
};

export default StandardAnnouncementModal;

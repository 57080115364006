import React, { useMemo } from "react";
import { Header, Icon, Button, Divider } from "semantic-ui-react";
import { FileChooser } from "components/lib/Choosers";
import { withTranslation } from "react-i18next";
import { IdeaFile } from "components/lib/Ideas";
import toast from "react-hot-toast";
import { BodySegment } from "./styles";

const File = ({ user, field, ideaId, fieldValue = "", updateIdea, isEditing, t }) => {
  const idea = useMemo(() => ({ _id: ideaId }), [ideaId]);

  const FileComponent = useMemo(() => <IdeaFile idea={idea} file={fieldValue} />, [fieldValue, idea]);

  if (!isEditing) {
    if (fieldValue) return FileComponent;
    return null;
  }
  return (
    <BodySegment placeholder>
      <Header icon>
        <Icon name="file" /> Choose a file
      </Header>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <FileChooser
            user={user}
            trigger={<Button primary icon="file" content={t("ideas.actions.chooseFile")} />}
            forType="idea"
            forId={ideaId}
            onComplete={(_, fileName, imageSrc) => {
              updateIdea(
                {
                  value: imageSrc,
                  name: fileName,
                },
                ["templated", field.id],
              );
            }}
            onError={toast.error}
            popupProps={{
              position: "bottom center",
            }}
          />
          <Button
            basic
            size="medium"
            icon="trash"
            content={t("generic.remove")}
            onClick={() => updateIdea(null, ["templated", field.id])}
          />
        </div>
      </div>
      {fieldValue ? (
        <>
          <Divider hidden />
          {FileComponent}
        </>
      ) : null}
    </BodySegment>
  );
};

export default withTranslation()(File);

import React from "react";
import * as Sentry from "@sentry/react";

import ErrorScreen from "components/lib/Error/ErrorScreen";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorLogged: null, errorFound: false };
    this.setError = this.setError.bind(this);
  }

  static getDerivedStateFromError() {
    return { errorFound: true };
  }

  componentDidCatch(err) {
    this.setState({ errorFound: true });

    Sentry.captureException(err);
  }

  setError() {
    this.setState({ errorFound: false });
  }

  render() {
    const { errorFound } = this.state;
    if (errorFound) {
      return <ErrorScreen setError={this.setError} theme={this.props.theme} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { useState, useMemo } from "react";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, Icon, Label, Modal, Button, Popup } from "semantic-ui-react";
import moment from "moment";
import actions from "actions";
import util from "utils/utils";
import api from "api";

import { ImageWithFallback } from "components/lib/ImageWithFallback";
import { Sticky, PlaceholderItem, TooltipButton } from "components/lib/UI";
import { UserChip } from "components/lib/Chips";
import { IdeaFollowButton } from "components/lib/FollowButton";
import { IdeaChallengeHelp, SubmitIdeaButton } from "components/lib/Ideas";

const MobileContainer = styled.div`
  width: 100%;
  padding: 0 5px;
`;

const IdeaStatusBarDiv = styled.div`
  height: ${({ theme }) => (theme.sizes.isMobile ? 40 : 50)}px;
  padding: ${({ theme }) => (theme.sizes.isMobile ? "5px 5px" : "7.5px 15px")};
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom right, ${theme.secondaryColour}, ${util.adjustColour(theme.secondaryColour, 0.5)})`};
  display: flex;
  align-items: center;
  top: 50px;
  width: 100%;
`;

const StatusLabel = styled(Label)`
  min-width: max-content;
  height: 27px;
  display: flex !important;
  align-items: center;
`;

const StatusbarInnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  min-width: ${({ $flexGrow }) => ($flexGrow === 0 ? "max-content" : 0)};
`;

const StatusbarContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .submit-idea-button {
    ${({ theme }) =>
      theme.sizes.isMobile
        ? `
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 150px !important;
      > i {
        height: 1em !important;
      }
    `
        : ""}
  }
`;

const StyledButton = styled(Button)`
  margin-right: 0 !important;
  height: 30px;
  min-width: max-content;
`;

const IdeaStatusBar = ({
  idea,
  updateIdea,
  isSaving,
  unsaved,
  isEditing,
  setEditing,
  onSubmit,
  user,
  assessment,
  submitAssessment,
  t,
  otherUsers = {},
  websocketConnected,
  scrollToRequired,
  loading,
  assessmentIsSubmissable,
}) => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const canShowHelpModal = useMemo(() => idea?.ownerChallenge?.stage === "published" && isEditing, [idea, isEditing]);
  const otherUsersValues = useMemo(() => Object.values(otherUsers), [otherUsers]);
  const theme = useTheme();

  const StatusbarOuterContainer = useMemo(() => (theme.sizes.isMobile ? MobileContainer : Container), [theme]);

  return (
    <Sticky top={50} style={{ zIndex: 300 }}>
      <IdeaStatusBarDiv theme={theme}>
        {canShowHelpModal ? (
          <Modal
            mountNode={document.getElementById("semantic-modal-mount-node")}
            open={helpModalOpen}
            onClose={() => setHelpModalOpen(false)}
            style={{ padding: 20, backgroundColor: "#fff" }}
          >
            <IdeaChallengeHelp challengeId={idea.challenge} closeHelp={() => setHelpModalOpen(false)} />
          </Modal>
        ) : null}

        <StatusbarOuterContainer>
          <StatusbarContainer>
            <StatusbarInnerContainer $flexGrow={1}>
              {loading ? (
                <PlaceholderItem height={25} style={{ width: 150 }} />
              ) : !theme.sizes.isMobile ? (
                idea.isSubmitted ? (
                  <Popup
                    trigger={
                      <StatusLabel size="tiny" color="green">
                        Submitted
                      </StatusLabel>
                    }
                    content={`Submitted ${moment(idea.submittedAt).format("DD/MM/YYYY HH:mm")}`}
                  />
                ) : (
                  <StatusLabel size="tiny" color="yellow">
                    <Icon name="file outline" /> Draft
                  </StatusLabel>
                )
              ) : null}
              {canShowHelpModal && !theme.sizes.isMobile ? (
                <Popup
                  header={`View ${t("generic.challenge")} details`}
                  trigger={<Button size="mini" icon="eye" onClick={() => setHelpModalOpen(true)} inverted basic />}
                />
              ) : null}
              {idea.ownerChallenge ? (
                <Button
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: 250,
                  }}
                  content={`${idea.ownerChallenge?.name}`}
                  size="mini"
                  as={Link}
                  to={`/challenges/${idea.challenge}?noRedirect`}
                  inverted
                  basic
                />
              ) : null}
              {user && !theme.sizes.isMobile && <IdeaFollowButton idea={idea} />}
            </StatusbarInnerContainer>

            <StatusbarInnerContainer $flexGrow={0}>
              {isEditing && !websocketConnected && !theme.sizes.isMobile ? (
                <Popup
                  header="Connection issues"
                  content={`Collaboration features are disabled. Your ${t("generic.ideas")} will still be saved automatically.`}
                  trigger={<Icon color="yellow" size="large" name="warning sign" />}
                />
              ) : null}

              {!theme.sizes.isMobile ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {otherUsersValues.length ? (
                    <Popup
                      header="Collaborators online"
                      content={otherUsersValues.map((u) => u.profile?.fullName).join(", ")}
                      trigger={
                        <div>
                          {otherUsersValues.map((u, index) => (
                            <ImageWithFallback
                              key={u._id}
                              avatar
                              src={util.avatarUrl(u)}
                              fallbackSrc={util.avatarUrl()}
                              style={{ marginLeft: index === 0 ? 0 : -8 }}
                            />
                          ))}
                        </div>
                      }
                    />
                  ) : null}
                </div>
              ) : null}

              {loading ? (
                <PlaceholderItem height={25} style={{ width: 150 }} />
              ) : (
                <>
                  {idea?.isAssessor && !idea?.ideaTemplate?.assessmentClosed && !assessment?.isSubmitted ? (
                    <>
                      <TooltipButton
                        style={{ marginLeft: 3 }}
                        size="tiny"
                        content="Submit assessment"
                        primary
                        onClick={submitAssessment}
                        loading={assessment.isSaving}
                        disabled={!assessmentIsSubmissable}
                        tooltip={
                          assessmentIsSubmissable
                            ? undefined
                            : "To submit an assessment you either have to complete all sections of the assessment or declare a conflict of interest"
                        }
                      />
                    </>
                  ) : null}

                  {(util.canEditIdea(user, idea) ||
                    util.canManageChallenge(user, idea.ownerChallenge) ||
                    util.hasPermission(user, "challenge.updateIdeaStatus", idea.challenge)) &&
                  !idea.children ? (
                    <>
                      {!idea.isSubmitted && util.canEditIdea(user, idea) && !theme.sizes.isMobile ? (
                        <StyledButton
                          size="tiny"
                          content={t(isEditing ? "ideas.header.preview" : "ideas.header.edit")}
                          onClick={() => {
                            setEditing(!isEditing);
                            if (isEditing) {
                              api.journey.record("ideas", idea._id, "previewedIdea");
                            } else {
                              api.journey.record("ideas", idea._id, "resumedEditingIdea");
                            }
                          }}
                          icon="edit"
                          secondary
                        />
                      ) : null}

                      <SubmitIdeaButton
                        user={user}
                        idea={idea}
                        updateIdea={updateIdea}
                        onSubmit={onSubmit}
                        scrollToRequired={scrollToRequired}
                        isSaving={isSaving}
                        unsaved={unsaved}
                        isStatusBar={true}
                      />
                    </>
                  ) : (
                    <UserChip inverted user={idea.owner} />
                  )}
                </>
              )}
            </StatusbarInnerContainer>
          </StatusbarContainer>
        </StatusbarOuterContainer>
      </IdeaStatusBarDiv>
    </Sticky>
  );
};

const mapDispatchToProps = (dispatch) => ({
  update: (id, challenge) => dispatch(actions.challenges.update(id, challenge)),
});
const IdeaStatusBarContainer = connect(null, mapDispatchToProps)(IdeaStatusBar);

export default withTranslation()(IdeaStatusBarContainer);

import { DragEvent, useCallback, useEffect, useRef, useState } from "react";

type FileDnDWrapperProps = {
  onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
  onDragLeave?: () => void;
  onError?: (message: string) => void;
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
  handleSelectedFiles: (e: DragEvent<HTMLDivElement>) => void;
};

export const FileDnDWrapper = (props: FileDnDWrapperProps) => {
  const {
    onDragOver = () => {},
    onDragLeave = () => {},
    children,
    containerStyle,
    disabled,
    handleSelectedFiles,
  } = props;
  const [dragItem, setDragItem] = useState(false);
  const dropRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleDragEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = useCallback(
    (e) => {
      if (disabled) return;
      handleDragEvent(e);
      setDragItem(null);
      onDragOver(e);
    },
    [setDragItem, onDragOver, disabled],
  );

  const handleDragOver = useCallback(
    (e) => {
      if (disabled) return;
      handleDragEvent(e);
      clearTimeout(timeoutRef.current);
      setDragItem(null);
      onDragOver(e);
    },
    [timeoutRef, setDragItem, onDragOver, disabled],
  );

  const handleDragLeave = useCallback(
    (e) => {
      if (disabled) return;
      handleDragEvent(e);
      timeoutRef.current = window.setTimeout(() => {
        setDragItem(null);
        onDragLeave();
      }, 150);
    },
    [timeoutRef, setDragItem, onDragLeave, disabled],
  );

  const handleDrop = useCallback(
    (e) => {
      if (disabled) return;
      handleDragEvent(e);
      handleSelectedFiles(e);
      onDragLeave();
    },
    [disabled, handleSelectedFiles, onDragLeave],
  );

  useEffect(() => {
    const dropContainer = dropRef.current;
    dropContainer.addEventListener("dragenter", handleDragEnter);
    dropContainer.addEventListener("dragleave", handleDragLeave);
    dropContainer.addEventListener("dragover", handleDragOver);
    dropContainer.addEventListener("drop", handleDrop);
    return () => {
      dropContainer.removeEventListener("dragenter", handleDragEnter);
      dropContainer.removeEventListener("dragleave", handleDragLeave);
      dropContainer.removeEventListener("dragover", handleDragOver);
      dropContainer.removeEventListener("drop", handleDrop);
    };
  }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

  useEffect(() => {
    if (dragItem) {
      onDragLeave();
    }
  }, [dragItem, onDragLeave]);

  return (
    <div ref={dropRef} style={containerStyle}>
      {children}
    </div>
  );
};

export default FileDnDWrapper;

import { useEffect } from "react";
import { useBlocker } from "react-router-dom";

type PromptEvent = { currentLocation: Location; nextLocation: Location };

type PromptProps = {
  when: boolean;
  message: string;
  shouldBlock?: (event: PromptEvent) => boolean;
};

const Prompt = ({ when, message, shouldBlock }: PromptProps) => {
  const blocker = useBlocker((event: PromptEvent) => {
    if (shouldBlock) {
      return when && shouldBlock(event);
    }
    return when;
  });

  if (blocker.state === "blocked") {
    const result = confirm(message);
    if (result) {
      blocker.proceed();
    } else {
      blocker.reset();
    }
  }

  useEffect(() => {
    if (when) {
      const listener = (e) => {
        e.preventDefault();
        e.returnValue = message;
      };
      window.addEventListener("beforeunload", listener);
      return () => {
        window.removeEventListener("beforeunload", listener);
      };
    }
  }, [when, message]);
  return null;
};

export default Prompt;

import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFact, KeyFactLinkSeeMore } from "../Styles";

const CompanyCompaniesHouse = ({ company, isPDF }) => {
  const { sources = {}, sicCodes = [], companyNumber, legalName, name } = company;

  if (
    sources.companiesHouse?.accounts ||
    sources.companiesHouse?.confirmationStatement ||
    sources.companiesHouse?.officers
  ) {
    const { accounts = {}, confirmationStatement: statement, officers: allOfficers = [] } = sources.companiesHouse;
    const { last_accounts: lastAccounts } = accounts;
    const officers = allOfficers.filter((o) => !o.resigned_on);

    return (
      <InfoBoxWidget>
        <InfoBoxWidgetTitleArea>
          <Icon name="building" />
          <h3>Companies House</h3>
          <span>Number: {companyNumber}</span>
        </InfoBoxWidgetTitleArea>
        <KeyFact title="Company name" fact={legalName || name} />
        {officers.length > 0 ? (
          <KeyFact
            title="Company directors"
            fact={`${officers
              .slice(0, 6)
              .map((officer) => `${officer.name} (${officer.country_of_residence || "UNKNOWN"})`)
              .join(", ")} ${officers.length > 6 ? ` + ${officers.length - 6} more` : ""}`}
          />
        ) : null}
        {sicCodes ? <KeyFact title="SIC Codes" fact={`${sicCodes.join(", ")}`} /> : null}
        {lastAccounts ? (
          <KeyFact title="Last accounts" fact={`${lastAccounts.type}, made up to ${lastAccounts.made_up_to}`} />
        ) : null}
        {statement ? (
          <KeyFact
            title="Confirmation status"
            fact={`Last made up to ${statement.last_made_up_to}. Next due ${statement.next_due}`}
          />
        ) : null}
        {!isPDF ? (
          <KeyFactLinkSeeMore>
            <Button
              secondary
              size="tiny"
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://find-and-update.company-information.service.gov.uk/company/${companyNumber}`}
            >
              <Icon name="eye" /> View on Companies House
            </Button>
          </KeyFactLinkSeeMore>
        ) : null}
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyCompaniesHouse;

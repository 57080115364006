import { useEffect } from "react";
import { useAppSelector } from "store";
import { OpenAPI } from "simplydo/interfaces";
import { useLocation } from "react-router-dom";

const CHATWOOT_BASE_URL = "https://support.simplydo.co.uk";

const { VITE_CHATWOOT_WEBSITE_TOKEN: CHATWOOT_TOKEN, VITE_STAGE } = import.meta.env;

const insertScript = () => {
  window.chatwootSettings = {
    hideMessageBubble: false,
    position: "right",
    locale: "en",
    type: "standard",
    baseDomain: ".simplydo.co.uk",
  };

  const BASE_URL = CHATWOOT_BASE_URL;
  const script = document.createElement("script");
  script.src = `${BASE_URL}/packs/js/sdk.js`;
  script.defer = true;
  script.async = true;

  script.onload = () => {
    if (!window.chatwootSDK) {
      return;
    }
    window.chatwootSDK.run({
      websiteToken: CHATWOOT_TOKEN,
      baseUrl: BASE_URL,
    });
  };

  document.body.appendChild(script);
};
insertScript();

type CustomChatwootWindow = Window & {
  chatwootSettings: {
    hideMessageBubble: boolean;
    position: "right" | "left";
    locale: "en";
    type: "standard";
    baseDomain: ".simplydo.co.uk";
  };

  chatwootSDK: {
    run: (config: { websiteToken: string; baseUrl: string }) => void;
  };

  $chatwoot: {
    hasLoaded: boolean;
    setUser: (
      userId: string,
      userAttributes: {
        name: string;
        avatar_url: string;
        email: string;
        identifier_hash: string;
      },
    ) => void;
    setCustomAttributes: (attributes: {
      org_name?: string;
      org_id?: string;
      stage?: string;
      viewing_page?: string;
    }) => void;
    z: () => void;
  };
};

declare const window: CustomChatwootWindow;

export const useChatwoot = () => {
  const user: OpenAPI.GET<`/users/me`>["response"] = useAppSelector((state) => state.user);

  const userId = user?._id;
  const userEmail = user?.emails?.length ? user.emails[0].address : "";
  const userName = user?.profile?.fullName;
  // @ts-ignore
  const userAvatar = user?.profile?.avatarUrl;
  const userOrgName = user?.ownerOrganisation?.name;
  const userOrgId = user?.ownerOrganisation?._id;
  const userIdentifier = user?.chatwootIdentifierHash;

  useEffect(() => {
    if (!userId) {
      return;
    }

    let didInit = false;
    const chatwootReadyListener = () => {
      if (didInit) {
        return;
      }
      didInit = true;

      window.$chatwoot.setUser(userId, {
        name: userName,
        avatar_url: userAvatar,
        email: userEmail,
        identifier_hash: userIdentifier,
      });
      window.$chatwoot.setCustomAttributes({
        org_name: userOrgName,
        org_id: userOrgId,
        stage: VITE_STAGE,
      });
    };

    if (!window.$chatwoot?.hasLoaded) {
      window.addEventListener("chatwoot:ready", chatwootReadyListener);
    } else {
      chatwootReadyListener();
    }

    return () => {
      window.removeEventListener("chatwoot:ready", chatwootReadyListener);
    };
  }, [userId, userEmail, userName, userAvatar, userOrgName, userOrgId, userIdentifier]);

  const location = useLocation();
  useEffect(() => {
    if (window.$chatwoot?.hasLoaded) {
      window.$chatwoot.setCustomAttributes({
        viewing_page: location.pathname,
      });
    }
  }, [location.pathname]);

  return null;
};

export default useChatwoot;

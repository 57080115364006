import React, { useCallback } from "react";

import Help from "./Help";
import { presets } from "./presets/presets";

import { getMentions } from "./getMentions";
import { BasicRichText } from "./BasicRichText";

class RichText extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mentions: {},
      lastQuery: "",
      lastKind: null,
      chooser: {},
    };

    this.timeout = null;
    this.onChange = this.onChange.bind(this);
  }

  getThrottledMentions(kind, query) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      getMentions(kind, query).then((result) => {
        this.setState(() => ({
          lastKind: kind,
          lastQuery: query,
          mentions: result,
        }));
      });
    }, 300);
  }

  onChange = (val, text) => {
    const { onChange, extraData } = this.props;
    if (onChange) {
      return onChange(val, text, extraData);
    }
  };

  setChooser = (kind, open, cmd, text) => this.setState(() => ({ chooser: { [kind]: { open, cmd, text } } }));

  render() {
    const {
      forType,
      forId,
      preset,
      onChange: _onChange,
      extraData: _extraData,
      fullMentionLinks,
      ...rest
    } = this.props;
    const { chooser } = this.state;
    return (
      <>
        <BasicRichText
          {...rest}
          options={presets[preset || "full"].options}
          mentions={this.state.mentions}
          onSuggest={(kind, query) => this.getThrottledMentions(kind, query)}
          extraMenuItems={presets[preset || "full"].getMenu(chooser, this.setChooser.bind(this), forType, forId)}
          HelpText={<Help />}
          onChange={this.onChange}
          settings={{
            uploadOptions: { forType, forId },
            fullMentionLinks,
          }}
        />
      </>
    );
  }
}

// Ideas are a bit poorly optimised when it comes to updates, by wrapping the handlers a bit differently for fields we save a decent amount of unnecessary updates
const RichTextField = React.memo(({ updateIdea, field, ...props }) => {
  const onChange = useCallback((val) => updateIdea(val, ["templated", field.id]), [updateIdea, field.id]);

  return (
    <RichText
      stickyMenu={true}
      stickyMenuOffset={140}
      emptyReturnValue={null}
      historyEnabled={false}
      onChange={onChange}
      {...props}
    />
  );
});
export { RichTextField };

export default RichText;

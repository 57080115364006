import actions from "actions";

const initialState = {
  urlOrganisation: null,
  mobileBannerOpen: true,
  demoingTour: "",
  hasSeenIdeaLikeInformationModalThisSession: false,
};

function organisations(state = initialState, action) {
  switch (action.type) {
    case actions.organisations.RECEIVE_URL_ORGANISATION:
      return { ...state, urlOrganisation: action.organisation };
    case actions.organisations.SET_MOBILE_BANNER_OPEN:
      return { ...state, mobileBannerOpen: action.mobileBannerOpen };
    case actions.organisations.SET_DEMOING_TOUR:
      return { ...state, demoingTour: action.tourId };
    case actions.organisations.HAS_SEEN_IDEA_LIKE_INFORMATION_MODAL_THIS_SESSION:
      return { ...state, hasSeenIdeaLikeInformationModalThisSession: action.hasSeen };
    default:
      return state;
  }
}

export default organisations;

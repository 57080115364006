export const countries = [
  {
    iso3: "AFG",
    iso2: "AF",
    name: "Afghanistan",
    numeric: 4,
  },
  {
    iso3: "ALB",
    iso2: "AL",
    name: "Albania",
    numeric: 8,
  },
  {
    iso3: "DZA",
    iso2: "DZ",
    name: "Algeria",
    numeric: 12,
  },
  {
    iso3: "ASM",
    iso2: "AS",
    name: "American Samoa",
    numeric: 16,
  },
  {
    iso3: "AGO",
    iso2: "AO",
    name: "Angola",
    numeric: 24,
  },
  {
    iso3: "ARG",
    iso2: "AR",
    name: "Argentina",
    numeric: 32,
  },
  {
    iso3: "ARM",
    iso2: "AM",
    name: "Armenia",
    numeric: 51,
  },
  {
    iso3: "AUS",
    iso2: "AU",
    name: "Australia",
    numeric: 36,
  },
  {
    iso3: "AUT",
    iso2: "AT",
    name: "Austria",
    numeric: 40,
  },
  {
    iso3: "AZE",
    iso2: "AZ",
    name: "Azerbaijan",
    numeric: 31,
  },
  {
    iso3: "BHR",
    iso2: "BH",
    name: "Bahrain",
    numeric: 48,
  },
  {
    iso3: "BGD",
    iso2: "BD",
    name: "Bangladesh",
    numeric: 50,
  },
  {
    iso3: "BRB",
    iso2: "BB",
    name: "Barbados",
    numeric: 52,
  },
  {
    iso3: "BLR",
    iso2: "BY",
    name: "Belarus",
    numeric: 112,
  },
  {
    iso3: "BEL",
    iso2: "BE",
    name: "Belgium",
    numeric: 56,
  },
  {
    iso3: "BEN",
    iso2: "BJ",
    name: "Benin",
    numeric: 204,
  },
  {
    iso3: "BMU",
    iso2: "BM",
    name: "Bermuda",
    numeric: 60,
  },
  {
    iso3: "BIH",
    iso2: "BA",
    name: "Bosnia and Herzegovina",
    numeric: 70,
  },
  {
    iso3: "BWA",
    iso2: "BW",
    name: "Botswana",
    numeric: 72,
  },
  {
    iso3: "BGR",
    iso2: "BG",
    name: "Bulgaria",
    numeric: 100,
  },
  {
    iso3: "BFA",
    iso2: "BF",
    name: "Burkina Faso",
    numeric: 854,
  },
  {
    iso3: "BDI",
    iso2: "BI",
    name: "Burundi",
    numeric: 108,
  },
  {
    iso3: "KHM",
    iso2: "KH",
    name: "Cambodia",
    numeric: 116,
  },
  {
    iso3: "CMR",
    iso2: "CM",
    name: "Cameroon",
    numeric: 120,
  },
  {
    iso3: "CAN",
    iso2: "CA",
    name: "Canada",
    numeric: 124,
  },
  {
    iso3: "CAF",
    iso2: "CF",
    name: "Central African Republic (the)",
    numeric: 140,
  },
  {
    iso3: "TCD",
    iso2: "TD",
    name: "Chad",
    numeric: 148,
  },
  {
    iso3: "CHL",
    iso2: "CL",
    name: "Chile",
    numeric: 152,
  },
  {
    iso3: "CHN",
    iso2: "CN",
    name: "China",
    numeric: 156,
  },
  {
    iso3: "COL",
    iso2: "CO",
    name: "Colombia",
    numeric: 170,
  },
  {
    iso3: "COD",
    iso2: "CD",
    name: "Congo (the Democratic Republic of the)",
    numeric: 180,
  },
  {
    iso3: "COG",
    iso2: "CG",
    name: "Congo (the)",
    numeric: 178,
  },
  {
    iso3: "HRV",
    iso2: "HR",
    name: "Croatia",
    numeric: 191,
  },
  {
    iso3: "CYP",
    iso2: "CY",
    name: "Cyprus",
    numeric: 196,
  },
  {
    iso3: "CZE",
    iso2: "CZ",
    name: "Czechia",
    numeric: 203,
  },
  {
    iso3: "CIV",
    iso2: "CI",
    name: "C\u00f4te d'Ivoire",
    numeric: 384,
  },
  {
    iso3: "DNK",
    iso2: "DK",
    name: "Denmark",
    numeric: 208,
  },
  {
    iso3: "DJI",
    iso2: "DJ",
    name: "Djibouti",
    numeric: 262,
  },
  {
    iso3: "DMA",
    iso2: "DM",
    name: "Dominica",
    numeric: 212,
  },
  {
    iso3: "ECU",
    iso2: "EC",
    name: "Ecuador",
    numeric: 218,
  },
  {
    iso3: "EGY",
    iso2: "EG",
    name: "Egypt",
    numeric: 818,
  },
  {
    iso3: "GNQ",
    iso2: "GQ",
    name: "Equatorial Guinea",
    numeric: 226,
  },
  {
    iso3: "ERI",
    iso2: "ER",
    name: "Eritrea",
    numeric: 232,
  },
  {
    iso3: "EST",
    iso2: "EE",
    name: "Estonia",
    numeric: 233,
  },
  {
    iso3: "ETH",
    iso2: "ET",
    name: "Ethiopia",
    numeric: 231,
  },
  {
    iso3: "FIN",
    iso2: "FI",
    name: "Finland",
    numeric: 246,
  },
  {
    iso3: "FRA",
    iso2: "FR",
    name: "France",
    numeric: 250,
  },
  {
    iso3: "GAB",
    iso2: "GA",
    name: "Gabon",
    numeric: 266,
  },
  {
    iso3: "GMB",
    iso2: "GM",
    name: "Gambia (the)",
    numeric: 270,
  },
  {
    iso3: "GEO",
    iso2: "GE",
    name: "Georgia",
    numeric: 268,
  },
  {
    iso3: "DEU",
    iso2: "DE",
    name: "Germany",
    numeric: 276,
  },
  {
    iso3: "GHA",
    iso2: "GH",
    name: "Ghana",
    numeric: 288,
  },
  {
    iso3: "GRC",
    iso2: "GR",
    name: "Greece",
    numeric: 300,
  },
  {
    iso3: "GUM",
    iso2: "GU",
    name: "Guam",
    numeric: 316,
  },
  {
    iso3: "GIN",
    iso2: "GN",
    name: "Guinea",
    numeric: 324,
  },
  {
    iso3: "GNB",
    iso2: "GW",
    name: "Guinea-Bissau",
    numeric: 624,
  },
  {
    iso3: "HKG",
    iso2: "HK",
    name: "Hong Kong",
    numeric: 344,
  },
  {
    iso3: "HUN",
    iso2: "HU",
    name: "Hungary",
    numeric: 348,
  },
  {
    iso3: "ISL",
    iso2: "IS",
    name: "Iceland",
    numeric: 352,
  },
  {
    iso3: "IND",
    iso2: "IN",
    name: "India",
    numeric: 356,
  },
  {
    iso3: "IDN",
    iso2: "ID",
    name: "Indonesia",
    numeric: 360,
  },
  {
    iso3: "IRN",
    iso2: "IR",
    name: "Iran (Islamic Republic of)",
    numeric: 364,
  },
  {
    iso3: "IRQ",
    iso2: "IQ",
    name: "Iraq",
    numeric: 368,
  },
  {
    iso3: "IRL",
    iso2: "IE",
    name: "Ireland",
    numeric: 372,
  },
  {
    iso3: "IMN",
    iso2: "IM",
    name: "Isle of Man",
    numeric: 833,
  },
  {
    iso3: "JPN",
    iso2: "JP",
    name: "Japan",
    numeric: 392,
  },
  {
    iso3: "JOR",
    iso2: "JO",
    name: "Jordan",
    numeric: 400,
  },
  {
    iso3: "KAZ",
    iso2: "KZ",
    name: "Kazakhstan",
    numeric: 398,
  },
  {
    iso3: "KEN",
    iso2: "KE",
    name: "Kenya",
    numeric: 404,
  },
  {
    iso3: "PRK",
    iso2: "KP",
    name: "Korea (the Democratic People's Republic of)",
    numeric: 408,
  },
  {
    iso3: "KOR",
    iso2: "KR",
    name: "Korea (the Republic of)",
    numeric: 410,
  },
  {
    iso3: "KWT",
    iso2: "KW",
    name: "Kuwait",
    numeric: 414,
  },
  {
    iso3: "KGZ",
    iso2: "KG",
    name: "Kyrgyzstan",
    numeric: 417,
  },
  {
    iso3: "LAO",
    iso2: "LA",
    name: "Lao People's Democratic Republic (the)",
    numeric: 418,
  },
  {
    iso3: "LVA",
    iso2: "LV",
    name: "Latvia",
    numeric: 428,
  },
  {
    iso3: "LBN",
    iso2: "LB",
    name: "Lebanon",
    numeric: 422,
  },
  {
    iso3: "LSO",
    iso2: "LS",
    name: "Lesotho",
    numeric: 426,
  },
  {
    iso3: "LBR",
    iso2: "LR",
    name: "Liberia",
    numeric: 430,
  },
  {
    iso3: "LBY",
    iso2: "LY",
    name: "Libya",
    numeric: 434,
  },
  {
    iso3: "LIE",
    iso2: "LI",
    name: "Liechtenstein",
    numeric: 438,
  },
  {
    iso3: "LTU",
    iso2: "LT",
    name: "Lithuania",
    numeric: 440,
  },
  {
    iso3: "LUX",
    iso2: "LU",
    name: "Luxembourg",
    numeric: 442,
  },
  {
    iso3: "MDG",
    iso2: "MG",
    name: "Madagascar",
    numeric: 450,
  },
  {
    iso3: "MWI",
    iso2: "MW",
    name: "Malawi",
    numeric: 454,
  },
  {
    iso3: "MYS",
    iso2: "MY",
    name: "Malaysia",
    numeric: 458,
  },
  {
    iso3: "MLI",
    iso2: "ML",
    name: "Mali",
    numeric: 466,
  },
  {
    iso3: "MLT",
    iso2: "MT",
    name: "Malta",
    numeric: 470,
  },
  {
    iso3: "MHL",
    iso2: "MH",
    name: "Marshall Islands (the)",
    numeric: 584,
  },
  {
    iso3: "MRT",
    iso2: "MR",
    name: "Mauritania",
    numeric: 478,
  },
  {
    iso3: "MUS",
    iso2: "MU",
    name: "Mauritius",
    numeric: 480,
  },
  {
    iso3: "MYT",
    iso2: "YT",
    name: "Mayotte",
    numeric: 175,
  },
  {
    iso3: "MEX",
    iso2: "MX",
    name: "Mexico",
    numeric: 484,
  },
  {
    iso3: "FSM",
    iso2: "FM",
    name: "Micronesia (Federated States of)",
    numeric: 583,
  },
  {
    iso3: "MDA",
    iso2: "MD",
    name: "Moldova (the Republic of)",
    numeric: 498,
  },
  {
    iso3: "MNE",
    iso2: "ME",
    name: "Montenegro",
    numeric: 499,
  },
  {
    iso3: "MAR",
    iso2: "MA",
    name: "Morocco",
    numeric: 504,
  },
  {
    iso3: "MOZ",
    iso2: "MZ",
    name: "Mozambique",
    numeric: 508,
  },
  {
    iso3: "MMR",
    iso2: "MM",
    name: "Myanmar",
    numeric: 104,
  },
  {
    iso3: "NAM",
    iso2: "NA",
    name: "Namibia",
    numeric: 516,
  },
  {
    iso3: "NPL",
    iso2: "NP",
    name: "Nepal",
    numeric: 524,
  },
  {
    iso3: "NLD",
    iso2: "NL",
    name: "Netherlands (the)",
    numeric: 528,
  },
  {
    iso3: "NZL",
    iso2: "NZ",
    name: "New Zealand",
    numeric: 554,
  },
  {
    iso3: "NER",
    iso2: "NE",
    name: "Niger (the)",
    numeric: 562,
  },
  {
    iso3: "NGA",
    iso2: "NG",
    name: "Nigeria",
    numeric: 566,
  },
  {
    iso3: "MNP",
    iso2: "MP",
    name: "Northern Mariana Islands (the)",
    numeric: 580,
  },
  {
    iso3: "NOR",
    iso2: "NO",
    name: "Norway",
    numeric: 578,
  },
  {
    iso3: "OMN",
    iso2: "OM",
    name: "Oman",
    numeric: 512,
  },
  {
    iso3: "PAK",
    iso2: "PK",
    name: "Pakistan",
    numeric: 586,
  },
  {
    iso3: "PLW",
    iso2: "PW",
    name: "Palau",
    numeric: 585,
  },
  {
    iso3: "PSE",
    iso2: "PS",
    name: "Palestine, State of",
    numeric: 275,
  },
  {
    iso3: "POL",
    iso2: "PL",
    name: "Poland",
    numeric: 616,
  },
  {
    iso3: "PRT",
    iso2: "PT",
    name: "Portugal",
    numeric: 620,
  },
  {
    iso3: "PRI",
    iso2: "PR",
    name: "Puerto Rico",
    numeric: 630,
  },
  {
    iso3: "QAT",
    iso2: "QA",
    name: "Qatar",
    numeric: 634,
  },
  {
    iso3: "MKD",
    iso2: "MK",
    name: "Republic of North Macedonia",
    numeric: 807,
  },
  {
    iso3: "ROU",
    iso2: "RO",
    name: "Romania",
    numeric: 642,
  },
  {
    iso3: "RUS",
    iso2: "RU",
    name: "Russian Federation (the)",
    numeric: 643,
  },
  {
    iso3: "RWA",
    iso2: "RW",
    name: "Rwanda",
    numeric: 646,
  },
  {
    iso3: "REU",
    iso2: "RE",
    name: "R\u00e9union",
    numeric: 638,
  },
  {
    iso3: "SHN",
    iso2: "SH",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    numeric: 654,
  },
  {
    iso3: "STP",
    iso2: "ST",
    name: "Sao Tome and Principe",
    numeric: 678,
  },
  {
    iso3: "SAU",
    iso2: "SA",
    name: "Saudi Arabia",
    numeric: 682,
  },
  {
    iso3: "SEN",
    iso2: "SN",
    name: "Senegal",
    numeric: 686,
  },
  {
    iso3: "SRB",
    iso2: "RS",
    name: "Serbia",
    numeric: 688,
  },
  {
    iso3: "SYC",
    iso2: "SC",
    name: "Seychelles",
    numeric: 690,
  },
  {
    iso3: "SLE",
    iso2: "SL",
    name: "Sierra Leone",
    numeric: 694,
  },
  {
    iso3: "SGP",
    iso2: "SG",
    name: "Singapore",
    numeric: 702,
  },
  {
    iso3: "SVK",
    iso2: "SK",
    name: "Slovakia",
    numeric: 703,
  },
  {
    iso3: "SVN",
    iso2: "SI",
    name: "Slovenia",
    numeric: 705,
  },
  {
    iso3: "SOM",
    iso2: "SO",
    name: "Somalia",
    numeric: 706,
  },
  {
    iso3: "SSD",
    iso2: "SS",
    name: "South Sudan",
    numeric: 728,
  },
  {
    iso3: "ESP",
    iso2: "ES",
    name: "Spain",
    numeric: 724,
  },
  {
    iso3: "LKA",
    iso2: "LK",
    name: "Sri Lanka",
    numeric: 144,
  },
  {
    iso3: "SDN",
    iso2: "SD",
    name: "Sudan (the)",
    numeric: 729,
  },
  {
    iso3: "SWE",
    iso2: "SE",
    name: "Sweden",
    numeric: 752,
  },
  {
    iso3: "CHE",
    iso2: "CH",
    name: "Switzerland",
    numeric: 756,
  },
  {
    iso3: "TWN",
    iso2: "TW",
    name: "Taiwan (Province of China)",
    numeric: 158,
  },
  {
    iso3: "TJK",
    iso2: "TJ",
    name: "Tajikistan",
    numeric: 762,
  },
  {
    iso3: "TZA",
    iso2: "TZ",
    name: "Tanzania, United Republic of",
    numeric: 834,
  },
  {
    iso3: "THA",
    iso2: "TH",
    name: "Thailand",
    numeric: 764,
  },
  {
    iso3: "TGO",
    iso2: "TG",
    name: "Togo",
    numeric: 768,
  },
  {
    iso3: "TUR",
    iso2: "TR",
    name: "Turkey",
    numeric: 792,
  },
  {
    iso3: "TKM",
    iso2: "TM",
    name: "Turkmenistan",
    numeric: 795,
  },
  {
    iso3: "UGA",
    iso2: "UG",
    name: "Uganda",
    numeric: 800,
  },
  {
    iso3: "UKR",
    iso2: "UA",
    name: "Ukraine",
    numeric: 804,
  },
  {
    iso3: "ARE",
    iso2: "AE",
    name: "United Arab Emirates (the)",
    numeric: 784,
  },
  {
    iso3: "GBR",
    iso2: "GB",
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    numeric: 826,
  },
  {
    iso3: "USA",
    iso2: "US",
    name: "United States of America (the)",
    numeric: 840,
  },
  {
    iso3: "UMI",
    iso2: "UM",
    name: "United States Minor Outlying Islands (the)",
    numeric: 581,
  },
  {
    iso3: "URY",
    iso2: "UY",
    name: "Uruguay",
    numeric: 858,
  },
  {
    iso3: "UZB",
    iso2: "UZ",
    name: "Uzbekistan",
    numeric: 860,
  },
  {
    iso3: "VEN",
    iso2: "VE",
    name: "Venezuela (Bolivarian Republic of)",
    numeric: 862,
  },
  {
    iso3: "VNM",
    iso2: "VN",
    name: "Viet Nam",
    numeric: 704,
  },
  {
    iso3: "VIR",
    iso2: "VI",
    name: "Virgin Islands (U.S.)",
    numeric: 850,
  },
  {
    iso3: "ESH",
    iso2: "EH",
    name: "Western Sahara",
    numeric: 732,
  },
  {
    iso3: "YEM",
    iso2: "YE",
    name: "Yemen",
    numeric: 887,
  },
  {
    iso3: "ZMB",
    iso2: "ZM",
    name: "Zambia",
    numeric: 894,
  },
  {
    iso3: "ZWE",
    iso2: "ZW",
    name: "Zimbabwe",
    numeric: 716,
  },
];

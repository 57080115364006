import React, { useMemo, useState, useEffect } from "react";
import { Progress, Loader } from "semantic-ui-react";
import styled from "styled-components";

// @ts-ignore
import AppIcon from "src/images/appIcon.png";
import { useTranslation } from "react-i18next";

const SuspenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  img {
    height: 75px;
    width: 75px;
  }
  span {
    display: block;
    font-weight: 600;
    opacity: 0.7;
    font-size: 1.2em;
    margin: 20px 0;
  }
`;

const NumberOfSeconds = 5;
const NumberOfMilliseconds = NumberOfSeconds * 1000;

const SuspenseLoading = () => {
  const [msCounted, setMsCounted] = useState(0);
  const percentageDone = useMemo(() => (msCounted / NumberOfMilliseconds) * 100, [msCounted]);

  const { t } = useTranslation();

  const LoadingMessages = useMemo(
    () => [
      "Setting up SimplyDo",
      `Polishing ${t("generic.challenges")}`,
      "Sourcing innovation",
      `Loading ${t("generic.ideas")}`,
    ],
    [t],
  );

  const loadingMessage = useMemo(
    () => LoadingMessages[Math.floor(Math.random() * LoadingMessages.length)],
    [LoadingMessages],
  );

  useEffect(() => {
    const countUp = setInterval(() => {
      setMsCounted((prevCount) => (prevCount >= NumberOfMilliseconds ? prevCount : prevCount + 100));
    }, 100);
    return () => clearInterval(countUp);
  }, []);

  return (
    <SuspenseContainer>
      <img src={AppIcon} alt="SimplyDo app icon" />
      <span>
        {loadingMessage}
        ..
      </span>
      {msCounted >= NumberOfMilliseconds || msCounted <= 100 ? (
        <Loader active inline="centered" />
      ) : (
        <Progress percent={percentageDone} style={{ width: 300 }} size="small" />
      )}
    </SuspenseContainer>
  );
};

export default SuspenseLoading;

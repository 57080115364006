import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";

export default function MentionedContent({ notification, events, notificationType, mentions = [] }) {
  const mentionedInfo = mentions.find((f) => f.type === notificationType);

  const hasType = notification[mentionedInfo.id];
  if (hasType) {
    const letter = mentionedInfo.id.slice(0, 1).toUpperCase();
    const ownerName = `owner${letter}${mentionedInfo.id.slice(1)}`;
    const ownerItem = notification[ownerName];
    if (ownerItem) {
      return (
        <Trans i18nKey="notifications.mentionedComment">
          <b>{{ name: util.getEventNames(events) }}</b>
          {{ mentionedInfo: `mentioned you in the ${mentionedInfo.id}` }}
          <b>{{ mentionedName: ownerItem.name }}</b>
        </Trans>
      );
    }
  }

  return (
    <Trans i18nKey="notifications.mentionedComment">
      <b>{{ name: util.getEventNames(events) }}</b>
      {{ mentionedInfo: mentionedInfo ? mentionedInfo.message : "No message found" }}
    </Trans>
  );
}

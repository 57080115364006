import React from "react";
import { Icon } from "semantic-ui-react";
import { InfoContainer, InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFactLink } from "../Styles";
import util from "utils/utils";

const certificationMap = {
  cyberEssentials: {
    title: "Cyber Essentials",
    fact: "A self-assessment of your organisation's cyber security.",
    url: "https://www.ncsc.gov.uk/cyberessentials/overview",
  },
  cyberEssentialsPlus: {
    title: "Cyber Essentials Plus",
    fact: "Everything in Cyber Essentials, plus an independent technical audit.",
    url: "https://www.ncsc.gov.uk/cyberessentials/overview",
  },
  PAS2030: {
    title: "PAS 2030",
    fact: "A standard for energy efficiency measures in domestic buildings.",
    url: "https://www.british-assessment.co.uk/services/pas-2030",
  },
  FENSA: {
    title: "FENSA",
    fact: "A government-authorised scheme for replacement windows and doors.",
    url: "https://www.fensa.org.uk/",
  },
  ISO27001: {
    title: "ISO 27001",
    fact: "An international standard for information security management.",
    url: "https://www.iso.org/standard/27001",
  },
  livingWageEmployer: {
    title: "Living Wage Employer",
    fact: "By paying the real Living Wage, employers are voluntarily taking a stand to ensure their employees can earn a wage which is enough to live on.",
    url: "https://www.livingwage.org.uk/",
  },
  ISO9001: {
    title: "ISO 9001",
    fact: "An international standard for quality management systems.",
    url: "https://www.iso.org/iso-9001-quality-management.html",
  },
  SSIP: {
    title: "SSIP",
    fact: "Safety Schemes in Procurement (SSIP) reduce occupational health and safety assessment duplication.",
    url: "https://ssip.org.uk/",
  },
  MCS: {
    title: "MCS",
    fact: "Defines, maintains and improves quality by certifying low-carbon energy technologies and contractors.",
    url: "https://mcscertified.com/",
  },
};

const CertificationKeyFact = ({ certification }) => {
  const certificationInfo = certificationMap[certification] || {
    title: certification,
    fact: "No information available",
  };
  return (
    <KeyFactLink
      title={certificationInfo.title}
      subtitle={certificationInfo.fact}
      url={certificationInfo.url}
      imageAltContent={<Icon name="check" size="large" style={{ marginTop: 10 }} />}
      fact={certificationInfo.url ? "Learn more .." : null}
    />
  );
};

const CompanyCertifications = ({ certifications = [] }) => {
  if (certifications.length) {
    return (
      <InfoBoxWidget $sectioned>
        <InfoBoxWidgetTitleArea>
          <Icon name="certificate" />
          <h3>Standards & accreditations</h3>
          <span>{util.pluralise(certifications.length, "certification", "certifications")} found</span>
        </InfoBoxWidgetTitleArea>
        <p style={{ color: "grey" }}>
          SimplyDo has checked this business and found the following certifications mentioned on its profile. We
          recommend double-checking compliance before engaging commercially.
        </p>
        <InfoContainer $wrapped>
          {certifications.map((certification) => (
            <CertificationKeyFact certification={certification} key={certification} />
          ))}
        </InfoContainer>
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyCertifications;

import React from "react";
import { confirmable } from "react-confirm";
import { Modal, Header, Button } from "semantic-ui-react";

class Dialog extends React.Component {
  render() {
    const { show, proceed, dismiss, title, confirmation } = this.props;
    return (
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={show}
        onClose={dismiss}
        basic
        size="small"
      >
        <Header content={title} />
        <Modal.Content>
          <video controls preload="metadata" style={{ maxWidth: "100%", display: "block", margin: "10px auto" }}>
            <source src={confirmation} type="video/mp4" onError={this.onError} />
            Your browser does not support this video.
          </video>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" inverted onClick={proceed} content="Finish watching" />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default confirmable(Dialog);

import React from "react";
import { Trans } from "react-i18next";

export default function StageChangedContent({ ownerChallenge = { name: "Undefined" } }) {
  return (
    <Trans i18nKey="notifications.stageChanged">
      The challenge
      <b>{{ challenge: ownerChallenge.name }}</b> is closing soon. Make sure you submit your idea before the deadline!
    </Trans>
  );
}

import actions from "actions";

const initialState = {
  userPage: 1,
  userQuery: "",
  userSort: "",
  userSortDirection: null,
  userView: "all",
  userDepartment: "",
};

function admin(state = initialState, action) {
  switch (action.type) {
    case actions.admin.UPDATE_USER_FILTERS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export default admin;

import React from "react";
import styled from "styled-components";

type IFakeLink = {
  withBorder?: boolean;
  borderColor?: string;
  className?: string;
  style?: object;
  onClick?: () => any;
  children: React.ReactNode;
};

const StyledFakeLink = styled.span<{ $withBorder?: boolean; $borderColor?: string }>`
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColour};
  text-decoration: none;
  ${({ $withBorder, theme, $borderColor }) =>
    $withBorder ? `border-bottom: 1px dotted ${$borderColor || theme.primaryColour};` : ""}
  &:hover {
    color: ${({ theme }) => theme.primaryColour};
  }
`;

function FakeLink({ withBorder, borderColor, children, ...rest }: IFakeLink) {
  return (
    <StyledFakeLink $withBorder={withBorder} $borderColor={borderColor} {...rest}>
      {children}
    </StyledFakeLink>
  );
}

export default FakeLink;

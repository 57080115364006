export const tags = (api) => ({
  getAll(includeCounts = true, success, fail) {
    api.maybeAuthenticatedRequest("GET", `/tags?includeCounts=${includeCounts}`, null, success, fail);
  },
  getAllForEntity(forType, forId, includeCounts = true, success, fail) {
    api.maybeAuthenticatedRequest(
      "GET",
      `/tags/entity/${forType}/${forId}?includeCounts=${includeCounts}`,
      null,
      success,
      fail,
    );
  },
  create(value, success, fail) {
    api.maybeAuthenticatedRequest("POST", "/tags", { value }, success, fail);
  },
  bulkCreate(values, success, fail) {
    api.maybeAuthenticatedRequest("POST", "/tags/bulk", { values }, success, fail);
  },
  reset(values, success, fail) {
    api.maybeAuthenticatedRequest("POST", `/tags/reset`, { values }, success, fail);
  },
  search(value, success, fail) {
    api.maybeAuthenticatedRequest("GET", `/tags/search/${value}`, null, success, fail);
  },
  searchWithOptions(value, organisation, limit, exclude, success, fail) {
    api.maybeAuthenticatedRequest(
      "GET",
      `/tags/search/${value}?organisation=${organisation}&limit=${limit}&${exclude.map((e) => `exclude=${e}`).join("&")}`,
      null,
      success,
      fail,
    );
  },
  get(id, success, fail) {
    api.authenticatedRequest("GET", `/tags/${id}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/tags/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest("DELETE", `/tags/${id}`, null, success, fail);
  },
  full(tagIds, success, fail) {
    api.authenticatedRequest("GET", `/tags/full?${tagIds.map((t) => `tagId=${t}`).join("&")}`, null, success, fail);
  },
  tagDocuments(tagType, tagIds, documents, success, fail) {
    api.maybeAuthenticatedRequest("POST", `/tags/${tagType}/tag`, { tags: tagIds, documents }, success, fail);
  },
  tagSingle(tagType, tagId, objectId, success, fail) {
    this.tagDocuments(tagType, [tagId], [objectId], (updatedTags) => success(updatedTags[0]), fail);
  },
  untagDocuments(tagType, tagIds, documents, success, fail) {
    api.maybeAuthenticatedRequest("DELETE", `/tags/${tagType}/tag`, { tags: tagIds, documents }, success, fail);
  },
  untagSingle(tagType, tagId, objectId, success, fail) {
    this.untagDocuments(tagType, [tagId], [objectId], (updatedTags) => success(updatedTags[0]), fail);
  },
});

import React, { useState, useRef, useEffect } from "react";
import Pickr from "@simonwep/pickr";
import "@simonwep/pickr/dist/themes/nano.min.css";

function ColourChooser({ value, onChange }) {
  const divRef = useRef(null);
  const [picker, setPicker] = useState();

  useEffect(() => {
    if (!picker && divRef?.current) {
      let didSave = false;
      const pickr = Pickr.create({
        el: divRef.current,
        theme: "nano",
        useAsButton: false,
        default: value || "#000000",
        comparison: false,
        closeOnScroll: true,
        components: {
          preview: true,
          hue: true,
          interaction: {
            hex: true,
            rgba: true,
            input: true,
            cancel: true,
            save: true,
          },
        },
      });
      pickr.on("save", (color) => {
        onChange(color?.toHEXA()?.toString());
        didSave = true;
        pickr.hide();
      });
      pickr.on("hide", () => {
        if (!didSave) {
          pickr.setColor(value);
        }
        didSave = false;
      });
      pickr.on("cancel", () => {
        pickr.setColor(value);
        pickr.hide();
      });
      setPicker(pickr);
    }
  }, [value, onChange, picker]);

  useEffect(() => {
    if (picker) {
      // Second parameter means the update is internal and should not trigger
      // External updates to the color value should not report back as a change
      picker.setColor(value || "#000000", true);
    }
  }, [value, picker]);

  return <div ref={divRef} />;
}

export default ColourChooser;

import { OpenAPI, Other } from "simplydo/interfaces";
import { loadEmptyState } from "./CompanyProvider";

// These details allow us to associate an action with a specific state, or even a specific search
type StateParams = {
  searchId?: string;
};

export type SearchState = {
  query: string;
  sort: string;
  sortDirection: string;
  locations: string[];
  crunchbaseLocations: string[];
  crunchbaseCategories: string[];
  countries: string[];
  categories: string[];
  source: string;
  includeLocations: boolean;
  sicCodes: string[];
  companyStatus: string[];
  update?: string;
};

type WithProgress<T> = T & {
  deepDiveProgress?: {
    lastUpdateAt?: Date;
    addRunningTasks?: string[];
    removeRunningTasks?: string[];
    runningTasks: string[];
    tasksTotal: number;
    tasksCompleted: number;
  };
};

export type CompanyStateItem = {
  companies: WithProgress<OpenAPI.Schemas["Company"]>[];
  total: number;
  page: number;
  loaded: number;
  loading: boolean;
  hasTimedOut?: boolean;
  loadingMore: boolean;
  searchState: SearchState;
  reloadSearch?: boolean;
  searchId?: string; // Used for multi state arrays optionally to track which state is which
  sources?: string[]; // Used for multi state arrays optionally to track which associates with which sources
  shouldIssueSearch?: boolean; // Used for multi state arrays optionally to track when to issue a search
};

export type MultiCompanyState = CompanyStateItem[];

export type CompanyState = CompanyStateItem | MultiCompanyState;

export const loadEmptySearchState = () => ({
  query: "",
  sort: "",
  sortDirection: "",
  locations: [],
  crunchbaseLocations: [],
  crunchbaseCategories: [],
  countries: [],
  categories: [],
  source: "",
  includeLocations: true,
  sicCodes: [],
  companyStatus: [],
});

export const initStateFromSearch = (
  recordedSearch: Other.IInnovationIntelligenceRecordedSearch,
  initCompanies?: OpenAPI.Schemas["Company"][],
  prependNewState?: boolean,
) => ({
  type: "INIT_STATE_FROM_SEARCH" as const,
  payload: {
    search: recordedSearch,
    companies: initCompanies,
    prependNewState,
  },
});

export const initStatesFromSearches = (searches: Other.IInnovationIntelligenceRecordedSearch[]) => ({
  type: "INIT_STATES_FROM_SEARCHES" as const,
  payload: {
    searches,
  },
});

export const removeState = (searchId: string) => ({
  type: "REMOVE_STATE" as const,
  payload: {
    searchId,
  },
});

export const updateStateMeta = (meta: Record<string, any>) => ({
  type: "UPDATE_STATE_META" as const,
  payload: {
    meta,
  },
});

export const setPage = (page: number) => ({
  type: "SET_PAGE" as const,
  payload: {
    page,
  },
});

export const setSearchLoading = (loading: boolean) => ({
  type: "SET_SEARCH_LOADING" as const,
  payload: { loading },
});

export const setLoading = (loading: boolean) => ({
  type: "SET_LOADING" as const,
  payload: { loading },
});

export const setLoadingMore = (loading: boolean) => ({
  type: "SET_LOADING_MORE" as const,
  payload: { loading },
});

export const setSearchState = (newSearchState: Partial<SearchState>, options?: { ignoreSource?: boolean }) => ({
  type: "UPDATE_SEARCH_STATE" as const,
  payload: { searchState: newSearchState, options },
});

export const resetSearchState = () => ({
  type: "RESET_SEARCH_STATE" as const,
  payload: {},
});

export const requestCompanies = (stateParams?: StateParams) => {
  return {
    type: "REQUEST_COMPANIES" as const,
    payload: {},
    ...(stateParams ?? {}),
  };
};

export const setCompanies = (companies: OpenAPI.Schemas["Company"][], stateParams?: StateParams) => ({
  type: "SET_COMPANIES" as const,
  payload: { companies, total: companies.length },
  ...(stateParams ?? {}),
});

export const receiveCompanies = (
  companies: OpenAPI.Schemas["Company"][],
  params: Pick<CompanyStateItem, "loaded" | "total">,
  stateParams?: StateParams,
) => ({
  type: "RECEIVE_COMPANIES" as const,
  payload: {
    companies,
    params,
  },
  ...(stateParams ?? {}),
});

export const updateCompany = (company: WithProgress<Partial<OpenAPI.Schemas["Company"]>>) => ({
  type: "UPDATE_COMPANY" as const,
  payload: { company },
});

export const updateCompanies = (
  companies: WithProgress<Partial<OpenAPI.Schemas["Company"]>>[],
  params: Pick<CompanyStateItem, "loaded" | "total">,
  _stateParams?: any,
) => ({
  type: "UPDATE_COMPANIES" as const,
  payload: { companies, params },
});

export const removeCompany = (companyId: string) => ({
  type: "REMOVE_COMPANY" as const,
  payload: { companyId },
});

export const bulkRemoveCompany = (companyIds: string[]) => ({
  type: "BULK_REMOVE_COMPANY" as const,
  payload: { companyIds },
});

export const setCreatingList = (creatingList: boolean) => ({
  type: "SET_CREATING_LIST" as const,
  payload: { creatingList },
});

export type ListTypes = "user" | "shared";

export const initLists = (lists: Record<ListTypes, OpenAPI.Schemas["InnovationIntelligenceList"][]>) => ({
  type: "INIT_LISTS" as const,
  payload: { lists },
});

export const setLists = (lists: OpenAPI.Schemas["InnovationIntelligenceList"][]) => ({
  type: "SET_LISTS" as const,
  payload: { lists },
});

export const addList = (list: OpenAPI.Schemas["InnovationIntelligenceList"]) => ({
  type: "ADD_LIST" as const,
  payload: { list },
});

export const updateList = (
  list: OpenAPI.Schemas["InnovationIntelligenceList"] & {
    subscribed?: boolean;
  },
) => ({
  type: "UPDATE_LIST" as const,
  payload: { list },
});

export const removeList = (listId: string) => ({
  type: "REMOVE_LIST" as const,
  payload: { listId },
});

export const addCompanyToList = (listId: string, companyId: string) => ({
  type: "ADD_COMPANY_TO_LIST" as const,
  payload: { companyId, listId },
});

export const removeCompanyFromList = (listId: string, companyId: string) => ({
  type: "REMOVE_COMPANY_FROM_LIST" as const,
  payload: { companyId, listId },
});

export const bulkAddCompanyToList = (listId: string, companyIds: string[]) => ({
  type: "BULK_ADD_COMPANY_TO_LIST" as const,
  payload: { companyIds, listId },
});

export const bulkRemoveCompanyFromList = (listId: string, companyIds: string[]) => ({
  type: "BULK_REMOVE_COMPANY_FROM_LIST" as const,
  payload: { companyIds, listId },
});

export type RawCompanyAction =
  | typeof setLoading
  | typeof setPage
  | typeof setLoadingMore
  | typeof requestCompanies
  | typeof setCompanies
  | typeof receiveCompanies
  | typeof updateCompanies
  | typeof updateCompany
  | typeof removeCompany
  | typeof bulkRemoveCompany
  | typeof initStateFromSearch
  | typeof initStatesFromSearches
  | typeof removeState
  | typeof setSearchState
  | typeof resetSearchState
  | typeof updateStateMeta
  | typeof setSearchLoading;

export type RawListAction =
  | typeof setCreatingList
  | typeof setLists
  | typeof addList
  | typeof updateList
  | typeof removeList
  | typeof addCompanyToList
  | typeof removeCompanyFromList
  | typeof bulkAddCompanyToList
  | typeof bulkRemoveCompanyFromList;

export type CompanyAction<T> = { forState: T } & StateParams & ReturnType<RawCompanyAction>;
export type ListAction = { forList: ListTypes } & ReturnType<RawListAction>;

export type CompanyStateAction<T> = CompanyAction<T> | ListAction | ReturnType<typeof initLists>;

export type CompanyReducerState<T extends string> = {
  states: Record<T, CompanyState>;
  stateMeta: Record<T, Record<string, any>>;
  lists: Record<ListTypes, OpenAPI.Schemas["InnovationIntelligenceList"][]>;
  creatingList: boolean;
  searchState: SearchState;
  searchLoading: boolean;
};

const ifStringToArray = (item) => {
  if (typeof item === "string" && item.length && item.includes(",")) {
    return item.split(",");
  }
  return item;
};

const applyAction = <T extends string>(
  forState: string,
  stateName: string,
  action: CompanyAction<T>,
  state: CompanyState,
  stateMeta: Record<string, any>,
) => {
  const { type, payload } = action;

  // Anything only apply to the state that is active
  if (stateName !== forState) {
    return state;
  }

  const applyUpdateToProvidedState = (providedState: CompanyStateItem) => {
    switch (type) {
      case "SET_PAGE":
        return { ...providedState, page: payload.page };
      case "SET_LOADING":
        return { ...providedState, loading: payload.loading };
      case "SET_LOADING_MORE":
        return { ...providedState, loadingMore: payload.loading };
      case "REQUEST_COMPANIES":
        return { ...providedState, loading: true, shouldIssueSearch: false };
      case "SET_COMPANIES":
        return { ...providedState, loading: false, companies: payload.companies, total: payload.total };
      case "RECEIVE_COMPANIES":
        return {
          ...providedState,
          loading: false,
          companies: payload.companies,
          ...(payload.params ?? {}),
        };
      case "UPDATE_COMPANY":
        if (!providedState.companies.find((company) => company._id === payload.company._id)) {
          return providedState;
        }

        return {
          ...providedState,
          companies: providedState.companies.map((company) => {
            const updatedCompany = {
              ...company,
              ...payload.company,
              sources: {
                ...(company.sources || {}),
                ...Object.fromEntries(
                  Object.entries(payload.company.sources || {}).map(([sourceKey, source]) => [
                    sourceKey,
                    {
                      ...(company.sources?.[sourceKey] || {}),
                      ...source,
                    },
                  ]),
                ),
              },
            };
            // Uncomment this if you want to log how websocket updates affect the company providedState
            // console.log(`Updating ${company.name}`);
            // console.log(action.company, company, updatedCompany);
            // console.log('------------------');
            return updatedCompany;
          }),
        };
      case "UPDATE_COMPANIES":
        if (payload.companies.length === 0) {
          return providedState;
        }

        return {
          ...providedState,
          companies: providedState.companies.map((company) => {
            const foundCompany = payload.companies.find((c) => c._id === company._id) ?? {};
            const updatedCompany = {
              ...company,
              ...foundCompany,
              sources: {
                ...(company.sources || {}),
                ...Object.fromEntries(
                  Object.entries(foundCompany.sources || {}).map(([sourceKey, source]) => [
                    sourceKey,
                    {
                      ...(company.sources?.[sourceKey] || {}),
                      ...source,
                    },
                  ]),
                ),
              },
            };
            return updatedCompany;
          }),
          ...(payload.params ?? {}),
        };
      case "REMOVE_COMPANY":
        return {
          ...providedState,
          companies: providedState.companies.filter((company) => company._id !== payload.companyId),
          total: providedState.total - 1,
        };
      case "BULK_REMOVE_COMPANY":
        return {
          ...providedState,
          companies: providedState.companies.filter((company) => !payload.companyIds.includes(company._id)),
          total: providedState.total - payload.companyIds.length,
        };
      default:
        return providedState;
    }
  };

  // If a state index is provided, only apply the action to the state at that index
  if (Array.isArray(state)) {
    // Some actions should be applied to the entire state
    switch (type) {
      case "INIT_STATE_FROM_SEARCH": {
        const defaultLoad: CompanyStateItem = loadEmptyState("");
        const { search, companies, prependNewState } = payload;
        const { params } = search;
        Object.entries(params).forEach(([key, value]) => {
          defaultLoad.searchState[key] = ifStringToArray(value);
        });
        defaultLoad.searchId = search._id;
        defaultLoad.sources = search.sources;
        if (search.sources.length > 1) {
          defaultLoad.searchState.source = "local";
        }
        if (companies) {
          defaultLoad.companies = companies;
        }
        defaultLoad.shouldIssueSearch = true;
        // Each new state should be added in an order such that it is added AFTER the provides state index
        // So if we're currently on state 0, and we're adding a new state, it should be added at index 1
        // Unless we specifically specify to prepend the new state, in which case it should be added at index 0
        if (prependNewState) {
          return [defaultLoad, ...state];
        }
        return [...state, defaultLoad];
      }
      case "INIT_STATES_FROM_SEARCHES": {
        const { searches } = payload;

        return searches.map((search) => {
          const defaultLoad: CompanyStateItem = loadEmptyState("");
          const { params } = search;
          Object.entries(params).forEach(([key, value]) => {
            defaultLoad.searchState[key] = ifStringToArray(value);
          });
          defaultLoad.searchId = search._id;
          defaultLoad.sources = search.sources;
          if (search.sources.length > 1) {
            defaultLoad.searchState.source = "local";
          }

          defaultLoad.shouldIssueSearch = true;
          return defaultLoad;
        });
      }
      case "REMOVE_STATE": {
        const { searchId } = payload;
        return state.filter((stateItem) => stateItem.searchId !== searchId);
      }
      default:
        break;
    }

    const searchId = action.searchId || stateMeta.activeStateId;

    return state.map((stateItem) => {
      if (stateItem.searchId === searchId) {
        return applyUpdateToProvidedState(stateItem);
      }
      return stateItem;
    });
  }
  return applyUpdateToProvidedState(state as CompanyStateItem);
};

export const companyReducer = <T extends string>(
  prevState: CompanyReducerState<T>,
  action: CompanyStateAction<T>,
): CompanyReducerState<T> => {
  const { type, payload } = action;

  const { forList } = action as { forList?: ListTypes };
  switch (type) {
    case "SET_SEARCH_LOADING":
      return { ...prevState, searchLoading: payload.loading };
    case "UPDATE_SEARCH_STATE": {
      let newSearchState = { ...prevState.searchState, ...payload.searchState };

      if (payload.options?.ignoreSource !== true && prevState.searchState.source !== newSearchState.source) {
        newSearchState = loadEmptySearchState();
        newSearchState.query = payload.searchState.query || prevState.searchState.query;
        newSearchState.source = payload.searchState.source;
      }

      return {
        ...prevState,
        searchState: newSearchState,
      };
    }
    case "UPDATE_STATE_META": {
      const { forState } = action;
      return {
        ...prevState,
        stateMeta: {
          ...prevState.stateMeta,
          [forState]: {
            ...(prevState.stateMeta[forState] || {}),
            ...payload.meta,
          },
        },
      };
    }
    case "RESET_SEARCH_STATE":
      return {
        ...prevState,
        searchState: {
          ...loadEmptySearchState(),
        },
      };
    case "INIT_LISTS":
      return { ...prevState, lists: payload.lists };
    case "SET_CREATING_LIST":
      return { ...prevState, creatingList: payload.creatingList };
    case "SET_LISTS":
      return { ...prevState, lists: { ...prevState.lists, [forList]: payload.lists } };
    case "ADD_LIST":
      return {
        ...prevState,
        lists: { ...prevState.lists, [forList]: [...prevState.lists[forList], payload.list] },
        creatingList: false,
      };
    case "REMOVE_LIST":
      return {
        ...prevState,
        lists: {
          ...prevState.lists,
          [forList]: prevState.lists[forList].filter((list) => list._id !== payload.listId),
        },
      };
    case "UPDATE_LIST":
      return {
        ...prevState,
        lists: {
          ...prevState.lists,
          [forList]: prevState.lists[forList].map((list) => {
            if (list._id === payload.list._id) {
              return payload.list;
            }
            return list;
          }),
        },
      };
    case "ADD_COMPANY_TO_LIST":
      return {
        ...prevState,
        lists: {
          ...prevState.lists,
          [forList]: prevState.lists[forList].map((list) => {
            if (list._id === payload.listId) {
              return {
                ...list,
                companies: [...list.companies, payload.companyId],
              };
            }
            return list;
          }),
        },
      };
    case "REMOVE_COMPANY_FROM_LIST":
      return {
        ...prevState,
        lists: {
          ...prevState.lists,
          [forList]: prevState.lists[forList].map((list) => {
            if (list._id === payload.listId) {
              return {
                ...list,
                companies: list.companies.filter((companyId) => companyId !== payload.companyId),
              };
            }
            return list;
          }),
        },
      };
    case "BULK_ADD_COMPANY_TO_LIST":
      return {
        ...prevState,
        lists: {
          ...prevState.lists,
          [forList]: prevState.lists[forList].map((list) => {
            if (list._id === payload.listId) {
              return {
                ...list,
                companies: [...list.companies, ...payload.companyIds],
              };
            }
            return list;
          }),
        },
      };
    case "BULK_REMOVE_COMPANY_FROM_LIST":
      return {
        ...prevState,
        lists: {
          ...prevState.lists,
          [forList]: prevState.lists[forList].map((list) => {
            if (list._id === payload.listId) {
              return {
                ...list,
                companies: list.companies.filter((companyId) => !payload.companyIds.includes(companyId)),
              };
            }
            return list;
          }),
        },
      };
    default:
      break;
  }

  const { forState } = action;

  const newStates = Object.fromEntries(
    Object.entries<CompanyState>(prevState.states).map(([name, oldState]) => [
      name,
      applyAction(forState, name, action, oldState, prevState.stateMeta[forState]),
    ]),
  );

  return {
    ...prevState,
    states: newStates as { [key in T]: CompanyState },
  };
};

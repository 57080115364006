export const verifications = (api) => ({
  searchForCompanies(country, companyNumber, companyName, forType, forId, success, fail, authToken) {
    api.maybeAuthenticatedRequest(
      "GET",
      `/verifications/companies/search?country=${country}&companyNumber=${companyNumber}&companyName=${encodeURIComponent(companyName)}&forType=${forType}&forId=${forId}${authToken ? `&authToken=${authToken}` : ""}`,
      null,
      success,
      fail,
    );
  },
  companiesHouseSearch(regNo, success, fail) {
    api.maybeAuthenticatedRequest(
      "GET",
      `/verifications/companies/companiesHouseSearch?companyNumber=${regNo}`,
      null,
      success,
      fail,
    );
  },
  createReport(id, forType, forId, success, fail, authToken) {
    api.maybeAuthenticatedRequest(
      "POST",
      "/verifications/companies/reports",
      {
        id,
        forType,
        forId,
        authToken: authToken || null,
      },
      success,
      fail,
    );
  },
  getReports(success, fail) {
    api.authenticatedRequest("GET", "/verifications/companies/reports", null, success, fail);
  },
});

import { SearchParams } from "simplydo/core";

export const superadmin = (api) => ({
  getSuperadmins(success, fail) {
    api.authenticatedRequest("GET", "/superadmin/users", null, success, fail);
  },
  evaluateScript(data, success, fail) {
    api.authenticatedRequest("POST", "/superadmin/scripts/execute", data, success, fail);
  },
  getScript(term, success, fail) {
    api.authenticatedRequest("GET", `/superadmin/scripts?query=${term || ""}`, null, success, fail);
  },
  createScript(data, success, fail) {
    api.authenticatedRequest("POST", "/superadmin/scripts", data, success, fail);
  },
  editScript(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/superadmin/scripts/${id}`, data, success, fail);
  },
  deleteScript(id, success, fail) {
    api.authenticatedRequest("DELETE", `/superadmin/scripts/${id}`, null, success, fail);
  },
  sendEmail(data, success, fail) {
    api.authenticatedRequest("POST", "/superadmin/email", data, success, fail);
  },
  getApiQuota(success, fail) {
    api.authenticatedRequest("GET", "/superadmin/api", null, success, fail);
  },
  forceCrash(data, success, fail) {
    api.authenticatedRequest("POST", "/superadmin/developers/crash", data, success, fail);
  },
  getGlobalSettings(success, fail) {
    api.authenticatedRequest("GET", "/superadmin/settings", null, success, fail);
  },
  updateGlobalSettings(data, success, fail) {
    api.authenticatedRequest("POST", "/superadmin/settings", data, success, fail);
  },
  getPowerbiConnection(success, fail) {
    api.authenticatedRequest("GET", "/superadmin/powerbi", null, success, fail);
  },
  deletePowerbiConnection(success, fail) {
    api.authenticatedRequest("DELETE", "/superadmin/powerbi", null, success, fail);
  },
  getPowerbiWorkspaces(success, fail) {
    api.authenticatedRequest("GET", "/superadmin/powerbi/workspaces", null, success, fail);
  },
  createPowerbiDataset(datasetType, workspaceId, workspaceName, success, fail) {
    api.authenticatedRequest(
      "POST",
      "/superadmin/powerbi/datasets",
      { datasetType, workspaceId, workspaceName },
      success,
      fail,
    );
  },
  syncPowerbiDataset(datasetId, success, fail) {
    api.authenticatedRequest("POST", `/superadmin/powerbi/datasets/${datasetId}`, null, success, fail);
  },
  deletePowerbiDataset(datasetId, success, fail) {
    api.authenticatedRequest("DELETE", `/superadmin/powerbi/datasets/${datasetId}`, null, success, fail);
  },
  allChallenges(params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/superadmin/challenges?${queryParams}`, null, success, fail);
  },
  allIdeas(params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/superadmin/ideas?${queryParams}`, null, success, fail);
  },
  getAnalytics(params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/superadmin/analytics?${queryParams}`, null, success, fail);
  },
});

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Loader, Button, Modal, Divider, Input, Checkbox, Tab, Icon } from "semantic-ui-react";
import styled from "styled-components";
import util from "utils/utils";
import { Other } from "simplydo/interfaces";
import RoleEditorPermissions, {
  PermissionDiv,
  PermissionDetails,
  PermissionCheckbox,
  PermissionTitle,
  PermissionMeta,
} from "./Permissions";
import RoleTable from "components/lib/Roles/RoleTable";
import toast from "react-hot-toast";
import api from "api";

import RoleEditorAssignment from "./Assignment";
import RoleEditorSettings from "./Settings";
import { useTranslation } from "react-i18next";

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
  margin-bottom: 10px;
  gap: 10px;
`;

type RoleEditorProps = {
  forType: "organisation" | "group" | "global" | "ideaBusinessProfile" | "challenge";
  permissionOptions: { key: string; text: string; title: string }[];
  forId: string;
  canCreateNewRoles?: boolean;
  canSetRolesAsDefault?: boolean;
};

const RoleEditor = ({
  forType,
  forId,
  permissionOptions,
  canCreateNewRoles,
  canSetRolesAsDefault,
}: RoleEditorProps) => {
  // Updating roles state
  const [roles, setRoles] = useState<Other.IRole[]>([]);
  const [managingPermissionsForRoleModalId, setManagingPermissionsForRoleModalId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [deletingRole, setDeletingRole] = useState<string>("");
  const { t } = useTranslation();

  const [roleNameSearch, setRoleNameSearch] = useState("");
  const [rolePermissionSearch, setRolePermissionSearch] = useState("");

  const filteredRoles = useMemo(() => {
    const nameSearch = roleNameSearch.toLowerCase();
    const permissionSearch = rolePermissionSearch.toLowerCase();

    if (!nameSearch && !permissionSearch) {
      return roles;
    }

    return roles.filter((role) => {
      const nameMatch = role.name.toLowerCase().includes(nameSearch);
      if (nameSearch && !nameMatch) {
        return false;
      }
      if (!permissionSearch) {
        return true;
      }
      for (const permission of role.permissions) {
        if (permission.toLowerCase().includes(permissionSearch)) {
          return nameMatch;
        }

        const option = permissionOptions.find((perm) => perm.key === permission);
        if (!option) {
          return false;
        }
        if (t(option.title).toLowerCase().includes(permissionSearch)) {
          return nameMatch;
        }
      }

      return false;
    });
  }, [roleNameSearch, permissionOptions, rolePermissionSearch, roles, t]);

  // Creating new role state
  const [roleCreationModalOpen, setRoleCreationModalOpen] = useState<boolean>(false);
  const [creatingRole, setCreatingRole] = useState<boolean>(false);
  const [newRoleName, setNewRoleName] = useState<string>("");
  const [newRolePermissions, setNewRolePermissions] = useState<string[]>([]);

  const usingForId = forType === "global" ? "global" : forId;
  const managingRoleModalRole = useMemo(
    () => roles.find((role) => role._id === managingPermissionsForRoleModalId),
    [managingPermissionsForRoleModalId, roles],
  );

  const getRoles = useCallback(() => {
    setLoading(true);
    api.roles.get(
      forType,
      usingForId,
      {},
      (data) => {
        setRoles(data.roles);
        setLoading(false);
      },
      (err) => {
        toast.error(err.message);
        setLoading(false);
      },
    );
  }, [usingForId, forType]);

  const createRole = useCallback(() => {
    setCreatingRole(true);
    api.roles.createRole(
      forType,
      usingForId,
      {
        name: newRoleName,
        permissions: newRolePermissions,
      },
      ({ role: newRole }) => {
        setCreatingRole(false);
        setRoles((prevRoles) => [newRole, ...prevRoles]);
        setNewRoleName("");
        setNewRolePermissions([]);
        setRoleCreationModalOpen(false);
      },
      (err) => {
        toast.error(err.message);
        setCreatingRole(false);
      },
    );
  }, [forType, newRoleName, newRolePermissions, usingForId]);

  const deleteRole = useCallback(
    (roleId: string) => {
      util
        .confirm(
          "Delete role",
          `Are you sure you want to delete the role "${roles.find((role) => role._id === roleId).name}"? This is permanent and cannot be undone. All users assigned to this role will have the role revoked and the role will be deleted.`,
        )
        .then(() => {
          setDeletingRole(roleId);
          api.roles.deleteRole(
            forType,
            usingForId,
            roleId,
            () => {
              setManagingPermissionsForRoleModalId("");
              setDeletingRole("");
              setRoles((prevRoles) => prevRoles.filter((role) => role._id !== roleId));
            },
            (err) => {
              toast.error(err.message);
              setDeletingRole("");
            },
          );
        })
        .catch(() => {});
    },
    [forType, roles, usingForId],
  );

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  return (
    <div>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={roleCreationModalOpen}
        onClose={() => setRoleCreationModalOpen(false)}
      >
        <Modal.Header>Create new role</Modal.Header>
        <Modal.Content scrolling>
          <Input placeholder="Role name" value={newRoleName} onChange={(e) => setNewRoleName(e.target.value)} fluid />
          <Divider hidden />
          {permissionOptions.map((permission) => (
            <PermissionDiv key={permission.key}>
              <PermissionCheckbox>
                <Checkbox
                  toggle
                  checked={newRolePermissions.includes(permission.key)}
                  onChange={() =>
                    setNewRolePermissions((prevPermissions) => {
                      if (prevPermissions.includes(permission.key)) {
                        return prevPermissions.filter((perm) => perm !== permission.key);
                      }
                      return [...prevPermissions, permission.key];
                    })
                  }
                />
              </PermissionCheckbox>
              <PermissionDetails>
                <PermissionTitle>{t(permission.title)}</PermissionTitle>
                <PermissionMeta>{t(permission.text)}</PermissionMeta>
              </PermissionDetails>
            </PermissionDiv>
          ))}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={() => setRoleCreationModalOpen(false)} />
          <Button
            content="Create role"
            icon="plus"
            primary
            onClick={createRole}
            loading={creatingRole}
            disabled={!newRoleName}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={!!managingPermissionsForRoleModalId}
        onClose={() => setManagingPermissionsForRoleModalId("")}
      >
        <Modal.Header>
          Managing role "{managingRoleModalRole?.name}"{" "}
          {managingRoleModalRole?.icon?.name ? (
            <Icon name={managingRoleModalRole.icon.name} color={managingRoleModalRole.icon.colour} />
          ) : null}
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Tab
            menu={{ secondary: true, pointing: true }}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
            panes={[
              {
                menuItem: "Settings",
                render: () => (
                  <RoleEditorSettings
                    canSetRolesAsDefault={canSetRolesAsDefault}
                    forType={forType}
                    forId={usingForId}
                    usingRole={managingRoleModalRole}
                    setRoles={setRoles}
                    roleId={managingPermissionsForRoleModalId}
                    roles={roles}
                  />
                ),
              },
              {
                menuItem: "Permissions",
                render: () => (
                  <RoleEditorPermissions
                    forType={forType}
                    forId={usingForId}
                    permissionOptions={permissionOptions}
                    usingRole={managingRoleModalRole}
                    setRoles={setRoles}
                  />
                ),
              },
              {
                menuItem: "Assignment",
                render: () => (
                  <RoleEditorAssignment
                    forType={forType}
                    forId={usingForId}
                    usingRole={managingRoleModalRole}
                    setRoles={setRoles}
                  />
                ),
              },
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          {!managingRoleModalRole?.preventUserDeletion ? (
            <Button
              content="Delete role"
              icon="trash"
              basic
              onClick={() => deleteRole(managingPermissionsForRoleModalId)}
              loading={deletingRole === managingPermissionsForRoleModalId}
            />
          ) : null}

          <Button content="Done" onClick={() => setManagingPermissionsForRoleModalId("")} />
        </Modal.Actions>
      </Modal>

      <FilterContainer>
        {canCreateNewRoles ? (
          <Button
            content="Create new role"
            icon="plus"
            primary
            fluid
            style={{ maxWidth: 200 }}
            onClick={() => setRoleCreationModalOpen(true)}
          />
        ) : null}

        <Input
          icon="search"
          placeholder="Search by name..."
          value={roleNameSearch}
          onChange={(e) => setRoleNameSearch(e.target.value)}
        />
        <Input
          icon="search"
          placeholder="Search by permission..."
          value={rolePermissionSearch}
          onChange={(e) => setRolePermissionSearch(e.target.value)}
        />
      </FilterContainer>

      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <RoleTable
          roles={filteredRoles}
          permissionOptions={permissionOptions}
          getRoleActions={(role) => (
            <Button content="Manage" icon="cogs" onClick={() => setManagingPermissionsForRoleModalId(role._id)} />
          )}
        />
      )}
    </div>
  );
};

export default RoleEditor;

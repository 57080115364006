import React, { useState, useEffect, useCallback } from "react";
import { useDidMount } from "utils/useDidMount";
import { Loader, Message, Button, Modal } from "semantic-ui-react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { HomeIdeaCard } from "components/home/Ideas";
import util from "utils/utils";
import api from "api";
import styled from "styled-components";

const UserIdeasContainer = styled.div`
  display: flex;
  ${({ theme }) =>
    !theme.sizes.isMobile &&
    `
    justify-content: center;
    align-items: center;
  `};
`;

const UserIdeasBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const NewIdeaButton = styled(Button)`
  margin: 10px;
`;

const NewIdea = ({ user, redirectToLogin }) => {
  const [failed, setFailed] = useState(false);
  const [creatingIdea, setCreatingIdea] = useState(false);
  const [createdIdea, setCreatedIdea] = useState(false);
  const [userIdeas, setUserIdeas] = useState([]);
  const [challenge, setChallenge] = useState({});
  const [userIdeasModalOpen, setUserIdeasModalOpen] = useState(false);
  const hasIdea = userIdeas?.length > 0;
  const { t } = useTranslation();

  const params = useParams();
  const challengeId = params.id;
  const navigate = useNavigate();

  const createIdea = useCallback(
    (currentChallenge) => {
      window.scrollTo(0, 0);
      util.newIdea(
        currentChallenge,
        user,
        (idea) => {
          setCreatingIdea(false);
          setCreatedIdea(true);
          navigate(`/ideas/${idea._id}`);
        },
        () => setCreatingIdea(false),
      );
    },
    [user, navigate],
  );

  useDidMount(() => {
    // Repeatable function to create an idea
    setCreatingIdea(true);
    api.challenges.get(
      challengeId,
      (newChallenge) => {
        setChallenge(newChallenge);
        const canMakeIdea = util.canCreateIdea(user, newChallenge);
        // If someone is logged in and can make an idea, we check if they have ideas for this challenge, and could continue you
        if (user && canMakeIdea) {
          api.challenges.getIdeas(challengeId, { scope: "me" }, ({ ideas }) => {
            setUserIdeas(ideas);
            // If they have ideas, we stop for now and let them select
            if (ideas?.length > 0) {
              setCreatedIdea(false);
              setCreatingIdea(false);
            } else {
              // If they don't have ideas, we make one
              createIdea(newChallenge);
            }
          });
        } else if (canMakeIdea) {
          // If there's not a user, but they can still make an idea (because of public idea creation) we do that
          if (!createdIdea && !creatingIdea) createIdea(newChallenge);
        } else if (!user) {
          // If there is no user, we get them to auth, then return
          setFailed(true);
          window.scrollTo(0, 0);
          redirectToLogin(null, newChallenge?.ownerOrganisation?.code);
        } else {
          // If they can't make an idea, we show an error
          setFailed(true);
          window.scrollTo(0, 0);
        }
      },
      () => setFailed(true),
    );
  }, [challengeId, user, createdIdea, creatingIdea, createIdea]);

  useEffect(() => {
    if (hasIdea) setUserIdeasModalOpen(true);
  }, [hasIdea]);

  return (
    <div>
      {failed ? (
        <Message warning>
          <h3>We were unable to create a new {t("generic.idea")} for you</h3>
          <p>
            The settings for this {t("generic.challenge")} may be preventing you from creating new {t("generic.ideas")}.
            For support with this please get in touch with the owner of the {t("generic.challenge")}.
          </p>
        </Message>
      ) : null}
      {creatingIdea && !failed ? <Loader active /> : null}
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        size="tiny"
        open={userIdeasModalOpen}
        onClose={() => setUserIdeasModalOpen(false)}
      >
        <UserIdeasContainer>
          <UserIdeasBox>
            <h3>Continue an existing idea...</h3>
            {userIdeas.map((idea, index) => (idea?._id ? <HomeIdeaCard idea={idea} index={index} /> : null))}
            <NewIdeaButton
              primary
              icon="plus"
              onClick={() => createIdea(challenge)}
              style={{ margin: "10px 15px", width: "100%" }}
            >
              Create new idea
            </NewIdeaButton>
            <Link to={`/challenges/${challenge._id}`}>Back to challenge</Link>
          </UserIdeasBox>
        </UserIdeasContainer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(NewIdea);

import React, { PureComponent } from "react";
import { Accordion, Icon } from "semantic-ui-react";

class BasicAccordion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accordionIndex: -1,
    };
  }

  handleAccordionClick = (e, titleProps) => {
    if (!titleProps) return;
    const { index } = titleProps;
    const { accordionIndex } = this.state;
    const newIndex = accordionIndex === index ? -1 : index;
    this.setState({ accordionIndex: newIndex });
  };

  render() {
    const { accordionIndex } = this.state;
    const { content, title, style } = this.props;
    return (
      <Accordion style={style}>
        <Accordion.Title active={accordionIndex === 0} index={0} onClick={this.handleAccordionClick}>
          <Icon name="dropdown" />
          {title}
        </Accordion.Title>
        <Accordion.Content active={accordionIndex === 0} index={0} onClick={this.handleAccordionClick}>
          {content}
        </Accordion.Content>
      </Accordion>
    );
  }
}

export default BasicAccordion;

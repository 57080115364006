import React, { Component } from "react";
import { Grid, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import useTheme from "theme/useTheme";
import { GiphyGif } from "components/lib/Giphy";

import FormattedComment from "components/lib/FormattedComment";
import FeedCard from "./FeedCard";

const CardLeft = ({ comment }) => {
  const hasComment = !!comment.comment;
  const hasMedia = comment.media && comment.media.length;
  const firstMediaItem = hasMedia ? comment.media[0] : {};
  const firstMediaItemIsImage = firstMediaItem.type === "gif" || firstMediaItem.type === "image";
  const firstMediaIsGif = firstMediaItem.type === "gif";
  const theme = useTheme();
  return (
    <Grid.Column stretched width={12} verticalAlign="middle" className="comment-column">
      <div className="comment-column-container">
        <Icon
          style={{ color: theme.primaryColour, marginRight: 15, fontSize: "3em" }}
          name="comment alternate"
          size="big"
        />
        {!hasComment && hasMedia && firstMediaItemIsImage ? (
          <>
            {firstMediaIsGif ? (
              <GiphyGif gif={firstMediaItem.data} size="fixed" />
            ) : (
              <img
                style={{
                  borderRadius: 5,
                  objectFit: "contain",
                  maxHeight: 150,
                }}
                alt={firstMediaItemIsImage.value}
                src={firstMediaItem.signedDownloadRequest || firstMediaItem.value}
              />
            )}
          </>
        ) : null}
        {hasComment ? (
          <p style={{ maxHeight: "100%" }}>
            <FormattedComment noLink>
              "{comment.comment}
              ""
            </FormattedComment>
          </p>
        ) : null}
      </div>
    </Grid.Column>
  );
};

class CommentCard extends Component {
  render() {
    const { comment, profileFeed, profileUser, hideAction, feedType } = this.props;
    return (
      <FeedCard
        user={comment.owner}
        action={profileFeed ? "activityFeed.comment.actionSomeone" : "activityFeed.comment.action"}
        feedType={feedType}
        subjectItem={profileFeed ? profileUser.profile.fullName : null}
        date={comment.createdAt}
        toLink={`/ideas/${comment.idea}`}
        type="COMMENT"
        cardLeft={<CardLeft comment={comment} />}
        hideAction={hideAction}
      />
    );
  }
}

export default withTranslation()(CommentCard);

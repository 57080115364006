import React, { useCallback, useState } from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import actions from "actions";
import util from "utils/utils";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import api from "api";

import AppIcon from "src/images/appIcon.png";

const AppBannerContainer = styled.div`
  background-color: #e9ebee;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  align-self: stretch;
  ${({ $padded }) =>
    $padded &&
    `
    margin: -1rem -1rem 1rem -1rem;
  `}
`;

const AppBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  // margin: 0 0 0.5rem 0;
`;

const AppInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    margin: 0;
    color: gray;
  }
`;

const AppImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 10px;
  margin-right: 0.5rem;
`;

const DownloadAppBanner = ({ mobileBannerOpen, padded }) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const userAgent = util.getMobileOperatingSystem();
  const isMobileDevice = (userAgent === "Android" || userAgent === "iOS") && !theme.sizes.isComputer;
  // https://stackoverflow.com/questions/13044805/how-to-check-if-an-app-is-installed-from-a-web-page-on-an-iphone
  const redirectToAppStore = useCallback((token = "") => {
    // The hack fix to check if the user has the app or not is not currently working on latest Android/iOS versions
    // There is no reliable way to see if they do
    // Therefore we remove it for now

    /*
    const appLink = (userAgent === 'Android') ? 'https://play.google.com/store/apps/details?id=uk.co.simplydo.mobile&hl=en_US' : 'https://apps.apple.com/gb/app/simply-do/id1445564123';
    // Checks for code execution during timeout i.e if the webpage has been open. Accounts for if user went to the app then immediately came back to the browser
    const now = new Date().valueOf();
    setTimeout(() => {
      if (new Date().valueOf() - now > 100) return;
      window.location = appLink;
    }, 50);
    */

    const code = util.code();

    window.location = `simplydo://?org=${code}${token ? `&token=${token}` : ""}`;
  }, []);

  const generateToken = useCallback(() => {
    setLoading(true);
    api.auth.generateExchangeToken(
      (data) => {
        setLoading(false);
        redirectToAppStore(data.exchangeToken);
      },
      () => {
        setLoading(false);
        redirectToAppStore();
      },
    );
  }, [redirectToAppStore]);

  if (!isMobileDevice || !mobileBannerOpen) return null;
  return (
    <AppBannerContainer $padded={padded}>
      {/*
        <CloseBanner>
          <Icon name='close' onClick={() => setMobileBannerOpen(false)} />
        </CloseBanner>
      */}
      <AppBanner>
        <AppImage src={AppIcon} />
        <AppInfo>
          <p>
            <b>Simply Do</b>
          </p>
          <p>Open the Simply Do app</p>
        </AppInfo>
        <Button
          loading={loading}
          primary
          style={{ justifySelf: "flex-end", marginLeft: "auto" }}
          onClick={generateToken}
          content="OPEN"
        />
      </AppBanner>
    </AppBannerContainer>
  );
};

const mapStateToProps = (state) => ({ mobileBannerOpen: state.organisations.mobileBannerOpen });

const mapDispatchToProps = (dispatch) => ({
  setMobileBannerOpen: (open) => dispatch(actions.organisations.setMobileBannerOpen(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAppBanner);

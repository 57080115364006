import React from "react";
import util from "utils/utils";
import { Popup, Icon } from "semantic-ui-react";
import { revenueMap, employeeCountMap } from "utils/companies";
import moment from "moment";
import { InfoContainer, InfoBox, InfoBoxWidget, KeyFact, AcquireSpan, ContactInfoWidget } from "../Styles";
import CompanyMap from "./CompanyMap";

const CompanyBasicInfo = ({ company, handleNewSearch, updateActiveTab }) => {
  const {
    // Contact info
    phone,
    email,
    website,
    twitter,
    facebook,
    linkedin,
    instagram,

    // Other
    description,
    foundedOn,
    location = {},
    patents = {},
    companyNumber,
    sources = {},
    sicCodes = [],
    ai,
    _meta,
  } = company;
  const { creditsafe = {}, crunchbase = {}, hunterioLookup = {} } = sources;
  const { acquisitions = [], revenueRange, numEmployees, fundingTotal, status, numArticles } = crunchbase;

  const { protect = {} } = creditsafe;
  const acquirerData = acquisitions.length ? acquisitions[0] : null;
  const keyFactsVisible =
    acquirerData ||
    revenueRange ||
    fundingTotal ||
    numEmployees ||
    status ||
    numArticles ||
    creditsafe?.protect ||
    companyNumber ||
    patents?.total;

  const { country, city, region, addressLine1, addressLine2, postcode } = location;
  const addressArray = [];
  if (addressLine1) addressArray.push(addressLine1);
  if (addressLine2) addressArray.push(addressLine2);
  if (postcode) addressArray.push(postcode);
  if (region) addressArray.push(region);
  if (country) addressArray.push(country);

  return (
    <InfoContainer>
      <InfoBox>
        <h1>About</h1>
        {foundedOn ? (
          <span className="meta">Established {foundedOn?.value ? `${moment(foundedOn.value).format("LL")} ` : ""}</span>
        ) : null}
        {ai?.description || description}
        <div className="boxes">
          <InfoBoxWidget $noBox>
            {phone ? (
              <ContactInfoWidget>
                <Icon name="phone" size="large" />
                <span>{phone}</span>
              </ContactInfoWidget>
            ) : null}
            {email ? (
              <ContactInfoWidget>
                <Icon name="mail" size="large" />
                <div>
                  <a href={`mailto:${email}`}>{email}</a>
                  {hunterioLookup?.emailConfidence ? (
                    <Popup
                      on="hover"
                      position="bottom center"
                      content={
                        <div>
                          This email address was found using <b>Hunter.io</b>. Hunter.io is a third party service that
                          provides email addresses for companies. The confidence score indicates how likely it is that
                          this email address is correct.
                        </div>
                      }
                      trigger={
                        <div className="contact-info-meta">
                          <span>{hunterioLookup?.emailConfidence}% confidence</span>
                          <Icon name="question circle" size="small" />
                        </div>
                      }
                    />
                  ) : null}
                </div>
              </ContactInfoWidget>
            ) : null}
            {website ? (
              <ContactInfoWidget>
                <Icon name="globe" size="large" />
                <a href={website.url} target="_blank" rel="noreferrer noopener">
                  {website.url}
                </a>
              </ContactInfoWidget>
            ) : null}
            {twitter ? (
              <ContactInfoWidget>
                <span className="icon-alt">𝕏</span>
                <a href={twitter.url} target="_blank" rel="noreferrer noopener">
                  {twitter.url}
                </a>
              </ContactInfoWidget>
            ) : null}
            {facebook ? (
              <ContactInfoWidget>
                <Icon name="facebook" size="large" />
                <a href={facebook.url} target="_blank" rel="noreferrer noopener">
                  {facebook.url}
                </a>
              </ContactInfoWidget>
            ) : null}
            {linkedin ? (
              <ContactInfoWidget>
                <Icon name="linkedin" size="large" />
                <a href={linkedin.url} target="_blank" rel="noreferrer noopener">
                  {linkedin.url}
                </a>
              </ContactInfoWidget>
            ) : null}
            {instagram ? (
              <ContactInfoWidget>
                <Icon name="instagram" size="large" />
                <a href={instagram.url} target="_blank" rel="noreferrer noopener">
                  {instagram.url}
                </a>
              </ContactInfoWidget>
            ) : null}
          </InfoBoxWidget>
        </div>
        <div className="boxes">
          <InfoBoxWidget $noBox>
            {addressLine1 || addressLine2 || postcode || region || city || country ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 16,
                }}
              >
                <CompanyMap company={company} />
                <KeyFact
                  title={
                    <span>
                      Location
                      <Popup
                        position="top left"
                        hoverable
                        trigger={
                          <span style={{ paddingLeft: 5 }}>
                            <Icon color="grey" name="question circle" />
                          </span>
                        }
                        content="The location may be outside of your original search area. This indicates that the company is operating a branch within the searched area but is headquartered elsewhere."
                        on="hover"
                      />
                    </span>
                  }
                  fact={
                    <div>
                      {addressArray.map((item) => (
                        <div key={item}>{item}</div>
                      ))}
                    </div>
                  }
                />
              </div>
            ) : null}
          </InfoBoxWidget>
        </div>
      </InfoBox>
      {keyFactsVisible && (
        <InfoBox>
          {acquirerData ? (
            <AcquireSpan $canClick={!!handleNewSearch}>
              Acquired by{" "}
              <span
                onClick={
                  handleNewSearch ? () => handleNewSearch({ query: acquirerData.acquirer_identifier.value }) : null
                }
              >
                {acquirerData.acquirer_identifier.value}
              </span>
            </AcquireSpan>
          ) : null}
          <div className="boxes" style={{ marginTop: 0 }}>
            <InfoBoxWidget $noBox>
              {revenueRange && <KeyFact title="Revenue range" fact={`Estimated ${revenueMap[revenueRange]}`} />}
              {fundingTotal && (
                <KeyFact
                  title="Total funding"
                  fact={`Estimated ${util.splitRevenueString(String(fundingTotal.value))} ${fundingTotal.currency}`}
                />
              )}
              {numEmployees && <KeyFact title="Employees" fact={`Estimated ${employeeCountMap[numEmployees]}`} />}
              {status && <KeyFact title="Status" fact={`Operating stage is ${status.toUpperCase()}`} />}
              {numArticles && <KeyFact title="Articles" fact={`${numArticles} articles reference this org`} />}
            </InfoBoxWidget>
            <InfoBoxWidget $noBox>
              {creditsafe?.protect ? (
                <KeyFact
                  title="Sanctions & alerts"
                  verified={!protect.alertsCount}
                  notVerified={!!protect.alertsCount}
                  fact={!protect.alertsCount ? "No issues found for company" : `${protect.alertsCount} issues found`}
                  underlined={!!protect.alertsCount}
                  onClick={protect.alertsCount ? () => updateActiveTab("sanctions") : null}
                />
              ) : null}
              {/*
              <KeyFact
                title='DUNS verified'
                info={`Find out if this company is verified through Dun & Bradstreet. You may need to press the 'Request additional data' button below to find out this additional information`}
                fact={duns?.duns ? duns.primaryName : 'Not yet verified'}
                verified={duns?.duns}
              />
            */}
              {companyNumber ? (
                <KeyFact
                  title="Companies House ID"
                  fact={
                    <a
                      href={`https://find-and-update.company-information.service.gov.uk/company/${companyNumber}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {companyNumber}
                    </a>
                  }
                />
              ) : null}
              {sicCodes.length > 0 ? <KeyFact title="SIC Codes" fact={sicCodes.join(", ")} /> : null}
              {patents.total && <KeyFact title="Patents" fact={`${patents.total} found for company`} />}
            </InfoBoxWidget>
          </div>
        </InfoBox>
      )}
    </InfoContainer>
  );
};

export default CompanyBasicInfo;

import React from "react";
import styled from "styled-components";
import SignaturePad from "react-signature-canvas";
import { Segment } from "semantic-ui-react";

const CanvasContainer = styled(Segment)`
  ${({ $width, $height }) => `
    width: ${$width};
    height: ${$height};
  `}
  background-color: #fff;
  padding: 0px !important;
`;

const ClearText = styled.span`
  color: gray;
  text-decoration-line: underline;
  margin: 5px 0;
  display: block;
  cursor: pointer;
`;

const SignatureCanvas = ({ canvasRef, width = "100%", height = "30px" }) => (
  <div>
    <CanvasContainer $width={width} $height={height}>
      <SignaturePad ref={canvasRef} canvasProps={{ width, height }} />
    </CanvasContainer>
    <ClearText onClick={() => canvasRef.current.clear()}>Clear signature</ClearText>
  </div>
);

export default SignatureCanvas;

import { Children, cloneElement, useState } from "react";
import { styled } from "styled-components";

const RadioCardContent = styled.div<{
  $selected: boolean;
  $editable: boolean;
}>`
  cursor: pointer;
  padding: 16px;
  border-radius: 4px;
  box-shadow:
    0 0 0 1px rgba(34, 36, 38, 0.15) inset,
    0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);

  ${({ $selected, $editable }) =>
    $selected
      ? `
    outline: 2px solid #2185d0;
  `
      : `
    ${
      !$editable &&
      `
      cursor: default;
      opacity: 0.5;
    `
    }
  `}
`;

const RadioCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

type RadioCardItemProps = {
  children: React.ReactNode;
  value: string;
  selected?: boolean;
  editable?: boolean;
  onClick?: () => void;
};

const RadioCardItem = ({ children, selected, editable, onClick }: RadioCardItemProps) => {
  return (
    <RadioCardContent $editable={editable} $selected={selected} onClick={onClick}>
      {children}
    </RadioCardContent>
  );
};

type RadioCardGroupProps = {
  children: React.ReactNode;
  defaultValue: string;
  onValueChange?: (value: string) => void;
  editable?: boolean;
};

const RadioCardGroup = ({ children, defaultValue, onValueChange, editable }: RadioCardGroupProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  return (
    <RadioCardContainer>
      {Children.map(children, (child: React.ReactElement) => {
        if (!child) {
          return null;
        }
        if (child.type !== RadioCardItem) {
          return child;
        }
        return cloneElement(child, {
          selected: child.props.selected ?? selectedValue === child.props.value,
          editable: editable,
          onClick: () => {
            if (!editable) {
              return;
            }
            setSelectedValue(child.props.value);
            onValueChange?.(child.props.value);
          },
        });
      })}
    </RadioCardContainer>
  );
};

export const RadioCard = {
  Group: RadioCardGroup,
  Item: RadioCardItem,
};

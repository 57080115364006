import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import util from "utils/utils";

import FeedCard from "./FeedCard";

const CardLeft = ({ idea }) => (
  <div style={{ maxHeight: "100%" }}>
    <div className="cover-and-text">
      <Link
        to={`/ideas/${idea._id}`}
        className="image-link"
        style={{
          display: "inline-block",
          width: 50,
          height: 50,
          borderRadius: 10,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundImage: `url(${util.mixinCssUrlFallback(util.ideaCoverImage(idea), util.ideaCoverImage())})`,
        }}
      />
      <div className="content-meta-container">
        <span className="content-meta">
          {`SUBMITTED TO ${idea.ownerChallenge}` && idea.ownerChallenge?.name.toUpperCase()}
        </span>
        <span className="content-name">
          <Link to={`/ideas/${idea._id}`}>Idea - {idea.name}</Link>
        </span>
      </div>
    </div>
  </div>
);

class ChallSubmitCard extends Component {
  render() {
    const { idea, hideAction, t, feedType } = this.props;
    return (
      <FeedCard
        feedType={feedType}
        ownerType={idea.company ? "company" : "user"}
        company={idea.ownerCompany}
        user={idea.owner}
        action={"activityFeed.idea.submitted"}
        date={idea.submittedAt}
        toLink={`/ideas/${idea._id}`}
        type={t("generic.idea")}
        cardLeft={<CardLeft idea={idea} />}
        hideAction={hideAction}
      />
    );
  }
}

export default withTranslation()(ChallSubmitCard);

import api from "api";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal, TextArea } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";

type RequestJoinModalProps = {
  idea: OpenAPI.Schemas["Idea"];
  open: boolean;
  onClose: () => void;
  onConfirm: (note: string) => void;
};

const RequestJoinModal = ({ idea, open, onClose, onConfirm }: RequestJoinModalProps) => {
  const user = useAppSelector((state) => state.user);
  const [note, setNote] = useState("");
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    api.invitations.create(
      {
        forType: "requestJoinIdea",
        forId: idea._id,
        invitee: user._id,
        note,
      },
      () => {
        toast.success(`Requested to join ${t("generic.idea")} team`);
        onClose();
        onConfirm(note);
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [user, note, idea, onClose, onConfirm, t]);

  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={open} onClose={onClose}>
      <Modal.Header>Request to join {idea.name}</Modal.Header>
      <Modal.Content>
        <p>
          Send a message to the {t("generic.idea")} team to request to join the team. You will be able to edit the{" "}
          {t("generic.idea")} once you have been accepted.
        </p>
        <p>
          Please describe why you would like to join the team, and what you can contribute to the {t("generic.idea")}{" "}
          below.
        </p>

        <Form>
          <TextArea
            value={note}
            placeholder="I would like to join the team because..."
            onChange={(e) => setNote(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button primary onClick={handleConfirm}>
          Send
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RequestJoinModal;

import React, { useCallback } from "react";
import { Input, Button, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import actions from "actions";
import api from "api";

function ForgottenPassword({ loading, setLoading, email, setEmail, t, organisation }) {
  const navigate = useNavigate();

  const sendEmail = useCallback(() => {
    setLoading(true);
    api.auth.sendPasswordResetEmail(
      email,
      organisation?._id,
      () => {
        setLoading(false);
        toast(t("entry.forgottenPassword.sent"));
        navigate("/");
      },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      },
    );
  }, [email, organisation, navigate, setLoading, t]);

  return (
    <div style={{ maxWidth: 450, display: "block", margin: "0px auto" }}>
      <h2>{t("entry.forgottenPassword.title")}</h2>
      <p>{t("entry.forgottenPassword.info")}</p>
      <Message warning>
        Please remember to check your spam or junk mail folder if you don’t receive the email in your inbox
      </Message>
      <Input
        fluid
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={t("emailPlaceholder")}
        autoFocus
      />
      <div style={{ marginTop: 30, textAlign: "right" }}>
        <Button basic onClick={() => navigate("..")} content={t("generic.cancel")} />
        <Button primary content={t("entry.forgottenPassword.send")} onClick={sendEmail} loading={loading} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { email, loading } = state.entry;
  return { email, loading, organisation: state.organisations.urlOrganisation };
};
const mapDispatchToProps = (dispatch) => ({
  setEmail: (e) => dispatch(actions.entry.updateEmail(e)),
  setLoading: (l) => dispatch(actions.entry.updateLoading(l)),
});
const ForgottenPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);

export default withTranslation()(ForgottenPasswordContainer);

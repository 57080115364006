import { useMemo, useState } from "react";
import FileChooser, { FileChooserProps } from "./FileChooser";
import { Icon, Progress } from "semantic-ui-react";
import styled from "styled-components";
import { UploadState, verifyType } from "./uploadUtils";

const StyledDropZone = styled.div`
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  > h5 {
    margin: 0;
    display: block;
    color: gray;
  }
  i.icon {
    color: gray;
  }
  u {
    cursor: pointer;
  }
`;

const DroppableFileChooserTrigger = ({
  disabled,
  accept,
  uploadState,
  draggedOverFiles,
  allowMultiple,
  style,
  onClick,
}: {
  accept?: string;
  disabled?: boolean;
  uploadState?: UploadState;
  draggedOverFiles?: FileList;
  allowMultiple?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}) => {
  const display = useMemo(() => {
    const defaultText = (
      <>
        Drop a file here to upload, or <u>click here to browse</u>.
        {allowMultiple ? " You can upload multiple files at once." : ""}
      </>
    );

    if (uploadState?.isUploading) {
      return { icon: "spinner" as const, text: defaultText };
    }
    if (draggedOverFiles) {
      if (!allowMultiple) {
        if (draggedOverFiles.length > 1) {
          return { icon: "stop circle" as const, text: "Only one file is accepted" };
        }
      }
      if (accept) {
        for (let i = 0; i < draggedOverFiles.length; i++) {
          if (!verifyType(accept, draggedOverFiles.item(i).type)) {
            return { icon: "stop circle" as const, text: "Only images are accepted" };
          }
        }
      }
      return { icon: "check circle" as const, text: "Drop the file to upload" };
    }
    return { icon: "upload" as const, text: defaultText };
  }, [allowMultiple, accept, draggedOverFiles, uploadState?.isUploading]);

  return (
    <StyledDropZone style={style} onClick={onClick}>
      <Icon size="huge" name={display.icon} />
      {uploadState?.isUploading ? (
        <Progress percent={uploadState.uploadProgress} indicating progress style={{ width: "100%" }}>
          Uploading...
        </Progress>
      ) : disabled ? (
        <h5>Cannot upload more files</h5>
      ) : (
        <h5>{display.text}</h5>
      )}
    </StyledDropZone>
  );
};

const FileChooserWithDropzone = (props: FileChooserProps) => {
  const {
    onDragOver: _onDragOver,
    onDragLeave: _onDragLeave,
    trigger: _trigger,
    popupProps = {},
    allowMultiple,
    style,
    accept,
    ...rest
  } = props;

  const [draggedOverFiles, setDraggedOverFiles] = useState(null);
  return (
    /* @ts-ignore */
    <FileChooser
      {...rest}
      accept={accept}
      allowMultiple={allowMultiple}
      onDragOver={(e) => {
        setDraggedOverFiles(e.dataTransfer.files);
      }}
      onDragLeave={() => setDraggedOverFiles(null)}
      popupProps={{
        ...popupProps,
        position: "top center",
        basic: true,
        offset: [0, -100],
      }}
      trigger={
        <DroppableFileChooserTrigger
          draggedOverFiles={draggedOverFiles}
          disabled={!!popupProps.disabled}
          allowMultiple={allowMultiple}
          accept={accept}
          style={style}
        />
      }
    />
  );
};

export default FileChooserWithDropzone;

import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import util from "utils/utils";
import api from "api";

import {
  Popup,
  Icon,
  Button,
  Dropdown,
  Table,
  Image,
  Pagination,
  Modal,
  Input,
  Message,
  Label,
} from "semantic-ui-react";
import { MultiLineParagraph } from "components/lib/UI";
import { ImageWithFallback } from "components/lib/ImageWithFallback";
import useThrottle from "utils/useThrottle";
import toast from "react-hot-toast";
import moment from "moment";

const Warn = styled.div`
  background-color: #ff1234;
  padding: 2px;
  border-radius: 50%;

  &&& img {
    margin-right: 0;
  }
`;

export const StyledHeader = styled.header`
  span {
    margin: 0;
    display: block;
    font-weight: 350;
  }
  h2 {
    font-size: 15px;
    font-weight: 600;
    color: rgb(40, 40, 40);
  }
  p {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 13px;
    color: #2a2828;
    padding: 3px;
    padding-left: 6px;
    border-left: 4px solid #ffe4b5;
    background-color: #fffad1;
    display: block;
  }
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;

  align-items: center;
  justify-content: space-between;
`;

const ClippedTextStyle = styled.div`
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  align-content: center;
  word-break: break-word;
`;

const ClippedText = ({ children }) =>
  children ? <Popup content={children} trigger={<ClippedTextStyle>{children}</ClippedTextStyle>} /> : null;

function FieldHeader({
  ideaId,
  field,
  externalChange,
  isEditing,
  isAssessing,
  style,
  updateIdea,
  importingContentEnabled,
}) {
  const { t } = useTranslation();
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [suggestionsOpen, setSuggestionOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionPage, setSuggestionPage] = useState(1);
  const [suggestionQuery, setSuggestionQuery] = useState("");

  const { title, description = "", required, type } = field;

  const paginatedSuggestions = useMemo(() => {
    return suggestions.slice((suggestionPage - 1) * 5, suggestionPage * 5);
  }, [suggestions, suggestionPage]);

  const loadSuggestions = useThrottle(
    (query) => {
      api.ideas.getCopySuggestions(
        ideaId,
        type,
        {
          query,
        },
        (res) => {
          setSuggestionOpen(true);
          setSuggestionPage(1);
          setLoadingSuggestions(false);
          setSuggestions(res.suggestions);
        },
        (err) => {
          if (err.status === 400) {
            toast("No support for importing this field type yet.");
          }
          setLoadingSuggestions(false);
        },
      );
    },
    300,
    [type, ideaId],
  );

  return (
    <StyledHeader style={style}>
      <TitleRow>
        {title && (
          <div>
            <h2 style={{ display: "inline" }}>{title}</h2>{" "}
            {required && isEditing && (
              <Popup
                size="small"
                trigger={
                  <Icon
                    name="asterisk"
                    style={{ position: "relative", top: -5, marginLeft: 3 }}
                    color="red"
                    size="tiny"
                  />
                }
                content={`This field must be filled in to submit your ${t("generic.idea")}`}
              />
            )}
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: -6,
            marginLeft: 12,
            height: 24,
          }}
        >
          {externalChange
            ? Object.values(externalChange).map((u) => (
                <Popup
                  header="Warning"
                  content={`${u.profile?.fullName} is currently editing this field, to ensure that no work is lost please wait until they are done.`}
                  key={u._id}
                  trigger={
                    <Warn>
                      <ImageWithFallback
                        avatar
                        src={util.avatarUrl(u)}
                        fallbackSrc={util.avatarUrl()}
                        data-tooltip={u.profile?.fullName}
                      />
                    </Warn>
                  }
                />
              ))
            : null}
        </div>
        {isEditing &&
        importingContentEnabled &&
        [
          "shorttext",
          "longtext",
          "richtext",
          "creditsafereport",
          "slider",
          "image",
          "multiimage",
          "file",
          "multifile",
        ].includes(field.type) ? (
          <>
            <Dropdown
              icon={null}
              pointing="top right"
              trigger={
                <Button icon="ellipsis horizontal" style={{ marginLeft: 10 }} size="mini" basic onClick={() => {}} />
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setLoadingSuggestions(true);
                    loadSuggestions();
                  }}
                >
                  <Icon name="download" />
                  Import content
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Modal open={suggestionsOpen} mountNode={document.getElementById("semantic-modal-mount-node")}>
              <Modal.Header>Import content from another {t("generic.idea")}</Modal.Header>
              <Modal.Content>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    gap: 10,
                  }}
                >
                  <h4>Choose a field to import from</h4>
                  <p>
                    Select content from a field you have previously completed in another {t("generic.idea")}. Just click
                    one of the fields to import its content. This will replace the current content in this field.
                  </p>
                  <Input
                    loading={loadingSuggestions}
                    icon="search"
                    placeholder="Search..."
                    value={suggestionQuery}
                    onChange={(e) => {
                      setLoadingSuggestions(true);
                      setSuggestionQuery(e.target.value);
                      loadSuggestions(e.target.value);
                    }}
                  />
                  {suggestions.length === 0 ? (
                    <Message info icon style={{ width: "100%" }}>
                      <Icon name="info circle" />
                      <div>
                        <h4>No content found</h4>
                        {suggestionQuery ? (
                          <p>No content matching your query could be found.</p>
                        ) : (
                          <p>You haven't created content for a type of this field before.</p>
                        )}
                      </div>
                    </Message>
                  ) : (
                    <>
                      <Table selectable verticalAlign="middle">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell collapsing></Table.HeaderCell>
                            <Table.HeaderCell>{t("common:capitalise", { key: "generic.idea" })}</Table.HeaderCell>
                            <Table.HeaderCell>{t("common:capitalise", { key: "generic.challenge" })}</Table.HeaderCell>
                            <Table.HeaderCell>Preview</Table.HeaderCell>
                            <Table.HeaderCell>Created at</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {paginatedSuggestions.map((s) => (
                            <Table.Row
                              key={s.summary}
                              onClick={() => {
                                util
                                  .confirm(
                                    `Import ${t("generic.idea")} data`,
                                    `Are you sure you want to import the content of this field? This will remove and overwrite the content in the field of the ${t("generic.idea")} you are currently editing!`,
                                  )
                                  .then(() => {
                                    api.ideas.copyData(
                                      s.idea._id,
                                      s.fieldId,
                                      ideaId,
                                      field.id,
                                      { index: s?.preview?.index },
                                      ({ content }) => {
                                        updateIdea(content, ["templated", field.id], true);
                                        setSuggestionOpen(false);
                                      },
                                      () => {
                                        toast.error("Failed to import content");
                                      },
                                    );
                                  })
                                  .catch(() => {});
                              }}
                            >
                              <Table.Cell collapsing>
                                <ImageWithFallback
                                  avatar
                                  style={{ objectFit: "cover", borderRadius: 4 }}
                                  src={util.ideaCoverImage(s.idea)}
                                  fallbackSrc={util.ideaCoverImage()}
                                />
                              </Table.Cell>
                              <Table.Cell width="3">
                                <ClippedText>{s.idea.name}</ClippedText>
                              </Table.Cell>
                              <Table.Cell width="2">
                                <ClippedText>{s.idea.ownerChallenge.name}</ClippedText>
                              </Table.Cell>
                              <Table.Cell width="7">
                                <ClippedText>
                                  {s.preview.url ? (
                                    s.preview.type === "image" ? (
                                      <Image
                                        src={s.preview.url}
                                        style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
                                      />
                                    ) : (
                                      <Label>{s.preview.name}</Label>
                                    )
                                  ) : (
                                    s.preview.summary
                                  )}
                                </ClippedText>
                              </Table.Cell>
                              <Table.Cell width="2">
                                <ClippedText>{moment(s.idea.updatedAt).format("DD/MM/YYYY")}</ClippedText>
                              </Table.Cell>
                              <Table.Cell collapsing>
                                <Button size="mini" color="secondary" icon="download" content="Select" />
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                      <Pagination
                        style={{ alignSelf: "center" }}
                        activePage={suggestionPage}
                        totalPages={Math.ceil(suggestions.length / 5)}
                        onPageChange={(e, { activePage }) => setSuggestionPage(activePage)}
                      />
                    </>
                  )}
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  onClick={() => {
                    setSuggestionOpen(false);
                  }}
                >
                  Close
                </Button>
              </Modal.Actions>
            </Modal>
          </>
        ) : null}
      </TitleRow>
      {(isAssessing || isEditing) && description.trim() && (
        <MultiLineParagraph>
          <Icon name="info circle" />
          {description}
        </MultiLineParagraph>
      )}
    </StyledHeader>
  );
}

export default FieldHeader;

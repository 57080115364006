import { ReactNode, Reducer, createContext, useCallback, useMemo, useReducer } from "react";
import {
  CompanyAction,
  CompanyReducerState,
  CompanyState,
  CompanyStateAction,
  ListTypes,
  RawCompanyAction,
  RawListAction,
  companyReducer,
  initLists as initListsAction,
  CompanyStateItem,
  loadEmptySearchState,
} from "./companyReducer";
import { CompanyClient } from "./CompanyClient";

export const defaultSearchState = {
  query: "",
  sort: "",
  sortDirection: "",
  locations: [],
  crunchbaseLocations: [],
  crunchbaseCategories: [],
  countries: [],
  categories: [],
  source: "",
  includeLocations: true,
  sicCodes: [],
  companyStatus: [],
};

export const loadEmptyState = (forState: string): CompanyStateItem => {
  const searchState = { forState, ...defaultSearchState };

  return {
    companies: [],
    page: 1,
    loaded: 0,
    total: 0,
    loading: false,
    loadingMore: false,
    searchState,
  };
};

export type InitialStateType<T extends string> = {
  states?: Partial<CompanyReducerState<T>["states"]>;
  lists?: Partial<CompanyReducerState<T>["lists"]>;
  creatingList?: boolean;
};

const CompanyProvider = <T extends string>({
  client,
  children,
  initialState: propInitialState,
}: {
  client: CompanyClient<T>;
  children: ReactNode;
  initialState?: InitialStateType<T>;
}) => {
  const initialState: CompanyReducerState<T> = useMemo(() => {
    return {
      states: {
        ...(Object.fromEntries(
          client.states.map((state) => [
            state.name,
            state.allowMultipleCompanyStates ? [] : { ...loadEmptyState(state.name) },
          ]),
        ) as { [key in T]: CompanyState }),
        ...(propInitialState?.states || {}),
      },
      stateMeta: Object.fromEntries(client.states.map((state) => [state.name, {}])) as { [key in T]: CompanyState },
      lists: {
        user: propInitialState?.lists?.user || [],
        shared: propInitialState?.lists?.shared || [],
      },
      creatingList: propInitialState?.creatingList || false,
      searchState: loadEmptySearchState(),
      searchLoading: false,
    };
  }, [client, propInitialState]);

  const [companiesState, dispatchToAll] = useReducer<Reducer<CompanyReducerState<T>, CompanyStateAction<T>>>(
    companyReducer,
    initialState,
  );

  const dispatchToState = useMemo(
    () =>
      Object.fromEntries(
        client.states.map((state) => {
          if (state.allowMultipleCompanyStates) {
            const multiDispatch = (data: CompanyAction<T>) => {
              dispatchToAll({ ...data, forState: state.name, searchId: data.searchId });
            };
            return [state.name, multiDispatch];
          }
          const singleDispatch = (data: CompanyAction<T>) => dispatchToAll({ ...data, forState: state.name });
          return [state.name, singleDispatch];
        }),
      ) as { [key in T]: (data: ReturnType<RawCompanyAction>) => void },
    [client.states],
  );

  const dispatchListAction = useCallback((forList: ListTypes, action: ReturnType<RawListAction>) => {
    dispatchToAll({ ...action, forList });
  }, []);

  const initLists = useCallback((lists) => {
    dispatchToAll(initListsAction(lists));
  }, []);

  if (!client.dispatchContext) {
    const CompanyContext = createContext({
      dispatchToState,
      dispatch: dispatchToAll,
      dispatchListAction,
      initLists,
    });
    client.dispatchContext = CompanyContext;
  }
  if (!client.stateContext) {
    const CompanyContext = createContext({
      companiesState,
    });
    client.stateContext = CompanyContext;
  }

  return (
    <client.stateContext.Provider value={{ companiesState }}>
      <client.dispatchContext.Provider
        value={{
          dispatch: dispatchToAll,
          dispatchToState,
          dispatchListAction,
          initLists,
        }}
      >
        {children}
      </client.dispatchContext.Provider>
    </client.stateContext.Provider>
  );
};

export { CompanyProvider };

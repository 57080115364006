import React from "react";
import { confirmable } from "react-confirm";
import { Modal, Header, Button } from "semantic-ui-react";

class Dialog extends React.Component {
  render() {
    const { show, proceed, dismiss, title, confirmation } = this.props;
    return (
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={show}
        onClose={dismiss}
        basic
        size="small"
        className="no-scroll"
      >
        <Header content={title} />
        <Modal.Content>{confirmation}</Modal.Content>
        <Modal.Actions>
          <Button color="green" inverted onClick={proceed} content="OK" />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default confirmable(Dialog);

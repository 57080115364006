import { ResizableExtension } from "../model/ResizableExtension";

export class VideoExtension extends ResizableExtension {
  name = "video";

  createCommands() {
    return {
      insertVideo:
        ({ src }) =>
        (param) => {
          if (!src) {
            return false;
          }
          const { srcRegex } = this.options;
          if (srcRegex && !src.match(srcRegex)) {
            return false;
          }

          this.insertCommand(param, { src });
        },
    };
  }
}

export const createVideoExtension = (options) =>
  new VideoExtension(options[0], { controls: { default: "true" }, allowfullscreen: { default: "true" } });

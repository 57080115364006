import actions from "actions";

const initialState = {
  email: "",
  password: "",
  challengeResponse: "",
  firstName: "",
  lastName: "",
  organisationSearchCode: "",
  agreedTerms: false,
  registerSection: 0,
  registering: false,
  loading: false,
  organisationSearching: false,
  showPassword: false,
  error: "",
};

function entry(state = initialState, action) {
  switch (action.type) {
    case actions.entry.CLEAR:
      return { ...initialState };
    case actions.entry.UPDATE_EMAIL:
      return { ...state, email: action.email };
    case actions.entry.UPDATE_PASSWORD:
      return { ...state, password: action.password };
    case actions.entry.UPDATE_CHALLENGE_RESPONSE:
      return { ...state, challengeResponse: action.challengeResponse };
    case actions.entry.UPDATE_FIRST_NAME:
      return { ...state, firstName: action.firstName, fullName: `${action.firstName} ${state.lastName}` };
    case actions.entry.UPDATE_LAST_NAME:
      return { ...state, lastName: action.lastName, fullName: `${state.firstName} ${action.lastName}` };
    case actions.entry.UPDATE_ORGANISATION_SEARCH_CODE:
      return { ...state, organisationSearchCode: action.code };
    case actions.entry.UPDATE_AGREED_TERMS:
      return { ...state, agreedTerms: action.agreedTerms };
    case actions.entry.UPDATE_REGISTER_SECTION:
      return { ...state, registerSection: action.registerSection };
    case actions.entry.UPDATE_REGISTERING:
      return { ...state, registering: action.registering };
    case actions.entry.UPDATE_LOADING:
      return { ...state, loading: action.loading };
    case actions.entry.UPDATE_ORGANISATION_SEARCHING:
      return { ...state, organisationSearching: action.searching };
    case actions.entry.UPDATE_SHOW_PASSWORD:
      return { ...state, showPassword: action.showPassword };
    case actions.entry.UPDATE_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}

export default entry;

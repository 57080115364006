import React from "react";
import Slider from "rc-slider";
import styled from "styled-components";
import "rc-slider/assets/index.css";

const StyledSlider = styled(Slider)`
  max-width: 80%;
  display: block;
  z-index: 0;
  margin: 20px auto;
  .rc-slider-rail {
    height: 8px;
  }
  .rc-slider-track {
    height: 8px;
  }
  .rc-slider-dot {
    display: none;
  }
  .rc-slider-mark-text {
    z-index: 5;
  }
  .rc-slider-handle {
    width: 30px;
    height: 30px;
    margin-top: -12px;
    z-index: 6;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  }
`;

function SimplyDoSlider(props) {
  return (
    <StyledSlider
      style={props.style}
      defaultValue={props.defaultValue ?? (props.min || 1)}
      value={props.value}
      min={props.min !== undefined ? props.min : 1}
      max={props.max !== undefined ? props.max : 10}
      step={props.steps || 1}
      onAfterChange={props.onAfterChange}
      onChange={props.onChange}
      marks={props.marks}
    />
  );
}

export default SimplyDoSlider;

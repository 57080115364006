import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Accordion, Message, Label, Button, Segment, Icon, Table } from "semantic-ui-react";
import toast from "react-hot-toast";
import util from "utils/utils";
import api from "api";
import moment from "moment";
import { UserChip } from "components/lib/Chips";
import { MultiLineParagraph } from "components/lib/UI";

function IdeaAssessmentReport({ user, challenge, idea, removeAssessors, onRemoveExternalInvitation, t, fetchIdeas }) {
  const [selectedAssessor, setSelectedAssessor] = useState("");
  const [invitations, setInvitations] = useState([]);

  // Associate a given assessment ID with its title
  const getAssessmentTitle = (assessmentId) => {
    const assessmentSections = challenge?.ideaTemplateData?.body.filter((section) => section.type === "assessment");
    const assessmentFields = assessmentSections.reduce((acc, section) => acc.concat(section.fields), []);
    const field = assessmentFields.find((f) => f.id === assessmentId);
    return field?.title || "Unknown field";
  };

  useEffect(() => {
    api.invitations.getForType("ideaAssessor", idea.challenge, ({ invitations: newInvitations }) => {
      setInvitations(newInvitations);
    });
  }, [idea]);

  const removeAssessor = (assessment) => {
    removeAssessors([idea._id], [assessment?.user]);
  };

  const removeInvite = (invitation) => {
    util
      .confirm(t("ideas.invitations.remove.title"), t("ideas.invitations.remove.info_assessor"))
      .then(() => {
        api.invitations.remove(
          invitation._id,
          () => {
            setInvitations(({ invitations: prevInvitations }) => ({
              invitations: [...prevInvitations.filter((i) => i._id !== invitation._id)],
            }));
            toast.success("Invite removed");
            onRemoveExternalInvitation(idea._id, invitation);
          },
          (err) => toast.error(err.message),
        );
      })
      .catch(() => {});
  };

  const unsubmitAssessment = (assessment) => {
    api.ideas.unsubmitAssessment(
      idea._id,
      assessment.user,
      () => {
        toast.success("Assessment unsubmitted");
        fetchIdeas();
      },
      (err) => toast.error(err.message),
    );
  };

  const ideaId = idea._id;
  const invited = useMemo(
    () =>
      invitations
        .filter((i) => i.forIdeas?.includes(ideaId))
        .map((i) => ({
          ...i,
          profile: {
            firstName: i.inviteeUser?.profile?.firstName || i.invitee,
            created: `Invited ${moment(i.createdAt).format("DD/MM/YY, hh:mm A")}`,
          },
        })),
    [invitations, ideaId],
  );

  return (
    <div data-testid="simplydo-idea-assessment-report" style={{ maxHeight: 400, overflow: "auto" }}>
      <h3 style={{ margin: "10px 0" }}>
        Assessment
        <Label style={{ marginLeft: 10 }} color="violet" size="large">
          {Math.round(idea.assessmentSubmittedScore || 0)}%
        </Label>
      </h3>
      <Accordion>
        {idea.assessments?.map((a) => {
          const isSelected = selectedAssessor === a.user;
          return (
            <>
              <Accordion.Title active={isSelected} onClick={() => setSelectedAssessor(isSelected ? "" : a.user)}>
                <Icon name={isSelected ? "chevron down" : "chevron right"} />
                {a?.assessorUser ? (
                  <UserChip
                    user={a.assessorUser}
                    onClick={isSelected ? null : () => setSelectedAssessor(a.assessorUser?._id)}
                  />
                ) : (
                  <span>Unknown user</span>
                )}
                {a.conflictOfInterest?.hasConflictOfInterest ? (
                  <Label size="small" color="red" style={{ marginLeft: 10, marginTop: 5 }}>
                    <Icon name="warning circle" />
                    Conflict of interest
                  </Label>
                ) : null}
                {!a.conflictOfInterest?.hasConflictOfInterest && (
                  <Label size="small" color="violet" style={{ marginLeft: 10, marginTop: 5 }}>
                    {Math.round(a.score || 0)}%
                  </Label>
                )}
                {a.isSubmitted ? (
                  <Label size="small" color="green" style={{ marginLeft: 10, marginTop: 5 }}>
                    Submitted
                  </Label>
                ) : (
                  <Label size="small" color="orange" style={{ marginLeft: 10, marginTop: 5 }}>
                    Not yet submitted
                  </Label>
                )}
              </Accordion.Title>
              <Accordion.Content active={isSelected}>
                {a.conflictOfInterest?.hasConflictOfInterest && a.conflictOfInterest?.notes ? (
                  <Message error size="tiny" header="Conflict of interest note" content={a.conflictOfInterest.notes} />
                ) : (
                  Object.entries(a.assessment || {}).map(([fieldId, fieldAssessment]) => (
                    <Segment attached key={fieldId}>
                      {fieldAssessment.score ? (
                        <h4 style={{ marginBottom: 0 }}>
                          {getAssessmentTitle(fieldId)} <Label>Score: {fieldAssessment.score || 0}</Label>
                        </h4>
                      ) : null}
                      <MultiLineParagraph>{fieldAssessment.notes || <i>No comments left</i>}</MultiLineParagraph>
                    </Segment>
                  ))
                )}
                {util.isEmpty(a.assessment) && !a.conflictOfInterest?.hasConflictOfInterest ? (
                  <Message size="tiny">This assessor has yet to begin their assessment.</Message>
                ) : null}
                {(util.canManageChallenge(user, challenge) ||
                  util.hasPermission(user, "challenge.editAssessments", idea.challenge)) &&
                removeAssessors ? (
                  <div
                    style={{
                      marginTop: 5,
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: 5,
                    }}
                  >
                    <Button
                      basic
                      size="mini"
                      style={{ marginBottom: 10 }}
                      onClick={() => removeAssessor(a)}
                      icon="trash"
                      content="Remove assessor"
                    />
                    {a.isSubmitted ? (
                      <Button
                        basic
                        size="mini"
                        style={{ marginBottom: 10, marginLeft: 5 }}
                        onClick={() => unsubmitAssessment(a)}
                        icon="ban"
                        content="Unsubmit assessment"
                      />
                    ) : null}
                  </div>
                ) : null}
              </Accordion.Content>
            </>
          );
        })}
      </Accordion>
      {invited.length > 0 && (
        <>
          <h5 style={{ marginRight: 16 }}>Pending invitations</h5>
          <Table basic="very" relaxed compact="very">
            <Table.Body>
              {invited.map((a) => (
                <Table.Row key={a._id}>
                  <Table.Cell>{a.inviteeUser?.profile?.firstName || a.invitee}</Table.Cell>
                  <Table.Cell>{a.created}</Table.Cell>
                  <Table.Cell collapsing textAlign="right">
                    {util.canManageChallenge(user, challenge) ||
                    util.hasPermission(user, "challenge.editAssessments", idea.challenge) ? (
                      <Button size="tiny" basic icon="trash" onClick={() => removeInvite(a)} />
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({ user: state.user });

export default withTranslation()(connect(mapStateToProps)(IdeaAssessmentReport));

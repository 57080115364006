import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Divider, Button, Label } from "semantic-ui-react";
import styled from "styled-components";
import moment from "moment";

import api from "api";
import RowComponent from "components/lib/RowComponent";
import InviteCard from "./InviteCard";
import { HomeInvitation } from "./InviteCard/types";
import { useAppDispatch, useAppSelector } from "store";
import actions from "actions";

const InvitationContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.boxShadow};
  h4 {
    margin-bottom: 0;
    display: block;
  }
  .ui {
    display: flex;
  }
`;

type IHomeInvitations = {
  invitations: HomeInvitation[];
  updateInvitations?: (invitations: HomeInvitation[]) => void;
  removeInvitation?: (invitation: HomeInvitation) => void;
  hideTitle?: boolean;
  showCreatedBySelf?: boolean;
  showOnlyAcceptable?: boolean;
  limit?: number;
};

const HomeInvitations = ({
  invitations,
  updateInvitations,
  removeInvitation,
  hideTitle,
  showCreatedBySelf = false,
  showOnlyAcceptable = false,
  limit,
}: IHomeInvitations) => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const clearInvite = useCallback(
    (invitation: HomeInvitation) => {
      const updatedInvitations = invitations.filter((i) => i._id !== invitation._id);
      updateInvitations && updateInvitations(updatedInvitations);
      removeInvitation && removeInvitation(invitation);
    },
    [invitations, updateInvitations, removeInvitation],
  );

  const acceptInvite = useCallback(
    (invitation: HomeInvitation, success: (i: HomeInvitation) => any, fail: (i: HomeInvitation) => any) => {
      api.invitations.accept(
        invitation._id,
        ({ permissions }) => {
          const acceptedInvitation = {
            ...invitation,
            acceptedAt: new Date().toISOString(),
            acceptedBy: user._id,
          };
          if (permissions) {
            Object.entries(permissions).forEach(([key, value]) => {
              dispatch(actions.user.updatePermissions(key, value));
            });
          }
          success(acceptedInvitation);
          clearInvite(acceptedInvitation);
        },
        () => fail(invitation),
      );
    },
    [clearInvite, user, dispatch],
  );

  const rejectInvite = useCallback(
    (invitation: HomeInvitation, success: (i: HomeInvitation) => any, fail: (i: HomeInvitation) => any) => {
      api.invitations.reject(
        invitation._id,
        () => {
          success(invitation);
          clearInvite(invitation);
        },
        () => fail(invitation),
      );
    },
    [clearInvite],
  );

  const removeInvite = useCallback(
    (invitation: HomeInvitation, success: (i: HomeInvitation) => any, fail: (i: HomeInvitation) => any) => {
      api.invitations.remove(
        invitation._id,
        () => {
          success(invitation);
          clearInvite(invitation);
        },
        () => fail(invitation),
      );
    },
    [clearInvite],
  );

  /**
   * We are filtering invitations with different flags because we render this component in multiple places in the app
   *
   * 1. The homepage
   *    - We only want to show invitations created by others (e.g. invitations/request directly to the current user) that the user can actually accept.
   *    - This is the same count we calculate to display in the notification tray since all other invitations are not really interesting for the user to interact with.
   *
   * 2. The invitations page
   *   a) Invitations interactable to the current user. Compared to the homepage this also includes invitations that can be removed (but not accepted) such as pending invitations that another admin user has created.
   *   b) Invitations created by the current user. This is useful for the current user to see what invitations they have sent.
   */
  const createdByOthers = useMemo(() => {
    return invitations.filter(
      (i) => showCreatedBySelf === i.createdByCurrentUser && (!showOnlyAcceptable || i.canAccept),
    );
  }, [invitations, showCreatedBySelf, showOnlyAcceptable]);

  const visibleInvitations = useMemo(() => {
    if (limit) return createdByOthers?.slice(0, limit);
    return createdByOthers;
  }, [createdByOthers, limit]);

  if (createdByOthers?.length === 0) return null;
  return (
    <RowComponent $noBorder>
      {hideTitle ? null : (
        <RowComponent.Fill>
          <RowComponent.Title block={undefined} linked={undefined} containerStyle={undefined}>
            Invitations & requests
          </RowComponent.Title>
          <Button as={Link} to="/invitations" primary size="mini">
            View all invitations
          </Button>
        </RowComponent.Fill>
      )}
      {createdByOthers?.length > 0 ? (
        <InvitationContainer>
          {visibleInvitations.map((invitation, index) => (
            <>
              <div style={{ position: "relative" }}>
                <Label ribbon style={{ display: "inline-block", position: "absolute", top: -23 }} size="mini">
                  {invitation.acceptedAt ? (
                    <>Accepted {moment(invitation.acceptedAt).fromNow()}</>
                  ) : (
                    <>{moment(invitation.createdAt).fromNow()}</>
                  )}
                </Label>
                <InviteCard
                  key={index}
                  invitation={invitation}
                  acceptInvite={acceptInvite}
                  rejectInvite={rejectInvite}
                  removeInvite={removeInvite}
                />
              </div>
              {index !== visibleInvitations.length - 1 && <Divider fitted />}
            </>
          ))}
          {limit && createdByOthers.length > limit ? (
            <>
              <Divider fitted />
              <RowComponent.Fill>
                <Divider hidden fitted />
                <Button basic as={Link} to="/invitations" size="mini">
                  + {createdByOthers.length - limit} more
                </Button>
              </RowComponent.Fill>
            </>
          ) : null}
        </InvitationContainer>
      ) : null}
    </RowComponent>
  );
};

export default HomeInvitations;

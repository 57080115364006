import React, { useCallback } from "react";
import { Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const JoinRequestedActions = ({ events, invitations, onAccept, onReject }) => {
  const invitationId = events[0].invitation;
  const invitationExists = invitations.find((i) => i._id === invitationId && !i.acceptedAt);

  const acceptInvite = useCallback(() => {
    api.invitations.accept(
      invitationId,
      () => {
        onAccept(invitationId);
        toast.success("Request accepted");
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [invitationId, onAccept]);

  const rejectInvite = useCallback(() => {
    api.invitations.reject(
      invitationId,
      () => {
        onReject(invitationId);
        toast.success("Request rejected");
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [invitationId, onReject]);

  if (!invitationExists) return null;
  return (
    <ButtonContainer>
      <Button icon="check" color="green" basic size="small" onClick={acceptInvite} style={{ marginBottom: 5 }} />
      <Button basic icon="trash" size="small" onClick={rejectInvite} />
    </ButtonContainer>
  );
};

export default JoinRequestedActions;

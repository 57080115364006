import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import { InfoContainer, InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFactLink } from "../Styles";

const CompanyChildOwnerships = ({ protect }) => {
  const { alertsCount = 0, results = [] } = protect || {};
  if (alertsCount) {
    return (
      <InfoBoxWidget $noBox $sectioned style={{ minHeight: 520, overflow: "auto" }}>
        <InfoBoxWidgetTitleArea>
          <Icon name="warning circle" />
          <h3>Sanctions & Alerts</h3>
          <span>{alertsCount} found</span>
        </InfoBoxWidgetTitleArea>
        <InfoContainer $wrapped $compact>
          {results.map((result) => (
            <Popup
              key={result.id}
              position="bottom right"
              on="click"
              positionFixed
              trigger={
                <div
                  key={result.id}
                  style={{
                    display: "flex",
                    flex: "0 calc(33% - 0.5rem)",
                  }}
                >
                  <KeyFactLink
                    title={result.name}
                    fact={result.comments[1]}
                    style={{
                      flex: 1,
                    }}
                  />
                </div>
              }
              content={
                <InfoBoxWidget $noBox style={{ maxHeight: "50vh", overflowY: "auto", overflowX: "hidden" }}>
                  <h5>Comments</h5>
                  {result.comments.map((comment) => (
                    <span key={comment} style={{ margin: "5px 0", maxWidth: 500 }}>
                      {comment}
                    </span>
                  ))}
                  <h5>Sources</h5>
                  {result.sources.map((source) => (
                    <a
                      key={source}
                      href={source}
                      target="_blank"
                      rel="noreferrer"
                      style={{ margin: "5px 0", maxWidth: 500 }}
                    >
                      {source}
                    </a>
                  ))}
                </InfoBoxWidget>
              }
              wide="very"
            />
          ))}
        </InfoContainer>
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyChildOwnerships;

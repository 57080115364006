import React from "react";
import { Placeholder, PlaceholderProps } from "semantic-ui-react";
import styled from "styled-components";

type PlaceholderItemProps = PlaceholderProps & {
  height: number;
};

export const PlaceholderItem = ({ height, style = {}, ...rest }: PlaceholderItemProps) => (
  <Placeholder
    {...rest}
    style={{
      height,
      borderRadius: 5,
      backgroundColor: "#fff",
      margin: 0,
      ...style,
    }}
  />
);

type PlaceholderStackProps = {
  gap: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

const PlaceholderStackContainer = styled.div<{ $gap: number }>`
  display: flex;
  gap: ${({ $gap }) => $gap}px;
  flex-direction: column;
  > .ui.placeholder {
    margin: 0;
  }
`;

export const PlaceholderStack = ({ gap, children, style = {} }: PlaceholderStackProps) => (
  <PlaceholderStackContainer $gap={gap} style={style}>
    {children}
  </PlaceholderStackContainer>
);

import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import util from "utils/utils";
import { useTranslation } from "react-i18next";

const IpStatementContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 1rem;
`;

const IpStatementItem = styled.div`
  border: 1px solid #e2e2e2;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  min-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    margin-right: 2rem;
  }
  .title {
    display: block;
    font-weight: 600;
  }
  .meta {
    display: block;
    color: #999999;
  }
  a {
    display: block;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const IPStatementInfo = ({ ipStatement, user }) => {
  const { userSignature, userSignedOn } = ipStatement;
  const { signedName, signedEmail } = userSignature || {};
  const { t } = useTranslation();
  if (!util.organisationFeaturesEnabled(user, ["ipManagement"])) return null;
  return (
    <IpStatementContainer>
      <IpStatementItem>
        <Icon
          name={ipStatement.userHasSigned ? "check circle" : "close"}
          inverted={!ipStatement.userHasSigned}
          color={ipStatement.userHasSigned ? "green" : "red"}
          size="big"
        />
        <div className="info-container">
          <span className="title">
            {ipStatement.userHasSigned
              ? `${t("common:capitalise", { key: "generic.idea" })} owner ${signedName} (${signedEmail}) has signed IP statement`
              : `${t("common:capitalise", { key: "generic.idea" })} owner has not signed latest IP statement`}
          </span>

          {ipStatement.userHasSigned ? (
            <span className="meta">Signed on {moment(userSignedOn).format("DD/MM/YY")}</span>
          ) : null}
        </div>
      </IpStatementItem>
    </IpStatementContainer>
  );
};

export default connect((state) => ({ user: state.user }))(IPStatementInfo);

import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  margin-top: 30px;
  text-align: right;
  ${({ theme }) =>
    theme?.sizes?.isMobile &&
    `
    display: flex;
    flex-direction: column;
    gap: 8px;
  `};
`;

function SettingActions({ children }) {
  return <StyledDiv>{children}</StyledDiv>;
}

export default SettingActions;

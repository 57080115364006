import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div``;

const Code = styled.span`
  background-color: #5a5a5a;
  border-radius: 2px;
  padding: 0px 3px 1px 3px;
`;

const Help = () => {
  const { t } = useTranslation();
  return (
    <Text>
      <Row>Try out some of these shortcuts to add extra context.</Row>
      <Row>
        Enter
        <Code>@</Code> to mention a user. (e.g.
        <Code>@Martin Martinez</Code>)
      </Row>
      <Row>
        Enter
        <Code>$</Code> to mention {t("generic.ideaWithArticle")}, group or {t("generic.challenge")}. (e.g.
        <Code>$Save the world</Code>)
      </Row>
      <Row>
        Enter
        <Code>:</Code> to search through a list of emojis. (e.g.
        <Code>:fire:</Code> turns into 🔥)
      </Row>
    </Text>
  );
};

export default Help;

import React from "react";
import styled from "styled-components";
import FormattedComment from "components/lib/FormattedComment";
import { OpenAPI } from "simplydo/interfaces";

const StyledParagraph = styled.p<{ $redacted: boolean }>`
  white-space: pre-line;
  ${({ $redacted }) =>
    $redacted &&
    `
    color: black;
    background-color: black;
    -moz-box-shadow:3px 0 2px #444;
    border:1px dotted #555;
    background: -moz-linear-gradient(180deg, #000, #222);
  `}
`;

type MultiLineParagraphProps = {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  content?: string;
  redacted?: boolean;
  mentionedUsers?: Record<string, OpenAPI.Schemas["User"]>;
  mentionedUserAccess?: Record<string, boolean>;
};

const MultiLineParagraph = ({
  style,
  className,
  children,
  content,
  redacted,
  mentionedUsers,
  mentionedUserAccess,
}: MultiLineParagraphProps) => {
  return (
    <StyledParagraph style={style} className={className} $redacted={redacted}>
      <FormattedComment mentionedUsers={mentionedUsers} mentionedUserAccess={mentionedUserAccess}>
        {children || content}
      </FormattedComment>
    </StyledParagraph>
  );
};

export default MultiLineParagraph;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader, Grid, Card, Icon, Message } from "semantic-ui-react";
import util from "utils/utils";

const types = {
  slack: {
    icon: "slack",
    name: "Slack",
  },
};

const OAuthConnection = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [type, setType] = useState(null);

  const params = useParams();

  useEffect(() => {
    const connectionType = params.type;
    if (!types[connectionType]) {
      setErrorMessage("Simply Do does not currently support connections with this service.");
    } else {
      const type = types[connectionType];
      setType(type);
      setLoading(true);
    }
  }, [params.type]);

  return (
    <Grid stackable centered style={{ marginTop: 40 }}>
      <Grid.Column computer={6} tablet={10}>
        <Card fluid color="yellow">
          {type && (
            <Card.Content textAlign="center" style={{ paddingTop: 20 }}>
              <Icon style={{ fontSize: 40 }} name={type.icon} />
              <h3 style={{ marginTop: 10 }}>
                Adding {type.name} to your {util.appName()} account...
              </h3>
            </Card.Content>
          )}
          {loading ? (
            <Card.Content>
              <Loader active inline="centered" />
            </Card.Content>
          ) : null}
          {!loading && errorMessage ? (
            <Card.Content>
              <Message error>{errorMessage}</Message>
            </Card.Content>
          ) : (
            <React.Fragment>
              <Card.Content>
                <h2>Done</h2>
              </Card.Content>
            </React.Fragment>
          )}
        </Card>
      </Grid.Column>
    </Grid>
  );
};

export default OAuthConnection;

import { OpenAPI } from "simplydo/interfaces";
import { Icon } from "semantic-ui-react";

import Emoji from "components/lib/Emoji/Emoji";
import ConfigurableTable from "../ConfigurableTable";
import { UserChip } from "../Chips";
import { Link, useNavigate } from "react-router-dom";
import { StatusLabel } from "../Cards/ChallengeOverview/styles";
import moment from "moment";
import Tag, { TagContainer } from "../Tag";
import { useTranslation } from "react-i18next";
import { IdeaCoverImage } from "../Cards/IdeaCard/ListView/styles";
import util from "utils/utils";

type IdeasProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  closeModal: () => void;
};

export const Ideas = ({ businessProfile }: IdeasProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <ConfigurableTable
        tableKey="businessProfileIdeas"
        data={businessProfile.ownerIdeas ?? []}
        keyExtractor={(idea) => idea._id}
        onRowClick={(idea) => {
          util
            .confirm(
              "Do you want to view this idea?",
              "You will leave the current page and will navigate to the idea. Proceed?",
            )
            .then(() => navigate(`/ideas/${idea._id}`))
            .catch(() => {});
        }}
        columns={[
          {
            key: "image",
            name: "",
            settingName: "Image",
            width: 40,
            // @ts-ignore
            render: ({ item }) => <IdeaCoverImage $coverImage={util.ideaCoverImage(item)} />,
          },
          {
            key: "name",
            name: "Name",
            collapsing: true,
            render: ({ cell }) => <b>{cell}</b>,
          },
          {
            key: "owner",
            name: "User",
            render: ({ cell }) => <UserChip user={cell} compact />,
          },
          {
            key: "collaborators",
            name: "Collaborators",
            render: ({ item }) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {item?.authors
                  ?.filter((a) => a._id !== item.user)
                  .map((a) => <UserChip key={a._id} user={a} compact />)}
              </div>
            ),
          },
          {
            key: "challenge",
            name: t("common:capitalise", { key: "generic.challenge" }),
            collapsing: true,
            render: ({ item }) => (
              <>
                <Icon name="target" />
                {item?.ownerChallenge?.name ? (
                  <Link to={`/challenges/${item.challenge}`}>{item.ownerChallenge.name}</Link>
                ) : (
                  <span>{t("common:capitalise", { key: "generic.challenge" })} deleted</span>
                )}
              </>
            ),
          },
          {
            key: "status",
            name: "Status",
            render: ({ item }) => (
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {/* @ts-ignore */}
                <StatusLabel color={item.isSubmitted ? "green" : "yellow"} />
                {item.isSubmitted ? "Submitted" : "Draft"}
              </div>
            ),
          },
          {
            key: "updatedAt",
            name: "Last update",
            collapsing: true,
            sortable: true,
            render: ({ cell }) => moment(cell).format("DD/MM/YY"),
          },
          {
            key: "submittedAt",
            name: "Submitted on",
            collapsing: true,
            sortable: true,
            render: ({ cell }) => moment(cell).format("DD/MM/YY"),
          },
          {
            key: "stamps",
            name: "Stamps",
            render: ({ cell }) => (
              <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {cell?.map((stamp) => (
                  <Emoji selected={false} onClick={null} key={stamp} emoji={{ id: stamp }} size={15} />
                ))}
              </div>
            ),
          },
          {
            key: "tags",
            name: "Tags",
            render: ({ item }) => (
              <TagContainer>
                {/* @ts-ignore */}
                {item.fullTags?.map((tag) => <Tag tag={tag} key={tag.value} />)}
              </TagContainer>
            ),
          },
          {
            key: "comments",
            name: "Comments",
            render: ({ item }) =>
              item.ownerChallenge?.preventIdeaComments ? <span>Disabled</span> : <span>{item.commentCount || 0}</span>,
          },
          {
            key: "likes",
            name: "Likes",
            render: ({ item }) => <span>{item.voteCount || 0}</span>,
          },
        ]}
      />
    </>
  );
};

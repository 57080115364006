import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Icon as SemanticIcon, Menu, Dropdown, Popup, Button, Modal, Message, Input, Icon } from "semantic-ui-react";
import { DateInput } from "components/lib/DateInputs";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import api from "api";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useTheme from "theme/useTheme";
import util from "utils/utils";

import ProjectComments from "components/lib/ProjectBoard/ProjectComments";
import { IdeaAssessmentReport } from "components/lib/Ideas";
import { UserChooser } from "components/lib/Choosers";
import { UserChip } from "components/lib/Chips";
import { HoverActionButton, HoverAction, HoverActionsContentBox } from "components/lib/HoverActions";
import Impacts from "../Impacts";

const eventTypes = {
  changedLane: { icon: "arrow right", color: "blue" },
  assignedUser: { icon: "user plus", color: "green" },
  assignedExternalUser: { icon: "user plus", color: "green" },
  unassignedUser: { icon: "user times", color: "red" },
  changedDeadline: { icon: "calendar", color: "blue" },
  changedReviewDate: { icon: "calendar", color: "blue" },
  addedToBoard: { icon: "add circle", color: "green" },
  removedFromBoard: { icon: "remove circle", color: "red" },
};

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const DateInputContainer = styled.div`
  > span {
  }
`;

const SubMenuItemContainer = styled.div`
  margin: 0 5px;
`;

const EventItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon-container {
    .i {
      font-size: 0.9em;
      line-height: 1.1em;
    }
    margin-right: 10px;
  }
  .info-container {
    > span {
      display: block;
    }
    .meta {
      color: gray;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
  &:not(:first-of-type) {
    margin-top: 5px;
  }
`;

const OpenModalAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: block;
    font-style: italic;
    color: gray;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const ExistingAssignees = styled.div`
  max-width: 100%;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
`;
const PendingInvites = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const PendingInvite = styled.div`
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  justify-content: space-between;
  align-items: center;
  span {
    display: block;
    margin-right: 10px;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

const PotentialAssignees = styled(ExistingAssignees)`
  flex-direction: column;
  gap: 10px;
`;

const AssigneeNote = styled.div`
  margin-top: 10px;
  > span {
    display: block;
    margin: 2.5px 0;
  }
  .meta {
    color: gray;
    font-size: 12px;
  }
`;

const PotentialAssigneeActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
  .ui.button {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }
`;

export const UNSAVED_ASSIGNEE_TEXT = [
  "You currently have unsaved assignments",
  "If you navigate away from the project assignment now you will lose your new assignments. Are you sure you want to continue?",
];

const ProjectTabs = {
  OVERVIEW: "overview",
  NOTES: "notes",
  ASSIGNEES: "assignees",
  ASSESSMENT: "assessment",
  ACTIVITY: "activity",
  IMPACT: "impact",
};

const IdeaPreviewProjectBoardActions = ({
  getBoard,
  idea,
  forBoardId: forId,
  canManage,
  onUpdateIdea,
  setCommentsOpen,
  lanes = [],
  updateIdeaPosition,
  onIdeaActivity,
  user,
  assessmentEnabled,
  challenge: propChallenge,
  potentialAssignees = [],
  setPotentialAssignees,
  canViewEntireChallenge,
  canCreateProjectNote,
}) => {
  const [projectOpen, setProjectOpen] = useState(true);
  const [ideaEvents, setIdeaEvents] = useState([]);
  const [invitationsModalOpen, setInvitationsModalOpen] = useState(false);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [activeProjectTab, _setActiveProjectTab] = useState(ProjectTabs.OVERVIEW);
  const [currentLane, setCurrentLane] = useState("default");
  const [impactList, setImpactList] = useState(null);
  const [refreshNotes, setRefreshNotes] = useState(true);

  const [potentialAssigneesNote, setPotentialAssigneesNote] = useState("");
  const { t } = useTranslation();

  const usingChallenge = useMemo(() => propChallenge ?? idea?.ownerChallenge, [propChallenge, idea]);
  const ideaId = idea?._id;
  const board = getBoard(idea);
  const laneName = lanes.find((l) => l._id === currentLane);
  const theme = useTheme();

  const userIsAssigned = useMemo(
    () => !!(board?.assignees || []).find((assigneeId) => assigneeId === user._id),
    [user, board],
  );
  const userIsAssessor = useMemo(
    () => !!(idea?.assessments || []).find((assessment) => assessment?.user === user._id),
    [idea, user],
  );

  const canViewImpacts =
    canManage ||
    userIsAssigned ||
    canViewEntireChallenge ||
    util.hasPermission(user, "challenge.viewProjectImpacts", usingChallenge._id);

  const getInvitations = useCallback(() => {
    api.invitations.getForType("projectInvited", ideaId, ({ invitations }) => {
      setPendingInvitations(invitations);
    });
  }, [setPendingInvitations, ideaId]);

  // Hook into the project tab changing, to notify users if they're attempting to change tabs with unsaved changes
  const setActiveProjectTab = useCallback(
    (newTab) => {
      const currentTab = activeProjectTab;
      const onConfirm = () => _setActiveProjectTab(newTab);
      if (currentTab === ProjectTabs.ASSIGNEES && newTab !== ProjectTabs.ASSIGNEES && potentialAssignees.length > 0) {
        util
          .confirm(UNSAVED_ASSIGNEE_TEXT[0], UNSAVED_ASSIGNEE_TEXT[1])
          .then(() => {
            onConfirm();
          })
          .catch(() => {
            return;
          });
      } else {
        onConfirm();
      }
    },
    [activeProjectTab, potentialAssignees.length],
  );

  useEffect(() => {
    getInvitations();
    const currentLaneId = board?.lane || "default";
    setCurrentLane(currentLaneId);
  }, [board, getInvitations]);

  const handleUpdateIdeaPosition = useCallback(
    (laneId) => {
      updateIdeaPosition(ideaId, laneId, 0);
      setCurrentLane(laneId);
      toast.success(
        `${t("common:capitalise", { key: "generic.idea" })} moved to lane ${lanes.find((l) => l._id === laneId)?.name || "Default"}`,
      );
    },
    [ideaId, updateIdeaPosition, lanes, t],
  );

  const updateProjectDeadline = useCallback(
    (inputDate) => {
      if (!board) return;
      const deadline = inputDate ? moment(inputDate, "YYYY-MM-DD").toDate() : null;
      const currentDeadline = board?.deadline;
      if ((!deadline && !currentDeadline) || deadline === currentDeadline) return;
      api.boards.updateProjectIdeaDeadline(
        forId,
        ideaId,
        deadline,
        () => {
          const updatedIdea = { ...idea };
          const newBoard = getBoard(updatedIdea, updatedIdea.challenge);
          newBoard.deadline = deadline && new Date(deadline);
          onUpdateIdea(updatedIdea);
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaId, idea, board, forId, onUpdateIdea, getBoard],
  );

  const addReviewDateNote = useCallback(() => {
    util
      .prompt(
        `Add ${t("generic.ideaWithArticle")} review note`,
        `What would you like to talk about during the next review of this ${t("generic.idea")}? The note will be added to the project notes section.`,
        (reviewDateNote) => {
          if (reviewDateNote) {
            const formattedReviewDateNote = "Note for next review: " + reviewDateNote;
            api.boards.postProjectComment(forId, ideaId, { message: { text: formattedReviewDateNote } }, () => {
              setRefreshNotes(true);
            });
          }
        },
      )
      .catch(() => {});
  }, [forId, ideaId, t, setRefreshNotes]);

  const updateProjectReviewDate = useCallback(
    (inputDate) => {
      if (!board) return;
      const reviewDate = inputDate ? moment(inputDate, "YYYY-MM-DD").toDate() : null;
      const currentreviewDate = board?.reviewDate;
      if ((!reviewDate && !currentreviewDate) || reviewDate === currentreviewDate) return;
      api.boards.updateProjectIdeaReviewDate(
        forId,
        ideaId,
        reviewDate,
        () => {
          const updatedIdea = { ...idea };
          const newBoard = getBoard(updatedIdea, updatedIdea.challenge);
          newBoard.reviewDate = reviewDate && new Date(reviewDate);
          onUpdateIdea(updatedIdea);
          if (reviewDate && util.hasPermission(user, "challenge.createProjectNotes", updatedIdea.challenge))
            addReviewDateNote();
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaId, idea, board, forId, onUpdateIdea, getBoard, addReviewDateNote, user],
  );

  const finishAssignments = useCallback(() => {
    setPotentialAssignees([]);
    setPotentialAssigneesNote("");
  }, [setPotentialAssignees]);

  const addAssignee = useCallback(
    (assigneeId) => {
      api.boards.assignProjectIdea(
        forId,
        ideaId,
        assigneeId,
        { message: potentialAssigneesNote },
        ({ assignees, assigneeUsers, updatedAt }) => {
          const updatedIdea = { ...idea };
          const newBoard = getBoard(updatedIdea, updatedIdea.challenge);
          newBoard.assigneeUsers = assigneeUsers;
          newBoard.assignees = assignees;
          newBoard.updatedAt = updatedAt;
          onUpdateIdea({
            ...idea,
            projectManagement: {
              ...idea.projectManagement,
              boards: [...idea.projectManagement.boards.filter((b) => b._id !== forId), newBoard],
            },
          });
          finishAssignments();
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaId, potentialAssigneesNote, forId, idea, onUpdateIdea, getBoard, finishAssignments],
  );

  const addAllAssignees = useCallback(() => {
    const emailUsers = potentialAssignees.filter((u) => u.isEmailInvitee);
    const existingUsers = potentialAssignees.filter((u) => !u.isEmailInvitee);
    if (emailUsers.length > 0) {
      api.invitations.createBulk(
        {
          invitees: emailUsers.map((u) => u._id),
          invitationType: "email",
          forType: "projectInvited",
          forId: ideaId,
          board: { forId },
        },
        () => {
          getInvitations();
          toast.success(`Invitation${emailUsers.length > 1 ? "s" : ""} sent`);
          if (potentialAssigneesNote) {
            api.boards.postProjectComment(forId, ideaId, {
              message: {
                text: `${user.profile.fullName} invited ${util.pluralise(emailUsers.length, "email user", "email users")} to this card: ${potentialAssigneesNote}`,
              },
            });
          }
          finishAssignments();
        },
      );
    }
    if (existingUsers.length > 0) {
      existingUsers.forEach((u) => addAssignee(u._id));
    }
  }, [forId, ideaId, potentialAssigneesNote, potentialAssignees, user, finishAssignments, addAssignee, getInvitations]);

  const handleSearch = useCallback(
    (search, callback, fail) => {
      api.boards.searchProjectAssignees(
        forId,
        search,
        ({ users: potentialAssignees }) => {
          callback(potentialAssignees);
        },
        fail,
      );
    },
    [forId],
  );

  const getIdeaEvents = useCallback(() => {
    if (ideaId && util.hasPermission(user, "challenge.viewProjectActivity", usingChallenge._id)) {
      api.boards.getProjectIdeaEvents(forId, ideaId, (data) => {
        setIdeaEvents(data.events);
      });
    }
  }, [forId, ideaId, user, usingChallenge?._id]);

  useEffect(() => {
    getIdeaEvents();
  }, [getIdeaEvents]);

  const removePendingInvitation = useCallback(
    (invitationId) => {
      api.invitations.remove(
        invitationId,
        () => {
          const updatedPendingInvitations = pendingInvitations.filter((i) => i._id !== invitationId);
          setPendingInvitations(updatedPendingInvitations);
          toast.success("Invitation removed. The user can no longer accept it.");
        },
        () => toast.error("Failed to remove invite"),
      );
    },
    [pendingInvitations],
  );

  useEffect(() => {
    if (user.ownerOrganisation._id) {
      api.organisations.getImpacts(user.ownerOrganisation._id, ({ impacts }) => {
        setImpactList(impacts);
      });
    }
  }, [user.ownerOrganisation._id]);

  const removeAssignee = useCallback(
    (assigneeId) => {
      util
        .confirm(
          "Remove assignee",
          "This user will be un-assigned from this project card, and will be unable to complete any project actions until you re-assign them. Are you sure you want to do this?",
        )
        .then(() => {
          api.boards.unassignProjectIdea(
            forId,
            ideaId,
            assigneeId,
            ({ assignees, assigneeUsers, updatedAt }) => {
              const updatedIdea = { ...idea };
              const newBoard = getBoard(updatedIdea, updatedIdea?.ownerChallenge);
              newBoard.assigneeUsers = assigneeUsers;
              newBoard.assignees = assignees;
              newBoard.updatedAt = updatedAt;
              onUpdateIdea(updatedIdea);
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {});
    },
    [ideaId, forId, getBoard, idea, onUpdateIdea],
  );

  const getLaneName = useCallback(
    (event, laneKey = "previousLane") => {
      // If previousLane is empty string/null, we know it was moved from default lane
      if (!event[laneKey]) {
        return usingChallenge?.projectBoard?.defaultLane?.name ?? "Default";
      }
      if (event[`${laneKey}Object`]) {
        return event[`${laneKey}Object`].name;
      }
      return "an unknown lane";
      // Else if we know the previous lane object, we can return that
    },
    [usingChallenge],
  );

  const impactEnabled = user.ownerOrganisation.enabledFeatures.includes("ideaImpacts");
  return (
    <ActionContainer data-testid="simplydo-project-board-actions">
      {!projectOpen ? (
        <HoverAction
          icon="compass"
          margined
          onClick={() => {
            setProjectOpen(true);
            setCommentsOpen(false);
          }}
        >
          {t("common:capitalise", { key: "generic.idea" })} management
        </HoverAction>
      ) : (
        <HoverActionsContentBox $large>
          <HoverActionButton onClick={() => setProjectOpen(false)}>
            <SemanticIcon name="compass" style={{ height: "1.1em" }} />
            <span>{t("common:capitalise", { key: "generic.idea" })} management</span>
            {theme.sizes.isMobile ? (
              <div className="fake-icon" />
            ) : (
              <SemanticIcon name="close" style={{ height: "1.1em" }} onClick={() => setProjectOpen(false)} />
            )}
          </HoverActionButton>
          <div className="content-box">
            <Menu tabular size="tiny" stackable style={{ overflowX: "auto", overflowY: "hidden" }}>
              {userIsAssigned || canViewEntireChallenge ? (
                <Menu.Item
                  name="Overview"
                  active={activeProjectTab === ProjectTabs.OVERVIEW}
                  onClick={() => setActiveProjectTab(ProjectTabs.OVERVIEW)}
                />
              ) : null}
              {userIsAssigned ||
              canViewEntireChallenge ||
              util.hasPermission(user, "challenge.viewProjectNotes", usingChallenge._id) ? (
                <Menu.Item
                  name="Notes"
                  active={activeProjectTab === ProjectTabs.NOTES}
                  onClick={() => setActiveProjectTab(ProjectTabs.NOTES)}
                />
              ) : null}
              {userIsAssigned ||
              canManage ||
              util.hasPermission(user, "challenge.editProjectAssignees", usingChallenge._id) ? (
                <Menu.Item
                  name="Assignees"
                  active={activeProjectTab === ProjectTabs.ASSIGNEES}
                  onClick={() => setActiveProjectTab(ProjectTabs.ASSIGNEES)}
                />
              ) : null}
              {(userIsAssigned ||
                canViewEntireChallenge ||
                userIsAssessor ||
                util.hasPermission(user, "challenge.viewAssessments", usingChallenge._id)) &&
              assessmentEnabled ? (
                <Menu.Item
                  name="Assessment"
                  active={activeProjectTab === ProjectTabs.ASSESSMENT}
                  onClick={() => setActiveProjectTab(ProjectTabs.ASSESSMENT)}
                />
              ) : null}
              {(userIsAssigned ||
                canViewEntireChallenge ||
                util.hasPermission(user, "challenge.viewProjectActivity", usingChallenge._id)) &&
              ideaEvents.length ? (
                <Menu.Item
                  name="Activity"
                  active={activeProjectTab === ProjectTabs.ACTIVITY}
                  onClick={() => setActiveProjectTab(ProjectTabs.ACTIVITY)}
                />
              ) : null}
              {impactEnabled && canViewImpacts && impactList?.length > 0 && (
                <Menu.Item
                  name="Impact"
                  active={activeProjectTab === ProjectTabs.IMPACT}
                  onClick={() => setActiveProjectTab(ProjectTabs.IMPACT)}
                />
              )}
            </Menu>
            {activeProjectTab === "overview" && (
              <SubMenuItemContainer>
                <div style={{ marginBottom: 10 }}>
                  <span style={{ fontWeight: "bold" }}>Stage: {laneName?.name || "Default"}</span>
                </div>
                <div className="content-item" style={{ alignItems: "center", display: "flex", marginTop: 10 }}>
                  <DateInputContainer>
                    <span>Review date</span>
                    <DateInput
                      style={{ marginRight: 10 }}
                      clearable={
                        userIsAssigned ||
                        canManage ||
                        util.hasPermission(user, "challenge.editProjectDates", usingChallenge._id)
                      }
                      closable={true}
                      disabled={
                        !userIsAssigned &&
                        !canManage &&
                        !util.hasPermission(user, "challenge.editProjectDates", usingChallenge._id)
                      }
                      placeholder="Add a review date"
                      value={board && board.reviewDate ? moment(board.reviewDate).format("YYYY-MM-DD") : null}
                      dateFormat="YYYY-MM-DD"
                      iconPosition="left"
                      size="tiny"
                      onChange={(event, { value }) => updateProjectReviewDate(moment(value).format("YYYY-MM-DD"))}
                      onClear={() => updateProjectReviewDate(null)}
                    />
                  </DateInputContainer>
                  <DateInputContainer>
                    <span>Deadline</span>
                    <DateInput
                      clearable={
                        userIsAssigned ||
                        canManage ||
                        util.hasPermission(user, "challenge.editProjectDates", usingChallenge._id)
                      }
                      disabled={
                        !userIsAssigned &&
                        !canManage &&
                        !util.hasPermission(user, "challenge.editProjectDates", usingChallenge._id)
                      }
                      placeholder="Add a deadline"
                      value={board && board.deadline ? moment(board.deadline).format("YYYY-MM-DD") : null}
                      dateFormat="YYYY-MM-DD"
                      iconPosition="left"
                      size="tiny"
                      onChange={(event, { value }) => updateProjectDeadline(moment(value).format("YYYY-MM-DD"))}
                      onClear={() => updateProjectDeadline(null)}
                    />
                  </DateInputContainer>
                </div>
              </SubMenuItemContainer>
            )}
            {(userIsAssigned ||
              canViewEntireChallenge ||
              util.hasPermission(user, "challenge.viewProjectNotes", usingChallenge._id)) &&
              activeProjectTab === "notes" && (
                <ProjectComments
                  forId={forId}
                  forIdea={ideaId}
                  onIdeaActivity={onIdeaActivity}
                  canManage={userIsAssigned || canManage}
                  canCreateProjectNote={userIsAssigned || canCreateProjectNote}
                  refreshNotes={refreshNotes}
                  setRefreshNotes={setRefreshNotes}
                />
              )}
            {(userIsAssigned ||
              canManage ||
              util.hasPermission(user, "challenge.editProjectAssignees", usingChallenge._id)) &&
              activeProjectTab === "assignees" && (
                <SubMenuItemContainer>
                  {usingChallenge?.projectManagementVisibility !== "users" ? (
                    <Message info>
                      <Message.Header>{t("common:capitalise", { key: "generic.idea" })} assignees</Message.Header>
                      <Message.Content>
                        This project board is only visible to {t("generic.challenge")} administrators. Only assignees
                        that are {t("generic.challenge")} administrators will be able to view the project board and{" "}
                        {t("generic.ideas")}. You can change this setting{" "}
                        <b>
                          <Link to={`/challenges/${forId}/settings/ideas`}>here</Link>
                        </b>
                      </Message.Content>
                    </Message>
                  ) : null}
                  {(userIsAssigned ||
                    canViewEntireChallenge ||
                    util.hasPermission(user, "challenge.viewProjectAssignees", usingChallenge._id)) &&
                  board &&
                  board.assignees &&
                  board?.assigneeUsers?.length ? (
                    <div style={{ marginTop: 10 }}>
                      <div className="content-item">
                        <ExistingAssignees>
                          {board.assigneeUsers.map((a) => (
                            <UserChip
                              compact
                              user={a}
                              key={a._id}
                              style={{ marginRight: 15 }}
                              actionIcon={
                                canManage ||
                                util.hasPermission(user, "challenge.editProjectAssignees", usingChallenge._id)
                                  ? "close"
                                  : null
                              }
                              actionClick={
                                canManage ||
                                util.hasPermission(user, "challenge.editProjectAssignees", usingChallenge._id)
                                  ? () => removeAssignee(a._id)
                                  : null
                              }
                            />
                          ))}
                        </ExistingAssignees>
                      </div>
                    </div>
                  ) : null}
                  {(userIsAssigned ||
                    canManage ||
                    util.hasPermission(user, "challenge.viewProjectAssignees", usingChallenge._id)) &&
                  pendingInvitations.length > 0 ? (
                    <OpenModalAction>
                      <span style={{ marginRight: 20 }} onClick={() => setInvitationsModalOpen(true)}>
                        View pending invitations
                      </span>
                    </OpenModalAction>
                  ) : null}
                  <div style={{ display: "flex", alignItems: "end", marginTop: 20 }}>
                    <UserChooser
                      trigger={
                        <Button
                          size="small"
                          content="Add assignees"
                          icon="user plus"
                          disabled={
                            !canManage &&
                            !util.hasPermission(user, "challenge.editProjectAssignees", usingChallenge._id)
                          }
                        />
                      }
                      onComplete={(users) => {
                        setPotentialAssignees(users.filter((u) => !board?.assignees?.includes(u._id)));
                      }}
                      externalInvitesInAudience={
                        usingChallenge?.visibility?.organisations?.length > 0 &&
                        usingChallenge?.projectManagementVisibility === "users"
                      }
                      clearOnComplete
                      searchFunction={handleSearch}
                      forType="projectInvited"
                      enabledFeatures={{ search: true, invite: true }}
                      confirm="Assign these users"
                    />
                  </div>
                  {potentialAssignees.length > 0 ? (
                    <div style={{ marginTop: 30 }}>
                      <p style={{ fontWeight: "bold", marginBottom: 10 }}>Selected assignees</p>
                      <PotentialAssignees>
                        {potentialAssignees.map((assignee) => (
                          <Popup
                            key={assignee._id}
                            hoverable
                            disabled={assignee.isInAudience}
                            trigger={
                              <div key={assignee._id}>
                                {!assignee.isInAudience ? (
                                  <Icon name="exclamation triangle" color="orange" size="large" />
                                ) : null}
                                <UserChip
                                  compact
                                  user={{ _id: assignee._id, profile: assignee.profile }}
                                  target="_blank"
                                  actionIcon="close"
                                  actionClick={() =>
                                    setPotentialAssignees(potentialAssignees.filter((a) => a._id !== assignee._id))
                                  }
                                />
                              </div>
                            }
                            content={
                              <Message warning>
                                <span>
                                  This assignee is currently outside of the {t("generic.challenge")} audience. Assignees
                                  not in the challenge audience will be unable to view the project board and{" "}
                                  {t("generic.ideas")}.
                                  <br /> To ensure users can access their assignments, add them to the{" "}
                                  <a target="_blank" rel="noreferrer" href={`/challenges/${forId}/settings/audience`}>
                                    challenge audience
                                  </a>{" "}
                                  after making this assignment.
                                </span>
                              </Message>
                            }
                          />
                        ))}
                      </PotentialAssignees>
                      <AssigneeNote style={{ marginTop: 15 }}>
                        <span style={{ fontWeight: "bold" }}>Assignment note</span>
                        <span className="meta">
                          This note will be added to the "Project Notes" tab along with information about who has been
                          assigned. Use it to explain why the user(s) have been assigned.
                        </span>
                        <Input
                          value={potentialAssigneesNote}
                          onChange={(e, { value }) => setPotentialAssigneesNote(value)}
                          size="tiny"
                          placeholder="Assignment note"
                          fluid
                        />
                      </AssigneeNote>
                      <PotentialAssigneeActions>
                        <Button content="Cancel" onClick={() => setPotentialAssignees({})} />
                        <Button
                          content={"Assign " + util.pluralise(potentialAssignees.length, "user", "users")}
                          primary
                          onClick={() => addAllAssignees()}
                        />
                      </PotentialAssigneeActions>
                    </div>
                  ) : null}
                </SubMenuItemContainer>
              )}
            {(userIsAssigned ||
              canViewEntireChallenge ||
              userIsAssessor ||
              util.hasPermission(user, "challenge.viewAssessments", usingChallenge._id)) &&
              activeProjectTab === "assessment" && (
                <SubMenuItemContainer>
                  <IdeaAssessmentReport idea={idea} challenge={usingChallenge} />
                </SubMenuItemContainer>
              )}
            {(userIsAssigned ||
              canViewEntireChallenge ||
              util.hasPermission(user, "challenge.viewProjectActivity", usingChallenge._id)) &&
            activeProjectTab === "activity" ? (
              <SubMenuItemContainer>
                {ideaEvents.map((e) =>
                  eventTypes[e.action] ? (
                    <EventItem key={e._id}>
                      <div className="icon-container">
                        <SemanticIcon
                          color={eventTypes[e.action].color}
                          name={eventTypes[e.action].icon}
                          size="large"
                        />
                      </div>
                      <div className="info-container">
                        <span>
                          <b>{moment(e.createdAt).fromNow()}</b>
                        </span>
                        <span className="meta">
                          {e.userObject ? `${e.userObject.profile.fullName}` : "SimplyDo"}
                          {e.action === "addedToBoard" && ` added the ${t("generic.idea")} to the board`}
                          {e.action === "removedFromBoard" && ` removed the ${t("generic.idea")} from the board`}
                          {e.action === "assignedUser" && (
                            <>
                              {e.userObject ? " assigned " : " auto-assigned "}
                              <b>{e.targetUserObject ? `${e.targetUserObject.profile.fullName}` : "unknown user"}</b>
                            </>
                          )}
                          {e.action === "assignedExternalUser" && (
                            <>
                              {" invited "}
                              <b>{e.email}</b>
                            </>
                          )}
                          {e.action === "unassignedUser" && (
                            <>
                              {" un-assigned "}
                              <b>{e.targetUserObject ? `${e.targetUserObject.profile.fullName}` : "unknown user"}</b>
                            </>
                          )}
                          {e.action === "changedLane" && (
                            <>
                              {" moved from "}
                              <b>{getLaneName(e)}</b>
                              {" to "}
                              <b>{getLaneName(e, "targetLane")}</b>
                            </>
                          )}
                          {e.action === "changedDeadline" && (
                            <>
                              {" changed the deadline to "}
                              <b>{e.deadline ? moment(e.deadline).format("DD/MM/YYYY") : "No deadline"}</b>
                            </>
                          )}
                          {e.action === "changedReviewDate" && (
                            <>
                              {" changed the review date to "}
                              <b>{e.reviewDate ? moment(e.reviewDate).format("DD/MM/YYYY") : "No review date"}</b>
                            </>
                          )}
                        </span>
                      </div>
                    </EventItem>
                  ) : null,
                )}
              </SubMenuItemContainer>
            ) : null}
            {activeProjectTab === "impact" && impactEnabled && canViewImpacts && impactList?.length > 0 ? (
              <SubMenuItemContainer>
                <Impacts
                  idea={idea}
                  user={user}
                  onUpdateIdea={onUpdateIdea}
                  impactList={impactList}
                  canManage={userIsAssigned || canManage}
                />
              </SubMenuItemContainer>
            ) : null}
          </div>
        </HoverActionsContentBox>
      )}
      {(userIsAssigned ||
        canViewEntireChallenge ||
        util.hasPermission(user, "challenge.moveProjectIdeas", usingChallenge._id)) &&
      lanes.length ? (
        <Dropdown on="hover" hoverable icon={null} trigger={<HoverAction icon="move">Move to lane</HoverAction>}>
          <Dropdown.Menu>
            {currentLane !== "default" && (
              <Dropdown.Item text="Default" onClick={() => handleUpdateIdeaPosition("default")} />
            )}

            {lanes
              .filter((l) => l._id !== currentLane)
              .map((lane) => (
                <Dropdown.Item text={lane.name} onClick={() => handleUpdateIdeaPosition(lane._id)} />
              ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={invitationsModalOpen}
        onClose={() => setInvitationsModalOpen(false)}
        style={{ width: 700 }}
      >
        <>
          <Modal.Header>Pending invitations</Modal.Header>
          <PendingInvites>
            {pendingInvitations.length > 0 && (
              <>
                {pendingInvitations.map((i) =>
                  i?.invitee ? (
                    <PendingInvite key={i._id}>
                      <p style={{ display: "inline-block" }}>{i.invitee}</p>
                      <div>
                        <span>
                          Invited by <b>{i.inviterUser ? util.getProfileName(i.inviterUser) : "someone"}</b> on{" "}
                          {moment(i.createdAt).format("DD/MM/YY")}
                        </span>
                        {canManage || util.hasPermission(user, "challenge.editProjectAssignees", usingChallenge._id) ? (
                          <Button
                            icon="trash"
                            basic
                            size="small"
                            style={{ marginLeft: 20 }}
                            onClick={() => removePendingInvitation(i._id)}
                          />
                        ) : null}
                      </div>
                    </PendingInvite>
                  ) : null,
                )}
              </>
            )}
          </PendingInvites>
          <Modal.Actions>
            <Button onClick={() => setInvitationsModalOpen(false)}>Close</Button>
          </Modal.Actions>
        </>
      </Modal>
    </ActionContainer>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(IdeaPreviewProjectBoardActions);

import actions from "actions";
import api from "api";
import { useCallback } from "react";
import { useAppDispatch } from "store";

export const useUserReload = () => {
  const dispatch = useAppDispatch();
  const reloadUser = useCallback(
    (success?: () => void, fail?: () => void) =>
      api.users.me(
        null,
        (me) => {
          dispatch(actions.user.receiveUser(me));
          success?.();
        },
        () => {
          fail?.();
        },
      ),
    [dispatch],
  );

  return reloadUser;
};

import React, { useState, useMemo } from "react";
import moment from "moment";
import { Button, Grid, Dropdown, Modal, Table } from "semantic-ui-react";
import styled from "styled-components";
import Color from "color";
import RichText from "components/lib/Editors/RichText";
import { CellAction, EventWithIndex } from "./Calendar";
import { UserChip } from "components/lib/Chips";
import { useAppSelector } from "store";

// MARK: Cell / Events
type ForegroundStyle = {
  $startsToday?: boolean;
  $endsToday?: boolean;
  $color?: string;
  $dummy?: boolean;
  $selected?: boolean;
};

const EventStyleForeground = styled.div<ForegroundStyle>`
  ${({ $color, $selected, $dummy, $startsToday, $endsToday }) => {
    const selectColor = Color($color).darken(0.2).hex();

    return `
      flex: 1;
      cursor: pointer;
      color: #fff;
      font-weight: bold;

      position: relative;
      z-index: 1;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      font-size: 12px;

      background-color: ${$selected ? selectColor : $color || "#2185d0"};
      padding: 0 6px;

      ${
        $dummy
          ? `
        visibility: hidden;
      `
          : ""
      }

      ${
        $startsToday
          ? `
        margin-left: 8px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      `
          : ""
      }

      ${
        $endsToday
          ? `
        margin-right: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      `
          : ""
      }
    `;
  }}
`;

type EventProps = {
  key: string;
  event: EventWithIndex;
  currDate: Date;
  setEditingEvent: (event: any, isNewEvent: boolean) => void;
};

type ForegroundEventProps = EventProps & {
  selected: boolean;
  onSelectEvent: (event: EventWithIndex) => void;
  deleteEvent: (eventId: string, eventType: string) => void;
  leaveEvent?: (eventId: string) => void;
  editable: boolean;
};

const ForegroundEvent = ({
  event,
  currDate,
  selected,
  onSelectEvent,
  deleteEvent,
  leaveEvent,
  setEditingEvent,
  editable,
}: ForegroundEventProps) => {
  const [open, setOpen] = useState(false);
  const user = useAppSelector((state) => state.user);

  if (event.eventType === "dummy") {
    return <EventStyleForeground $dummy>.</EventStyleForeground>;
  }

  const eventStartsToday = event.from.toDateString() === currDate.toDateString();
  const eventEndsToday = event.to.toDateString() === currDate.toDateString();
  const eventIsMonday = currDate.getDay() === 1;
  const hasSeperateEndDate = event.to.toDateString() !== event.from.toDateString();
  const dateFormat = event?.type === "reminder" ? "MMM DD" : "MMM DD, HH:mm";
  return (
    <>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Modal.Header>{event.title}</Modal.Header>
        <Modal.Content>
          <h4>Date</h4>
          {hasSeperateEndDate
            ? `${moment(event.from).format(dateFormat)} - ${moment(event.to).format(dateFormat)}`
            : `${moment(event.from).format(dateFormat)}`}
          <h4>Description</h4>
          {event.description && (
            <>
              <RichText value={event.description || ""} placeholder="No description" readOnly />
            </>
          )}
          {event?.ownerMembers?.length > 0 ? (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Members</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {event.ownerMembers.map((member) => (
                  <Table.Row key={member._id}>
                    <Table.Cell>
                      <UserChip user={member} compact />
                    </Table.Cell>
                    <Table.Cell collapsing>
                      {leaveEvent && member._id === user._id ? (
                        <Button content="Leave" icon="sign-out" basic onClick={() => leaveEvent(event.id)} />
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          {event.isGenerated || !editable ? null : (
            <>
              <Button
                icon="trash"
                basic
                onClick={() => {
                  deleteEvent && event.id && deleteEvent(event.id, event.type);
                  setOpen(false);
                }}
              />
              <Button
                content="Edit"
                icon="edit"
                onClick={() => {
                  setOpen(false);
                  setEditingEvent(event, true);
                }}
              />
            </>
          )}
          <Button color="black" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
      <EventStyleForeground
        $startsToday={eventStartsToday}
        $endsToday={eventEndsToday}
        $selected={selected}
        $color={event.color}
        onClick={() => {
          setOpen(true);
          onSelectEvent(event);
        }}
      >
        {eventStartsToday || eventIsMonday ? `${event.title}` : <span>&nbsp;</span>}
      </EventStyleForeground>
    </>
  );
};

const EventStyleBackground = styled.div<{ $color: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: ${({ $color }) =>
    Color($color || "#2185d0")
      .lighten(0.4)
      .hex()};
  padding-left: 4px;
  font-weight: bold;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ $color }) =>
    Color($color || "#2185d0")
      .lighten(0.9)
      .hex()};
`;

const StyledItemButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-left: 10px;
  padding: 3px !important;
`;

const BackgroundEvent = ({ currDate, event }: EventProps) => {
  const eventStartsToday = event.from.toDateString() === currDate.toDateString();
  const eventEndsToday = event.to.toDateString() === currDate.toDateString();

  return (
    <EventStyleBackground $color={event.color}>
      {eventStartsToday || eventEndsToday ? event.title : null}
    </EventStyleBackground>
  );
};

type CellProps = {
  key: string;
  dayDate: Date;
  daysIntoCalendar: number;
  totalDays: number;
  dayNumOfTheWeek: number;
  weekNum: number;
  events: EventWithIndex[];
  onSelectEvent: (event: EventWithIndex) => void;
  selectedEvent: EventWithIndex;
  leaveEvent: (eventId: string) => void;
  deleteEvent: (eventId: string, eventType: string) => void;
  setEditingEvent: (event: {
    from?: Date;
    to?: any;
    type: string;
    id?: string;
    eventContent?: { sendAt: Date; id: string };
    color?: string;
    members?: string[];
  }) => void;
  editable: boolean;
  cellActions: Array<CellAction>;
};

const EventList = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  padding-bottom: 8px;
`;

const Cell = ({
  dayDate,
  daysIntoCalendar,
  totalDays,
  dayNumOfTheWeek,
  events,
  onSelectEvent,
  selectedEvent,
  leaveEvent,
  deleteEvent,
  setEditingEvent,
  editable,
  cellActions,
}: CellProps) => {
  const user = useAppSelector((state) => state.user);
  const isToday = moment(dayDate).isSame(new Date(), "day");
  const isWeekendDay = dayNumOfTheWeek === 5 || dayNumOfTheWeek === 6;
  const isPaddingBeforeStartDay = daysIntoCalendar < 0;
  const isPaddingAfterEndDay = daysIntoCalendar >= totalDays;
  const [isMouseOver, setIsMouseOver] = useState<Date | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const isPadding = isPaddingBeforeStartDay || isPaddingAfterEndDay;

  const { foregroundEvents, backgroundEvents } = useMemo<{
    foregroundEvents: EventWithIndex[];
    backgroundEvents: EventWithIndex[];
  }>(() => {
    if (isPadding) {
      return { foregroundEvents: [], backgroundEvents: [] };
    }

    let foregroundIndex = 0;
    return events.reduce(
      (
        acc: {
          foregroundEvents: EventWithIndex[];
          backgroundEvents: EventWithIndex[];
        },
        event,
      ) => {
        if (event.eventType === "background") {
          acc.backgroundEvents.push(event);
        } else {
          const dummiesToInsert = event.index - foregroundIndex;
          for (let i = 0; i < dummiesToInsert; i++) {
            acc.foregroundEvents.push({ eventType: "dummy" } as EventWithIndex);
          }
          foregroundIndex = event.index + 1;

          acc.foregroundEvents.push(event);
        }

        return acc;
      },
      { foregroundEvents: [], backgroundEvents: [] },
    );
  }, [events, isPadding]);

  return (
    <Grid.Column
      style={{
        position: "relative",
        padding: 0,
        background: isToday ? "#cbebbdc7" : isPadding ? "#c3c3c3" : isWeekendDay ? "#f1f1f1" : "white",
        paddingBottom: 20,
      }}
      onMouseOver={() => setIsMouseOver(dayDate)}
      onMouseLeave={() => setIsMouseOver(null)}
    >
      <div style={{ position: "relative", zIndex: 1, paddingLeft: 4 }}>
        <span style={{ color: "grey" }}>
          {moment(dayDate).format("MMM D")} {isPadding || isWeekendDay ? null : <b>Day {daysIntoCalendar + 1}</b>}
        </span>
      </div>
      <EventList>
        {foregroundEvents.map((event) => (
          <ForegroundEvent
            key={event.id}
            event={event}
            currDate={dayDate}
            onSelectEvent={onSelectEvent}
            selected={selectedEvent?.id === event.id}
            deleteEvent={deleteEvent}
            leaveEvent={leaveEvent}
            setEditingEvent={setEditingEvent}
            editable={editable}
          />
        ))}
      </EventList>
      {backgroundEvents.map((event) => (
        <BackgroundEvent key={event.id} event={event} currDate={dayDate} setEditingEvent={setEditingEvent} />
      ))}
      {(isMouseOver || isOpen) && !isPadding && editable && (
        <Dropdown
          icon={null}
          pointing="top right"
          style={{ position: "absolute", bottom: 5, right: 5 }}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          trigger={<StyledItemButton size="mini" icon="add" content="Add item" />}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text="Add an event"
              onClick={() =>
                setEditingEvent({
                  type: "event",
                  from: dayDate,
                  to: moment(dayDate).add(1, "hour"),
                  color: "rgba(63, 136, 210, 1)",
                  members: [user._id],
                  // @ts-ignore
                  ownerMembers: [user],
                })
              }
            />
            {cellActions.map((action) =>
              action?.onlyVisibleInRange &&
              moment(dayDate.setHours(1, 0, 0, 0)) <= moment(new Date().setHours(0, 0, 0, 0)) ? null : (
                <Dropdown.Item
                  key={action?.name}
                  text={action?.name}
                  onClick={() => {
                    const eventData = action.action(dayDate as unknown as string);
                    setEditingEvent(eventData as Event);
                  }}
                />
              ),
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Grid.Column>
  );
};

export default Cell;

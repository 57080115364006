import React, { Component } from "react";
import { Progress } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";

const AchievementContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 30%;
  justify-content: flex-start;

  .meta {
    color: #5d5d5d;
    text-align: left !important;
    min-height: 1rem;
  }
`;

const AchievementDiv = styled.div`
  flex: 1;
  text-align: center;
  border-radius: 10px;
  padding: 25px 10px 8px 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ $achieved }) => ($achieved ? "#EBF8EA" : "#fff")};
  border: 0.5px solid ${({ $achieved }) => ($achieved ? "#bfd9bd" : "#babcbe")};
  span {
    display: block;
  }
  .achievement-icon {
    line-height: 40px;
    font-size: 40px;
    padding-bottom: 10px;
    position: relative;
  }
  .name {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .description {
    font-size: 14px;
    color: rgb(170, 170, 170);
    margin-bottom: 10px;
  }
  &.not-won {
    span {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .icon {
      width: 43px;
      height: 43px;
      border-radius: 50%;
      background: rgb(220, 220, 220);
      margin-bottom: 10px;
    }
    .name {
      width: 60%;
      height: 15px;
      background: rgb(210, 210, 210);
      border-radius: 3px;
      margin-bottom: 10px;
    }
  }
`;

const ProgressBar = styled(Progress)`
  margin: 0 10px !important;
  .label {
    color: #e2e7f3 !important;
  }
`;

class AchievementComponent extends Component {
  render() {
    const { achievement, showDate, showAllInfo } = this.props;
    const { name, icon, description, achievedAt, exp, progress = 0, count = 0, help } = achievement;
    const achieved = achievement.achieved || showAllInfo;

    return (
      <AchievementContainer className="achievement-component">
        <AchievementDiv $achieved={achieved} className={!achieved ? "not-won" : ""}>
          {achieved ? (
            <span
              role="img"
              aria-label={name}
              className="achievement-icon"
              dangerouslySetInnerHTML={{ __html: icon }}
            />
          ) : (
            <span className="icon" />
          )}
          <span className="name">{achieved && <b>{name}</b>}</span>
          {description ? (
            <span className="description">
              {achieved ? description : help} <span>EXP: {exp}</span>
            </span>
          ) : null}
          {count ? (
            <div style={{ width: "100%" }}>
              <ProgressBar
                className="progress-bar-achievement"
                progress="ratio"
                success={achieved}
                value={progress}
                total={count}
              />
            </div>
          ) : null}
        </AchievementDiv>
        <span className="meta" style={{ color: "#babcbe" }}>
          {achievedAt
            ? `Achieved ${showDate ? moment(achievedAt).format("DD/MM/YY") : moment(achievedAt).fromNow()}`
            : ""}
        </span>
      </AchievementContainer>
    );
  }
}

export default withTranslation()(AchievementComponent);

import React from "react";
import styled from "styled-components";

const StyledRedactedInfo = styled.div<{ $width: number }>`
  margin-bottom: 7px;
  height: 12px;
  width: ${({ $width }) => $width}%;
  background-color: rgb(200, 200, 200);
`;

const RedactedInfo = ({ width }: { width: number }) => <StyledRedactedInfo $width={width} />;

export default RedactedInfo;

import React, { useCallback } from "react";
import { Button, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import moment from "moment";
import util from "utils/utils";

import RichTextViewer from "components/lib/Editors/RichTextViewer";
import { CardFeed, ContentArea } from "./FeedCard";

const CardHeader = styled.div`
  width: 100%;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .author {
    display: flex;
    align-items: center;
    color: gray;
    max-width: 80%;
  }
  .header-right {
    display: flex;
    align-items: center;
    .ui.button {
      margin-left: 5px;
    }
    .created {
      > span {
        display: block;
        color: "rgb(180,180,180)";
        font-size: 10px;
      }
    }
  }
`;

const CardAvatar = styled.div`
  position: relative;
  height: 30px !important;
  width: 30px !important;
  line-height: inherit;
  border-radius: 50%;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: middle;
  margin-right: 5px;
  background-image: ${({ avatarUrl }) => `url(${avatarUrl})`};
`;

const PostCardFeed = styled(CardFeed)`
  margin-bottom: 20px;
  &:hover {
    background-color: #fff;
  }
`;

const OrganisationPost = ({ user, post, canPost, onUpdatePost, onDeletePost, isGroup }) => {
  const { message, owner, createdAt } = post;
  const theme = useTheme();

  const handleDelete = useCallback(() => {
    util
      .confirm(
        "Delete activity feed post",
        "The post will be deleted and no longer shown in any activity feeds in this organisation. This action is irreversible",
      )
      .then(() => onDeletePost(post._id))
      .catch(() => {});
  }, [onDeletePost, post._id]);

  return (
    <PostCardFeed>
      <CardHeader>
        <div className="author">
          {owner || !isGroup ? (
            <CardAvatar avatarUrl={util.mixinCssUrlFallback(util.avatarUrl(owner), util.avatarUrl())} />
          ) : null}
          {owner
            ? `${owner.profile.fullName} posted a message to ${user.ownerOrganisation?.name}`
            : `${isGroup ? post.ownerGroup.name : user.ownerOrganisation.name} posted a message`}
        </div>
        <div className="header-right">
          {!theme.sizes.isMobile && (
            <div className="created" data-tooltip={moment(createdAt).format("HH:mm on DD/MM/YY")}>
              <span>{moment(createdAt).fromNow()}</span>
            </div>
          )}
          {!canPost && post.isPinned ? <Button size="mini" icon="pin" disabled color="green" /> : null}
          {canPost && onUpdatePost && (
            <Popup
              on="hover"
              hoverable
              content={
                post.isPinned
                  ? "Click this button to un-pin this post. It will no longer be guaranteed to appear at the top of every users activity feed. Once this post is one week old, it will gradually be replaced by more recent posts."
                  : "Click this button to pin this post. It will always appear at the top of every users activity feed. If there are multiple pinned posts, they will be sorted by recency."
              }
              trigger={
                <Button
                  size="mini"
                  icon="pin"
                  onClick={() => onUpdatePost(post._id, { isPinned: !post.isPinned })}
                  color={post.isPinned ? "green" : undefined}
                  basic={!post.isPinned}
                />
              }
            />
          )}
          {canPost && onDeletePost && <Button size="mini" icon="trash" onClick={handleDelete} basic />}
        </div>
      </CardHeader>
      <ContentArea>
        <RichTextViewer content={message} />
      </ContentArea>
    </PostCardFeed>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(OrganisationPost);

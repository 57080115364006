import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";

const ProjectAssignedAssignee = ({
  events,
  ownerIdea = { name: "Undefined" },
  ownerChallenge = { name: "Undefined" },
}) => {
  return (
    <Trans i18nKey="notifications.projectAssignedAssignee">
      <b>{{ name: util.getEventNames(events) }}</b>
      has assigned you to help project-manage the idea
      <b>{{ idea: ownerIdea.name }}</b>
      in the challenge
      <b>{{ challenge: ownerChallenge.name }}</b>
    </Trans>
  );
};

export default ProjectAssignedAssignee;

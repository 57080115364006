import React from "react";
import { Popup, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import util from "utils/utils";
import styled from "styled-components";

import { StyledSearchItem } from "./SearchItem";
import { ImageWithFallback } from "../ImageWithFallback";

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    line-height: 1rem;
  }
`;

function UserSearchItem({ user, active, onClick, onMouseEnter, onMouseLeave }) {
  const { profile = {} } = user;
  const { fullName = "", jobTitle = "", department = "" } = profile;
  return (
    <StyledSearchItem
      onClick={onClick}
      $active={active}
      as={Link}
      to={`/users/${user._id}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ImageWithFallback
        avatar
        src={util.avatarUrl(user)}
        fallbackSrc={util.avatarUrl()}
        style={{ height: "1rem", width: "1rem" }}
      />
      <StyledInfoContainer>
        <p style={{ marginBottom: 0 }}>
          {fullName}
          {user.iconRoles?.length ? (
            <Popup
              content={`This user has the following role: ${user.iconRoles[0].name}`}
              trigger={
                <Icon
                  name={user.iconRoles[0].icon.name}
                  color={user.iconRoles[0].icon.colour}
                  style={{ marginLeft: 5 }}
                />
              }
            />
          ) : null}
        </p>
        {(jobTitle || department) && (
          <p style={{ marginBottom: 0, color: "#4e585c", fontSize: 12 }}>
            {jobTitle}
            {jobTitle && department && " - "}
            {department}
          </p>
        )}
      </StyledInfoContainer>
    </StyledSearchItem>
  );
}

export default UserSearchItem;

import React, { useCallback } from "react";
import { Card, Button, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { UserChip } from "components/lib/Chips";
import util from "utils/utils";
import { InviteCardProps } from "./types";

const RequestJoinGroupInviteCard = ({ invitation, acceptInvite, rejectInvite, removeInvite }: InviteCardProps) => {
  const { inviterUser, group, createdByCurrentUser } = invitation;

  const accept = useCallback(() => {
    util
      .confirm(
        "Allow group join",
        `Are you sure you want to allow ${inviterUser?.profile?.fullName} to join "${group?.name}"?`,
      )
      .then(() => {
        acceptInvite(
          invitation,
          () => {
            toast.success("Accepted request");
          },
          () => toast.error("Error while accepting request"),
        );
      })
      .catch(() => {});
  }, [acceptInvite, invitation, group?.name, inviterUser?.profile?.fullName]);

  const reject = useCallback(() => {
    util
      .confirm(
        "Reject group join",
        `Are you sure you want to reject ${inviterUser?.profile?.fullName} from joining "${group?.name || "Deleted group"}"?`,
      )
      .then(() => {
        rejectInvite(
          invitation,
          () => {
            toast("Request rejected");
          },
          () => toast.error("Error rejecting request"),
        );
      })
      .catch(() => {});
  }, [rejectInvite, invitation, group?.name, inviterUser?.profile?.fullName]);

  const remove = useCallback(() => {
    util
      .confirm(
        "Remove request",
        `Are you sure you want to remove your request to join "${group?.name || "Deleted group"}"?`,
      )
      .then(() => {
        removeInvite(
          invitation,
          () => {
            toast("Invitation removed");
          },
          () => toast.error("Error removing invite"),
        );
      })
      .catch(() => {});
  }, [removeInvite, invitation, group?.name]);

  if (!group) {
    invitation.canAccept = false;
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div>
        <Header
          style={{ marginBottom: "0.3em" }}
          as={Link}
          disabled={!group}
          to={group ? `/groups/${group._id}` : null}
        >
          {group?.name || "Deleted group"}
        </Header>
        <Card.Description>
          {createdByCurrentUser ? (
            "You have requested to join this group"
          ) : (
            <UserChip compact user={inviterUser} postText="has requested to join this group" />
          )}
        </Card.Description>
      </div>
      <Card.Content extra>
        <div className="ui">
          {invitation.acceptedAt ? (
            <Button basic color="green" as={Link} disabled={!group} to={`/groups/${group?._id}`}>
              Go to group
            </Button>
          ) : (
            <>
              {invitation.canAccept ? (
                <Button basic color="green" onClick={accept}>
                  Accept
                </Button>
              ) : null}
              {invitation.canRemove ? (
                <Button
                  basic
                  color={createdByCurrentUser ? null : "red"}
                  onClick={createdByCurrentUser ? remove : reject}
                >
                  {createdByCurrentUser ? "Remove" : "Reject"}
                </Button>
              ) : null}
            </>
          )}
        </div>
      </Card.Content>
    </div>
  );
};

export default RequestJoinGroupInviteCard;

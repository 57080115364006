import { isBoolean, ExtensionTag, NodeExtension, omitExtraAttributes, isElementDomNode } from "remirror";

const NODE_TYPE = "span";

export class ResizableExtension extends NodeExtension {
  tags = [ExtensionTag.InlineNode, ExtensionTag.LastNodeCompatible];

  constructor(options = {}, attrs = {}) {
    const { extraAttributes = {} } = options;
    const resizeOptions = {
      ...options,
      resizable: options.resizable !== false,
      extraAttributes: {
        src: { default: null },
        width: { default: null },
        height: { default: null },
        ...extraAttributes,
      },
    };
    super(resizeOptions);

    this.otherAttrs = attrs;
  }

  createNodeSpec(extra) {
    return {
      inline: true,
      attrs: {
        ...extra.defaults(),
        ...this.otherAttrs,
        src: { default: null },
        height: { default: null },
        width: { default: null },
      },
      parseDOM: [
        {
          tag: `${this.tag || this.name}`,
          node: this.name,
          priority: 55,
          getAttrs: (dom) => {
            if (!isElementDomNode(dom)) {
              return false;
            }
            const attrs = extra.parse(dom);
            if (!attrs.src) {
              return false;
            }
            const { srcRegex } = this.options;
            if (srcRegex && !attrs.src.match(srcRegex)) {
              return false;
            }
            return { ...attrs };
          },
        },
        {
          tag: `${NODE_TYPE}[data-node=${this.name}]`,
          priority: 60,
          getAttrs: (node) => {
            if (!isElementDomNode(node)) {
              return false;
            }
            const childAttrs = extra.parse(node.firstChild);
            if (!childAttrs.src) {
              return false;
            }
            const { srcRegex } = this.options;
            if (srcRegex && !childAttrs.src.match(srcRegex)) {
              return false;
            }
            return { ...childAttrs };
          },
        },
      ],
      toDOM: (node) => {
        const otherAttrs = omitExtraAttributes(node.attrs, extra);
        const { width, height, src } = extra.dom(node);
        const className = `${this.options.resizable && "resizable"}`;
        let style = `width: ${width}px; height: ${height}px;`;
        if (this.options.minWidth) {
          style += `min-width: ${this.options.minWidth}px;`;
        }
        if (this.options.minHeight) {
          style += `min-height: ${this.options.minHeight}px;`;
        }

        return [
          NODE_TYPE,
          {
            "data-node": this.name,
            class: className,
            style,
          },
          [
            this.tag || this.name,
            {
              src,
              width,
              height,
              ...otherAttrs,
            },
          ],
        ];
      },
    };
  }

  insertCommand(param, attrs) {
    const { tr, dispatch } = param;
    const { selection } = tr;
    const position = isBoolean(selection.$cursor) ? selection.$cursor.pos : selection.$to.pos;
    const node = this.type.create(attrs);
    dispatch(tr.insert(position, node).insertText(" "));
    return true;
  }

  createPlugin() {
    const getPluginElement = (view, pos) => {
      const { doc } = view.state;
      const node = doc.nodeAt(pos);
      if (!node) return null;
      if (node.type.name !== this.type.name) return null;
      return node;
    };

    const handleDown = (view, evt) => {
      const tagName = evt.target.tagName.toLowerCase();
      if (tagName !== NODE_TYPE) {
        return false;
      }
      const pos = view.posAtDOM(evt.target);
      const node = getPluginElement(view, pos);
      if (!node) return false;
      if (this.options.resizable) {
        this._dirtyResize = pos;
        evt.target.classList.add("mouseDown");
      }
    };

    const handleResize = (view, evt) => {
      if (this._dirtyResize) {
        const pos = this._dirtyResize;
        this._dirtyResize = null;
        const node = getPluginElement(view, pos);
        if (!node) return false;

        const tagName = evt.toElement?.tagName?.toLowerCase();
        if (tagName && tagName === node.type.name) {
          return false;
        }

        if (this.options.resizable) {
          evt.target.classList.remove("mouseDown");
          const { style } = evt.target;
          if (!style?.width || !style?.height) {
            return false;
          }
          const newWidth = Math.max(this.options.minWidth || 0, parseInt(style.width, 10));
          const newHeight = Math.max(this.options.minHeight || 0, parseInt(style.height, 10));

          if (node.attrs?.height !== newHeight || node.attrs?.width !== newWidth) {
            const update = view.state.tr.setNodeMarkup(pos, node.type, {
              src: node.attrs.src,
              height: newHeight,
              width: newWidth,
            });
            view.dispatch(update);
          }
        }

        return true;
      }
    };

    return {
      props: {
        handleDOMEvents: {
          mousedown: handleDown,
          mouseup: handleResize,
          mouseleave: handleResize,
        },
      },
    };
  }
}

import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import RowComponent from "components/lib/RowComponent";
import util from "utils/utils";
import moment from "moment";

export const HomeIdeaCard = ({ idea, index }) => (
  <RowComponent.Cell index={index} key={idea._id} as={Link} to={`/ideas/${idea._id}`}>
    <div
      style={{
        width: 50,
        height: 40,
        borderRadius: 3,
        flex: "0 0 50px",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundImage: `url(${util.mixinCssUrlFallback(util.ideaCoverImage(idea, "200x200"), util.ideaCoverImage())})`,
      }}
    />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: 5,
        marginRight: 5,
        position: "relative",
        top: -1,
      }}
    >
      <RowComponent.Title wrapping style={{ color: "#5d5d5d" }} block>
        {idea.name}
      </RowComponent.Title>
      <RowComponent.StatusArea>
        {idea.isSubmitted ? (
          <>
            <RowComponent.StatusLabel color="green" />
            Submitted {moment(idea.submittedAt).fromNow()}
          </>
        ) : (
          <>
            <RowComponent.StatusLabel color="yellow" />
            Updated {moment(idea.updatedAt).fromNow()}
          </>
        )}
      </RowComponent.StatusArea>
    </div>
  </RowComponent.Cell>
);

function HomeIdeas({ t, ideas }) {
  return (
    <RowComponent $noBorder>
      {ideas && ideas.length > 0 ? (
        <RowComponent.Fill>
          <RowComponent.Title linked to="/ideas">
            {t("generic.ideas")}
          </RowComponent.Title>
          <Button as={Link} to="/ideas" primary size="mini">
            {t("ideas.yours.sidebar.viewAll")}
          </Button>
        </RowComponent.Fill>
      ) : null}
      {ideas && ideas.length > 0 ? (
        <>
          {ideas.map((idea, index) => (
            <HomeIdeaCard key={idea._id} idea={idea} index={index} />
          ))}
        </>
      ) : null}
    </RowComponent>
  );
}

const HomeIdeasContainer = withTranslation()(HomeIdeas);

export default HomeIdeasContainer;

import { SearchParams } from "simplydo/core";
import { OpenAPI } from "simplydo/interfaces";

export const search = (api) => ({
  omni(query, success, fail) {
    const params = new SearchParams(query);
    api.authenticatedRequest("GET", `/search/omni${params.toSafeString()}`, null, success, fail);
  },
  admins(term, success, fail) {
    api.authenticatedRequest("GET", `/search/admins?query=${term}`, null, success, fail);
  },
  challengeOwner(challengeId, term, success, fail) {
    api.authenticatedRequest("GET", `/search/challenges/${challengeId}/owner?query=${term}`, null, success, fail);
  },
  challengeAssessors(challengeId, term, success, fail) {
    api.authenticatedRequest("GET", `/search/challenges/${challengeId}/assessors?query=${term}`, null, success, fail);
  },
  challengeAssessorsIncludeExisting(challengeId, term, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/search/challenges/${challengeId}/assessors?query=${term}&includeExisting=true`,
      null,
      success,
      fail,
    );
  },
  ideaCollaborators(id, term, success, fail) {
    api.authenticatedRequest("GET", `/search/ideas/${id}/collaborators/new?query=${term}`, null, success, fail);
  },
  groupMembers(id, params, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/search/groups/${id}/members/new?query=${params.term}${params.excludeMembers ? `&excludeMembers=${params.excludeMembers}` : ""}`,
      null,
      success,
      fail,
    );
  },
  ideaBusinessProfileMembers(id, params, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/search/ideaBusinessProfile/${id}/members/new?query=${params.term}${params.excludeMembers ? `&excludeMembers=${params.excludeMembers}` : ""}`,
      null,
      success,
      fail,
    );
  },
  messageRecipient(term, success, fail) {
    api.authenticatedRequest("GET", `/search/messages/recipient?query=${term}`, null, success, fail);
  },
  getRecentSearches(success, fail) {
    api.authenticatedRequest("GET", "/search/omni/recent", null, success, fail);
  },
  recentSearch(searchData, success, fail) {
    api.authenticatedRequest("POST", "/search/omni/recent", searchData, success, fail);
  },
  innovationIntelligenceListSharing(term, exclude, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/search/innovationintelligence/lists/sharing?query=${term}&exclude=${exclude}`,
      null,
      success,
      fail,
    );
  },
  google(
    query: string,
    page: number,
    limit: number,
    success: (data: OpenAPI.GET<"/search/google">["response"]) => void,
    fail,
  ) {
    api.authenticatedRequest("GET", `/search/google?query=${query}&page=${page}&limit=${limit}`, null, success, fail);
  },
});

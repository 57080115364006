import React, { useState, useMemo, useCallback } from "react";
import { Grid, Button, Input, Modal, Divider, Pagination } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { FileChooserWithDropzone } from "components/lib/Choosers";
import toast from "react-hot-toast";
import styled from "styled-components";

const clientId = import.meta.env.VITE_UNSPLASH_CLIENT_ID;

const UnsplashThumb = styled.div`
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  display: inline-block;
  position: relative;

  p {
    display: none;
    font-size: 13px;
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 2px;
  }

  .unsplash-fader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(rgba(250, 250, 250, 0.9), rgba(250, 250, 250, 0));
    transition: opacity 0.25s;
  }

  &:hover {
    .unsplash-fader {
      opacity: 0.8;
    }
    p {
      display: block;
    }
  }
`;

type ImageChooserProps = {
  forType: string;
  forId: string;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onComplete: (responseFilename: string, filename: string, responseDownload: string) => void;
  trigger?: React.ReactElement;
  content?: string;
  isBasic?: boolean;
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
};

const ImageChooser = ({
  forType,
  forId,
  isOpen,
  onOpen,
  onClose,
  onComplete,
  trigger,
  content,
  isBasic,
  primary,
  secondary,
  disabled,
}: ImageChooserProps) => {
  const { t } = useTranslation();
  const [unsplashTerm, setUnsplashTerm] = useState("");
  const [unsplashLoading, setUnsplashLoading] = useState(false);
  const [unsplashImages, setUnsplashImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maximumPage, setMaximumPage] = useState(1);
  const [noResults, setNoResults] = useState(false);
  const [open, setOpen] = useState(isOpen);

  if (isOpen !== undefined && isOpen !== open) {
    setOpen(isOpen);
  }

  // Button handlers for manually opening and closing the chooser popup
  const closePopup = useCallback(() => {
    if (open && onClose) {
      onClose();
    }
    setOpen(false);
  }, [open, onClose]);

  const openPopup = useCallback(() => {
    if (open) {
      return closePopup();
    }
    if (!open && onOpen) onOpen();
    setOpen(true);
  }, [open, onOpen, closePopup]);

  const onChosen = (responseFilename, filename, responseDownload) => {
    onComplete(responseFilename, filename, responseDownload);
    closePopup();
  };

  const chooseFromUnsplash = (image) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${image.links.download_location}&client_id=${clientId}`);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          onChosen(`unsplash-${data.url}`, null, data.url);
        }
      }
    };
    xhr.send();
  };

  const queryUnsplash = (term, page) => {
    if (!term) return;
    setUnsplashLoading(true);
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `https://api.unsplash.com/search/photos/?query=${term}&page=${page}&per_page=8&client_id=${clientId}`,
    );
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          setUnsplashImages(data.results);
          setMaximumPage(data.total_pages);
          setNoResults(data.results.length === 0);
        }
        setUnsplashLoading(false);
      }
    };
    xhr.send();
  };

  const searchUnsplash = () => {
    queryUnsplash(unsplashTerm, 1);
    setCurrentPage(1);
  };

  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      e.preventDefault();
      searchUnsplash();
    }
  };

  const triggerElement = useMemo(() => {
    if (trigger) {
      return React.cloneElement(trigger, {
        onClick: disabled ? () => {} : openPopup,
      });
    }

    return (
      <Button
        size="small"
        primary={primary}
        secondary={secondary}
        basic={isBasic}
        icon="image"
        disabled={disabled}
        content={content || t("ideas.details.chooseCover")}
        onClick={openPopup}
      />
    );
  }, [trigger, openPopup, disabled, isBasic, content, t, primary, secondary]);

  return (
    <>
      {triggerElement}
      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={open}>
        <Modal.Header>Choose an image</Modal.Header>
        <Modal.Content>
          <h3>Upload from your local machine</h3>
          <FileChooserWithDropzone
            imagesOnly={true}
            forType={forType}
            forId={forId}
            allowMultiple={false}
            onComplete={onChosen}
            onError={toast.error}
            accept="image/*"
          />
          <Divider section />
          <div>
            <h3>Search online for an image</h3>
            <p style={{ margin: 0 }}>
              <small>
                Courtesy of{" "}
                <a
                  href="https://unsplash.com/?utm_source=simply_do&utm_medium=referral"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Unsplash
                </a>
              </small>
            </p>

            <Input
              fluid
              icon="search"
              iconPosition="left"
              placeholder="Search for an image..."
              onClick={(e) => e.stopPropagation()}
              style={{ marginBottom: 20 }}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => setUnsplashTerm(e.target.value)}
              action={<Button loading={unsplashLoading} primary content="Show results" onClick={searchUnsplash} />}
            />
            {noResults && (
              <Grid.Column textAlign="center">
                <span>No images found.</span>
              </Grid.Column>
            )}
            <Grid>
              {unsplashImages.map((image) => (
                <Grid.Column width={4} key={image.urls.thumb}>
                  <UnsplashThumb
                    style={{ backgroundImage: `url(${image.urls.thumb})` }}
                    onClick={() => chooseFromUnsplash(image)}
                  >
                    <div className="unsplash-fader" />
                    <p>
                      Photo by{" "}
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        href={`https://unsplash.com/@${image.user.username}?utm_source=simply_do&utm_medium=referral`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {image.user.name}
                      </a>
                    </p>
                  </UnsplashThumb>
                </Grid.Column>
              ))}
            </Grid>
            {unsplashImages.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  marginTop: 14,
                  justifyContent: "flex-end",
                }}
              >
                <Pagination
                  totalPages={maximumPage}
                  activePage={currentPage}
                  onPageChange={(e, { activePage }) => {
                    setCurrentPage(activePage as number);
                    queryUnsplash(unsplashTerm, activePage);
                  }}
                />
              </div>
            ) : null}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("generic.close")} onClick={closePopup} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ImageChooser;

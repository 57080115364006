import { OpenAPI } from "simplydo/interfaces";

export const connections = (api) => ({
  getConnectedUsersForContext(
    service: string,
    contextId: string,
    success: (response: OpenAPI.GET<"/connections/users/{service}/{context_id}">["response"]) => void,
    fail,
  ) {
    api.authenticatedRequest("GET", `/connections/users/${service}/${contextId}`, null, success, fail);
  },
  establishConnection(
    service: string,
    contextId: string,
    success: (response: OpenAPI.POST<"/connections/users/{service}/{context_id}">["response"]) => void,
    fail,
  ) {
    api.authenticatedRequest("POST", `/connections/users/${service}/${contextId}`, null, success, fail);
  },
  removeConnection(
    service: string,
    contextId: string,
    userId: string,
    success: (response: OpenAPI.DELETE<"/connections/users/{service}/{context_id}">["response"]) => void,
    fail,
  ) {
    api.authenticatedRequest("DELETE", `/connections/users/${service}/${contextId}/${userId}`, null, success, fail);
  },
});

import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";

const RequestOpen = ({ events, ownerChallenge = { name: "Undefined" } }) => {
  return (
    <Trans i18nKey="notifications.challengeRequestOpen">
      <b>{{ name: util.getEventNames(events) }}</b>
      has sent a request to open the challenge
      <b>{{ challenge: ownerChallenge.name }}</b>
    </Trans>
  );
};

export default RequestOpen;

import React, { useState, useEffect, useCallback } from "react";
import { Icon, Loader, Table, Button, Input, Checkbox, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import util from "utils/utils";
import api from "api";
import toast from "react-hot-toast";
import moment from "moment";

const FollowingContainer = styled.div`
  gap: 10px;
  padding-bottom: 10px;
  > h3 {
    margin: 0;
  }
  > span {
    display: block;
    margin: 10px 0;
  }
  .search-options {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  .pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .following-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > span,
    a {
      display: block;
    }
    a {
      color: black;
      &:not(.following-is-deleted) {
        text-decoration-line: underline;
      }
    }
    a.following-is-deleted {
      pointer-events: none;
    }
    .following-type {
      opacity: 0.65;
      font-size: 0.8em;
      height: 15px;
    }
    .following-name {
      font-weight: 500;
    }
  }
`;

const FollowingContext = styled.div`
  display: flex;
  flex-direction: row;
  .following-image {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 10px;
  }
  i.icon {
    width: 40px;
    height: 40px;
    font-size: 2.75em;
    margin-right: 10px;
    color: gray;
  }
`;

const ProfileFollowing = ({ user, profileUser, loading: profileLoading, t }) => {
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState("");
  const [following, setFollowing] = useState([]);
  const [hasFollowing, setHasFollowing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchPage, setSearchPage] = useState(1);
  const [showDeleted, setShowDeleted] = useState(false);
  const [showUnfollowed, setShowUnfollowed] = useState(false);
  const [paginationState, setPaginationState] = useState({
    prevPageAvailable: false,
    nextPageAvailable: false,
  });
  const profileUserId = profileUser._id;

  const getFollowing = useCallback(() => {
    if (profileUserId) {
      setLoading(true);
      api.users.getFollowing(
        profileUserId,
        {
          query: searchQuery,
          page: searchPage,
          showDeleted,
          showUnfollowed,
        },
        ({ following: newFollowing, prevPageAvailable, nextPageAvailable }) => {
          setFollowing(newFollowing);
          setHasFollowing((prev) => prev || newFollowing.length);
          setPaginationState({ nextPageAvailable, prevPageAvailable });
          setLoading(false);
        },
        (err) => {
          toast.error(`Failed to get user following: ${err.message}`);
          setLoading(false);
        },
      );
    }
  }, [profileUserId, searchQuery, searchPage, showDeleted, showUnfollowed]);

  const updateFollowing = useCallback((followingItem) => {
    setUpdating(followingItem._id);
    api[`${followingItem.type}s`].follow(
      followingItem._id,
      !!followingItem.unfollowed,
      () => {
        setFollowing((prevFollowing) =>
          prevFollowing.map((f) => {
            if (f._id !== followingItem._id) return f;
            return {
              ...f,
              unfollowed: !followingItem.unfollowed,
            };
          }),
        );
        setUpdating("");
      },
      () => {
        toast.error("Failed to update following status");
        setUpdating("");
      },
    );
  }, []);

  const getFollowingItemImage = useCallback((followingItem) => {
    if (followingItem.type === "challenge") return followingItem.ownerChallenge?.bannerImageUrl || "";
    if (followingItem.type === "idea")
      return followingItem.ownerIdea ? util.ideaCoverImage(followingItem.ownerIdea) : "";
    return "";
  }, []);

  const getFollowingItemTitle = useCallback((followingItem) => {
    if (followingItem.type === "challenge") return followingItem.ownerChallenge?.name || "[DELETED]";
    if (followingItem.type === "idea") return followingItem.ownerIdea?.name || "[DELETED]";
    return "";
  }, []);

  useEffect(() => getFollowing(), [getFollowing]);

  if (profileLoading) return <Loader active />;
  return (
    <FollowingContainer>
      <h3>{t("users.following.title")}</h3>
      <span>{t("users.following.info")}</span>
      <Divider />
      {hasFollowing ? (
        <div className="search-options">
          <Input
            size="small"
            placeholder={t("users.following.search")}
            onChange={(e, { value }) => setSearchQuery(value)}
            value={searchQuery || ""}
            loading={!!(loading && searchQuery)}
          />
          <Checkbox
            checked={showUnfollowed}
            onChange={() => setShowUnfollowed(!showUnfollowed)}
            label={`Show ${t("generic.ideas")}/challenges ${user._id === profileUser._id ? "you have" : `${profileUser.profile.firstName} has`} unfollowed`}
          />
          {util.hasPermission(user, "org.viewDashboard", user.ownerOrganisation._id) ? (
            <Checkbox checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)} label="Show deleted items" />
          ) : null}
        </div>
      ) : null}
      {loading && !hasFollowing ? (
        <Loader active />
      ) : (
        <Table basic="very" padded style={{ width: "100%" }}>
          <Table.Body>
            {following.map((followingItem, idx) => (
              <Table.Row key={idx}>
                <Table.Cell width={9}>
                  <FollowingContext>
                    {getFollowingItemImage(followingItem) ? (
                      <img
                        className="following-image"
                        alt="Following item cover"
                        src={getFollowingItemImage(followingItem)}
                      />
                    ) : (
                      <Icon name="question" size="large" />
                    )}
                    <div className="following-info">
                      <span className="following-type">{followingItem.type.toUpperCase()}</span>
                      <Link
                        to={followingItem.isDeleted ? "" : `/${followingItem.type}s/${followingItem._id}`}
                        className={`following-name ${followingItem.isDeleted ? "following-is-deleted" : ""}`}
                      >
                        {getFollowingItemTitle(followingItem)}
                      </Link>
                    </div>
                  </FollowingContext>
                </Table.Cell>
                <Table.Cell width={5}>
                  <div className="following-info">
                    <span className="following-name">
                      {user._id === profileUser._id ? "You" : profileUser.profile.firstName} {followingItem.reasonText}{" "}
                      {followingItem.type}
                    </span>
                    <span className="following-type">
                      On {moment(followingItem.createdAt).format("DD/MM/YY HH:mm")}
                    </span>
                  </div>
                </Table.Cell>
                {user?._id === profileUser?._id && (
                  <Table.Cell width={2}>
                    {!followingItem.isDeleted && (
                      <Button
                        basic={!followingItem.unfollowed}
                        content={
                          followingItem.unfollowed ? t("users.following.refollow") : t("users.following.unfollow")
                        }
                        loading={followingItem._id === updating}
                        onClick={() => updateFollowing(followingItem)}
                        style={{ width: 120 }}
                      />
                    )}
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
      {hasFollowing ? (
        <div className="pagination-container">
          {paginationState.prevPageAvailable ? (
            <Button size="tiny" content={t("generic.previous")} onClick={() => setSearchPage(searchPage - 1)} />
          ) : (
            <div />
          )}
          {paginationState.nextPageAvailable ? (
            <Button size="tiny" content={t("generic.next")} onClick={() => setSearchPage(searchPage + 1)} />
          ) : (
            <div />
          )}
        </div>
      ) : null}
    </FollowingContainer>
  );
};

export default withTranslation()(ProfileFollowing);

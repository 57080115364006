import React from "react";
import { Icon } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import { useTranslation } from "react-i18next";
import api from "api";
import CardHeader from "./CardHeader";

export const CardFeed = styled.div`
  width: 100%;
  font-family: "Inter", sans-serif;
  box-shadow: ${({ theme }) => theme.boxShadow};
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 15px;
  .card-action {
    flex: 0.25;
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    background-color: #f0f0f0;
  }
  .hover-action {
    padding: 6px;
    &:hover {
      background-color: rgb(245, 245, 245) !important;
      border-radius: 15%;
    }
  }
  p {
    font-size: 1.2em;
    color: #4d4949;
    max-height: 100%;
  }
  .content-name {
    font-size: 1.2em;
    color: #000;
    margin-left: 15px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow-wrap: ellipsis;
    a {
      color: #5d5d5d;
    }
  }
  .content-meta-container {
    display: flex;
    flex-direction: column;
  }
  .content-meta {
    color: rgb(136, 132, 132);
    margin-left: 15px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow-wrap: ellipsis;
  }
  span {
    color: #4d4949;
  }
  .cover-and-text {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .cover-image {
    width: 3.2em !important;
    height: 3.2em !important;
  }
  .comment-column {
    height: 100%;
  }
  .comment-column-container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .comment-column-container p {
    font-size: 1.2em;
    color: #4d4949;
    overflow: hidden;
    word-break: break-word;
  }
  .image-link {
    a {
      width: 60px !important;
      height: 50px !important;
    }
  }
`;

export const ContentArea = styled.div`
  width: "100%";
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 20px;
`;

const FeedCard = ({ cardLeft, toLink, ...rest }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!cardLeft) {
    return null;
  }
  return (
    <CardFeed style={{ marginBottom: theme.sizes.isMobile ? 10 : 20, marginTop: 3 }}>
      <CardHeader {...rest} />
      <ContentArea
        onClick={() => {
          api.journey.record("feed", toLink, "clickedFeedCard");
          navigate(toLink);
        }}
      >
        {cardLeft}
        <Link className="hover-action" to={toLink} style={{ fontWeight: "bold", minWidth: "max-content" }}>
          {t("generic.view", { entity: "" }).toUpperCase()} <Icon name="arrow alternate circle right outline" />
        </Link>
      </ContentArea>
    </CardFeed>
  );
};

export default FeedCard;

import actions from "actions";
import api from "api";
import { CheckboxHeader } from "components/lib/UI";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Button, Icon, Message, Segment, Form, Divider, Card } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { useAppDispatch, useAppSelector } from "store";
import util from "utils/utils";

type Impact = OpenAPI.GET<"/organisations/{id}/impacts">["response"]["impacts"][number];

const formatNumber = (value: string) => {
  if (window.Intl) {
    return new Intl.NumberFormat().format(Number(value));
  }
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatRange = (impact: Impact, from: string, to: string) => {
  if (impact.type === "time") {
    return `${formatNumber(from)} - ${formatNumber(to)} days`;
  }
  if (impact.type === "money") {
    return `£${formatNumber(from)} - £${formatNumber(to)}`;
  }
  return `${formatNumber(from)} - ${formatNumber(to)}`;
};

export const formatOption = (
  impact: Impact,
  option: {
    option?: string;
    from?: string;
    to?: string;
  },
) => {
  return option.option ?? formatRange(impact, option.from, option.to);
};

const Impacts = () => {
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.user);

  const params = useParams();
  const dispatch = useAppDispatch();
  const challenge = useAppSelector((state) => state.challenges.challenges.filter((c) => c._id === params.id)[0]);
  const onReceiveChallenge = useCallback((challenge) => dispatch(actions.challenges.receive(challenge)), [dispatch]);
  const canManageOrg = useMemo(() => util.hasPermission(user, "org.viewDashboard", user.ownerOrganisation._id), [user]);
  const canManageChallenge = useMemo(() => util.canManageChallenge(user, challenge), [user, challenge]);
  const [impactList, setImpactList] = useState<Array<Impact>>([]);
  const [temporaryImpactValues, setTemporaryImpactValues] = useState({});

  const updateSettings = useCallback(
    (change) => {
      api.challenges.update(
        challenge._id,
        {
          impactStatements: {
            ...(challenge.impactStatements ?? {}),
            ...change,
          },
        },
        onReceiveChallenge,
        (err) => toast.error(err.message),
      );
    },
    [onReceiveChallenge, challenge],
  );

  const updateImpact = useCallback(
    (impactId, change) => {
      updateSettings({
        impacts: {
          ...(challenge.impactStatements?.impacts ?? {}),
          [impactId]: {
            ...(challenge.impactStatements?.impacts?.[impactId] ?? {}),
            ...change,
          },
        },
      });
    },
    [updateSettings, challenge],
  );

  useEffect(() => {
    let isMounted = true;
    if (user.ownerOrganisation._id) {
      api.organisations.getImpacts(
        user.ownerOrganisation._id,
        ({ impacts }) => {
          if (!isMounted) return;
          setImpactList(impacts);
        },
        () => {},
      );
    }
    return () => {
      isMounted = false;
    };
  }, [user.ownerOrganisation._id]);

  return (
    <div>
      <h3>Choose how impacts are going to be tracked in this {t("generic.challenge")}</h3>
      <p>
        These impacts are used to track the impact and progress of the {t("generic.ideas")} in this{" "}
        {t("generic.challenge")} and across the entire organisation.
      </p>
      {canManageOrg ? (
        <Message info>
          As an organisational admin you are able to edit the available impacts by visiting{" "}
          <Link to="/admin/settings">the admin settings</Link>. Please be aware that this will affect the impacts in{" "}
          {t("generic.challenges")} across the entire organisation.
        </Message>
      ) : null}
      <CheckboxHeader
        header={`Enable ${t("generic.idea")} impact statements`}
        description={`Allow users and project managers to generate impact statements for ${t("generic.ideas")}.`}
        as="h5"
        checked={challenge.impactStatements?.enabled}
        onChange={() => updateSettings({ enabled: !challenge.impactStatements?.enabled })}
        disabled={!canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)}
      />

      {challenge.impactStatements?.enabled ? (
        <div>
          <Segment style={{ marginTop: 15 }}>
            <h4>Configuration</h4>
            <CheckboxHeader
              header={`Show impact statements on the ${t("generic.idea")} page before submission`}
              description="Allow users to define an initial impact statement before submitting their idea."
              as="h5"
              checked={challenge.impactStatements?.showOnIdea}
              onChange={() => updateSettings({ showOnIdea: !challenge.impactStatements?.showOnIdea })}
              disabled={!canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)}
              willHideChildren
            >
              <div style={{ marginTop: 15 }}>
                <CheckboxHeader
                  header={`Require impact statements for all ${t("generic.ideas")}`}
                  description={`Require users to complete an initial impact statement before submitting their ${t("generic.idea")}.`}
                  as="h5"
                  checked={challenge.impactStatements?.requireOnIdea}
                  disabled={!canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)}
                  onChange={() => updateSettings({ requireOnIdea: !challenge.impactStatements?.requireOnIdea })}
                />
              </div>
            </CheckboxHeader>
            <CheckboxHeader
              header="Require justification"
              description="Users are able to add justification for the impact they have selected by default. With this option they will be required to supply context for their selection."
              as="h5"
              checked={challenge.impactStatements?.requireJustification}
              onChange={() =>
                updateSettings({ requireJustification: !challenge.impactStatements?.requireJustification })
              }
              disabled={!canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)}
            />
          </Segment>
          <Segment>
            <h4>Available impacts</h4>
            <p>
              Please make sure to select all impacts that are relevant to this {t("generic.challenge")}, if an impact is
              not selected it will NOT be available in any impact statement.
            </p>
            <p>
              These impacts will be available for users to select when creating an impact statement for their{" "}
              {t("generic.idea")}. You can define the ranges for each impact to make it easier for users to select the
              right impact.
            </p>
            {impactList.length === 0 ? (
              <Message info>
                There are currently no impacts available. Please contact an organisational admin to get started with
                impact statements.
              </Message>
            ) : null}

            {impactList.map((impact) => (
              <div key={impact._id}>
                <CheckboxHeader
                  header={
                    <div>
                      {impact.icon ? <Icon name={impact.icon} /> : null}
                      {impact.name}
                    </div>
                  }
                  description={impact.description}
                  as="h5"
                  checked={challenge.impactStatements?.impacts?.[impact._id]?.enabled}
                  onChange={() =>
                    updateImpact(impact._id, { enabled: !challenge.impactStatements?.impacts?.[impact._id]?.enabled })
                  }
                  disabled={!canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)}
                  willHideChildren
                >
                  {!impact.type ? (
                    <Message>No configuration required</Message>
                  ) : (
                    <Message>
                      {impact.type === "numerical" || impact.type === "money" || impact.type === "time" ? (
                        <div>
                          <p>
                            <h5>Impact options</h5>
                            These are the options a user will be able to choose from when selecting this impact. For
                            this impact type the input is going to enforce a number.
                          </p>
                          <CheckboxHeader
                            header="Allow custom input"
                            description="Let users enter an exact value for this impact instead of only having the option of selecting a predefined range."
                            as="h5"
                            checked={challenge.impactStatements?.impacts?.[impact._id]?.customOptionEnabled}
                            onChange={() => {
                              updateImpact(impact._id, {
                                customOptionEnabled:
                                  !challenge.impactStatements?.impacts?.[impact._id]?.customOptionEnabled,
                              });
                            }}
                            disabled={
                              !canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)
                            }
                          />

                          <p>
                            <h5>Impact range options</h5>
                            We recommend setting up a few options to make it easier for users to select the right
                            impact. If no options are set up, users will have to enter the exact value.
                          </p>
                          <div style={{ display: "flex", gap: 8 }}>
                            {challenge.impactStatements?.impacts?.[impact._id]?.options?.map((option, index) => (
                              <div key={index}>
                                <Card style={{ width: "auto" }}>
                                  <Card.Content>{formatRange(impact, option.from, option.to)}</Card.Content>
                                  <Card.Content extra>
                                    <Button
                                      floated="right"
                                      size="mini"
                                      icon
                                      basic
                                      onClick={() => {
                                        util
                                          .confirm("Delete impact range", "Are you sure you want to delete this range?")
                                          .then(() => {
                                            updateImpact(impact._id, {
                                              options: challenge.impactStatements?.impacts?.[
                                                impact._id
                                              ]?.options?.filter((o, i) => i !== index),
                                            });
                                          })
                                          .catch(() => {});
                                      }}
                                    >
                                      <Icon name="trash" />
                                    </Button>
                                  </Card.Content>
                                </Card>
                              </div>
                            ))}
                          </div>
                          <Divider />
                          <b>Add new range</b>
                          <Form style={{ marginTop: 8 }}>
                            <Form.Group widths="equal">
                              <Form.Input
                                type="number"
                                fluid
                                value={temporaryImpactValues[impact._id]?.from ?? ""}
                                placeholder="From"
                                onChange={(e) => {
                                  setTemporaryImpactValues({
                                    ...temporaryImpactValues,
                                    [impact._id]: {
                                      ...temporaryImpactValues[impact._id],
                                      from: parseFloat(e.target.value),
                                    },
                                  });
                                }}
                              />
                              <Form.Input
                                type="number"
                                fluid
                                placeholder="To"
                                value={temporaryImpactValues[impact._id]?.to ?? ""}
                                onChange={(e) => {
                                  setTemporaryImpactValues((prev) => ({
                                    ...prev,
                                    [impact._id]: {
                                      ...temporaryImpactValues[impact._id],
                                      to: parseFloat(e.target.value),
                                    },
                                  }));
                                }}
                              />
                              <Form.Button
                                secondary
                                onClick={() => {
                                  updateImpact(impact._id, {
                                    options: [
                                      ...(challenge.impactStatements?.impacts?.[impact._id]?.options ?? []),
                                      {
                                        from: temporaryImpactValues[impact._id]?.from,
                                        to: temporaryImpactValues[impact._id]?.to,
                                      },
                                    ],
                                  });
                                  setTemporaryImpactValues((prev) => ({
                                    ...prev,
                                    [impact._id]: {
                                      from: "",
                                      to: "",
                                    },
                                  }));
                                }}
                                disabled={
                                  temporaryImpactValues[impact._id]?.from === undefined ||
                                  temporaryImpactValues[impact._id]?.to === undefined
                                }
                              >
                                Add range
                              </Form.Button>
                            </Form.Group>
                          </Form>
                        </div>
                      ) : null}
                      {impact.type === "dropdown" ? (
                        <div>
                          <p>
                            <h5>Impact options</h5>
                            These are the options a user will be able to choose from when selecting this impact.
                          </p>
                          <CheckboxHeader
                            header="Allow custom input"
                            description="Let users enter a custom value for this impact."
                            as="h5"
                            checked={challenge.impactStatements?.impacts?.[impact._id]?.customOptionEnabled}
                            onChange={() => {
                              updateImpact(impact._id, {
                                customOptionEnabled:
                                  !challenge.impactStatements?.impacts?.[impact._id]?.customOptionEnabled,
                              });
                            }}
                            disabled={
                              !canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)
                            }
                          />
                          <div style={{ display: "flex", gap: 8 }}>
                            {challenge.impactStatements?.impacts?.[impact._id]?.options?.map((option, index) => (
                              <div key={index}>
                                <Card style={{ width: "auto" }}>
                                  <Card.Content>{option.option}</Card.Content>
                                  <Card.Content extra>
                                    <Button
                                      floated="right"
                                      size="mini"
                                      icon
                                      basic
                                      onClick={() => {
                                        util
                                          .confirm(
                                            "Delete impact option",
                                            "Are you sure you want to delete this option?",
                                          )
                                          .then(() => {
                                            updateImpact(impact._id, {
                                              options: challenge.impactStatements?.impacts?.[
                                                impact._id
                                              ]?.options?.filter((o, i) => i !== index),
                                            });
                                          })
                                          .catch(() => {});
                                      }}
                                    >
                                      <Icon name="trash" />
                                    </Button>
                                  </Card.Content>
                                </Card>
                              </div>
                            ))}
                          </div>
                          <Divider />
                          <b>Add new option</b>
                          <Form style={{ marginTop: 8 }}>
                            <Form.Group widths="equal">
                              <Form.Input
                                fluid
                                value={temporaryImpactValues[impact._id]?.option ?? ""}
                                placeholder="Option"
                                onChange={(e) => {
                                  setTemporaryImpactValues({
                                    ...temporaryImpactValues,
                                    [impact._id]: {
                                      ...temporaryImpactValues[impact._id],
                                      option: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <Form.Button
                                secondary
                                onClick={() => {
                                  updateImpact(impact._id, {
                                    options: [
                                      ...(challenge.impactStatements?.impacts?.[impact._id]?.options ?? []),
                                      {
                                        option: temporaryImpactValues[impact._id]?.option,
                                      },
                                    ],
                                  });
                                  setTemporaryImpactValues((prev) => ({
                                    ...prev,
                                    [impact._id]: {
                                      option: "",
                                    },
                                  }));
                                }}
                                disabled={temporaryImpactValues[impact._id]?.option === undefined}
                              >
                                Add option
                              </Form.Button>
                            </Form.Group>
                          </Form>
                        </div>
                      ) : null}
                    </Message>
                  )}
                </CheckboxHeader>
              </div>
            ))}
          </Segment>
        </div>
      ) : null}
    </div>
  );
};

export default Impacts;

import React, { useState, useCallback, useMemo, useEffect } from "react";
import Modal from "react-modal";
import "./sliding-pane.css";

const SlidingPane = (props) => {
  const {
    isOpen,
    onOpen,
    onClose,
    onRequestClose: propOnRequestClose,
    onAfterClose,
    // If preventUncontrolledClosure is true, the modal will not close when the user clicks outside of it, unless the onRequestClose prop is set and handled correctly;
    preventUncontrolledClosure = false,
    children,
    style,
    overlayStyle,
    from = "right",
    shouldCloseOnEsc,
    width,
    trigger,
    duration = 500,
  } = props;
  const [modalOpen, setModalOpen] = useState(isOpen);
  const isControlled = useMemo(() => "isOpen" in props, [props]);

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!modalOpen) {
      return;
    }
    const scrollTop = document.documentElement.scrollTop;
    const listener = (e) => {
      e.preventDefault();
      e.stopPropagation();
      document.documentElement.scrollTop = scrollTop;
      return false;
    };

    window.onscroll = listener;
    window.onscrollend = listener;
    return () => {
      window.onscroll = null;
      window.onscrollend = null;
    };
  }, [modalOpen]);

  const onRequestClose = useCallback(() => {
    propOnRequestClose && propOnRequestClose();
    if (!preventUncontrolledClosure) {
      setModalOpen(false);
    }
  }, [propOnRequestClose, preventUncontrolledClosure]);

  let triggerWithState = trigger;
  if (trigger && !isControlled) {
    triggerWithState = React.cloneElement(trigger, { onClick: () => setModalOpen(true) });
  }

  return (
    <>
      {trigger ? triggerWithState : null}
      <Modal
        parentSelector={() => document.getElementById("semantic-modal-mount-node")}
        ariaHideApp={false}
        className={`sliding-pane sliding-pane_from_${from}`}
        style={{
          content: {
            width: width || "80%",
            maxWidth: "100%",
            transition: `transform ${duration / 1000}s`,
            ...style,
          },
          overlay: overlayStyle,
        }}
        overlayClassName="sliding-pane__overlay"
        closeTimeoutMS={duration}
        isOpen={modalOpen}
        shouldCloseOnEsc={shouldCloseOnEsc}
        onAfterOpen={onOpen}
        onRequestClose={onRequestClose}
        onClose={onClose}
        onAfterClose={onAfterClose}
      >
        {children}
      </Modal>
      {modalOpen ? (
        <style>
          {`
            #root { padding-right: 15px; }
            :root { scrollbar-width: none; }
            ::-webkit-scrollbar { width: 0px; }
            .app-content {
              filter: blur(2px);
            }
          `}
        </style>
      ) : null}
    </>
  );
};

export default SlidingPane;

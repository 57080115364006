import React, { FC, useState, useCallback, useEffect } from "react";
import { OpenAPI } from "simplydo/interfaces";
import RowComponent from "components/lib/RowComponent";
import api from "api";
import { Icon } from "semantic-ui-react";
import { useAppSelector } from "store";

type PinnedPagesResponse = OpenAPI.GET<"/pages/pinned">;

type HomePagesProps = object;

const HomePages: FC<HomePagesProps> = () => {
  const [pinnedPages, setPinnedPages] = useState<PinnedPagesResponse["response"]["pages"]>([]);
  const user = useAppSelector((state) => state.user);
  const orgId = user.ownerOrganisation?._id;

  const getPinnedPages = useCallback(() => {
    api.pages.getPinned(
      "organisation",
      orgId,
      ({ pages: newPages }) => {
        setPinnedPages(newPages);
      },
      () => {},
    );
  }, [orgId]);

  useEffect(() => {
    getPinnedPages();
  }, [getPinnedPages]);

  if (!pinnedPages.length) return null;
  return (
    <RowComponent>
      <RowComponent.Fill>
        <RowComponent.Title>Pages</RowComponent.Title>
      </RowComponent.Fill>
      {pinnedPages.map((page) => (
        <RowComponent.Cell key={page._id} as="a" target="_blank" rel="noreferrer" href={page.link}>
          <Icon name="linkify" size="large" />
          <RowComponent.Title>{page.title}</RowComponent.Title>
        </RowComponent.Cell>
      ))}
    </RowComponent>
  );
};

export default HomePages;

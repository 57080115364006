import React from "react";
import { Icon } from "semantic-ui-react";
import { crunchbaseImageUrl } from "utils/companies";
import { InfoContainer, InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFactLink, InfoItem } from "../Styles";

const CompanyChildOwnerships = ({ childOwnerships, handleNewSearch }) => {
  if (childOwnerships.length) {
    return (
      <InfoBoxWidget $noBox $sectioned style={{ maxHeight: 520, overflow: "auto" }}>
        <InfoBoxWidgetTitleArea>
          <Icon name="boxes" />
          <h3>Ownerships & Subsidiaries</h3>
          <span>{childOwnerships.length} found</span>
        </InfoBoxWidgetTitleArea>
        <InfoContainer $wrapped $compact>
          {childOwnerships.map((child) => (
            <InfoItem
              $compact
              key={child.ownee_identifier.uuid}
              onClick={() => handleNewSearch({ query: child.ownee_identifier.value })}
            >
              <KeyFactLink
                image={`${crunchbaseImageUrl}${child.ownee_identifier.image_id}`}
                title={child.ownee_identifier.value}
                fact={`Type: ${child.ownership_type.toUpperCase()}`}
              />
            </InfoItem>
          ))}
        </InfoContainer>
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyChildOwnerships;

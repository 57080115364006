import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  KeyboardEvent,
  useCallback,
  useImperativeHandle,
  useMemo,
} from "react";
import { Remirror, ReactFrameworkOutput, ThemeProvider, useRemirror } from "@remirror/react";
import { Extension, RemirrorJSON, htmlToProsemirrorNode } from "remirror";
import { HistoryExtension, TextExtension, PositionerExtension } from "remirror/extensions";
import { AllStyledComponent } from "@remirror/styles/styled-components";
import "remirror/styles/all.css";
import sanitizeHtml from "sanitize-html";

import { RefocusExtension } from "./extensions/custom/refocus";

import TextEditor from "./view";
import useThrottle from "utils/useThrottle";

// const Indicator = styled(SocialCharacterCountWrapper)`
//   z-index: 99;
//   &,.remirror-character-count-circle {
//     stroke: ${({ theme }) => theme.primaryColour} !important;
//   }
// `;

const allowedTags = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "blockquote",
  "p",
  "a",
  "ul",
  "ol",
  "nl",
  "li",
  "b",
  "i",
  "strong",
  "em",
  "strike",
  "code",
  "hr",
  "br",
  "div",
  "table",
  "thead",
  "caption",
  "tbody",
  "tr",
  "th",
  "td",
  "pre",
  "iframe",
  "u",
  "s",
  "img",
  "video",
  "span",
];

export const DEFAULT_CONTENT = "<p></p>";

export const sanitiseValue = (value) =>
  sanitizeHtml(value, {
    allowedTags,
    allowedAttributes: {
      a: ["href", "name", "rel", "target", "auto", "class", "data-mention-atom-id", "data-mention-atom-name", "style"],
      img: ["src", "width", "height"],
      div: ["style"], // alignment
      span: ["src", "name", "class", "data-ext", "data-node", "style"], // file
      i: ["class"],
      iframe: ["src", "allowfullscreen", "width", "height", "title", "frameborder", "allow", "referrerpolicy"],
      video: ["src", "allowfullscreen", "width", "height"],
    },
    allowedIframeDomains: ["youtube.com", "vimeo.com", "powerbi.com"],
  });

type IEditorWrapper = {
  value?: string;
  onChange?: (value: string, text: string) => void;
  onChangeJson?: (value: RemirrorJSON) => void;
  onBlur?: (value: string, text: string) => void;
  onFocus?: (params: any, event: any) => void;
  emptyReturnValue?: string;
  wordLimit?: number;
  extensions?: Extension[];
  children?: ReactNode;
  readOnly?: boolean;
  placeholder?: string;
  noShadow?: boolean;
  editorRef?: any;
  handleKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

/* Remirror has an inbuilt "html" string handler which is just mapped to `htmlToProsemirrorNode` exported from `remirror` package. However, the `preserveWhitespace` option defaults to `false` and it cannot be overwritten by a prop.

This is an issue, because it removes leading/trailing whitespaces when we rebuild the state after passing a new value. We only update when the value has changed but in some case (e.g., saving an idea) the API respondes with urls that have a changed signature.

References:
  - https://github.com/remirror/remirror/blob/a0cc38df84d3de7d3fd72ae1804803bf4b76b0de/packages/remirror__core-utils/src/core-utils.ts#L1380
  - https://github.com/remirror/remirror/blob/a0cc38df84d3de7d3fd72ae1804803bf4b76b0de/packages/remirror__core/src/builtins/helpers-extension.ts#L72
*/
const htmlStringHandler = (props) => htmlToProsemirrorNode({ ...props, preserveWhitespace: true });

const EditorWrapper = ({
  value,
  onChange,
  onChangeJson,
  onBlur,
  onFocus,
  emptyReturnValue = DEFAULT_CONTENT,
  extensions,
  children,
  readOnly,
  placeholder,
  noShadow,
  editorRef: propRef,
  handleKeyDown,
}: IEditorWrapper) => {
  const _localRef = useRef<ReactFrameworkOutput<Extension>>();
  const [editorRef] = useState<typeof _localRef>(propRef ?? _localRef);

  const sanitisedValue = useMemo(() => sanitiseValue(value || DEFAULT_CONTENT), [value]);

  // Maintain a previous HTML state to prevent unnecessary updates
  const [previousHTML, setPreviousHTML] = useState<string | undefined>(sanitisedValue);

  const [allExtensions] = useState<() => Extension[]>(() =>
    (
      [
        ...extensions,
        new RefocusExtension({}),
        new PositionerExtension({}),
        new HistoryExtension({}),
        new TextExtension(),
      ] as Extension[]
    ).filter((extension) => !!extension),
  );

  const {
    manager,
    state: remirrorState,
    getContext,
    setState,
  } = useRemirror({
    extensions: allExtensions,
    content: sanitiseValue(value || DEFAULT_CONTENT),
    stringHandler: htmlStringHandler,
    selection: "end",
  });

  useImperativeHandle(editorRef, () => getContext(), [getContext]);

  useEffect(() => {
    const currentHTML = sanitiseValue(editorRef.current?.helpers?.getHTML());
    const newHTML = sanitisedValue;

    if (currentHTML === newHTML || newHTML === previousHTML) {
      return;
    }

    const newState = manager.createState({ content: newHTML, selection: remirrorState.selection });
    manager.view.updateState(newState);
    setPreviousHTML(newHTML);
  }, [sanitisedValue, previousHTML, manager, remirrorState, editorRef]);

  const onBlurHandler = useCallback(
    (change) => {
      if (readOnly) return;
      if (onBlur) {
        const html = change.helpers.getHTML();
        if (html === DEFAULT_CONTENT) {
          setPreviousHTML(DEFAULT_CONTENT);
          onBlur(emptyReturnValue, "");
        } else {
          const text = change.helpers.getText();
          const sanitised = sanitiseValue(html);
          setPreviousHTML(sanitised);
          onBlur(sanitised, text);
        }
      }
    },
    [readOnly, onBlur, emptyReturnValue],
  );

  const onUpdate = useThrottle(
    (html, text) => {
      setPreviousHTML(html || DEFAULT_CONTENT);
      onChange(html, text);
    },
    400,
    [onChange],
  );

  const onUpdateJSON = useThrottle(
    (json) => {
      onChangeJson(json);
    },
    400,
    [onChange],
  );

  const onChangeHandler = useCallback(
    (change) => {
      // Update the internal state with any change
      setState(change.state);

      // External change handler is only called when the actual HTML content changes
      // for this a few checks are necessary to reduce unnecessary calls
      const { tr } = change;
      // Update internal editor state to using the new value

      const metaHistory = tr?.getMeta("history$");

      if (!tr) return;
      if (tr.steps.length === 0) return;
      if (change.internalUpdate) return; // don't report internal updates
      if (metaHistory) return; // don't report history updates (which are disabled but still register as a transaction)
      if (readOnly) return; // read only never reports
      const html = change.helpers.getHTML();
      const sanitised = sanitiseValue(html);
      if (sanitiseValue(value) === sanitised) return;

      if (onChangeJson) {
        onUpdateJSON(change.helpers.getRemirrorJSON());
      }
      if (onChange) {
        // Is get html doing some sanitisation?!
        if (html === DEFAULT_CONTENT) {
          onUpdate(emptyReturnValue, "");
        } else {
          const text = change.helpers.getText();
          onUpdate(sanitised, text);
        }
      }
    },
    [setState, emptyReturnValue, onChange, onChangeJson, onUpdate, onUpdateJSON, readOnly, value],
  );

  return (
    <AllStyledComponent>
      <ThemeProvider>
        <Remirror
          manager={manager}
          initialContent={remirrorState}
          editable={!readOnly}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlurHandler}
          onKeyDown={handleKeyDown}
          onChange={onChangeHandler}
        >
          <div
            style={{
              flex: 1,
              alignSelf: "stretch",
              position: "relative",
              wordBreak: "break-all",
              minHeight: "100px !important",
            }}
          >
            {!readOnly ? <>{children}</> : null}
            <TextEditor noShadow={noShadow} />
          </div>
        </Remirror>
      </ThemeProvider>
    </AllStyledComponent>
  );
};

export default EditorWrapper;

import { List } from "semantic-ui-react";
import styled from "styled-components";

const StyledSearchItem = styled(List.Item)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  cursor: pointer;

  padding: 6px 10px;
  font-size: 1rem;
  line-height: 1rem;
  border-radius: 5px;

  gap: 4px;

  width: 100%;
  margin: 0;
  ${({ $active }) => $active && "background-color: #e9ebee;"}

  i {
    flex: 0 0 auto;
  }

  img {
    margin-right: 0.45rem !important;
  }

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.1rem;
  }
`;

export { StyledSearchItem };

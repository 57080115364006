import React, { useMemo, useCallback } from "react";
import api from "api";
import util from "utils/utils";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import actions from "actions";
import toast from "react-hot-toast";

import TagModalChooser from "components/lib/Choosers/Tags/TagModalChooser";

const IdeaTags = ({ user, challenge, updateIdea, idea, updateTagList, isSubmission = false }) => {
  const currentChallenge = challenge || idea.ownerChallenge;
  const canEdit = useMemo(
    () =>
      util.canEditIdea(user, idea) ||
      util.canManageChallenge(user, currentChallenge) ||
      util.hasPermission(user, "challenge.editIdeaTags", currentChallenge?._id),
    [user, idea, currentChallenge],
  );

  const updateTagListWithTag = useCallback(
    (tag) => {
      const tagList = [...currentChallenge.tagList];
      const index = tagList.findIndex((t) => t._id === tag._id);
      tagList[index] = tag;

      if (challenge) {
        updateTagList(challenge._id, tagList);
      } else {
        updateIdea({ ...currentChallenge, tagList }, ["ownerChallenge"], true);
      }
    },
    [updateTagList, updateIdea, currentChallenge, challenge],
  );

  const addTag = useCallback(
    (tag) => {
      api.tags.tagSingle(
        "ideas",
        tag._id,
        idea._id,
        (updatedTag) => {
          updateIdea(
            (idea) => [...(idea.fullTags || []).filter((t) => t._id !== updatedTag._id), updatedTag],
            ["fullTags"],
            true,
          );
          updateIdea(
            (idea) => [...(idea.tags || []).filter((t) => t !== updatedTag._id), updatedTag._id],
            ["tags"],
            true,
          );
          updateTagListWithTag(updatedTag);
        },
        (err) => toast.error(err.message),
      );
    },
    [idea, updateIdea, updateTagListWithTag],
  );

  const removeTag = useCallback(
    (tag) => {
      api.tags.untagSingle("ideas", tag._id, idea._id, (updatedTag) => {
        updateIdea((idea) => [...idea.fullTags.filter((t) => t._id !== updatedTag._id)], ["fullTags"], true);
        updateIdea((idea) => [...idea.tags.filter((t) => t !== updatedTag._id)], ["tags"], true);
        updateTagListWithTag(updatedTag);
      });
    },
    [idea, updateIdea, updateTagListWithTag],
  );

  if (!currentChallenge) {
    return null;
  }

  return (
    <>
      <TagModalChooser
        trigger={
          !isSubmission && <Button compact icon="hashtag" content="Edit tags" size="tiny" style={{ marginLeft: 3 }} />
        }
        showTags
        tags={idea.fullTags}
        availableTags={currentChallenge.tagList.filter((tag) => !tag?.disabledEntities?.includes("ideas"))}
        addTag={addTag}
        removeTag={removeTag}
        canEdit={canEdit}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const challenge = state.challenges.challenges.filter((c) => c._id === ownProps.idea.challenge)[0];
  return { user: state.user, challenge };
};

const mapDispatchToProps = (dispatch) => ({
  updateTagList: (id, tagList) => dispatch(actions.challenges.update(id, { tagList })),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdeaTags);

import React from "react";
import { useTranslation, Trans } from "react-i18next";

export default function AssessorAssignedContent({ events, ownerChallenge = { name: "Undefined" } }) {
  const allIdeas = [];
  for (let i = 0; i < events.length; i += 1) {
    const event = events[i];
    const eventIdeas = event?.ownerIdeas || [];
    allIdeas.push(...eventIdeas);
  }
  const { t } = useTranslation();
  return (
    <Trans i18nKey="notifications.assessorAssigned">
      You have been assigned to assess the {allIdeas.length > 1 ? `${t("generic.ideas")}` : `${t("generic.idea")}`}
      <b>
        {{
          idea: `${allIdeas.map((i, index) => {
            let name = index < allIdeas.length && index !== 0 ? " " : "";
            name += i.name || "[Deleted]";
            return name;
          })} `,
        }}
      </b>
      in the challenge
      <b> {{ challenge: ownerChallenge.name }}</b>
    </Trans>
  );
}

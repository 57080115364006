import React, { useEffect, useState, Suspense, useMemo } from "react";
import { I18nextProvider } from "react-i18next";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Loader, Button } from "semantic-ui-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import i18n from "src/i18n";

import styled, { ThemeProvider } from "styled-components";
import api from "api";
import actions from "actions";
import util from "utils/utils";
import websocketApi from "api/websocket";
import useTheme from "theme/useTheme";
import toast from "react-hot-toast";

import Color from "color";

import ErrorBoundary from "components/lib/Error/ErrorBoundary";
import AgreeToTerms from "components/lib/Entry/AgreeToTerms";
import VerifyEmail from "components/lib/Entry/VerifyEmail";
import InitialProfileSetup from "components/lib/Entry/InitialProfileSetup";
import OrgTagChooser from "components/lib/Choosers/Tags/OrgTagChooser";
import MessageMoles from "components/lib/Messaging/MessageMolesContainer";
import GoogleHelper from "./lib/GoogleHelper";

import CustomOnboarding from "./lib/CustomOnboarding";
import EventSubscriptions from "./EventSubscriptions";
import EdgeSuggestionModal from "./lib/EdgeSuggestionModal";
import { MountPoint } from "./lib/Modal/MountPoint";

import AppRouting from "./app/AppRouting";
import Toaster from "./app/Toaster";
import SuspenseLoading from "./app/SuspenseLoading";
import { useChatwoot } from "./app/useChatwoot";

import { serviceWorkerBridge } from "../serviceWorkerBridge";
import { AutoBusinessProfileModal } from "./lib/IdeaBusinessProfileModal/AutoBusinessProfileModal";

const disconnectWebsocket = () => {
  websocketApi.disconnect();
};

window.addEventListener("beforeunload", disconnectWebsocket);

// Dashboard styles
export const StyledApp = styled.div`
  &,
  .ui,
  .ui :not(.icon),
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  input,
  p {
    font-family: "Inter";
    -webkit-font-smoothing: antialiased;
  }
  .ui.container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .idea img {
    object-fit: contain;
    max-width: 100%;
  }

  ${({ theme }) => {
    if (!theme) {
      return "";
    }

    let override = `
      a,a:hover{color:${theme.primaryColour};}
      a.with-border{border-bottom:1px dotted ${theme.primaryColour};}
      .primary-text{color: ${theme.primaryColour};}
    `;

    Object.entries(theme.colorMap || {}).forEach(([color, overrideColor]) => {
      override += `
          .ui.${color} {
            border-color: ${overrideColor} !important;
          }
          .ui.${color}.button, .ui.${color}.label {
            color: ${Color(overrideColor).darken(0.5)} !important;
          }
          i.icon.${color}.pastel {
            color: ${overrideColor} !important;
          }
          .ui.msteams.button {
            color: #fff !important;
          }
          .ui.${color}.label:not(.basic), .ui.${color}.button:not(.inverted, .basic) {
            background-color: ${overrideColor} !important;
          }
          .ui.${color}.button:not(.inverted, .basic):hover {
            background-color: ${Color(overrideColor).darken(0.1)} !important;
          }
          i.${color}.icon.inverted.pastel {
            background-color: ${overrideColor} !important;
            color: ${Color(overrideColor).darken(0.5)} !important;
          }
          .ui.${color}.button.inverted {
            background-color: transparent !important;
            box-shadow: 0 0 0 2px ${Color(overrideColor).darken(0.3)} inset !important;
            color: ${Color(overrideColor).darken(0.3)} !important;
          }
          .ui.${color}.button.inverted:hover {
            box-shadow: 0 0 0 2px ${Color(overrideColor).darken(0.5)} inset !important;
            background-color: ${Color(overrideColor).darken(0.3)} !important;
            color: #fff !important;
          }

          .ui.${color}.button.inverted.basic {
            box-shadow: 0 0 0 1px ${Color(overrideColor).darken(0.3)} inset !important;
          }

          .ui.${color}.button.inverted.basic:hover {
            box-shadow: 0 0 0 1px ${Color(overrideColor).darken(0.5)} inset !important;
          }
          `;
    });

    ["primary", "secondary"].map((classify) => {
      const background = theme[`${classify}Colour`];
      const shouldBeWhite = theme[`shouldBeWhiteOn${classify.charAt(0).toUpperCase() + classify.slice(1)}`];

      const color = shouldBeWhite ? "#fff" : "#000";

      override += `
        .ui.button.${classify} {
          background-color: ${background};
          color: ${color};
          outline: 0.5px solid ${Color(background).darken(0.1).hex()};

          &:hover {
            background-color: ${Color(background).darken(0.1).hex()};
            outline: 0.5px solid ${Color(background).darken(0.2).hex()};
          }

          &:active {
            background-color: ${Color(background).darken(0.2).hex()};
          }

          &:active {
            box-shadow: 0 0 3px 1px rgba(0, 0, 0, .15) inset, 0 1px 4px 0 ${Color(background).alpha(0.3).rgb()} inset;
            outline: 0.5px solid ${Color(background).darken(0.4).hex()};
          }

          &.basic:not(.inverted) {
            color: rgba(0, 0, 0, .6) !important;
            box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset !important;
            outline: none !important;

            &:hover {
              background: ${background} !important;
              color: ${color} !important;
              box-shadow: 0 0 0 1px rgba(34, 36, 38, .35) inset, 0 0 0 0 rgba(34, 36, 38, .15) inset !important;
            }

            &:hover:active, &:active {
              background: ${Color(background).darken(0.2).hex()} !important;
              color: ${color} !important;
              box-shadow: 0 0 0 1px rgba(34, 36, 38, .35) inset, 0 0 0 0 rgba(34, 36, 38, .15) inset !important;
            }
          }

          &.inverted:not(.basic) {
            box-shadow: 0 0 0 2px ${background} inset !important;
            color: ${background};
            background-color: transparent;
            background: none;

            &:hover {
              box-shadow: none !important;
              background-color: ${background};
              color: ${color};
            }

            &:active {
              background-color: ${Color(background).darken(0.2).hex()};
            }

            &:active {
              box-shadow: 0 0 3px 1px rgba(0, 0, 0, .15) inset, 0 1px 4px 0 ${Color(background).alpha(0.15).rgb()} inset !important;
              outline: 0.5px solid ${Color(background).darken(0.3).hex()};
            }
          }

          &.inverted.basic {
            box-shadow: 0 0 0 2px ${Color(background).alpha(0.5).rgb()} inset !important;
            outline: 0.5px solid transparent !important;
            color: ${Color(background).darken(0.1).hex()} !important;
            background-color: transparent;
            background: none;

            &:hover {
              box-shadow: 0 0 0 2px ${background} inset !important;
            }

            &:active {
              background-color: ${Color(background).alpha(0.1).rgb()} !important;
            }
          }
        }
      `;
    });

    return override;
  }}
`;

function App({
  user,
  urlOrganisation,
  selectedMessageThreads,
  onReceiveOrganisation,
  onUpdateAvailableOrganisations,
  onReceiveUTMChallenge,
  onReceiveUTMLink,
  clearUtm,
  setClearUtm,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const ownerOrganisation = user?.ownerOrganisation || urlOrganisation;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [params, setParams] = useSearchParams();
  const lang = params.get("lang") || ownerOrganisation?.defaultLanguage;
  const utmLinkReferrer = params.get("utm-link");
  const utmChallengeReferrer = params.get("utm-challenge");
  const userId = user?._id;
  const userHasVerifiedEmail = util.hasVerifiedEmail(user, user?.ownerOrganisation);
  const [resumingSession, setResumingSession] = useState(true);
  const [showingTagChooser, setShowingTagChooser] = useState(null);
  const theme = useTheme();
  const appName = useMemo(() => ownerOrganisation?.appName || "Simply Do", [ownerOrganisation?.appName]);
  const [promptReload, setPromptReload] = useState(true);
  const innoIntelEnabled = useMemo(
    () =>
      util.organisationFeaturesEnabled(user, ["innovationIntelligence"]) &&
      util.hasPermission(user, "org.innovationIntelligence", user.ownerOrganisation._id),
    [user],
  );
  /*
    Initialise Chatwoot plugin for support chat
  */
  useChatwoot();

  useEffect(() => {
    setResumingSession(true);
    api.auth.resumeSession(
      () => setResumingSession(false),
      () => setResumingSession(false),
    );
  }, []);

  useEffect(() => {
    serviceWorkerBridge.navigator = {
      push: navigate,
    };
  }, [navigate]);

  useEffect(() => {
    if (clearUtm) {
      // Clear utm's after a referral has been tracked
      // Used to clear utms after login, resume session and register
      params.delete("utm-challenge");
      params.delete("utm-link");
      setParams(params);
      setClearUtm(false);
    }
  }, [params, setParams, setClearUtm, clearUtm]);

  useEffect(() => {
    if (showingTagChooser === null && user && user?.ownerOrganisation) {
      setShowingTagChooser(util.shouldShowInterestsChooser(user));
    }
  }, [showingTagChooser, user]);

  useEffect(() => {
    const code = util.code();
    if (code) {
      api.organisations.getByCode(code, (newOrganisation) => {
        onReceiveOrganisation(newOrganisation);
      });
    }
  }, [onReceiveOrganisation]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  /*
    UTM is a value associated with a custom URL. We attach these to our sdi.click links, to allow tracking analytics. We can then attribute sign-ups, clicks and views to specific sdi.click links and track timing
  */
  useEffect(() => {
    if (utmLinkReferrer) onReceiveUTMLink(utmLinkReferrer);
  }, [utmLinkReferrer, onReceiveUTMLink]);

  // Check for new version of the app, prompt the user to refresh if one is available
  useEffect(() => {
    if (!api?.webVersion) {
      return;
    }

    if (util.localStorageIsSupported()) {
      const lastHash = localStorage.getItem("lastPromptHash");
      if (lastHash === api.webVersion) {
        setPromptReload(false);
        return;
      }
    }

    if (import.meta.env.VITE_STAGE !== "Dev" && import.meta.env.VITE_GIT_SHA !== api.webVersion && promptReload) {
      setPromptReload(false);
      toast(
        <div>
          <p style={{ fontWeight: "bold", marginBottom: 5 }}>A new version of SimplyDo is available</p>
          <p style={{ fontSize: 17 }}>
            To load the most recent version simply refresh the page. Please make sure you have do not have any unsaved
            changes.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              primary
              icon="refresh"
              onClick={() => {
                if (util.localStorageIsSupported()) {
                  localStorage.setItem("lastPromptHash", api.webVersion);
                }
                window.location.reload();
              }}
              content="Refresh"
            />
          </div>
        </div>,
        { duration: 15000, style: { minWidth: "600px" } },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptReload, api.webVersion]);

  useEffect(() => {
    if (utmChallengeReferrer) onReceiveUTMChallenge(utmChallengeReferrer);
  }, [utmChallengeReferrer, onReceiveUTMChallenge]);

  /*
    Track page views when the page changes, and scroll to top if moving to challenge/idea pages
  */
  useEffect(() => {
    const regExr = new RegExp(/\/challenges|ideas\/([A-z0-9]+)\w+/g);
    if (!regExr.test(window.location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userId && userHasVerifiedEmail) {
      api.users.getOrganisations(userId, (orgs) => {
        onUpdateAvailableOrganisations(orgs);
      });
    }
  }, [userId, userHasVerifiedEmail, onUpdateAvailableOrganisations]);
  if (resumingSession) return <Loader active inline="centered" style={{ marginTop: 30 }} />;
  if (user && !util.hasAgreedTerms(user)) return <AgreeToTerms />;
  if (user && !userHasVerifiedEmail && window.location.pathname !== "/verify-email") return <VerifyEmail />;
  if (user && !(user.profile?.firstName && user.profile?.lastName)) return <InitialProfileSetup />;
  if (user && !user.hasCompletedOrganisationOnboarding) return <CustomOnboarding />;
  if (showingTagChooser) return <OrgTagChooser setVisible={setShowingTagChooser} />;
  return (
    <ErrorBoundary theme={theme}>
      <I18nextProvider i18n={i18n} defaultNS={"translation"}>
        <ThemeProvider theme={theme}>
          <StyledApp theme={theme}>
            <DndProvider backend={HTML5Backend}>
              <EventSubscriptions />
              <EdgeSuggestionModal />
              <Helmet defaultTitle={appName} titleTemplate={`%s | ${appName}`}>
                {import.meta.env.VITE_CSP && (
                  <meta httpEquiv="Content-Security-Policy" content={`${import.meta.env.VITE_CSP}`} />
                )}
                <link rel="shortcut icon" type="image/png" href={util.orgFavicon(ownerOrganisation)} />
              </Helmet>
              <Suspense fallback={<SuspenseLoading />}>
                <AppRouting />
              </Suspense>
              <MessageMoles selectedMessageThreads={selectedMessageThreads} />
              <Toaster />
              {innoIntelEnabled && (
                <GoogleHelper
                  displayOnPages={["^/innovationintelligence"]}
                  containerStyle={{
                    bottom: 20,
                    right: 20,
                  }}
                />
              )}
              <AutoBusinessProfileModal />
              <MountPoint />

              <div id="semantic-modal-mount-node" />
            </DndProvider>
          </StyledApp>
        </ThemeProvider>
      </I18nextProvider>
    </ErrorBoundary>
  );
}

const mapStateToProps = (state) => {
  const { user } = state;
  const { selectedMessageThreads, messageThreads } = state.messages;
  const { urlOrganisation } = state.organisations;
  const { clearUtm } = state.auth;
  return {
    user,
    selectedMessageThreads,
    messageThreads,
    urlOrganisation,
    clearUtm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onReceiveOrganisation: (org) => dispatch(actions.organisations.receiveUrlOrganisation(org)),
  onUpdateAvailableOrganisations: (orgs) => dispatch(actions.user.updateAvailableOrganisations(orgs)),
  onReceiveUTMLink: (utm) => dispatch(actions.auth.setUtmLink(utm)),
  onReceiveUTMChallenge: (utm) => dispatch(actions.auth.setUtmChallenge(utm)),
  setClearUtm: (shouldClear) => dispatch(actions.auth.setClearUtm(shouldClear)),
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;

import { Context } from "react";
import {
  CompanyReducerState,
  RawCompanyAction,
  CompanyAction,
  RawListAction,
  initLists,
  ListTypes,
} from "./companyReducer";

type CompanyClientInput<T extends string> = {
  name: T;
  activePath: string;
  allowMultipleCompanyStates?: boolean;
};

class CompanyClient<T extends string> {
  states: CompanyClientInput<T>[];

  stateContext: Context<{
    companiesState: CompanyReducerState<T>;
  }>;

  dispatchContext: Context<{
    dispatchListAction: (forList: ListTypes, data: ReturnType<RawListAction>) => void;
    initLists: (...data: Parameters<typeof initLists>) => void;
    dispatchToState: Record<T, (data: ReturnType<RawCompanyAction>) => void>;
    dispatch: (data: CompanyAction<T>) => void;
  }>;

  constructor(states: CompanyClientInput<T>[]) {
    this.states = states;
  }
}

export { CompanyClient };

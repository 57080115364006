import util from "utils/utils";
import api from "api";
import { DefaultPreset } from "../../Remirror/extensions/default";
// Fine here because of popup
import { t } from "i18next";

const uploadFile = (file, uploadOptions) => {
  const regex = new RegExp("[^a-zA-Z0-9_.]", "g");
  const name = file.name.replace(regex, "-");
  const { forType, forId } = uploadOptions;

  return new Promise((resolve, reject) => {
    api.uploads.generateFileUploadRequest(
      {
        forType,
        forId,
        name,
        size: file.size,
        type: file.type,
      },
      (response) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", response.signedRequest);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.upload.addEventListener("progress", (e) => {
          if (e.lengthComputable) {
            // progress
          }
        });
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(response.signedDownloadRequest);
            } else {
              reject();
            }
          }
        };
        xhr.send(file);
      },
      () => {
        reject();
      },
    );
  });
};

const FullPreset = [
  ...DefaultPreset,
  { name: "list" },
  { name: "bulletList" },
  { name: "orderedList" },
  { name: "bold" },
  { name: "strike" },
  { name: "italic" },
  { name: "underline" },
  { name: "image", config: { uploadFile, enableResizing: true, preferPastedTextContent: false } },
  {
    name: "mention",
    config: {
      char: "@",
      name: "users",
      formatMention: (user) => ({
        href: `/users/${user._id}`,
        id: user._id,
        label: user?.profile?.fullName,
        description: user?.profile?.department || user?.profile?.jobTitle || "User",
        image: util.avatarUrl(user),
      }),
    },
  },
  {
    name: "mention",
    config: {
      char: "$",
      name: "ideas",
      formatMention: (idea) => ({
        href: `/ideas/${idea._id}`,
        id: idea._id,
        label: idea?.name,
        description: `${t("common:capitalise", { key: "generic.idea" })} for ${idea?.ownerChallenge?.name}`,
        image: util.ideaCoverImage(idea),
      }),
    },
  },
  {
    name: "mention",
    config: {
      char: "$",
      name: "challenges",
      formatMention: (challenge) => ({
        href: `/challenges/${challenge._id}`,
        id: challenge._id,
        label: challenge?.name,
        description: `${challenge.stage === "published" ? "Open" : "Closed"} ${t("generic.challenge")}`,
        image: util.getImageUrl(challenge, ["coverImage", "bannerImage"], ["200x200"]),
      }),
    },
  },
  {
    name: "mention",
    config: {
      char: "$",
      name: "organisations",
      formatMention: (org) => ({
        href: `//${org.code}.simplydo.co.uk`,
        id: org._id,
        label: org?.name,
        description: "Organisation",
        image: org?.darkLogoUrl,
      }),
    },
  },
  {
    name: "mention",
    config: {
      char: "$",
      name: "groups",
      formatMention: (group) => ({
        href: `/groups/${group._id}`,
        id: group._id,
        label: group?.name,
        description: "Group",
        image: util.groupCoverImage(group),
      }),
    },
  },
  { name: "mention", config: { name: "emoji" } },
  { name: "alignment" },
  { name: "video", config: { minWidth: 322, minHeight: 172, resizable: true } },
  { name: "iframe", config: { enableResizing: true } },
  {
    name: "file",
    config: {
      onClick: (src) => {
        window.open(src, "_blank");
      },
      uploadFile,
    },
  },
  {
    name: "link",
    config: {
      autoLink: true,
      priority: 50,
      autoLinkRegex:
        /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\da-z]+([.-][\da-z]+)*((\.[a-z]{2,5})|(:\d{1,5}))(\/.*)?)/gi,
    },
  },
];

export default FullPreset;

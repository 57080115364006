import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { InfoBoxWidget, InfoBoxWidgetTitleArea, TRLIndicators } from "../Styles";

const CompanyTRL = ({ trl, isPDF }) => {
  if (trl) {
    return (
      <InfoBoxWidget>
        <InfoBoxWidgetTitleArea>
          <Icon name="cogs" />
          <h3>Estimated TRL</h3>
        </InfoBoxWidgetTitleArea>
        <span style={{ marginBottom: "1rem" }}>
          We estimated this company's technology readiness level (TRL) based on a number of known factors. Our estimates
          are indicated by a TRL
          <em>range</em>.
        </span>
        <TRLIndicators trl={trl} />
        {!isPDF ? (
          <div style={{ textAlign: "center" }}>
            <Button
              secondary
              size="tiny"
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://ec.europa.eu/research/participants/data/ref/h2020/wp/2014_2015/annexes/h2020-wp1415-annex-g-trl_en.pdf"
            >
              What is TRL?
            </Button>
          </div>
        ) : null}
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyTRL;

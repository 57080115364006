export const persistentTokens = (api) => ({
  get(forType: string, forId: string, forEntity: string, success, fail) {
    api.authenticatedRequest("GET", `/persistent-tokens/${forType}/${forId}/${forEntity}`, null, success, fail);
  },
  create(data: { forType: string; forId: string; forEntity: string }, success, fail) {
    api.authenticatedRequest("POST", "/persistent-tokens", data, success, fail);
  },
  delete(id: string, success, fail) {
    api.authenticatedRequest("DELETE", `/persistent-tokens/${id}`, null, success, fail);
  },
  invalidate(id: string, success, fail) {
    api.authenticatedRequest("POST", `/persistent-tokens/${id}/invalidate`, null, success, fail);
  },
  getByToken(token: string, success, fail) {
    api.maybeAuthenticatedRequest("GET", `/persistent-tokens/redeem/${token}`, null, success, fail);
  },
  redeem(token: string, success, fail) {
    api.authenticatedRequest("POST", `/persistent-tokens/redeem/${token}`, null, success, fail);
  },
});

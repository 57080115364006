import React, { useMemo } from "react";
import GroupInvite from "./Group";
import IdeaInvite from "./Idea";
import IdeaBusinessProfileInvite from "./IdeaBusinessProfile";
import RequestJoinIdea from "./RequestJoinIdea";
import IdeaAssessorInvite from "./IdeaAssessor";
import RequestJoinGroup from "./RequestJoinGroup";
import ProjectInviteCard from "./ProjectInvited";
import { InviteCardProps } from "./types";

import styled from "styled-components";

const NoteText = styled.span`
  font-size: 14px;
  color: #718096;
  white-space: pre-wrap;
`;

// Overwrite internal handling of the invitation button to allow wrapping if needed
const Card = styled.div`
  .content.extra .ui {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
`;

const InviteCard = ({ invitation, acceptInvite, rejectInvite, removeInvite }: InviteCardProps) => {
  const CardType = useMemo(() => {
    switch (invitation.forType) {
      case "group":
        return GroupInvite;
      case "idea":
        return IdeaInvite;
      case "ideaBusinessProfile":
        return IdeaBusinessProfileInvite;
      case "requestJoinIdea":
        return RequestJoinIdea;
      case "ideaAssessor":
        return IdeaAssessorInvite;
      case "requestJoinGroup":
        return RequestJoinGroup;
      case "projectInvited":
        return ProjectInviteCard;
      default:
        return null;
    }
  }, [invitation]);

  return (
    <React.Fragment>
      {CardType ? (
        <Card>
          <CardType
            invitation={invitation}
            acceptInvite={acceptInvite}
            rejectInvite={rejectInvite}
            removeInvite={removeInvite}
          />
          {!invitation.acceptedBy && invitation.note ? <NoteText>"{invitation.note}"</NoteText> : null}
        </Card>
      ) : null}
    </React.Fragment>
  );
};

export default InviteCard;

import { useMemo, useState } from "react";
import { Grid } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import Cell from "./Cell";

type CalendarType = OpenAPI.GET<"/challenges/{id}/calendar">["response"]["calendar"];
type Event = CalendarType["events"][0];

type CalendarWithDates = Omit<CalendarType, "start" | "end"> & {
  start: Date;
  end: Date;
};

export type EventWithIndex = Omit<Event, "from" | "to"> & {
  from: Date;
  to?: Date;
  index: number;
};

// MARK: Date helpers
export const datePlusDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const dateStart = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const dateEnd = (date) => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

export type CellAction = {
  name: string;
  onlyVisibleInRange?: boolean;
  action: (dayDate: string) => Event;
};

type CalendarProps = CalendarWithDates & {
  deleteEvent: (eventId: string, eventType: string) => void;
  leaveEvent: (eventId: string) => void;
  setEditingEvent: (event: { from: Date; type: string; id: string }) => void;
  editable: boolean;
  cellActions: Array<CellAction>;
};

const Calendar = ({
  events,
  start: startDate,
  end: endDate,
  deleteEvent,
  leaveEvent,
  setEditingEvent,
  editable,
  cellActions,
}: CalendarProps) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Add index and create date objects from range strings
  const alignedEvents = useMemo<EventWithIndex[]>(() => {
    const withDates = events.map((event) => ({
      ...event,
      from: new Date(event.from),
      to: new Date(event.to ?? event.from),
    }));

    withDates.sort((a, b) => a.from.getTime() - b.from.getTime());

    const counts = {};
    const indexedEvents = withDates.flatMap((event) => {
      const aligned: EventWithIndex = { index: 0, ...event };

      if (aligned.eventType === "background") {
        return aligned;
      }
      const eventDays = (aligned.to.getTime() - aligned.from.getTime()) / (1000 * 60 * 60 * 24);

      for (let i = 0; i <= eventDays; i++) {
        const currDate = datePlusDays(aligned.from, i).toDateString();
        counts[currDate] = counts[currDate] || 0;

        if (i === 0) {
          aligned.index = counts[currDate];
        }

        counts[currDate]++;
      }
      return aligned;
    });
    return indexedEvents;
  }, [events]);

  const [start, startOffset] = useMemo(() => {
    let asDate = startDate;

    // pad so that first day is a monday
    const startDay = asDate.getDay();
    if (startDay !== 1) {
      asDate = datePlusDays(asDate, 1 - startDay);
    }
    return [asDate, startDay - 1];
  }, [startDate]);

  const end = endDate;

  const daysRange = useMemo(
    () => 1 + Math.floor((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)),
    [start, end],
  );

  return (
    <Grid celled>
      <Grid.Row columns={6}>
        <Grid.Column>
          <b>Mon</b>
        </Grid.Column>
        <Grid.Column>
          <b>Tue</b>
        </Grid.Column>
        <Grid.Column>
          <b>Wed</b>
        </Grid.Column>
        <Grid.Column>
          <b>Thu</b>
        </Grid.Column>
        <Grid.Column>
          <b>Fri</b>
        </Grid.Column>
        <Grid.Column>
          <b>Sat/Sun</b>
        </Grid.Column>
      </Grid.Row>
      {Array.from({ length: Math.ceil(daysRange / 7) }, (_, weekNum) => {
        return (
          <Grid.Row
            key={`week-${weekNum}`}
            style={{
              minHeight: 100,
            }}
            columns={6}
          >
            {Array.from({ length: 7 }, (_, dayNum) => {
              const dayCounter = weekNum * 7 + dayNum;

              // These props are the same for regular and weekend cells
              const sharedProps = {
                weekNum,
                totalDays: daysRange - startOffset,
                deleteEvent,
                leaveEvent,
                onSelectEvent: setSelectedEvent,
                selectedEvent,
                cellActions,
                setEditingEvent,
                editable,
              };
              // Render weekend inside one cell
              if (dayNum === 5) {
                return (
                  <Grid.Column style={{ padding: 0 }} key={`weekend-${weekNum}`}>
                    <Grid celled="internally" style={{ height: "100%" }}>
                      <Grid.Row columns={2}>
                        {Array.from({ length: 2 }, (_, weekendDayNum) => {
                          const weekendDayCounter = dayCounter + weekendDayNum;
                          const weekendDayStart = dateStart(datePlusDays(start, weekendDayCounter));

                          const weekendDayEnd = dateEnd(weekendDayStart);

                          const weekendEvents = alignedEvents.filter(
                            (event) => event.to >= weekendDayStart && event.from <= weekendDayEnd,
                          );

                          return (
                            <Cell
                              key={`weekend-${weekNum}-${weekendDayNum}`}
                              dayDate={weekendDayStart}
                              daysIntoCalendar={weekendDayCounter - startOffset}
                              dayNumOfTheWeek={dayNum + weekendDayNum}
                              events={weekendEvents}
                              {...sharedProps}
                            />
                          );
                        })}
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                );
              }
              if (dayNum === 6) {
                return null;
              }
              const dateDayStart = dateStart(datePlusDays(start, dayCounter));
              const dateDayEnd = dateEnd(dateDayStart);

              const events = alignedEvents.filter((event) => event.to >= dateDayStart && event.from <= dateDayEnd);
              return (
                <Cell
                  key={`day-${weekNum}-${dayNum}`}
                  dayDate={dateDayStart}
                  daysIntoCalendar={dayCounter - startOffset}
                  dayNumOfTheWeek={dayNum}
                  events={events}
                  {...sharedProps}
                />
              );
            })}
          </Grid.Row>
        );
      })}
    </Grid>
  );
};

export default Calendar;

import React, { Component } from "react";
import { Label, Radio, Header } from "semantic-ui-react";

class RadioHeader extends Component {
  click = () => {
    if (this.props.onChange) {
      this.props.onChange(true);
    } else if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const {
      as,
      label,
      label2,
      name,
      value,
      checked,
      onChange,
      header,
      content,
      style = {},
      onClick = () => {},
      disabled,
    } = this.props;
    return (
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          flex: "auto",
          ...style,
        }}
      >
        <div style={{ textAlign: "center", paddingTop: 10 }}>
          <Radio
            disabled={disabled}
            label={null}
            name={name}
            value={value}
            checked={checked}
            onClick={onClick}
            onChange={(e, c) => onChange && onChange(c.checked)}
          />
        </div>
        <div style={{ paddingLeft: 15 }} onClick={disabled ? () => {} : this.click}>
          <Header as={as} style={{ marginBottom: 5, cursor: !disabled && "pointer" }}>
            {header} {label && <Label>{label}</Label>}
            {label2 && <Label>{label2}</Label>}
          </Header>
          <div>{content}</div>
        </div>

        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

export default RadioHeader;

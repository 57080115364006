import defaultExtensions from "./default";
import { createMentionExtension } from "./custom/mention";
import { createFileExtension } from "./custom/file";
import { createVideoExtension } from "./custom/video";
import { createAlignmentExtension } from "./custom/alignment";

const customExtensions = {
  mention: createMentionExtension,
  file: createFileExtension,
  video: createVideoExtension,
  alignment: createAlignmentExtension,
};

const createExtension = (name, options) => {
  if (!name) {
    throw new Error('Extension requires "name" property.');
  }

  if (name in defaultExtensions) {
    // For default extension just use the last config
    const usedOption = options[options.length - 1];
    return defaultExtensions[name](usedOption);
  }

  if (name in customExtensions) {
    return customExtensions[name](options);
  }

  throw new Error(`Extension with "${name}" does not exist.`);
};

export default createExtension;

import styled from "styled-components";
import { Modal } from "semantic-ui-react";

const IdeaListContainer = styled.table`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  > tbody > tr > td {
    padding: 0;
    margin: 0;
  }
`;

const StyledModal = styled(Modal)`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const ActionPopup = styled.div`
  .ui.menu {
    margin: 0;
    min-width: 350px;
    > a {
      min-width: 350px;
    }
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      margin-right: 5px;
    }
    i.icon {
      height: 1.4em;
    }
    span {
      display: block;
    }
    margin: 0 2.5px;
    border-bottom: 1px solid #dddddd;
    &:hover {
      background-color: rgba(211, 211, 211, 0.3);
    }
    :last-of-type {
      border-bottom: none;
    }
    .stamp-container {
      display: flex;
      flex-direction: column;
      span {
        display: block;
        margin-bottom: 3px;
      }
      &:hover {
        background-color: #fff;
      }
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
`;

const DeleteNameContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const DeleteNameItem = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export { IdeaListContainer, StyledModal, ActionPopup, DeleteNameContainer, DeleteNameItem };

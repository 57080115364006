import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Container, Popup, Button, Dropdown } from "semantic-ui-react";
import { Sticky } from "components/lib/UI";
import util from "utils/utils";
import moment from "moment";
import IdeaPDFExport from "components/lib/PDFExport/IdeaPDFExport";
import IdeaDocxExport from "components/lib/IdeaDocxExport";

const IdeaToolbarDiv = styled.div`
  min-height: ${({ theme }) => (theme.sizes.isMobile ? 30 : 40)}px;
  background-color: #f0f0f0;
  border-bottom: 1px solid lightgray;
  top: 50px;
  width: 100%;
`;

const ToolbarContainer = styled(Container)`
  padding: 5px 0 5px 0;
  display: flex;
  flex-direction: row-reverse !important;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $verticalAlign }) => $verticalAlign || "flex-start"};
  align-items: ${({ $horizontalAlign }) => $horizontalAlign || "center"};
  flex-wrap: wrap;
`;

const IdeaEditToolbar = ({
  t,
  idea,
  user,
  isEditing,
  undoChanges,
  redoChanges,
  canUndo,
  canRedo,
  savedAt,
  deleteIdea,
}) => {
  const theme = useContext(ThemeContext);
  if (!isEditing) return null;
  return (
    <Sticky top={theme.sizes.isMobile ? 90 : 100} style={{ zIndex: 90 }}>
      <IdeaToolbarDiv>
        <ToolbarContainer>
          <HorizontalDiv $verticalAlign="space-between">
            <HorizontalDiv />
            <HorizontalDiv>
              <Popup
                content="Undo changes"
                on="hover"
                trigger={
                  <Button
                    size="mini"
                    icon="undo"
                    onClick={undoChanges}
                    disabled={!canUndo}
                    style={{ marginRight: 10 }}
                    basic
                  />
                }
              />
              <Popup
                content="Redo changes"
                on="hover"
                trigger={
                  <Button
                    size="mini"
                    icon="redo"
                    onClick={redoChanges}
                    disabled={!canRedo}
                    style={{ marginRight: 10 }}
                    basic
                  />
                }
              />
              <Dropdown icon="settings" direction="left">
                <Dropdown.Menu>
                  {user ? (
                    <IdeaPDFExport
                      trigger={<Dropdown.Item icon="file pdf" text="Download as PDF" />}
                      ideas={[idea]}
                      challenge={idea.ownerChallenge}
                    />
                  ) : null}
                  {user && !idea.isSubmitted ? (
                    <IdeaDocxExport
                      trigger={<Dropdown.Item icon="file word" text="Download as Word document" />}
                      ideas={[idea]}
                      challenge={idea.ownerChallenge}
                    />
                  ) : null}
                  {!idea?.ownerChallenge?.preventIdeaCoverImages && idea?.coverImage ? (
                    <Dropdown.Item
                      as="a"
                      href={idea.coverImageUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      content="Download cover image"
                      icon="download"
                    />
                  ) : null}
                  <Dropdown.Item
                    icon="trash"
                    basic
                    text={`Delete ${t("generic.idea")}`}
                    onClick={() => deleteIdea(!user)}
                  />
                </Dropdown.Menu>
              </Dropdown>
              {util.canEditIdea(user, idea) && !idea.isSubmitted && !theme.sizes.isMobile && (
                <Button
                  style={{ marginLeft: 10 }}
                  size="mini"
                  icon="save"
                  content={
                    savedAt ? t("generic.savedAt", { time: moment(savedAt).format("hh:mma") }) : t("generic.save")
                  }
                  onClick={() => {
                    toast(`Your ${t("generic.idea")} is automatically saved in the background as you work.`);
                  }}
                />
              )}
            </HorizontalDiv>
          </HorizontalDiv>
        </ToolbarContainer>
      </IdeaToolbarDiv>
    </Sticky>
  );
};

export default withTranslation()(IdeaEditToolbar);

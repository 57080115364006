import { ExtensionTag, NodeExtension, getActiveNode, toggleWrap, lift, wrapIn } from "remirror";

export class AlignmentExtension extends NodeExtension {
  name = "alignment";

  tags = [ExtensionTag.BlockNode];

  defaultOptions = { directions: ["left", "center", "right"] };

  createNodeSpec(extra) {
    return {
      content: "block*",
      draggable: false,
      attrs: {
        ...extra.defaults(),
        direction: "left",
      },
      parseDOM: [
        {
          tag: "p",
          priority: 105,
          getAttrs: (el) => {
            const hasAlignment = this.defaultOptions.directions.includes(el.style?.textAlign);
            if (!hasAlignment) return false;
            return { ...extra.parse(el), direction: el.style?.textAlign };
          },
        },
        {
          tag: "div",
          priority: 45,
          getAttrs: (el) => {
            const hasAlignment = this.defaultOptions.directions.includes(el.style?.textAlign);
            if (!hasAlignment) return false;
            return { ...extra.parse(el), direction: el.style?.textAlign };
          },
        },
      ],
      toDOM: (node) => ["div", { ...extra.dom(node), style: `text-align: ${node.attrs.direction};` }, 0],
    };
  }

  createCommands() {
    return {
      toggleAlignment: (attrs) => toggleWrap(this.type, attrs),
      removeAlignment: () => (param) => {
        const { tr } = param;
        const activeNode = getActiveNode({ state: tr, type: this.type });
        if (activeNode) {
          return lift(param);
        }
        return false;
      },
      setAlignment:
        ({ direction }) =>
        (param) => {
          const { tr } = param;
          const activeNode = getActiveNode({ state: tr, type: this.type });
          if (activeNode) {
            const lifted = lift(param);
            if (activeNode.node.attrs.direction === direction) {
              return lifted;
            }
          }
          return wrapIn(this.type, { direction })(param);
        },
    };
  }
}

export const createAlignmentExtension = (options) => new AlignmentExtension(options[0]);

import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { Icon, Popup, Modal } from "semantic-ui-react";

export const StyledModal = styled(Modal)`
  ${({ theme }) =>
    theme.sizes.isMobile
      ? `
    width: 100vw !important;
    margin: 0 !important;
    left: 0;
    top: 0;
    border-radius: 0 !important;
  `
      : `
    width: 75% !important;
    margin: 50px 0 !important;
  `}
`;

export const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => (!theme.sizes.isComputer ? "1rem" : "2rem")};
  h3 {
    color: gray;
    font-size: 1.7rem;
  }
  h1 {
    font-size: 1.9rem;
    font-weight: 700;
  }
  span {
    font-size: 1.1rem;
    color: #343434;
  }
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .ui.button {
      &:not(:last-of-type) {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const RowFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0;
  max-width: ${({ theme }) => (!theme.sizes.isComputer ? "100%" : "50%")};
  img,
  i.icon {
    width: 100px;
    height: ${({ theme }) => (theme.sizes.isMobile ? "50px" : "100px")};
    object-fit: contain;
    display: block;
    margin-right: 1rem;
  }
  i.icon {
    position: relative;
    top: 21px;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  h3,
  h1,
  span {
    display: block;
    margin: 0 0 0.25rem;
  }

  .meta {
    font-style: italic;
    font-size: 1.2rem;
    color: #5a5a5a;
  }
`;

export const UnlinkCompanyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > h5 {
    font-weight: normal;
    margin: 0;
  }
`;

export const AlternativeCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  > h5 {
    display: block;
    margin: 0;
  }
  > span {
    display: block;
    margin: 0;
    opacity: 0.8;
    font-size: 1rem;
    a {
      color: black;
      opacity: 0.8;
      text-decoration-line: underline;
    }
  }
  > .choose-alternate-option {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0;
    > .ui.button {
      width: 175px;
      height: 40px;
    }
  }
  .description {
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .text {
    max-width: 30%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const AlternativeCompanyCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  > .alt-company-info {
    display: flex;
    > img {
      height: 50px;
      width: 75px;
      border-radius: 5px;
      margin-right: 10px;
      object-fit: contain;
    }
    .alt-company-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .alt-company-title {
        display: block;
      }
      .alt-company-description {
        display: block;
        opacity: 0.8;
      }
    }
  }
`;

export const InfoContainer = styled.div<{ $wrapped?: boolean; $sectioned?: boolean }>`
  display: flex;
  flex-direction: ${({ theme }) => (theme.sizes.isComputer ? "row" : "column")};
  align-items: flex-start;
  max-width: 100%;
  ${({ theme }) =>
    theme.sizes.isMobile &&
    `
    width: 100%;
  `}
  ${({ $wrapped }) => $wrapped && "flex-flow: row wrap;"}
  ${({ $sectioned }) =>
    $sectioned &&
    `
    margin-top: 10px;
    padding-top: 10px;
  `}
`;

export const InfoBox = styled.div<{ $wrapped?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ $wrapped }) => ($wrapped ? "0.5" : "1")};
  max-width: 100%;
  ${({ theme }) =>
    theme.sizes.isMobile &&
    `
    width: 100%;
  `}
  > span, .meta {
    color: #343434;
    margin-bottom: 1rem;
  }
  > .meta {
    font-style: italic;
  }
  > h1 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  &:not(:last-of-type) {
    ${({ theme }) =>
      theme.sizes.isComputer &&
      `
      padding-right: 1rem;
      margin-right: 1rem;
      border-right: 1px solid lightgray;
    `}
  }
  .boxes {
    display: flex;
    flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
    margin-top: 1rem;
    ${({ theme }) =>
      theme.sizes.isMobile &&
      `
      width: 100%;
    `}
  }
  .attribution {
    color: gray;
    > a {
      color: gray;
      font-weight: 600;
      text-decoration-line: underline;
    }
  }
`;

const StyledInfoItem = styled.a<{ $compact?: boolean }>`
  display: block;
  ${({ theme, $compact }) =>
    !theme.sizes.isComputer
      ? `
    flex: 0 100%;
  `
      : `
    flex: 0 calc(${$compact ? "33" : "50"}% - 0.5rem);
    &:not(:nth-of-type(${$compact ? "3" : "2"}n)) {
      margin-right: 1rem;
    }
  `}
`;

export const InfoItem = (props) => <StyledInfoItem target="_blank" rel="noopener noreferrer" {...props} />;

export const InfoBoxWidget = styled.div<{ $noBox?: boolean; $relaxed?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  margin: 10px 0;
  ${({ theme }) =>
    theme.sizes.isMobile &&
    `
    width: 100%;
  `}
  &:not(:first-of-type) {
    ${({ theme }) => theme.sizes.isComputer && "margin-left: 20px"};
  }
  ${({ $noBox, theme }) =>
    !$noBox &&
    `
    padding: ${theme.sizes.isMobile ? "15px" : "20px"};
    -webkit-box-shadow: 2px 2px 6px 2px #d3d3d3;
    box-shadow: 2px 2px 6px 2px #d3d3d3;
    border-radius: 5px;
  `}
  ${({ $relaxed }) =>
    $relaxed &&
    `
    padding: 0;
  `}
  > h3 {
    color: #000;
  }
  p {
    font-size: 1.2em;
  }
  span {
    font-size: 1em;
  }
  .attribution {
    color: gray;
    > a {
      color: gray;
      font-weight: 600;
      text-decoration-line: underline;
    }
  }
`;

export const InfoBoxWidgetTitleArea = styled.div<{
  $alignItems?: string;
  $noMargin?: boolean;
  $flexDirection?: string;
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: ${({ $alignItems }) => $alignItems || "flex-end"};
  gap: 10px;
  ${({ $flexDirection }) =>
    $flexDirection &&
    `
    flex-direction: ${$flexDirection};
  `}
  margin-bottom: 15px;
  > h3 {
    color: #000;
    display: block;
    margin: 0 5px 0 0;
    line-height: 0.9em;
  }
  > span {
    display: block;
    color: gray;
  }
  > i.icon {
    font-size: 1.5em;
    margin-right: 10px;
  }
`;

export const ContactInfoWidget = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: gray;
  margin-bottom: 1rem;
  word-break: break-all;
  overflow: hidden;
  overflow-wrap: break-word;
  i.icon {
    margin-right: 0.75rem;
    display: block;
  }
  span.icon-alt {
    font-weight: bold;
    margin-right: 1.15rem;
    font-size: 1.4rem;
    margin-left: 6px;
  }
  a,
  span {
    color: #343434;
    display: block;
  }
  a {
    text-decoration-line: underline;
  }
  .contact-info-meta {
    opacity: 0.7;
    display: flex;
    flex-direction: row;
    align-items: center;
    > span {
      margin-bottom: 0;
      font-size: 0.85rem;
    }
    > i.icon {
      margin-left: 2.5px;
    }
  }
`;

export const MetaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  margin-top: 1rem;
  span {
    color: gray;
    a {
      color: gray;
      text-decoration-line: underline;
    }
  }
  .action-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .ui.button {
    height: 36px;
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
  .ui.dropdown {
    margin-right: 1rem;
    max-width: 50%;
  }
  > .ui.active.loader {
    position: relative;
  }
  > .ui.loader::after {
    border-color: #767676 transparent transparent !important;
    margin-left: 5px;
  }
`;

export const StyledKeyFact = styled.div<{ $hasImage?: boolean; $underlined?: boolean }>`
  display: flex;
  flex: 0 1 50%;

  flex-direction: ${({ $hasImage }) => ($hasImage ? "row" : "column")};
  align-items: ${({ $hasImage }) => ($hasImage ? "center" : "flex-start")};
  margin-bottom: 1rem;
  img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    margin-right: 7.5px;
    border-radius: 5px;
  }
  > .sub-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  h5 {
    font-size: 1.2rem;
    margin-bottom: 0.25rem;
  }
  span,
  a {
    font-size: 1.1rem;
  }
  .info {
    text-decoration-line: underline;
    color: gray;
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  .fact {
    margin-top: 2.5px;
    font-size: 1rem;
    ${({ $underlined }) => $underlined && "text-decoration-line: underline;font-weight: bold;"}
  }
  ${({ onClick }) =>
    onClick &&
    `
    cursor: pointer;
  `}
`;

export const StyledKeyFactLink = styled(StyledKeyFact)`
  display: flex;
  flex-direction: row;

  border-radius: 5px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
  h5 {
    font-weight: 400;
  }
  span {
    color: gray;
    font-size: 1rem;
  }
  .fake-link {
    color: #000;
    text-decoration-line: none;
  }
  .subtitle {
    color: #000;
  }
  .fact {
    margin-top: 5px;
    font-size: 0.9rem;
  }
  > .image-container {
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    > * {
      display: block;
    }
  }
`;

export const KeyFactLinkSeeMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > a,
  span {
    text-decoration-line: underline;
    color: gray;
  }
  .ui.dimmer .ui.loader::after {
    border-color: gray transparent transparent !important;
  }
  .ui.loader::after {
    border-color: #767676 transparent transparent !important;
  }
`;

export const AcquireSpan = styled.span<{ $canClick?: boolean }>`
  font-weight: bold;
  font-size: 1.3em !important;
  > span {
    ${({ $canClick }) =>
      $canClick
        ? `
        font-size: 1em !important;
        color: #5e5ef8;
        cursor: pointer;
    `
        : null}
  }
`;

export const KeyFact = ({ title, fact, info, verified, notVerified, noEllipsis, underlined, onClick }) => (
  <StyledKeyFact $underlined={underlined} onClick={onClick}>
    <h5>
      {title}
      {!noEllipsis ? "" : ""}
    </h5>
    <span className="fact">
      {(verified || notVerified) && (
        <Icon
          name={verified ? "check circle" : "exclamation circle"}
          color={verified ? "green" : "orange"}
          style={{ marginRight: 2.5 }}
        />
      )}
      {fact}
    </span>
    {info && (
      <Popup
        content={info}
        trigger={
          <span className="info">
            <Icon name="question" />
            What is this?
          </span>
        }
      />
    )}
  </StyledKeyFact>
);

type KeyFactLinkProps = {
  title: string;
  subtitle?: string;
  fact: string;
  url?: string;
  image?: string;
  imageAltContent?: ReactNode;
};

export const KeyFactLink = ({ title, subtitle, fact, url, image, imageAltContent, ...rest }: KeyFactLinkProps) => {
  const [imageDidError, setImageDidError] = useState(false);
  return (
    <StyledKeyFactLink onClick={() => (url ? window.open(url, "_blank") : {})} {...rest}>
      {image && (
        <div className="image-container">
          {image && (!imageDidError || !imageAltContent) ? (
            <img onError={() => setImageDidError(true)} src={image} alt="" />
          ) : (
            imageAltContent
          )}
        </div>
      )}
      <div className="sub-container">
        <span className="fake-link">{title}</span>
        {subtitle && <span className="subtitle">{subtitle}</span>}
        <span className="fact">{fact}</span>
      </div>
    </StyledKeyFactLink>
  );
};

const TRLIndication = styled.div`
  margin-bottom: 1rem;
  h5 {
    font-size: 1.2rem;
    margin-bottom: 0.15rem;
  }
  .info {
    text-decoration-line: underline;
    color: gray;
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  ${RowFlex} {
    margin: 0.5rem 0;
  }
`;

const TRLIndicatorRectangle = styled.div<{ $active?: boolean; $colour?: string }>`
  border-radius: 5px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    display: block;
    color: ${({ $active }) => ($active ? "white" : "#d2d2d3")};
    ${({ $active }) => $active && "font-weight: bold;"}
  }
  flex: 1;
  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }
  background-color: ${({ $colour }) => $colour};
  ${({ $active }) => $active && "border: 1px solid gray;"}
`;

export const TRLIndicators = ({ trl }) => (
  <TRLIndication>
    <RowFlex>
      <TRLIndicatorRectangle $active={Math.round(trl) === 1} $colour={Math.round(trl) === 1 ? "#FF0D0D" : "#fff4f4"}>
        <span>TRL 1-3</span>
      </TRLIndicatorRectangle>
      <TRLIndicatorRectangle $active={Math.round(trl) === 2} $colour={Math.round(trl) === 2 ? "#FF8E15" : "#fff1e2"}>
        <span>TRL 4-6</span>
      </TRLIndicatorRectangle>
      <TRLIndicatorRectangle $active={Math.round(trl) === 3} $colour={Math.round(trl) === 3 ? "#69B34C" : "#f0f8ee"}>
        <span>TRL 7-9</span>
      </TRLIndicatorRectangle>
    </RowFlex>
  </TRLIndication>
);

import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { FakeLink } from "components/lib/UI";
import { InfoContainer, InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFactLink, InfoItem } from "../Styles";

const CompanyArticles = ({ numArticles, articles = [], news = [] }) => {
  const [viewingMore, setViewingMore] = useState(false);
  const [viewingMoreNews, setViewingMoreNews] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {articles.length ? (
        <InfoBoxWidget $sectioned>
          <InfoBoxWidgetTitleArea>
            <Icon name="newspaper" />
            <h3>Articles</h3>
            <span>{numArticles ?? articles.length} found</span>
          </InfoBoxWidgetTitleArea>
          <InfoContainer $wrapped>
            {articles.slice(0, viewingMore ? 30 : 10).map((article) => (
              <InfoItem key={article._id} href={article.url}>
                <KeyFactLink
                  key={article.title}
                  title={article.title}
                  fact={`Published: ${moment(article.postedAt).format("YYYY-MM-DD HH:mm")}`}
                  image={article.imageUrl}
                  imageAltContent={<Icon name="newspaper" size="large" />}
                />
              </InfoItem>
            ))}
          </InfoContainer>
          {articles.length > 9 && (
            <div style={{ textAlign: "center" }}>
              <FakeLink onClick={() => setViewingMore(!viewingMore)} withBorder>
                View {viewingMore ? "fewer" : "more"} articles
              </FakeLink>
            </div>
          )}
        </InfoBoxWidget>
      ) : null}
      {news.length ? (
        <InfoBoxWidget $sectioned style={{ marginLeft: 0 }}>
          <InfoBoxWidgetTitleArea>
            <Icon name="globe" />
            <h3>News</h3>
            <span>{news.length} found</span>
          </InfoBoxWidgetTitleArea>
          <InfoContainer $wrapped>
            {news.slice(0, viewingMoreNews ? 30 : 10).map((article) => (
              <InfoItem key={article._id} href={article.url}>
                <KeyFactLink
                  key={article.title}
                  title={article.title}
                  fact={`Published: ${moment(article.postedAt).format("YYYY-MM-DD HH:mm")}`}
                  image={article.imageUrl}
                  imageAltContent={<Icon name="newspaper" size="large" />}
                />
              </InfoItem>
            ))}
          </InfoContainer>
          {news.length > 9 && (
            <div style={{ textAlign: "center" }}>
              <FakeLink onClick={() => setViewingMoreNews(!viewingMoreNews)} withBorder>
                View {viewingMoreNews ? "fewer" : "more"} news
              </FakeLink>
            </div>
          )}
        </InfoBoxWidget>
      ) : null}
    </div>
  );
};

export default CompanyArticles;

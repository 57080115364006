const challengesActions = {
  RECEIVE_CHALLENGE: "RECEIVE_CHALLENGE",
  UPDATE_CHALLENGE: "UPDATE_CHALLENGE",
  UPDATE_IDEA_FILTERS: "UPDATE_IDEA_FILTERS",
  DELETE_CHALLENGE: "DELETE_CHALLENGE",
  SET_IDEA_VIEW_TYPE: "SET_IDEA_VIEW_TYPE",

  receive(challenge) {
    return { type: this.RECEIVE_CHALLENGE, challenge };
  },
  update(id, data) {
    return { type: this.UPDATE_CHALLENGE, id, data };
  },
  updateIdeaFilters(data) {
    return { type: this.UPDATE_IDEA_FILTERS, data };
  },
  setIdeaViewType(ideaViewType) {
    return { type: this.SET_IDEA_VIEW_TYPE, ideaViewType };
  },
  delete(id) {
    return { type: this.DELETE_CHALLENGE, id };
  },
};

export default challengesActions;

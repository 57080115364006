import React, { HTMLProps } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const StyledCheckbox = styled.div<{ $compact?: boolean; $isSelected?: boolean }>`
  display: flex;
  width: ${({ $compact }) => ($compact ? "30px" : "40px")};
  height: ${({ $compact }) => ($compact ? "30px" : "40px")};
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  // border: 1px solid lightgray;
  background-color: ${({ $isSelected, theme }) => ($isSelected ? theme.accent : "#e9ebee")};
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "gray")};
  padding: 5px;

  cursor: pointer;
  > i {
    font-size: ${({ $compact }) => ($compact ? "1.2em" : "2em")};
    width: 1em;
    margin-right: 0;
    margin-top: ${({ $compact }) => ($compact ? "0 !important" : "7px")};
    ${({ $isSelected }) => !$isSelected && "font-size: 0"}
  }
  &:hover {
    > i {
      font-size: ${({ $compact }) => ($compact ? "1.2em" : "2em")} !important;
    }
  }
`;

type CheckboxProps = HTMLProps<HTMLDivElement> & {
  compact?: boolean;
  isSelected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const Checkbox = ({ compact, isSelected, onClick, ...rest }: CheckboxProps) => {
  return (
    <StyledCheckbox $compact={compact} $isSelected={isSelected} onClick={onClick} {...rest}>
      <Icon name="check" className="icon-checkbox" />
    </StyledCheckbox>
  );
};

export default Checkbox;

import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Icon, Button, Input, Header, Form, Radio, Label } from "semantic-ui-react";
import toast from "react-hot-toast";
import actions from "actions";
import useThrottle from "utils/useThrottle";
import api from "api";
import styled from "styled-components";
import AdjustableUserList from "components/lib/AdjustableUserList";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";

const sections = ["Name", "Type", "Members"];

const FormatField = styled(Form.Field)`
  display: flex;
  border-width: 1px;
  border-color: #c3cfd9;
  border-style: solid;
  padding: 30px;
  margin: 10px 0px 10px 0px;
  align-items: center;
`;

const FormatInfo = styled.div`
  text-align: left;
  margin-left: 20px;
`;

const NewGroup = () => {
  const user = useAppSelector((state) => state.user);
  const [newGroupState, setNewGroupState] = useState({
    name: "",
    visibility: "open",
    currentSection: "Name",
    completedSections: [],
    addedMembers: [user],
  });
  const [searchState, setSearchState] = useState({
    memberSearchResults: [],
  });
  const [group, setGroup] = useState({});
  const groupId = group._id;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { name, visibility, currentSection, completedSections, addedMembers } = newGroupState;
  const { memberSearchResults } = searchState;

  const nextPage = useCallback(() => {
    const currentSectionIndex = sections.indexOf(currentSection);
    setNewGroupState((prevState) => ({
      ...prevState,
      currentSection: sections[currentSectionIndex + 1],
      completedSections: [...prevState.completedSections, currentSection],
    }));
  }, [currentSection]);

  const saveGroup = useCallback(
    (type, value) => {
      api.groups.update(
        groupId,
        { [type]: value },
        (data) => setGroup(data),
        (err) => toast.error(err.message),
      );
    },
    [groupId],
  );

  const createGroup = useCallback(() => {
    api.groups.create(
      name,
      (data) => {
        setGroup(data);
        dispatch(actions.user.createGroup(data._id, name));
        nextPage();
      },
      (err) => toast.error(err.message),
    );
  }, [name, nextPage, dispatch]);

  const keyFunction = useCallback(
    (event) => {
      if (event.keyCode === 13 && currentSection === "Name" && name) {
        createGroup();
      }
    },
    [currentSection, name, createGroup],
  );

  useEffect(() => {
    document.addEventListener("keydown", keyFunction, false);
    return () => {
      document.removeEventListener("keydown", keyFunction, false);
    };
  }, [keyFunction]);

  const throttledSearch = useThrottle((groupId, memberSearch) => {
    api.search.groupMembers(
      groupId,
      { term: memberSearch },
      ({ users }) => {
        setSearchState((prevState) => ({ ...prevState, memberSearchResults: users }));
      },
      (err) => toast.error(err.message),
    );
  }, 500);
  const searchMembers = useCallback(
    (memberSearch) => {
      throttledSearch(group._id, memberSearch);
    },
    [group._id, throttledSearch],
  );

  const addMember = useCallback(
    (newUser) => {
      api.groups.join(
        group._id,
        newUser._id,
        () => {
          setNewGroupState((prevState) => ({ ...prevState, addedMembers: [...prevState.addedMembers, newUser] }));
        },
        (err) => toast.error(err.message),
      );
    },
    [group._id],
  );

  const removeMember = useCallback(
    (removedUser) => {
      api.groups.leave(
        group._id,
        removedUser._id,
        () => {
          setNewGroupState((prevState) => ({
            ...prevState,
            addedMembers: prevState.addedMembers.filter((m) => m._id !== removedUser._id),
          }));
        },
        (err) => toast.error(err.message),
      );
    },
    [group._id],
  );

  const updateVisibility = useCallback(() => {
    if (visibility === "open") return;
    if (visibility === "public") saveGroup("isPublic", true);
    if (visibility === "private") saveGroup("isClosed", true);
  }, [visibility, saveGroup]);

  const memberActionButton = useCallback(
    (member) => {
      const isAddedToGroup = addedMembers.find((m) => m._id === member._id);
      return (
        <Button
          onClick={() => {
            if (isAddedToGroup) removeMember(member);
            else addMember(member);
          }}
          basic={!isAddedToGroup}
          primary
        >
          <Icon name="add user" />
          {isAddedToGroup ? t("groups.new.members.remove") : t("groups.new.members.add")}
        </Button>
      );
    },
    [t, addedMembers, removeMember, addMember],
  );

  const renderName = useMemo(
    () => (
      <div>
        <Header as="h2">{t("groups.new.name.title")}</Header>
        <p style={{ marginTop: 50, fontSize: "17px" }}>{t("groups.new.name.info")}</p>
        <Input
          placeholder={t("groups.new.name.placeholder")}
          size="big"
          style={{ marginTop: 50 }}
          value={name}
          onChange={(e, { value }) => setNewGroupState((prevState) => ({ ...prevState, name: value }))}
          fluid
        />
        <p style={{ fontSize: "13px", margin: "10px" }}>{t("groups.new.name.info2")}</p>
        <Button
          style={{ marginTop: 50 }}
          disabled={name.length < 5}
          size="large"
          secondary
          onClick={() => createGroup()}
        >
          {t("generic.next")}
          <Icon name="arrow right" />
        </Button>
      </div>
    ),
    [name, t, createGroup],
  );

  const renderType = useMemo(
    () => (
      <div style={{ textAlign: "center" }}>
        <Header as="h2">{t("groups.new.type.title", { name })}</Header>
        <Form>
          <FormatField>
            <Radio
              name="visibility"
              value="open"
              checked={visibility === "open"}
              onChange={(e, { value }) => setNewGroupState((prevState) => ({ ...prevState, visibility: value }))}
            />
            <FormatInfo>
              <Header size="medium">
                {t("groups.new.type.openOrg.title")} <Label>{t("generic.default")}</Label>
              </Header>
              <p>{t("groups.new.type.openOrg.info")}</p>
            </FormatInfo>
          </FormatField>
          <FormatField>
            <Radio
              name="visibility"
              value="private"
              checked={visibility === "private"}
              onChange={(e, { value }) => setNewGroupState((prevState) => ({ ...prevState, visibility: value }))}
            />
            <FormatInfo>
              <Header size="medium">{t("groups.new.type.private.title")}</Header>
              <p>{t("groups.new.type.private.info")}</p>
            </FormatInfo>
          </FormatField>
        </Form>
        <Button
          style={{ marginTop: 20 }}
          size="large"
          secondary
          onClick={() => {
            searchMembers();
            nextPage();
            updateVisibility();
          }}
        >
          {t("generic.next")}
          <Icon name="arrow right" />
        </Button>
      </div>
    ),
    [t, visibility, name, searchMembers, nextPage, updateVisibility],
  );

  const renderMembers = useMemo(
    () => (
      <div>
        <Header as="h2">{t("groups.new.members.title")}</Header>
        <AdjustableUserList
          results={memberSearchResults}
          searchPlaceholder="Start typing a name..."
          searchFunction={searchMembers}
          actions={memberActionButton}
        />
        <p style={{ fontSize: "13px", margin: "10px" }}>{t("groups.new.members.info")}</p>
        <Button style={{ marginTop: 20 }} as={Link} to={`/groups/${group._id}`} secondary>
          {t("generic.finish")}
          <Icon name="arrow right" />
        </Button>
      </div>
    ),
    [t, memberSearchResults, group._id, searchMembers, memberActionButton],
  );

  return (
    <Grid centered stackable style={{ marginTop: 40 }}>
      <Grid.Row columns={3}>
        {sections.map((section) => {
          let style;
          if (section === currentSection) style = { backgroundColor: "#DFE7ED", color: "#DFE7ED" };
          else if (completedSections.includes(section)) style = { backgroundColor: "#4B5C6B", color: "#fff" };
          else style = { backgroundColor: "#fff", color: "#fff" };
          if (section === "Done") return null;
          return (
            <Grid.Column key={section} width={2} textAlign="center">
              <Icon name="checkmark" bordered circular style={style} /> {section}
            </Grid.Column>
          );
        })}
      </Grid.Row>
      <Grid.Column
        textAlign="center"
        width={8}
        verticalAlign="middle"
        style={{ marginTop: 40, minHeight: 300, marginBottom: 40 }}
      >
        {(() => {
          switch (currentSection) {
            case "Name":
              return renderName;
            case "Type":
              return renderType;
            case "Members":
              return renderMembers;
            default:
              return null;
          }
        })()}
      </Grid.Column>
    </Grid>
  );
};

export default NewGroup;

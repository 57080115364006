import React from "react";
import { Icon } from "semantic-ui-react";
import {
  InfoContainer,
  InfoBoxWidget,
  InfoBoxWidgetTitleArea,
  KeyFactLinkSeeMore,
  KeyFactLink,
  InfoItem,
} from "../Styles";

const CompanyPatents = ({ patents = [], total, companyName }) => {
  if (patents?.length) {
    return (
      <InfoBoxWidget $sectioned>
        <InfoBoxWidgetTitleArea>
          <Icon name="archive" />
          <h3>Patents</h3>
          <span>{total} total patent(s)</span>
        </InfoBoxWidgetTitleArea>
        <InfoContainer $wrapped>
          {patents.map((patent) => (
            <InfoItem key={patent.title} href={patent.googleUrl}>
              <KeyFactLink title={patent.title} fact={`Published: ${patent.date}`} />
            </InfoItem>
          ))}
        </InfoContainer>
        {patents.length > 8 ? (
          <KeyFactLinkSeeMore>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://datatool.patentsview.org/#search/assignee&asn=1|${companyName}`}
            >
              View more patents
            </a>
          </KeyFactLinkSeeMore>
        ) : null}
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyPatents;

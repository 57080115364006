import React, { useState, useCallback, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Container, Card, Grid, Button, Input, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import useTheme from "theme/useTheme";
import toast from "react-hot-toast";
import util from "utils/utils";
import api from "api";
import uuid from "uuid";

import DiscoverImage from "src/images/discover.png";

import { Banner, EmptyBox, PlaceholderItem, PlaceholderStack } from "components/lib/UI";
import ChallengeCard from "components/lib/Cards/ChallengeCard";
import NewChallengeModal from "components/challenges/NewChallengeModal";
import { useAppSelector } from "store";
import { OpenAPI } from "simplydo/interfaces";
import useThrottle from "utils/useThrottle";

const Challenges = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(true);
  const currentFetchId = useRef("");
  const [newChallengeOpen, setNewChallengeOpen] = useState(false);
  const [challengeData, setChallengeData] = useState<OpenAPI.GET<"/challenges/all">["response"]>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const theme = useTheme();

  const { challenges } = challengeData ?? {};

  const fetchChallenges = useThrottle(
    (query) => {
      const fetchId = uuid.v4();
      currentFetchId.current = fetchId;

      api.challenges.getAll(
        {
          query,
        },
        (data) => {
          if (fetchId !== currentFetchId.current) return;
          setChallengeData(data);
          setIsLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setIsLoading(false);
        },
      );
    },
    400,
    [],
  );

  const getChallenges = useCallback(
    (query) => {
      setIsLoading(true);
      setSearchQuery(query);
      fetchChallenges(query);
    },
    [fetchChallenges],
  );

  useEffect(() => {
    getChallenges("");
  }, [getChallenges]);

  return (
    <div>
      {newChallengeOpen ? (
        <NewChallengeModal newChallengeOpen={newChallengeOpen} closeModal={() => setNewChallengeOpen(false)} />
      ) : null}
      <Helmet title={t("common:capitalise", { key: "generic.challenge" })} />
      <Banner marginless>
        <Container width={16}>
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column computer={9}>
                <h1 style={{ color: "#3b3b3b", margin: "0px" }}>
                  {t("challenges.yours.orgTitle", { orgName: user.ownerOrganisation.name })}
                </h1>
                <p style={{ marginBottom: !theme.sizes.isComputer && "-20px" }}>{t("challenges.yours.description")}</p>
              </Grid.Column>
              <Grid.Column computer={7} textAlign="right" verticalAlign="bottom">
                {util.canCreateChallenges(user) && (
                  <Button
                    size={!theme.sizes.isComputer ? "tiny" : "medium"}
                    icon="plus"
                    content={
                      !theme.sizes.isComputer
                        ? `Create ${t("generic.challengeWithArticle")}`
                        : t("challenges.new.title")
                    }
                    inverted
                    onClick={() => setNewChallengeOpen(true)}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Banner>

      <Container>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column width={16}>
              <div
                style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 24, marginTop: 24 }}
              >
                <Input
                  placeholder={`Search for ${t("generic.challenges")}...`}
                  loading={isLoading}
                  icon="search"
                  onChange={(e, { value }) => getChallenges(value)}
                  value={searchQuery}
                  style={{ width: "33%" }}
                />
                {!challenges?.length && !isLoading ? (
                  searchQuery ? (
                    <EmptyBox style={{ width: "100%" }}>
                      <img
                        src={DiscoverImage}
                        style={{
                          maxHeight: 150,
                          maxWidth: "70%",
                          display: "block",
                          margin: "10px auto",
                        }}
                        alt="Discover"
                      />
                      <h2>{t("ideas.yours.sidebar.noResults")}</h2>
                    </EmptyBox>
                  ) : (
                    <>
                      <EmptyBox style={{ width: "100%" }}>
                        <img
                          src={DiscoverImage}
                          style={{
                            maxHeight: 150,
                            maxWidth: "70%",
                            display: "block",
                            margin: "10px auto",
                          }}
                          alt="Discover"
                        />

                        <h2>There are no {t("generic.challenges")} open to you currently.</h2>
                      </EmptyBox>
                      <Divider hidden />
                    </>
                  )
                ) : (
                  <>
                    <div style={{ width: "100%" }}>
                      <Card.Group itemsPerRow={4} stackable>
                        {!challenges?.length && isLoading
                          ? Array.from({ length: 8 }).map((_, i) => (
                              <Card key={i}>
                                <PlaceholderStack gap={5}>
                                  <PlaceholderItem height={200} />
                                </PlaceholderStack>
                              </Card>
                            ))
                          : challenges?.map((challenge) => (
                              <ChallengeCard
                                showIdeas
                                challenge={challenge}
                                ideas={challenge.ideas}
                                key={challenge._id}
                              />
                            ))}
                      </Card.Group>
                    </div>
                    <Divider />
                  </>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
      </Container>
    </div>
  );
};

export default Challenges;

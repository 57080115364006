import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Modal, Loader, Segment, Input } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import LinkRow from "./LinkRow";
import { ShortLinkCreator } from "./Links";
import { EmptyBox } from "components/lib/UI";
import { sdiClickDomain } from "utils/domains";

type AdminShortLinks = OpenAPI.GET<"/organisations/{id}/shortenedUrls">;

type ManageLinksModalProps = {
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

const ManageLinksModal = ({ isOpen, onOpen = () => {}, onClose = () => {} }: ManageLinksModalProps) => {
  const [links, setLinks] = useState<AdminShortLinks["response"]["shortenedUrls"]>([]);
  const [hasLinksResults, setHasLinksResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchState, setSearchState] = useState<{
    query: string;
  }>({
    query: "",
  });
  const [creatingNewLink, setCreatingNewLink] = useState(false);
  const [newLinkState, setNewLinkState] = useState<{
    url: string;
    path: string;
    description: string;
  }>({
    url: "",
    path: "",
    description: "",
  });
  const [creatingNewLinkLoading, setCreatingNewLinkLoading] = useState(false);
  const user: OpenAPI.GET<"/users/me">["response"] = useAppSelector((state) => state.user);
  const orgId = user?.ownerOrganisation?._id;

  const getShortenedUrls = useCallback(() => {
    setLoading(true);
    api.organisations.getShortenedUrls(
      orgId,
      searchState,
      (data) => {
        setLinks(data.shortenedUrls);
        setHasLinksResults((prev) => prev || data.shortenedUrls.length > 0);
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  }, [orgId, searchState]);

  useEffect(() => {
    if (isOpen) {
      getShortenedUrls();
    }
  }, [getShortenedUrls, isOpen]);

  const createUrl = useCallback(() => {
    setCreatingNewLinkLoading(true);
    api.organisations.createShortenedUrl(
      orgId,
      newLinkState,
      (shortenedUrl) => {
        setNewLinkState({
          url: "",
          path: "",
          description: "",
        });
        setCreatingNewLinkLoading(false);
        setCreatingNewLink(false);
        setLinks([shortenedUrl, ...links]);
        setHasLinksResults(true);
      },
      (err) => {
        setCreatingNewLinkLoading(true);
        toast.error(err.message);
      },
    );
  }, [orgId, newLinkState, links]);

  const deleteUrl = useCallback(
    (urlId) => {
      util
        .confirm(
          "Really delete this URL?",
          "The URL and associated analytics data will be immediately removed. Be careful - someone else can take your short URL once you delete it.",
        )
        .then(() => {
          api.organisations.deleteShortenedUrl(
            orgId,
            urlId,
            () => {
              setLinks(links.filter((link) => link._id !== urlId));
              if (links.length === 1) {
                setHasLinksResults(false);
              }
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {});
    },
    [orgId, links],
  );

  const copyLink = useCallback((link) => {
    if (window.navigator?.clipboard) {
      window.navigator.clipboard.writeText(`${sdiClickDomain}/${link}`).then(
        () => toast.success("Link copied to your clipboard"),
        () => toast.error("Unable to copy the link"),
      );
    } else {
      toast.error("Your browser does not support copying");
    }
  }, []);

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Modal.Header>{user?.ownerOrganisation?.name}'s Shortened Links</Modal.Header>
      <Modal.Content scrolling>
        {loading && !hasLinksResults ? <Loader inline="centered" active /> : null}
        {!creatingNewLink ? (
          <Button
            primary
            fluid
            icon="add"
            content="Create new shortened link"
            onClick={() => setCreatingNewLink(true)}
            style={{ marginBottom: 10 }}
          />
        ) : (
          <Segment>
            <ShortLinkCreator
              url={newLinkState.url}
              path={newLinkState.path}
              description={newLinkState.description}
              setUrl={(url) => setNewLinkState({ ...newLinkState, url })}
              setPath={(path) => setNewLinkState({ ...newLinkState, path })}
              setDescription={(description) => setNewLinkState({ ...newLinkState, description })}
            />
            <Button
              primary
              fluid
              icon="add"
              content="Create shortened link using these details"
              onClick={createUrl}
              loading={creatingNewLinkLoading}
              style={{ marginTop: 10 }}
            />
          </Segment>
        )}
        {hasLinksResults ? (
          <>
            <Input
              fluid
              placeholder="Search by URL or path"
              loading={loading}
              value={searchState.query}
              onChange={(e) => setSearchState({ ...searchState, query: e.target.value })}
            />
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Link</Table.HeaderCell>
                  <Table.HeaderCell>Created</Table.HeaderCell>
                  <Table.HeaderCell>Total clicks</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {links.map((link) => (
                  <LinkRow
                    key={link._id}
                    link={link}
                    chartData={() => {}}
                    copyLink={copyLink}
                    deleteUrl={deleteUrl}
                    disableReferrals
                  />
                ))}
              </Table.Body>
            </Table>
          </>
        ) : null}
        {!loading && links.length === 0 ? <EmptyBox title="No shortened links found" /> : null}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Done</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ManageLinksModal;

import React from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { InfoContainer, InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFactLink, InfoItem } from "../Styles";

const CompanyBlog = ({ blogTask }) => {
  const { blogs = [] } = blogTask;
  if (!blogs?.length) return null;
  return (
    <InfoBoxWidget $sectioned>
      <InfoBoxWidgetTitleArea>
        <Icon name="newspaper" />
        <h3>Blogs</h3>
        <span>{blogs.length} found</span>
      </InfoBoxWidgetTitleArea>
      {blogs.map((blog) => (
        <>
          <h1>{blog?.title}</h1>
          <InfoContainer $wrapped>
            {blog?.entries?.map((blogPost) => (
              <InfoItem key={blogPost?._id} href={blogPost?.url}>
                <KeyFactLink
                  key={blogPost?.title}
                  title={blogPost?.title}
                  fact={
                    blogPost?.publishedAt
                      ? `Published: ${moment(blogPost?.publishedAt).format("YYYY-MM-DD HH:mm")}`
                      : null
                  }
                  image={blogPost?.imageUrl}
                  url={blogPost?.link}
                />
              </InfoItem>
            ))}
          </InfoContainer>
        </>
      ))}
    </InfoBoxWidget>
  );
};

export default CompanyBlog;

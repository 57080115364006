import { useState, useEffect } from "react";

export const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const elem = myRef?.current;
    if (!elem) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setDimensions({
        width: myRef?.current?.offsetWidth || 0,
        height: myRef?.current?.offsetHeight || 0,
      });
    });
    resizeObserver.observe(elem);

    return () => {
      resizeObserver.unobserve(elem);
    };
  }, [myRef]);

  return dimensions;
};

import React, { useCallback } from "react";
import { Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import uuid from "uuid";

import MultiImageChooser from "components/lib/Choosers/MultiImageChooser";

const MultiImage = ({ field, fieldValue: images = [], ideaId, isEditing, updateIdea }) => {
  const addImage = useCallback(
    (f, n, imageSrc) => {
      const updatedImages = [{ value: imageSrc, name: n, _id: uuid.v4() }, ...images];
      updateIdea(updatedImages, ["templated", field.id]);
    },
    [images, field, updateIdea],
  );

  const removeImage = useCallback(
    (_id) => {
      const updatedImages = images.filter((i) => i._id !== _id);
      updateIdea(updatedImages, ["templated", field.id]);
    },
    [images, field, updateIdea],
  );

  const maxImages = field.maxImages || 10;
  const disableUpload = field.maxImages ? images.length >= maxImages : null;

  return (
    <MultiImageChooser
      editing={isEditing}
      onComplete={addImage}
      deleteFile={removeImage}
      forType="idea"
      forId={ideaId}
      getFileUrl={(image) => image.value}
      title="Image gallery"
      description="Upload multiple images to the gallery"
      images={images}
      maxImages={maxImages}
      trigger={<Button primary icon="add" content="Upload gallery" disabled={disableUpload} />}
    />
  );
};

export default withTranslation()(MultiImage);

import React, { useCallback } from "react";
import { Modal, Table, Button } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import util from "utils/utils";
import { IExistingAssessor, IPotentialAssessor, IModalAction, TableHeaderItem, IPendingInvitationAssessor } from ".";
import { ImageWithFallback } from "components/lib/ImageWithFallback";

type IIdeaModal = {
  isOpen: boolean;
  onClose: () => void;
  idea?: Other.IIdea;
  existingAssessors?: IExistingAssessor[];
  pendingInvitations?: IPendingInvitationAssessor[];
  potentialAssessors?: IPotentialAssessor[];
  getIdeaActions: (
    idea: Other.IIdea,
    assessor: IExistingAssessor | IPotentialAssessor | IPendingInvitationAssessor,
    isLocal: boolean,
  ) => IModalAction[];
};

const IdeaModal = ({
  isOpen,
  onClose,
  idea,
  existingAssessors = [],
  potentialAssessors = [],
  pendingInvitations = [],
  getIdeaActions,
}: IIdeaModal) => {
  const AssessorRow = useCallback(
    (assessor: IExistingAssessor | IPotentialAssessor | IPendingInvitationAssessor, isLocal = false) => (
      <Table.Row key={assessor._id}>
        <Table.Cell>
          <TableHeaderItem as="h4" style={{ fontWeight: "400" }}>
            <ImageWithFallback avatar src={util.avatarUrl(assessor)} fallbackSrc={util.avatarUrl()} />
            {assessor.type === "pendingInvitation" ? assessor.invitee : assessor.profile.fullName}
          </TableHeaderItem>
        </Table.Cell>
        <Table.Cell collapsing>
          {getIdeaActions(idea, assessor, isLocal).map((action) => (
            <Button
              size="tiny"
              content={action.content}
              icon={action.icon}
              loading={action.loading}
              onClick={action.onClick}
            />
          ))}
        </Table.Cell>
      </Table.Row>
    ),
    [idea, getIdeaActions],
  );

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={isOpen}
      onClose={onClose}
      size="fullscreen"
    >
      <Modal.Header>{idea?.name}</Modal.Header>
      <Modal.Content>
        <Table basic="very">
          {potentialAssessors.length && idea ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Selected local assessors</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{potentialAssessors.map((assessor) => AssessorRow(assessor, true))}</Table.Body>
            </>
          ) : null}

          {pendingInvitations.length && idea ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">Pending invitations</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{pendingInvitations.map((invitation) => AssessorRow(invitation))}</Table.Body>
            </>
          ) : null}

          {existingAssessors.length && idea ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">Previously assigned assessors</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{existingAssessors.map((assessor) => AssessorRow(assessor))}</Table.Body>
            </>
          ) : null}
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Done" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export default IdeaModal;

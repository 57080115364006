import React, { useEffect, useState, useCallback } from "react";
import { Message, Divider, Segment, Button, Grid } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "store";
import toast from "react-hot-toast";
import api from "api";
import { OpenAPI } from "simplydo/interfaces";
import moment from "moment";
import actions from "actions";
import util from "utils/utils";

const VerifyEmail = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tryAgain, setTryAgain] = useState<boolean>(false);
  const user: OpenAPI.GET<"/users/me">["response"] = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const userCreatedAt = user.createdAt;

  useEffect(() => {
    // only send new verification email, if the user has not just signed up as we send a verification email during signup
    if (userCreatedAt && moment(userCreatedAt) < moment(new Date(Date.now())).subtract("3", "minutes")) {
      api.auth.requestEmailVerification(
        null,
        () => {},
        () => {},
      );
    }
    const tryAgainTimer = setTimeout(() => setTryAgain(true), 20000);
    return () => {
      clearInterval(tryAgainTimer);
    };
  }, [userCreatedAt]);

  // Ping at regular intervals to user endpoint to see if they have verified their email
  useEffect(() => {
    const userMeInterval = setInterval(() => {
      if (document.visibilityState === "visible") {
        api.users.me(
          null,
          (userResponse: OpenAPI.GET<"/users/me">["response"]) => {
            if (util.hasVerifiedEmail(userResponse, userResponse.organisation)) {
              clearInterval(userMeInterval);
              dispatch(actions.user.receiveUser(userResponse));
            }
          },
          () => {},
        );
      }
    }, 2000);
    return () => {
      clearInterval(userMeInterval);
    };
  }, [dispatch]);

  const clicked = useCallback(() => {
    setLoading(true);
    api.auth.requestEmailVerification(
      null,
      () => {
        setLoading(false);
        setTryAgain(false);
        toast("Verification email sent");
      },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      },
    );
  }, []);

  if (!user) return null;
  return (
    <div>
      <Grid stackable centered>
        <Grid.Column computer={5} tablet={8}>
          <Segment style={{ marginTop: 50 }}>
            <h3>Please verify your account</h3>
            <p>{user.ownerOrganisation.name} requires that you verify your email address.</p>
            <Divider hidden />
            <p>
              We've sent an email to your registered address. Please follow the link in this email to verify your
              address.
            </p>
            <Message size="small">If you can't see it in your inbox, please check your spam folder!</Message>
            {tryAgain && (
              <div>
                <h4>Didn't get an email?</h4>
                <Button
                  secondary
                  size="small"
                  fluid
                  content="Send another"
                  onClick={() => {
                    clicked();
                  }}
                  loading={loading}
                />
              </div>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default VerifyEmail;

import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal, Button, Tab, Icon, Menu } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import api from "api";
import toast from "react-hot-toast";

import { Overview } from "./Overview";
import { Users } from "./Users";
import { Roles } from "./Roles";
import { Ideas } from "./Ideas";
import util from "utils/utils";
import { useAppDispatch, useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { EditOverview } from "./EditOverview";
import Prompt from "utils/Prompt";
import actions from "actions";
import { Settings } from "./Settings";

type IIdeaBusinessProfileProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  onClose: () => void;
  onSave?: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
  onDelete?: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
};

const IdeaBusinessProfileModal = ({ businessProfile, onClose, onSave, onDelete }: IIdeaBusinessProfileProps) => {
  const { t } = useTranslation();
  const [fullProfile, setFullProfile] = useState(businessProfile);
  const isCreating = !fullProfile?._id;

  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const tabActiveIndex = parseInt(searchParams.get("businessProfileTab") || "0", 10);

  const [isUnsaved, setUnsaved] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(isCreating);

  const updateProfile = useCallback((setProfile) => {
    setUnsaved(true);
    setFullProfile(setProfile);
  }, []);

  const updateActiveTab = useCallback(
    (newIndex: number | string) => {
      setSearchParams((prev) => {
        prev.set("businessProfileTab", newIndex.toString());
        return prev;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (!businessProfile?._id) {
      return;
    }

    let mounted = true;
    api.users.getProfile(
      businessProfile._id,
      (result) => {
        if (mounted) {
          setFullProfile(result.ideaBusinessProfile);
        }
      },
      () => {
        toast.error("Failed to load business profile");
      },
    );

    return () => {
      mounted = false;
    };
  }, [businessProfile?._id]);

  const saveProfile = useCallback(
    (cb?: () => void) => {
      setSaving(true);
      const isCreating = !fullProfile._id;
      const saveFunc = isCreating
        ? api.users.createProfile
        : (profile, success, fail) => api.users.updateProfile(fullProfile._id, profile, success, fail);

      saveFunc(
        fullProfile.profile,
        ({ ideaBusinessProfile: newIdeaBusinessProfile }) => {
          setSaving(false);
          setIsEditing(false);
          setUnsaved(false);
          setFullProfile((prev) => ({
            ...prev,
            ...newIdeaBusinessProfile,
          }));
          if (isCreating) {
            toast.success("Profile created");
            dispatch(
              actions.user.updatePermissions(newIdeaBusinessProfile._id, [
                "ideaBusinessProfile.viewDetails",
                "ideaBusinessProfile.editDetails",
                "ideaBusinessProfile.viewIdeas",
                "ideaBusinessProfile.editIdeas",
                "ideaBusinessProfile.viewMembers",
                "ideaBusinessProfile.editMembers",
                "ideaBusinessProfile.deleteProfile",
              ]),
            );
          } else {
            toast.success("Profile updated");
          }
          cb && cb();
          onSave && onSave(newIdeaBusinessProfile);
        },
        (err) => {
          toast.error(err.message);
          setSaving(false);
        },
      );
    },
    [fullProfile, dispatch, onSave],
  );

  const closeModal = useCallback(() => {
    if (isUnsaved) {
      util
        .confirm(
          "Unsaved changes",
          "Unsaved changes will be lost if the modal is closed. Are you sure you want to proceed and discard your changes?",
        )
        .then(() => {
          onClose && onClose();
          setSearchParams((prev) => {
            prev.delete("businessProfileTab");
            return prev;
          });
        })
        .catch(() => {});
    } else {
      onClose && onClose();
      setSearchParams((prev) => {
        prev.delete("businessProfileTab");
        return prev;
      });
    }
  }, [onClose, setSearchParams, isUnsaved]);

  if (!fullProfile) return null;
  const { profile } = fullProfile;

  const canEditProfile = isCreating || util.hasPermission(user, "ideaBusinessProfile.editDetails", businessProfile._id);
  return (
    <Modal
      open={!!businessProfile}
      dimmer={
        <Modal.Dimmer
          onClick={(e) => {
            if (e.target !== e.currentTarget) {
              return;
            }
            closeModal();
          }}
        />
      }
      closeOnDimmerClick={!isUnsaved}
      closeOnEscape={!isUnsaved}
      mountNode={document.getElementById("semantic-modal-mount-node")}
    >
      <Prompt
        when={isUnsaved}
        message="Unsaved changes will be lost if the modal is closed. Are you sure you want to proceed and discard your changes?"
        shouldBlock={(event) => event.nextLocation.pathname !== event.currentLocation.pathname}
      />
      <Modal.Header>{profile?.name}</Modal.Header>
      <Modal.Content scrolling style={{ minHeight: 600 }}>
        {util.hasPermission(user, "ideaBusinessProfile.viewMembers", businessProfile._id) ||
        util.hasPermission(user, "ideaBusinessProfile.editMembers", businessProfile._id) ||
        util.hasPermission(user, "ideaBusinessProfile.viewIdeas", businessProfile._id) ? (
          <Tab
            menu={{ secondary: true, pointing: true }}
            activeIndex={tabActiveIndex}
            onTabChange={(e, { activeIndex }) => updateActiveTab(activeIndex)}
            panes={[
              {
                menuItem: (
                  <Menu.Item>
                    <Icon name="building" />
                    Profile
                  </Menu.Item>
                ),
                render: () =>
                  isEditing ? (
                    <EditOverview businessProfile={fullProfile} setBusinessProfile={updateProfile} />
                  ) : (
                    <Overview businessProfile={fullProfile} />
                  ),
              },
              !isCreating && util.hasPermission(user, "ideaBusinessProfile.viewMembers", businessProfile._id)
                ? {
                    menuItem: (
                      <Menu.Item>
                        <Icon name="users" />
                        Users
                      </Menu.Item>
                    ),
                    render: () => <Users businessProfile={fullProfile} closeModal={closeModal} />,
                  }
                : undefined,
              !isCreating && util.hasPermission(user, "ideaBusinessProfile.editMembers", businessProfile._id)
                ? {
                    menuItem: (
                      <Menu.Item>
                        <Icon name="key" />
                        Roles
                      </Menu.Item>
                    ),
                    render: () => <Roles businessProfile={fullProfile} closeModal={closeModal} />,
                  }
                : undefined,
              !isCreating && util.hasPermission(user, "ideaBusinessProfile.viewIdeas", businessProfile._id)
                ? {
                    menuItem: (
                      <Menu.Item>
                        <Icon name="lightbulb" />
                        {t("common:capitalise", { key: "generic.ideas" })}
                      </Menu.Item>
                    ),
                    render: () => <Ideas businessProfile={fullProfile} closeModal={closeModal} />,
                  }
                : undefined,
              !isCreating && util.hasPermission(user, "ideaBusinessProfile.deleteProfile", businessProfile._id)
                ? {
                    menuItem: (
                      <Menu.Item>
                        <Icon name="cogs" />
                        Settings
                      </Menu.Item>
                    ),
                    render: () => (
                      <Settings businessProfile={fullProfile} closeModal={closeModal} onDelete={onDelete} />
                    ),
                  }
                : undefined,
            ]}
          />
        ) : isEditing ? (
          <EditOverview businessProfile={fullProfile} setBusinessProfile={updateProfile} />
        ) : (
          <Overview businessProfile={fullProfile} />
        )}
      </Modal.Content>
      <Modal.Actions>
        {isEditing ? null : (
          <Button basic onClick={closeModal}>
            {isCreating ? "Discard" : "Close"}
          </Button>
        )}
        {canEditProfile ? (
          isEditing ? (
            <>
              <Button
                onClick={() => {
                  if (isUnsaved) {
                    util
                      .confirm(
                        "Unsaved changes",
                        "Unsaved changes will be lost. Are you sure you want to proceed and discard your changes?",
                      )
                      .then(() => {
                        setUnsaved(false);
                        setIsEditing(false);
                        setFullProfile(businessProfile);
                      })
                      .catch(() => {});
                  } else {
                    setUnsaved(false);
                    setIsEditing(false);
                    setFullProfile(businessProfile);
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                primary
                disabled={isSaving}
                onClick={() => {
                  saveProfile(() => {
                    setIsEditing(false);
                  });
                }}
              >
                <Icon name="check" />
                {isCreating ? "Save profile" : "Save changes"}
              </Button>
            </>
          ) : (
            <Button
              primary
              onClick={() => {
                updateActiveTab(0);
                setIsEditing(true);
              }}
            >
              <Icon name="pencil" />
              Edit profile
            </Button>
          )
        ) : null}
      </Modal.Actions>
    </Modal>
  );
};

export default IdeaBusinessProfileModal;

import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const CheckboxStyle = styled.div<{ $checked?: boolean }>`
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  // border: 1px solid lightgray;
  background-color: ${({ $checked, theme }) => ($checked ? theme.accent : "#e9ebee")};
  color: ${({ $checked }) => ($checked ? "#fff" : "gray")};

  cursor: pointer;
  > i {
    font-size: 2em;
    line-height: 1em;
    width: 1em;
    margin-right: 0;
  }
  &:hover {
    > i {
      font-size: 2em !important;
    }
  }
`;

interface LargeCheckboxProps {
  checked: boolean;
  onChange: (any, checked: boolean) => void;
  content?: React.ReactNode;
}

const LargeCheckbox = ({ checked, onChange, content }: LargeCheckboxProps) => (
  <CheckboxStyle $checked={checked} onClick={(e) => onChange(e, !checked)}>
    {checked ? <Icon name="check" /> : null}
    {content}
  </CheckboxStyle>
);

export default LargeCheckbox;

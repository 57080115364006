import React, { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SearchParams } from "simplydo/core";

import actions from "actions";
import api from "api";

import MessageMole from "./MessageMole";

const MessageMolesContainer = ({ selectedMessageThreads }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const onMessageThreadSelected = useCallback(
    (threadId) => dispatch(actions.messages.selectThread(threadId)),
    [dispatch],
  );

  useEffect(() => {
    const searchParams = new SearchParams(location.search);
    if (searchParams.has("threadId")) {
      const threadId = searchParams.get("threadId");
      api.messages.getThread(
        threadId,
        ({ thread }) => {
          onMessageThreadSelected(thread);
        },
        () => {},
      );

      // Remove the threadId from the URL after opening
      searchParams.delete("threadId");
      const stringifiedParams = searchParams.toString();
      window.history.replaceState({}, "", location.pathname + (stringifiedParams ? `?${stringifiedParams}` : ""));
    }
  }, [location, onMessageThreadSelected]);

  return (
    <>
      {selectedMessageThreads.map((t, index) => (
        <MessageMole thread={t} index={index} key={t._id} totalThreads={selectedMessageThreads.length} />
      ))}
    </>
  );
};

export default MessageMolesContainer;

import React, { useState, useCallback, useEffect } from "react";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import util from "utils/utils";
import api from "api";

import ActivityFeedView from "components/lib/ActivityFeed";

const ActivityFeed = ({ t, user }) => {
  const [feedState, setFeedState] = useState({
    challenges: [],
    discoverChallenges: [],
    feed: [],
    ideas: [],
    achievements: [],
    ideaComments: [],
    loading: false,
    canExtend: false,
    extending: false,
    isFeed: false,
  });
  const [parameterState, setParameterState] = useState({
    show: ["challenge", "discoverChallenge", "achievement", "comment", "idea", "organisationActivityFeedPosts"],
    feedType: "all",
    limit: 25,
    page: 1,
  });
  const {
    loading,
    challenges,
    discoverChallenges,
    feed,
    ideas,
    achievements,
    ideaComments,
    canExtend,
    extending,
    isFeed,
  } = feedState;
  const { limit, page, show, feedType } = parameterState;
  const orgId = user?.ownerOrganisation?._id;

  const feedOptions = [
    { key: 1, text: t("common:capitalise", { key: "generic.ideas" }), value: "idea" },
    { key: 2, text: t("common:capitalise", { key: "generic.challenges" }), value: "challenge" },
    { key: 3, text: t("challenges.discover.title"), value: "discoverChallenge" },
    { key: 4, text: t("generic.achievements"), value: "achievement" },
    { key: 5, text: t("generic.comments"), value: "comment" },
    { key: 6, text: t("generic.organisations"), value: "organisationActivityFeedPosts" },
  ];

  const getFeed = useCallback(() => {
    setFeedState((prevState) => ({ ...prevState, loading: true }));
    api.users.feed(
      { limit, page, include: show },
      (data) => {
        setFeedState((prevState) => ({
          ...prevState,
          ...data,
          loading: false,
          isFeed: true,
        }));
      },
      (err) => {
        toast.error(err.message);
        setFeedState((prevState) => ({ ...prevState, loading: false }));
      },
    );
  }, [limit, page, show]);

  useEffect(() => {
    if (!isFeed) getFeed();
  }, [getFeed, isFeed]);

  const extendFeed = useCallback(() => {
    setFeedState((prevState) => ({ ...prevState, extending: true }));
    api.users.feed(
      { limit, page: page + 1, include: show },
      (data) => {
        setFeedState((prevState) => ({
          ...prevState,
          feed: [...prevState.feed, ...data.feed],
          extending: false,
        }));
        setParameterState((prevState) => ({ ...prevState, page: page + 1 }));
      },
      (err) => {
        toast.error(err.message);
        setFeedState((prevState) => ({ ...prevState, extending: false }));
      },
    );
  }, [limit, page, show]);

  const handleFeedType = useCallback(
    ({ value }) => {
      const isShown = show.indexOf(value) > -1;

      if (!isShown) {
        setParameterState((prevState) => ({ ...prevState, show: [value, ...prevState.show] }));
      } else {
        setParameterState((prevState) => ({ ...prevState, show: prevState.show.filter((f) => f !== value) }));
      }
      setFeedState((prevState) => ({ ...prevState, loading: true, isFeed: false }));
    },
    [show],
  );

  const onPost = useCallback(
    (post) => {
      api.organisations.postToActivityFeed(
        orgId,
        post,
        (data) => {
          setFeedState((prevState) => ({
            ...prevState,
            feed: [
              { ...data.post, owner: post.isAnonymous ? null : user, type: "organisationActivityFeedPost" },
              ...prevState.feed,
            ],
          }));
        },
        (err) => toast.error(err.message),
      );
    },
    [orgId, user],
  );

  const onUpdatePost = useCallback(
    (postId, updateData) => {
      api.organisations.updateActivityFeedPost(
        orgId,
        postId,
        updateData,
        ({ post: updatedPost }) => {
          setFeedState((prevState) => {
            const postIndex = prevState.feed.findIndex((p) => p._id === postId);
            const updatedFeed = [...prevState.feed];
            updatedFeed.splice(postIndex, 1, {
              ...prevState.feed[postIndex],
              ...updatedPost,
              isPinned: !!updatedPost.isPinned,
            });
            return { ...prevState, feed: updatedFeed };
          });
        },
        (err) => toast.error(err.message),
      );
    },
    [orgId],
  );

  const onDeletePost = useCallback(
    (postId) => {
      api.organisations.deletePostFromActivityFeed(
        orgId,
        postId,
        () => {
          setFeedState((prevState) => ({ ...prevState, feed: prevState.feed.filter((p) => p._id !== postId) }));
        },
        (err) => toast.error(err.message),
      );
    },
    [orgId],
  );

  return (
    <ActivityFeedView
      loading={loading}
      challenges={challenges}
      discoverChallenges={discoverChallenges}
      feed={feed}
      ideas={ideas}
      achievements={achievements}
      ideaComments={ideaComments}
      feedType={feedType}
      isFeed={isFeed}
      feedOptions={feedOptions}
      handleFeedType={handleFeedType}
      extendFeed={extendFeed}
      canExtend={canExtend}
      show={show}
      extending={extending}
      canPost={util.hasPermission(user, "org.viewDashboard", user?.ownerOrganisation._id)}
      onPost={onPost}
      onUpdatePost={onUpdatePost}
      onDeletePost={onDeletePost}
    />
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default withTranslation()(connect(mapStateToProps)(ActivityFeed));

/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Loader, Button, Icon, Message } from "semantic-ui-react";
import moment from "moment";
import styled from "styled-components";
import api from "api";
import toast from "react-hot-toast";
import RedactedInfo from "components/lib/RedactedInfo";
import { useSearchCompanyDispatch } from "components/innovationintelligence/CompanyState/client";
import { updateCompany } from "components/innovationintelligence/CompanyState/companyReducer";
import CreditsafeManualMatch from "./CreditsafeManualMatch";
import { InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFact, KeyFactLinkSeeMore } from "../Styles";

const CreditRatingColourMap = {
  A: "MediumSeaGreen",
  B: "MediumSeaGreen",
  C: "MediumSeaGreen",
  D: "Orange",
  E: "Orange",
};

const GetCreditContainer = styled.div`
  margin-top: 15px;
  position: relative;
  .fake-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    .circle {
      background-color: rgb(200, 200, 200);
      height: 75px;
      width: 75px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .lines {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const CreditContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CreditRatingDisplay = styled.div`
  border-radius: 50%;
  height: 65px;
  width: 65px;
  background-color: ${({ $creditRating }) => CreditRatingColourMap[$creditRating] || "red"};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  > span {
    display: block;
    color: #fff;
    font-weight: bold;
    font-size: 2.5em;
  }
`;

export const CreditsafeReportSummary = ({ creditsafe }) => {
  const { data: reportData } = creditsafe?.report || {};
  const { creditScore, companySummary } = reportData || {};
  const { currentCreditRating, latestRatingChangeDate } = creditScore || {};

  return (
    <>
      <InfoBoxWidgetTitleArea $noMargin>
        <Icon name="money" />
        <h3>Credit information</h3>
      </InfoBoxWidgetTitleArea>
      <CreditContainer>
        <CreditRatingDisplay $creditRating={currentCreditRating.commonValue}>
          <span>{currentCreditRating.commonValue}</span>
        </CreditRatingDisplay>
        <div>
          {companySummary.businessName ? <KeyFact title="Registered name" fact={companySummary.businessName} /> : null}
          <KeyFact
            title="Credit rating"
            fact={`Rating: ${currentCreditRating.commonValue}, ${currentCreditRating.commonDescription}. Last updated ${moment(latestRatingChangeDate).format("lll")}`}
          />
          <KeyFact title="Company status" fact={companySummary.companyStatus.description} />
        </div>
      </CreditContainer>
    </>
  );
};

const CompanyCreditsafe = ({ companyId, name, location, creditsafe: propCreditsafe = {} }) => {
  const [reportLoading, setReportLoading] = useState(false);
  const [reportSuccess, setReportSuccess] = useState(false);
  const [didFindReport, setDidFindReport] = useState(false);
  const [manualMatchOpen, setManualMatchOpen] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [creditsafeErrorMessage, setCreditsafeErrorMessage] = useState("");

  // Allow synchronous updating of CreditSafe after calling the API
  const [creditsafe, setCreditsafe] = useState(propCreditsafe);

  const dispatch = useSearchCompanyDispatch();

  const getReportData = useCallback(() => {
    setReportLoading(true);
    api.innovationIntelligence.getCreditsafeReport(
      companyId,
      "",
      ({ success, didFindReport: newDidFindReport, report }) => {
        setReportLoading(false);
        setDidFindReport(newDidFindReport);
        setReportSuccess(success);
        if (report) {
          setCreditsafe((prev) => ({
            ...prev,
            report,
          }));
        }
      },
      (err) => {
        toast.error(err.message);
        setCreditsafeErrorMessage(err.message);
        setReportLoading(false);
      },
    );
  }, [companyId]);

  useEffect(() => {
    if (reportSuccess && !didFindReport) setManualMatchOpen(true);
  }, [reportSuccess, didFindReport]);

  const getReportPdf = useCallback(() => {
    setPdfLoading(true);
    api.innovationIntelligence.getCreditsafeReportPdf(
      companyId,
      ({ report, didFindReport: didFind, updatedCreditsafe }) => {
        setPdfLoading(false);
        if (didFind) {
          dispatch(
            updateCompany({
              _id: companyId,
              sources: {
                creditsafe: updatedCreditsafe,
              },
            }),
          );
          window.open(report.path, "_blank");
        } else {
          toast.error("Could not fetch detailed report.");
        }
      },
      (err) => {
        toast.error(err.message);
        setPdfLoading(false);
      },
    );
  }, [companyId, dispatch]);

  // We set this as a useMemo with 0 dependencies so the Math.random() call is stable, and the lines don't change whenever there's a state update
  const HiddenContentLines = useMemo(
    () => [...Array(6)].map((e, i) => <RedactedInfo key={i} width={Math.random() * 25 + 60} />),
    [],
  );

  return (
    <InfoBoxWidget $relaxed={!!creditsafeErrorMessage}>
      <CreditsafeManualMatch
        isOpen={manualMatchOpen}
        onClose={() => setManualMatchOpen(false)}
        companyId={companyId}
        name={name}
        location={location}
        onError={(err) => {
          setCreditsafeErrorMessage(err.message);
          setManualMatchOpen(false);
        }}
        onReceiveCreditSafeReport={(report) => {
          setCreditsafe((prev) => ({
            ...prev,
            report,
          }));
        }}
      />

      {creditsafeErrorMessage ? (
        <>
          <Message negative>
            <Message.Header>Could not fetch credit information</Message.Header>
            <p>
              CreditSafe is currently unable to process this request. We are unable to fetch credit information at this
              time. Please try again later or contact us directly if this issue persists.
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
              }}
            >
              <Button
                compact
                size="small"
                basic
                content="Try again"
                onClick={() => {
                  setCreditsafeErrorMessage("");
                }}
              />
            </div>
          </Message>
        </>
      ) : !creditsafe?.report ? (
        <>
          <InfoBoxWidgetTitleArea $noMargin>
            <Icon name="money" />
            <h3>Credit information</h3>
          </InfoBoxWidgetTitleArea>
          <GetCreditContainer>
            <div className="fake-info">
              <div className="circle" />
              <div className="lines">{HiddenContentLines}</div>
            </div>
            <div className="button-container">
              <Button
                loading={reportLoading}
                content={reportSuccess && !didFindReport ? "Search for credit information" : "Get credit information"}
                onClick={reportSuccess && !didFindReport ? () => setManualMatchOpen(true) : () => getReportData()}
                primary
              />
            </div>
          </GetCreditContainer>
        </>
      ) : (
        <>
          <CreditsafeReportSummary creditsafe={creditsafe} />
          <KeyFactLinkSeeMore>
            {pdfLoading && <Loader inverted active inline size="tiny" style={{ marginRight: 5 }} />}
            <Button secondary size="tiny" onClick={getReportPdf}>
              <Icon name="pdf file" /> Download detailed credit report
            </Button>
          </KeyFactLinkSeeMore>
        </>
      )}
    </InfoBoxWidget>
  );
};

export default CompanyCreditsafe;

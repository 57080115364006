import { Form } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";

type TemplateField = OpenAPI.Schemas["IdeaTemplate"]["body"][0]["fields"][0];

type VisibilityConditionsType = TemplateField["visibilityConditions"];
type VisibilityCondition = VisibilityConditionsType["conditions"][0];

const isNotEmpty = (field: string | undefined) => field !== null && field !== undefined && field !== "";

const isEmpty = (field: string | undefined) => !isNotEmpty(field);

const equalTo = (field: string | undefined, value: string) => field === value;

/**
 * This controls which checks are available for any field type.
 * If modified please also update the corresponding type in the backend. (package: python-utils/simplydo/conditions.py)
 */
export const conditionChecks = {
  shorttext: {
    isEmpty: isEmpty,
    isNotEmpty: isNotEmpty,
    equalTo: equalTo,
  },
  longtext: {
    isEmpty: isEmpty,
    isNotEmpty: isNotEmpty,
    equalTo: equalTo,
  },
  richtext: {
    isEmpty: (field) => isEmpty(field) || field === "<p></p>",
    isNotEmpty: (field) => isNotEmpty(field) && field !== "<p></p>",
  },
  slider: {
    equalTo: (field, value) => field && equalTo(field.value.toString(), value),
  },
  dropdown: {
    equalTo: equalTo,
    isNotEmpty: isNotEmpty,
    isEmpty: isEmpty,
  },
  optionMultiple: {
    includes: (field, value) => field && field.includes(value),
    doesNotInclude: (field, value) => field && !field.includes(value),
    isNotEmpty: (field) => field && field.length > 0,
    isEmpty: (field) => !field || (field && field.length === 0),
  },
  creditsafereport: {
    isNotEmpty: (field) => field && field._id,
    isEmpty: (field) => !field || (field && !field._id),
  },
};

export const getDefaultCondition = (fieldType: string) => {
  const availableChecks = conditionChecks[fieldType];
  if (!availableChecks) {
    return null;
  }
  if ("isNotEmpty" in availableChecks) {
    return "isNotEmpty";
  }
  if ("equalTo" in availableChecks) {
    return "equalTo";
  }
  return null;
};

const transformerLookup = {
  isEmpty: {
    key: "isEmpty",
    value: "isEmpty",
    text: "is empty.",
  },
  isNotEmpty: {
    key: "isNotEmpty",
    value: "isNotEmpty",
    text: "has a value.",
  },
  equalTo: {
    key: "equalTo",
    value: "equalTo",
    text: "is equal to:",
  },
  includes: {
    key: "includes",
    value: "includes",
    text: "includes:",
  },
  doesNotInclude: {
    key: "doesNotInclude",
    value: "doesNotInclude",
    text: "does not include:",
  },
};

export const renderValueCheck = (
  condition: VisibilityCondition,
  field: TemplateField,
  onChange: (value: string) => void,
) => {
  if (!field) {
    return <Form.Input disabled />;
  }
  switch (condition.condition) {
    case "equalTo":
      if (field.type === "dropdown") {
        return (
          <Form.Dropdown
            key={field.id}
            selection
            value={condition.value}
            options={field.options.map((o) => ({
              key: o,
              value: o,
              text: o,
            }))}
            onChange={(e, { value }) => onChange(value as string)}
          />
        );
      }
      return <Form.Input key={field.id} value={condition.value} onChange={(e, { value }) => onChange(value)} />;
    case "includes":
    case "doesNotInclude":
      return (
        <Form.Dropdown
          key={field.id}
          selection
          value={condition.value}
          options={field.options.map((o) => ({
            key: o,
            value: o,
            text: o,
          }))}
          onChange={(e, { value }) => onChange(value as string)}
        />
      );
    default:
      return <Form.Input key={field.id} disabled />;
  }
};

export const availableConditions = Object.fromEntries(
  Object.entries(conditionChecks).map(([key, value]) => [
    key,
    Object.keys(value)
      .filter((transformerName) => transformerName in transformerLookup)
      .map((transformerName) => transformerLookup[transformerName]),
  ]),
);

export const fieldVisibilityCheck = (idea, field) => {
  if (!idea.ideaTemplate) {
    return true;
  }

  const fieldTypes = {};
  for (const section of idea.ideaTemplate.body || []) {
    for (const field of section.fields || []) {
      fieldTypes[field.id] = field.type;
    }
  }

  const visibilityConditions = field.visibilityConditions || {};
  if (!visibilityConditions) {
    return true;
  }
  if (!visibilityConditions.enabled) {
    return true;
  }

  const conditions = visibilityConditions.conditions || [];
  if (conditions.length === 0) {
    return true;
  }

  const checks = [];
  for (const condition of conditions) {
    const conditionField = condition.field;
    const conditionCondition = condition.condition;

    if (!conditionField || !conditionCondition) {
      continue;
    }

    const fieldType = fieldTypes[conditionField];
    if (!fieldType) {
      continue;
    }

    const conditionValue = condition.value;
    const fieldValue = idea?.templated?.[conditionField];
    const conditionTransformer = conditionChecks[fieldType][conditionCondition];
    if (!conditionTransformer) {
      continue;
    }

    checks.push(conditionTransformer(fieldValue, conditionValue));
  }

  const conditionType = visibilityConditions.conditionType || "any";
  if (conditionType === "all") {
    return checks.every((check) => check);
  } else if (conditionType === "any") {
    return checks.some((check) => check);
  }
  return true;
};

import React, { useState, useEffect, useCallback } from "react";
import { Grid, Button, Modal, Loader } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import styled from "styled-components";

import AchievementComponent from "components/lib/Users/Achievement";

export const AchievementContainer = styled.div`
  display: flex;
  flex-direction: column;
  .achievement-component {
    width: 100%;
    margin: 0;
  }
  .ui.button {
    margin: 0 !important;
  }
`;

const AchievementAwarder = ({ user, targetUser, onClose }) => {
  const [achievements, setAchievements] = useState([]);
  const [achievementsLoading, setAchievementsLoading] = useState(false);
  const [customAchievements, setCustomAchievements] = useState([]);
  const organisationId = user?.ownerOrganisation?._id;
  const targetUserId = targetUser?._id;

  useEffect(() => {
    if (targetUserId) {
      api.users.get(
        targetUserId,
        (dataUser) => {
          setCustomAchievements(dataUser.customAchievements);
        },
        () => {},
      );
    }
    setCustomAchievements([]);
  }, [targetUserId]);

  useEffect(() => {
    setAchievementsLoading(true);
    api.organisations.getAchievements(
      organisationId,
      (data) => {
        setAchievements(data.achievements);
        setAchievementsLoading(false);
      },
      (err) => {
        toast.error(err.message);
        setAchievementsLoading(false);
      },
    );
  }, [organisationId]);

  const hasBeenAwarded = useCallback(
    (achievement) => {
      const customAchievementIds = customAchievements.map((i) => i._id);
      return customAchievementIds.indexOf(achievement._id) > -1;
    },
    [customAchievements],
  );

  const onAwardAchievement = useCallback(
    (achievement) => {
      api.organisations.awardAchievement(
        organisationId,
        achievement._id,
        targetUserId,
        () => {
          const updatedAchievements = Object.assign([], customAchievements);
          updatedAchievements.unshift(achievement);
          setCustomAchievements(updatedAchievements);
        },
        (err) => toast.error(err.message),
      );
    },
    [organisationId, targetUserId, customAchievements],
  );

  const onRemoveAward = useCallback(
    (achievementId) => {
      api.organisations.removeAwardAchievement(organisationId, achievementId, targetUserId, () => {
        const updatedAchievements = customAchievements.filter((a) => a._id !== achievementId);
        setCustomAchievements(updatedAchievements);
      });
    },
    [organisationId, targetUserId, customAchievements],
  );

  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={targetUser && true} onClose={onClose}>
      <Modal.Header>Award custom achievements</Modal.Header>
      <Modal.Content>
        {achievementsLoading ? (
          <Loader centered />
        ) : (
          <Grid centered stackable style={{ margin: "1rem 0" }}>
            {achievements.map((achievement) => (
              <Grid.Column stretched computer={4} tablet={5} key={achievement._id} textAlign="center">
                <AchievementContainer>
                  <AchievementComponent achievement={achievement} showDate showAllInfo />
                  {hasBeenAwarded(achievement) ? (
                    <Button icon="trash" basic content="Remove award" onClick={() => onRemoveAward(achievement._id)} />
                  ) : (
                    <Button
                      primary
                      icon="add"
                      content="Award achievement"
                      onClick={() => onAwardAchievement(achievement)}
                    />
                  )}
                </AchievementContainer>
              </Grid.Column>
            ))}
          </Grid>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Done" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export default AchievementAwarder;

import React from "react";
import { Icon, Message } from "semantic-ui-react";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import { BodyContainer, InfoTextHeader } from "./styles";

const CustomInfoText = ({ field }) => (
  <BodyContainer style={{ borderRadius: 5 }} width={16}>
    <Message>
      <InfoTextHeader>
        <Icon name="info circle" color="#4B4B4B" size="big" />
        <h3>Supporting information</h3>
      </InfoTextHeader>
      <RichTextViewer content={field.content} />
    </Message>
  </BodyContainer>
);

export default CustomInfoText;

import React from "react";
import styled from "styled-components";
import util from "utils/utils";

import { UserChip } from "components/lib/Chips";

const Bar = styled.div`
  height: 60px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.secondaryColour},
    ${({ theme }) => util.adjustColour(theme.secondaryColour, 0.5)}
  );
  top: 50px;
  width: 100%;
  z-index: 8;
  position: fixed;
`;

const UserStatusBar = ({ profileUser }) => {
  if (!profileUser || !profileUser.profile) return null;
  return (
    <Bar>
      <UserChip user={profileUser} inverted />
    </Bar>
  );
};

export default UserStatusBar;

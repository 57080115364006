import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Divider, Label, Icon, Form, Popup, Button, Dropdown, Checkbox, Segment, Image } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";

import constants from "utils/constants";

import util from "utils/utils";
import toast from "react-hot-toast";
import styled from "styled-components";
import api from "api";
import { businessSizeOptions, businessStatusOptions, businessTypeOptions } from "./Overview";
import { ImageChooser } from "../Choosers";
import { TooltipButton } from "../UI";

const UploadImageSegment = styled(Segment)`
  min-width: 100%;
  margin-bottom: 16px !important;

  .ui.image {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const AccreditationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  .ui.label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const AdvancedProfileContainer = styled.div`
  .ui.input {
    width: 100%;
  }
  .ui.list {
    margin-top: 10px;
    .ui.avatar.image {
      object-fit: contain;
    }
  }
  .or {
    margin: 5px 0;
    > span {
      color: gray;
      display: block;
    }
  }
`;

type EditOverviewProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  setBusinessProfile?: Dispatch<SetStateAction<OpenAPI.Schemas["IdeaBusinessProfile"]>>;
};

export const EditOverview = ({ businessProfile, setBusinessProfile }: EditOverviewProps) => {
  const [addingCustomAccreditation, setAddingCustomAccreditation] = useState(false);
  const [generatingDescription, setGeneratingDescription] = useState(false);
  const [customAccreditation, setCustomAccreditation] = useState("");

  const { profile } = businessProfile || {};
  const {
    name,
    description,
    address = {},
    websiteUrl,
    websiteUrlSkipped,
    size,
    type,
    status,
    accreditations = [],
    creditsafeRegNo,
    creditsafeRegNoSkipped,
    vatNo,
    vatNoSkipped,
    primaryContact,
  } = profile || {};
  const { fullName = "", phoneNumber = "", email = "", jobTitle = "" } = primaryContact || {};

  const { country, city, streetAddress, postcode } = address;

  const generateDescription = useCallback(() => {
    setGeneratingDescription(true);
    api.users.generateBusinessDescription(
      {
        companyName: name,
        websiteUrl: websiteUrl,
      },
      ({ success, error, description }) => {
        if (!success) {
          toast.error(error);
          setGeneratingDescription(false);
        } else {
          setBusinessProfile((prevState) => ({ ...prevState, profile: { ...prevState.profile, description } }));
          setGeneratingDescription(false);
        }
      },
      (err) => {
        toast.error(err.message);
        setGeneratingDescription(false);
      },
    );
  }, [websiteUrl, name, setBusinessProfile]);

  return (
    <>
      <Form>
        <div style={{ display: "flex", gap: 24 }}>
          <ImageChooser
            forType="ideaBusinessProfile"
            forId={businessProfile._id}
            disabled={!businessProfile._id}
            trigger={
              <div>
                <UploadImageSegment>
                  {profile?.imageUrl ? (
                    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                      <div
                        style={{
                          display: "flex",
                          alignSelf: "flex-start",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 4,
                          overflow: "hidden",
                          maxHeight: 150,
                          width: 150,
                        }}
                      >
                        <Image style={{ objectFit: "contain" }} src={profile?.imageUrl} alt={profile?.name} />
                      </div>
                      <Button
                        size="mini"
                        basic
                        fluid
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          util
                            .confirm("Remove logo", "Are you sure you want to remove the logo?")
                            .then(() => {
                              setBusinessProfile((prevState) => ({
                                ...prevState,
                                profile: { ...prevState.profile, imageUrl: null, image: null },
                              }));
                            })
                            .catch(() => {});
                        }}
                      >
                        <Icon name="trash" />
                        Remove
                      </Button>
                    </div>
                  ) : (
                    <TooltipButton
                      fluid
                      disabled={!businessProfile._id}
                      wrapperStyle={{ display: "flex" }}
                      tooltip={
                        !businessProfile._id
                          ? "You will be able to upload a custom logo once you have saved an initial version of this profile."
                          : null
                      }
                    >
                      <Icon name="upload" />
                      Upload logo
                    </TooltipButton>
                  )}
                </UploadImageSegment>
              </div>
            }
            onComplete={(responseFilename, fn, downloadUrl) => {
              setBusinessProfile((prevState) => ({
                ...prevState,
                profile: { ...prevState.profile, image: responseFilename, imageUrl: downloadUrl },
              }));
            }}
          />
          <div style={{ flex: 1 }}>
            <h4>About</h4>
            <Form.Input
              required
              placeholder="Type your organisation name.."
              value={name}
              onChange={(e, { value }) => {
                setBusinessProfile((prevState) => ({ ...prevState, profile: { ...prevState.profile, name: value } }));
              }}
            />
            <Form.Group widths={"equal"}>
              <Form.Input
                label="Phone number"
                placeholder="+44 0000 000000"
                value={profile.phoneNumber}
                required
                onChange={(e, { value }) =>
                  setBusinessProfile((prevState) => ({
                    ...prevState,
                    profile: { ...prevState.profile, phoneNumber: value },
                  }))
                }
              />
              <Form.Field>
                <Form.Input
                  label="Website, social media, or other web presence"
                  required
                  placeholder="https://www.example.com"
                  disabled={websiteUrlSkipped}
                  value={websiteUrl}
                  onChange={(e, { value }) =>
                    setBusinessProfile((prevState) => ({
                      ...prevState,
                      profile: { ...prevState.profile, websiteUrl: value },
                    }))
                  }
                />
                <Checkbox
                  label="My business does not have a website yet"
                  style={{ marginTop: 15 }}
                  onChange={(e, { checked }) =>
                    setBusinessProfile((prevState) => ({
                      ...prevState,
                      profile: {
                        ...prevState.profile,
                        websiteUrlSkipped: checked,
                      },
                    }))
                  }
                  checked={websiteUrlSkipped}
                />
              </Form.Field>
            </Form.Group>
          </div>
        </div>
        <Form>
          <Form.TextArea
            label="About the organisation"
            value={description}
            onChange={(e, { value }) =>
              setBusinessProfile((prevState) => ({
                ...prevState,
                profile: { ...prevState.profile, description: value as string },
              }))
            }
          />
        </Form>
        {util.validateUrl(websiteUrl ?? "") ? (
          <Popup
            content="SimplyDo will visit the website URL provided and use the content to generate a organisation description."
            trigger={
              <Button
                content="Generate organisation description from website"
                floated="right"
                loading={generatingDescription}
                onClick={generateDescription}
                size="tiny"
                style={{ marginTop: 5 }}
                icon="magic"
              />
            }
          />
        ) : null}
        <Divider hidden />
        <h4 style={{ marginBottom: 0 }}>Registered address</h4>
        <p
          style={{ color: "grey", marginTop: 5 }}
        >{`This must be your official registered address as recorded with Companies House (or other official register).  If you are a sole trader or another type of organisation, use your trading address or an address where your business can be reached.`}</p>
        <Form.Input
          required
          label="Street address"
          value={streetAddress}
          onChange={(e, { value }) =>
            setBusinessProfile((prevState) => ({
              ...prevState,
              profile: {
                ...prevState.profile,
                address: { ...(prevState.profile?.address || {}), streetAddress: value },
              },
            }))
          }
        />
        <Form.Group widths={"equal"}>
          <Form.Input
            required
            label="Country"
            value={country}
            onChange={(e, { value }) =>
              setBusinessProfile((prevState) => ({
                ...prevState,
                profile: {
                  ...prevState.profile,
                  address: { ...(prevState.profile?.address || {}), country: value },
                },
              }))
            }
          />
          <Form.Input
            required
            label="City"
            value={city}
            onChange={(e, { value }) =>
              setBusinessProfile((prevState) => ({
                ...prevState,
                profile: {
                  ...prevState.profile,
                  address: { ...(prevState.profile?.address || {}), city: value },
                },
              }))
            }
          />
          <Form.Input
            required
            label="Postcode"
            value={postcode}
            onChange={(e, { value }) =>
              setBusinessProfile((prevState) => ({
                ...prevState,
                profile: {
                  ...prevState.profile,
                  address: { ...(prevState.profile?.address || {}), postcode: value },
                },
              }))
            }
          />
        </Form.Group>

        <Divider hidden />
        <h4>Additional information</h4>
        <Form.Group widths={"equal"}>
          <Form.Select
            required
            value={size}
            label={"Organisation size"}
            options={businessSizeOptions}
            onChange={(e, { value }) =>
              setBusinessProfile((prevState) => ({
                ...prevState,
                profile: {
                  ...prevState.profile,
                  size: value as "micro" | "small" | "medium" | "large",
                },
              }))
            }
          />
          <Form.Select
            required
            value={type}
            label={"Organisation type"}
            options={businessTypeOptions}
            onChange={(e, { value }) =>
              setBusinessProfile((prevState) => ({
                ...prevState,
                profile: {
                  ...prevState.profile,
                  type: value as "public" | "private" | "notForProfit" | "academic",
                },
              }))
            }
          />
          <Form.Select
            required
            value={status}
            label={"Organisation status"}
            options={businessStatusOptions}
            onChange={(e, { value }) =>
              setBusinessProfile((prevState) => ({
                ...prevState,
                profile: {
                  ...prevState.profile,
                  status: value as
                    | "preStartup"
                    | "startup"
                    | "established_small"
                    | "established_medium"
                    | "established_large",
                },
              }))
            }
          />
        </Form.Group>
      </Form>

      <Form style={{ marginTop: 20 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "48%" }}>
            <>
              <Form.Input
                placeholder="01234567"
                required
                label="Company registration number"
                value={creditsafeRegNo}
                onChange={(e, { value }) =>
                  setBusinessProfile((prevState) => ({
                    ...prevState,
                    profile: { ...prevState.profile, creditsafeRegNo: value },
                  }))
                }
                disabled={creditsafeRegNoSkipped}
              />
              <Checkbox
                label="My business does not have a registration number"
                style={{ marginBottom: 10 }}
                onChange={(e, { checked }) =>
                  setBusinessProfile((prevState) => ({
                    ...prevState,
                    profile: {
                      ...prevState.profile,
                      creditsafeRegNoSkipped: checked,
                    },
                  }))
                }
                checked={creditsafeRegNoSkipped}
              />
            </>
          </div>
          <div style={{ width: "48%" }}>
            <Form.Input
              required
              placeholder="GB-000000"
              label="VAT registration number"
              value={vatNo}
              onChange={(e, { value }) =>
                setBusinessProfile((prevState) => ({
                  ...prevState,
                  profile: { ...prevState.profile, vatNo: value },
                }))
              }
              disabled={vatNoSkipped}
            />
            <Checkbox
              label="My business does not have a VAT number"
              checked={vatNoSkipped}
              onChange={(e, { checked }) =>
                setBusinessProfile((prevState) => ({
                  ...prevState,
                  profile: {
                    ...prevState.profile,
                    vatNoSkipped: checked,
                  },
                }))
              }
              style={{ marginBottom: 10 }}
            />
          </div>
        </div>
      </Form>

      <h4>Accreditations</h4>
      <AccreditationsContainer>
        {(accreditations ?? []).map((accreditation) => (
          <Label key={accreditation} size="medium">
            <span>{constants.accreditations.find((a) => a.key === accreditation)?.text || accreditation}</span>
            <Icon
              name="delete"
              onClick={() =>
                setBusinessProfile((prevState) => ({
                  ...prevState,
                  profile: {
                    ...prevState.profile,
                    accreditations: prevState.profile?.accreditations?.filter((a) => a !== accreditation),
                  },
                }))
              }
            />
          </Label>
        ))}
        <Dropdown button className="icon" labeled icon="add" scrolling text="Add accreditation">
          <Dropdown.Menu>
            {constants.accreditations.map((accreditation) => (
              <Dropdown.Item
                key={accreditation.key}
                value={accreditation.key}
                onClick={(e, { value }) => {
                  if (accreditations?.includes(value as string)) {
                    toast.error("This accreditation is already added");
                    return;
                  }
                  if (value === "other") {
                    setAddingCustomAccreditation(true);
                  } else {
                    setBusinessProfile((prevState) => ({
                      ...prevState,
                      profile: {
                        ...prevState.profile,
                        accreditations: [...(prevState.profile?.accreditations ?? []), value as string],
                      },
                    }));
                  }
                }}
              >
                {accreditation.text}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {addingCustomAccreditation && (
          <>
            <Form.Input
              placeholder="Enter accreditation"
              value={customAccreditation}
              onChange={(e) => setCustomAccreditation(e.target.value)}
            />
            <Button
              onClick={() => {
                setBusinessProfile((prevState) => ({
                  ...prevState,
                  profile: {
                    ...prevState.profile,
                    accreditations: [...(prevState.profile?.accreditations ?? []), customAccreditation],
                  },
                }));
                setCustomAccreditation("");
                setAddingCustomAccreditation(false);
              }}
            >
              Add
            </Button>
          </>
        )}
      </AccreditationsContainer>

      <Divider section hidden />
      <AdvancedProfileContainer>
        <h4>Contact details</h4>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              placeholder="Brian McBrain"
              label="Full name"
              required
              value={fullName}
              onChange={(e, { value }) =>
                setBusinessProfile((prevState) => ({
                  ...prevState,
                  profile: {
                    ...prevState.profile,
                    primaryContact: {
                      ...(prevState.profile?.primaryContact || {}),
                      fullName: value,
                    },
                  },
                }))
              }
            />
            <Form.Input
              placeholder="Engineer"
              label="Position or job title"
              required
              value={jobTitle}
              onChange={(e, { value }) =>
                setBusinessProfile((prevState) => ({
                  ...prevState,
                  profile: {
                    ...prevState.profile,
                    primaryContact: {
                      ...(prevState.profile?.primaryContact || {}),
                      jobTitle: value,
                    },
                  },
                }))
              }
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              placeholder="+44 0000 000000"
              label="Phone number"
              required
              value={phoneNumber}
              onChange={(e, { value }) =>
                setBusinessProfile((prevState) => ({
                  ...prevState,
                  profile: {
                    ...prevState.profile,
                    primaryContact: {
                      ...(prevState.profile?.primaryContact || {}),
                      phoneNumber: value,
                    },
                  },
                }))
              }
            />
            <Form.Input
              placeholder="brian@simplydo.co.uk"
              required
              label="Contact email"
              value={email}
              onChange={(e, { value }) =>
                setBusinessProfile((prevState) => ({
                  ...prevState,
                  profile: {
                    ...prevState.profile,
                    primaryContact: {
                      ...(prevState.profile?.primaryContact || {}),
                      email: value,
                    },
                  },
                }))
              }
            />
          </Form.Group>
        </Form>
      </AdvancedProfileContainer>
    </>
  );
};

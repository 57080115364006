import React from "react";
import { Trans } from "react-i18next";

export default function UnlockedAchievementContent({ events }) {
  return (
    <Trans i18nKey="notifications.unlockedAchievement">
      You unlocked the achievement
      <b>
        {{
          achievement: `${events.map((e, index) => {
            let description = index < events.length && index !== 0 ? " " : "";
            description += e.achievement;
            return description;
          })}`,
        }}
      </b>
    </Trans>
  );
}

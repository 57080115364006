import React from "react";
import { Toaster as ToastContainer } from "components/lib/Toast";

const Toaster = () => (
  <ToastContainer
    position="top-right"
    containerStyle={{
      top: 55,
      right: 5,
    }}
    toastOptions={{
      duration: 5000,
      style: {
        padding: "10px 20px",
        fontSize: "20px",
        border: "0.5px solid #e9ebee",
        borderRight: "2px solid #4582d3",
      },
      success: {
        style: {
          border: "0.5px solid #e9ebee",
          borderRight: "2px solid #61d345",
        },
      },
      error: {
        style: {
          borderRight: "2px solid red",
        },
      },
    }}
  />
);

export default Toaster;

export const journey = (api) => ({
  record(forType: string, forId: string | null, action: string) {
    api.maybeAuthenticatedRequest(
      "POST",
      "/journey/record?authFailMechanism=skip",
      { event: { forType, forId, action } },
      () => {},
      () => {},
    );
  },
  pageview(path: string, query?: string) {
    api.maybeAuthenticatedRequest(
      "POST",
      "/journey/view?authFailMechanism=skip",
      { path, query },
      () => {},
      () => {},
    );
  },
});

import React, { useMemo, useState } from "react";
import { Dropdown, DropdownItemProps } from "semantic-ui-react";
import styled from "styled-components";
import util from "utils/utils";

export const StyledSelectableDropdown = styled(Dropdown)`
  a.ui.label {
    display: none !important;
  }
`;

type SelectableItemProps = {
  option: DropdownItemProps;
  isSelected: boolean;
  isHighlighted: boolean;
  onSelect: () => void;
  setHighlight: (isHighlighted: boolean) => void;
};

const StyledSelectableItem = styled(Dropdown.Item)`
  img {
    width: 20px !important;
    height: 20px !important;
  }
`;

const SelectableItem = ({ option, isSelected, onSelect, isHighlighted, setHighlight }: SelectableItemProps) => (
  <StyledSelectableItem
    style={{
      display: "flex",
      alignItems: "center",
      background: isHighlighted ? "rgba(0,0,0,.05)" : null,
    }}
    key={option.key}
    value={option.value}
    text={
      option.description ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>{option.text}</span>
          <span style={{ fontSize: "0.9em", color: "#999", marginTop: 2 }}>{option.description as string}</span>
        </div>
      ) : (
        option.text
      )
    }
    image={option.image}
    icon={isSelected ? "checkmark" : undefined}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onSelect();
    }}
    onMouseEnter={() => setHighlight(true)}
    onMouseLeave={() => setHighlight(false)}
  />
);

export type SelectableDropdownOptions = (DropdownItemProps & { key: string })[];

type ISelectableDropdown = {
  placeholder: string;
  pluralName?: string;
  single?: boolean;
  fluid?: boolean;
  searchable?: boolean;
  className?: string;
  options: SelectableDropdownOptions;
  style?: React.CSSProperties;
  clearable?: boolean;
  searchQuery?: string;
} & (
  | {
      value: string[];
      single?: false | undefined;
      searchIsOption?: false; // searchIsOptions is currently only available for single selections
      onChange: (changedValue: string[], empty: null, option: DropdownItemProps) => void;
    }
  | {
      value: string;
      single: true;
      searchIsOption?: boolean;
      onChange: (changedValue: string, optionKey: string, option: DropdownItemProps) => void;
    }
);

const SelectableDropdown = ({
  value,
  placeholder,
  pluralName = "options",
  onChange,
  options,
  fluid = true,
  searchable = false,
  searchIsOption = false,
  single,
  style,
  clearable = true,
  className,
}: ISelectableDropdown) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightIndex, setHighlightIndex] = useState<null | number>(null);

  const normalisedSelections = single === true ? [value] : value;
  const selections = !value ? [] : normalisedSelections;
  let availableOptions = options;

  if (searchIsOption && single) {
    if (value && !availableOptions.find((option) => option.value === value)) {
      availableOptions = [{ value, text: value }, ...availableOptions];
    }
    if (searchTerm && (!value || (value && searchTerm.toLowerCase() !== value.toLowerCase()))) {
      availableOptions = [{ value: searchTerm, text: `${searchTerm} - Use custom search term` }, ...availableOptions];
    }
  }

  const visibleOptions = useMemo(
    () =>
      searchable
        ? availableOptions.filter(
            (option) =>
              `${option.value}`.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
              `${option.text}`.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
          )
        : availableOptions,
    [searchable, availableOptions, searchTerm],
  );

  let formattedText: React.ReactNode | undefined;
  if (selections) {
    if (selections.length === 1) {
      // Display the tag value directly
      formattedText = availableOptions.find((o) => o.value === selections[0])?.text || undefined;
    } else if (selections.length > 1) {
      // Display pluralised text, single option not possible
      formattedText = util.pluralise(selections.length, "", `${pluralName} selected`, true);
    }
  }

  const selectOption = (isSelected: boolean, option: DropdownItemProps) => {
    if (single === true) {
      onChange(isSelected ? null : `${option.value}`, option.key, option);
    } else if (isSelected) {
      onChange(
        selections.filter((v) => v !== option.value),
        null,
        option,
      );
    } else {
      onChange([...selections, `${option.value}`], null, option);
    }
    if (searchable) {
      setSearchTerm("");
    }
    setOpen(false);
  };

  return (
    <StyledSelectableDropdown
      className={`selection ${className}`}
      multiple
      open={open}
      value={selections}
      search={searchable}
      searchQuery={searchable ? searchTerm : undefined}
      onSearchChange={(e, { searchQuery }) => {
        setSearchTerm(searchQuery);
        setOpen(true);
      }}
      onClick={() => setOpen(true)}
      fluid={fluid}
      onClose={() => setOpen(false)}
      clearable={clearable}
      placeholder={placeholder}
      text={formattedText}
      style={style}
      onChange={(e, { value: changedValue }) => {
        if (changedValue.length === 0) {
          if (single === true) {
            return onChange(null, null, null);
          }
          onChange([], null, null);
        }
      }}
      onBlur={() => {
        setHighlightIndex(null);
      }}
      onKeyDown={(e: KeyboardEvent) => {
        const { code } = e;
        if (code === "ArrowDown") {
          setHighlightIndex(highlightIndex !== null ? (highlightIndex + 1) % availableOptions.length : 0);
        }
        if (code === "ArrowUp") {
          let newIndex = highlightIndex - 1;
          if (newIndex < 0) {
            newIndex = availableOptions.length - 1;
          }
          setHighlightIndex(highlightIndex !== null ? newIndex : availableOptions.length - 1);
        }
        if (code === "Enter") {
          if (availableOptions.length === 1) {
            return selectOption(selections.indexOf(`${availableOptions[0].value}`) > -1, availableOptions[0]);
          }
          if (highlightIndex === null) return;
          const isSelected = selections.indexOf(`${availableOptions[highlightIndex].value}`) > -1;
          selectOption(isSelected, availableOptions[highlightIndex]);
        }
      }}
    >
      <Dropdown.Menu>
        {visibleOptions.map((option, i) => {
          const isSelected = selections.indexOf(`${option.value}`) > -1;
          if (option?.isDivider)
            return (
              <React.Fragment key={option.key}>
                <Dropdown.Divider style={{ margin: 0 }} />
                {option.text ? <Dropdown.Header style={{ margin: "5px 0" }}>{option.text}</Dropdown.Header> : null}
              </React.Fragment>
            );
          return (
            <SelectableItem
              key={option.key}
              option={option}
              isSelected={isSelected}
              isHighlighted={highlightIndex === i}
              setHighlight={(isHighlighted) => (isHighlighted ? setHighlightIndex(i) : setHighlightIndex(null))}
              onSelect={() => selectOption(isSelected, option)}
            />
          );
        })}
      </Dropdown.Menu>
    </StyledSelectableDropdown>
  );
};

export default SelectableDropdown;

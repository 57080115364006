import React, { useState, useEffect, useCallback } from "react";
import { Message, Loader, Card, Divider, Icon } from "semantic-ui-react";
import useTheme from "theme/useTheme";
import { useTranslation } from "react-i18next";
import api from "api";
import styled from "styled-components";
import { SearchParams } from "simplydo/core";

const SuccessfulAuthorisationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    border-radius: 5px;
    border: 1px solid #e9ebee;
    background-color: #f8f8f8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 50px 10px;
    min-width: ${({ theme }) => (theme.sizes.isMobile ? undefined : "400px")};
    > h5,
    > span,
    > p {
      margin: 0;
      display: block;
    }
    > h5 {
      font-size: 1.6em;
    }
    > span {
      font-size: 1.1em;
    }
    i.icon {
      font-size: 4em;
    }
  }
`;

const AuthoriseToken = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const authoriseToken = useCallback(() => {
    const params = new SearchParams(window.location.search);
    const token = params.get("token");
    const phrase = params.get("phrase");
    setLoading(true);
    api.auth.authoriseToken(
      token,
      phrase,
      () => {
        setSuccess(true);
        setLoading(false);
      },
      (err) => {
        setErrorMessage(err.message);
        setLoading(false);
      },
    );
  }, []);

  useEffect(() => {
    authoriseToken();
  }, [authoriseToken]);

  if (success) {
    return (
      <SuccessfulAuthorisationContainer>
        <div>
          <Icon name="check circle" color="green" size="huge" />
          <h5>SimplyDo has authorised your login</h5>
          <p>
            Please now return to the
            <b>browser tab</b> or
            <b>mobile app</b> from where you began to authorise your login.
          </p>
        </div>
      </SuccessfulAuthorisationContainer>
    );
  }
  return (
    <Card.Group centered>
      <Card color="yellow" style={{ marginTop: 50, minWidth: !theme.sizes.isMobile ? 400 : undefined }}>
        <Card.Content>
          <Card.Header>SimplyDo is authorising your login</Card.Header>
          {loading && <Card.Meta>{t("entry.login.token.wait")}</Card.Meta>}
          {loading && <Loader centered active inline="centered" />}
          <Divider hidden />
        </Card.Content>
        <Card.Content>
          {errorMessage && (
            <Message color="orange">
              Unable to authorise your token at this time:
              {errorMessage}
            </Message>
          )}
          {success ? (
            <Message success>
              <h3>SimplyDo has authorised your login</h3>
              <p>
                Please now return to the
                <b>browser tab</b> or
                <b>mobile app</b> from where you began to authorise your login.
              </p>
            </Message>
          ) : null}
        </Card.Content>
      </Card>
    </Card.Group>
  );
};

export default AuthoriseToken;

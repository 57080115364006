import React from "react";
import { useRemirrorContext } from "@remirror/react";

import styled from "styled-components";
import Color from "color";

// See: https://docs.remirror.org/guides/advanced-guide/
const InnerEditorWrapper = styled.div<{ $noShadow?: boolean }>`
  display: flex;

  .remirror-editor {
    box-sizing: border-box;
    position: relative;
    line-height: 1.6em;
    width: 100%;
    min-height: 25px !important;
    word-break: normal;
    overflow: hidden !important;
    overflow-wrap: break-word;

    &:focus {
      outline: none;
    }

    &.ProseMirror-focused {
      .ProseMirror-menubar {
        display: none;
      }
    }

    &[contenteditable="false"] {
      box-shadow: 0px 0px 0px 0 !important;
      padding: 0;
    }

    &[contenteditable="true"] {
      padding: 10px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0 0 0 ${({ $noShadow }) => ($noShadow ? 0 : 1)}px rgba(0, 0, 0, 0.1) !important;
      caret-color: ${({ theme }) => theme.primaryColour};
      min-height: 80px !important;
    }

    &[contenteditable="false"] {
      ul,
      ol {
        text-align: left;
      }

      .remirror-resizable-view > div {
        display: none !important;
      }
    }

    img {
      max-width: 100%;
    }

    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    p {
      margin: 0;
      color: black;
    }

    p em {
      letter-spacing: 1.2px;
    }

    a {
      text-decoration: none !important;
      color: ${({ theme }) => Color(theme.primaryColour).desaturate(0.25).hex()};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    a,
    span {
      font-family: "Inter";
    }

    .remirror-is-empty {
      font-size: small !important;
      font-weight: normal !important;
    }

    .ProseMirror-selectednode {
      background-color: rgb(245, 248, 250);
    }

    .mention-suggest {
      pointer-events: none;
      cursor: default;
      color: black;
    }

    .mention,
    .mention-suggest {
      color: ${({ theme }) => Color(theme.primaryColour).desaturate(0.25).hex()};
    }

    s > .selection {
      text-decoration: line-through;
    }

    span[data-ext="file"] {
      cursor: pointer;
      background-color: #e4e4e4;
      padding: 2px 4px;
      padding-right: 6px;
      border-radius: 2px;
    }

    span[data-node] {
      max-width: 98%;
      position: relative;
      min-width: 20px;
      min-height: 20px;
      display: inline-block;
      overflow: hidden;
      vertical-align: middle;

      &.mouseDown {
        > * {
          pointer-events: none;
        }
      }
      > * {
        object-fit: contain;
        width: 100%;
        height: 100%;
        display: inline-block;
      }
    }
  }
`;

type IReactTextEditor = {
  noShadow?: boolean;
};

const ReactTextEditor = ({ noShadow }: IReactTextEditor) => {
  const context = useRemirrorContext();
  const { getRootProps } = context;

  // Not allowed to use dict spread for `key`
  const { key, ...props } = getRootProps();

  // @ts-ignore
  return <InnerEditorWrapper $noShadow={noShadow} key={key} {...props} />;
};

export default ReactTextEditor;

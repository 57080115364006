import React, { useState, useEffect, useCallback } from "react";
import { useAppSelector } from "store";
import { Form, Input, Table, Button, Checkbox, Modal, Divider, Popup } from "semantic-ui-react";
import { DateInput } from "components/lib/DateInputs";
import useThrottle from "utils/useThrottle";
import toast from "react-hot-toast";
import moment from "moment";
import api from "api";
import util from "utils/utils";
import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea/lib";
import useTheme from "theme/useTheme";
import OmniSearcher from "components/lib/OmniSearcher";
import { PaginationWithLimiter } from "components/lib/UI";
import LinkRow from "./LinkRow";
import { sdiClickDomain } from "utils/domains";
import { useTranslation } from "react-i18next";

const QueryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  .ui.input,
  .ui.checkbox,
  .ui.dropdown {
    margin-right: 10px;
  }
`;

const SortableTableRow = styled(Table.Row)`
  .not-sortable {
    pointer-events: none;
    cursor: default;
  }
`;

const ShowingClicksFilter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  > h5 {
    margin: 5px 0;
    display: block;
  }
  > .filters {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .ui.checkbox {
      margin-right: 10px;
    }
    .custom-date-chooser {
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      > .field {
        margin: 0px 10px;
        .ui.icon.input {
          height: 30px;
        }
      }
    }
  }
`;

const SortTypes = {
  path: ["pathAsc", "pathDesc"],
  createdAt: ["createdAtNewest", "createdAtOldest"],
  clicks: ["clicksAsc", "clicksDesc"],
};

const ClickDateOptions = {
  last30days: { startDay: 30, endDay: 0 },
  last60days: { startDay: 60, endDay: 0 },
  last90days: { startDay: 90, endDay: 0 },
  custom: { startDay: 30, endDay: 0 },
};

export const DefaultCustomDates = {
  startDay: moment().subtract(30, "days").toDate(),
  endDay: moment().toDate(),
};

type ShortLinkCreatorProps = {
  description: string;
  setDescription: (description: string) => void;
  url: string;
  setUrl: (url: string) => void;
  path: string;
  setPath: (path: string) => void;
};

export const ShortLinkCreator = ({
  description,
  setDescription,
  url,
  setUrl,
  path,
  setPath,
}: ShortLinkCreatorProps) => {
  const [searchResults, setSearchResults] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const user = useAppSelector((state) => state.user);
  const orgId = user?.ownerOrganisation?._id;

  const { t } = useTranslation();

  const updateSearchTerm = useThrottle(
    (term) => {
      setSearchTerm(term);
      api.organisations.searchPotentialShortenedUrls(
        orgId,
        term,
        (data) => {
          setSearchResults(data.searchResults);
          setSearchOpen(true);
        },
        () => toast.error("Unable to search at this time"),
      );
    },
    400,
    [orgId],
  );

  const setLinkData = useCallback(
    (context, item) => {
      setDescription(item.name);
      setUrl(item.link);
      setSearchOpen(false);
      setSearchTerm("");
      setSearchResults(null);
    },
    [setDescription, setUrl],
  );

  return (
    <>
      <p>
        To create a link directly to any {t("generic.challenge")}, group, poll or idea on Simply Do you can search for
        it below.
      </p>
      <OmniSearcher
        searchTerm={searchTerm}
        updateSearchTerm={(term) => updateSearchTerm(term)}
        searchOpen={searchOpen}
        setSearchOpen={setSearchOpen}
        searchResults={searchResults}
        onResultClick={setLinkData}
      />
      <Divider hidden />
      <p>Alternatively, create a link from scratch.</p>
      <Form>
        <Form.Field style={{ maxWidth: 600 }}>
          <label>URL to shorten (e.g. to {t("generic.challengeWithArticle")})</label>
          <Input placeholder="https://..." value={url} onChange={(e) => setUrl(e.target.value)} />
        </Form.Field>
        <Form.Field style={{ maxWidth: 600 }}>
          <label>Short URL (maximum of 30 characters)</label>
          <Input
            label={`${sdiClickDomain}/`}
            placeholder="myurl"
            value={path}
            onChange={(e) => setPath(e.target.value)}
          />
        </Form.Field>
        <Form.Field style={{ maxWidth: 600 }}>
          <label>
            Description - <i>optional</i>
          </label>
          <TextareaAutosize
            value={description}
            onChange={(e) => setDescription((e.target as HTMLTextAreaElement).value)}
            onPointerEnterCapture={undefined} // ?? Type issue with lib
            onPointerLeaveCapture={undefined}
          />
        </Form.Field>
      </Form>
    </>
  );
};

const Links = () => {
  const user = useAppSelector((state) => state.user);
  const [url, setUrl] = useState("");
  const [path, setPath] = useState("");
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState([]);
  const [total, setTotal] = useState(0);
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const { t } = useTranslation();

  const [searchState, setSearchState] = useState({
    startDay: 30,
    endDay: 0,
    query: "",
    sort: "",
    limit: 15,
    page: 1,
    groupByUrl: false,
  });
  const [clickDates, setClickDates] = useState("last30days");
  const [customDates, setCustomDates] = useState(DefaultCustomDates);
  const [clicks, setClicks] = useState({});
  const orgId = user?.ownerOrganisation?._id;
  const theme = useTheme();

  const getShortenedUrls = useCallback(() => {
    api.organisations.getShortenedUrls(
      orgId,
      searchState,
      (data) => {
        setLinks(data.shortenedUrls);
        setTotal(data.total);
        setClicks(data.clicks);
      },
      () => {
        toast.error("Unable to load links at this time");
      },
    );
  }, [orgId, searchState]);

  useEffect(() => {
    getShortenedUrls();
  }, [getShortenedUrls]);

  useEffect(() => {
    setSearchState((prevSearchState) => ({
      ...prevSearchState,
      ...ClickDateOptions[clickDates],
    }));
    setCustomDates(DefaultCustomDates);
  }, [clickDates]);

  const generateDatasets = useCallback((label) => {
    const colour = "#fae7bd";
    return {
      borderColor: util.adjustColour(colour, -0.05),
      pointBackgroundColor: util.adjustColour(colour, -0.05),
      backgroundColor: colour,
      data: [],
      label,
      pointRadius: 4,
    };
  }, []);

  const chartData = useCallback(
    (forLink) => {
      const labels = [];
      const dataset = generateDatasets(forLink.path);
      if (clicks[forLink._id]) {
        Object.keys(clicks[forLink._id]).forEach((d) => labels.push(d));
        Object.values(clicks[forLink._id]).forEach((v) => dataset.data.push(v));
      }
      return { labels, datasets: [dataset] };
    },
    [generateDatasets, clicks],
  );

  const createUrl = useCallback(() => {
    api.organisations.createShortenedUrl(
      user.ownerOrganisation._id,
      { url, path, description },
      (shortenedUrl) => {
        setUrl("");
        setPath("");
        setDescription("");
        setLinkModalOpen(false);
        const newLinks = Object.assign([], links);
        newLinks.splice(0, 0, shortenedUrl);
        setLinks(newLinks);
      },
      (err) => toast.error(err.message),
    );
  }, [user.ownerOrganisation._id, links, url, path, description]);

  const deleteUrl = useCallback(
    (urlId) => {
      util
        .confirm(
          "Really delete this URL?",
          "The URL and associated analytics data will be immediately removed. Be careful - someone else can take your short URL once you delete it.",
        )
        .then(() => {
          api.organisations.deleteShortenedUrl(
            user.ownerOrganisation._id,
            urlId,
            () => {
              const newLinks = Object.assign([], links);
              const index = links.map((l) => l._id).indexOf(urlId);
              newLinks.splice(index, 1);
              setLinks(newLinks);
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {});
    },
    [user.ownerOrganisation._id, links],
  );

  const copyLink = useCallback((link) => {
    if (window.navigator?.clipboard) {
      window.navigator.clipboard.writeText(`${sdiClickDomain}/${link}`).then(
        () => toast.success("Link copied to your clipboard"),
        () => toast.error("Unable to copy the link"),
      );
    } else {
      toast.error("Your browser does not support copying");
    }
  }, []);

  const getDirection = useCallback(
    (sortType) => {
      if (searchState.sort.indexOf(sortType) === -1) return null;
      if (searchState.sort.indexOf("Newest") > -1 || searchState.sort.indexOf("Asc") > -1) return "ascending";
      if (searchState.sort.indexOf("Oldest") > -1 || searchState.sort.indexOf("Desc") > -1) return "descending";
      return null;
    },
    [searchState.sort],
  );

  const handleSort = useCallback(
    (sortType) => {
      const sortTypeOptions = SortTypes[sortType];
      const currentSort = sortTypeOptions.indexOf(searchState.sort);
      if (currentSort === sortTypeOptions.length - 1) {
        setSearchState((prevState) => ({ ...prevState, sort: "" }));
      } else {
        setSearchState((prevState) => ({ ...prevState, sort: sortTypeOptions[currentSort + 1] }));
      }
    },
    [searchState],
  );

  const updateClicksDates = useCallback((dateKey, newDate) => {
    const newDateItem = moment(newDate);
    setCustomDates((prevCustomDates) => ({ ...prevCustomDates, [dateKey]: newDateItem.toDate() }));
  }, []);

  useEffect(() => {
    if (clickDates === "custom") {
      const currentDate = moment();
      setSearchState((prevSearchState) => ({
        ...prevSearchState,
        startDay: Math.abs(moment(customDates.startDay).diff(currentDate, "days")),
        endDay: Math.abs(moment(customDates.endDay).diff(currentDate, "days")),
      }));
    }
  }, [clickDates, customDates]);

  return (
    <>
      <h3>Shortened links</h3>
      <p>
        {util.appName()} allows you to create custom links that directly go to {t("generic.challenges")} or{" "}
        {t("generic.ideas")} (or somewhere else) using a shorter and easier-to-read URL. Once created, you can use the
        generated links in social media posts, emails, and on web-pages.
      </p>

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <h5 style={{ margin: "0 0 10px 0" }}>Existing links</h5>
          <QueryContainer>
            <Input
              onChange={(e, { value }) => setSearchState((prevState) => ({ ...prevState, query: value }))}
              value={searchState.query}
              placeholder="Filter links..."
            />
            <Popup
              content={"If checked, sorting is applied on a URL group basis"}
              on="hover"
              hoverable
              trigger={
                <Checkbox
                  label="Group links by URL"
                  checked={searchState.groupByUrl}
                  onClick={() => setSearchState((prevState) => ({ ...prevState, groupByUrl: !prevState.groupByUrl }))}
                />
              }
            />
          </QueryContainer>
        </div>
        <Button
          size="small"
          primary
          icon="plus"
          onClick={() => setLinkModalOpen(true)}
          content="Create a new short link"
        />
      </div>
      <ShowingClicksFilter>
        <h5>Show clicks over...</h5>
        <div className="filters">
          <Checkbox
            label="Last 30 days"
            checked={clickDates === "last30days"}
            onClick={() => setClickDates("last30days")}
          />
          <Checkbox
            label="Last 60 days"
            checked={clickDates === "last60days"}
            onClick={() => setClickDates("last60days")}
          />
          <Checkbox
            label="Last 90 days"
            checked={clickDates === "last90days"}
            onClick={() => setClickDates("last90days")}
          />
          <Checkbox label="Custom" checked={clickDates === "custom"} onClick={() => setClickDates("custom")} />
          {clickDates === "custom" ? (
            <div className="custom-date-chooser">
              <DateInput
                name="date"
                placeholder="From"
                value={moment(customDates.startDay).format("YYYY-MM-DD")}
                dateFormat="YYYY-MM-DD"
                onChange={(e, { value }) => updateClicksDates("startDay", value)}
              />
              {" to "}
              <DateInput
                name="date"
                placeholder="To"
                value={moment(customDates.endDay).format("YYYY-MM-DD")}
                dateFormat="YYYY-MM-DD"
                onChange={(e, { value }) => updateClicksDates("endDay", value)}
              />
            </div>
          ) : null}
        </div>
      </ShowingClicksFilter>
      <Table basic="very" sortable>
        {!theme.sizes.isMobile && (
          <Table.Header>
            <SortableTableRow>
              <Table.HeaderCell sorted={getDirection("path")} onClick={() => handleSort("path")}>
                Link
              </Table.HeaderCell>
              <Table.HeaderCell sorted={getDirection("createdAt")} onClick={() => handleSort("createdAt")}>
                Created
              </Table.HeaderCell>
              <Table.HeaderCell sorted={getDirection("clicks")} onClick={() => handleSort("clicks")}>
                Total clicks
              </Table.HeaderCell>
              <Table.HeaderCell className="not-sortable">Clicks (Last 30 days)</Table.HeaderCell>
              <Table.HeaderCell className="not-sortable" />
            </SortableTableRow>
          </Table.Header>
        )}
        <Table.Body>
          {links.map((link, i) => (
            <LinkRow key={i} link={link} deleteUrl={deleteUrl} copyLink={copyLink} chartData={chartData} />
          ))}
        </Table.Body>
      </Table>
      <PaginationWithLimiter
        limit={searchState.limit}
        page={searchState.page}
        total={total}
        text="links"
        updateLimit={(value) => setSearchState((prevState) => ({ ...prevState, limit: value }))}
        updatePage={(value) => setSearchState((prevState) => ({ ...prevState, page: value }))}
      />
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={linkModalOpen}
        onClose={() => setLinkModalOpen(false)}
      >
        <Modal.Header>Create a new link</Modal.Header>
        <Modal.Content>
          <ShortLinkCreator
            description={description}
            setDescription={setDescription}
            url={url}
            setUrl={setUrl}
            path={path}
            setPath={setPath}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={() => setLinkModalOpen(false)} />
          <Button primary icon="check" content="Create" onClick={createUrl} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default Links;

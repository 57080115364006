import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import util from "utils/utils";

import FeedCard from "./FeedCard";

const CardLeft = ({ idea }) => (
  <div style={{ maxHeight: "100%" }}>
    <div className="cover-and-text">
      <Link
        to={`/ideas/${idea._id}`}
        className="image-link"
        style={{
          display: "inline-block",
          width: 50,
          height: 50,
          borderRadius: 10,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundImage: `url(${util.mixinCssUrlFallback(util.ideaCoverImage(idea), util.ideaCoverImage())})`,
        }}
      />
      <div className="content-name">
        <Link to={`/ideas/${idea._id}`}>{idea.name}</Link>
      </div>
    </div>
  </div>
);

class IdeaCard extends Component {
  render() {
    const { idea, currentUser, profileFeed, hideAction, groupFeed, t, feedType } = this.props;
    return (
      <FeedCard
        ownerType={idea.company ? "company" : "user"}
        company={idea.ownerCompany}
        user={idea.owner}
        feedType={feedType}
        action={
          profileFeed || groupFeed || idea.user === currentUser._id
            ? "activityFeed.idea.created"
            : "activityFeed.idea.collaborate"
        }
        date={idea.createdAt}
        toLink={`/ideas/${idea._id}`}
        type={t("generic.idea")}
        cardLeft={<CardLeft idea={idea} />}
        hideAction={hideAction}
      />
    );
  }
}

const mapStateToProps = (state) => ({ currentUser: state.user });
const IdeaCardContainer = connect(mapStateToProps)(IdeaCard);

export default withTranslation()(IdeaCardContainer);

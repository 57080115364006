import React from "react";
import { Icon, Progress, Image, List, Popup } from "semantic-ui-react";
import moment from "moment";
import { InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFactLink } from "../Styles";

const getProgressColor = (rawScore) => {
  const sentimentScore = rawScore * 100;
  if (!sentimentScore) return null;
  if (sentimentScore < 33) return { error: true };
  if (sentimentScore < 66) return { warning: true };
  return { success: true };
};

const CompanyTwitter = ({ twitter }) => {
  if (twitter) {
    const { socialActivity, user, tweets = [], sentimentScore, mentionSentimentScore } = twitter;
    if (!socialActivity) return null;
    return (
      <InfoBoxWidget>
        <InfoBoxWidgetTitleArea>
          <h3>𝕏 - Social analysis</h3>
        </InfoBoxWidgetTitleArea>

        <List divided relaxed="very">
          <List.Item>
            <Image avatar src={user.profile_image_url} />
            <List.Content>
              <List.Header>Company profile</List.Header>
              <List.Description>@{user.username}</List.Description>
            </List.Content>
          </List.Item>

          <List.Item>
            <List.Icon name="users" size="large" verticalAlign="middle" />
            <List.Content>
              <List.Header>Follower count</List.Header>
              <List.Description>{Number(user.public_metrics.followers_count).toLocaleString()}</List.Description>
            </List.Content>
          </List.Item>

          <List.Item>
            <List.Icon name="dashboard" size="large" verticalAlign="middle" />
            <List.Content>
              <List.Header>
                Twitter activity score
                <Popup
                  content="This score is calculated based on tweet history, activity, and frequency."
                  trigger={
                    <span style={{ color: "gray", marginLeft: 10, fontSize: 11 }}>
                      <Icon name="question" />
                      What is this?
                    </span>
                  }
                />
              </List.Header>
              <List.Description>{parseInt(socialActivity.socialActivityScore * 100, 10)}%</List.Description>
            </List.Content>
          </List.Item>

          <List.Item>
            <List.Icon name="smile outline" size="large" />
            <List.Content>
              <List.Description>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    {sentimentScore ? (
                      <>
                        <h4 style={{ marginTop: 0 }}>Tweet sentiment score</h4>
                        <p>The average positive sentiment of this company's own tweets.</p>
                        <Progress percent={sentimentScore * 100} {...getProgressColor(sentimentScore)}>
                          {parseInt(sentimentScore * 100, 10)}%
                        </Progress>
                      </>
                    ) : null}
                  </div>
                  <div>
                    {mentionSentimentScore ? (
                      <>
                        <h4 style={{ marginTop: 0 }}>Mentions sentiment score</h4>
                        <p>The average sentiment of recent tweets mentioning @{user.username}.</p>
                        <Progress percent={mentionSentimentScore * 100} {...getProgressColor(mentionSentimentScore)}>
                          {parseInt(mentionSentimentScore * 100, 10)}%
                        </Progress>
                      </>
                    ) : null}
                  </div>
                </div>
              </List.Description>
            </List.Content>
          </List.Item>

          <List.Item>
            <List.Icon name="at" size="large" />
            <List.Content>
              <List.Header>
                Latest tweets
                <span style={{ marginLeft: 10, color: "gray", fontSize: 11 }}>
                  {user?.public_metrics?.tweet_count} tweet(s)
                </span>
              </List.Header>
              <List.Description>
                {tweets.slice(0, 3).map((tweet) => (
                  <KeyFactLink
                    image={user.profile_image_url}
                    key={tweet.url}
                    title={`${tweet.text}`}
                    fact={`${moment(tweet.created_at).format("lll")}`}
                    url={`https://x.com/${user.username}/status/${tweet.id}`}
                  />
                ))}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyTwitter;

import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import styled from "styled-components";
import { useUserReload } from "utils/useUserReload";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const InvitedActions = ({ events, invitations, onAccept, onReject }) => {
  const navigate = useNavigate();
  const invitationId = events[0].invitation;
  const invitationExists = invitations.find((i) => i._id === invitationId && !i.acceptedAt);
  const reloadUser = useUserReload();

  const acceptInvite = useCallback(() => {
    api.invitations.accept(
      invitationId,
      ({ group }) => {
        onAccept(invitationId);
        reloadUser(() => {
          navigate(`/groups/${group._id}`);
        });
        toast.success("Group joined");
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [invitationId, onAccept, navigate, reloadUser]);

  const rejectInvite = useCallback(() => {
    api.invitations.reject(
      invitationId,
      () => {
        onReject(invitationId);
        toast.success("Group invitation rejected");
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [invitationId, onReject]);

  if (!invitationExists) return null;
  return (
    <ButtonContainer>
      <Button icon="check" color="green" size="small" onClick={acceptInvite} style={{ marginBottom: 5 }} />
      <Button basic icon="trash" size="small" onClick={rejectInvite} />
    </ButtonContainer>
  );
};

export default InvitedActions;

import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Icon, Button } from "semantic-ui-react";
import RowComponent from "components/lib/RowComponent";

function HomeProjectBoards({ boards }) {
  if (!boards || !boards.length) return null;
  return (
    <RowComponent $noBorder>
      <RowComponent.Fill>
        <RowComponent.Title linked to="/challenges">
          Project boards
        </RowComponent.Title>
        <Button as={Link} to="/admin/boards" primary size="mini">
          View all boards
        </Button>
      </RowComponent.Fill>
      {boards && boards.length > 0 && (
        <>
          {boards.slice(0, 3).map((board, index) => (
            <RowComponent.Cell index={index} key={board._id} as={Link} to={`/boards/${board._id}`}>
              <Icon name="compass" color="gray" size="big" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 5,
                  marginRight: 5,
                  position: "relative",
                  top: -1,
                }}
              >
                <RowComponent.Title style={{ color: "#5d5d5d" }} block>
                  {board.name}
                </RowComponent.Title>
                {board.description && <RowComponent.StatusArea>{board.description}</RowComponent.StatusArea>}
              </div>
            </RowComponent.Cell>
          ))}
        </>
      )}
    </RowComponent>
  );
}

const mapStateToProps = (state) => ({ user: state.user });

const HomeProjectBoardsContainer = withTranslation()(connect(mapStateToProps)(HomeProjectBoards));

export default HomeProjectBoardsContainer;

import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";
import FormattedComment from "components/lib/FormattedComment";

export default function DiscussionPostedContent({ ownerGroup = { name: "Undefined" }, events }) {
  return (
    <Trans i18nKey="notifications.groupDiscussion">
      <b>{{ name: util.getEventNames(events) }}</b>
      posted in the group discussion for
      <b>{{ group: ownerGroup.name }}</b>:
      <FormattedComment noLink>"{{ comment: events[0]?.comment }}"</FormattedComment>
    </Trans>
  );
}

import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  position: -webkit-sticky;
  position: sticky;
  ${({ $top, $bottom }) => ($top || !$bottom ? `top: ${$top || 0}px;` : "")}
  ${({ $bottom }) => ($bottom ? `bottom: ${$bottom}px;` : "")}
`;

function Sticky({ top, bottom, style, children }) {
  return (
    <StyledDiv $top={top} $bottom={bottom} style={style}>
      {children}
    </StyledDiv>
  );
}

export default Sticky;

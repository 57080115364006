import React from "react";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import { FakeLink } from "components/lib/UI";

const SidebarContainer = styled.div`
  @media (min-width: 1400px) {
    flex: 0.25 1 20%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-right: 1px solid #babcbe;
  padding: 25px;
  position: -webkit-sticky;
  position: sticky;
  ${({ $top, $bottom }) => ($top || !$bottom ? `top: ${$top || 0}px;` : "")}
  ${({ $bottom }) => ($bottom ? `bottom: ${$bottom}px;` : "")}
  min-height: 100vh;
`;

const HomeSidebar = ({ children, ...props }) => <SidebarContainer {...props}>{children}</SidebarContainer>;

const ContentColumnContainer = styled.div`
  flex: 0.2 1 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => (theme.sizes.isMobile ? "" : "25px;")};
`;

const HomeContentColumn = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <ContentColumnContainer {...props} theme={theme}>
      {children}
    </ContentColumnContainer>
  );
};

const ViewMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ViewMore = ({ onClick, children }) => (
  <ViewMoreContainer>
    <FakeLink onClick={onClick} withBorder>
      {children}
    </FakeLink>
  </ViewMoreContainer>
);

export { HomeSidebar, HomeContentColumn, ViewMore };

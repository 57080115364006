import React from "react";
import { Input } from "semantic-ui-react";

const ShortText = ({ field, fieldValue = "", updateIdea, isEditing, inputRef, handleKeyDown }) => {
  if (!isEditing) return <span>{fieldValue}</span>;
  return (
    <div>
      <Input
        fluid
        value={fieldValue}
        onChange={(e) => updateIdea(e.target.value, ["templated", field.id])}
        ref={inputRef}
        readOnly={!isEditing}
        onKeyDown={(event) => handleKeyDown(event)}
      />
    </div>
  );
};

export default ShortText;

import { useState } from "react";
import { Image } from "semantic-ui-react";

type ImageWithFallbackProps = {
  fallbackSrc?: string;
} & React.ComponentProps<typeof Image>;

export const ImageWithFallback = ({ fallbackSrc, src, ...props }: ImageWithFallbackProps) => {
  const [safeSrc, setSafeSrc] = useState(src);
  return (
    <Image
      src={safeSrc}
      onError={() => {
        if (fallbackSrc !== safeSrc && fallbackSrc) {
          setSafeSrc(fallbackSrc);
        }
      }}
      {...props}
    />
  );
};

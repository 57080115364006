import React, { useRef, useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import smoothscroll from "smoothscroll-polyfill";
import { SearchParams } from "simplydo/core";

import NotFound from "components/NotFound";
import PageLoadError from "components/lib/PageLoadError";
import { useIdeaEditor, useEditControls, useAssessmentEditor } from "./hooks";
import IdeaStatusBar from "./StatusBar";
import EditToolbar from "./EditToolbar";
import IdeaHeader from "./Header";
import IdeaSideBar from "./SideBar";
import IdeaBody from "./Body";

import IdeaHelmet from "./Components/IdeaHelmet";
import AssignIdeaModal from "./Components/AssignIdea";

smoothscroll.polyfill();

export const IdeaPage = ({ user, t }) => {
  const isIdeaPage = util.activePath(/\/ideas([A-z0-9])*\/?([A-z0-9])*\/?$/gi);

  const params = useParams();
  const ideaId = params?.id;

  const navigate = useNavigate();

  const {
    ideaState,
    updateIdea,
    reloadIdea,
    undoChanges,
    redoChanges,
    canUndo,
    canRedo,
    otherUsers,
    websocketConnected,
    externalChanges,
  } = useIdeaEditor(ideaId);
  const [emptyRequiredField, setEmptyRequiredField] = useState(false);
  const { idea, unsaved, isLoading, isLoaded, isSaving, errorMessage, notFound, savedAt } = ideaState;
  const ideaChallenge = idea?.challenge;
  const userId = user?._id;

  const editControls = useEditControls(user, idea);
  const [editState, setEditing] = editControls;
  const { canEdit, isEditing } = editState;

  const { assessmentState, updateAssessment, submitAssessment } = useAssessmentEditor(idea);
  const {
    assessment,
    isLoading: assessmentLoading,
    savedAt: assessmentSavedAt,
    assessmentIsSubmissable,
  } = assessmentState;

  const [assignIdeaModalOpen, setAssignIdeaModalOpen] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);

  const sectionRefs = useRef({});

  const [ideaSetupComplete, setIdeaSetupComplete] = useState(false);
  const [isSettingUp, setIsSettingUp] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [authType, setAuthType] = useState(null);

  const ideaUser = idea?.user;
  const ideaAuthToken = idea?.authToken;

  /*
    Handle any post-load effects
    For now this is opening the assignment modal, if the idea was previously anonymous, or the url contains an auth token for sms based ideas
  */
  const setupIdea = useCallback(() => {
    if (!isSettingUp && !ideaSetupComplete && isLoaded && userId && !ideaUser) {
      setIsSettingUp(true);
      let publicAuthToken = null;
      if (util.localStorageIsSupported()) {
        publicAuthToken = localStorage.getItem(`ideaAuthToken:${ideaId}`);
      }
      const params = new SearchParams(window.location.search);
      const smsAuthToken = params.get("smsAuthToken");
      if (publicAuthToken && ideaAuthToken === publicAuthToken) {
        setAssignIdeaModalOpen(true);
        setAuthToken(publicAuthToken);
      } else if (smsAuthToken && ideaAuthToken === smsAuthToken) {
        setAssignIdeaModalOpen(true);
        setAuthToken(smsAuthToken);
        setAuthType("sms");
      }
      setIdeaSetupComplete(true);
      setIsSettingUp(false);
    }
  }, [ideaId, isLoaded, ideaUser, ideaAuthToken, ideaSetupComplete, isSettingUp, userId]);

  useEffect(() => setupIdea(), [setupIdea]);

  /*
    Once idea is loaded, scroll to the part of the page requested
  */
  useEffect(() => {
    let hashTimer;
    if (isLoaded) {
      hashTimer = setTimeout(() => {
        const { hash } = document.location;
        if (hash) {
          const elem = document.getElementById(hash.replace("#", ""));
          if (elem) elem.scrollIntoView();
        }
      }, 500);
    }
    return () => clearTimeout(hashTimer);
  }, [isLoaded]);

  const setRef = useCallback((ref, itemId) => {
    sectionRefs.current[itemId] = ref;
  }, []);

  // Attempt to scroll to where the user was previously editing, when changing into preview mode
  // Whenever the user switches to preview, we save their scroll position when editing. Once returned to editing, we scroll back there
  const [scrollPosition, setScrollPosition] = useState(0);
  const [editingScrollPosition, setEditingScrollPosition] = useState(0);
  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const timeout = useRef();
  const setEditingAndScroll = useCallback(
    (newIsEditing) => {
      setEditing(newIsEditing);
      if (!newIsEditing) {
        setEditingScrollPosition(scrollPosition);
      } else {
        if (timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = null;
        }
        timeout.current = setTimeout(() => {
          window.scrollTo({ top: editingScrollPosition, left: 0, behavior: "smooth" });
        }, 500);
      }
    },
    [editingScrollPosition, scrollPosition, setEditing],
  );

  const scrollToRequired = useCallback((sectionId) => {
    const sectionOffset = sectionRefs.current[sectionId]?.offsetTop || 0;
    window.scrollTo({ top: sectionOffset + 64, left: 0, behavior: "smooth" });
    setEmptyRequiredField(true);
  }, []);

  const onSubmit = useCallback(
    (isSubmitted) => {
      updateIdea(isSubmitted, ["isSubmitted"], true);
      setEmptyRequiredField(false);
    },
    [updateIdea],
  );

  const deleteIdea = useCallback(() => {
    util
      .confirm(t("ideas.delete.title"), t("ideas.delete.info"))
      .then(() => {
        api.ideas.delete(
          ideaId,
          () => {
            navigate(!user ? `/challenges/${ideaChallenge}` : "/ideas");
            toast(t("generic.deleted", { type: "Idea" }));
            if (util.localStorageIsSupported()) {
              localStorage.removeItem(`ideaAuthToken:${ideaId}`);
            }
          },
          (err) => toast.error(err.message),
        );
      })
      .catch(() => {});
  }, [ideaId, t, navigate, user, ideaChallenge]);

  const leaveIdea = useCallback(() => {
    util
      .confirm(t("ideas.teammates.leave.title"), t("ideas.teammates.leave.info"))
      .then(() => {
        api.ideas.removeCollaborator(
          ideaId,
          userId,
          ({ authors, collaborators }) => {
            updateIdea(authors, ["authors"], true);
            updateIdea(collaborators, ["collaborators"], true);
            reloadIdea();
          },
          (err) => toast.error(err.message),
        );
      })
      .catch(() => {});
  }, [ideaId, userId, t, updateIdea, reloadIdea]);

  const assignIdea = useCallback(() => {
    setIsAssigning(true);
    const data = { authToken, authType };
    api.ideas.assign(
      ideaId,
      data,
      () => {
        setAssignIdeaModalOpen(false);
        setIsAssigning(false);
        updateIdea(userId, ["user"], true);
        updateIdea([user], ["authors"], true);
      },
      () => setIsAssigning(false),
    );
  }, [ideaId, updateIdea, user, userId, authToken, authType]);

  const removeIdeaFromMerge = useCallback(
    (mergeIdeaId, ideaId, onComplete = () => {}, onError = () => {}) => {
      // If you're removing the 2nd to last idea from a merge, then we delete the merge container, as there'd be no point in having it
      const willDeleteMergeContainer = idea?.children?.length <= 2;
      util
        .confirm(
          `Are you sure you want to remove this ${t("generic.idea")} from the merge?`,
          `The ${t("generic.idea")} will not be deleted and will remain in the ${t("generic.challenge")}. You can re-add it to the merge at any time.${willDeleteMergeContainer ? `The merge container will be deleted as it will only contain one ${t("generic.idea")}.` : ""}`,
        )
        .then(() => {
          api.ideas.removeIdeaFromMerge(
            mergeIdeaId,
            ideaId,
            () => {
              if (willDeleteMergeContainer) {
                const remainingIdea = idea.ownerChildren.find((child) => child !== ideaId);
                // If we delete the merge container, return to the challenge
                navigate(`/ideas/${remainingIdea._id}`);
              }
              updateIdea(
                idea.children.filter((child) => child !== ideaId),
                ["children"],
                true,
                () => {},
                false,
              );
              updateIdea(
                idea.ownerChildren.filter((child) => child._id !== ideaId),
                ["ownerChildren"],
                true,
                () => {},
                false,
              );
              onComplete(mergeIdeaId, ideaId);
            },
            (err) => {
              toast.error(err.message);
              onError(mergeIdeaId, ideaId);
            },
          );
        });
    },
    [navigate, idea?.children, idea?.ownerChildren, updateIdea, t],
  );
  if (errorMessage)
    return <PageLoadError title={t(user ? "ideas.errorUser" : "ideas.errorNoUser")} message={errorMessage} />;
  if (notFound) return <NotFound />;

  return (
    <div style={{ position: "relative" }}>
      {idea ? <IdeaHelmet idea={idea} /> : null}

      <AssignIdeaModal
        isOpen={assignIdeaModalOpen}
        assignIdea={assignIdea}
        onClose={() => setAssignIdeaModalOpen(false)}
        loading={isAssigning}
      />

      <IdeaStatusBar
        user={user}
        idea={idea}
        loading={isLoading}
        isSaving={isSaving}
        unsaved={unsaved}
        isEditing={isEditing}
        setEditing={setEditingAndScroll}
        savedAt={savedAt}
        onSubmit={onSubmit}
        otherUsers={otherUsers}
        websocketConnected={websocketConnected}
        scrollToRequired={scrollToRequired}
        assessment={assessment}
        submitAssessment={submitAssessment}
        updateIdea={updateIdea}
        assessmentIsSubmissable={assessmentIsSubmissable}
      />

      <EditToolbar
        idea={idea}
        user={user}
        savedAt={savedAt}
        isEditing={isEditing}
        undoChanges={undoChanges}
        redoChanges={redoChanges}
        canUndo={canUndo}
        canRedo={canRedo}
        deleteIdea={deleteIdea}
      />

      <IdeaHeader
        idea={idea}
        loading={isLoading}
        isEditing={isEditing}
        deleteIdea={deleteIdea}
        updateIdea={updateIdea}
        leaveIdea={leaveIdea}
      />

      <IdeaSideBar
        idea={idea}
        isEditing={isEditing}
        loading={isLoading}
        isIdeaPage={isIdeaPage}
        sectionRefs={sectionRefs}
        updateIdea={updateIdea}
        canEdit={canEdit}
        leaveIdea={leaveIdea}
        main={
          <div className="idea">
            <IdeaBody
              idea={idea}
              user={user}
              isSaving={isSaving}
              unsaved={unsaved}
              challenge={idea?.ownerChallenge}
              loading={isLoading}
              externalChanges={externalChanges}
              setRef={setRef}
              sectionRefs={sectionRefs}
              updateIdea={updateIdea}
              scrollToRequired={scrollToRequired}
              onSubmit={onSubmit}
              assessment={assessment}
              assessmentLoading={assessmentLoading}
              assessmentSavedAt={assessmentSavedAt}
              assessmentIsSubmissable={assessmentIsSubmissable}
              updateAssessment={updateAssessment}
              submitAssessment={submitAssessment}
              emptyRequiredField={emptyRequiredField}
              usingAssessmentTemplate={idea?.children?.length ? idea?.ownerChallenge?.ownerIdeaTemplate : undefined}
              removeIdeaFromMerge={removeIdeaFromMerge}
              {...editState}
            />
          </div>
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default withTranslation()(connect(mapStateToProps)(IdeaPage));

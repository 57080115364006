import React, { cloneElement } from "react";
import { Popup } from "semantic-ui-react";

/*
  Reusable function to display a popup with a message if a disabled flag is given, else just render the component
*/

const DisabledExplanationPopup = ({ children, content, disabled, on, hoverable }) => {
  if (typeof children !== "object" || (Array.isArray(children) && (!children.length || children.length > 1)))
    throw new Error("A single React element is required as a child for DisabledExplanationPopup.js");
  if (!disabled) return children;
  // Disabled buttons also disable hoverable popups
  // Therefore instead of disabling it, we apply some disabled styles and remove the onClick, while still allowing pointer events
  return (
    <Popup
      style={{ zIndex: 2000 }}
      trigger={cloneElement(children, {
        disabled: false,
        onClick: () => {},
        style: {
          pointerEvents: "auto",
          opacity: 0.45,
          cursor: "default",
        },
      })}
      content={content}
      on={on || "hover"}
      hoverable={hoverable}
    />
  );
};

export default DisabledExplanationPopup;

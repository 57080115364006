import React from "react";
import { Trans, useTranslation } from "react-i18next";
import util from "utils/utils";

export default function DeadlineSet({ events }) {
  const { t } = useTranslation();
  const ideaStr = util.pluralise(events.length, t("generic.idea"), t("generic.ideas"), false);
  return (
    <Trans i18nKey="notifications.ideaDeadlineSet">
      A new deadline has been assigned to your {{ ideaStr }}
      <b>{{ ideas: util.getEventNames(events, "ownerIdea") }}</b>. Make sure you submit your idea before the deadline!
    </Trans>
  );
}

import React, { useCallback } from "react";
import { Form, Checkbox } from "semantic-ui-react";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-left: 15px;
`;

const OptionMultiple = ({ field, fieldValue = [], updateIdea, isEditing }) => {
  /*
    If not editing, only show the selected options
  */
  const { options = [] } = field;

  const toggleCheckbox = useCallback(
    (option) => {
      if (!isEditing) return;
      const isChecked = fieldValue.indexOf(option) > -1;
      if (isChecked)
        updateIdea(
          fieldValue.filter((value) => value !== option),
          ["templated", field.id],
        );
      else updateIdea([...fieldValue, option], ["templated", field.id]);
    },
    [isEditing, fieldValue, field.id, updateIdea],
  );

  return (
    <StyledForm>
      {options
        .filter((option) => isEditing || fieldValue.indexOf(option) > -1)
        .map((option) => (
          <Form.Field key={option}>
            <Checkbox
              label={option}
              checked={fieldValue.indexOf(option) > -1}
              readOnly={!isEditing}
              onChange={() => toggleCheckbox(option)}
            />
          </Form.Field>
        ))}
    </StyledForm>
  );
};

export default OptionMultiple;

import React, { useState } from "react";
import { Form, Grid, Button, Header } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import api from "api";
import { SearchParams } from "simplydo/core";

export function LoginNewOrg({ organisation, t, utmLink, utmChallenge }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [challengeResponse, setChallengeResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function login() {
    setLoading(true);
    api.auth.login(
      {
        email,
        password,
        org: organisation._id,
        challengeResponse,
        utmLink,
        utmChallenge,
      },
      () => {
        setLoading(false);
        const params = new SearchParams(window.location.search);
        const then = params.get("then");
        if (then) {
          navigate(then);
        } else {
          navigate("/");
        }
      },
      (err) => {
        if (organisation?.registrationChallenge) {
          toast.error("Registration challenge failed, please check you have met the requirements for sign-up");
        } else {
          toast.error(err.message);
        }
        setLoading(false);
      },
    );
  }

  return (
    <div>
      <Grid style={{ marginTop: 30 }}>
        <Grid.Row centered>
          <Grid.Column mobile={15} computer={5} tablet={5}>
            <Header as="h2">{t("entry.loginNewOrg.title", { orgName: organisation?.name })}</Header>
            <p>{t("entry.loginNewOrg.info", { orgName: organisation?.name })}</p>
            <Form>
              <Form.Field style={{ marginTop: 30 }}>
                <label>{t("entry.login.email")}</label>
                <input
                  tabIndex="1"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  placeholder="mary@example.com"
                />
              </Form.Field>
              <Form.Field style={{ marginTop: 30 }}>
                <label>
                  {t("entry.login.password")}
                  <Link tabindex="4" to="/password/forgotten" style={{ float: "right" }}>
                    {t("entry.forgottenPassword.title")}{" "}
                  </Link>
                </label>
                <input
                  tabIndex="2"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  type="password"
                  placeholder={t("entry.login.yourPass")}
                />
              </Form.Field>
              {organisation?.registrationChallenge?.type === "custom" && (
                <Form.Field style={{ marginTop: 30 }}>
                  <Form.Input
                    tabindex="3"
                    fluid
                    label={organisation.registrationChallenge.question}
                    value={challengeResponse}
                    onChange={(e) => setChallengeResponse(e.target.value)}
                  />
                </Form.Field>
              )}
              <Button
                tabindex="4"
                style={{ marginTop: 30 }}
                fluid
                loading={loading}
                type="submit"
                primary
                onClick={login}
              >
                {t("entry.login.title")}
              </Button>
              <Button style={{ marginTop: 10 }} as={Link} to="/" fluid basic>
                {t("generic.cancel")}
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { utmLink, utmChallenge } = state.auth;
  return {
    organisation: state.organisations.urlOrganisation,
    utmLink,
    utmChallenge,
  };
};

export default withTranslation()(connect(mapStateToProps)(LoginNewOrg));

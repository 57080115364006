import CreditSafeIcon from "src/images/creditsafe-icon.png";
import CrunchbaseIcon from "src/images/crunchbase-icon.png";
import CompaniesHouseIcon from "src/images/companiesHouse-icon.png";

const revenueMap = {
  r_00000000: "Less than $1M",
  r_00001000: "$1M to $10M",
  r_00010000: "$10M to $50M",
  r_00050000: "$50M to $100M",
  r_00100000: "$100M to $500M",
  r_00500000: "$500M to $1B",
  r_01000000: "$1B to $10B",
  r_10000000: "$10B+",
};

const employeeCountMap = {
  c_00001_00010: "1-10",
  c_00011_00050: "11-50",
  c_00051_00100: "51-100",
  c_00101_00250: "101-250",
  c_00251_00500: "251-500",
  c_00501_01000: "501-1000",
  c_01001_05000: "1001-5000",
  c_05001_10000: "5001-10000",
  c_10001_max: "10001+",
};

const crunchbaseImageUrl = "https://res.cloudinary.com/crunchbase-production/image/upload/";

const pepReducer = (prev, nextArray) =>
  prev + (!nextArray ? 0 : nextArray.reduce((prevValue, isPep) => prevValue + (isPep ? 1 : 0), 0));

const pepMap = {
  firstLevelShareHolders: "First level shareholders",
  immediateLevelShareHolders: "Immediate first-level shareholders e.g. company directors, officers and sponsors",
  globalUltimateOwner: "Global ultimate owner",
  domesticUltimateOwner: "Domestic ultimate owner",
  controllingShareholders: "Controlling shareholders",
  subsidiaries: "Subsidiaries, if available",
  parent: "Parent/holding company, if the current one is a subsidiary",
};

const hasPoliticallyExposedPerson = (bvdData) => {
  const bvdCompanyData = bvdData.Data[0];
  // Iterate through each array in the Bvd data object.
  // If it's an array, we count the number of Truthy values, to get the total number of issues found
  const numberOfIssues = Object.values(bvdCompanyData).reduce((prev, nextArray) => pepReducer(prev, nextArray), 0);
  return {
    numberOfIssues,
    // list of matches for first-level shareholders
    firstLevelShareHolders: pepReducer(0, bvdCompanyData.SH_WORLDCOMPLIANCE_MATCH_EXCEPT_SBE_INDICATOR),
    // list of matches for immediate first-level shareholders, e.g. company directors, officers and sponsors
    immediateLevelShareHolders: pepReducer(0, bvdCompanyData.ISH_WORLDCOMPLIANCE_MATCH_EXCEPT_SBE_INDICATOR),
    // match for the global ultimate owner
    globalUltimateOwner: pepReducer(0, bvdCompanyData.GUO_WORLDCOMPLIANCE_MATCH_EXCEPT_SBE_INDICATOR),
    // match for the domestic ultimate owner
    domesticUltimateOwner: pepReducer(0, bvdCompanyData.DUO_WORLDCOMPLIANCE_MATCH_EXCEPT_SBE_INDICATOR),
    // list of matches for controlling shareholders
    controllingShareholders: pepReducer(0, bvdCompanyData.CSH_WORLDCOMPLIANCE_MATCH_EXCEPT_SBE_INDICATOR),
    // subsidiaries
    subsidiaries: pepReducer(0, bvdCompanyData.SUB_WORLDCOMPLIANCE_MATCH_EXCEPT_SBE_INDICATOR),
    // match for parent
    parent: pepReducer(0, bvdCompanyData.HQ_WORLDCOMPLIANCE_MATCH_EXCEPT_SBE_INDICATOR),
  };
};

const companyStatuses = [
  { value: "active", text: "Active" },
  { value: "dissolved", text: "Dissolved" },
  { value: "liquidation", text: "Liquidated" },
  { value: "receivership", text: "Receivership" },
  { value: "administration", text: "Under administration" },
  { value: "voluntary-arrangement", text: "Voluntary arrangement" },
  { value: "converted-closed", text: "Converted closed" },
  { value: "insolvency-proceedings", text: "Insolvency proceedings" },
  { value: "registered", text: "Registered" },
  { value: "removed", text: "Removed" },
];

const sourceInfo = {
  crunchbase: {
    name: "Crunchbase",
    url: "https://www.crunchbase.com/",
    favicon: CrunchbaseIcon,
  },
  crunchbaseLookup: {
    name: "Crunchbase Profile",
  },
  creditsafe: {
    name: "CreditSafe",
    url: "https://www.creditsafe.com/",
    favicon: CreditSafeIcon,
  },
  creditsafeReport: {
    name: "CreditSafe Report",
    url: "https://www.creditsafe.com/",
    favicon: CreditSafeIcon,
  },
  companiesHouse: {
    name: "Companies House",
    url: "https://www.gov.uk/government/organisations/companies-house",
    favicon: CompaniesHouseIcon,
  },
  google: {
    name: "Google",
    url: "https://www.google.com/",
    icon: "google",
  },
  googleMaps: {
    name: "Google Maps",
    url: "https://www.google.com/maps/",
    icon: "map",
  },
  facebook: {
    name: "Facebook",
    url: "https://www.facebook.com/",
    icon: "facebook",
  },
  facebookScrape: {
    name: "Facebook Profile",
    url: "https://www.facebook.com/",
    icon: "facebook",
  },
  serpapi: {
    name: "Serp API",
    url: "https://serpapi.com/",
    favicon: "https://serpapi.com/favicon.ico",
  },
  hunterioLookup: {
    name: "Hunter.io",
    url: "https://hunter.io/",
    favicon: "https://hunter.io/favicon.ico",
  },
  duckduckgo: {
    name: "DuckDuckGo",
    url: "https://duckduckgo.com/",
    favicon: "https://duckduckgo.com/favicon.ico",
  },
  foursquare: {
    name: "Foursquare",
    url: "https://foursquare.com/",
    icon: "foursquare",
  },
  ocrScan: {
    name: "OCR Scan",
  },
  patents: {
    name: "Patents View",
  },
  trlEstimation: {
    name: "TRL Estimator",
  },
  twitter: {
    name: "𝕏",
    url: "https://x.com/",
    icon: "twitter",
  },
  websiteScrape: {
    name: "Company Website",
  },
  website: {
    name: "Company Website",
  },
  blogTask: {
    name: "RSS Feed",
  },
  geocoding: {
    name: "Geolocation API",
  },
};

export {
  revenueMap,
  employeeCountMap,
  hasPoliticallyExposedPerson,
  pepMap,
  crunchbaseImageUrl,
  companyStatuses,
  sourceInfo,
};

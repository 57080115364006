import React, { useState, useEffect, useCallback, useMemo, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Table, Icon, Label, Button, Popup, Modal, Message, Divider } from "semantic-ui-react";
import util from "utils/utils";
import api from "api";
import toast from "react-hot-toast";
import moment from "moment";
import useTheme from "theme/useTheme";
import IdeaPDFExport from "components/lib/PDFExport/IdeaPDFExport";

import { OpenAPI, Other } from "simplydo/interfaces";
import { HandledIdeaPreview as IdeaPreview } from "components/lib/Ideas";
import { FakeLink, TooltipButton } from "components/lib/UI";
import ProductTour from "components/lib/ProductTour";
import { SearchParams } from "simplydo/core";
import { useTranslation } from "react-i18next";

export const AssessmentClosureInfo = ({ ideaTemplate }: { ideaTemplate?: OpenAPI.Schemas["IdeaTemplate"] }) => {
  const { t } = useTranslation();

  if (!ideaTemplate) return null;
  const { assessmentClosed = false, assessmentDeadline } = ideaTemplate;
  if (assessmentClosed) {
    return (
      <Message warning size="small" style={{ marginBottom: 25 }}>
        <p>
          <Icon name="warning" />
          <b>Assessment closed</b>: Assessment is now closed for this {t("generic.challenge")}. You can continue to view
          your assigned ideas and previous assessments. If you think this is in error, please contact an administrator.
        </p>
      </Message>
    );
  }
  if (assessmentDeadline) {
    return (
      <Message info size="small" style={{ marginBottom: 25 }}>
        <p>
          <Icon name="warning" />
          <b>Assessment deadline</b>: Assessment for this {t("generic.challenge")} has a deadline of{" "}
          <b>{moment(assessmentDeadline).format("HH:mm on DD/MM/YY")}</b>. You cannot edit or submit assessments after
          this date.
        </p>
      </Message>
    );
  }
  return null;
};

type IChallengeYourAssessments = {
  challenge: Other.IChallenge;
  getAssignedAssessments: () => void;
  myAssignedAssessments: Other.IIdeaAssignment[];
  setMyAssignedAssessments: Dispatch<SetStateAction<Other.IIdeaAssignment[]>>;
  demoingTour?: string;
};

function ChallengeYourAssessments({
  getAssignedAssessments,
  myAssignedAssessments,
  setMyAssignedAssessments,
  challenge,
  demoingTour,
}: IChallengeYourAssessments) {
  const searchParams = new SearchParams(window.location.search);
  const user = useSelector((state: { user: Other.IUserMe | null }) => state?.user);
  const urlOrganisation = useSelector(
    (state: { organisations: { urlOrganisation: Other.IOrganisation | null } }) =>
      state?.organisations?.urlOrganisation,
  );
  const [previewId, setPreviewId] = useState(searchParams.has("idea") ? searchParams.get("idea") : "");
  const [assessmentEdited, setAssessmentEdited] = useState(false);
  const [tourCheckLoaded, setTourCheckLoaded] = useState(false);
  const [link, setLink] = useState();
  const [reportDownloaded, setReportDownloaded] = useState(false);
  const shouldShowTour = useMemo(
    () => !!challenge?._id && !!myAssignedAssessments.length,
    [challenge, myAssignedAssessments],
  );
  const tourIdea = useMemo(
    () => (myAssignedAssessments.length ? myAssignedAssessments[0]._id : ""),
    [myAssignedAssessments],
  );
  const { ideaTemplateData = {} } = challenge;
  // @ts-ignore
  const { assessmentClosed = false } = ideaTemplateData;
  const challengeId = challenge?._id;
  const userId = user?._id;
  const navigate = useNavigate();
  const theme = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    if (previewId) setAssessmentEdited(false);
  }, [previewId]);

  // If we are about to run the assessment tour, we do not display the idea preview
  const getTour = useCallback(() => {
    if ((user?.ownerOrganisation?._id || urlOrganisation) && !theme.sizes.isMobile) {
      api.organisations.getProductTour(
        user?.ownerOrganisation?._id || urlOrganisation,
        "assessment",
        ({ success, userHasCompletedTour }) => {
          if (success && (!userHasCompletedTour || demoingTour === "assessment")) {
            navigate({ search: "" }, { replace: true });
            setPreviewId("");
          }
          setTourCheckLoaded(true);
        },
        () => setTourCheckLoaded(true),
      );
    } else {
      setTourCheckLoaded(true);
    }
  }, [user, demoingTour, urlOrganisation, navigate, theme.sizes.isMobile]);

  useEffect(() => {
    getTour();
  }, [getTour]);

  const onModalClose = useCallback(() => {
    navigate({ search: "" }, { replace: true });
    setPreviewId("");
    if (assessmentEdited) getAssignedAssessments();
  }, [assessmentEdited, setPreviewId, getAssignedAssessments, navigate]);

  const submitAssessment = (ideaId) => {
    util
      .confirm(
        "Ready to submit your assessment?",
        `Once submitted you will not be able to change your assessment of this ${t("generic.idea")}.`,
      )
      .then(() =>
        api.ideas.submitAssessment(
          ideaId,
          ({ idea: updatedIdea }) => {
            toast.success("Assessment submitted");
            setMyAssignedAssessments((prevState) =>
              prevState.map((ideaAssessment) => {
                if (ideaAssessment._id !== ideaId) return ideaAssessment;
                return { ...ideaAssessment, ...updatedIdea };
              }),
            );
          },
          (err) => {
            toast.error(`Unable to submit your assessment: ${err.message}`);
          },
        ),
      )
      .catch(() => {});
  };

  const submitAllAssessments = useCallback(() => {
    util
      .confirm(
        "Ready to submit your assessments?",
        `Once submitted you will not be able to change any of your assessments in this ${t("generic.challenge")}. Only completed assessments will be submitted.`,
      )
      .then(() => {
        api.challenges.submitAllAssessments(
          challengeId,
          ({ submittedAssessmentIdeaIds }) => {
            toast.success("Assessments submitted");
            setMyAssignedAssessments((prevState) =>
              prevState.map((ideaAssessment) => {
                if (submittedAssessmentIdeaIds.indexOf(ideaAssessment._id) > -1) {
                  return {
                    ...ideaAssessment,
                    assessments: ideaAssessment.assessments.map((prev) => ({ ...prev, isSubmitted: true })),
                  };
                }
                return ideaAssessment;
              }),
            );
          },
          (err) => toast.error(`Unable to submit your assessment: ${err.message}`),
        );
      })
      .catch(() => {});
  }, [challengeId, setMyAssignedAssessments, t]);

  const downloadReport = () => {
    api.reports.individualAssessorReport(
      challenge._id,
      (c) => {
        setLink(c.link);
        setReportDownloaded(true);
      },
      () => {
        toast.error("Error unable to generate report");
      },
    );
  };

  /* Note that myAssignedAssessments is a list of ideas, each with an assessment object, which might contain an assessments array. Each entry in the assessments array is an assessment made by an individual assessor, which might contain a (deeper) assessment object, which references individual idea sections.
   * Example myAssignedAssessments: [
   * {
   *    name: 'Idea name',
   *    _id: 'IdeaID',
   *    assessmentScore: 75,
   *    assessmentSubmittedScore: 75,
   *    assessments: [ // array of assessor's assessments
   *      {
   *        score: 75, // total calculated score across this assessor based on total available
   *        assessorUser: userObject,
   *        assessment: {
   *          'section1ID': {
   *            score: 15, // assessor's score for this section,
   *            notes: 'Notes for this section'
   *          }, ...
   *        },
   *      }, ...
   *    ]
   *  }, ...
   * ]
   *
   * Note that whilst other assessor's assessments may be present in the DB and their names included in the array, if scoped to a single assessor then the total calcualted score will only take into account the scoped assessment.
   */
  const hasUnsubmittedAssessments = myAssignedAssessments.find((assessment) =>
    assessment.assessments.find((x) => x.isSubmitted !== true),
  );

  const hasSubmissableAssessments = myAssignedAssessments.find((assessment) => {
    const myAssessment = assessment.assessments?.find((ideaAssessment) => ideaAssessment.user === userId);
    const assessmentIsSubmissable = util.assessmentIsSubmissable(myAssessment, ideaTemplateData, assessmentClosed);
    return assessmentIsSubmissable;
  });

  return (
    <>
      {/* @ts-ignore */}
      <IdeaPreview
        ideaId={tourCheckLoaded && previewId}
        onAssessmentEdited={() => setAssessmentEdited(true)}
        closeModal={() => onModalClose()}
      />
      <ProductTour
        tour="assessment"
        skipStep={!ideaTemplateData?.assessmentDeadline && ".assessment-tour-step-5"}
        isReady={shouldShowTour}
        customTourFunctions={{
          openIdea: () => setPreviewId(tourIdea),
          closeIdea: () => {
            onModalClose();
          },
        }}
        onComplete={() => setPreviewId("")}
      />
      {!user ? (
        <Message>
          <h3>Please login to view this page</h3>
          <p>Register or login to your SimplyDo account to view your assessments for this {t("generic.challenge")}.</p>
          <Divider section />
          <Button as={Link} to={`/login?then=/challenges/${challenge?._id}/assessments`} primary>
            Login or register
          </Button>
        </Message>
      ) : (
        <>
          <div className="assessment-tour-step-1">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <h3 style={{ display: "block", margin: 0 }}>Your assessments</h3>
            </div>
            <div className="assessment-tour-step-5">
              {/* @ts-ignore */}
              <AssessmentClosureInfo ideaTemplate={ideaTemplateData} />
            </div>
            <Table unstackable basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t("common:capitalise", { key: "generic.idea" })}</Table.HeaderCell>
                  <Table.HeaderCell collapsing>
                    Your total score
                    <Popup
                      position="top right"
                      trigger={<Icon name="question circle" />}
                      content="Automatically calculated based on your assessment & predefined section weightings"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Conflict</Table.HeaderCell>
                  {!theme.sizes.isMobile && <Table.HeaderCell />}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {myAssignedAssessments?.map((a, index) => {
                  const myAssessment = a.assessments?.find((ideaAssessment) => ideaAssessment.user === userId);
                  const assessmentIsSubmissable = util.assessmentIsSubmissable(
                    myAssessment,
                    ideaTemplateData,
                    assessmentClosed,
                  );
                  return (
                    <Table.Row key={a._id} className={"assessment-tour-step-2"}>
                      <Table.Cell>
                        {/* @ts-ignore */}
                        <FakeLink onClick={() => setPreviewId(a._id)}>{a.name}</FakeLink>
                      </Table.Cell>
                      <Table.Cell collapsing>
                        {myAssessment?.conflictOfInterest?.hasConflictOfInterest && myAssessment?.isSubmitted ? (
                          "N/A"
                        ) : (
                          <>{Math.round(myAssessment?.score || 0)} %</>
                        )}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        {myAssessment?.isSubmitted ? (
                          <Label size="small" color="green">
                            Submitted
                          </Label>
                        ) : (
                          <Label size="small" color="orange">
                            Not submitted
                          </Label>
                        )}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        {myAssessment?.conflictOfInterest?.hasConflictOfInterest ? (
                          <Label size="small" color="red" basic>
                            Conflict of interest
                          </Label>
                        ) : null}
                      </Table.Cell>
                      {!theme.sizes.isMobile && (
                        <Table.Cell collapsing>
                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {!myAssessment?.isSubmitted && !assessmentClosed ? (
                              <>
                                <TooltipButton
                                  tooltip={
                                    assessmentIsSubmissable
                                      ? undefined
                                      : "To submit an assessment you either have to complete all sections of the assessment or declare a conflict of interest"
                                  }
                                  disabled={!assessmentIsSubmissable}
                                  size="tiny"
                                  inverted
                                  primary
                                  content="Submit assessment"
                                  onClick={() => submitAssessment(a._id)}
                                />
                              </>
                            ) : null}
                            <IdeaPDFExport
                              ideas={[a as unknown as OpenAPI.Schemas["Idea"]]}
                              challenge={challenge as unknown as OpenAPI.Schemas["Challenge"]}
                              trigger={
                                <Button
                                  size="tiny"
                                  basic
                                  content={`Download ${t("generic.idea")} PDF`}
                                  icon="file pdf"
                                />
                              }
                            />
                            <Button
                              primary
                              size="tiny"
                              content={
                                myAssessment?.isSubmitted ? `View ${t("generic.idea")}` : `Assess ${t("generic.idea")}`
                              }
                              className={index === 0 ? "assessment-tour-step-3" : null}
                              icon="eye"
                              onClick={() => setPreviewId(a._id)}
                            />
                          </div>
                        </Table.Cell>
                      )}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: 5,
            }}
          >
            <Popup
              trigger={
                <Button
                  size="tiny"
                  secondary
                  basic
                  content="Download summary report"
                  icon="file excel"
                  onClick={() => downloadReport()}
                />
              }
              content="Generate an excel report containing a summary of your assessments"
            />
            <IdeaPDFExport
              ideas={myAssignedAssessments as unknown as OpenAPI.Schemas["Idea"][]}
              challenge={challenge as unknown as OpenAPI.Schemas["Challenge"]}
              trigger={
                <Button
                  size="tiny"
                  secondary
                  basic
                  content={`Download all ${t("generic.ideas")} as PDF`}
                  icon="file pdf"
                />
              }
            />
            {hasUnsubmittedAssessments && (
              <TooltipButton
                size="tiny"
                icon="check"
                primary
                style={{ maxWith: 200 }}
                disabled={!hasSubmissableAssessments}
                content="Submit all completed assessments"
                tooltip="To submit an assessment you either have to complete all sections of the assessment or declare a conflict of interest"
                className="assessment-tour-step-6"
                onClick={() => submitAllAssessments()}
              />
            )}
          </div>
          <Modal mountNode={document.getElementById("semantic-modal-mount-node")} small open={reportDownloaded}>
            <Modal.Header>Report Generated</Modal.Header>
            <Modal.Content>
              <p>Your assessment report was generated successfully.</p>
            </Modal.Content>
            <Modal.Actions>
              <Button content="Cancel" onClick={() => setReportDownloaded(false)} />
              {/* @ts-ignore */}
              <Button
                as="a"
                secondary
                icon="file pdf"
                onClick={() => setReportDownloaded(false)}
                href={link}
                content="Download report"
              />
            </Modal.Actions>
          </Modal>
        </>
      )}
    </>
  );
}

export default ChallengeYourAssessments;

import { OpenAPI } from "simplydo/interfaces";
import constants from "utils/constants";
import RoleEditor from "../Roles/RoleEditor";

type RolesProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  closeModal: () => void;
};

export const Roles = ({ businessProfile }: RolesProps) => {
  return (
    <RoleEditor
      forType="ideaBusinessProfile"
      forId={businessProfile._id}
      permissionOptions={constants.permissions.ideaBusinessProfile}
      canCreateNewRoles
      canSetRolesAsDefault
    />
  );
};

const authActions = {
  START_GETTING_USER: "START_GETTING_USER",
  FINISH_GETTING_USER: "FINISH_GETTING_USER",
  GET_USER_FAIL: "GET_USER_FAIL",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT: "LOGOUT",
  SET_UTM_LINK: "SET_UTM_LINK",
  SET_UTM_CHALLENGE: "SET_UTM_CHALLENGE",
  SET_CLEAR_UTM: "SET_CLEAR_UTM",
  IP_NOT_ALLOWED: "IP_NOT_ALLOWED",

  startGettingUser() {
    return { type: this.START_GETTING_USER };
  },

  finishGettingUser() {
    return { type: this.FINISH_GETTING_USER };
  },

  getUserFail(err) {
    return {
      type: this.GET_USER_FAIL,
      error: err.message || "Please try again later.",
      showLogin: err?.status === 401,
    };
  },

  login() {
    return { type: this.LOGIN_SUCCESS };
  },

  loginFail() {
    return { type: this.LOGIN_FAIL };
  },

  logout() {
    return { type: this.LOGOUT };
  },

  setUtmLink(utm) {
    return { type: this.SET_UTM_LINK, utm };
  },

  setUtmChallenge(utm) {
    return { type: this.SET_UTM_CHALLENGE, utm };
  },

  setClearUtm(shouldClear) {
    return { type: this.SET_CLEAR_UTM, shouldClear };
  },

  ipNotAllowed(err) {
    return { type: this.IP_NOT_ALLOWED, error: err.message };
  },
};

export default authActions;

import { configureStore } from "@reduxjs/toolkit";
import type { PreloadedState } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import reducers from "reducers";

export type RootState = ReturnType<typeof reducers>;

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: reducers,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  });

const store = setupStore();

// eslint-disable-next-line no-undef
export type AppStore = ReturnType<typeof configureStore>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store };
export default store;

import React from "react";
import { List, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import useTheme from "theme/useTheme";
import styled from "styled-components";

const TaskList = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 5px !important;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.boxShadow};
  h3 {
    color: black;
  }
`;

const HomeTasklist = ({ user, t }) => {
  const { engagements = [], profile = {} } = user;
  const theme = useTheme();
  const tasklistComplete =
    engagements.includes("viewedChallenge") &&
    ((user.ideaCount && user.ideaCount > 0) || engagements.includes("createdIdea")) &&
    profile.avatar;
  if (tasklistComplete) return null;
  return (
    <TaskList theme={theme}>
      <h3>{t("home.tasklist.title", { user })}</h3>
      <p>{t("home.getStarted.info")}</p>
      <Divider />
      <List relaxed="very">
        <List.Item>
          <List.Icon
            size="large"
            name={profile.avatar && (profile.tagline || profile.overview) ? "check circle" : "circle outline"}
            color={profile.avatar && (profile.tagline || profile.overview) ? "green" : null}
          />
          <List.Content style={{ fontSize: 18 }}>
            <Link to="/preferences/profile" style={{ color: "#000", textDecoration: "underline" }}>
              {t("home.tasklist.createProfile")}
            </Link>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon
            size="large"
            name={engagements.includes("viewedChallenge") ? "check circle" : "circle outline"}
            color={engagements.includes("viewedChallenge") ? "green" : null}
          />
          <List.Content style={{ fontSize: 18 }}>
            <Link to="/challenges/discover" style={{ color: "#000", textDecoration: "underline" }}>
              {t("home.tasklist.viewChall")}
            </Link>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon
            size="large"
            name={
              (user.ideaCount && user.ideaCount > 0) || engagements.includes("createdIdea")
                ? "check circle"
                : "circle outline"
            }
            color={(user.ideaCount && user.ideaCount > 0) || engagements.includes("createdIdea") ? "green" : null}
          />
          <List.Content style={{ fontSize: 18 }}>
            <Link to="/challenges/discover" style={{ color: "#000", textDecoration: "underline" }}>
              {t("home.tasklist.createIdea")}
            </Link>
          </List.Content>
        </List.Item>
      </List>
    </TaskList>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(withTranslation()(HomeTasklist));

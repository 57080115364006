import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, Message } from "semantic-ui-react";
import util from "utils/utils";

class TermsCheckbox extends Component {
  render() {
    const { t, agreed, onChange, style } = this.props;
    return (
      <div style={{ ...style }}>
        <p>
          {t("entry.terms.instruction", { appName: util.appName() })}{" "}
          <a href="https://simplydo.co.uk/terms-of-use" target="_blank" rel="noopener noreferrer">
            {t("entry.terms.terms")}
          </a>{" "}
          {t("generic.and")}{" "}
          <a href="https://simplydo.co.uk/privacy-policy" target="_blank" rel="noopener noreferrer">
            {t("entry.terms.privacy")}
          </a>
          .
        </p>
        <div style={{ paddingLeft: 20, padding: 10 }}>
          <Checkbox
            size="large"
            checked={agreed}
            onChange={(e, c) => onChange(c.checked)}
            label={t("entry.terms.iAgree")}
          />
        </div>
        <Message size="small">{t("entry.terms.info")}</Message>
      </div>
    );
  }
}

export default withTranslation()(TermsCheckbox);

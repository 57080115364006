import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledButton = styled(Link)`
  display: block;
  padding: 0.6em 1.25em;
  cursor: pointer;
  min-height: 1em;
  font-weight: 700;
  line-height: 1em;
  border-radius: 0.25rem;
  background-color: ${({ $selected, theme }) => ($selected ? theme.primaryColour : "#fff")};
  color: ${({ $selected, theme }) => ($selected && theme.shouldBeWhiteOnPrimary ? "#fff" : "#333")} !important;
  transition: background-color 0.2s;
  &:hover {
    ${({ $selected }) => !$selected && "background-color: #e9ebee;"}
  }
`;

const HighlightLink = ({ to, selected, content, ...rest }) => (
  <StyledButton to={to} content={content} $selected={selected} {...rest}>
    {content}
  </StyledButton>
);

export default HighlightLink;

import React, { Component } from "react";
import { Grid, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { withTheme } from "styled-components";
import { GiphyGif } from "components/lib/Giphy";

import FormattedComment from "components/lib/FormattedComment";
import FeedCard from "./FeedCard";

const CardLeft = ({ comment, theme }) => {
  const hasComment = !!comment.comment;
  const hasMedia = comment.media && comment.media.length;
  const firstMediaItem = hasMedia ? comment.media[0] : {};
  const firstMediaItemIsImage = firstMediaItem.type === "gif" || firstMediaItem.type === "image";
  const firstMediaIsGif = firstMediaItem.type === "gif";

  return (
    <Grid.Column stretched width={12} verticalAlign="middle" className="comment-column">
      <div className="comment-column-container">
        <Icon style={{ color: theme.primaryColour, marginRight: 15, fontSize: "3em" }} name="newspaper" size="big" />
        {!hasComment && hasMedia && firstMediaItemIsImage ? (
          <>
            {firstMediaIsGif ? (
              <GiphyGif gif={firstMediaItem.data} size="fixed" />
            ) : (
              <img
                style={{
                  borderRadius: 5,
                  objectFit: "contain",
                  maxHeight: 150,
                }}
                alt={firstMediaItemIsImage.value}
                src={firstMediaItem.signedDownloadRequest || firstMediaItem.value}
              />
            )}
          </>
        ) : null}
        {hasComment ? (
          <p style={{ maxHeight: "100%" }}>
            <FormattedComment noLink>"{comment.comment}"</FormattedComment>
          </p>
        ) : null}
      </div>
    </Grid.Column>
  );
};

class GroupDiscussionFeedCard extends Component {
  render() {
    const { comment, theme, hideAction, feedType } = this.props;
    return (
      <FeedCard
        feedType={feedType}
        user={comment.owner}
        action={comment.parent ? "activityFeed.discussion.parentAction" : "activityFeed.discussion.action"}
        date={comment.createdAt}
        toLink={`/groups/${comment.group}/discussion`}
        type="Group feed"
        cardLeft={<CardLeft comment={comment} theme={theme} />}
        hideAction={hideAction}
      />
    );
  }
}

export default withTranslation()(withTheme(GroupDiscussionFeedCard));

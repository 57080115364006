import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Table, Icon } from "semantic-ui-react";
import styled from "styled-components";

const VerificationContainer = styled.div`
  border-radius: 5px;
  box-shadow:
    0 0 0 1px #d4d4d5,
    0 2px #16ab39,
    0 1px 3px #bcbdbd;
  padding: 15px;
  > .header {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

function IdeaCompanyVerification({ idea, report }) {
  if (!report || !idea) return null;
  return (
    <VerificationContainer>
      <div className="header">
        <Icon name="check circle" color="green" /> Company successfully found
      </div>
      <Table basic="very">
        <Table.Body>
          <Table.Row>
            <Table.HeaderCell collapsing>Name</Table.HeaderCell>
            <Table.Cell>{report.companyName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell collapsing>Registration</Table.HeaderCell>
            <Table.Cell>{report.companyNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell collapsing>Address</Table.HeaderCell>
            <Table.Cell>{report.companyAddress}</Table.Cell>
          </Table.Row>
          {report.companyVatNumber && (
            <Table.Row>
              <Table.HeaderCell collapsing>VAT number</Table.HeaderCell>
              <Table.Cell>{report.companyVatNumber}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {report.reportUrl && (
        <Button
          as="a"
          href={report.reportUrl || report.tempReportUrl}
          target="_blank"
          rel="noopener noreferrer"
          download="report.pdf"
          fluid
          primary
          icon="download"
          content="Download report"
        />
      )}
    </VerificationContainer>
  );
}

export default withTranslation()(IdeaCompanyVerification);

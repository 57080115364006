import React from "react";
import { Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const WidgetContainer = styled.div<{
  $noMargin?: boolean;
  $noBorder?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 ${({ $noMargin }) => ($noMargin ? "0" : "20px")};
  margin-bottom: ${({ $noMargin }) => ($noMargin ? "" : "20px")};
  width: 100%;
  border-bottom: ${({ $noBorder }) => ($noBorder ? "" : "1px solid #e3e4e5")};
`;

const RowComponent = ({ children, ...props }) => <WidgetContainer {...props}>{children}</WidgetContainer>;

const Title = styled.h4<{
  $linked?: boolean;
  $wrapping?: boolean;
}>`
  display: block;
  margin: 0;
  align-self: center;
  max-width: 300px;
  @media (max-width: 1399px) {
    max-width: 150px;
  }
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: ${({ $wrapping }) => ($wrapping ? "normal" : "nowrap")};
  overflow: hidden;
  font-weight: 700;
  font-size: 1.07142857rem;
  color: black !important;
  ${({ $linked }) =>
    $linked &&
    `
    text-decoration-line: underline !important;
    text-decoration-style: dashed;
  `};
`;

type RowTitleProps = {
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  block?: boolean;
  linked?: boolean;
  wrapping?: boolean;
  to?: string;
  style?: React.CSSProperties;
};

const RowTitle = ({ children, containerStyle, block, linked, wrapping, style, to }: RowTitleProps) => {
  if (block) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          ...containerStyle,
        }}
      >
        <Title $wrapping={wrapping} style={style}>
          {children}
        </Title>
      </div>
    );
  }
  return (
    <Title as={linked ? Link : null} $wrapping={wrapping} to={to} style={style}>
      {children}
    </Title>
  );
};

const TableCell = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 5px 10px 5px;
  width: 100%;
  border-radius: 10px;
  &:hover {
    background-color: #e3e4e5;
  }
`;

const RowTableCell = ({ children, ...props }) => <TableCell {...props}>{children}</TableCell>;

const HorizontalFlex = styled.div<{ $noMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? "0" : "10px")};
`;

const StatusArea = styled.div`
  color: #c0c0c0;
  display: flex;
  align-items: center;
  line-height: 13px;
  font-size: 14px;
  margin-top: 3px;
`;

const StyledLabel = styled(Label)`
  &&& {
    margin-right: 5px;
  }
`;

type StatusLabelProps = {
  color?: string;
  style?: React.CSSProperties;
};

const StatusLabel = ({ color, style, ...props }: StatusLabelProps) => (
  <StyledLabel {...props} style={{ ...style, marginRight: 5 }} circular color={color} empty />
);

RowComponent.Title = RowTitle;
RowComponent.Cell = RowTableCell;
RowComponent.Fill = HorizontalFlex;
RowComponent.StatusArea = StatusArea;
RowComponent.StatusLabel = StatusLabel;

export default RowComponent;

export const webhooks = (api) => ({
  get(type, typeId, success, fail) {
    api.authenticatedRequest("GET", `/webhooks?type=${type}&typeId=${typeId}`, null, success, fail);
  },
  create(data, success, fail) {
    api.authenticatedRequest("POST", "/webhooks", data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest("DELETE", `/webhooks/${id}`, null, success, fail);
  },
});

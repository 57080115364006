import { OpenAPI } from "simplydo/interfaces";
import ConfigurableTable from "../ConfigurableTable";
import { Link } from "react-router-dom";
import util from "utils/utils";
import { Button, Image, Segment, Table } from "semantic-ui-react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import RoleAssigner from "../Roles/RoleAssigner";
import constants from "utils/constants";
import api from "api";
import { UserChooser } from "../Choosers";
import toast from "react-hot-toast";
import { UserChip } from "../Chips";
import { useTranslation } from "react-i18next";
import actions from "actions";

type UsersProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  closeModal: () => void;
};

export const Users = ({ businessProfile, closeModal }: UsersProps) => {
  const [assignRoleUser, setAssignRoleUser] = useState(null);
  const [members, setMembers] = useState([]);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const canManageUsers = util.hasPermission(user, "ideaBusinessProfile.editMembers", businessProfile._id);
  const { t } = useTranslation();

  const fetchMembers = useCallback(() => {
    api.roles.getUsers(
      "ideaBusinessProfile",
      businessProfile._id,
      {},
      (res) => {
        setMembers(res.users);
      },
      () => {},
    );
  }, [businessProfile._id]);

  const getPendingInvitations = useCallback(() => {
    if (!canManageUsers) {
      return;
    }

    api.invitations.getForType(
      "ideaBusinessProfile",
      businessProfile._id,
      (data) => {
        setPendingInvitations(data.invitations);
      },
      () => {
        toast.error("Failed to get invitations");
      },
    );
  }, [businessProfile._id, canManageUsers]);

  const removePendingInvitation = useCallback((invitationId) => {
    util
      .confirm("Remove invitation", "Are you sure you want to remove this invitation?")
      .then(() => {
        api.invitations.remove(
          invitationId,
          () => {
            setPendingInvitations((prev) => prev.filter((i) => i._id !== invitationId));
            toast.success("Invitation removed. The user can no longer accept it.");
          },
          () => toast.error("Failed to remove invite"),
        );
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetchMembers();
    getPendingInvitations();
  }, [fetchMembers, getPendingInvitations]);

  return (
    <div
      style={{
        display: "flex",
        gap: 12,
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {util.hasPermission(user, "ideaBusinessProfile.editMembers", businessProfile._id) ? (
        <>
          <RoleAssigner
            forType="ideaBusinessProfile"
            forId={businessProfile._id}
            forUsers={assignRoleUser ? [assignRoleUser._id] : []}
            permissionOptions={constants.permissions.ideaBusinessProfile}
            onAssign={() => {
              fetchMembers();
            }}
            onUnassign={() => {
              fetchMembers();
            }}
            modalProps={{
              onClose: () => setAssignRoleUser(null),
            }}
          />
          {pendingInvitations.length > 0 ? (
            <Segment style={{ alignSelf: "stretch" }}>
              <b style={{ fontSize: 18 }}>Pending invitations</b>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>User</Table.HeaderCell>
                    <Table.HeaderCell>Invited by</Table.HeaderCell>
                    <Table.HeaderCell>Created at</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {pendingInvitations.map((r) => (
                    <Table.Row key={r.inviteeUser?._id || r.invitee}>
                      <Table.Cell>{r.inviteeUser ? <UserChip user={r.inviteeUser} /> : r.invitee}</Table.Cell>
                      <Table.Cell>
                        <UserChip user={r.inviterUser} />
                      </Table.Cell>
                      <Table.Cell>{moment(r.createdAt).format("DD/MM/YY")}</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Button icon="trash" basic size="tiny" onClick={() => removePendingInvitation(r._id)} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          ) : null}
        </>
      ) : null}
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          alignSelf: "stretch",
        }}
      >
        <b style={{ fontSize: 22 }}>Members</b>
        {util.hasPermission(user, "ideaBusinessProfile.editMembers", businessProfile._id) ? (
          <UserChooser
            trigger={<Button content="Invite users" primary />}
            forType="ideaBusinessProfile"
            forId={businessProfile._id}
            enabledFeatures={{ search: true, invite: true, persistentToken: true }}
            searchFunction={(term, cb) =>
              api.roles.getPotentialRoleUsers(
                "ideaBusinessProfile",
                businessProfile._id,
                "default",
                { query: term },
                (res) => cb(res.users),
                () => {},
              )
            }
            confirm="Invite users to profile"
            persistentTokenTitle={t("ideaBusinessProfiles.members.link.title")}
            persistentTokenDescription={t("ideaBusinessProfiles.members.link.description")}
            persistentTokenWarning={t("ideaBusinessProfiles.members.link.warning")}
            clearOnComplete
            onComplete={(users) => {
              const emailUsers = users.filter((u) => u.isEmailInvitee);
              if (emailUsers.length) {
                api.invitations.createBulk(
                  {
                    invitees: emailUsers.map((u) => u._id),
                    invitationType: "email",
                    forType: "ideaBusinessProfile",
                    forId: businessProfile?._id,
                  },
                  getPendingInvitations,
                  () => {},
                );
              }

              const internalInvitees = users.filter((u) => !u.isEmailInvitee);
              if (internalInvitees.length > 0) {
                api.invitations.createBulk(
                  {
                    forId: businessProfile?._id,
                    forType: "ideaBusinessProfile",
                    invitees: internalInvitees.map((u) => u._id),
                  },
                  () => {
                    toast.success("Users invited to business profile");
                    getPendingInvitations();
                  },
                  (err) => toast.error(err.message),
                );
              }
            }}
          />
        ) : null}
      </div>
      <ConfigurableTable
        tableKey="businessProfileUsers"
        data={members}
        keyExtractor={(user) => user._id}
        actions={(items) =>
          canManageUsers
            ? [
                {
                  name: "Assign roles",
                  icon: "key",
                  onClick: (items) => setAssignRoleUser(items[0]),
                },
                {
                  name: "Remove from profile",
                  icon: "trash",
                  onClick: (items) => {
                    util
                      .confirm("Remove user", "Are you sure you want to remove this user from the profile?")
                      .then(() => {
                        api.roles.removeUserFromAllRoles(
                          "ideaBusinessProfile",
                          businessProfile._id,
                          items[0]._id,
                          fetchMembers,
                          fetchMembers,
                        );
                      })
                      .catch(() => {});
                  },
                },
              ]
            : items.length === 1 && items[0]._id === user._id
              ? [
                  {
                    name: "Leave profile",
                    icon: "trash",
                    onClick: () => {
                      util
                        .confirm("Leave profile", "Are you sure you want to leave this profile?")
                        .then(() => {
                          api.roles.removeUserFromAllRoles(
                            "ideaBusinessProfile",
                            businessProfile._id,
                            user._id,
                            () => {
                              dispatch(actions.user.updatePermissions(businessProfile._id, []));
                              closeModal();
                            },
                            () => {},
                          );
                        })
                        .catch(() => {});
                    },
                  },
                ]
              : []
        }
        columns={[
          {
            key: "image",
            name: "",
            settingName: "Image",
            width: 40,
            center: true,
            render: ({ item }) => (
              <Link to={`/users/${item._id}`}>
                <Image style={{ objectFit: "cover" }} avatar src={util.avatarUrl(item)} />
              </Link>
            ),
          },
          {
            key: "firstName",
            name: "First name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.firstName}</Link>,
            sortable: true,
          },
          {
            key: "lastName",
            name: "Last name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.lastName}</Link>,
            sortable: true,
          },
          {
            key: "department",
            name: "Department",
            render: ({ item }) => item.profile.department,
            sortable: false,
          },
          {
            key: "roles",
            name: "Roles",
            render: ({ item }) => item.roles.map((r) => r.name).join(", "),
            sortable: false,
          },
          {
            key: "lastSeenAt",
            name: "Last seen",
            render: ({ cell }) => moment(cell).format("DD/MM/YYYY"),
            sortable: false,
          },
        ]}
      />
    </div>
  );
};

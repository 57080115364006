import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Message, Label, Icon, Button, Input, Table, Select, Divider } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import actions from "actions";
import util from "utils/utils";
// @ts-ignore
import { languages, setInterpolationDictionary } from "src/i18n";
import { useAppDispatch, useAppSelector } from "store";
import moment from "moment";

type Token = {
  id?: string;
  platform: string;
  token: string;
  os: string;
  client: string;
  createdAt: string;
};

export function UserSettingsAccount() {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const onLogout = () => dispatch(actions.auth.logout());
  const onSwitchOrganisation = (org) => dispatch(actions.user.switchOrganisation(org));
  const onUpdateAvailableOrganisations = (orgs) => dispatch(actions.user.updateAvailableOrganisations(orgs));

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [loggingOutOthers, setLoggingOutOthers] = useState(false);
  const [deleteAccountString, setDeleteAccountString] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [apiTokens, setApiTokens] = useState<(Token | string)[]>([]);
  const [loggingOutToken, setLogginOutToken] = useState("");

  useEffect(() => {
    api.auth.getApiTokens(
      (newTokens) => {
        setApiTokens(newTokens.tokens);
      },
      () => {},
    );
  }, []);

  const logoutToken = (tokenId: string, index: number) => {
    util
      .confirm(
        "Log out this device?",
        "To access your SimplyDo account again on this device you will need to re-authenticate.",
      )
      .then(
        () => {
          setLogginOutToken(tokenId);
          api.auth.deleteApiToken(
            tokenId,
            index,
            () => {
              setLogginOutToken("");
              if (tokenId) {
                setApiTokens(
                  apiTokens.filter((tokenData) => tokenData !== tokenId && (tokenData as Token).id !== tokenId),
                );
              } else {
                const newTokens = [...apiTokens];
                newTokens.splice(index, 1);
                setApiTokens(newTokens);
              }
            },
            (err) => {
              setLogginOutToken("");
              toast.error(err.message);
            },
          );
        },
        () => {},
      )
      .catch(() => {});
  };

  const logoutOthers = () => {
    setLoggingOutOthers(true);
    util
      .confirm(
        "Log out all other devices?",
        "To access your SimplyDo account again on those devices you will need to re-authenticate.",
      )
      .then(() => {
        api.auth.logoutOthers(
          () => {
            setLoggingOutOthers(false);
            setApiTokens(
              apiTokens.filter((tokenData) => (tokenData as Token).token === api.token || tokenData === api.token),
            );
            toast(t("users.account.options.logOut.success"));
          },
          (err) => {
            toast.error(err.message);
            setLoggingOutOthers(false);
          },
        );
      })
      .catch(() => {
        setLoggingOutOthers(false);
      });
  };

  const deleteAccount = () => {
    if (deleteAccountString === "DELETE") {
      util
        .confirm(t("users.account.options.delete.confirm.title"), t("users.account.options.delete.confirm.info"))
        .then(
          () => {
            setLoggingOutOthers(true);
            api.users.delete(
              user._id,
              {},
              () => {
                api.auth.localLogout(onLogout);
                toast(t("users.account.options.delete.confirm.success"));
                navigate("/");
                setErrorMessage("");
                setLoggingOutOthers(false);
              },
              (err) => {
                setErrorMessage(err.message);
                setLoggingOutOthers(false);
              },
            );
          },
          () => {},
        )
        .catch(() => {});
    } else {
      setErrorMessage('Please type "DELETE" to delete your account');
      setLoggingOutOthers(false);
    }
  };

  const switchOrganisation = (org) => {
    api.users.switchOrganisation(
      user._id,
      org._id,
      (data) => {
        onSwitchOrganisation(data.organisation);
      },
      (err) => toast.error(err.message),
    );
  };

  const leaveOrganisation = (org) => {
    util
      .confirm("Really leave this organisation?", "You may not be able to re-join automatically.")
      .then(() => {
        api.users.leaveOrganisation(
          user._id,
          org._id,
          (data) => {
            onUpdateAvailableOrganisations(data.organisations);
          },
          (err) => toast.error(err.message),
        );
      })
      .catch(() => {});
  };

  const changeLanguage = (language) => {
    const currentLanguage = i18n.language;

    i18n.changeLanguage(language);
    setInterpolationDictionary(user?.ownerOrganisation?.terminology);
    api.users.updateLanguagePreferences(
      user._id,
      language,
      () => {},
      (err) => {
        i18n.changeLanguage(currentLanguage);
        setInterpolationDictionary(user?.ownerOrganisation?.terminology);
        toast.error(err.message);
      },
    );
  };

  return (
    <Container>
      <h3>{t("users.account.options.orgs.title")}</h3>
      <p>{t("users.account.options.orgs.info", { appName: util.appName() })}</p>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("users.account.options.orgs.list.name")}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {user.availableOrganisations &&
            user.availableOrganisations.map((o) => (
              <Table.Row key={o._id}>
                <Table.Cell>{o.name}</Table.Cell>
                <Table.Cell collapsing textAlign="right">
                  {o._id === user.ownerOrganisation._id ? (
                    <Label size="tiny" color="green">
                      {t("users.account.options.orgs.list.primary")}
                    </Label>
                  ) : (
                    <>
                      <Button
                        basic
                        size="mini"
                        content={t("users.account.options.orgs.list.makePrimary")}
                        onClick={() => switchOrganisation(o)}
                      />
                      <Button basic color="orange" size="mini" content="Leave" onClick={() => leaveOrganisation(o)} />
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Message size="tiny">{t("users.account.options.orgs.message")}</Message>
      <Divider />
      <Divider hidden />

      <h3>{t("users.preferences.language.title")}</h3>
      <p>{t("users.preferences.language.info")}</p>
      <Select
        fluid
        value={i18n.language}
        options={languages.map((l) => ({
          flag: l.flag,
          key: l.key,
          value: l.key,
          text: l.name,
        }))}
        onChange={(e, s) => changeLanguage(s.value)}
      />
      <Divider />
      <Divider hidden />

      <h3>Logged-in devices</h3>
      <p>
        A list of all your currently active sessions across devices. You can choose to revoke access here, this will
        cause the device to be logged out immediately.
      </p>
      {apiTokens.length > 0 ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>OS</Table.HeaderCell>
              <Table.HeaderCell>Browser / Device</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {apiTokens.map((apiToken: Token, index: number) => {
              const unwrappedToken = ((apiToken as Token) ? apiToken : { token: apiToken }) as Token;
              return (
                <Table.Row key={apiToken?.token}>
                  <Table.Cell collapsing>
                    <div>
                      {unwrappedToken.platform === "web" ? <Icon name="computer" size="large" /> : null}
                      {unwrappedToken.platform === "mobile" ? <Icon name="mobile alternate" size="large" /> : null}
                      {unwrappedToken.platform === "teams" ? <Icon name="microsoft" size="large" /> : null}
                      {!unwrappedToken.platform ? <Icon name="question" size="large" /> : null}
                    </div>
                  </Table.Cell>
                  <Table.Cell collapsing>{unwrappedToken.os || "Unknown"}</Table.Cell>
                  <Table.Cell collapsing>{unwrappedToken.client || "Unknown"}</Table.Cell>
                  <Table.Cell collapsing>
                    {unwrappedToken.createdAt ? moment(unwrappedToken.createdAt).format("DD/MM/YYYY HH:mm") : "Unknown"}
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="right">
                    {unwrappedToken.token === api.token ? <Label>This device</Label> : null}
                    <Button
                      basic
                      size="mini"
                      content="Log out"
                      onClick={() => {
                        logoutToken(unwrappedToken.id, index);
                      }}
                      loading={loggingOutToken === unwrappedToken.id}
                      disabled={unwrappedToken.token === api.token}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : null}

      <h3>{t("users.account.options.logOut.title")}</h3>
      <p>{t("users.account.options.logOut.info")}</p>
      <div>
        <Button
          basic
          icon="sign out"
          content={t("users.account.options.logOut.button")}
          onClick={logoutOthers}
          loading={loggingOutOthers}
        />
      </div>

      {!user.ownerOrganisation.disableAccountSelfDeletion ? (
        <>
          <Divider />
          <Divider hidden />
          <h3>{t("users.account.options.delete.title")}</h3>
          <p>{t("users.account.options.delete.info", { appName: util.appName() })}</p>
          <Label color="red" ribbon>
            <Icon name="exclamation triangle" /> {t("users.account.options.delete.warningLabel")}
          </Label>
          <Message color="orange">{t("users.account.options.delete.warningMessage")}</Message>
          <p>{t("users.account.options.delete.enterPassword")}</p>
          {errorMessage && !loggingOutOthers && (
            <Message size="small" negative>
              <Icon name="warning circle" />
              {errorMessage}.
            </Message>
          )}
          <Input
            size="small"
            fluid
            value={deleteAccountString}
            action={
              <Button color="red" content={t("users.account.options.delete.confirm.title")} onClick={deleteAccount} />
            }
            placeholder="DELETE"
            onChange={(e) => setDeleteAccountString(e.target.value)}
          />
        </>
      ) : null}
    </Container>
  );
}

export default UserSettingsAccount;

import React from "react";
import { Emoji, emojiIndex } from "emoji-mart";
import styled from "styled-components";

const legacyStampMapper = {
  green: "green_apple",
  yellow: "full_moon",
  orange: "large_orange_diamond",
  red: "red_circle",
  purple: "aquarius",
  blue: "large_blue_circle",
  pink: "ribbon",
};

const SelectedContainer = styled.div<{ $size?: number; $selected?: boolean; $canClick?: boolean }>`
  width: ${({ $size }) => ($size ? `${$size}px` : "20px")};
  height: ${({ $size }) => ($size ? `${$size}px` : "20px")};
  border-radius: 50%;
  background-color: ${({ theme, $selected }) => ($selected ? theme.primaryColour : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ $canClick }) => ($canClick ? "pointer" : "inherit")};
  button,
  span {
    ${({ $canClick }) => ($canClick ? "pointer" : "inherit")};
  }
  ${({ $canClick }) =>
    $canClick &&
    `
    span {
      &:hover {
        opacity: 75%;
      }
    }
  `}

  > span {
    display: flex !important;
  }
`;

type EmojiComponentProps = {
  selected?: boolean;
  emoji: any;
  onClick?: (emoji: any) => void;
  size?: number;
  style?: React.CSSProperties;
};

const EmojiComponent = ({ selected, emoji, onClick, size, style, ...rest }: EmojiComponentProps) => {
  if (emoji?.id in legacyStampMapper) {
    emoji.id = legacyStampMapper[emoji.id];
  }
  if (!(emoji?.id in emojiIndex.emojis)) {
    return null;
  }
  return (
    <SelectedContainer
      $selected={selected}
      $canClick={!!onClick}
      $size={size}
      style={style}
      onClick={onClick ? () => onClick(emoji) : null}
    >
      <Emoji emoji={emoji} size={size} style={{ display: "flex" }} {...rest} />
    </SelectedContainer>
  );
};

export default EmojiComponent;

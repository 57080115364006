import React from "react";
import { Trans } from "react-i18next";

const PollClosed = ({ context }) => {
  return (
    <Trans i18nKey="notifications.pollClosed">
      The poll <b>{{ pollName: context.pollName || "Deleted poll" }}</b> in your organisation has now been closed
    </Trans>
  );
};

export default PollClosed;

import api from "api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";
import { CheckboxHeader } from "./UI";
import { Icon, Form, Divider } from "semantic-ui-react";
import { formatOption } from "components/challenges/Challenge/Settings/Impacts";
import { RadioCard } from "components/RadioCard";
import { useTranslation } from "react-i18next";
import useThrottle from "utils/useThrottle";

type Challenge = OpenAPI.GET<"/challenges/{id}">["response"];
type Impact = OpenAPI.GET<"/organisations/{id}/impacts">["response"]["impacts"][number];
type ImpactStatement = OpenAPI.Schemas["ImpactStatements"];

type ImpactStatementProps = {
  statementDefintion: Challenge["impactStatements"];
  ideaId: string;
  onUpdate?: (statement: ImpactStatement) => void;
  editable?: boolean;
};

const IdeaImpactStatement = ({ statementDefintion, ideaId, onUpdate, editable }: ImpactStatementProps) => {
  const { t } = useTranslation();
  const [impactList, setImpactList] = useState<Array<Impact>>([]);
  const user = useAppSelector((state) => state.user);

  const [impactStatement, setImpactStatement] = useState<ImpactStatement>({});

  const saveImpactStatement = useThrottle(
    (newStatement: ImpactStatement) => {
      // If there is no statement yet, or the currently active one is submitted, create a new one
      if (!newStatement._id || newStatement.isSubmitted) {
        api.ideas.createImpactStatement(
          ideaId,
          { impacts: newStatement.impacts },
          ({ impactStatement }) => {
            onUpdate?.(impactStatement);
          },
          () => {},
        );
      } else {
        api.ideas.updateImpactStatement(
          ideaId,
          newStatement._id,
          { impacts: newStatement.impacts },
          ({ impactStatement }) => {
            onUpdate?.(impactStatement);
          },
          () => {},
        );
      }
    },
    400,
    [ideaId],
  );

  const updateImpactStatement = useCallback(
    (setter) => {
      const finalValue = setter(impactStatement);

      setImpactStatement(finalValue);
      saveImpactStatement(finalValue);
    },
    [impactStatement, saveImpactStatement],
  );

  const updateSingleImpact = useCallback(
    (impactId: string, setter) => {
      updateImpactStatement((prev) => {
        const newImpact = setter(prev?.impacts?.[impactId] ?? {});
        return {
          ...prev,
          impacts: {
            ...(prev.impacts ?? {}),
            [impactId]: {
              ...(prev.impacts?.[impactId] ?? {}),
              ...newImpact,
            },
          },
        };
      });
    },
    [updateImpactStatement],
  );

  useEffect(() => {
    let isMounted = true;
    if (user.ownerOrganisation._id) {
      api.organisations.getImpacts(
        user.ownerOrganisation._id,
        ({ impacts }) => {
          if (!isMounted) return;
          setImpactList(impacts);
        },
        () => {},
      );
    }
    api.ideas.getActiveImpactStatement(
      ideaId,
      (response: OpenAPI.GET<"/ideas/{idea_id}/impactStatement/active">["response"]) => {
        if (!isMounted) return;
        setImpactStatement(response.impactStatement);
      },
      () => {},
    );

    return () => {
      isMounted = false;
    };
  }, [user.ownerOrganisation._id, ideaId]);

  const enabledImpacts = useMemo(() => {
    return impactList.filter((impact) => statementDefintion?.impacts?.[impact._id]?.enabled);
  }, [impactList, statementDefintion]);

  return (
    <div>
      {enabledImpacts
        .filter((impact) => editable || impactStatement?.impacts?.[impact._id]?.enabled)
        .map((impact, index) => {
          return (
            <>
              {index !== 0 ? <Divider fitted /> : null}
              <div key={impact._id} style={{ marginTop: index === 0 ? 0 : 20 }}>
                <CheckboxHeader
                  disabled={!editable}
                  header={
                    <div>
                      {impact.icon ? <Icon name={impact.icon} /> : null}
                      {impact.name}
                    </div>
                  }
                  description={impact.description}
                  as="h5"
                  checked={impactStatement?.impacts?.[impact._id]?.enabled}
                  onChange={() => {
                    updateSingleImpact(impact._id, (prev) => ({
                      ...prev,
                      enabled: !prev?.enabled,
                    }));
                  }}
                  willHideChildren
                >
                  <div style={{ marginTop: 15 }}>
                    {statementDefintion.impacts[impact._id].options ? (
                      <>
                        <RadioCard.Group
                          editable={editable}
                          defaultValue={
                            impactStatement?.impacts?.[impact._id]?.choice === "custom"
                              ? "custom"
                              : impactStatement?.impacts?.[impact._id]?.value
                          }
                          onValueChange={(value) => {
                            const option = statementDefintion.impacts[impact._id].options.find(
                              (option) => formatOption(impact, option) === value,
                            );

                            updateSingleImpact(impact._id, (prev) => ({
                              ...prev,
                              choice: value === "custom" ? "custom" : option,
                              value: value === "custom" ? undefined : value,
                            }));
                          }}
                        >
                          {statementDefintion.impacts[impact._id].options?.map((option) => {
                            const optionValue = formatOption(impact, option);
                            return (
                              <RadioCard.Item key={optionValue} value={optionValue}>
                                {optionValue}
                              </RadioCard.Item>
                            );
                          })}
                          {statementDefintion.impacts[impact._id].customOptionEnabled ? (
                            <RadioCard.Item value="custom">Other</RadioCard.Item>
                          ) : null}
                        </RadioCard.Group>
                      </>
                    ) : null}
                    <Form style={{ marginTop: 15 }}>
                      {impactStatement?.impacts?.[impact._id]?.choice === "custom" ? (
                        editable ? (
                          <Form.Input
                            editable={editable}
                            label="Define value"
                            type={
                              impact.type === "numerical" || impact.type === "money" || impact.type === "time"
                                ? "number"
                                : "text"
                            }
                            required
                            placeholder="Please provide a custom value"
                            value={impactStatement?.impacts?.[impact._id]?.value}
                            onChange={(e, { value }) => {
                              updateSingleImpact(impact._id, (prev) => ({
                                ...prev,
                                value:
                                  impact.type === "numerical" || impact.type === "money" || impact.type === "time"
                                    ? parseFloat(value)
                                    : value,
                              }));
                            }}
                          />
                        ) : (
                          <>
                            <h5>Impact value</h5>
                            <p>{impactStatement?.impacts?.[impact._id]?.value}</p>
                          </>
                        )
                      ) : null}
                      {editable ? (
                        <Form.TextArea
                          editable={editable}
                          label="Details and justification"
                          placeholder={`Please provide some more information for your choice. Why did you choose this impact? How does it relate to your ${t("generic.idea")}? How can the impact of your proposal be measured?`}
                          required={statementDefintion.requireJustification}
                          value={impactStatement?.impacts?.[impact._id]?.justification}
                          onChange={(e, { value }) => {
                            updateSingleImpact(impact._id, (prev) => ({
                              ...prev,
                              justification: value,
                            }));
                          }}
                        />
                      ) : (
                        <>
                          <h5>Details and justification</h5>
                          <p>{impactStatement?.impacts?.[impact._id]?.justification}</p>
                        </>
                      )}
                    </Form>
                  </div>
                </CheckboxHeader>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default IdeaImpactStatement;

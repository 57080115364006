import React from "react";
import { Icon } from "semantic-ui-react";
import { crunchbaseImageUrl } from "utils/companies";
import { InfoContainer, InfoBoxWidget, InfoBoxWidgetTitleArea, KeyFactLink, InfoItem } from "../Styles";

const CompanyAcquisitions = ({ acquisitions, handleNewSearch }) => {
  if (acquisitions.length) {
    return (
      <InfoBoxWidget $noBox $sectioned style={{ maxHeight: 520, overflow: "auto" }}>
        <InfoBoxWidgetTitleArea>
          <Icon name="shop" />
          <h3>Acquisitions</h3>
          <span>{acquisitions.length} found</span>
        </InfoBoxWidgetTitleArea>
        <InfoContainer $wrapped>
          {acquisitions.map((acquisition) => (
            <InfoItem
              compact
              key={acquisition.identifier.uuid}
              onClick={() => handleNewSearch({ query: acquisition.acquiree_identifier.value })}
            >
              <KeyFactLink
                image={`${crunchbaseImageUrl}${acquisition.acquiree_identifier.image_id}`}
                title={acquisition.acquiree_identifier.value}
                fact={acquisition.short_description}
              />
            </InfoItem>
          ))}
        </InfoContainer>
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyAcquisitions;

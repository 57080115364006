import { SearchParams } from "simplydo/core";

export const innovationIntelligence = (api) => ({
  getLists(success, fail) {
    api.authenticatedRequest("GET", "/innovationintelligence/lists", null, success, fail);
  },
  createList(data, success, fail) {
    api.authenticatedRequest("PUT", "/innovationintelligence/lists", data, success, fail);
  },
  getList(listId, success, fail) {
    api.authenticatedRequest("GET", `/innovationintelligence/lists/${listId}`, null, success, fail);
  },
  updateList(listId, data, success, fail) {
    api.authenticatedRequest("POST", `/innovationintelligence/lists/${listId}`, data, success, fail);
  },
  duplicateList(listId, data, success, fail) {
    api.authenticatedRequest("PUT", `/innovationintelligence/lists/${listId}`, data, success, fail);
  },
  deleteList(listId, success, fail) {
    api.authenticatedRequest("DELETE", `/innovationintelligence/lists/${listId}`, null, success, fail);
  },
  shareList(listId, userId, success, fail) {
    api.authenticatedRequest("POST", `/innovationintelligence/lists/${listId}/share/${userId}`, null, success, fail);
  },
  shareListMulti(listId, data, success, fail) {
    api.authenticatedRequest("POST", `/innovationintelligence/lists/${listId}/share`, data, success, fail);
  },
  unshareList(listId, userId, success, fail) {
    api.authenticatedRequest("DELETE", `/innovationintelligence/lists/${listId}/share/${userId}`, null, success, fail);
  },
  createPowerbiDataset(listId, datasetType, workspaceId, workspaceName, success, fail) {
    api.authenticatedRequest(
      "POST",
      `/innovationintelligence/lists/${listId}/powerbi/datasets`,
      { datasetType, workspaceId, workspaceName },
      success,
      fail,
    );
  },
  deletePowerbiDataset(listId, datasetId, success, fail) {
    api.authenticatedRequest(
      "DELETE",
      `/innovationintelligence/lists/${listId}/powerbi/datasets/${datasetId}`,
      null,
      success,
      fail,
    );
  },
  getListCompanies(listId, params, success, fail) {
    const urlParams = new SearchParams(params);
    api.authenticatedRequest(
      "GET",
      `/innovationintelligence/lists/${listId}/companies?${urlParams.toString()}`,
      null,
      success,
      fail,
    );
  },
  addListCompanyByUrl(listId, companyUrl, success, fail) {
    api.authenticatedRequest(
      "POST",
      `/innovationintelligence/lists/${listId}/companies/url?companyUrl=${encodeURIComponent(companyUrl)}`,
      null,
      success,
      fail,
    );
  },
  removeListCompanyByUrl(listId, companyUrl, success, fail) {
    api.authenticatedRequest(
      "DELETE",
      `/innovationintelligence/lists/${listId}/companies/url?companyUrl=${encodeURIComponent(companyUrl)}`,
      null,
      success,
      fail,
    );
  },
  addListCompany(listId, companyId, success, fail) {
    api.journey.record("innovationIntelligence", { listId, companyId }, "addedToList");
    api.authenticatedRequest(
      "POST",
      `/innovationintelligence/lists/${listId}/companies/${companyId}`,
      null,
      success,
      fail,
    );
  },
  addListCompanyMulti(listId, companyIds, success, fail) {
    api.journey.record("innovationIntelligence", { listId, companyIds }, "addedToList");
    api.authenticatedRequest("POST", `/innovationintelligence/lists/${listId}/companies`, companyIds, success, fail);
  },
  removeListCompanyMulti(listId, companyIds, success, fail) {
    api.journey.record("innovationIntelligence", { listId, companyIds }, "removedFromList");
    api.authenticatedRequest("DELETE", `/innovationintelligence/lists/${listId}/companies`, companyIds, success, fail);
  },
  removeListCompany(listId, companyId, success, fail) {
    api.journey.record("innovationIntelligence", { listId, companyId }, "removedFromList");
    api.authenticatedRequest(
      "DELETE",
      `/innovationintelligence/lists/${listId}/companies/${companyId}`,
      null,
      success,
      fail,
    );
  },
  emailCompanies(listId, data, success, fail) {
    const params: { listId?: string } = {};
    if (listId) {
      params.listId = listId;
    }
    const urlParams = new SearchParams(params);
    api.authenticatedRequest(
      "POST",
      `/innovationintelligence/companies/email?${urlParams.toString()}`,
      data,
      success,
      fail,
    );
  },
  listCompaniesCsv(listId, data, success, fail) {
    api.authenticatedRequest(
      "POST",
      `/innovationintelligence/lists/${listId}/companies/email/csv`,
      data,
      success,
      fail,
    );
  },
  subscribeToList(listId, shouldSubscribe, success, fail) {
    api.authenticatedRequest(
      shouldSubscribe ? "POST" : "DELETE",
      `/innovationintelligence/lists/${listId}/subscribe`,
      null,
      success,
      fail,
    );
  },
  getListNews(listId, page, success, fail) {
    api.authenticatedRequest("GET", `/innovationintelligence/lists/${listId}/news?page=${page}`, null, success, fail);
  },
  getListLogs(listId, success, fail) {
    api.authenticatedRequest("GET", `/innovationintelligence/lists/${listId}/logs`, null, success, fail);
  },
  getListAnalysis(listId, success, fail) {
    api.authenticatedRequest("GET", `/innovationintelligence/lists/${listId}/analysis`, null, success, fail);
  },
  localSearch(params, success, fail) {
    const searchString = `?query=${params.query || ""}&location=${params.location || ""}&unspscCode=${params.unspscCode || ""}`;
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${searchString}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
    api.journey.record("innovationIntelligence", params, "searched");
    api.authenticatedRequest("GET", `/innovationintelligence/search/local${searchString}`, null, success, fail);
  },
  searchLocations(params, success, fail) {
    const urlParams = new SearchParams(params);
    api.authenticatedRequest(
      "GET",
      `/innovationintelligence/search/locations${urlParams.toSafeString()}`,
      null,
      success,
      fail,
    );
  },
  searchCategories(params, success, fail) {
    const urlParams = new SearchParams(params);
    api.authenticatedRequest(
      "GET",
      `/innovationintelligence/search/categories${urlParams.toSafeString()}`,
      null,
      success,
      fail,
    );
  },
  search(params, success, fail) {
    const urlParams = new SearchParams(params);
    api.journey.record("innovationIntelligence", params, "searched");
    api.authenticatedRequest("GET", `/innovationintelligence/search${urlParams.toSafeString()}`, null, success, fail);
  },
  getSearchPage(searchId, page, fullLoad, state, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/innovationintelligence/search/${searchId}/page/${page}?fullLoad=${fullLoad}&state=${state}`,
      null,
      success,
      fail,
    );
  },
  searchHistory(success, fail) {
    api.authenticatedRequest("GET", "/innovationintelligence/search/history", null, success, fail);
  },
  deleteSearch(id, success, fail) {
    api.authenticatedRequest("DELETE", `/innovationintelligence/search/${id}`, null, success, fail);
  },
  saveSearch(data, success, fail) {
    api.authenticatedRequest("POST", "/innovationintelligence/search", data, success, fail);
  },
  deleteSavedSearches(data: { searchIds: string[]; searchType: string }, success, fail) {
    api.authenticatedRequest("DELETE", "/innovationintelligence/search", data, success, fail);
  },
  get(id, success, fail) {
    api.maybeAuthenticatedRequest("GET", `/innovationintelligence/companies/${id}`, null, success, fail);
  },
  deepDive(id, taskContext, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/innovationintelligence/companies/${id}/deep_dive?taskContext=${taskContext}`,
      null,
      success,
      fail,
    );
  },
  getSectorialNews(id, success, fail) {
    api.authenticatedRequest("GET", `/innovationintelligence/companies/${id}/sectorial`, null, success, fail);
  },
  getFundingInfo(id, success, fail) {
    api.authenticatedRequest("GET", `/innovationintelligence/companies/${id}/research`, null, success, fail);
  },
  getExternalServices(id, services, success, fail) {
    api.authenticatedRequest("POST", `/innovationintelligence/companies/${id}/services`, { services }, success, fail);
  },
  getCreditsafeReport(id, creditsafeIdToUse, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/innovationintelligence/companies/${id}/creditsafe/report${creditsafeIdToUse ? `?creditsafeId=${creditsafeIdToUse}` : ""}`,
      null,
      success,
      fail,
    );
  },
  getCreditsafeReportPdf(id, success, fail) {
    api.authenticatedRequest("GET", `/innovationintelligence/companies/${id}/creditsafe/pdf`, null, success, fail);
  },
  getNotes(id, success, fail) {
    api.authenticatedRequest("GET", `/innovationintelligence/companies/${id}/notes`, null, success, fail);
  },
  createNote(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/innovationintelligence/companies/${id}/notes`, data, success, fail);
  },
  editNote(id, noteId, data, success, fail) {
    api.authenticatedRequest("POST", `/innovationintelligence/companies/${id}/notes/${noteId}`, data, success, fail);
  },
  deleteNote(id, noteId, success, fail) {
    api.authenticatedRequest("DELETE", `/innovationintelligence/companies/${id}/notes/${noteId}`, null, success, fail);
  },
  hide(id, toHide, success, fail) {
    api.authenticatedRequest(
      toHide ? "POST" : "DELETE",
      `/innovationintelligence/companies/${id}/hide`,
      null,
      success,
      fail,
    );
  },
  getHidden(success, fail) {
    api.authenticatedRequest("GET", "/innovationintelligence/companies/hidden", null, success, fail);
  },
  getCountryLists(success, fail) {
    api.authenticatedRequest("GET", "/innovationintelligence/search/locations/lists", null, success, fail);
  },
  updateCompany(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/innovationintelligence/companies/${id}`, data, success, fail);
  },
  inviteCompanies(data, success, fail) {
    api.authenticatedRequest("POST", "/innovationintelligence/companies/invite", data, success, fail);
  },
  getDashboards(success, fail) {
    api.authenticatedRequest("GET", "/innovationintelligence/dashboards", null, success, fail);
  },
  createDashboard(data, success, fail) {
    api.authenticatedRequest("PUT", "/innovationintelligence/dashboards", data, success, fail);
  },
  getDashboard(id, success, fail) {
    api.authenticatedRequest("GET", `/innovationintelligence/dashboards/${id}`, null, success, fail);
  },
  refreshDashboard(id, success, fail) {
    api.authenticatedRequest("POST", `/innovationintelligence/dashboards/${id}`, null, success, fail);
  },
  deleteDashboard(id, success, fail) {
    api.authenticatedRequest("DELETE", `/innovationintelligence/dashboards/${id}`, null, success, fail);
  },
  subscribeToDashboard(id, toSubscribe, success, fail) {
    api.authenticatedRequest(
      toSubscribe ? "POST" : "DELETE",
      `/innovationintelligence/dashboards/${id}/subscribe`,
      null,
      success,
      fail,
    );
  },
  updateDashboardEventLocation(id, data, success, fail) {
    api.authenticatedRequest("POST", `/innovationintelligence/dashboards/${id}/eventLocation`, data, success, fail);
  },
  findCompaniesByCsv(data, success, fail) {
    api.authenticatedRequest("POST", "/innovationintelligence/companies/findByCsv", data, success, fail);
  },
  connectExcel(id, success, fail) {
    api.authenticatedRequest("POST", `/innovationintelligence/lists/${id}/microsoftExcel`, null, success, fail);
  },
  disconnectService(id, service, success, fail) {
    api.authenticatedRequest("DELETE", `/innovationintelligence/lists/${id}/${service}`, null, success, fail);
  },
});

const organisationsActions = {
  RECEIVE_URL_ORGANISATION: "RECEIVE_URL_ORGANISATION",
  SET_MOBILE_BANNER_OPEN: "SET_MOBILE_BANNER_OPEN",
  SET_DEMOING_TOUR: "SET_DEMOING_TOUR",
  HAS_SEEN_IDEA_LIKE_INFORMATION_MODAL_THIS_SESSION: "HAS_SEEN_IDEA_LIKE_INFORMATION_MODAL_THIS_SESSION",

  receiveUrlOrganisation(organisation) {
    return { type: this.RECEIVE_URL_ORGANISATION, organisation };
  },
  setMobileBannerOpen(open) {
    return { type: this.SET_MOBILE_BANNER_OPEN, mobileBannerOpen: open };
  },
  setDemoingTour(tourId) {
    return { type: this.SET_DEMOING_TOUR, tourId };
  },
  setHasSeenIdeaLikeInformationModalThisSession(hasSeen) {
    return { type: this.HAS_SEEN_IDEA_LIKE_INFORMATION_MODAL_THIS_SESSION, hasSeen };
  },
};

export default organisationsActions;

import { useState, useEffect, useCallback, useMemo } from "react";
import util from "utils/utils";

export const useEditControlsHook = (user, idea) => {
  const [editState, setEditState] = useState({
    ideaInitialised: false,
    isEditing: true,
    canEdit: false,
  });
  const canEdit = useMemo(() => util.canEditIdea(user, idea), [user, idea]);
  const ideaSubmitted = !!idea?.isSubmitted;
  const ideaExists = !!idea;

  useEffect(() => {
    setEditState((prevState) => ({
      ...prevState,
      ideaInitialised: ideaExists,
    }));
  }, [ideaExists]);

  useEffect(() => {
    setEditState((prevState) => ({
      ...prevState,
      isEditing: canEdit && !ideaSubmitted,
      canEdit,
    }));
  }, [canEdit, ideaSubmitted]);

  const setEditing = useCallback((isEditing) => {
    setEditState((prevState) => ({
      ...prevState,
      isEditing: prevState.canEdit && isEditing,
    }));
  }, []);

  return [editState, setEditing];
};

export default useEditControlsHook;

import { useCallback, useState } from "react";
import { Message, Table, Modal, Button, Input } from "semantic-ui-react";
import util from "utils/utils";

import { useAppDispatch, useAppSelector } from "store";
import api from "api";
import actions from "actions";
import { UserChip } from "./Chips";

const PointOfContactList = () => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const organisation = user?.ownerOrganisation;
  const pointsOfContact = organisation?.pointOfContactRoles;

  const [selectedRole, setSelectedRole] = useState(null);
  const onMessageThreadSelected = useCallback(
    (thread, isPhone = false) => dispatch(actions.messages.selectThread(thread, isPhone)),
    [dispatch],
  );

  const createChat = useCallback(
    (users) => {
      api.messages.createThread(
        { users },
        (thread) => {
          onMessageThreadSelected(thread);
        },
        () => {},
      );
    },
    [onMessageThreadSelected],
  );

  if (!pointsOfContact || (pointsOfContact && pointsOfContact.length === 0)) {
    return null;
  }

  return (
    <Message>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={!!selectedRole}
        onClose={() => {
          setSelectedRole(null);
        }}
      >
        <Modal.Header>Contact {selectedRole?.ownerAssignedTo?.profile?.fullName}</Modal.Header>
        <Modal.Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 30,
            }}
          >
            <div>
              {selectedRole?.assignedTo === user?._id ? (
                <Message info>
                  <Message.Header>This is you!</Message.Header>
                  <Message.Content>You are the support contact for this role</Message.Content>
                </Message>
              ) : null}
              <p>
                You can contact {selectedRole?.ownerAssignedTo?.profile?.fullName} by sending them a direct message on
                SimplyDo
              </p>
              <Button
                disabled={selectedRole?.assignedTo === user?._id}
                onClick={() => {
                  setSelectedRole(null);
                  createChat([selectedRole?.assignedTo]);
                }}
              >
                Send message
              </Button>
            </div>
            {selectedRole?.ownerAssignedTo?.email ? (
              <div>
                <p>Alternatively, you can send them an email directly to:</p>
                <Input
                  readOnly
                  action={{
                    icon: "copy",
                    onClick: () => {
                      navigator.clipboard.writeText(selectedRole?.ownerAssignedTo?.email);
                    },
                  }}
                  value={selectedRole?.ownerAssignedTo?.email}
                />
              </div>
            ) : null}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setSelectedRole(null);
            }}
          >
            Done
          </Button>
        </Modal.Actions>
      </Modal>
      <Message.Header>Need help?</Message.Header>
      <Message.Content>
        <p>
          If you need help or have a specific query please contact{" "}
          {util.pluralise(pointsOfContact.length, "this user.", "one of the following users.", false)}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Table selectable style={{ maxWidth: 450 }}>
            <Table.Body>
              {pointsOfContact.map((role) => (
                <Table.Row
                  key={role.id}
                  onClick={() => {
                    setSelectedRole(role);
                  }}
                >
                  <Table.Cell>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 12,
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          For
                          <h3
                            style={{
                              margin: 0,
                            }}
                          >
                            {role.name}
                          </h3>
                        </span>
                        <UserChip user={role.ownerAssignedTo} hideGhost disableLink />
                      </div>
                      <p
                        style={{
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {role.description}
                      </p>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Message.Content>
    </Message>
  );
};

export default PointOfContactList;

import React from "react";
import { Menu, Loader, Button, Segment } from "semantic-ui-react";
import { UserChip } from "components/lib/Chips";
import styled from "styled-components";

const RecipientContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const MessageTraySearch = ({
  results,
  selectRecipients,
  searching,
  addRecipient,
  removeRecipient,
  recipients,
  createChat,
}) => {
  if (searching)
    return (
      <Menu.Item>
        <Loader />
      </Menu.Item>
    );
  if (!results) return <Menu.Item header>No users found</Menu.Item>;
  return (
    <>
      {results.slice(0, 9).map((u) => (
        <Menu.Item key={u._id} style={{ display: "flex" }} onClick={() => addRecipient(u)}>
          <UserChip key={u._id} user={u} displayOnly />
        </Menu.Item>
      ))}
      {recipients.length ? (
        <Segment>
          <h3>Selected recipients</h3>
          <RecipientContainer>
            {recipients.map((u) => (
              <UserChip
                key={u._id}
                user={u}
                compact
                displayOnly
                actionIcon="remove"
                actionClick={() => removeRecipient(u)}
              />
            ))}
          </RecipientContainer>
          {recipients.length > 1 ? (
            <Button primary icon="send" fluid onClick={() => createChat(recipients.map((u) => u._id))}>
              Start group chat
            </Button>
          ) : (
            <Button primary icon="send" fluid onClick={() => selectRecipients(recipients[0])}>
              Message user
            </Button>
          )}
        </Segment>
      ) : null}
    </>
  );
};

export default MessageTraySearch;

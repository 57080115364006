import React, { useState, useCallback } from "react";
import { Popup, Button, Loader, Input, Icon } from "semantic-ui-react";
import giphy from "api/giphy";
import useThrottle from "utils/useThrottle";
import toast from "react-hot-toast";
import styled from "styled-components";
import useTheme from "theme/useTheme";

import GiphyGif from "./Gif";
import GiphyLogo from "./GiphyLogo.png";

const GiphyContainer = styled.div`
  padding: 5px;
  min-width: ${({ theme }) => (theme.sizes.isMobile ? 220 : 660)}px;
  min-height: 360px;
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px 0;
  }
`;

const GiphyGallery = styled.div`
  display: flex;
  width: ${({ theme }) => (theme.sizes.isMobile ? 220 : 660)}px;
  flex-wrap: wrap;
`;

const GiphyGifContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  width: 100px;
  height: 125px;
  overflow: hidden;
  background-color: #e9e9e9;
`;

const GiphyPicker = ({ trigger, open, onChoose, onClose }) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [gifs, setGifs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  const getTrending = useCallback(() => {
    setLoading(true);
    giphy.trending(
      { page: page },
      ({ data }) => {
        setLoading(false);
        setGifs(data);
      },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      },
    );
  }, [page]);

  const searchGifs = useThrottle(
    () => {
      if (searchTerm) {
        setLoading(true);
        giphy.search(
          { query: searchTerm, page: page },
          ({ data }) => {
            setLoading(false);
            setGifs(data);
          },
          (err) => {
            setLoading(false);
            toast.error(err.message);
          },
        );
      } else {
        getTrending();
      }
    },
    500,
    [getTrending, searchTerm, page],
  );

  const handleSearch = useCallback((term) => setSearchTerm(term), []);
  const handleClick = useCallback((gif) => onChoose(gif), [onChoose]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter") searchGifs();
    },
    [searchGifs],
  );

  const pageBack = useCallback(() => {
    setPage((prevPage) => prevPage - 1);
    getTrending();
  }, [getTrending]);

  const pageForward = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
    getTrending();
  }, [getTrending]);

  const searchPageBack = useCallback(() => {
    setPage((prevPage) => prevPage - 1);
    searchGifs();
  }, [searchGifs]);

  const searchPageForward = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
    searchGifs();
  }, [searchGifs]);

  return (
    <div>
      <Popup
        open={open}
        onClose={onClose}
        position={theme.sizes.isMobile ? "bottom center" : null}
        trigger={<div>{trigger}</div>}
        content={
          <GiphyContainer>
            <Input
              placeholder="Search GIFs..."
              value={searchTerm}
              fluid
              onKeyPress={handleKeyPress}
              onChange={(e, { value }) => handleSearch(value)}
              style={{ marginBottom: 5 }}
              label={
                <Button
                  content="Search"
                  onClick={() => {
                    setPage(1);
                    searchGifs();
                  }}
                />
              }
              labelPosition="right"
            />
            <div>
              {loading ? (
                <Loader active />
              ) : (
                <GiphyGallery>
                  {gifs.map((gif, i) => (
                    <Popup
                      key={i}
                      trigger={
                        <GiphyGifContainer onClick={() => handleClick(gif)}>
                          <GiphyGif gif={gif} size="fixed_small" key={i} />
                        </GiphyGifContainer>
                      }
                      content={gif.title}
                    />
                  ))}
                </GiphyGallery>
              )}
            </div>
            <div className="nav-buttons">
              <Button icon onClick={searchTerm ? searchPageBack : pageBack} labelPosition="left" disabled={page === 1}>
                <Icon name="arrow circle left" />
                Previous
              </Button>
              <span>Page {page}</span>
              <Button icon onClick={searchTerm ? searchPageForward : pageForward} labelPosition="right">
                Next
                <Icon name="arrow circle right" />
              </Button>
            </div>
            <img src={GiphyLogo} style={{ float: "right" }} alt="Powered by Giphy" />
          </GiphyContainer>
        }
        on="click"
        onOpen={() => getTrending()}
      />
    </div>
  );
};

export default GiphyPicker;

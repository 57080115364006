import React, { useCallback, useRef } from "react";
import { Button, Dropdown } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import styled from "styled-components";
import api from "api";
import util from "utils/utils";
import toast from "react-hot-toast";
import { useContainerDimensions } from "utils/useContainerDimensions";
import { useTranslation } from "react-i18next";
import Impact from "./Impact";
import { useAppSelector } from "store";

const IdeaImpactHeader = styled.div`
  > span {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .impact-name {
  }
  .impact-description {
    opacity: 0.7;
  }
`;

const IdeaImpactDropdownItem = styled(Dropdown.Item)`
  display: flex !important;
  overflow: hidden;
  text-overflow: ellipsis;
  > i {
    width: 10px;
  }
  ${IdeaImpactHeader} {
    max-width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
  }
`;

type ImpactProps = {
  idea: Other.IIdea;
  onUpdateIdea: (idea: Partial<Other.IIdea>) => void;
  impactList: Other.IImpact[];
  canManage: boolean;
};

function Impacts({ idea, onUpdateIdea, impactList, canManage }: ImpactProps) {
  const ideaId = idea._id;
  const dropdownRef = useRef(null);
  const { width } = useContainerDimensions(dropdownRef);
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const addImpact = useCallback(
    (impactId) => {
      let authToken = null;
      if (util.localStorageIsSupported()) {
        authToken = localStorage.getItem(`ideaAuthToken:${ideaId}`);
      }
      api.ideas.addImpactMeasure(
        ideaId,
        impactId,
        { authToken },
        (data) => {
          onUpdateIdea({ impacts: [...(idea.impacts ?? []), data.newImpact] });
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [ideaId, onUpdateIdea, idea.impacts],
  );

  const deleteImpact = useCallback(
    (impactId, impactMeasureId) => {
      util
        .confirm("Are you sure you want to delete this impact measure?", "This action cannot be undone.")
        .then(() => {
          let authToken = null;
          if (util.localStorageIsSupported()) {
            authToken = localStorage.getItem(`ideaAuthToken:${ideaId}`);
          }
          const usingImpact = idea.impacts.find((i) => i.impactId === impactId);
          api.ideas.removeImpactMeasure(
            usingImpact.idea,
            impactId,
            impactMeasureId,
            { authToken },
            ({ totalImpacts }) => {
              onUpdateIdea({ impacts: idea.impacts.filter((i) => i._id !== impactMeasureId), totalImpacts });
            },
            (err) => {
              toast.error(err.message);
            },
          );
        })
        .catch(() => {});
    },
    [idea.impacts, ideaId, onUpdateIdea],
  );

  const onUpdateIdeaImpact = useCallback(
    (impactId, data) => {
      onUpdateIdea({
        impacts: idea.impacts.map((impact) => {
          if (impact._id === impactId) {
            return { ...impact, ...data };
          }
          return impact;
        }),
      });
    },
    [idea.impacts, onUpdateIdea],
  );

  const onUpdateIdeaTotalImpacts = useCallback(
    (totalImpacts) => {
      onUpdateIdea({
        totalImpacts,
      });
    },
    [onUpdateIdea],
  );

  if (!(impactList?.length > 0)) return null;
  return (
    <div ref={dropdownRef}>
      <p style={{ color: "grey" }}>
        How will this {t("generic.idea")} impact "{idea.ownerChallenge?.name}"?
      </p>

      {idea.impacts?.map((i) => (
        <Impact
          key={i._id}
          idea={idea}
          impact={i}
          onUpdateIdeaImpact={onUpdateIdeaImpact}
          deleteImpact={deleteImpact}
          onUpdateIdeaTotalImpacts={onUpdateIdeaTotalImpacts}
          canEdit={canManage || util.hasPermission(user, "challenge.editProjectImpacts", idea?.challenge)}
        />
      ))}
      {canManage || util.hasPermission(user, "challenge.editProjectImpacts", idea?.challenge) ? (
        <Dropdown
          icon={null}
          pointing="bottom"
          fluid
          trigger={<Button size="small" primary icon="plus" content="Add new impact measure" fluid />}
        >
          <Dropdown.Menu
            style={{ left: "auto", right: "auto", width, maxHeight: 250, overflowY: "auto", overflowX: "hidden" }}
          >
            {impactList.map((i, idx) => (
              <IdeaImpactDropdownItem
                key={idx}
                icon={i.icon ?? "tag"}
                content={
                  <IdeaImpactHeader>
                    <span className="impact-name">{i.name}</span>
                    <span className="impact-description">{i.description}</span>
                  </IdeaImpactHeader>
                }
                // description={i.description}
                onClick={() => addImpact(i._id)}
                style={{ width }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
    </div>
  );
}

export default Impacts;

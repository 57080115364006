import { UserChip } from "components/lib/Chips";
import { HomeInvitation } from "./types";

const InviteeChip = ({
  invitation,
  preText,
  postText,
}: {
  invitation: HomeInvitation;
  preText?: string;
  postText?: string;
}) => {
  const { inviteeUser, invitee, invitationType } = invitation;
  if (invitationType === "user") {
    return <UserChip compact user={inviteeUser} precedingText={preText} postText={postText} />;
  } else if (invitationType === "email") {
    return (
      <span>
        {preText} {invitee} {postText}
      </span>
    );
  }
  return null;
};

export default InviteeChip;

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import util from "utils/utils";
import api from "api";

const EntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  justify-content: center;
  align-items: center;
  .ui.button {
    width: 300px;
  }
`;

const StyledLoader = styled(Loader)`
  margin-bottom: 15px;
`;

const OrgLogo = styled.img`
  width: 300px;
  margin-bottom: 15px;
  object-fit: contain;
`;

const OrgText = styled.span`
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
`;

const AppInfoText = styled.p`
  text-align: center;
  margin: 10px 2rem 15px;
  font-size: 1.1em;
  color: #696969;
`;

const SmartLink = ({ user }) => {
  const userAgent = util.getMobileOperatingSystem();
  const isMobileDevice = userAgent === "Android" || userAgent === "iOS";
  const appLink =
    userAgent === "Android"
      ? "https://play.google.com/store/apps/details?id=uk.co.simplydo.mobile&hl=en_US"
      : "https://apps.apple.com/gb/app/simply-do/id1445564123";
  const code = util.code() || "simplydo";
  const [goToAppStore, setGoToAppStore] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const userId = user?._id;

  const { t } = useTranslation();
  const navigate = useNavigate();

  // https://stackoverflow.com/questions/13044805/how-to-check-if-an-app-is-installed-from-a-web-page-on-an-iphone
  const redirectToAppStore = useCallback(
    (exchangeToken) => {
      // Checks for code execution during timeout i.e if the webpage has been open. Accounts for if user went to the app then immediately came back to the browser
      const now = new Date().valueOf();
      setTimeout(() => {
        if (new Date().valueOf() - now > 100) return;
        setGoToAppStore(true);
      }, 50);
      window.location = `simplydo://?org=${code}&token=${exchangeToken}`;
    },
    [code],
  );

  const getOrg = useCallback(() => {
    api.organisations.getByCode(code, (org) => {
      if (org) setOrganisation(org);
    });
  }, [code]);

  const checkDevice = useCallback(() => {
    if (!isMobileDevice) {
      navigate("/");
    } else if (userId) {
      api.auth.generateExchangeToken((data) => redirectToAppStore(data.exchangeToken));
    } else redirectToAppStore();
  }, [isMobileDevice, redirectToAppStore, navigate, userId]);

  useEffect(() => {
    checkDevice();
    getOrg();
  }, [checkDevice, getOrg]);

  return (
    <EntryContainer>
      {goToAppStore ? (
        <>
          {organisation && organisation.darkLogoUrl && (
            <>
              <OrgLogo src={organisation.darkLogoUrl} />
              <OrgText>{organisation.name} is using Simply Do</OrgText>
            </>
          )}
          <AppInfoText>
            <p>
              <Icon name="download" /> Download the Simply Do mobile app for an optimised experience on your mobile
              devices.
            </p>
            <p>
              <Icon name="mobile alternate" /> Create and view {t("generic.ideas")}, message team members or browse{" "}
              {t("generic.challenges")} quickly and easily.
            </p>
            <p>
              <Icon name="alarm" /> Plus you will be able to receive updates via push notifications.
            </p>
          </AppInfoText>
          <Button
            primary
            size="huge"
            onClick={() => {
              window.location = appLink;
            }}
            style={{ marginBottom: 15 }}
          >
            Visit Simply Do on the App Store
          </Button>
          <Button size="tiny" onClick={() => navigate("/")}>
            Return to Simply Do Web
          </Button>
        </>
      ) : (
        <StyledLoader active centered />
      )}
    </EntryContainer>
  );
};

export default connect((state) => ({ user: state.user }))(SmartLink);

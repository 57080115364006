import React from "react";
import { Card } from "semantic-ui-react";
import styled from "styled-components";

const StyledCard = styled(Card)`
  -webkit-box-shadow: -1px 5px 33px -7px rgba(0, 0, 0, 0.41) !important;
  -moz-box-shadow: -1px 5px 33px -7px rgba(0, 0, 0, 0.41) !important;
  box-shadow: -1px 5px 33px -7px rgba(0, 0, 0, 0.41) !important;
  transition: all ease 1s;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  &:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
`;

type ShadowedCardProps = {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  content?: React.ReactNode;
  onCardClicked?: () => void;
};

const ShadowedCard = ({ style, className, children, content, onCardClicked }: ShadowedCardProps) => {
  return (
    <StyledCard onClick={onCardClicked && onCardClicked} style={style} className={className}>
      {children || content}
    </StyledCard>
  );
};

export default ShadowedCard;

import { OpenAPI } from "simplydo/interfaces";
import util from "utils/utils";
import { Button, Icon, Message, Segment } from "semantic-ui-react";
import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import api from "api";
import toast from "react-hot-toast";
import actions from "actions";
import { t } from "i18next";

type SettingsProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  closeModal: () => void;
  onDelete?: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
};

export const Settings = ({ businessProfile, onDelete, closeModal }: SettingsProps) => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [hasDeleteConflict, setHasDeleteConflict] = useState(false);

  const deleteProfile = useCallback(() => {
    util
      .confirm(
        `Really delete the organisation profile ${businessProfile.profile.name}?`,
        `Deleting the organisation profile ${businessProfile.profile.name} is permanent and cannot be undone.`,
      )
      .then(() => {
        // Attempt to delete the profile.
        // If the profile is attached to another idea, we alert the user, and don't continue with this action

        api.users.deleteProfile(
          businessProfile._id,
          ({ success: deletingSuccess, reason }: { success: boolean; reason: string }) => {
            if (!deletingSuccess) {
              toast(reason);
              setHasDeleteConflict(true);
            } else {
              dispatch(actions.user.updatePermissions(businessProfile._id, []));
              closeModal();
              onDelete && onDelete(businessProfile);
            }
          },
          (err) => {
            toast.error(err.message);
            setHasDeleteConflict(true);
          },
        );
      })
      .catch(() => {});
  }, [businessProfile, onDelete, closeModal, dispatch]);

  return (
    <div
      style={{
        display: "flex",
        gap: 12,
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Segment>
        <h3>Settings</h3>
        Delete this profile if it is no longer needed.
        <Message warning>
          Deleting this profile will remove it from all associated draft {t("generic.ideas")}. If the profile is
          attached to a submitted {t("generic.idea")}, the data will remain until it is removed.
        </Message>
        <Button
          disabled={
            hasDeleteConflict || !util.hasPermission(user, "ideaBusinessProfile.deleteProfile", businessProfile._id)
          }
          inverted
          onClick={() => {
            deleteProfile();
          }}
          color="red"
        >
          <Icon name="trash" />
          Delete profile
        </Button>
      </Segment>
    </div>
  );
};

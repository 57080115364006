import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import moment from "moment";
import util from "utils/utils";

import RowComponent from "components/lib/RowComponent";

function HomePolls({ user, polls }) {
  if (!polls?.length) return null;
  return (
    <RowComponent>
      <RowComponent.Fill>
        <RowComponent.Title>Polls</RowComponent.Title>
        {util.hasPermission(user, "org.viewDashboard", user?.ownerOrganisation?._id) && (
          <Button as={Link} to="/admin/polls" primary size="mini">
            Manage polls
          </Button>
        )}
      </RowComponent.Fill>
      {polls.map((poll, index) => (
        <RowComponent.Cell
          index={index}
          key={poll._id}
          as={"a"}
          href={`/polls/${poll._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="bolt" size="big" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 5,
              marginRight: 5,
              position: "relative",
              top: -1,
            }}
          >
            <RowComponent.Title style={{ color: "#5d5d5d" }} block>
              {poll.name}
            </RowComponent.Title>
            {poll?.hasResponded && (
              <span style={{ color: "green" }}>
                <Icon name="check circle" />
                Completed
              </span>
            )}
            {poll?.deadline && (
              <span style={{ color: "orange" }}>
                <Icon name="time" />
                Closes {moment(poll.deadline).fromNow()}
              </span>
            )}
          </div>
        </RowComponent.Cell>
      ))}
    </RowComponent>
  );
}

const mapStateToProps = (state) => ({ user: state.user });

const HomePollsContainer = withTranslation()(connect(mapStateToProps)(HomePolls));

export default HomePollsContainer;

import React, { useState, useCallback } from "react";
import { Icon, Checkbox, Button } from "semantic-ui-react";
import styled from "styled-components";
import RichText from "components/lib/Editors/RichText";
import util from "utils/utils";

const WidgetContainer = styled.div`
  width: 100%;
  font-family: "Inter", sans-serif;
  box-shadow: ${({ theme }) => theme.boxShadow};
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const NewPostButton = styled(WidgetContainer)`
  background-color: ${({ theme }) => theme.primaryColour};
  color: ${({ theme }) => (theme.shouldBeWhiteOnPrimary ? "#fff" : "#000")};
  &:hover {
    background-color: ${({ theme }) => util.adjustColour(theme.primaryColour, -0.1)};
  }
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  span,
  i,
  .ui.icon {
    display: block;
    font-weight: 600;
  }
  i,
  .ui.icon {
    margin-right: 5px;
    transform: translateY(-3.5px);
  }
  > span {
  }
  cursor: pointer;
`;

const PostEditor = styled(WidgetContainer)`
  padding: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h5 {
    margin: 0;
  }
  > span {
    color: gray;
    margin-bottom: 1rem;
  }
  .ui.checkbox {
    margin: 1rem 0;
  }
  .actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

const ActivityFeedPostWidget = ({ onPost, groupFeed }) => {
  const [showEditor, setShowEditor] = useState(false);
  const [message, setMessage] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  const handlePost = useCallback(() => {
    onPost({ message, isAnonymous, isPinned });
    setMessage("");
    setIsAnonymous(false);
    setIsPinned(false);
    setShowEditor(false);
  }, [message, isAnonymous, isPinned, onPost]);

  if (!showEditor) {
    return (
      <NewPostButton onClick={() => setShowEditor(true)}>
        <Icon name="add" />
        <span>Create new feed post</span>
      </NewPostButton>
    );
  }
  return (
    <PostEditor>
      <h5 style={{ marginBottom: 5 }}>New activity feed post</h5>
      <span>
        These posts will show to
        {groupFeed ? "users in this group in the activity feed" : "users of this organisation in their activity feed"}.
        By default, they will appear at the top of the feeds for 7 days, after which they will filter into the timeline.
      </span>
      <div style={{ width: "100%" }}>
        <RichText preset="minimal" value={message} onChange={(value) => setMessage(value)} />
      </div>

      <Checkbox
        toggle
        checked={isPinned}
        onChange={() => setIsPinned(!isPinned)}
        label={`Toggle this on to pin the post to the top of ${groupFeed ? "the group activity feed" : "all users' activity feeds"}. If multiple posts are pinned, they will be ordered by recency.`}
      />
      <Checkbox
        toggle
        checked={isAnonymous}
        onChange={() => setIsAnonymous(!isAnonymous)}
        label={`Toggle this on to post anonymously. The ${groupFeed ? "group" : "organisation"} name will be shown as the author.`}
      />
      <div className="actions">
        <Button primary content="Publish post" icon="send" onClick={handlePost} />
      </div>
    </PostEditor>
  );
};

export default ActivityFeedPostWidget;

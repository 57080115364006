import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import useTheme from "theme/useTheme";
import util from "utils/utils";
import RowComponent from "components/lib/RowComponent";

const hasDefaultCoverImage = (coverImageUrl) =>
  !coverImageUrl || (coverImageUrl && coverImageUrl !== "https://cdn.simplydo.co.uk/images/groupCoverImage.png");
const sortDiscoverable = (groups, user) =>
  groups.sort((a, b) => {
    const isInA = util.isInGroup(user, a);
    const isInB = util.isInGroup(user, b);
    if (isInB && !isInA) return 1;
    if (isInA && !isInB) return -1;
    return 0;
  });

const HomeGroups = ({ user, groups }) => {
  const theme = useTheme();
  if (!groups.length) return null;
  return (
    <RowComponent>
      <RowComponent.Fill>
        <RowComponent.Title>Groups</RowComponent.Title>
        <Button as={Link} to="/groups" primary size="mini">
          View all groups
        </Button>
      </RowComponent.Fill>
      {sortDiscoverable(groups, user)
        .slice(0, 4)
        .map((group, index) => (
          <RowComponent.Cell index={index} key={group._id} as={Link} to={`/groups/${group._id}`}>
            <div
              style={{
                width: 50,
                height: 40,
                borderRadius: 3,
                flex: "0 0 50px",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundImage: `url(${util.groupCoverImage(group)})`,
                backgroundColor: hasDefaultCoverImage(group.coverImageUrl) ? theme.accent : null,
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
                marginRight: 5,
                position: "relative",
                top: -1,
              }}
            >
              <RowComponent.Title wrapping style={{ color: "#5d5d5d" }} block>
                {group.name}
              </RowComponent.Title>
              <RowComponent.StatusArea>
                {util.isInGroup(user, group) ? (
                  <>
                    <RowComponent.StatusLabel color="green" /> Member
                  </>
                ) : (
                  <>
                    <RowComponent.StatusLabel color="blue" /> Available group
                  </>
                )}
              </RowComponent.StatusArea>
            </div>
          </RowComponent.Cell>
        ))}
    </RowComponent>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default withTranslation()(connect(mapStateToProps)(HomeGroups));

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import util from "utils/utils";

import FeedCard from "./FeedCard";

const CardLeft = ({ challenge }) => (
  <Grid.Column width={12} verticalAlign="middle">
    <div className="cover-and-text">
      <Link
        to={util.challengeLink(challenge)}
        className="image-link"
        style={{
          width: 60,
          height: 50,
          borderRadius: 10,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundImage: `url(${util.mixinCssUrlFallback(util.challengeImage(challenge), util.challengeImage())})`,
        }}
      />
      <span className="content-name">
        <Link to={util.challengeLink(challenge)}>{challenge.name}</Link>
      </span>
    </div>
  </Grid.Column>
);

class DiscoverChallengeCard extends Component {
  render() {
    const { challenge, t, isMobile, hideAction, feedType } = this.props;
    return (
      <FeedCard
        feedType={feedType}
        user={challenge.anonymous ? null : challenge.postedBy}
        action={
          challenge.publishedAt ? "activityFeed.challenges.actionPublished" : "activityFeed.challenges.actionCreated"
        }
        date={challenge.publishedAt || challenge.createdAt}
        toLink={`/challenges/${challenge._id}`}
        type={t("generic.challenge")}
        cardLeft={<CardLeft isMobile={isMobile} challenge={challenge} />}
        hideAction={hideAction}
      />
    );
  }
}

export default withTranslation()(DiscoverChallengeCard);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import styled, { withTheme } from "styled-components";
import moment from "moment";
import util from "utils/utils";
import { Icon } from "semantic-ui-react";

const StyledAvatar = styled(Link)`
  position: relative;
  height: 30px !important;
  width: 30px !important;
  line-height: inherit;
  border-radius: 50%;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
  margin-right: 5px;
  background-image: ${({ $subject }) =>
    `url(${util.mixinCssUrlFallback($subject.name ? util.profileLogo($subject) : util.avatarUrl($subject), util.avatarUrl())})`};
`;

const StyledCompanyLogo = styled.div`
  position: relative;
  height: 30px !important;
  width: 30px !important;
  line-height: inherit;
  border-radius: 50%;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
  margin-right: 5px;
  background-image: ${({ $company }) => `url(${$company.imageUrl})`};
`;
class FeedCardHeader extends Component {
  render() {
    const { user, currentUser, action, date, theme, t, subjectItem, ownerType, company } = this.props;
    const subject =
      user && currentUser && user._id === currentUser._id
        ? {
            ...currentUser,
          }
        : user;
    let name = "";
    if (ownerType === "company") {
      name = company.data.creditsafe.legal_name || company.name;
    } else if (subject && !subject.profile && subject.name) {
      name = subject.name;
    } else if (subject && subject.profile) {
      name = subject.profile?.fullName || "Someone";
    } else name = t("generic.anonymous");
    return (
      <div style={{ width: "100%", color: "#000", paddingBottom: 0 }}>
        <div
          style={{
            float: "left",
            display: "flex",
            alignItems: "center",
            color: "gray",
          }}
        >
          {ownerType === "company" ? (
            <StyledCompanyLogo $company={company} />
          ) : (
            <>
              {user ? (
                <StyledAvatar $subject={subject} to={`/${subject.name ? "profiles" : "users"}/${subject._id}`} />
              ) : (
                <Icon size="large" name="user secret" style={{ color: "rgb(150,150,150)", marginRight: 5 }} />
              )}
            </>
          )}
          {}
          {t(action, { name, subjectItem })}
        </div>
        {!theme.sizes.isMobile && (
          <div
            style={{
              float: "right",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              lineHeight: 2.5,
              color: "rgb(180,180,180)",
              fontSize: 10,
            }}
            data-tooltip={moment(date).format("HH:mm on DD/MM/YY")}
          >
            {moment(date).fromNow()}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ currentUser: state.user });
const FeedCardHeaderContainer = connect(mapStateToProps, null)(FeedCardHeader);

export default withTheme(withTranslation()(FeedCardHeaderContainer));

import auth from "./auth";
import user from "./user";
import messages from "./messages";
import organisations from "./organisations";
import challenges from "./challenges";
import admin from "./admin";
import entry from "./entry";
import googleHelper from "./googleHelper";

const actions = {
  auth,
  user,
  messages,
  organisations,
  challenges,
  admin,
  entry,
  googleHelper,
};
export default actions;

import React, { useMemo } from "react";
import { Header, Icon, Button } from "semantic-ui-react";
import { VideoChooser } from "components/lib/Choosers";
import { withTranslation } from "react-i18next";
import VideoPlayer from "components/lib/Video/Video";
import toast from "react-hot-toast";
import { BodySegment } from "./styles";

const Video = ({ field, ideaId, fieldValue = "", updateIdea, isEditing, t }) => {
  const idea = useMemo(() => ({ _id: ideaId }), [ideaId]);

  const VideoComponent = useMemo(
    () => <VideoPlayer src={fieldValue} style={{ marginTop: "20px" }} forType="idea" forItem={idea} />,
    [fieldValue, idea],
  );

  if (!isEditing) return VideoComponent;
  return (
    <BodySegment placeholder>
      <Header icon>
        <Icon name="film" /> Choose a video
      </Header>
      <VideoChooser
        trigger={<Button primary icon="film" content={t("ideas.actions.chooseVideo")} />}
        forType="idea"
        forId={ideaId}
        onComplete={(v, name, videoSrc) => {
          // The video player doesn't respond well to updates, therefore we reset the video to null first
          updateIdea(null, ["templated", field.id], true);
          updateIdea(videoSrc, ["templated", field.id]);
        }}
        onError={toast.error}
      />
      {fieldValue ? (
        <>
          {VideoComponent}
          <Button
            size="small"
            style={{ marginTop: 15 }}
            basic
            icon="close"
            color="orange"
            content={t("generic.remove")}
            onClick={() => updateIdea(null, ["templated", field.id])}
          />
        </>
      ) : null}
    </BodySegment>
  );
};

export default withTranslation()(Video);

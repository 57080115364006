import { MentionAtomExtension } from "remirror/extensions";

export const createMentionExtension = (options) => {
  const mentionOptions = {
    extraAttributes: {
      target: { default: "_blank" },
      href: { default: null },
      "data-mention-atom-id": { default: "" },
      "data-mention-atom-name": { default: "" },
      contenteditable: { default: "false" },
    },
    matchers: options
      .filter(({ name }) => name !== "emoji")
      .map(({ name, char }) => ({
        name,
        char,
        matchOffset: 0,
        supportedCharacters: /[\w\d_ ]+/,
        suggestClassName: "mention-suggest",
        mentionClassName: "mention",
      })),
    appendText: " ",
    mentionTag: "a",
    suggestTag: "a",
    priority: 99,
  };
  return new MentionAtomExtension(mentionOptions);
};

import React, { useEffect, useState, useCallback, useRef, DragEvent } from "react";
import { Menu, Progress, Button, Popup, PopupProps } from "semantic-ui-react";
import { useAppSelector } from "store";
import toast from "react-hot-toast";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import util from "utils/utils";
import useTheme from "theme/useTheme";

import OneDriveFileChooser from "../OneDriveFileChooser";
import { OnCompleteParams, UploadState, allowedTypes, uploadFile, uploadMultiFiles, verifyType } from "./uploadUtils";
import FileDnDWrapper from "./FileDnDWrapper";

const ChooserPopup = styled(Popup)`
  z-index: 5;
  max-width: 400px !important;
  .menu {
    max-width: 400px !important;
    min-width: 300px !important;
  }
`;

type FileChooserBaseProps = {
  accept?: string;
  imagesOnly?: boolean;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  trigger?: React.ReactElement;
  content?: string;
  forType: string;
  forId: string;
  onError?: (message: string) => void;
  isUploading?: boolean;
  uploadProgress?: number;
  popupProps?: PopupProps;
  onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
  onDragLeave?: () => void;
  style?: React.CSSProperties;
  maxMBFileSize?: number;
};

export type FileChooserProps =
  | ({
      allowMultiple?: false;
      onComplete: (
        savedName: OnCompleteParams["savedName"],
        fileName: OnCompleteParams["fileName"],
        signedDownloadRequest: OnCompleteParams["signedDownloadRequest"],
      ) => void;
      onCompleteMultiple?: never;
    } & FileChooserBaseProps)
  | ({
      allowMultiple: true;
      onCompleteMultiple: (files: OnCompleteParams[]) => void;
      onComplete?: never;
    } & FileChooserBaseProps);

const FileChooser = (props: FileChooserProps) => {
  const { t } = useTranslation();
  const {
    accept,
    isOpen,
    onOpen,
    onClose,
    trigger,
    content,
    forType,
    forId,
    onError,
    isUploading: propIsUploadLoading,
    uploadProgress: propUploadProgress,
    popupProps = {},
    onDragOver,
    onDragLeave,
    onComplete,
    onCompleteMultiple,
    allowMultiple,
    maxMBFileSize = 100,
  } = props;

  const [uploadState, setUploadState] = useState<UploadState>({
    isUploading: false,
    uploadProgress: 0,
  });
  const { isUploading: stateIsUploading, uploadProgress: stateUploadProgress } = uploadState;
  const isUploading = propIsUploadLoading === undefined ? stateIsUploading : propIsUploadLoading;
  const uploadProgress = propUploadProgress === undefined ? stateUploadProgress : propUploadProgress;
  const [open, setOpen] = useState(false);
  const [oneDriveOpen, setOneDriveOpen] = useState(false);
  const inputFile = useRef(null);
  const theme = useTheme();
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (open !== isOpen && typeof isOpen === "boolean") {
      setOpen(!!isOpen);
    }
  }, [open, isOpen]);

  const togglePopup = useCallback(() => {
    if (!open && onOpen) onOpen();
    if (open && onClose) onClose();
    setOpen((prevOpen) => !prevOpen);
  }, [open, onOpen, onClose]);

  const storageFileChosen = useCallback(
    (savedName, fileName, signedDownloadRequest) => {
      setOneDriveOpen(false);
      setOpen(false);
      onComplete(savedName, fileName, signedDownloadRequest);
    },
    [setOneDriveOpen, setOpen, onComplete],
  );

  const chooseFile = useCallback(() => {
    inputFile.current.click();
  }, [inputFile]);

  const getFilesFromEvent = useCallback((e) => {
    const fileList: FileList = e.dataTransfer?.files ?? e.target?.files;

    const filesWithPreview: Array<
      File & {
        preview?: string;
      }
    > = [];

    for (let i = 0; i < fileList.length; i += 1) {
      const file: File & {
        preview?: string;
      } = fileList[i];
      file.preview = URL.createObjectURL(file);
      filesWithPreview.push(file);
    }

    return filesWithPreview;
  }, []);

  const handleSelectedFiles = (e) => {
    const uploadItems = getFilesFromEvent(e);
    if (!allowMultiple) {
      if (uploadItems.length > 1) {
        toast.error("You can only upload one file at a time");
        return;
      }
    }

    if (uploadItems.length > 5) {
      toast.error("You can only upload up to 5 files at once");
      return;
    }

    for (const item of uploadItems) {
      if (accept) {
        const { type } = item;
        const isAllowed = verifyType(accept, type);
        if (!isAllowed) {
          continue;
        }
      }

      if (maxMBFileSize && item.size > maxMBFileSize * 1024 * 1024) {
        toast.error(`"${item.name}" is too large. Max file size is ${maxMBFileSize}MB`);
        return;
      }
    }

    if (allowMultiple) {
      uploadMultiFiles(uploadItems, setUploadState, forType, forId, onCompleteMultiple, onError);
    } else {
      uploadFile(uploadItems[0], setUploadState, forType, forId, onComplete, onError);
    }
  };

  return (
    <React.Fragment>
      <input
        type="file"
        accept={accept ?? Object.values(allowedTypes).join(",")}
        style={{ display: "none" }}
        ref={inputFile}
        multiple={allowMultiple}
        onChange={handleSelectedFiles}
      />

      {/* @ts-ignore */}
      <OneDriveFileChooser
        {...props}
        open={oneDriveOpen}
        onComplete={storageFileChosen}
        onClose={() => {
          setOneDriveOpen(false);
          onClose && onClose();
        }}
      />

      <FileDnDWrapper
        handleSelectedFiles={handleSelectedFiles}
        onError={onError}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        <ChooserPopup
          style={{ zIndex: 1500 }}
          on="click"
          open={open}
          position={theme.sizes.isMobile ? "bottom center" : "bottom left"}
          onClose={() => (onClose ? null : setOpen(false))}
          trigger={
            trigger ? (
              <div>
                {React.cloneElement(trigger, {
                  loading: isUploading || undefined,
                  onClick: togglePopup,
                  uploadState,
                })}
              </div>
            ) : (
              <Button
                size="small"
                secondary
                icon="file"
                fluid
                content={content || "Choose a file"}
                loading={isUploading}
                onClick={togglePopup}
              />
            )
          }
          content={
            <div>
              <h5 style={{ marginTop: 0 }}>Upload a file</h5>
              <p style={{ color: "grey" }}>The maximum file size is {maxMBFileSize}MB.</p>
              <Menu vertical borderless>
                {isUploading && <Progress percent={uploadProgress} indicating progress style={{ margin: 5 }} />}
                <Menu.Item onClick={chooseFile}>
                  <i className="upload icon" />
                  {t("ideas.actions.chooseFile")}
                </Menu.Item>
                {util.userIsConnectedTo(user, "office365") ? (
                  <Menu.Item
                    onClick={() => {
                      setOpen(false);
                      setOneDriveOpen(true);
                    }}
                    icon="microsoft"
                    content="Choose from OneDrive"
                  />
                ) : null}
              </Menu>

              <div style={{ textAlign: "left", marginTop: 20 }}>
                <Button basic size="tiny" content={t("generic.cancel")} onClick={togglePopup} />
              </div>
            </div>
          }
          {...popupProps}
        />
      </FileDnDWrapper>
    </React.Fragment>
  );
};

export default FileChooser;

import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Icon, Popup, Table, Button, Modal, Dropdown } from "semantic-ui-react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import useTheme from "theme/useTheme";
import QRCodeStyling from "qr-code-styling";

import AppIcon from "src/images/appIcon.png";
import { useNavigate } from "react-router-dom";
import { OpenAPI } from "simplydo/interfaces";
import { sdiClickDomain } from "utils/domains";

type ShortenedLink = OpenAPI.GET<`/organisations/{id}/shortenedUrls`>["response"]["shortenedUrls"][0];

const chartOptions = {
  layout: {
    padding: {
      right: 8,
      top: 4,
      left: 3,
      bottom: 4,
    },
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      { ticks: { beginAtZero: true, display: false }, gridLines: { color: "rgba(0,0,0,0.03)", drawBorder: false } },
    ],
    xAxes: [{ type: "time", gridLines: { color: "rgba(0,0,0,0)", display: true }, display: false }],
  },
  legend: {
    display: false,
  },
  tooltips: {
    displayColors: false,
    callbacks: {
      title() {},
      label(tooltipItem, data) {
        const { label } = tooltipItem;
        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return `${label}: ${value} clicks`;
      },
    },
  },
};

type LinkRowProps = {
  link: ShortenedLink;
  deleteUrl: (id: string) => void;
  copyLink: (link: string) => void;
  chartData: (link: any) => any;
  disableReferrals?: boolean;
};

const LinkRow = ({ link, deleteUrl, copyLink, chartData: getChartData, disableReferrals = false }: LinkRowProps) => {
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const navigate = useNavigate();

  const chartData = useMemo(() => getChartData(link), [link, getChartData]);
  const theme = useTheme();
  const qrRef = useRef(null);

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 200,
        height: 200,
        type: "svg",
        data: `${sdiClickDomain}/${link.path}`,
        image: AppIcon,
        dotsOptions: {
          color: "#14435B",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
        imageOptions: {
          margin: 4,
        },
      }),
    [link],
  );

  useEffect(() => {
    if (qrCodeModalOpen) {
      qrCode.append(qrRef.current);
    }
  }, [qrCodeModalOpen, qrCode]);

  const downloadQrCode = useCallback(
    (extension) => {
      qrCode.download({ name: `simpylydo-${link.path}`, extension });
    },
    [qrCode, link],
  );

  return (
    <>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        style={{ marginTop: 50 }}
        open={qrCodeModalOpen}
        onClose={() => setQrCodeModalOpen(false)}
      >
        <Modal.Header>
          <h2>Link QR code</h2>
        </Modal.Header>
        <Modal.Content>
          <p>
            Download this QR code and share it with users to take them directly to the link. Scanning the QR code using
            a camera will take you directly to the link.
          </p>
          <div style={{ width: "70%", margin: "10px auto", textAlign: "center" }} ref={qrRef} />
        </Modal.Content>
        <Modal.Actions>
          <Button icon="close" content="Cancel" onClick={() => setQrCodeModalOpen(false)} />
          <Dropdown trigger={<Button icon="download" content="Download QR code" />} icon={null}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => downloadQrCode("png")}>... download as PNG</Dropdown.Item>
              <Dropdown.Item onClick={() => downloadQrCode("jpeg")}>... download as JPEG</Dropdown.Item>
              <Dropdown.Item onClick={() => downloadQrCode("svg")}>... download as SVG</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Actions>
      </Modal>
      <Table.Row key={link._id}>
        <Table.Cell>
          <a
            href={`${sdiClickDomain}/${link.path}`}
            target="_blank"
            rel="noopener noreferrer"
          >{`${sdiClickDomain}/${link.path}`}</a>
          <span
            style={{ marginLeft: 5, cursor: "pointer" }}
            data-tooltip="Copy to clipboard"
            onClick={() => copyLink(link.path)}
          >
            <Icon name="copy outline" />
          </span>
          <br />
          <small style={{ overflowWrap: "anywhere" }}>
            Resolves to:{" "}
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.url}
            </a>
          </small>
          <br /> <small style={{ overflowWrap: "anywhere" }}>{link?.description}</small>
        </Table.Cell>
        {theme.sizes.isMobile ? (
          <>
            <Table.Cell>
              Created {moment(link.createdAt).fromNow()}, {link.clicks || 0} clicks
            </Table.Cell>
            <Table.Cell />
            <Table.Cell />
          </>
        ) : (
          <>
            <Table.Cell>{moment(link.createdAt).fromNow()}</Table.Cell>
            <Table.Cell>{link.clicks || 0}</Table.Cell>
            {chartData ? (
              <Table.Cell>
                <div style={{ width: 400, height: 75 }}>
                  <Line height={75} width={400} options={chartOptions} data={chartData} />
                </div>
              </Table.Cell>
            ) : null}
          </>
        )}
        <Table.Cell collapsing textAlign="right">
          {!disableReferrals ? (
            <Popup
              on="hover"
              content="Show user capture information from this link"
              trigger={<Button size="tiny" icon="info" onClick={() => navigate(`/admin/links/${link._id}`)} />}
            />
          ) : null}

          <Popup
            on="hover"
            content="Download a QR code to share this link with users. "
            trigger={<Button size="tiny" icon="qrcode" onClick={() => setQrCodeModalOpen(true)} />}
          />
          <Button size="tiny" basic icon="trash" onClick={() => deleteUrl(link._id)} />
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default LinkRow;

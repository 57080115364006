import React, { useState, useMemo, useRef, useEffect, useCallback } from "react";
import {
  Container,
  Button,
  Form,
  Divider,
  Icon,
  Input,
  Message,
  Modal,
  ModalContent,
  ModalActions,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import api from "api";
import QRCodeStyling from "qr-code-styling";
import { connect } from "react-redux";
import util from "utils/utils";
import actions from "actions";

import PasswordGuidance from "components/lib/Entry/PasswordGuidance";
import AppIcon from "src/images/appIcon.png";

export const UserSettingsPassword = ({ user, t, onEnabledMfa }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [working, setWorking] = useState(false);
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [mfa, setMfa] = useState(user.mfa_enabled);
  const [uri, setUri] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [disableMfaModel, setDisableMfaModel] = useState(false);
  const [mfaPassword, setMfaPassword] = useState("");
  const [otpVerifier, setOtpVerifier] = useState("");
  const otpQrRef = useRef(null);

  const updatePassword = () => {
    setWorking(true);
    api.auth.updatePassword(
      currentPassword,
      newPassword,
      () => {
        toast.success(t("users.account.options.changePassword.success"));
        setCurrentPassword("");
        setNewPassword("");
        setWorking(false);
        setErrorMessage("");
      },
      (err) => {
        setWorking(false);
        setErrorMessage(err.message);
      },
    );
  };

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 200,
        height: 200,
        type: "svg",
        data: uri,
        image: AppIcon,
        dotsOptions: {
          color: "#14435B",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
        imageOptions: {
          margin: 4,
        },
      }),
    [uri],
  );

  useEffect(() => {
    if (modalOpen && !qrCode._container) {
      qrCode.append(otpQrRef.current);
    }
  }, [modalOpen, qrCode]);

  const toggleMfa = useCallback(() => {
    const shouldToggle = !mfa;
    if (shouldToggle) {
      setModalOpen(true);
      api.auth.generateOtpUri(
        (check) => {
          setUri(check.uri);
        },
        (err) => {
          toast.error(err.message);
        },
      );
    } else {
      setDisableMfaModel(true);
    }
  }, [mfa]);

  const disableMfa = () => {
    api.auth.disableOtp(
      { password: mfaPassword },
      () => {
        toast.success("Multi factor authentication has been switched off");
        setMfa(false);
      },
      (err) => {
        toast.error(err.message);
      },
    );
    setDisableMfaModel(false);
    setMfaPassword("");
  };

  const checkMfaCode = () => {
    api.auth.enableOtp(
      { otp: otpVerifier },
      () => {
        setMfa(true);
        setModalOpen(false);
        setOtpVerifier("");
        toast.success("Multi factor authentication is now enabled");
        onEnabledMfa();
      },
      (err) => {
        toast.error(err.message);
        setOtpVerifier("");
      },
    );
  };
  return (
    <Container>
      <h3>{t("users.account.options.changePassword.title")}</h3>
      <p>{t("users.account.options.changePassword.info")}</p>
      <Form>
        <Form.Field>
          <label htmlFor="current-password-input">{t("users.account.options.changePassword.currentPass")}</label>
          <Form.Input
            id="current-password-input"
            style={{ width: 300 }}
            type={showPasswordOld ? "text" : "password"}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            icon={{
              name: showPasswordOld ? "eye slash" : "eye",
              circular: true,
              link: true,
              onClick: () => setShowPasswordOld(!showPasswordOld),
            }}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="new-password-input">{t("users.account.options.changePassword.newPass")}</label>
          <Form.Input
            id="new-password-input"
            style={{ width: 300 }}
            type={showPasswordNew ? "text" : "password"}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            icon={{
              name: showPasswordNew ? "eye slash" : "eye",
              circular: true,
              link: true,
              onClick: () => setShowPasswordNew(!showPasswordNew),
            }}
          />
        </Form.Field>
      </Form>
      <PasswordGuidance password={newPassword} />
      {errorMessage && !working && (
        <Message style={{ width: "50%" }} size="small" negative>
          <Icon name="warning circle" />
          {errorMessage}.
        </Message>
      )}
      <Button
        primary
        icon="check"
        content={t("users.account.options.changePassword.change")}
        onClick={() => updatePassword()}
        style={{ maxWidth: "fit-content" }}
        loading={working}
      />

      <Divider hidden />
      <h3>
        <Icon name="lock" style={{ paddingRight: "10px" }} />
        Multi-factor authentication
      </h3>
      <p>
        Multi-factor authentication adds an extra layer of security to your
        {util.appName()} account by ensuring that all login attempts to your account require an additional one-time
        password generated by your device.
      </p>
      <p>
        Apps on the App Store and Google Play allow you to generate one-time passwords. We recommend Google
        Authenticator and Authy, which are both free.
      </p>

      <Button
        primary
        style={{ maxWidth: "fit-content" }}
        icon={!mfa ? "lock" : "unlock"}
        content={mfa ? "Disable multi-factor authentication" : "Enable multi-factor authentication"}
        onClick={toggleMfa}
      />

      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={modalOpen}>
        <Modal.Header>
          <Icon name="lock" style={{ paddingRight: "5px" }} />
          Enable multi-factor authentication
        </Modal.Header>
        <ModalContent>
          <h3>Step 1</h3>
          <p>Open your multi-factor authentication app on your device.</p>
          <p>
            For iOS and Android devices, we recommend installing Google Authenticator or Authy from the App Store or
            Google Play.
          </p>

          <h3>Step 2</h3>
          <p>Use your multi-factor authentication app to scan the QR code displayed below.</p>
          <div style={{ width: "70%", margin: "10px auto", textAlign: "center" }} ref={otpQrRef} />

          <h3>Step 3</h3>
          <p>
            Your app should display a six-digit code. Enter the code below to complete the multi-factor authentication
            setup.
          </p>
          <div style={{ textAlign: "center" }}>
            <Input
              onKeyDown={(e) => {
                e.keyCode === 13 && checkMfaCode();
              }}
              size="large"
              onChange={(e) => setOtpVerifier(e.target.value)}
              value={otpVerifier}
              placeholder="123456"
              autoFocus
            />
          </div>
        </ModalContent>
        <ModalActions>
          <Button
            content={t("generic.cancel")}
            onClick={() => {
              setModalOpen(false);
              setMfa(false);
            }}
          />
          <Button primary content="Verify" onClick={() => checkMfaCode()} />
        </ModalActions>
      </Modal>
      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={disableMfaModel}>
        <Modal.Header>
          <Icon name="lock" style={{ paddingRight: "5px" }} />
          Disable multi-factor authentication
        </Modal.Header>
        <ModalContent>
          <p>For security, if you enable multi-factor authentication again you will need to generate a new key.</p>
          <p>Please enter your password in order to continue.</p>
          <div style={{ textAlign: "center" }}>
            <Input
              size="large"
              onKeyDown={(e) => {
                e.keyCode === 13 && disableMfa();
              }}
              type="password"
              onChange={(e) => setMfaPassword(e.target.value)}
              value={mfaPassword}
              autoFocus
            />
          </div>
        </ModalContent>
        <ModalActions>
          <Button content={t("generic.cancel")} onClick={() => setDisableMfaModel(false)} />
          <Button primary content="Verify" onClick={() => disableMfa()} />
        </ModalActions>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onEnabledMfa: () => dispatch(actions.user.enableMfa()),
});
const UserSettingsPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(UserSettingsPassword);

export default withTranslation()(UserSettingsPasswordContainer);

import React from "react";
import { MultiLineParagraph } from "components/lib/UI";
import { Form, TextArea } from "semantic-ui-react";

const LongText = ({ field, fieldValue = "", updateIdea, isEditing, inputRef, handleKeyDown }) => {
  if (!isEditing) return <MultiLineParagraph content={fieldValue} />;
  return (
    <Form>
      <TextArea
        value={fieldValue}
        onChange={(e, { value }) => updateIdea(value, ["templated", field.id])}
        ref={inputRef}
        onKeyDown={(event) => handleKeyDown(event)}
      />
    </Form>
  );
};

export default LongText;

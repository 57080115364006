import { Other } from "simplydo/interfaces";

import { SearchParams } from "simplydo/core";

export const challenges = (api) => ({
  getAll(
    params: {
      query?: string;
    },
    success,
    fail,
  ) {
    // @ts-ignore
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/challenges/all?${queryParams}`, null, success, fail);
  },
  create(name, success, fail) {
    api.authenticatedRequest("POST", "/challenges", { name }, success, fail);
  },
  createWithData(data: Partial<Other.IChallenge>, success, fail) {
    api.authenticatedRequest("POST", "/challenges", data, success, fail);
  },
  getAudienceSize(visibility, success, fail) {
    api.authenticatedRequest("POST", "/challenges/new/audience", { visibility }, success, fail);
  },
  get(id, success, fail) {
    api.maybeAuthenticatedRequest("GET", `/challenges/${id}`, null, success, fail);
  },
  getOverview(id, params, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/challenges/${id}/overview?${params.filter ? `filter=${params.filter}` : ""}`,
      null,
      success,
      fail,
    );
  },
  getProblems(id, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/problems`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest("DELETE", `/challenges/${id}`, null, success, fail);
  },
  setStatus(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/${id}/status`, data, success, fail);
  },
  updateStage(id, stage, openWithComms, success, fail) {
    if (!stage) api.authenticatedRequest("DELETE", `/challenges/${id}/stage`, null, success, fail);
    else
      api.authenticatedRequest(
        "PUT",
        `/challenges/${id}/stage/${stage}?openWithComms=${openWithComms}`,
        null,
        success,
        fail,
      );
  },
  getIdeas(id, params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/challenges/${id}/ideas?${queryParams}`, null, success, fail);
  },
  getIdeaFilters(id, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/ideas/filters`, null, success, fail);
  },
  getIdeaAssignees(id, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/ideas/assignees`, null, success, fail);
  },
  exportIdeas(id, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/ideas/export`, null, success, fail);
  },
  updateIdeasStatus(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/${id}/ideas/status`, data, success, fail);
  },
  getTemplate(id, success, fail, authToken?: string) {
    api.maybeAuthenticatedRequest(
      "GET",
      `/challenges/${id}/template${authToken ? `?authToken=${authToken}` : ""}`,
      null,
      success,
      fail,
    );
  },
  updateTemplate(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/${id}/template`, data, success, fail);
  },
  getPostable(challenge, success, fail) {
    api.authenticatedRequest("GET", `/organisations/postable/new?challenge=${challenge || ""}`, null, success, fail);
  },
  createIdea(id, name, success, fail) {
    api.maybeAuthenticatedRequest("POST", `/challenges/${id}/ideas`, { name }, success, fail);
  },
  createAnnouncement(id, data, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/announcements`, data, success, fail);
  },
  getAnnouncements(id, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/challenges/${id}/announcements`,
      null,
      (data) => success && success(data.announcements),
      fail,
    );
  },
  deleteAnnouncement(id, announcementId, success, fail) {
    api.authenticatedRequest("DELETE", `/challenges/${id}/announcements/${announcementId}`, null, success, fail);
  },
  updateAnnouncement(id, announcementId, data, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/${id}/announcements/${announcementId}`, data, success, fail);
  },
  getReports(id, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/reports`, null, success, fail);
  },
  getChartData(id, data, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/charts?filter=${data.filter}`, null, success, fail);
  },
  getOptions(id, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/charts/options`, null, success, fail);
  },
  assignAssessors(id, ideas, users, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/${id}/assessors`, { ideas, users }, success, fail);
  },
  assignAssessorsMatrix(id, matrix, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/${id}/assessors`, { matrix }, success, fail);
  },
  unassignAssessors(id, ideas, users, success, fail) {
    api.authenticatedRequest("DELETE", `/challenges/${id}/assessors`, { ideas, users }, success, fail);
  },
  getAssignedAssessments(id, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/assessments`, null, success, fail);
  },
  submitAllAssessments(id, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/assessments`, null, success, fail);
  },
  connectService(id, service, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/connections/${service}`, null, success, fail);
  },
  disconnectService(id, service, success, fail) {
    api.authenticatedRequest("DELETE", `/challenges/${id}/connections/${service}`, null, success, fail);
  },
  updateServiceWithData(id, service, data, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/connections/${service}`, data, success, fail);
  },
  createPlannerPlan(id, data, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/planner/plans`, data, success, fail);
  },
  storeReportOneDrive(reportId, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/reports/${reportId}/storage/onedrive`, null, success, fail);
  },
  follow(id, status = true, success, fail) {
    api.authenticatedRequest(status ? "POST" : "DELETE", `/challenges/${id}/followers`, null, success, fail);
  },
  followers(id, params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/challenges/${id}/followers?${queryParams}`, null, success, fail);
  },
  curate(id, toCurate, success, fail) {
    api.authenticatedRequest(toCurate ? "PUT" : "DELETE", `/challenges/${id}/curate`, null, success, fail);
  },
  getIpSignatureFields(success, fail) {
    api.authenticatedRequest("GET", "/challenges/ipmanagement/fields", null, success, fail);
  },
  getLatestIpStatement(id, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/ipstatements/statements`, null, success, fail);
  },
  agreeToLatestIpStatement(id, statementId, data, success, fail) {
    api.authenticatedRequest(
      "PUT",
      `/challenges/${id}/ipstatements/statements/${statementId}/agreement`,
      data,
      success,
      fail,
    );
  },
  getAgreementsForIPStatement(id, statementId, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/challenges/${id}/ipstatements/statements/${statementId}/agreements`,
      null,
      success,
      fail,
    );
  },
  updateIpStatementStatus(id, toEnable, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/ipstatements`, { enabled: toEnable }, success, fail);
  },
  addIpStatement(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/${id}/ipstatements/statements`, data, success, fail);
  },
  transferIpStatement(id, statementId, data, success, fail) {
    api.authenticatedRequest(
      "POST",
      `/challenges/${id}/ipstatements/statements/${statementId}/transfer`,
      data,
      success,
      fail,
    );
  },
  removeIpStatement(id, statementId, success, fail) {
    api.authenticatedRequest("DELETE", `/challenges/${id}/ipstatements/statements/${statementId}`, null, success, fail);
  },
  setActiveIpStatement(id, statementId, success, fail) {
    api.authenticatedRequest("PUT", `/challenges/${id}/ipstatements/statements/${statementId}`, {}, success, fail);
  },
  updateIpStatement(id, statementId, data, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/ipstatements/statements/${statementId}`, data, success, fail);
  },
  activity(id, params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/challenges/${id}/activity?${queryParams}`, null, success, fail);
  },
  createFromIdea(data, success, fail) {
    api.authenticatedRequest("POST", "/challenges/createFromIdea", data, success, fail);
  },
  getAssessmentDashboard(id, search, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/assessments/dashboard?search=${search}`, null, success, fail);
  },
  getAssessor(id, assessorId, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/assessments/dashboard/${assessorId}`, null, success, fail);
  },
  getPotentialAssessors(id: string, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/assessors/potential`, null, success, fail);
  },
  updatePotentialAssessors(id: string, users: string[], emails: string[], success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/assessors/potential`, { users, emails }, success, fail);
  },
  genAIGenerateChallengeDetails(data: { details: string[] }, success, fail) {
    api.authenticatedRequest("POST", "/challenges/ai/details", data, success, fail);
  },
  genAIGenerateTags(data: { details: string[] }, success, fail) {
    api.authenticatedRequest("POST", "/challenges/ai/tags", data, success, fail);
  },
  genAIImagePrompt(data: { details: string[] }, success, fail) {
    api.authenticatedRequest("POST", "/challenges/ai/image_prompt", data, success, fail);
  },
  assignChallengeRole(id: string, roleType: string, data: { users: string[] }, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/roles/${roleType}/assign`, data, success, fail);
  },
  unassignChallengeRole(id: string, roleType: string, data: { users: string[] }, success, fail) {
    api.authenticatedRequest("DELETE", `/challenges/${id}/roles/${roleType}/assign`, data, success, fail);
  },
  getChallengeRoleUsers(id: string, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/roles/users`, null, success, fail);
  },
  getCalendar(id: string, success, fail) {
    api.authenticatedRequest("GET", `/challenges/${id}/calendar`, null, success, fail);
  },
  updateCalendar(id: string, data, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/calendar`, data, success, fail);
  },
  shiftCalendarEvents(id: string, data, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/calendar/shift`, data, success, fail);
  },
  createCalendarEvent(id: string, event, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/calendar/events`, event, success, fail);
  },
  updateCalendarEvent(id: string, eventId: string, event, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/calendar/events/${eventId}`, event, success, fail);
  },
  deleteCalendarEvent(id: string, eventId: string, success, fail) {
    api.authenticatedRequest("DELETE", `/challenges/${id}/calendar/events/${eventId}`, null, success, fail);
  },
  leaveCalendarEvent(id: string, eventId: string, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/calendar/events/${eventId}/leave`, null, success, fail);
  },
  copy(id: string, success, fail) {
    api.authenticatedRequest("POST", `/challenges/${id}/copy`, null, success, fail);
  },
});

import React, { Component } from "react";
import { Label } from "semantic-ui-react";
import styled from "styled-components";

const ThemedRadio = styled.div`
  padding-left: 13px;
  .container-radio {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 9px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark-radio {
    position: absolute;
    top: 1px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: lightgray;
    border: solid 1px;
    border-color: gray;
    border-radius: 50%;
  }
  .container-radio:hover input ~ .checkmark-radio {
    background-color: #ccc;
  }
  .container-radio input:checked ~ .checkmark-radio {
    border: 0px;
  }
  .checkmark-radio:after {
    content: "";
    position: absolute;
    display: none;
  }
  .container-radio input:checked ~ .checkmark-radio:after {
    display: block;
  }
  .container-radio .checkmark-radio:after {
    top: 5px;
    left: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
  }
`;

const RadioCheck = styled.span.attrs(() => ({
  className: "checkmark-radio",
}))`
  && {
    background-color: ${({ $checked, theme }) => ($checked ? theme.primaryColour : "null")};
  }
`;

class Radio extends Component {
  render() {
    const { label, checked, badge, badgeColour, tooltip, onChange, name } = this.props;
    return (
      <ThemedRadio data-tooltip={tooltip} data-position="top left">
        <label className="container-radio">
          <input type="radio" name={name} onChange={onChange} checked={checked} />
          <RadioCheck $checked={checked} />
          <span className="radio-label">{label}</span>
          {badge && (
            <Label size="mini" color={badgeColour || "violet"} style={{ float: "right" }}>
              {badge}
            </Label>
          )}
        </label>
      </ThemedRadio>
    );
  }
}

export default Radio;

import React from "react";
import { withTranslation } from "react-i18next";
import { Card, Button, Icon } from "semantic-ui-react";

function IdeaFile({ idea, file, t }) {
  if (!file || !idea) return null;
  return (
    <Card.Group centered>
      <Card>
        <Card.Content textAlign="center">
          <Icon name="file" style={{ fontSize: 30, color: "rgb(200,200,200)", marginBottom: 10 }} />
          <Card.Header style={{ overflowWrap: "break-word" }}>{file.name}</Card.Header>
          <Card.Meta>{t("ideas.templated.file.title")}</Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <Button
            as="a"
            href={file.value}
            target="_blank"
            rel="noopener noreferrer"
            download={file.name}
            fluid
            basic
            icon="download"
            content={t("generic.download")}
          />
        </Card.Content>
      </Card>
    </Card.Group>
  );
}

export default withTranslation()(IdeaFile);

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import MessageTray from "components/lib/Messaging/MessageTray";

const MessagesContainer = styled.div`
  background-color: #fff;
  padding: 1rem;
`;

const MobileMessageWidget = ({ user }) => {
  if (!user) return null;
  return (
    <MessagesContainer>
      <MessageTray isMobile />
    </MessagesContainer>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(MobileMessageWidget);

import React, { useState, useEffect, useCallback } from "react";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import actions from "actions";
import api from "api";
import SetupBranding from "./SetupBranding";

const InitialProfileSetup = ({ user, onProfileUpdated }) => {
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const userFirstName = user?.profile?.firstName || "";
  const userLastName = user?.profile?.lastName || "";

  useEffect(() => {
    if (userFirstName) setFirstName(userFirstName);
    if (userLastName) setLastName(userLastName);
  }, [userFirstName, userLastName]);

  const saveProfile = useCallback(() => {
    setSaving(true);
    const profile = { ...user.profile, firstName, lastName };
    api.users.update(
      user._id,
      { profile },
      (newUser) => {
        toast.success("Profile updated");
        setSaving(false);
        onProfileUpdated(newUser.profile);
      },
      (err) => {
        toast.error(err.message);
        setSaving(false);
      },
    );
  }, [firstName, lastName, user.profile, user._id, onProfileUpdated]);

  if (!user) return null;
  return (
    <div>
      <SetupBranding />
      <Grid stackable centered>
        <Grid.Column computer={5} tablet={8}>
          <Segment style={{ marginTop: 50 }}>
            <h3>Please fill in your profile</h3>
            <p>To continue, please let others know who you are.</p>
            <Form>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="First name"
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <Form.Input
                  fluid
                  label="Last name"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
              <Button onClick={saveProfile} loading={saving} primary content="Save profile" />
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onProfileUpdated: (profile) => dispatch(actions.user.updateProfile(profile)),
});

const InitialProfileSetupContainer = connect(mapStateToProps, mapDispatchToProps)(InitialProfileSetup);

export default InitialProfileSetupContainer;

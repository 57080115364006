import React, { useState, useEffect } from "react";
import useMention from "./useMention";
import Dropdown from "./Dropdown";

const mapToName = (list) =>
  list.reduce((p, c) => {
    p[c.name] = c;
    return p;
  }, {});

/**
 * The mention components
 *
 * @param mentions - a map of name to available mentions
 * @param config - the configuration objects used to initialise the mention extension
 * @param onMentionSelect - Will receive the selected mention as a parameter
 */
const Mentions = ({ mentions, config, onSuggest, onMentionSelect }) => {
  const [mappedConfig, setMappedConfig] = useState(() => mapToName(config));

  useEffect(() => {
    setMappedConfig(mapToName(config));
  }, [config]);

  const mentionProps = useMention({
    allMentions: mentions,
    config: mappedConfig,
    onSuggest,
    onMentionSelect,
  });
  return <Dropdown {...mentionProps} />;
};

export default Mentions;

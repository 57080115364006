const googleHelperActions = {
  REQUEST_SEARCH: "REQUEST_SEARCH",
  RECEIVE_SEARCH: "RECEIVE_SEARCH",
  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
  OPEN_GOOGLE_HELPER: "OPEN_GOOGLE_HELPER",

  requestSearch: () => ({
    type: googleHelperActions.REQUEST_SEARCH,
    payload: {
      loading: true,
    },
  }),
  receiveSearch: (searchResults: unknown[]) => ({
    type: googleHelperActions.RECEIVE_SEARCH,
    payload: {
      loading: false,
      searchResults,
    },
  }),
  setSearchQuery: (searchTerm: string) => ({
    type: googleHelperActions.SET_SEARCH_QUERY,
    payload: {
      searchTerm,
    },
  }),
  openGoogleHelper: (isOpen: boolean) => ({
    type: googleHelperActions.OPEN_GOOGLE_HELPER,
    payload: {
      isOpen,
    },
  }),
};

export default googleHelperActions;

const constants = {
  termsVersion: 2,
  avatars: ["1.png", "2.png", "3.png", "4.png", "5.png", "6.png", "7.png", "8.png"],
  defaultPrimaryColour: "#819df8",

  businessSectors: [
    "Agriculture and Farming",
    "Consumer Products and Retail",
    "Creative Industries",
    "Education, Recruitment and Training",
    "Environmental and Energy",
    "Financial and Professional Services",
    "Food and Hospitality",
    "I.C.T.",
    "Life Sciences",
    "Manufacturing, Advanced Materials and Engineering",
    "Property and Construction",
    "Sport, Leisure and Tourism",
    "Other",
  ],

  organisationCategories: [
    "Accountancy, banking and finance",
    "Business, consulting and management",
    "Charity and voluntary work",
    "Creative arts and design",
    "Energy and utilities",
    "Engineering and manufacturing",
    "Environment and agriculture",
    "Healthcare",
    "Hospitality and events management",
    "Information technology",
    "Law",
    "Law enforcement and security",
    "Leisure, sport and tourism",
    "Marketing, advertising and PR",
    "Media and internet",
    "Property and construction",
    "Public services and administration",
    "Recruitment and HR",
    "Retail",
    "Sales",
    "Science and pharmaceuticals",
    "Social care",
    "Teacher training and education",
    "Transport and logistics",
  ],

  supplierInnovationTags: [
    "Aerospace and Defence",
    "Agriculture, Forestry and Paper",
    "Automotive: Vehicle and Parts",
    "Banks and insurance",
    "Chemicals",
    "Construction and Materials",
    "Electronics and Electrical Equipment",
    "Food and Beverage",
    "Healthcare",
    "Industrial and General Engineering",
    "Industrial Goods and Services",
    "Industrial Metals",
    "Media",
    "Mining",
    "Oil and Gas",
    "Personal and Household Goods",
    "Pharmaceuticals and Biotechnology",
    "Retail",
    "Technology – Hardware & Software",
    "Telecommunications",
    "Transportation",
    "Travel, Leisure and Leisure Goods",
    "Utilities",
    "Other",
  ],

  employeeImprovementTags: [
    "Business Continuity",
    "CPD",
    "Charity and Voluntary",
    "Diversity and Inclusion",
    "Equipment",
    "Estates and Facilities",
    "External Communications",
    "Finance",
    "Health and Safety",
    "Internal Communications",
    "Leadership and Organisational Development",
    "User Experience",
    "Quality Improvement",
    "Recognition and Awards",
    "Sustainability",
  ],

  businessExperiences: [
    "Work experience",
    "Summer placements",
    "Year long placements",
    "Internships (paid)",
    "Internships (unpaid)",
  ],

  fileTypeIcons: {
    "archive outline": ["zip", "gz", "tar"],
    "word outline": ["doc", "docx"],
    "code outline": ["js", "python", "c", "java", "json", "xml", "html", "css", "md"],
    "image outline": ["jpg", "jpeg", "png", "gif", "bmp"],
    "pdf outline": ["pdf"],
    "video outline": ["mp4", "mkv", "wmv", "m4"],
    "powerpoint outline": ["ppt", "pptx"],
    "audio outline": ["wav", "mp3", "ogg"],
    "excel outline": ["xls", "xlsx"],
    "text outline": ["txt"],
  },

  permissions: {
    super: [
      {
        key: "super.viewDashboard",
        title: "permissions.super.viewDashboard.title",
        text: "permissions.super.viewDashboard.text",
      },
      {
        key: "super.listOrgs",
        title: "permissions.super.listOrgs.title",
        text: "permissions.super.listOrgs.tex",
      },
      {
        key: "super.createOrgs",
        title: "permissions.super.createOrgs.title",
        text: "permissions.super.createOrgs.text",
      },
      {
        key: "super.manageOrgs",
        title: "permissions.super.manageOrgs.title",
        text: "permissions.super.manageOrgs.text",
      },
      {
        key: "super.deleteOrgs",
        title: "permissions.super.deleteOrgs.title",
        text: "permissions.super.deleteOrgs.text",
      },
      {
        key: "super.switchOrg",
        title: "permissions.super.switchOrg.title",
        text: "permissions.super.switchOrg.text",
      },
      {
        key: "super.listRecents",
        title: "permissions.super.listRecents.title",
        text: "permissions.super.listRecents.text",
      },
      {
        key: "super.managePermissions",
        title: "permissions.super.managePermissions.title",
        text: "permissions.super.managePermissions.text",
      },
      {
        key: "super.ghostUser",
        title: "permissions.super.ghostUser.title",
        text: "permissions.super.ghostUser.text",
      },
    ],
    org: [
      {
        key: "org.viewDashboard",
        title: "permissions.org.viewDashboard.title",
        text: "permissions.org.viewDashboard.text",
      },
      {
        key: "org.listUsers",
        title: "permissions.org.listUsers.title",
        text: "permissions.org.listUsers.text",
      },
      {
        key: "org.deleteUsers",
        title: "permissions.org.deleteUsers.title",
        text: "permissions.org.deleteUsers.text",
      },
      {
        key: "org.uploadFiles",
        title: "permissions.org.uploadFiles.title",
        text: "permissions.org.uploadFiles.text",
      },
      {
        key: "org.deleteFiles",
        title: "permissions.org.deleteFiles.title",
        text: "permissions.org.deleteFiles.text",
      },
      {
        key: "org.createGroups",
        title: "permissions.org.createGroups.title",
        text: "permissions.org.createGroups.text",
      },
      {
        key: "org.manageGroups",
        title: "permissions.org.manageGroups.title",
        text: "permissions.org.manageGroups.text",
      },
      {
        key: "org.addToGroupsDirectly",
        title: "permissions.org.addToGroupsDirectly.title",
        text: "permissions.org.addToGroupsDirectly.text",
      },
      {
        key: "org.deleteGroups",
        title: "permissions.org.deleteGroups.title",
        text: "permissions.org.deleteGroups.text",
      },
      {
        key: "org.createChallenges",
        title: "permissions.org.createChallenges.title",
        text: "permissions.org.createChallenges.text",
      },
      {
        key: "org.manageChallenges",
        title: "permissions.org.manageChallenges.title",
        text: "permissions.org.manageChallenges.text",
      },
      {
        key: "org.publishChallenges",
        title: "permissions.org.publishChallenges.title",
        text: "permissions.org.publishChallenges.text",
      },
      {
        key: "org.deleteChallenges",
        title: "permissions.org.deleteChallenges.title",
        text: "permissions.org.deleteChallenges.text",
      },
      {
        key: "org.createAnnouncements",
        title: "permissions.org.createAnnouncements.title",
        text: "permissions.org.createAnnouncements.text",
      },
      {
        key: "org.manageAnnouncements",
        title: "permissions.org.manageAnnouncements.title",
        text: "permissions.org.manageAnnouncements.text",
      },
      {
        key: "org.changeSettings",
        title: "permissions.org.changeSettings.title",
        text: "permissions.org.changeSettings.text",
      },
      {
        key: "org.managePermissions",
        title: "permissions.org.managePermissions.title",
        text: "permissions.org.managePermissions.text",
      },
      {
        key: "org.innovationIntelligence",
        title: "permissions.org.innovationIntelligence.title",
        text: "permissions.org.innovationIntelligence.text",
      },
      {
        key: "org.ipManagement",
        title: "permissions.org.ipManagement.title",
        text: "permissions.org.ipManagement.text",
      },
      {
        key: "org.viewEdi",
        title: "permissions.org.viewEdi.title",
        text: `permissions.org.viewEdi.text`,
      },
    ],
    group: [
      {
        key: "group.editSettings",
        title: "permissions.group.editSettings.title",
        text: "permissions.group.editSettings.text",
      },
      {
        key: "group.managePages",
        title: "permissions.group.managePages.title",
        text: "permissions.group.managePages.text",
      },
      {
        key: "group.view",
        title: "permissions.group.view.title",
        text: "permissions.group.view.text",
      },
      {
        key: "group.postDiscussion",
        title: "permissions.group.postDiscussion.title",
        text: "permissions.group.postDiscussion.text",
      },
      {
        key: "group.viewDiscussion",
        title: "permissions.group.viewDiscussion.title",
        text: "permissions.group.viewDiscussion.text",
      },
      {
        key: "group.listUsers",
        title: "permissions.group.listUsers.title",
        text: "permissions.group.listUsers.text",
      },
      {
        key: "group.listBusinesses",
        title: "permissions.group.listBusinesses.title",
        text: "permissions.group.listBusinesses.text",
      },
    ],
    ideaBusinessProfile: [
      {
        key: "ideaBusinessProfile.viewDetails",
        title: "permissions.ideaBusinessProfile.viewDetails.title",
        text: "permissions.ideaBusinessProfile.viewDetails.text",
      },
      {
        key: "ideaBusinessProfile.editDetails",
        title: "permissions.ideaBusinessProfile.editDetails.title",
        text: "permissions.ideaBusinessProfile.editDetails.text",
      },
      {
        key: "ideaBusinessProfile.viewIdeas",
        title: "permissions.ideaBusinessProfile.viewIdeas.title",
        text: "permissions.ideaBusinessProfile.viewIdeas.text",
      },
      {
        key: "ideaBusinessProfile.editIdeas",
        title: "permissions.ideaBusinessProfile.editIdeas.title",
        text: "permissions.ideaBusinessProfile.editIdeas.text",
      },
      {
        key: "ideaBusinessProfile.viewMembers",
        title: "permissions.ideaBusinessProfile.viewMembers.title",
        text: "permissions.ideaBusinessProfile.viewMembers.text",
      },
      {
        key: "ideaBusinessProfile.editMembers",
        title: "permissions.ideaBusinessProfile.editMembers.title",
        text: "permissions.ideaBusinessProfile.editMembers.text",
      },
      {
        key: "ideaBusinessProfile.deleteProfile",
        title: "permissions.ideaBusinessProfile.deleteProfile.title",
        text: "permissions.ideaBusinessProfile.deleteProfile.text",
      },
    ],
    challenge: [
      {
        key: "challenge.viewSettings",
        title: "permissions.challenge.viewSettings.title",
        text: "permissions.challenge.viewSettings.text",
      },
      {
        key: "challenge.editSettings",
        title: "permissions.challenge.editSettings.title",
        text: "permissions.challenge.editSettings.text",
      },
      {
        key: "challenge.editIdeaFormat",
        title: "permissions.challenge.editIdeaFormat.title",
        text: "permissions.challenge.editIdeaFormat.text",
      },
      {
        key: "challenge.viewMembers",
        title: "permissions.challenge.viewMembers.title",
        text: "permissions.challenge.viewMembers.text",
      },
      {
        key: "challenge.editMembers",
        title: "permissions.challenge.editMembers.title",
        text: "permissions.challenge.editMembers.text",
      },
      {
        key: "challenge.viewIdeas",
        title: "permissions.challenge.viewIdeas.title",
        text: "permissions.challenge.viewIdeas.text",
      },
      {
        key: "challenge.deleteIdeas",
        title: "permissions.challenge.deleteIdeas.title",
        text: "permissions.challenge.deleteIdeas.text",
      },
      {
        key: "challenge.mergeIdeas",
        title: "permissions.challenge.mergeIdeas.title",
        text: "permissions.challenge.mergeIdeas.text",
      },
      {
        key: "challenge.pinIdeas",
        title: "permissions.challenge.pinIdeas.title",
        text: "permissions.challenge.pinIdeas.text",
      },
      {
        key: "challenge.manageIdeaComments",
        title: "permissions.challenge.manageIdeaComments.title",
        text: "permissions.challenge.manageIdeaComments.text",
      },
      {
        key: "challenge.editIdeaTags",
        title: "permissions.challenge.tagIdeas.title",
        text: "permissions.challenge.tagIdeas.text",
      },
      {
        key: "challenge.editIdeaDeadlines",
        title: "permissions.challenge.editIdeaDeadlines.title",
        text: "permissions.challenge.editIdeaDeadlines.text",
      },
      {
        key: "challenge.editIdeaLabels",
        title: "permissions.challenge.editIdeaLabels.title",
        text: "permissions.challenge.editIdeaLabels.text",
      },
      {
        key: "challenge.moveIdeasToChallenge",
        title: "permissions.challenge.moveIdeasToChallenge.title",
        text: "permissions.challenge.moveIdeasToChallenge.text",
      },
      {
        key: "challenge.updateIdeaStatus",
        title: "permissions.challenge.updateIdeaStatus.title",
        text: "permissions.challenge.updateIdeaStatus.text",
      },
      {
        key: "challenge.viewActivity",
        title: "permissions.challenge.viewActivity.title",
        text: "permissions.challenge.viewActivity.text",
      },
      {
        key: "challenge.viewAssessments",
        title: "permissions.challenge.viewAssessments.title",
        text: "permissions.challenge.viewAssessments.text",
      },
      {
        key: "challenge.editAssessments",
        title: "permissions.challenge.editAssessments.title",
        text: "permissions.challenge.editAssessments.text",
      },
      {
        key: "challenge.viewPlanner",
        title: "permissions.challenge.viewPlanner.title",
        text: "permissions.challenge.viewPlanner.text",
      },
      {
        key: "challenge.viewProjectBoard",
        title: "permissions.challenge.viewProjectBoard.title",
        text: "permissions.challenge.viewProjectBoard.text",
      },
      {
        key: "challenge.editProjectBoard",
        title: "permissions.challenge.editProjectBoard.title",
        text: "permissions.challenge.editProjectBoard.text",
      },
      {
        key: "challenge.moveProjectIdeas",
        title: "permissions.challenge.moveProjectIdeas.title",
        text: "permissions.challenge.moveProjectIdeas.text",
      },
      {
        key: "challenge.viewProjectAssignees",
        title: "permissions.challenge.viewProjectAssignees.title",
        text: "permissions.challenge.viewProjectAssignees.text",
      },
      {
        key: "challenge.editProjectAssignees",
        title: "permissions.challenge.editProjectAssignees.title",
        text: "permissions.challenge.editProjectAssignees.text",
      },
      {
        key: "challenge.viewProjectImpacts",
        title: "permissions.challenge.viewProjectImpacts.title",
        text: "permissions.challenge.viewProjectImpacts.text",
      },
      {
        key: "challenge.editProjectImpacts",
        title: "permissions.challenge.editProjectImpacts.title",
        text: "permissions.challenge.editProjectImpacts.text",
      },
      {
        key: "challenge.viewProjectNotes",
        title: "permissions.challenge.viewProjectNotes.title",
        text: "permissions.challenge.viewProjectNotes.text",
      },
      {
        key: "challenge.createProjectNotes",
        title: "permissions.challenge.createProjectNotes.title",
        text: "permissions.challenge.createProjectNotes.text",
      },
      {
        key: "challenge.viewProjectActivity",
        title: "permissions.challenge.viewProjectActivity.title",
        text: "permissions.challenge.viewProjectActivity.text",
      },
      {
        key: "challenge.editProjectDates",
        title: "permissions.challenge.editProjectDates.title",
        text: "permissions.challenge.editProjectDates.text",
      },
      {
        key: "challenge.delete",
        title: "permissions.challenge.delete.title",
        text: "permissions.challenge.delete.text",
      },
    ],
  },
  legacyStampMapper: {
    green_apple: "green",
    full_moon: "yellow",
    large_orange_diamond: "orange",
    red_circle: "red",
    aquarius: "purple",
    large_blue_circle: "blue",
    ribbon: "pink",
    green: "green_apple",
    yellow: "full_moon",
    orange: "large_orange_diamond",
    red: "red_circle",
    purple: "aquarius",
    blue: "large_blue_circle",
    pink: "ribbon",
  },
  subscriptions: [
    {
      type: "general",
      title: "subscriptions.directMessages.title",
      key: "directMessages",
      text: "subscriptions.directMessages.text",
    },
    {
      type: "general",
      title: "subscriptions.announcements.title",
      key: "announcements",
      text: "subscriptions.announcements.text",
    },
    {
      type: "general",
      title: "subscriptions.invitations.title",
      key: "invitations",
      text: "subscriptions.invitations.text",
    },
    {
      type: "general",
      title: "subscriptions.userInteraction.title",
      key: "userInteraction",
      text: "subscriptions.userInteraction.text",
    },
    {
      type: "general",
      title: "subscriptions.achievements.title",
      key: "achievements",
      text: "subscriptions.achievements.text",
    },
    {
      type: "ideas",
      title: "subscriptions.ideaUpdates.title",
      key: "ideaUpdates",
      text: "subscriptions.ideaUpdates.text",
    },
    {
      type: "ideas",
      title: "subscriptions.ideaManagement.title",
      key: "ideaManagement",
      text: "subscriptions.ideaManagement.text",
    },
    {
      type: "challenges",
      title: "subscriptions.challengeUpdates.title",
      key: "challengeUpdates",
      text: "subscriptions.challengeUpdates.text",
    },
    {
      type: "challenges",
      title: "subscriptions.challengeManagement.title",
      key: "challengeManagement",
      text: "subscriptions.challengeManagement.text",
    },
    {
      type: "challenges",
      title: "subscriptions.challengeDailyUpdates.title",
      key: "challengeDailyUpdates",
      text: "subscriptions.challengeDailyUpdates.text",
    },
    {
      type: "challenges",
      title: "subscriptions.challengeWeeklyUpdates.title",
      key: "challengeWeeklyUpdates",
      text: "subscriptions.challengeWeeklyUpdates.text",
    },
    {
      type: "groups",
      title: "subscriptions.groupDiscussions.title",
      key: "groupDiscussions",
      text: "subscriptions.groupDiscussions.text",
    },
    {
      type: "ideas",
      title: "subscriptions.followedIdeaWeeklyUpdates.title",
      key: "followedIdeaWeeklyUpdates",
      text: "subscriptions.followedIdeaWeeklyUpdates.text",
    },
  ],

  enabledFeatures: [
    {
      key: "challenges",
      title: "Create challenges",
      forType: "challenge",
    },
    {
      key: "createExternalChallenges",
      title: "Create external challenges",
      forType: "challenge",
    },
    {
      key: "receiveExternalChallenges",
      title: "Receive external challenges",
      forType: "challenge",
    },
    {
      key: "ipManagement",
      title: "Challenge IP management",
      forType: "challenge",
    },
    {
      key: "receiveReferrals",
      title: "Receive user referred from another organisation",
      forType: "general",
    },
    {
      key: "innovationIntelligence",
      title: "Global search and dashboards",
      forType: "innointel",
    },
    {
      key: "innovationIntelligenceLocalSearch",
      title: "Local Search",
      forType: "innointel",
      label: "Beta",
    },
    {
      key: "ideaImpacts",
      title: "Idea impacts",
      forType: "challenge",
    },
    {
      key: "globalCreditsafe",
      title: "Use SimplyDo's Creditsafe login",
      forType: "integrations",
    },
    {
      key: "simplyCrm",
      title: "Enable Simply CRM integration",
      forType: "integrations",
    },
    {
      key: "ideaBusinessProfiles",
      title: "Idea business profiles",
      forType: "challenge",
    },
    {
      key: "openAI",
      title: "Open AI features",
      forType: "integrations",
    },
    {
      key: "allowPublicChallenges",
      title: "Allow public challenges",
      forType: "general",
    },
    {
      key: "ideaSpreadsheetView",
      title: `Idea spreadsheet view`,
      forType: "general",
    },
    {
      key: "customPages",
      title: "Create richtext custom pages",
      forType: "general",
    },
    {
      key: "stockImages",
      title: "Enable stock images",
      forType: "general",
    },
    {
      key: "userGhosting",
      title: "User ghosting (Organisation-level user ghosting)",
      forType: "general",
      showWarning: "This should be used with caution, as it can be used to impersonate users.",
    },
    {
      key: "conditionalFields",
      title: "Conditional fields in idea formats",
      forType: "challenge",
      label: "Beta",
    },
    {
      key: "ideaMerging",
      title: "Idea merging",
      forType: "challenge",
      label: "Beta",
    },
    {
      key: "googleHelper",
      title: "Google Helper FAB",
      forType: "innointel",
      label: "Beta",
    },
    {
      key: "bypassSSOProtection",
      title: "Bypass SSO login protection",
      forType: "general",
      showWarning:
        "This should be used with caution, as it bypasses SSO login protection which enables users to take control of previously created accounts if they control the email address.",
    },
    {
      key: "dynamics365",
      forType: "integrations",
      label: "Beta",
      showWarning: "Do not enable for production use.",
      title: "Dynamics 365 integration",
    },
    {
      key: "challengePlanner",
      title: "Challenge planner",
      forType: "integrations",
      label: "Beta",
    },
    {
      key: "impactStatements",
      title: "New challenge impacts",
      forType: "challenge",
      label: "Beta",
    },
  ] as const,
  superadminTags: [
    {
      key: "internalInnovation",
      title: "Employee led",
    },
    {
      key: "externalInnovation",
      title: "Supplier led",
    },
    {
      key: "police",
      title: "Police",
    },
    {
      key: "nhs",
      title: "NHS",
    },
    {
      key: "defence",
      title: "Defence",
    },
    {
      key: "government",
      title: "Government",
    },
    {
      key: "housing",
      title: "Housing",
    },
    {
      key: "academia",
      title: "Academia",
    },
    {
      key: "other",
      title: "Other",
    },
  ],

  emailRegex:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape

  defaultSubscriptions: {
    announcements: ["email"],
    directMessages: ["email", "push"],
    invitations: ["email"],
    mentions: ["email", "push"],
    userInteraction: ["email", "push"],
    achievements: ["push"],
    challengeUpdates: ["email", "push"],
    challengeManagement: ["email", "push"],
    ideaUpdates: ["email", "push"],
    ideaManagement: ["email", "push"],
    challengeDailyUpdates: [],
    challengeWeeklyUpdates: ["email", "push"],
    groupDiscussions: ["email", "push"],
  },

  productTours: [
    // Tour info:
    // - After adding a tour, ensure its enabled in the settings for organisation
    /*
    {
      key: 'example',
      value: 'example',
      title: 'Example title', // This is displayed to admins when enabling/disabling the tour
      description: 'Explain to users what a challenge is, and show where they can create an idea', // Same as above
      steps: [ // Any number of steps can be provided
        {
          target: '.challenge-tour-step-1', // CSS classname of the element to show the tooltip over
          content: 'This is the challenge header', // The content of the tooltip. This can be a string OR any react node
          disableBeacon: true, // 99% of the time you want to set disableBeacon: true - this ensures the tooltip automatically opens without user input,
          //
          // The following custom parameters are added to allow custom actions to take place.
          // If you provide a prop `customFunctions` to the ProductTour function, which is an object which keys as functionIds, then by specifing the functionId here they be called when that step is run _before_ any content is shown
          //
          customTourFunctionId: 'functionIdHere'
        },
      ],
    }
    */
    {
      key: "assessment",
      value: "assessment",
      title: "productTours.assessment.title",
      description: "productTours.assessment.description",
      steps: [
        {
          target: ".assessment-tour-step-1",
          content: "productTours.assessment.steps.1.content",
          disableBeacon: true,
          customTourFunctionId: "closeIdea",
        },
        {
          target: ".assessment-tour-step-2",
          content: "productTours.assessment.steps.2.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-tour-step-5",
          content: "productTours.assessment.steps.3.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-tour-step-3",
          content: "productTours.assessment.steps.4.content",
          disableBeacon: true,
        },
        {
          placement: "center",
          content: "productTours.assessment.steps.5.content",
          target: "body",
          disableBeacon: true,
          customTourFunctionId: "openIdea",
        },
        {
          target: ".assessment-tour-step-4",
          content: "productTours.assessment.steps.6.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-tour-step-6",
          content: "productTours.assessment.steps.7.content",
          customTourFunctionId: "closeIdea",
          disableBeacon: true,
        },
      ],
    },
    {
      key: "assessmentHomepage",
      value: "assessmentHomepage",
      title: "productTours.assessmentHomepage.title",
      description: "productTours.assessmentHomepage.description",
      steps: [
        {
          placement: "center",
          target: "body",
          content: "productTours.assessmentHomepage.steps.1.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-homepage-tour-step-1",
          content: "productTours.assessmentHomepage.steps.2.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-homepage-tour-step-2",
          content: "productTours.assessmentHomepage.steps.3.content",
          disableBeacon: true,
        },
      ],
    },
    {
      key: "innovationIntelligenceOverview",
      value: "innovationIntelligenceOverview",
      title: "productTours.innovationIntelligenceOverview.title",
      description: "productTours.innovationIntelligenceOverview.description",
      steps: [
        {
          placement: "center",
          target: "body",
          content: "productTours.innovationIntelligenceOverview.steps.1.content",
        },
        {
          target: ".innovation-intelligence-overview-tour-step-1",
          content: "productTours.innovationIntelligenceOverview.steps.2.content",
          disableBeacon: true,
        },
        {
          target: ".innovation-intelligence-overview-tour-step-2",
          content: "productTours.innovationIntelligenceOverview.steps.3.content",
          disableBeacon: true,
        },
        {
          target: ".innovation-intelligence-overview-tour-step-3",
          content: "productTours.innovationIntelligenceOverview.steps.4.content",
          disableBeacon: true,
        },
      ],
    },
    {
      key: "innovationIntelligenceSearch",
      value: "innovationIntelligenceSearch",
      title: "productTours.innovationIntelligenceSearch.title",
      description: "productTours.innovationIntelligenceSearch.description",
      steps: [
        {
          placement: "center",
          target: "body",
          content: "productTours.innovationIntelligenceSearch.steps.1.content",
        },
        {
          target: ".innovation-intelligence-search-tour-step-1",
          content: "productTours.innovationIntelligenceSearch.steps.2.content",
          disableBeacon: true,
          customTourFunctionId: "exampleSearch",
        },
      ],
    },
    {
      key: "orgSwitcher",
      value: "orgSwitcher",
      title: "productTours.orgSwitcher.title",
      description: "productTours.orgSwitcher.description",
      steps: [
        {
          target: ".org-switcher-tour-step-1",
          content: "productTours.orgSwitcher.steps.1.content",
          disableBeacon: true,
        },
      ],
    },
    {
      key: "projectBoardOnIdeasPage",
      value: "projectBoardOnIdeasPage",
      title: "productTours.projectBoardOnIdeasPage.title",
      description: "productTours.projectBoardOnIdeasPage.description",
      steps: [
        {
          target: ".project-board-ideas-page-1",
          content: "productTours.projectBoardOnIdeasPage.steps.1.content",
          disableBeacon: true,
        },
      ],
    },
  ],

  accreditations: [
    { key: "cyberEssentialsPlus", text: "Cyber Essentials Plus" },
    { key: "cyberEssentials", text: "Cyber Essentials" },
    { key: "ISO27001", text: "ISO 27001" },
    { key: "ISO9001", text: "ISO 9001" },
    { key: "FENSA", text: "FENSA" },
    { key: "SSIP", text: "SSIP" },
    { key: "MCS", text: "MCS" },
    { key: "PAS2030", text: "PAS 2030" },
    { key: "livingWageEmployer", text: "Living Wage Employer" },
    { key: "other", text: "Other" },
  ],

  challengeDeadlineMomentFormat: "DD/MM/YYYY HH:mm",
};

export default constants;

import React, { Component } from "react";
import { Divider, Grid, Segment, Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import constants from "utils/constants";
import actions from "actions";
import api from "api";

import SettingActions from "components/lib/SettingActions";
import TermsCheckbox from "./TermsCheckbox";

class AgreeToTerms extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, agreed: false };
  }

  clicked = () => {
    if (this.state.agreed) {
      this.setState({ loading: true });
      api.auth.agreeToTerms(
        constants.termsVersion,
        (agreements) => {
          this.props.onTermsAgreed(agreements);
          this.setState({ loading: false });
        },
        (err) => {
          toast.error(err.message);
          this.setState({ loading: false });
        },
      );
    } else {
      toast.error("Please agree to the terms before continuing");
    }
  };

  render() {
    const { user } = this.props;
    if (!user) return null;
    return (
      <div>
        <Grid stackable centered>
          <Grid.Column computer={6} tablet={10}>
            <Segment style={{ marginTop: 50 }}>
              <h3>We value your privacy, {user && user.profile ? user.profile.firstName : null}</h3>
              <p>You are seeing this message because our Policies or Terms may have recently changed.</p>
              <TermsCheckbox agreed={this.state.agreed} onChange={(agreed) => this.setState({ agreed })} />
              <Divider hidden />
              <SettingActions>
                <Button primary content="Continue" onClick={this.clicked} loading={this.state.loading} />
              </SettingActions>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onTermsAgreed: (agreements) => dispatch(actions.user.agreeTerms(agreements)),
});
const AgreeToTermsContainer = connect(mapStateToProps, mapDispatchToProps)(AgreeToTerms);

export default AgreeToTermsContainer;

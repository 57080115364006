import React, { useState } from "react";
import { Icon, Dropdown, Input, Popup, Divider } from "semantic-ui-react";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import { InfoContainer, InfoBoxWidget, InfoBoxWidgetTitleArea, InfoItem } from "../Styles";

const StyledShareholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 2.5px;
  ${({ $isVC }) =>
    $isVC &&
    `
    background-color: #ffdd99
  `}
  ${({ $isCompany, $isVC }) =>
    $isCompany &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${$isVC ? "#ffc34d" : "#e9ebee"};
    }
  `}
  span {
    display: block;
    margin: 2.5px 0;
  }
  .type {
    color: gray;
    font-weight: bold;
  }
  .name {
    font-size: 1.2em;
  }
  .meta {
    color: gray;
  }
`;

const wordsToRemove = [
  "l.p.",
  "investments",
  "llc",
  "limited",
  "investment",
  "i",
  "ii",
  "iii",
  "iv",
  "v",
  "vi",
  "vii",
  "viii",
  "ix",
  "x",
  "ltd",
  "fund",
  "stock",
  "growth",
  "equity",
  "partners",
  "insights",
  "lp",
  "s.r.l",
  "contrafund",
  "holdings",
  "nv",
  "gpe",
  "pec",
];

const displayOptions = [
  { key: "company", text: "Companies", value: "company" },
  { key: "person", text: "People", value: "person" },
  { key: "other", text: "Other", value: "other" },
];

const getSanitisedCompanyName = (companyName) => {
  // Most of the investment names have additional elements like:
  // 'IV', 'LLC', 'L.P'
  // We attempt to remove these to make the search more reliable
  const splitCompanyName = companyName.split(" ");
  const filteredCompanyName = splitCompanyName.filter((word) => wordsToRemove.indexOf(word.toLowerCase()) === -1);
  return filteredCompanyName.join(" ");
};

const ventureCapitalWords = ["vc", "capital", "bank", "venture", "ventures"];

const isVC = (companyName) => {
  const splitCompanyName = companyName.toLowerCase().split(" ");
  return splitCompanyName.filter((word) => ventureCapitalWords.indexOf(word) > -1).length > 0;
};

const sortedShareholders = (sortType, shareholders) => {
  switch (sortType) {
    case "sharesDesc":
      return shareholders.sort((a, b) => b.totalNumberOfSharesOwned > a.totalNumberOfSharesOwned);
    case "sharesAsc":
      return shareholders.sort((a, b) => a.totalNumberOfSharesOwned > b.totalNumberOfSharesOwned);
    default:
      return shareholders.sort((a, b) => b.totalNumberOfSharesOwned > a.totalNumberOfSharesOwned);
  }
};

const CompanyShareholders = ({ shareholders, handleNewSearch, isPDF }) => {
  const [sortType, setSortType] = useState("sharesDesc");
  const [show, setShow] = useState(["company", "person", "other"]);
  const [filter, setFilter] = useState("");
  const theme = useTheme();

  if (shareholders?.length) {
    return (
      <InfoBoxWidget $noBox style={!isPDF ? { maxHeight: 520, minHeight: 200, overflow: "auto" } : {}}>
        <InfoBoxWidgetTitleArea>
          <Icon name="group" />
          <h3>Shareholders</h3>
          <span>{shareholders.length} shareholder(s) found</span>
        </InfoBoxWidgetTitleArea>
        <Divider hidden />
        {!isPDF ? (
          <InfoBoxWidgetTitleArea $alignItems="center" $flexDirection="row">
            <Input
              placeholder="Search shareholders..."
              value={filter}
              onChange={(e, { value }) => setFilter(value)}
              style={theme.sizes.isMobile ? { width: "100%" } : { width: 300 }}
            />
            <Dropdown
              selection
              placeholder="Choose shareholder sort..."
              defaultValue="sharesDesc"
              options={[
                { key: "sharesDesc", value: "sharesDesc", text: "Total number of shares held (Desc)" },
                { key: "sharesAsc", value: "sharesAsc", text: "Total number of shares held (Asc)" },
              ]}
              value={sortType}
              onChange={(e, { value }) => setSortType(value)}
              size="tiny"
              style={theme.sizes.isMobile ? { width: "100%" } : { width: 300 }}
            />
            <Dropdown
              selection
              multiple
              placeholder="Display shareholder type.."
              options={displayOptions}
              value={show}
              onChange={(e, { value }) => setShow(value)}
              size="tiny"
              style={theme.sizes.isMobile ? { width: "100%" } : { width: 350 }}
            />
          </InfoBoxWidgetTitleArea>
        ) : null}

        <InfoContainer $wrapped>
          {sortedShareholders(sortType, shareholders)
            .filter((s) => s.name.toLowerCase().indexOf(filter.toLowerCase()) > -1)
            .map((shareholder) => {
              const isShown = show.indexOf(shareholder.shareholderType.toLowerCase()) > -1;
              const isCompany = shareholder.shareholderType.toLowerCase() === "company";
              const assumedVC = isVC(shareholder.name);

              if (!isShown) return null;
              return (
                <Popup
                  key={shareholder.name}
                  disabled={!assumedVC}
                  on="hover"
                  content="Company is assumed to be venture capital"
                  trigger={
                    <InfoItem $compact>
                      <StyledShareholder
                        $isVC={assumedVC}
                        $isCompany={isCompany}
                        onClick={() => {
                          if (isCompany) {
                            handleNewSearch({ query: getSanitisedCompanyName(shareholder.name) });
                          }
                        }}
                      >
                        <span className="type">{shareholder.shareholderType.toUpperCase()}</span>
                        <span className="main">{shareholder.name}</span>
                        <span className="meta">
                          Shares: {shareholder.totalNumberOfSharesOwned}, {shareholder.percentSharesHeld || "<0.01"}%,{" "}
                          {shareholder.shareType}
                        </span>
                      </StyledShareholder>
                    </InfoItem>
                  }
                />
              );
            })}
        </InfoContainer>
      </InfoBoxWidget>
    );
  }
  return null;
};

export default CompanyShareholders;

import { useCallback, useState, useRef } from "react";
import api from "api";

export const getMentions = (kind, query) => {
  if (query === "") {
    return Promise.resolve({});
  }
  return new Promise((resolve, reject) => {
    api.search.omni(
      { query, include: kind, prioritise: true },
      (result) => {
        resolve(result);
      },
      (err) => {
        reject(err);
      },
    );
  });
};

export const useGetMentions = () => {
  const [mentions, setMentions] = useState({});
  const timeout = useRef(null);

  const getScopedMentions = useCallback((kind, query) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      getMentions(kind, query)
        .then((result) => {
          setMentions(result);
        })
        .catch(() => {
          setMentions({});
        });
    }, 300);
  }, []);

  return [mentions, getScopedMentions];
};

import React, { Component } from "react";
import { Loader, Card, Message, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import actions from "actions";
import { EmptyBox } from "components/lib/UI";
import IdeaCard from "components/lib/Cards/IdeaCard";

class ProfileIdeas extends Component {
  render() {
    const { ideas = [], loading, t, filter, sort, showDraft, showSubmitted, user, profileUser } = this.props;
    const filteredIdeas = ideas.filter((idea) => {
      if (!showDraft && !idea.isSubmitted) return false;
      if (!showSubmitted && idea.isSubmitted) return false;
      if (idea.fullTags && idea.fullTags.find((fullTag) => fullTag.value.match(RegExp(filter, "i")))) return true;
      if (idea.name && idea.name.match(RegExp(filter, "i"))) return true;
      if (idea.ownerChallenge && idea.ownerChallenge?.name.includes(filter)) return true;
      return false;
    });
    if (sort === "oldest") filteredIdeas.reverse();
    if (loading) return <Loader active inline="centered" />;
    if (!loading && !filteredIdeas.length && !ideas.length) {
      return (
        <EmptyBox>
          <h2>
            {t(user?._id === profileUser?._id ? "ideas.yours.sidebar.noneCreated" : "ideas.others.noneCreated", {
              user: profileUser?.profile?.fullName,
            })}
          </h2>
          <Button
            as={Link}
            to="/challenges/discover"
            primary
            basic
            icon="plus"
            content={t("challenges.discover.title")}
          />
        </EmptyBox>
      );
    }
    if (!loading && !filteredIdeas.length && ideas.length > 0) {
      return <EmptyBox title="This user has no public ideas available" />;
    }
    return (
      <div style={{ marginBottom: 20 }}>
        {profileUser._id === user._id && (
          <div style={{ marginBottom: 15 }}>
            <Message>Only administrators can view these {t("generic.ideas")}.</Message>
          </div>
        )}
        <Card.Group itemsPerRow={3} stackable>
          {filteredIdeas.map((idea) => (
            <IdeaCard
              key={idea._id}
              idea={idea}
              footer={
                <Link to={`/ideas/${idea._id}`} style={{ color: "#fff", textTransform: "uppercase" }}>
                  {t("ideas.card.view")} <Icon name="arrow alternate circle right outline" />
                </Link>
              }
              themedFooter
            />
          ))}
        </Card.Group>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  updateShowIdeasOnProfile: () => dispatch(actions.user.updatePublicIdeas()),
});
const ProfileIdeasContainer = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProfileIdeas));

export default ProfileIdeasContainer;

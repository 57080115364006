export const messages = (api) => ({
  createThread(data, success, fail) {
    api.authenticatedRequest("POST", "/messages/threads", data, success, fail);
  },
  getThreads(data, success, fail) {
    const { page = 1, query = "" } = data;
    api.authenticatedRequest(
      "GET",
      `/messages/threads?page=${page}${query ? `&query=${query}` : ""}`,
      null,
      success,
      fail,
    );
  },
  getThread(id, success, fail) {
    api.authenticatedRequest("GET", `/messages/threads/${id}`, null, (data) => success && success(data), fail);
  },
  muteThread(id, toMute, success, fail) {
    api.authenticatedRequest(toMute ? "PUT" : "DELETE", `/messages/threads/${id}/mute`, null, success, fail);
  },
  leaveThread(id, success, fail) {
    api.authenticatedRequest("DELETE", `/messages/threads/${id}`, null, success, fail);
  },
  updateThread(id, data, success, fail) {
    api.authenticatedRequest("POST", `/messages/threads/${id}/update`, data, ({ thread }) => success(thread), fail);
  },
  findThreads(data, success, fail) {
    api.authenticatedRequest("POST", "/messages/threads/search", data, success, fail);
  },
  findThreadByUser(userId, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/messages/users/${userId}/threads`,
      null,
      (data) => success && success(data),
      fail,
    );
  },
  send(id, content, success, fail) {
    api.authenticatedRequest("POST", `/messages/threads/${id}`, { content }, success, fail);
  },
  getMessageThread(id, success, fail) {
    api.authenticatedRequest("GET", `/messages/threads/${id}/messageThread`, null, success, fail);
  },
  markAsSeen(messageThreads, markAllAsSeen, success, fail) {
    api.authenticatedRequest("PUT", "/messages/threads/seen", { messageThreads, markAllAsSeen }, success, fail);
  },
});

import React from "react";

import { DateInput as SemanticDateInput, DateInputProps } from "semantic-ui-calendar-react";

const DateInput = ({ minDate, maxDate, value, ...props }: DateInputProps) => {
  // Semantic date input will crash if the current value passed in is outside the range of min and max date
  // This wrapper component will prevent that from happening by intercepting the value prop and setting a new min/max date that fits the current value
  if (value) {
    const date = new Date(value);
    // @ts-ignore
    if (minDate && date < new Date(minDate)) {
      minDate = value;
    }
    // @ts-ignore
    if (maxDate && date > new Date(maxDate)) {
      maxDate = value;
    }
  }
  // @ts-ignore
  return <SemanticDateInput minDate={minDate} maxDate={maxDate} value={value} {...props} />;
};

export default DateInput;
